const _ = require('lodash');

class ScrollService {
  static initClass() {
    this.prototype.scrollToElement = _.debounce(function ({
      containerId,
      element,
    }) {
      const container = this.$(`#${containerId}`);
      const top = this.$(element).offset()?.top;
      if (!top) {
        return;
      }
      const containerTop = container.offset().top;
      const scrollTop = container.scrollTop();
      const padding = 200;
      return container.animate({
        scrollTop: top - containerTop + scrollTop - padding,
      });
    },
    100);
  }

  constructor({ $ }) {
    this.$ = $;
  }

  toggleBodyScroll(enabled) {
    const overflow = enabled ? 'auto' : 'hidden';
    return this.$('body').css({ overflow });
  }
}
ScrollService.initClass();

module.exports = ScrollService;
