const _ = require('lodash');
const BaseTab = require('../base_tab');
const NotesInputsBuilder = require('./inputs_builders/notes_inputs_builder');
const PdfTourbookNotesTemplateContainer = require('../pdf_tourbook_notes_template/container');
const PropTypes = require('prop-types');
const React = require('react');
const TabViewContainer = require('../tab_view/container');
const ReadyRegistry = require('@utils/ready_registry');

class TourbookNotesView extends React.Component {
  constructor(props) {
    super(props);
    this._buildPdfTemplateComponent =
      this._buildPdfTemplateComponent.bind(this);
  }

  static initClass() {
    this.propTypes = {
      entry: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      setCurrentTab: PropTypes.func.isRequired,
      setCurrentField: PropTypes.func.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      track: PropTypes.func.isRequired,
      tourbookEntryFieldsService: PropTypes.object.isRequired,
    };
  }

  componentWillMount() {
    return this._initializeTab();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.entryId !== this.props.entryId) {
      return this._initializeTab();
    }
  }

  render() {
    return (
      <TabViewContainer
        completeConfig={{
          label: 'Add Listing',
          style: 'add',
        }}
        onComplete={() => {
          this.props.track('Selects to Add a Listing', {
            additionalProperties: { source: 'next button' },
          });
          return this.props.setCurrentModal({ key: 'new-entry' });
        }}
        tabConfigs={this._getTabConfigs()}
      />
    );
  }

  _buildPdfTemplateComponent() {
    return (
      <PdfTourbookNotesTemplateContainer
        entryId={this.props.entryId}
        isEditing={true}
        readyRegistry={new ReadyRegistry()}
      />
    );
  }

  _getHeaderFieldWithDefault(fieldKey) {
    return (
      this.props.entry.fields.header[fieldKey] ||
      this.props.tourbookEntryFieldsService.headerFields[fieldKey]
    );
  }

  _getInputs(builder) {
    const arg = _.pick(this.props, ['entry', 'entryId']);
    return builder(arg);
  }

  _getTabConfigs() {
    return [
      {
        active: true,
        component: BaseTab,
        componentProps: {
          inputs: this._getInputs(NotesInputsBuilder),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
        },
        label: this._getHeaderFieldWithDefault('notesHeader'),
        value: 'notes',
      },
    ];
  }

  _initializeTab(props) {
    return this.props.setCurrentTab({ key: 'notes' });
  }
}
TourbookNotesView.initClass();

module.exports = TourbookNotesView;
