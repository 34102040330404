"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleMapQueryService = void 0;
const _ = require('lodash');
const { locateServices } = require('@services/service_locator');
const DATA_SOURCE_TYPES = require('@enums/data_source_types');
const { ParentQueryService, Query, NewEntryData, Entry } = require('./parent_query_service');
class GoogleMapQueryService extends ParentQueryService {
    constructor() {
        super();
        this.source = DATA_SOURCE_TYPES.GOOGLE;
        ({
            googleAddressService: this.googleAddressService,
            tourbookEntryBuilderService: this.tourbookEntryBuilderService,
            tourbookEntryFieldsService: this.tourbookEntryFieldsService,
        } = locateServices([
            'googleAddressService',
            'tourbookEntryBuilderService',
            'tourbookEntryFieldsService',
        ]));
    }
    standardizeQuery(rawQuery) {
        const temp = this.googleAddressService.getProperty(rawQuery);
        const query = new Query();
        query.streetAddress = temp.address;
        query.city = temp.city;
        query.state = temp.state;
        query.zip = temp.zip;
        query.id = temp.googlePlace.place_id;
        query.originalQuery = rawQuery;
        return query;
    }
    getProperty(query) {
        const _super = Object.create(null, {
            getProperty: { get: () => super.getProperty }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield _super.getProperty.call(this, query);
            data.property = Object.assign(Object.assign({}, query), { CorrectedAddress1: query.streetAddress, propertyId: query.id });
            return data;
        });
    }
    getEntry(newEntryData, additionalInfo) {
        const _super = Object.create(null, {
            getEntry: { get: () => super.getEntry }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const entry = yield _super.getEntry.call(this, newEntryData, additionalInfo);
            const property = newEntryData.property;
            // Intermediate values
            const fields = this.tourbookEntryFieldsService.getFields({ property });
            entry.fields = fields;
            entry.meta = this.tourbookEntryBuilderService.getEntryMeta({ fields, property });
            return entry;
        });
    }
}
exports.GoogleMapQueryService = GoogleMapQueryService;
