/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require('lodash');
const FirebaseService = require('./firebase_service');
const TourbooksService = require('./tourbooks_service');
const { buildMockFirebase } = require('@test/mock_firebase_helpers');
const { setService, stubService } = require('./service_locator');

describe('TourbooksService', function () {
  beforeEach(
    async function() {
      this.tourbookBuilderService = stubService('tourbookBuilderService');
      this.tourbookBuilderService.buildDefault
        .withArgs({ id: 'user1' })
        .resolves({
          content: { cover: { fields: { title: 'Test Title' } } },
          owner_id: 'user1',
        });
      const dependencies = buildMockFirebase();
      this.firebaseService = new FirebaseService(dependencies);
      await this.firebaseService.setValue('/', null);
      setService('firebaseService', this.firebaseService);

      return (this.tourbooksService = new TourbooksService());
    }
  );

  describe('create', function () {
    beforeEach(
      async function() {
        return this.result = await this.tourbooksService.create({
          id: 'user1',
        });
      }
    );

    it('returns an id and a tourbook', function () {
      expect(this.result.id).to.not.be.undefined;
      expect(this.result.tourbook).to.not.be.undefined;
      return expect(this.result.tourbook.owner_id).to.eql('user1');
    });

    it(
      'saves the tourbook to firebase',
      async function() {
        const tourbook = await this.firebaseService.getValue(
          `tourbooks/${this.result.id}`
        );
        return expect(tourbook).to.eql(this.result.tourbook);
      }
    );

    return it(
      'udpates the meta for the owner',
      async function() {
        const meta = await this.firebaseService.getValue(
          `userTourbooks/user1/${this.result.id}`
        );
        return expect(meta).to.eql({
          name: 'Test Title',
          ownerID: 'user1',
          shared: false,
        });
      }
    );
  });

  describe('leaveSharedTourbook', function () {
    beforeEach(function () {
      this.db = {
        'tourbooks/tourbook1': {
          content: { some: 'data' },
          owner_id: 'broker1',
          shared: { broker2: true },
        },
        'userSharedTourbooks/broker2/tourbook1': { some: 'meta' },
        'userTourbooks/broker1/tourbook1': { shared: true, some: 'meta' },
      };
      return this.setupTest = async function() {
        await this.firebaseService.updateValues('/', this.db);
        return await this.tourbooksService.leaveSharedTourbook({
          tourbookId: 'tourbook1',
          userId: 'broker2',
        });
      }.bind(this);
    });

    it(
      'deletes the appropriate userSharedTourbooks node for the sharee',
      async function() {
        await this.setupTest();
        const data = await this.firebaseService.getValue(
          'userSharedTourbooks/broker2/tourbook1'
        );
        return expect(data).to.be.null;
      }
    );

    it(
      'deletes the appropriate node in tourbooks/<tid> for the sharee',
      async function() {
        await this.setupTest();
        const data = await this.firebaseService.getValue(
          'tourbooks/tourbook1/shared/broker2'
        );
        return expect(data).to.be.null;
      }
    );

    describe('when the user leaving the tourbook is the last shared user', () =>
      it(
        'updates the shared flag in userTourbooks/<owner uid>/<tid> to false',
        async function() {
          await this.setupTest();
          const data = await this.firebaseService.getValue(
            'userTourbooks/broker1/tourbook1/shared'
          );
          return expect(data).to.be.false;
        }
      ));

    return describe('when the user leaving the tourbook is not the last shared user', function () {
      beforeEach(function () {
        return (this.db['tourbooks/tourbook1'].shared.broker3 = true);
      });

      return it(
        'updates the shared flag in userTourbooks/<owner uid>/<tid> to false',
        async function() {
          await this.setupTest();
          const data = await this.firebaseService.getValue(
            'userTourbooks/broker1/tourbook1/shared'
          );
          return expect(data).to.be.true;
        }
      );
    });
  });

  describe('delete', function () {
    beforeEach(
      async function() {
        await this.firebaseService.updateValues('/', {
          'tourbooks/tourbook1': {
            content: { some: 'data' },
            owner_id: 'broker1',
            shared: {
              broker2: true,
            },
          },
          'userSharedTourbooks/broker2/tourbook1': { some: 'meta' },
          'userTourbooks/broker1/tourbook1': { some: 'meta' },
        });
        return await this.tourbooksService.delete('tourbook1');
      }
    );

    it(
      'deletes the tourbook',
      async function() {
        const tourbook = await this.firebaseService.getValue(
          'tourbooks/tourbook1'
        );
        return expect(tourbook).to.be.null;
      }
    );

    return it(
      'delete all tourbook meta',
      async function() {
        const meta1 = await this.firebaseService.getValue(
          'userTourbooks/broker1/tourbook1'
        );
        expect(meta1).to.be.null;
        const meta2 = await this.firebaseService.getValue(
          'userSharedTourbooks/broker2/tourbook1'
        );
        return expect(meta2).to.be.null;
      }
    );
  });

  describe('getOwner', function () {
    beforeEach(
      async function() {
        return await this.firebaseService.updateValues('/', {
          'tourbooks/tourbook1': {
            content: { some: 'data' },
            owner_id: 'broker1',
            shared: {
              broker2: true,
            },
          },
        });
      }
    );

    return it(
      'returns the owner ID',
      async function() {
        return expect(await this.tourbooksService.getOwner('tourbook1')).to.eql(
          'broker1'
        );
      }
    );
  });

  describe('getSharedUserIds', function () {
    beforeEach(
      async function() {
        return await this.firebaseService.updateValues('/', {
          'tourbooks/tourbook1': {
            content: { some: 'data' },
            owner_id: 'broker1',
            shared: {
              broker2: true,
              broker3: true,
            },
          },
        });
      }
    );

    return it(
      'returns the shared users',
      async function() {
        return expect(
          await this.tourbooksService.getSharedUserIds('tourbook1')
        ).to.eql(['broker2', 'broker3']);
      }
    );
  });

  describe('getIsPublished', function () {
    describe('without publish events present', () =>
      it(
        'returns false',
        async function() {
          return expect(
            await this.tourbooksService.getIsPublished('tourbook1')
          ).to.eql(false);
        }
      ));

    describe('with publish events present for the owner', function () {
      beforeEach(
        async function() {
          return await this.firebaseService.updateValues('/', {
            'tourbooks/tourbook1': {
              content: { some: 'data' },
              owner_id: 'broker1',
              shared: {
                broker2: true,
                broker3: true,
              },
            },
            'userTourbooksPublished/broker1/tourbook1': {
              publishMethod: 'email',
              timestamp: 123,
            },
          });
        }
      );

      return it(
        'returns true',
        async function() {
          return expect(
            await this.tourbooksService.getIsPublished('tourbook1')
          ).to.eql(true);
        }
      );
    });

    return describe('with publish events present for a shared broker', function () {
      beforeEach(
        async function() {
          return await this.firebaseService.updateValues('/', {
            'tourbooks/tourbook1': {
              content: { some: 'data' },
              owner_id: 'broker1',
              shared: {
                broker2: true,
                broker3: true,
              },
            },
            'userTourbooksPublished/broker3/tourbook1': {
              publishMethod: 'email',
              timestamp: 123,
            },
          });
        }
      );

      return it(
        'returns true',
        async function() {
          return expect(
            await this.tourbooksService.getIsPublished('tourbook1')
          ).to.eql(true);
        }
      );
    });
  });

  describe('reorderEntryFields', () =>
    it(
      'updates the order for the fields',
      async function() {
        const propertyFieldsPath =
          'tourbooks/tourbook1/content/entries/entry1/fields/property';
        await this.firebaseService.setValue(propertyFieldsPath, {
          buildingArea: { order: 0, value: 'a' },
          floors: { order: 1, value: 'b' },
          officeClass: { order: 2, value: 'c' },
          yearBuilt: { order: 3, value: 'd' },
        });
        await this.tourbooksService.reorderEntryFields({
          entryId: 'entry1',
          fieldKeys: ['officeClass', 'buildingArea', 'floors', 'yearBuilt'],
          section: 'property',
          tourbookId: 'tourbook1',
        });
        const data = await this.firebaseService.getValue(propertyFieldsPath);
        return expect(data).to.eql({
          buildingArea: { order: 1, value: 'a' },
          floors: { order: 2, value: 'b' },
          officeClass: { order: 0, value: 'c' },
          yearBuilt: { order: 3, value: 'd' },
        });
      }
    ));

  describe('updateAtPath', function () {
    it(
      'sets the value at the correct path through firebase',
      async function() {
        await this.firebaseService.setValue(
          'tourbooks/id1/path1/path2',
          'oldValue'
        );
        await this.tourbooksService.updateAtPath(
          'id1',
          ['path1', 'path2'],
          'newValue'
        );
        const data = await this.firebaseService.getValue(
          'tourbooks/id1/path1/path2'
        );
        return expect(data).to.eql('newValue');
      }
    );

    return it('throws an error if path is undefined', function () {
      const fn = () =>
        this.tourbooksService.updateAtPath('id1', undefined, 'value1');
      return expect(fn).to.throw('missing parameter path');
    });
  });

  return describe('trackSentTo', () =>
    it(
      'tracks the send with a timestamp',
      async function() {
        await this.tourbooksService.trackSentTo('id1', 'email1');
        await this.tourbooksService.trackSentTo('id1', 'email2');
        const data = _.values(
          await this.firebaseService.getValue('analytics/id1/sentTo')
        );
        expect(data).to.have.lengthOf(2);
        expect(data[0].email).to.eql('email1');
        return expect(data[1].email).to.eql('email2');
      }
    ));
});
