const _ = require('lodash');
const PostInputSetAddButton = require('../post_input_set_add_button');
const PropTypes = require('prop-types');
const React = require('react');

class RecommendedFieldsSection extends React.Component {
  constructor(props) {
    super(props);
    this._openModal = this._openModal.bind(this);
  }

  static initClass() {
    this.propTypes = {
      fieldDefinitions: PropTypes.object.isRequired,
      fields: PropTypes.object.isRequired,
      options: PropTypes.shape({
        entryId: PropTypes.string.isRequired,
        section: PropTypes.string.isRequired,
      }).isRequired,
      previousFields: PropTypes.object.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      tourbookFieldsService: PropTypes.object.isRequired,
      track: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <PostInputSetAddButton
        caption={this._getLinkText()}
        label="Add Recommended Fields"
        onClick={this._openModal} />
    );
  }

  _getLinkText() {
    const suggestedFields = this._getSuggestedFields();
    const numFieldsWithData = _.filter(suggestedFields, {
      hasValue: true,
      isAtlas: true,
    }).length;
    if (numFieldsWithData > 0) {
      return 'N360 data available';
    } else {
      return '';
    }
  }

  _getSuggestedFields() {
    return this.props.tourbookFieldsService.getSuggestedFields({
      fieldDefinitions: this.props.fieldDefinitions,
      fields: this.props.fields,
      previousFields: this.props.previousFields,
      section: this.props.options.section,
    });
  }

  _openModal() {
    this.props.track('Browses Recommended Fields', {
      entryId: this.props.options.entryId,
    });
    return this.props.setCurrentModal({
      entryId: this.props.options.entryId,
      key: 'recommended-fields',
      section: this.props.options.section,
    });
  }
}
RecommendedFieldsSection.initClass();

module.exports = RecommendedFieldsSection;
