const _ = require('lodash');

class DocraptorHtmlService {
  static initClass() {
    this.prototype.meta = '<meta charset="utf-8"/>'; // For bullet point character

    this.prototype.scripts =
      // Load fonts
      `\
<script src='https://use.typekit.net/qer2cbg.js'></script>
<script> try{Typekit.load({async: true});}catch(e){} </script>\
`;

    this.prototype.stringTransformations = {
      // IE converts "text-overflow" to "-ms-text-overflow"
      '-ms-text-overflow': 'text-overflow',
      // Safari changes "background-repeat: no-repeat;" to "background-repeat: no-repeat no-repeat;"
      // While this is supported in the CSS specification, Docraptor currently does not support it
      'background-repeat: no-repeat no-repeat;':
        'background-repeat: no-repeat;',
      '­': '', // Removes 0 length spaces
      '—': '-',
      '’': "'",
    };
  }

  constructor({ $, document }) {
    this.$ = $;
    this.document = document;
  }

  transform(tourbookHtml) {
    const html = this.$(tourbookHtml).addClass('docraptor')[0].outerHTML;
    return this._applyStringTransformations(
      this.meta + this.scripts + this._getCss() + html
    );
  }

  _applyStringTransformations(data, transforms) {
    const iteratee = function (accumulator, to, from) {
      const regExp = new RegExp(from, 'g');
      return accumulator.replace(regExp, to);
    };
    return _.reduce(this.stringTransformations, iteratee, data);
  }

  _getCss() {
    const styles = this._getStyles();
    return `\
<style>
  body { display: block; height: auto; width: auto; position: relative; }
  @page { size: US-Letter landscape; }
  @page { margin: 0px; }
  ${styles.join('')}
  .docraptor [data-warning="warning-message"] { display: none }
  .docraptor [data-warning="warning-border"] { border: none }
</style>\
`;
  }

  _getStyles() {
    const { styleSheets } = this.document;
    if (styleSheets[0]?.cssText) {
      // IE
      return _.chain(styleSheets).reject('href').map('cssText').value();
    } else {
      // Chrome and Firefox
      return _.chain(styleSheets)
        .map(this._getStylesFromStyleSheet)
        .flatten()
        .value();
    }
  }

  _getStylesFromStyleSheet(styleSheet) {
    try {
      return _.chain(styleSheet.cssRules).toArray().map('cssText').value();
    } catch (error) {
      // Firefox's security rule is triggered when inspecting foreign stylesheets
      if (error.name !== 'SecurityError') {
        throw error;
      }
      return [];
    }
  }
}
DocraptorHtmlService.initClass();

module.exports = DocraptorHtmlService;
