/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const AnalyticsService = require('./analytics_service');
const { stubServices } = require('./service_locator');

describe('AnalyticsService', function () {
  beforeEach(function () {
    this.localStorage = {};
    this.window = {};
    ({
      subscriptionDecoratorService: this.subscriptionDecoratorService,
      userTourbooksService: this.userTourbooksService,
    } = stubServices(['subscriptionDecoratorService', 'userTourbooksService']));
    const decoratedSubscription = {
      getAnalyticsProperties: sinon
        .stub()
        .returns({ key1: 'value1', key2: 'value2' }),
      getNumberOfTourbooksRemaining: sinon.stub().returns(1),
    };
    this.subscriptionDecoratorService.decorate.returns(decoratedSubscription);

    return (this.analyticsService = new AnalyticsService({
      localStorage: this.localStorage,
      window: this.window,
    }));
  });

  describe('clearAnalytics', function () {
    describe('analytics and mixpanel not loaded', () =>
      it('does not error', function () {
        return this.analyticsService.clearAnalytics();
      }));

    return describe('analytics loaded', function () {
      beforeEach(function () {
        return (this.window.analytics = {});
      });

      describe('user not loaded', () =>
        it('does not error', function () {
          return this.analyticsService.clearAnalytics();
        }));

      describe('user loaded', function () {
        beforeEach(function () {
          this.logout = sinon.stub();
          return (this.window.analytics.user = () => ({ logout: this.logout }));
        });

        return it('logs out the analytics user', function () {
          this.analyticsService.clearAnalytics();
          return expect(this.logout).to.have.been.called;
        });
      });

      return describe('mixpanel loaded', function () {
        beforeEach(function () {
          return (this.window.mixpanel = {});
        });

        describe('cookie not loaded', () =>
          it('does not error', function () {
            return this.analyticsService.clearAnalytics();
          }));

        return describe('cookie loaded', function () {
          beforeEach(function () {
            this.clear = sinon.stub();
            return (this.window.mixpanel.cookie = { clear: this.clear });
          });

          return it('clears the mixpanel cookie', function () {
            this.analyticsService.clearAnalytics();
            return expect(this.clear).to.have.been.called;
          });
        });
      });
    });
  });

  describe('track', function () {
    beforeEach(function () {
      this.identify = sinon.stub();
      this.track = sinon.stub();
      return (this.window.analytics = {
        identify: this.identify,
        track: this.track,
      });
    });

    describe('with a user identified', function () {
      beforeEach(function () {
        return (this.window.analytics.user = () => ({
          id() {
            return 'user1';
          },
        }));
      });

      describe('user is being impersonated', function () {
        beforeEach(function () {
          this.localStorage.impersonating = true;
          return this.analyticsService.track('event1', {
            some: 'event properties',
          });
        });

        it('tracks the event', function () {
          return expect(this.track).to.have.been.calledWith('event1', {
            some: 'event properties',
          });
        });

        return it('does not update mixpanels last seen', function () {
          return expect(this.identify).not.to.have.been.called;
        });
      });

      return describe('user is not being impersonated', function () {
        beforeEach(function () {
          return this.analyticsService.track('event1', {
            some: 'event properties',
          });
        });

        it('tracks the event', function () {
          return expect(this.track).to.have.been.calledWith('event1', {
            some: 'event properties',
          });
        });

        return it('does not update mixpanels last seen', function () {
          return expect(this.identify).to.have.been.calledWith('user1', {
            last_seen_trigger: '',
          });
        });
      });
    });

    return describe('without a user identified', function () {
      beforeEach(function () {
        return (this.window.analytics.user = () => ({
          id() {
            return null;
          },
        }));
      });

      it('does not track the event', function () {
        this.analyticsService.track('event1', { some: 'event properties' });
        return expect(this.track).not.to.have.been.called;
      });

      return describe('when the even queue is drained', () =>
        it('tracks the events', function () {
          this.analyticsService.track('event1', { some: 'event properties1' });
          this.analyticsService.track('event2', { some: 'event properties2' });
          this.analyticsService.sendEventQueue();
          expect(this.track).to.have.been.calledWith('event1', {
            some: 'event properties1',
          });
          return expect(this.track).to.have.been.calledWith('event2', {
            some: 'event properties2',
          });
        }));
    });
  });

  describe('identify', function () {
    beforeEach(function () {
      this.userTourbooksService.getOwnedCount.resolves(2);
      this.userTourbooksService.getPublishedCount.resolves(3);
      this.userTourbooksService.getSharedCount.resolves(4);
      this.identify = sinon.stub();
      return (this.window.analytics = { identify: this.identify });
    });

    return it(
      'sets the user properties',
      async function() {
        await this.analyticsService.identify({
          auth0Profile: {
            created_at: '2017-01-01 00:00:00',
            email: 'john@doe.com',
          },
          firebaseProfile: {
            company: 'My Company',
            first_name: 'John',
            last_name: 'Doe',
            phone: '1234-567',
          },
          firebaseUser: { getToken: sinon.stub().resolves() },
          id: 'user1',
        });
        return expect(this.identify).to.have.been.calledWith('user1', {
          company: 'My Company',
          created_at: '2017-01-01 00:00:00',
          editor: 'v2',
          email: 'john@doe.com',
          first_name: 'John',
          free_tourbooks_left: 1,
          id: 'user1',
          last_name: 'Doe',
          name: 'John Doe',
          phone: '1234-567',
          published_tourbooks_count: 3,
          subscription_key1: 'value1',
          subscription_key2: 'value2',
          tourbooks_count: 2,
        });
      }
    );
  });

  describe('setAlias', function () {
    beforeEach(function () {
      this.setAlias = sinon.stub();
      return (this.window.analytics = { setAlias: this.setAlias });
    });

    return it('sets the analytics alias', function () {
      this.analyticsService.setAlias('user1');
      return expect(this.setAlias).to.have.been.calledWith('user1');
    });
  });

  return describe('updateUserLastPublish', function () {
    beforeEach(function () {
      this.userTourbooksService.getPublishedCount.resolves(1);

      this.identify = sinon.stub();
      this.window.analytics = { identify: this.identify };
      return (this.user = {
        firebaseUser: { getToken: sinon.stub().resolves() },
        id: 'user1',
      });
    });

    describe('without broker contact info', () =>
      it(
        'updates the properties',
        async function() {
          await this.analyticsService.updateUserLastPublish(this.user);
          return expect(this.identify).to.have.been.calledWith('user1', {
            LTP_Company: undefined,
            LTP_Name: undefined,
            LTP_Phone: undefined,
            published_tourbooks_count: 1,
          });
        }
      ));

    return describe('with broker contact info', () =>
      it(
        'updates the properties',
        async function() {
          const brokerContactInfo = {
            company: 'My Company',
            name: 'My Name',
            phone: '123-4567',
          };
          await this.analyticsService.updateUserLastPublish(
            this.user,
            brokerContactInfo
          );
          return expect(this.identify).to.have.been.calledWith('user1', {
            LTP_Company: 'My Company',
            LTP_Name: 'My Name',
            LTP_Phone: '123-4567',
            published_tourbooks_count: 1,
          });
        }
      ));
  });
});
