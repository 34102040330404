/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const TourbookEntryBuilderService = require('./tourbook_entry_builder_service');
const _ = require('lodash');
const { Promise } = require('bluebird');
const { stubServices } = require('./service_locator');

describe('TourbookEntryBuilderService', function () {
  beforeEach(function () {
    ({
      amenitiesService: this.amenitiesService,
      cloudinaryService: this.cloudinaryService,
      firebaseService: this.firebaseService,
      googleAddressService: this.googleAddressService,
      tourbookEntryFieldsService: this.tourbookEntryFieldsService,
    } = stubServices([
      'amenitiesService',
      'cloudinaryService',
      'firebaseService',
      'googleAddressService',
      'tourbookEntryFieldsService',
    ]));
    this.amenitiesService.getEntryUpdates.resolves({
      pages: { amenities: 'amenities data' },
    });
    this.cloudinaryService.uploadImages = sinon.spy((images) =>
      Promise.resolve(
        _.map(images, (image) => [
          {
            cloudinaryPublicId: image.id,
            type: 'image',
          },
        ])
      )
    );
    this.firebaseService.getUniqueId
      .onCall(0)
      .returns('pageId1')
      .onCall(1)
      .returns('imageId1')
      .onCall(2)
      .returns('imageId2')
      .onCall(3)
      .returns('imageId3');
    this.googleAddressService.geocode.resolves({
      geometry: {
        location: {
          lat() {
            return 3;
          },
          lng() {
            return 4;
          },
        },
      },
    });
    this.tourbookEntryFieldsService.getData.returns({
      fields: {
        header: {
          field1: 'value1',
          field2: 'value2',
        },
      },
      xceligentData: {
        field3: 'value3',
      },
    });
    return (this.tourbookEntryBuilderService =
      new TourbookEntryBuilderService());
  });

  describe('build', () =>
    describe('without space', function () {
      describe('property has a latitude and longitude', function () {
        beforeEach(
          async function() {
            return this.result = await this.tourbookEntryBuilderService.build({
              includeAmenities: true,
              property: { latitude: 1, leaseOrSale: 'Lease', longitude: 2 },
            });
          }
        );

        return it('returns the result', function () {
          return expect(this.result).to.eql({
            fields: {
              header: {
                field1: 'value1',
                field2: 'value2',
              },
            },
            images: {},
            imagesShown: [],
            layout: '1_1_1',
            location: {
              latitude: 1,
              longitude: 2,
            },
            meta: {
              originalAddressFields: {},
              source: 'new',
            },
            pages: {
              amenities: 'amenities data',
            },
            xceligentData: {
              field3: 'value3',
            },
            xceligentImages: [],
          });
        });
      });

      describe('property has no latitude and longitude', function () {
        beforeEach(
          async function() {
            this.tourbookEntryFieldsService.getData.returns({
              fields: {
                header: {
                  CorrectedAddress1: '123 My Street',
                  city: 'My City',
                  state: 'My State',
                  zip: '01234',
                },
              },
              xceligentData: {},
            });
            return this.result = await this.tourbookEntryBuilderService.build({
              property: { leaseOrSale: 'Lease' },
            });
          }
        );

        it('geocodes the address', function () {
          return expect(
            this.googleAddressService.geocode
          ).to.have.been.calledWith('123 My Street My City My State 01234');
        });

        it('adds the geocoded location', function () {
          return expect(this.result.location).to.eql({
            latitude: 3,
            longitude: 4,
          });
        });

        it('adds the address data in the fields', function () {
          return expect(this.result.fields.header).to.eql({
            CorrectedAddress1: '123 My Street',
            city: 'My City',
            state: 'My State',
            zip: '01234',
          });
        });

        return it('copies the address data to meta/originalAddressFields', function () {
          return expect(this.result.meta.originalAddressFields).to.eql({
            CorrectedAddress1: '123 My Street',
            city: 'My City',
            state: 'My State',
            zip: '01234',
          });
        });
      });

      describe('property has 0 latitude and longitude', function () {
        beforeEach(
          async function() {
            this.tourbookEntryFieldsService.getData.returns({
              fields: {
                header: {
                  CorrectedAddress1: '123 My Street',
                  city: 'My City',
                  state: 'My State',
                  zip: '01234',
                },
              },
              xceligentData: {},
            });
            return this.result = await this.tourbookEntryBuilderService.build({
              property: {
                latitude: 0,
                leaseOrSale: 'Lease',
                longitude: 0,
              },
            });
          }
        );

        it('geocodes the address', function () {
          return expect(
            this.googleAddressService.geocode
          ).to.have.been.calledWith('123 My Street My City My State 01234');
        });

        it('adds the geocoded location', function () {
          return expect(this.result.location).to.eql({
            latitude: 3,
            longitude: 4,
          });
        });

        it('adds the address data in the fields', function () {
          return expect(this.result.fields.header).to.eql({
            CorrectedAddress1: '123 My Street',
            city: 'My City',
            state: 'My State',
            zip: '01234',
          });
        });

        return it('copies the address data to meta/originalAddressFields', function () {
          return expect(this.result.meta.originalAddressFields).to.eql({
            CorrectedAddress1: '123 My Street',
            city: 'My City',
            state: 'My State',
            zip: '01234',
          });
        });
      });

      return describe('with space from atlas', function () {
        describe('type', function () {
          beforeEach(function () {
            this.property = {};
            return (this.space = {});
          });

          it(
            'sets the source as xceligent',
            async function() {
              this.result = await this.tourbookEntryBuilderService.build({
                property: this.property,
                space: this.space,
              });
              return expect(this.result.meta.source).to.eql('xceligent');
            }
          );

          describe('neither space nor property have type defined', function () {
            beforeEach(
              async function() {
                return this.result =
                  await this.tourbookEntryBuilderService.build({
                    property: this.property,
                    space: this.space,
                  });
              }
            );

            return it('does not set type', function () {
              return expect(this.result.type).to.be.null;
            });
          });

          describe('space has type defined', function () {
            beforeEach(
              async function() {
                this.space.leaseOrSale = 'Lease';
                return this.result =
                  await this.tourbookEntryBuilderService.build({
                    property: this.property,
                    space: this.space,
                  });
              }
            );

            return it('sets type', function () {
              return expect(this.result.type).to.eql('Lease');
            });
          });

          describe('property has type defined', function () {
            beforeEach(
              async function() {
                this.property.leaseOrSale = 'Sale';
                return this.result =
                  await this.tourbookEntryBuilderService.build({
                    property: this.property,
                    space: this.space,
                  });
              }
            );

            return it('sets type', function () {
              return expect(this.result.type).to.eql('Sale');
            });
          });

          return describe('space and property have type defined', function () {
            beforeEach(
              async function() {
                this.property.leaseOrSale = 'Sale';
                this.space.leaseOrSale = 'Lease';
                return this.result =
                  await this.tourbookEntryBuilderService.build({
                    property: this.property,
                    space: this.space,
                  });
              }
            );

            return it('sets type', function () {
              return expect(this.result.type).to.eql('Lease');
            });
          });
        });

        return describe('images', function () {
          beforeEach(function () {
            this.property = {
              fromAtlas: true,
              media: [
                {
                  atlas: true,
                  extension: '.jpg',
                  path: 'https://www.xceligentpro.com/media/s3.amazonaws.com/some/path.jpg',
                  photoOrder: '1',
                  source: 'property',
                },
                {
                  atlas: true,
                  extension: '.jpg',
                  mediaName: 'Building Floor Plan',
                  path: 'https://res.cloudinary.com/some/path2.jpg',
                  photoOrder: '2',
                  source: 'property',
                },
              ],
            };
            return (this.space = {
              media: [
                {
                  atlas: true,
                  extension: '.jpg',
                  path: 'https://www.xceligentpro.com/media/s3.amazonaws.com/some/path3.jpg',
                  photoOrder: '1',
                  source: 'space',
                },
              ],
            });
          });

          return describe('with all working images', function () {
            beforeEach(
              async function() {
                return this.result =
                  await this.tourbookEntryBuilderService.build({
                    property: this.property,
                    space: this.space,
                  });
              }
            );

            it('correctly uploads the images', function () {
              return expect(
                this.cloudinaryService.uploadImages
              ).to.have.been.calledWith([
                {
                  id: 'atlas-upload/some/path',
                  path: 'https://www.xceligentpro.com/media/s3.amazonaws.com/some/path.jpg',
                },
                {
                  id: 'atlas-upload/some/path3',
                  path: 'https://www.xceligentpro.com/media/s3.amazonaws.com/some/path3.jpg',
                },
                {
                  id: 'atlas-upload/some/path2',
                  path: 'https://res.cloudinary.com/some/path2.jpg',
                },
              ]);
            });

            return it('returns the proper result', function () {
              expect(this.result.xceligentImages).to.eql([
                {
                  atlas: true,
                  cloudinaryPublicId: 'atlas-upload/some/path',
                  source: 'property',
                  type: 'image',
                },
                {
                  atlas: true,
                  cloudinaryPublicId: 'atlas-upload/some/path3',
                  source: 'space',
                  type: 'image',
                },
                {
                  atlas: true,
                  cloudinaryPublicId: 'atlas-upload/some/path2',
                  mediaTitle: 'Building Floor Plan',
                  source: 'property',
                  type: 'image',
                },
              ]);
              expect(this.result.images).to.eql({
                imageId1: {
                  atlas: true,
                  cloudinaryPublicId: 'atlas-upload/some/path',
                  source: 'property',
                  type: 'image',
                },
                imageId2: {
                  atlas: true,
                  cloudinaryPublicId: 'atlas-upload/some/path3',
                  source: 'space',
                  type: 'image',
                },
                imageId3: {
                  atlas: true,
                  cloudinaryPublicId: 'atlas-upload/some/path2',
                  mediaTitle: 'Building Floor Plan',
                  source: 'property',
                  type: 'image',
                },
              });
              expect(this.result.imagesShown).to.eql([
                {
                  atlas: true,
                  cloudinaryPublicId: 'atlas-upload/some/path',
                  source: 'property',
                  type: 'image',
                },
                {
                  atlas: true,
                  cloudinaryPublicId: 'atlas-upload/some/path3',
                  source: 'space',
                  type: 'image',
                },
              ]);
              return expect(this.result.pages.images).to.eql({
                pageId1: {
                  imagesShown: [
                    {
                      atlas: true,
                      cloudinaryPublicId: 'atlas-upload/some/path2',
                      mediaTitle: 'Building Floor Plan',
                      source: 'property',
                      type: 'image',
                    },
                  ],
                  type: 'floor_plan',
                },
              });
            });
          });
        });
      });
    }));

  describe('with xceligent property', function () {
    describe('without space', function () {
      beforeEach(
        async function() {
          return this.result = await this.tourbookEntryBuilderService.build({
            property: { id: 1 },
          });
        }
      );

      return it('saves the ids in meta', function () {
        return expect(this.result.meta.xceligentPropertyId).to.eql(1);
      });
    });

    return describe('with space', function () {
      beforeEach(
        async function() {
          return this.result = await this.tourbookEntryBuilderService.build({
            property: { id: 1 },
            space: { id: 3, listingID: 2 },
          });
        }
      );

      return it('saves the ids in meta', function () {
        expect(this.result.meta.xceligentPropertyId).to.eql(1);
        expect(this.result.meta.xceligentListingId).to.eql(2);
        return expect(this.result.meta.xceligentSpaceId).to.eql(3);
      });
    });
  });

  return describe('with images from cs', function () {
    beforeEach(
      async function() {
        this.image1 = { cloudinaryPublicId: 'image1', cs: true };
        this.image2 = { cloudinaryPublicId: 'image2', cs: true };
        this.image3 = { cloudinaryPublicId: 'image3', cs: true };
        this.cloudinaryService.isImageValid
          .withArgs(this.image1)
          .resolves(true)
          .withArgs(this.image2)
          .resolves(false)
          .withArgs(this.image3)
          .resolves(true);
        this.property = { media: [this.image1, this.image2] };
        this.space = { media: [this.image3] };
        return this.result = await this.tourbookEntryBuilderService.build({
          property: this.property,
          space: this.space,
        });
      }
    );

    return it('filters out invalid images', function () {
      return expect(this.result.xceligentImages).to.eql([
        this.image1,
        this.image3,
      ]);
    });
  });
});
