/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const UserTourbooksService = require('./user_tourbooks_service');
const { buildMockService } = require('@test/mock_service_helpers');
const { setService } = require('./service_locator');

describe('UserTourbooksService', function () {
  beforeEach(function () {
    this.firebaseService = buildMockService('firebaseService');
    this.firebaseService.getServerTimestamp.returns(123);
    this.firebaseService.pushValue.resolves();
    this.firebaseService.updateValues.resolves();
    setService('firebaseService', this.firebaseService);

    return (this.service = new UserTourbooksService());
  });

  describe('getPublishedCount', () =>
    describe('with 3 publish events', function () {
      beforeEach(function () {
        return this.firebaseService.getChildCount
          .withArgs('userTourbooksPublished/user1', 'token')
          .returns(3);
      });

      return it('returns 3', function () {
        return expect(this.service.getPublishedCount('user1', 'token')).to.eql(
          3
        );
      });
    }));

  describe('recordTourbookPublish', () =>
    it('adds a publish event to firebase', function () {
      this.service.recordTourbookPublish('user1', 'tourbook1', 'email');
      const pushStub = this.firebaseService.pushValue;
      return expect(pushStub).to.have.been.calledWith(
        'userTourbooksPublished/user1/tourbook1',
        {
          publishMethod: 'email',
          timestamp: 123,
        }
      );
    }));

  return describe('updateMetaField', function () {
    beforeEach(function () {
      const tourbook = {
        owner_id: 'broker1',
        shared: {
          broker2: true,
        },
      };
      return this.service.updateMetaField({
        path: ['field1'],
        tourbook,
        tourbookId: 'tourbook1',
        value: 'value1',
      });
    });

    return it('calls firebase update on the correct fields', function () {
      return expect(this.firebaseService.updateValues).to.be.calledWith('/', {
        'userSharedTourbooks/broker2/tourbook1/field1': 'value1',
        'userTourbooks/broker1/tourbook1/field1': 'value1',
      });
    });
  });
});
