const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ConfirmActionBar = require('@components/shared/confirm_action_bar');
const ImageCropper = require('./components/image_cropper');
const MapEditor = require('./components/map_editor');
const ModalContainer = require('@components/shared/modal/container');
const PropTypes = require('prop-types');
const React = require('react');
const StreetviewEditor = require('./components/streetview_editor');

class CropImageModal extends React.Component {
  constructor(props) {
    super(props);
    this._crop = this._crop.bind(this);
  }

  static initClass() {
    this.propTypes = {
      image: PropTypes.object.isRequired,
      imageUrlService: PropTypes.object.isRequired,
      onClose: PropTypes.func.isRequired,
      options: PropTypes.shape({
        entryLocation: PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        }),
        height: PropTypes.number,
        path: PropTypes.array.isRequired,
        width: PropTypes.number,
      }).isRequired,
      trackCropImage: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    };
  }

  render() {
    const Editor = this._getEditor();
    return (
      <ModalContainer onClose={this.props.onClose} verticallyCentered={true}>
        <div>
          <div className={cx(['editor-wrapper'])}>
            {
              <Editor
                {...{
                  desiredHeight: this.props.options.height,
                  desiredWidth: this.props.options.width,
                  entryLatitude: this.props.options.entryLocation?.latitude,
                  entryLongitude: this.props.options.entryLocation?.longitude,
                  image: this.props.image,
                  imageUrlService: this.props.imageUrlService,
                  ref: (node) => {
                    return (this.editor = node);
                  },
                }}
              />
            }
          </div>
          <ConfirmActionBar
            cancel={this.props.onClose}
            confirm={this._crop}
            label="Crop Image"
          />
        </div>
      </ModalContainer>
    );
  }

  _crop() {
    const newData = this.editor.getData();
    const value = _.assign({}, this.props.image, newData);
    this.props.update({
      analyticsOptions: { trigger: 'crop' },
      path: this.props.options.path,
      value,
    });
    this.props.trackCropImage(value);
    return this.props.onClose();
  }

  _getEditor() {
    switch (this.props.image.type) {
      case 'image':
        return ImageCropper;
      case 'map':
        return MapEditor;
      case 'streetview':
        return StreetviewEditor;
      default:
        throw new Error(`Unsupported image type: ${this.props.image.type}`);
    }
  }
}
CropImageModal.initClass();

module.exports = CropImageModal;
