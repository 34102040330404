const _ = require('lodash');
const { locateServices } = require('./service_locator');
const IMAGE_SOURCE_TYPES = require('@enums/image_source_types');

class N360Service {
  static initClass() {
    this.prototype.propertySearch = async function (searchTerm) {
      const encodedSearchTerm = encodeURI(searchTerm);
      if (encodedSearchTerm.length > 0) {
        const queryObject = {
          type: 'PropertySearch',
          params: { term: encodedSearchTerm, pageSize: 30, pageNumber: 1 },
        };
        const result = await this._makeRequest(queryObject); 
        if (!result) {
          return [];
        }
        const data = result.data;
        const status = result.status;
        const hits = (data && data.data && data.data.hits && data.data.hits.hits) || [];
        if (status !== 200) {
          return [];
        }
        if(!hits){
          return [];
        }
        const properties = this._extractProperties(hits);
        if (properties.length > 0) {
          return properties;
        } else {
          return [];
        }
      }
      return [];
    };

    this.prototype.propertyDetailFetch = async function (propertyId) {
      if (propertyId.length > 0) {
        const queryObject = { type: 'PropertyDetails', params: { propertyId } };
        const {
          data: { data, status },
        } = await this._makeRequest(queryObject);
        if (status !== 200) {
          return {};
        }
        const propertyDetails = data;
        if (propertyDetails.propertyId != null) {
          return this.n360FieldsService.mapPropertyData(propertyDetails);
        } else {
          return { noData: true };
        }
      }
    };

    this.prototype.spaceSearch = async function ({ propertyId, spaceId }) {
      const queryObject = { type: 'SpaceSearch', params: { propertyId } };
      const {
        data: { data, status },
      } = await this._makeRequest(queryObject);
      if (status !== 200) {
        return { noResponse: true };
      }
      const spaces = data;
      if (!(spaces.length > 0)) {
        return { noData: true };
      }
      const spacesObjectCollection =
        this.n360FieldsService.mapN360AllSpacesData(spaces);
      for (let key = 0; key < spacesObjectCollection.length; key++) {
        const spaceObject = spacesObjectCollection[key];
        const id = spaceObject.listingID;
        const results = await this.getSpaceImages({ id });
        let media = [];
        if (results.noData) {
          media = 'no images available';
        } else {
          media = [];
          for (let result of Array.from(results)) {
            if (this._isNotPdf(result.fileName)) {
              const replacement = {
                id: result.fileId != null ? result.fileId : undefined,
                url:
                  'https://n360.ngkf.com/property-images' +
                  result.imagePath +
                  result.fileName,
                comments:
                  result.description != null ? result.description : undefined,
                fileName: result.fileName?.slice(0, -4),
                extension: result.fileName?.split('.').pop(),
                imageType: result.imageType,
                isPrimary: result.isPrimary,
                userName: undefined,
                dateModified: undefined,
                source: IMAGE_SOURCE_TYPES.PROPERTY_SPACE_IMAGE,
                class: 'Space',
                commercialSearch: false,
                cloudinaryPublicId: undefined,
              };
              media.push(replacement);
            }
          }
          // add media to collection as property
          spacesObjectCollection[key].media = media;
        }
      }
      return spacesObjectCollection;
    };

    this.prototype.getPropertyImages = async function ({ id }) {
      const queryObject = {
        type: 'PropertyImages',
        params: { propertyId: id },
      };
      const {
        data: { data, status },
      } = await this._makeRequest(queryObject);
      if (status !== 200) {
        return {};
      }
      const images = data;
      if (images.length > 0) {
        return images;
      } else {
        return { noData: true };
      }
    };

    this.prototype.getSpaceImages = async function ({ id }) {
      const queryObject = {
        type: 'SpaceImages',
        params: { tenantSpaceId: id },
      };
      const {
        data: { data, status },
      } = await this._makeRequest(queryObject);
      if (status !== 200) {
        return {};
      }
      const images = data;
      if (images.length > 0) {
        return images;
      } else {
        return { noData: true };
      }
    };

    // getGenieImages from Cloudinary
    this.prototype.getGenieImages = async function ({ id }) {
      let queryString =
        'query { genie { retrieveCloudinaryPhotos(propertyId:' + id;
      queryString = queryString + '){ id cloudinaryId comments userName } } }';
      queryString = encodeURIComponent(queryString);
      const queryObject = { type: 'GraphQL', params: { query: queryString } };
      const {
        data: { data, status },
      } = await this._makeRequest(queryObject);
      if (status !== 200) {
        return {};
      }
      const genieImageUrls =
        data.data.genie.retrieveCloudinaryPhotos != null
          ? data.data.genie.retrieveCloudinaryPhotos
          : [];
      if (genieImageUrls.length > 0) {
        return genieImageUrls;
      } else {
        return { noData: true };
      }
    };

    this.prototype.getPropertyMedia = async function ({ id }) {
      const propertyImages = await this.getPropertyImages({ id });
      const genieImages = await this.getGenieImages({ id });
      const allPropertyMedia = [];
      for (let propImage of Array.from(propertyImages)) {
        const imageObject = {
          id: propImage.id != null ? propImage.id : undefined,
          url:
            'https://n360.ngkf.com/property-images' +
            propImage.imagePath +
            propImage.fileName,
          comments:
            propImage.description != null ? propImage.description : undefined,
          fileName: propImage.fileName.slice(0, -4),
          extension: propImage.fileName.split('.').pop(),
          imageType: propImage.imageType,
          isPrimary: propImage.isPrimary,
          userName: propImage.firstName + ' ' + propImage.lastName,
          dateModified: propImage.dateModified,
          source: IMAGE_SOURCE_TYPES.PROPERTY_IMAGE,
          class: 'Property',
          commercialSearch: false,
        };
        allPropertyMedia.push(imageObject);
      }

      for (let img of Array.from(genieImages)) {
        const returnObject = {
          id: img.id != null ? img.id : undefined,
          comments: img.comments != null ? img.comments : undefined,
          fileName: img.imgcloudinaryId,
          userName: img.userName,
          source: IMAGE_SOURCE_TYPES.CLOUDINARY_IMAGES,
          class: 'Property',
          commercialSearch: true,
          cloudinaryPublicId:
            img.cloudinaryPublicId != null
              ? img.cloudinaryPublicId
              : img.cloudinaryId,
        };
        allPropertyMedia.push(returnObject);
      }
      return allPropertyMedia;
    };
  }

  constructor({ ENV: { firebaseFunctionsConfig }, axios }) {
    this._makeRequest = this._makeRequest.bind(this);
    this._isNotPdf = this._isNotPdf.bind(this);
    this.firebaseFunctionsConfig = firebaseFunctionsConfig;
    this.axios = axios;
    ({
      n360FieldsService: this.n360FieldsService,
      auth0Service: this.auth0Service
    } = locateServices([
      'n360FieldsService', 'auth0Service'
    ]));
  }

  async _makeRequest(queryObject) {
    return await this.axios({
      data: queryObject,
      headers: { "Authorization": `Bearer ${this.auth0Service.localStorage.auth0IdToken}` },
      withCredentials: false,
      method: 'post',
      url: `${this.firebaseFunctionsConfig.url}/apiN360`,
    }).catch(err => console.error);
  }

  _extractProperties(hits) {
    const properties = [];
    hits.forEach(function (hit, idx, out) {
      const source = hit._source;
      if (source.propertyName != null && source.propertyName !== '') {
        hit.label = source.propertyName;
      } else {
        if (source.streetAddress != null && source.streetAddress !== '') {
          hit.label = source.streetAddress;
        } else {
          hit.label = '-';
        }
      }
      hit.formatted_address =
        source.streetAddress +
        ', ' +
        source.city +
        ', ' +
        source.state +
        ' ' +
        source.zip +
        ', USA';
      return properties.push(hit);
    });
    return properties;
  }

  _extractSpaces(data) {
    const spaces = data;
    return spaces;
  }

  _isNotPdf(fileSpec) {
    const ext = fileSpec.split('.').pop();
    if (ext === 'pdf') {
      return false;
    } else {
      return true;
    }
  }

  _source() {
    return {
      zip: '11701',
      city: 'Amityville',
      propertySubType: 'Other',
      latitude: 40.675427,
      propertySize: 0,
      propertyTypeId: 5,
      propertyName: '121-125 Park Ave',
      propertySearchText: '121-125 Park Ave',
      streetAddress: '121-125 Park Ave',
      propertyType: 'Multi Housing',
      state: 'NY',
      propertyId: 30005719,
      longitude: -73.417834,
    };
  }
}
N360Service.initClass();

module.exports = N360Service;
