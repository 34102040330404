/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const FieldFormatterService = require('./field_formatter_service');
const TourbookAnalyticsService = require('./tourbook_analytics_service');
const TourbookMetaService = require('./tourbook_meta_service');
const { setServices, stubService } = require('./service_locator');
const mockTourbook = require('@test/resources/tourbook.json');

describe('TourbookAnalyticsService', function () {
  beforeEach(function () {
    const window = {
      location: {
        host: 'bespaceful.com',
        protocol: 'https:',
      },
    };
    this.analyticsService = stubService('analyticsService');
    this.mobileService = stubService('mobileService');
    this.tourbooksService = stubService('tourbooksService');
    this.firebaseService = stubService('firebaseService');
    this.firebaseService.addTask.resolves();
    this.fieldFormatterService = new FieldFormatterService();
    this.mobileService.getUrl.returns('mobile url');
    this.tourbookMetaService = new TourbookMetaService();
    setServices({
      fieldFormatterService: this.fieldFormatterService,
      tourbookMetaService: this.tourbookMetaService,
    });

    return (this.tourbookAnalyticsService = new TourbookAnalyticsService({
      window,
    }));
  });

  describe('track', function () {
    beforeEach(function () {
      this.id = 'tourbook1';
      this.content = {
        cover: {
          contactInfos: {
            contactInfoId1: { name: 'John Broker', type: 'broker' },
            contactInfoId2: { name: 'John Client', type: 'client' },
          },
          fields: {
            date: '2017-02-01T00:00:00.000Z',
            title: 'My City',
          },
        },
        entries: {
          entry1: { some: 'entry data1' },
          entry2: { some: 'entry data2' },
        },
        settings: {
          display: {
            summary: false,
            tourMap: true,
          },
        },
      };
      return (this.timestamps = { created_at: 1485734400000 });
    });

    describe('isOwner is true', () =>
      it('calls analyticsService.track with the proper details', function () {
        this.tourbookAnalyticsService.track('Added Listing', {
          content: this.content,
          id: this.id,
          isOwner: true,
          timestamps: this.timestamps,
        });
        return expect(this.analyticsService.track).to.have.been.calledWith(
          'Added Listing',
          {
            tourbook_contact1_type: 'broker',
            tourbook_contact2_type: 'client',
            tourbook_created_at: '2017-01-30T00:00:00.000Z',
            tourbook_digital_url: 'mobile url',
            tourbook_editor_url: 'https://bespaceful.com/#/tourbook/tourbook1',
            tourbook_id: 'tourbook1',
            tourbook_isOwner: true,
            tourbook_mapOn: true,
            tourbook_name: 'My City',
            tourbook_numListings: 2,
            tourbook_summaryOn: false,
            tourbook_title: 'My City',
            tourbook_tour_date: '2017-02-01T00:00:00.000Z',
          }
        );
      }));

    describe('isOwner is false', () =>
      it('calls analyticsService.track with the proper details', function () {
        this.tourbookAnalyticsService.track('Added Listing', {
          content: this.content,
          id: this.id,
          isOwner: false,
          timestamps: this.timestamps,
        });
        return expect(
          this.analyticsService.track.lastCall.args[1].tourbook_isOwner
        ).to.be.false;
      }));

    describe('with additionalProperties', () =>
      it('calls analyticsService.track with the proper details', function () {
        const additionalProperties = { key1: 'value1', key2: 'value2' };
        this.tourbookAnalyticsService.track('Added Listing', {
          additionalProperties,
          content: this.content,
          id: this.id,
          isOwner: false,
          timestamps: this.timestamps,
        });
        return expect(this.analyticsService.track.lastCall.args[1]).to.include({
          key1: 'value1',
          key2: 'value2',
        });
      }));

    describe('with entryId', function () {
      beforeEach(function () {
        return (this.content.entries = {
          entry1: {
            fields: {
              header: {
                CorrectedAddress1: '123 My Street',
                city: 'My City',
                state: 'My State',
                zip: '45678',
              },
            },
            type: 'Lease',
          },
        });
      });

      describe('with meta', function () {
        beforeEach(function () {
          return (this.content.entries.entry1.meta = {
            originalAddressFields: {
              CorrectedAddress1: '123 My St',
              city: 'My City',
              state: 'My State',
              zip: '45678',
            },
            source: 'new',
          });
        });

        return it('calls analyticsService.track with the proper details', function () {
          this.tourbookAnalyticsService.track('Added Listing', {
            content: this.content,
            entryId: 'entry1',
            id: this.id,
            isOwner: false,
            timestamps: this.timestamps,
          });
          return expect(
            this.analyticsService.track.lastCall.args[1]
          ).to.include({
            listing_address: '123 My Street, My City, My State 45678',
            listing_original_address: '123 My St, My City, My State 45678',
            listing_source: 'new',
            listing_type: 'Lease',
          });
        });
      });

      describe('with source only', function () {
        beforeEach(function () {
          return (this.content.entries.entry1.meta = {
            source: 'previous tour book',
          });
        });

        return it('calls analyticsService.track with the proper details', function () {
          this.tourbookAnalyticsService.track('Added Listing', {
            content: this.content,
            entryId: 'entry1',
            id: this.id,
            isOwner: false,
            timestamps: this.timestamps,
          });
          return expect(
            this.analyticsService.track.lastCall.args[1]
          ).to.include({
            listing_address: '123 My Street, My City, My State 45678',
            listing_original_address: null,
            listing_source: 'previous tour book',
            listing_type: 'Lease',
          });
        });
      });

      return describe('without meta', () =>
        it('calls analyticsService.track with the proper details', function () {
          this.tourbookAnalyticsService.track('Added Listing', {
            content: this.content,
            entryId: 'entry1',
            id: this.id,
            isOwner: false,
            timestamps: this.timestamps,
          });
          return expect(
            this.analyticsService.track.lastCall.args[1]
          ).to.include({
            listing_address: '123 My Street, My City, My State 45678',
            listing_original_address: null,
            listing_source: null,
            listing_type: 'Lease',
          });
        }));
    });

    describe('no cover fields', function () {
      beforeEach(function () {
        return delete this.content.cover.fields;
      });

      return it('does not error', function () {
        this.tourbookAnalyticsService.track('Added Listing', {
          content: this.content,
          id: this.id,
          isOwner: true,
          timestamps: this.timestamps,
        });
        return expect(this.analyticsService.track.lastCall.args[1]).to.include({
          tourbook_name: 'Untitled Book',
          tourbook_title: '',
          tourbook_tour_date: '',
        });
      });
    });

    return it('uses the given trackFn instead of analyticsService.track if provided', function () {
      const trackFn = sinon.stub();
      this.tourbookAnalyticsService.track(
        'Added Listing',
        {
          content: this.content,
          id: this.id,
          isOwner: true,
          timestamps: this.timestamps,
        },
        trackFn
      );
      expect(this.analyticsService.track).to.not.have.been.called;
      return expect(trackFn).to.have.been.called;
    });
  });

  describe('trackTourbookEvent', function () {
    beforeEach(function () {
      this.targetUser = 'owner';
      this.tourbook = {
        owner_id: 'owner',
        content: {
          bar: 1,
        },
        timestamps: {
          created_at: 0,
          updated_at: 0,
        },
      };
      this.tourbooksService.get.withArgs('tourbook-1').resolves(this.tourbook);
      this.tourbookAnalyticsService.track = sinon.stub();
      this.additionalProperties = {
        foo: 1,
        bar: 1,
      };
      return this.runTrack = async function() {
        return await this.tourbookAnalyticsService.trackTourbookEvent(
          'mixpanel event description',
          {
            tourbookId: 'tourbook-1',
            targetUser: this.targetUser,
            category: 'category',
            additionalProperties: this.additionalProperties,
          }
        );
      };
    });

    describe('for passing properties', function () {
      beforeEach(
        async function() {
          return await this.runTrack();
        }
      );

      it('calls tourbookAnalyticsService.track with the same event description', function () {
        return expect(
          this.tourbookAnalyticsService.track.lastCall.args[0]
        ).to.eql('mixpanel event description');
      });

      it('calls tourbookAnalyticsService.track with the appropriate tourbook content', function () {
        return expect(
          this.tourbookAnalyticsService.track.lastCall.args[1]
        ).to.contain({
          content: this.tourbook.content,
        });
      });

      it('calls tourbookAnalyticsService.track with the specified category', function () {
        return expect(
          this.tourbookAnalyticsService.track.lastCall.args[1]
            .additionalProperties
        ).to.contain({
          category: 'category',
        });
      });

      it('calls tourbookAnalyticsService.track with the appropriate timestamps', function () {
        return expect(
          this.tourbookAnalyticsService.track.lastCall.args[1]
        ).to.contain({
          timestamps: this.tourbook.timestamps,
        });
      });

      return it('calls tourbookAnalyticsService.track with the additionalProperties if included', function () {
        return expect(
          this.tourbookAnalyticsService.track.lastCall.args[1]
            .additionalProperties
        ).to.contain({
          foo: 1,
          bar: 1,
        });
      });
    });

    describe('when the user is the owner', function () {
      beforeEach(
        async function() {
          this.targetUser = 'owner';
          return await this.runTrack();
        }
      );

      return it('calls tourbookAnalyticsService.track with isOwner set to true', function () {
        return expect(
          this.tourbookAnalyticsService.track.lastCall.args[1]
        ).to.contain({ isOwner: true });
      });
    });

    describe('when the user is not the owner', function () {
      beforeEach(
        async function() {
          this.targetUser = 'not-owner';
          return await this.runTrack();
        }
      );

      return it('calls tourbookAnalyticsService.track with isOwner set to false', function () {
        return expect(
          this.tourbookAnalyticsService.track.lastCall.args[1]
        ).to.contain({ isOwner: false });
      });
    });

    describe('when a tourbook is provided', function () {
      it(
        'does not call tourbookService.get',
        async function() {
          expect(this.tourbooksService.get).to.not.have.been.called;
          await this.tourbookAnalyticsService.trackTourbookEvent(
            'mixpanel event description',
            {
              tourbookId: 'tourbook-1',
              tourbook: this.tourbook,
              targetUser: 'owner',
            }
          );
          return expect(this.tourbooksService.get).to.not.have.been.called;
        }
      );

      return it('tracks synchronously on the same event frame', function () {
        expect(this.tourbookAnalyticsService.track).to.not.have.been.called;
        this.tourbookAnalyticsService.trackTourbookEvent(
          'mixpanel event description',
          {
            tourbookId: 'tourbook-1',
            tourbook: this.tourbook,
            targetUser: 'owner',
          }
        );
        return expect(this.tourbookAnalyticsService.track).to.have.been.called;
      });
    });

    return it(
      'passes the given trackFn to tourbookAnalyticsService.track if provided',
      async function() {
        const options = {
          tourbookId: 'tourbook-1',
          targetUser: 'owner',
        };
        const trackFn = sinon.stub();
        await this.tourbookAnalyticsService.trackTourbookEvent(
          'mixpanel event description',
          options,
          trackFn
        );
        return expect(
          this.tourbookAnalyticsService.track.lastCall.args[2]
        ).to.eql(trackFn);
      }
    );
  });

  return describe('trackTourbookEventOnBackend', function () {
    beforeEach(function () {
      this.tourbooksService.get.withArgs('tourbook-1').resolves(mockTourbook);
      this.options = {
        tourbookId: 'tourbook-1',
        targetUser: 'targetUser',
        currentUser: 'owner',
        category: 'category',
      };
      return this.runTrack = async function() {
        return await this.tourbookAnalyticsService.trackTourbookEventOnBackend(
          'Event Name here',
          this.options
        );
      };
    });

    it(
      'adds a track task to the firebase queue',
      async function() {
        await this.runTrack();
        expect(this.firebaseService.addTask).to.be.calledOnce;
        return expect(this.firebaseService.addTask.lastCall.args[0]).to.contain(
          {
            type: 'track',
            createdBy: 'owner',
            event: 'Event Name here',
            userId: 'targetUser',
          }
        );
      }
    );

    return it(
      'includes same properties as client-side track',
      async function() {
        await this.runTrack();
        const backendTrackProperties = JSON.parse(
          this.firebaseService.addTask.lastCall.args[0].properties
        );
        await this.tourbookAnalyticsService.trackTourbookEvent(
          'Event Name here',
          this.options
        );
        const frontendTrackProperties =
          this.analyticsService.track.lastCall.args[1];
        return expect(backendTrackProperties).to.eql(frontendTrackProperties);
      }
    );
  });
});
