const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const store = require('@store');
const _ = require('lodash');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

let isSecretHintShown = false;

class NotesInput extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      keyboardShortcutService: PropTypes.object.isRequired,
      updateChild: PropTypes.func.isRequired,
      value: PropTypes.object,
      hideLock: PropTypes.bool,
      titleOverride: PropTypes.string,
    };
  }

  componentDidMount() {
    if (this.props.active) {
      return this.input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active === this.props.active) {
      return;
    }
    const fnName = this.props.active ? 'focus' : 'blur';
    return this.input[fnName]();
  }

  componentWillMount() {
    if (this.props.active) {
      return this._reassignShortcuts();
    }
  }

  componentWillUnmount() {
    return this._restoreShortcuts();
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.active !== nextProps.active) {
      if (nextProps.active) {
        return this._reassignShortcuts();
      } else {
        return this._restoreShortcuts();
      }
    }
  }

  render() {
    return (
      <div className={cx(['root', this.props.titleOverride === 'Private Notes' ? 'private-page' : ''])}>
        <div className={cx(['header'])}>
          {this.props.titleOverride ? (
            <label>{this.props.titleOverride}</label>
          ) : (
            <label>Notes</label>
          )}
          {!this.props.hideBullets && this._renderBulletsToggle()}
        </div>
        <div className={cx(['input-wrapper'])}>
          {!this.props.hideHint && this._renderHelpMessage()}
          {this._renderTextarea()}
          {!this.props.hideLock && this._renderSecretToggle()}
          {this._renderSecretHint()}
        </div>
      </div>
    );
  }

  _hasBullets() {
    return this.props.value?.bullets;
  }

  _isSecret() {
    return this.props.value?.secret;
  }

  _setSecretHintShown(argVal) {
    return (isSecretHintShown = argVal);
  }

  _reassignShortcuts() {
    return (this.restoreShortcutsFn =
      this.props.keyboardShortcutService.reassign('13', 'shift-13'));
  }

  _renderBulletsToggle() {
    const toggleProps = {
      className: cx([
        'bullets-toggle',
        `${this._hasBullets() ? 'checked' : ''}`,
      ]),
      onClick: () => this.props.updateChild('bullets', !this._hasBullets()),
    };
    return (
      <div {...toggleProps}>
        <FontAwesomeIcon name="list-ul" />
      </div>
    );
  }

  _renderHelpMessage() {
    let msg = 'to add a ';
    msg += this._hasBullets() ? 'bullet' : 'paragraph';
    msg += ', press ';
    return (
      <div className={cx(['help'])}>
        <span>{msg}</span>
        <span className={cx(['green'])}>Enter</span>
      </div>
    );
  }

  _renderTextarea() {
    return (
      <textarea
        onChange={() => this.props.updateChild('content', this.input.value)}
        placeholder="Enter notes here..."
        ref={(node) => {
          return (this.input = node);
        }}
        value={this.props.value?.content || ''}
      />
    );
  }

  _renderSecretToggle() {
    let parentSecret = false;
    if (this.props?.value?.type === 'additional_note') {
      const state = store.getState();
      const parentPath = this.props.path
        .slice(0, 3) // path to entry
        .concat(['fields', 'notes', 'notes']);
      const parentNote = _.get(state.tourbookEditorPage.data, parentPath);
      parentSecret = parentNote?.secret;
    }

    const secretHintProps = {
      onMouseEnter: () => _setSecretHintShown(true),
      onMouseLeave: () => _setSecretHintShown(false),
    };
    const toggleProps = {
      className: cx([
        'secret-toggle',
        `${this._isSecret() ? 'checked' : ''}`,
        `${parentSecret ? 'disabled' : ''}`,
      ]),
      onClick: parentSecret
        ? () => {}
        : () => this.props.updateChild('secret', !this._isSecret()),
    };
    return (
      <div {...toggleProps}>
        <FontAwesomeIcon name="lock" />
      </div>
    );
  }

  _renderSecretHint() {
    const secretState = this._isSecret();
    if (secretState) {
      return (
        <div className={cx(['secret-hint'])}>
          This note has been marked private and only shared with collaborators.
        </div>
      );
    }
  }

  _restoreShortcuts() {
    if (typeof this.restoreShortcutsFn === 'function') {
      this.restoreShortcutsFn();
    }
    return delete this.restoreShortcutsFn;
  }
}
NotesInput.initClass();

module.exports = NotesInput;
