const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const MapView = require('./');

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ..._.pick(actions.tourbookEditorPage, ['update']),
    dispatch,
    resetMap: (props) => {
      dispatch(
        props.update({
          path: ['content', 'settings', props.mapOptionsKey, 'center'],
          value: null,
        })
      );
      dispatch(
        props.update({
          path: ['content', 'settings', props.mapOptionsKey, 'zoom'],
          value: 3,
        })
      );
    },
    onMoveEnd: (props, newCenter) => {
      dispatch(
        props.update({
          path: ['content', 'settings', props.mapOptionsKey, 'center'],
          value: { latitude: newCenter.lat, longitude: newCenter.lng },
        })
      );
    },
    onZoomChange: (props, newZoom) => {
      dispatch(
        props.update({
          path: ['content', 'settings', props.mapOptionsKey, 'zoom'],
          value: newZoom,
        })
      );
    },
  };
};

const mapStateToProps = function (state) {
  const { content } = state.tourbookEditorPage.data;
  return {
    entries: content.entries,
    settings: content.settings,
  };
};

module.exports = build({
  component: MapView,
  mapDispatchToProps,
  mapStateToProps,
});
