let auth0, axios, firebaseDefaultApp;
const _ = require('lodash');
const $ = require('jquery');
const { initializeApp } = require('firebase/app');

if (process.env.NODE_ENV === 'integration_test') {
  const mockDependencies = require('../../test/mock_dependencies');
  ({ auth0, axios, firebaseDefaultApp } = mockDependencies);
  _.assign(global, mockDependencies.globalExtensions);
  _.assign(window, mockDependencies.window);
} else {
  // browser environment
  auth0 = require('auth0-js');
  axios = require('axios');

  auth0 = new auth0.WebAuth({
    clientID: ENV.auth0Config.clientId,
    domain: ENV.auth0Config.domain,
    responseType: 'token id_token',
    device: 'my-device',
    scope: 'openid offline_access profile email',
    redirectUri: window.location.href,
  });
  firebaseDefaultApp = initializeApp(ENV.firebaseConfig.default, 'default');
}
window.onerror = (errorMsg, url, lineNumber, colNo, error) => {
  window.analytics.track(errorMsg, { url, lineNumber, colNo, error });
  return false;
};
module.exports = {
  $,
  ENV: ENV,
  auth0,
  axios,
  document,
  firebaseDefaultApp,
  localStorage,
  window,
};
