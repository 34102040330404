const _ = require('lodash');
const { locateService } = require('@services/service_locator');

class PageNumberService {
  constructor() {
    this.pdfService = locateService('pdfService');
  }

  getAmenitiesPageNumber({ entries, entryId, tourbook }) {
    const entryPageNumber = this.getEntryPageNumber({
      entries,
      entryId,
      tourbook,
    });
    const imagePageCount = this._getImagePageCount(entries[entryId]);
    return entryPageNumber + imagePageCount + 1;
  }

  _getEntryPageCount(entry) {
    const imagePageCount = this._getImagePageCount(entry);
    const amenityPageCount = this._getAmenityPageCount(entry);
    const entryPageCount = 1;
    return entryPageCount + imagePageCount + amenityPageCount;
  }

  _getAmenityPageCount(entry) {
    if (!entry.pages?.amenities) {
      return 0;
    }
    const { amenities } = entry.pages;
    const amenityPageWillShow = _.some(amenities, (amenity) =>
      this._amenityHasData(amenity)
    );
    if (amenityPageWillShow) {
      return 1;
    } else {
      return 0;
    }
  }

  _amenityHasData(amenity) {
    return amenity.noData === undefined || amenity.noData === false;
  }

  getEntryPageNumber({ entries, entryId, tourbook }) {
    const introPages = this._getIntroPagesCount(tourbook);
    return this._getPreviousEntriesPageCount(entries, entryId) + introPages + 1;
  }

  getImagePageNumber({ entries, entryId, imagePageId, tourbook }) {
    const entryPageNumber = this.getEntryPageNumber({
      entries,
      entryId,
      tourbook,
    });
    const withoutSkipped = _.pickBy(
      entries[entryId].pages.images,
      (imagesPage) =>
        imagesPage.type !== 'skipped' &&
        this.pdfService.shouldPageBeIncludedInPdf(imagesPage, entries[entryId])
    );
    const imagePageIds = Object.keys(withoutSkipped);
    return entryPageNumber + imagePageIds.indexOf(imagePageId) + 1;
  }

  _getIntroPagesCount(tourbook) {
    const coverPageCount = 1;
    const mapPageCount = this._getMapPageCount(tourbook);
    let summaryPageCount = 0;
    if (tourbook.content?.settings?.display?.summary) {
      summaryPageCount = this._getSummaryPageCount(tourbook.content.entries);
    }
    return coverPageCount + mapPageCount + summaryPageCount;
  }

  _getPreviousEntriesPageCount(entries, entryId) {
    const orderIndexOfEntry = entries[entryId].order;
    return _.chain(entries)
      .filter((entry) => entry.order < orderIndexOfEntry && !entry.hidden)
      .sumBy((entry) => this._getEntryPageCount(entry))
      .value();
  }

  _getSummaryPageCount(entries) {
    const nonSummaryEntryKeys = [
      'tourbook-notes',
      'tourbook-floorplan',
      'tourbook-images',
    ];
    return (
      Math.floor(
        (_.size(
          _.filter(entries, (entry) => !nonSummaryEntryKeys.includes(entry.key) && !entry.hidden)
        ) -
          1) /
          4
      ) + 1
    );
  }

  getSummaryPageNumber(offset, tourbook) {
    const coverPageCount = 1;
    const additionalBrokerPageCount = this._getAdditionalBrokerPageCount(tourbook);
    const mapPageCount = this._getMapPageCount(tourbook);
    const summaryPageOffset = Math.floor(offset / 4);
    return coverPageCount + additionalBrokerPageCount + mapPageCount + summaryPageOffset + 1;
  }

  _getImagePageCount(entry) {
    return _.size(
      _.filter(
        entry.pages?.images,
        (imagesPage) =>
          imagesPage.type !== 'skipped' &&
          this.pdfService.shouldPageBeIncludedInPdf(imagesPage, entry)
      )
    );
  }

  _getAdditionalBrokerPageCount(tourbook) {
    return tourbook.content.broker?.brokerInfos ? 1 : 0;
  }

  _getMapPageCount(tourbook) {
    if (tourbook.content?.settings?.display?.tourMap) {
      return Object.keys(tourbook.content?.settings)
        .filter((key) => key.startsWith('mapOptions'))
        .filter((key) => !tourbook.content.settings[key].hidden).length;
    } else {
      return 0;
    }
  }
}

module.exports = PageNumberService;
