/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const DocraptorApiService = require('./docraptor_api_service');
const { stubService } = require('./service_locator');

describe('DocraptorApiService', function () {
  beforeEach(function () {
    this.ENV = {
      docraptorConfig: {
        apiKey: '123',
        test: 'test config',
      },
    };
    this.axios = sinon.stub();
    this.window = {
      location: {
        toString() {
          return 'location';
        },
      },
    };
    this.docraptorHtmlService = stubService('docraptorHtmlService');
    this.docraptorHtmlService.transform.returns('transformed html');

    return (this.docraptorApiService = new DocraptorApiService({
      ENV: this.ENV,
      axios: this.axios,
      window: this.window,
    }));
  });

  describe('getOptions', () =>
    it('returns the options to pass to docraptor', function () {
      return expect(
        this.docraptorApiService.getOptions('html', 'title')
      ).to.eql({
        doc: {
          async: true,
          document_content: 'transformed html',
          document_type: 'pdf',
          javascript: true,
          name: 'title.pdf',
          prince_options: {
            css_dpi: 72,
            media: 'screen',
          },
          referrer: 'location',
          strict: 'none',
          test: 'test config',
        },
      });
    }));

  return describe('generatePdf', function () {
    beforeEach(function () {
      return (this.docraptorApiService.statusPollDelay = 0);
    });

    describe('completes', function () {
      beforeEach(function () {
        return this.axios
          .onCall(0)
          .resolves({ data: { status_id: 'status1' } })
          .onCall(1)
          .resolves({ data: { status: 'working' } })
          .onCall(2)
          .resolves({ data: { status: 'working' } })
          .onCall(3)
          .resolves({
            data: { download_url: 'docraptor url', status: 'completed' },
          });
      });

      it(
        'makes the proper generate call to docraptor',
        async function() {
          await this.docraptorApiService.generatePdf('html', 'title');
          return expect(this.axios.firstCall.args[0]).eql({
            data: {
              doc: {
                async: true,
                document_content: 'transformed html',
                document_type: 'pdf',
                javascript: true,
                name: 'title.pdf',
                prince_options: {
                  css_dpi: 73,
                  media: 'screen',
                },
                referrer: 'location',
                strict: 'none',
                test: 'test config',
              },
            },
            method: 'POST',
            url: 'https://docraptor.com/docs?user_credentials=123',
          });
        }
      );

      it(
        'makes the proper status calls to docraptor',
        async function() {
          await this.docraptorApiService.generatePdf('html', 'title');
          return [1, 2, 3].forEach((i) => {
            return expect(this.axios.getCall(i).args[0]).eql({
              method: 'GET',
              url: 'https://docraptor.com/status/status1?user_credentials=123',
            });
          });
        }
      );

      return it(
        'returns the url',
        async function() {
          const result = await this.docraptorApiService.generatePdf(
            'html',
            'title'
          );
          return expect(result).to.eql('docraptor url');
        }
      );
    });

    return describe('fails', function () {
      beforeEach(function () {
        return this.axios
          .onCall(0)
          .resolves({ data: { status_id: 'status1' } })
          .onCall(1)
          .resolves({ data: { status: 'working' } })
          .onCall(2)
          .resolves({ data: { status: 'working' } })
          .onCall(3)
          .resolves({
            data: { status: 'failed', validation_errors: ['some errors'] },
          });
      });

      return it(
        'returns the url',
        async function() {
          let error = null;
          try {
            await this.docraptorApiService.generatePdf('html', 'title');
          } catch (e) {
            error = e;
          }
          expect(error).to.exist;
          return expect(error.message).to.eql(
            'PDF Generation failed: some errors'
          );
        }
      );
    });
  });
});
