const { default: AutoScale } = require('react-auto-scale');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const InputRowContainer = require('@components/shared/input_row/container');
const {
  getBrokerContactInfos,
  getBrokerLogo,
} = require('@utils/tourbook_cover_parser');
const PdfEntryTemplateContainer = require('../pdf_entry_template/container');
const ReadyRegistry = require('@utils/ready_registry');
const { FONT_SIZE_DEFAULT, LINE_SPACING_DEFAULT } = require("@const");

class SettingsView extends React.Component {
  static initClass() {
    this.propTypes = {
      cover: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      settings: PropTypes.object.isRequired,
      track: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    };
  }

  componentDidMount() {
    this.addDefaultValues();
    return this.props.track('Views Tour Book Settings');
  }

  addDefaultValues() {
    if (!this.props.settings.fontSize) {
      this.props.update({
        path: ['content', 'settings', 'fontSize'],
        value: FONT_SIZE_DEFAULT,
      });
    }
    if (!this.props.settings.lineSpacing) {
      this.props.update({
        path: ['content', 'settings', 'lineSpacing'],
        value: LINE_SPACING_DEFAULT,
      });
    }
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <div className={cx(['inputs'])}>
          <div className={cx(['input-section', 'privacy'])}>
            <div className={cx(['input-title'])}>Privacy</div>
            <div className={cx(['input-rows'])}>
              <InputRowContainer
                active={false}
                shouldAddColonToLabel={true}
                shouldDisplayHint={false}
                input={{
                  label: 'Confidential',
                  path: ['content', 'settings', 'confidential'],
                  type: 'toggle',
                }}
              />
            </div>
            <span className={cx(['text-info'])}>
              {this.props.settings.confidential ? <p>The listings within this tourbook are confidential and can not be found by other Spaceful users.</p> :
                <p>The listings within this tourbook are not confidential and can be used by other Spaceful users.</p>}
            </span>
          </div>
          <div className={cx(['input-section', 'appearance'])}>
            <div className={cx(['input-title'])}>Appearance</div>
            <div className={cx(['input-rows'])}>
              <InputRowContainer
                active={false}
                shouldAddColonToLabel={true}
                shouldDisplayHint={false}
                input={{
                  label: 'Accent Color',
                  path: ['content', 'settings', 'accentColor'],
                  type: 'color',
                }}
              />
              <InputRowContainer
                active={false}
                shouldAddColonToLabel={true}
                shouldDisplayHint={false}
                input={{
                  label: 'Font Size',
                  path: ['content', 'settings', 'fontSize'],
                  type: 'slider',
                }}
              />
              <InputRowContainer
                active={false}
                shouldAddColonToLabel={true}
                shouldDisplayHint={false}
                input={{
                  label: 'Condensed Line Spacing',
                  path: ['content', 'settings', 'lineSpacing'],
                  type: 'toggle',
                }}
              />
            </div>
          </div>
          <div className={cx(['input-section', 'footer'])}>
            <div className={cx(['input-title'])}>Footer</div>
            <div className={cx(['input-rows'])}>
              {this._getFooterInputs().map((input, index) => (
                <InputRowContainer
                  active={false}
                  input={input}
                  key={index}
                  shouldAddColonToLabel={false}
                  shouldDisplayHint={false}
                />
              ))}
            </div>
          </div>
          <div className={cx(['input-section', 'full-pages'])}>
            <div className={cx(['input-title'])}>Full Pages</div>
            <div className={cx(['input-rows'])}>
              {this._getFullPagesInputs().map((input, index) => (
                <InputRowContainer
                  active={false}
                  input={input}
                  key={index}
                  shouldAddColonToLabel={false}
                  shouldDisplayHint={false}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={cx(['pdf-template-wrapper'])}>
          <AutoScale>
            <PdfEntryTemplateContainer {...this._getPdfEntryProps()} />
          </AutoScale>
        </div>
      </div>
    );
  }

  _getFooterInputs() {
    return [
      {
        label: 'Display Logo',
        options: {
          isHidden: !getBrokerLogo(this.props.cover),
          messageIfHidden: 'You must add a Broker Logo to the Cover first',
        },
        path: ['content', 'settings', 'display', 'footerLogo'],
        type: 'toggle',
      },
      {
        label: "Display Broker's Contact",
        options: {
          isHidden: getBrokerContactInfos(this.props.cover).length === 0,
          messageIfHidden:
            'You must add Broker Contact Info to the Cover first',
        },
        path: ['content', 'settings', 'display', 'footerText'],
        type: 'toggle',
      },
      {
        label: 'Show Page Numbers',
        path: ['content', 'settings', 'display', 'pageNumbers'],
        type: 'toggle',
      },
    ];
  }

  _getFullPagesInputs() {
    return [
      {
        label: 'Display Map Page',
        path: ['content', 'settings', 'display', 'tourMap'],
        type: 'toggle',
      },
      {
        label: 'Display Summary Page',
        path: ['content', 'settings', 'display', 'summary'],
        type: 'toggle',
      },
    ];
  }

  _getPdfEntryProps() {
    return {
      entryId: this.props.entryId,
      readyRegistry: new ReadyRegistry(),
    };
  }
}
SettingsView.initClass();

module.exports = SettingsView;
