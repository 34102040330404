/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const md5 = require('blueimp-md5');
const UsersService = require('./users_service');
const { Promise } = require('bluebird');
const { setServices } = require('./service_locator');

describe('UsersService', function () {
  beforeEach(function () {
    this.firebaseService = {
      getServerTimestamp: sinon.stub().returns(100),
      updateValues: sinon.stub(),
      getValue: sinon.stub().resolves(),
    };
    this.tourbook = {
      owner_id: '',
      shared: {},
    };
    this.tourbooksService = { get: () => Promise.resolve(this.tourbook) };
    this.userId = 'user1';
    this.tourbookId = 'tid';
    setServices({
      firebaseService: this.firebaseService,
      tourbooksService: this.tourbooksService,
    });

    return (this.service = new UsersService());
  });

  describe('addPreviousFields', function () {
    beforeEach(function () {
      return this.service.addPreviousFields('user1', [
        { key: 'field1' },
        { key: 'field2', label: 'label2' },
      ]);
    });

    return it('saves the previous fields to firebase', function () {
      return expect(this.firebaseService.updateValues).to.be.calledWith(
        'userPreviousCustomFields/user1',
        {
          field1: { label: null, lastUsed: 100 },
          field2: { label: 'label2', lastUsed: 100 },
        }
      );
    });
  });

  describe('canEdit', function () {
    describe('user is not admin', function () {
      beforeEach(function () {
        return (this.service.isAdmin = sinon.stub().resolves(false));
      });

      describe('tourbook is not owned by the user', () =>
        it('resolves to false', function () {
          return expect(
            this.service.canEdit({
              tourbookId: this.tourbookId,
              userId: this.userId,
            })
          ).to.eventually.eql(false);
        }));

      describe('tourbook is owned by the user', function () {
        beforeEach(function () {
          return (this.tourbook.owner_id = 'user1');
        });

        return it('resolves to true', function () {
          return expect(
            this.service.canEdit({
              tourbookId: this.tourbookId,
              userId: this.userId,
            })
          ).to.eventually.eql(true);
        });
      });

      describe('tourbook is not shared with the user', () =>
        it('resolves to false', function () {
          return expect(
            this.service.canEdit({
              tourbookId: this.tourbookId,
              userId: this.userId,
            })
          ).to.eventually.eql(false);
        }));

      return describe('tourbook is shared with the user', function () {
        beforeEach(function () {
          return (this.tourbook.shared['user1'] = { email: 'test@test.com' });
        });

        return it('resolves to true', function () {
          return expect(
            this.service.canEdit({
              tourbookId: this.tourbookId,
              userId: this.userId,
            })
          ).to.eventually.eql(true);
        });
      });
    });

    return describe('user is an admin', function () {
      beforeEach(function () {
        return (this.service.isAdmin = sinon.stub().resolves(true));
      });

      return it('resolves to true', function () {
        return expect(
          this.service.canEdit({
            tourbookId: this.tourbookId,
            userId: this.userId,
          })
        ).to.eventually.eql(true);
      });
    });
  });

  describe('getMostRecentlyCreatedTourbook', function () {
    it(
      'returns undefined when no tourbook exists',
      async function() {
        this.firebaseService.getValue
          .withArgs('userTourbooks/user-1')
          .resolves(undefined);
        const tourbook = await this.service.getMostRecentlyCreatedTourbook(
          'user-1'
        );
        return expect(tourbook).to.eql(undefined);
      }
    );

    return it(
      'returns most recent tourbook',
      async function() {
        this.firebaseService.getValue
          .withArgs('userTourbooks/user-1')
          .resolves({
            'a_ tid': {},
            tid: {},
          });
        this.firebaseService.getValue
          .withArgs('tourbooks/tid')
          .resolves(this.tourbook);
        const tourbook = await this.service.getMostRecentlyCreatedTourbook(
          'user-1'
        );
        return expect(tourbook).to.be.eql(this.tourbook);
      }
    );
  });

  describe('validateEmail', () =>
    it('validates email properly', function () {
      expect(this.service.validateEmail('foobar@test.com')).to.be.true;
      expect(this.service.validateEmail('foo.bar@test.com')).to.be.true;
      expect(this.service.validateEmail('foo.bar@test')).to.be.false;
      expect(this.service.validateEmail('foo@bar@test')).to.be.false;
      return expect(this.service.validateEmail('foo@test')).to.be.false;
    }));

  return describe('getUserIdsByEmail', function () {
    it(
      'makes the right firebase call',
      async function() {
        await this.service.getUserIdsByEmail('foobar@test.com');
        const hash = md5('foobar@test.com');
        return expect(this.firebaseService.getValue).to.be.calledWith(
          `userEmailMap/${hash}`
        );
      }
    );

    return it(
      'lowercases the email address',
      async function() {
        await this.service.getUserIdsByEmail('FOObar@test.com');
        const hash = md5('foobar@test.com');
        return expect(this.firebaseService.getValue).to.be.calledWith(
          `userEmailMap/${hash}`
        );
      }
    );
  });
});
