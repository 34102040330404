const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const EntryImagesInput = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentModal',
  'setIsDraggingImage',
  'track',
  'update',
]);

const mapStateToProps = function (state, ownProps) {
  const entryPath = _.initial(ownProps.path);
  const { tourbookId } = state.tourbookEditorPage;
  const entryId = _.last(entryPath);
  const entry = _.get(state.tourbookEditorPage.data, entryPath);
  return {
    defaultStreetviewSettings:
      state.tourbookEditorPage.entryDefaultStreetviewSettings?.[entryId],
    digitalTourbookEntryImages:
      state.tourbookEditorPage.digitalTourbookEntryImages?.[entryId],
    entry,
    entryId,
    tourbookId,
    entryPath,
  };
};

const propsTypes = { path: PropTypes.array };

module.exports = build({
  component: EntryImagesInput,
  mapDispatchToProps,
  mapStateToProps,
  propsTypes,
  serviceNames: ['cloudinaryService', 'tourbookEntryImagesService'],
});
