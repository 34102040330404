/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require('lodash');
const TourbookEntryImagesService = require('./tourbook_entry_images_service');
const CloudinaryService = require('./cloudinary_service');
const { buildMockService } = require('@test/mock_service_helpers');
const { setService } = require('./service_locator');

const createImage = (id) => ({
  cloudinaryPublicId: id,
  type: 'image',
});

describe('TourbookEntryImagesService', function () {
  beforeEach(function () {
    this.cloudinaryService = new CloudinaryService({
      ENV: {
        cloudinaryConfig: {
          defaultCloud: 'default',
          listingCloud: 'listing',
        },
        webtaskConfig: {
          url: 'webtask.url',
        },
      },
    });
    setService('cloudinaryService', this.cloudinaryService);
    this.googleAddressService = buildMockService('googleAddressService');
    setService('googleAddressService', this.googleAddressService);
    return (this.service = new TourbookEntryImagesService());
  });

  describe('getSummaryImageUrl', function () {
    beforeEach(function () {
      this.cloudinaryService.getSummaryUrl = sinon
        .stub()
        .withArgs({ cloudinaryPublicId: 'image1', type: 'image' })
        .returns('url');
      return (this.entry = { imagesShown: [createImage('image1')] });
    });

    return it('gets the url for the first entry image', function () {
      return expect(this.service.getSummaryImageUrl(this.entry)).to.eql('url');
    });
  });

  describe('getDeletePaths', function () {
    beforeEach(function () {
      return (this.entry = {
        images: {
          image1: createImage('image1'),
        },
      });
    });

    describe('images not used in the PDF', () =>
      it('returns the update to remove it from images', function () {
        return expect(
          this.service.getDeleteImagePaths(this.entry, 'image1')
        ).to.eql([['images', 'image1']]);
      }));

    describe('images on the main page', function () {
      beforeEach(function () {
        return (this.entry.imagesShown = [
          createImage('image1'),
          createImage('image2'),
          createImage('image3'),
        ]);
      });

      return it('returns the update to remove it from images and the main page', function () {
        return expect(
          this.service.getDeleteImagePaths(this.entry, 'image1')
        ).to.eql([
          ['imagesShown', 0],
          ['images', 'image1'],
        ]);
      });
    });

    describe('images on the extra pages', function () {
      beforeEach(function () {
        return (this.entry.pages = {
          images: {
            page1: {
              imagesShown: [
                createImage('image1'),
                createImage('image2'),
                createImage('image3'),
                createImage('image4'),
              ],
            },
          },
        });
      });

      return it('returns the update to remove it from images and the extra page', function () {
        return expect(
          this.service.getDeleteImagePaths(this.entry, 'image1')
        ).to.eql([
          ['pages', 'images', 'page1', 'imagesShown', 0],
          ['images', 'image1'],
        ]);
      });
    });

    return describe('images on the amenities page', function () {
      beforeEach(function () {
        return (this.entry.pages = {
          amenities: {
            bottom_left: { type: 'image' },
            imagesShown: {
              bottom_left: createImage('image1'),
            },
          },
        });
      });

      return it('returns the update to remove it from images and the amenities page', function () {
        return expect(
          this.service.getDeleteImagePaths(this.entry, 'image1')
        ).to.eql([
          ['pages', 'amenities', 'imagesShown', 'bottom_left'],
          ['images', 'image1'],
        ]);
      });
    });
  });

  describe('getImagesInPDF', function () {
    beforeEach(function () {
      return (this.entry = {});
    });

    describe('images on the main page', function () {
      beforeEach(function () {
        return (this.entry.imagesShown = [
          createImage('image1'),
          createImage('image2'),
          createImage('image3'),
        ]);
      });

      describe('with layout', function () {
        beforeEach(function () {
          return (this.entry.layout = '1');
        });

        return it('returns the images based on the layout', function () {
          return expect(this.service.getImagesInPDF(this.entry)).to.eql([
            { cloudinaryPublicId: 'image1', type: 'image' },
          ]);
        });
      });

      return describe('without layout', () =>
        it('returns the images based on the layout "1_2"', function () {
          return expect(this.service.getImagesInPDF(this.entry)).to.eql([
            { cloudinaryPublicId: 'image1', type: 'image' },
            { cloudinaryPublicId: 'image2', type: 'image' },
          ]);
        }));
    });

    describe('images on the extra pages', function () {
      beforeEach(function () {
        return (this.entry.pages = {
          images: {
            page1: {
              imagesShown: [
                createImage('image1'),
                createImage('image2'),
                createImage('image3'),
                createImage('image4'),
              ],
            },
          },
        });
      });

      describe('type is floor_plan', function () {
        beforeEach(function () {
          return (this.entry.pages.images.page1.type = 'floor_plan');
        });

        return it('returns the first image', function () {
          return expect(this.service.getImagesInPDF(this.entry)).to.eql([
            { cloudinaryPublicId: 'image1', type: 'image' },
          ]);
        });
      });

      describe('type is image', function () {
        beforeEach(function () {
          this.entry.pages.images.page1.layout = '2_1_1';
          return (this.entry.pages.images.page1.type = 'image');
        });

        return it('returns the images based on the layout', function () {
          return expect(this.service.getImagesInPDF(this.entry)).to.eql([
            { cloudinaryPublicId: 'image1', type: 'image' },
            { cloudinaryPublicId: 'image2', type: 'image' },
            { cloudinaryPublicId: 'image3', type: 'image' },
          ]);
        });
      });

      return describe('type is skipped', function () {
        beforeEach(function () {
          return (this.entry.pages.images.page1.type = 'skipped');
        });

        return it('returns no images', function () {
          return expect(this.service.getImagesInPDF(this.entry)).to.eql([]);
        });
      });
    });

    describe('images on the amenities page', function () {
      beforeEach(function () {
        return (this.entry.pages = {
          amenities: {
            imagesShown: {
              bottom_left: createImage('image1'),
              bottom_right: createImage('image2'),
              top_left: createImage('image3'),
              top_right: createImage('image4'),
            },
          },
        });
      });

      describe('all sections have type image', function () {
        beforeEach(function () {
          return _.assign(this.entry.pages.amenities, {
            bottom_left: { type: 'image' },
            bottom_right: { type: 'image' },
            top_left: { type: 'image' },
            top_right: { type: 'image' },
          });
        });

        return it('returns the images from top to bottom, left to right', function () {
          return expect(this.service.getImagesInPDF(this.entry)).to.eql([
            { cloudinaryPublicId: 'image3', type: 'image' },
            { cloudinaryPublicId: 'image4', type: 'image' },
            { cloudinaryPublicId: 'image1', type: 'image' },
            { cloudinaryPublicId: 'image2', type: 'image' },
          ]);
        });
      });

      return describe('no sections have type image', () =>
        it('does not return any images', function () {
          return expect(this.service.getImagesInPDF(this.entry)).to.eql([]);
        }));
    });

    return it('ignores streetview / map images', function () {
      this.entry.imagesShown = [{ type: 'streetview' }, { type: 'map' }];
      return expect(this.service.getImagesInPDF(this.entry)).to.eql([]);
    });
  });

  return describe('getDefaultStreetviewSettings', function () {
    beforeEach(
      async function () {
        this.entry = {
          fields: {
            header: {
              CorrectedAddress1: '123 My Street',
              city: 'My City',
              state: 'My State',
              zip: '12345',
            },
          },
        };
        this.googleAddressService.getMainStreetviewLocation.resolves({
          lat() {
            return 5.6;
          },
          lng() {
            return 7.8;
          },
        });
        return this.result = await this.service.getDefaultStreetviewSettings(
          this.entry
        );
      }
    );

    it('calls the googleAddressService with the proper attributes', function () {
      return expect(
        this.googleAddressService.getMainStreetviewLocation
      ).to.have.been.calledWith('123 My Street My City My State 12345');
    });

    return it('returns the google streetview data merged with type and isAmenity: false', function () {
      return expect(this.result).to.eql({
        position: [5.6, 7.8],
        pov: { heading: 1, pitch: 0, zoom: 1 },
      });
    });
  });
});
