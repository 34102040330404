exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 19 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/components/custom_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/components/custom_input/index.styl */\n.custom_input---root---VCuDxaR0doly3aA7WBkU- {\n  display: -ms-flexbox;\n  display: flex;\n}\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/components/custom_input/index.styl */\n.custom_input---root---VCuDxaR0doly3aA7WBkU- input {\n  margin-right: 10px;\n}\n\n/* line 9 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/components/custom_input/index.styl */\n.custom_input---root---VCuDxaR0doly3aA7WBkU- .fa {\n  line-height: 20px;\n}\n\n/* line 12 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/components/custom_input/index.styl */\n.custom_input---root---VCuDxaR0doly3aA7WBkU- .custom_input---clear---2xKuWDNyaK7hIUyHna-G4b {\n  height: 22px;\n  line-height: 22px;\n}\n\n/* line 16 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/components/custom_input/index.styl */\n.custom_input---root---VCuDxaR0doly3aA7WBkU- .custom_input---clear---2xKuWDNyaK7hIUyHna-G4b i {\n  color: #999;\n  height: 10px;\n  width: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "custom_input---root---VCuDxaR0doly3aA7WBkU-",
	"clear": "custom_input---clear---2xKuWDNyaK7hIUyHna-G4b"
};