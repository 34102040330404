const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PdfEntryTemplate = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentView',
  'setIsDraggingImage',
  'trackDragImageToPdf',
  'update',
]);

const mapStateToProps = function (state, ownProps) {
  const { data, isDraggingImage } = state.tourbookEditorPage;
  const entry = data.content.entries[ownProps.entryId];
  return { entry, isDraggingImage };
};

const propTypes = {
  entryId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

const serviceNames = ['tourbookFieldsService'];

module.exports = build({
  component: PdfEntryTemplate,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames,
});
