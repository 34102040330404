const $ = require('jquery');
const { Promise } = require('bluebird');
const React = require('react');
const ReactDOM = require('react-dom');
const Root = require('./root');
require('bootstrap/dist/css/bootstrap.css');


const render = function (Component) {
  let element = <Component />;
  return ReactDOM.render(element, document.getElementById('app'));
};

render(Root);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./root', function () {
    const nextRoot = require('./root');
    return render(nextRoot);
  });
}

// Polyfill promise (specifically for IE): https://github.com/mzabriskie/axios#promises
switch (process.env.NODE_ENV) {
  case 'staging':
  case 'production':
    Promise.config({
      warnings: false,
      longStackTraces: false,
      cancellation: false,
      monitoring: false,
    });
    break;
  default:
    Promise.config({
      warnings: true,
      longStackTraces: true,
      cancellation: false,
      monitoring: true,
    });
}
// window.Promise = Promise;
// Assign React to window for Chrome React Developer Tools
window.React = React;

// Prevent backspace from navigating to the previous page in IE / Firefox
$(document).on('keydown', function (event) {
  if (event.which === 8 && !$(event.target).is('input, textarea')) {
    return event.preventDefault();
  }
});
