const React = require('react');
const importedStyles = require('./tourbook_card.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const moment = require('moment');
const { locateServices } = require('@services/service_locator');
const Popover = require('@components/shared/popover');
const PropTypes = require('prop-types');
const { build } = require('@components/container_helpers');
const { useNavigate } = require('react-router-dom');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

const mapStateToProps = () =>
// TODO: Get actual collaborator count
({
  collaboratorCount: 1,
});

class TourbookCardInner extends React.Component {
  static initClass() {
    this.propTypes = {
      mode: PropTypes.oneOf(['owned', 'sharedWith']).isRequired,
      navigate: PropTypes.func.isRequired,
      tourbookId: PropTypes.string.isRequired,
      tourbook: PropTypes.object.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._getTourbookStyle = this._getTourbookStyle.bind(this);
    this.state = { inside: false };

    ({
      cloudinaryService: this.cloudinaryService,
      libraryPageActions: this.libraryPageActions,
      collaborateModalActions: this.collaborateModalActions,
    } = locateServices([
      'cloudinaryService',
      'libraryPageActions',
      'collaborateModalActions',
    ]));
  }

  _getCoverImageUrl(coverPhotoID) {
    const options = { thumbnail: true };
    return this.cloudinaryService.getImageUrl(
      { cloudinaryPublicId: coverPhotoID },
      options
    );
  }

  render() {
    return this._renderCard('');
  }

  renderCollaboratorCount() {
    if (this.props.collaboratorCount > 1) {
      return (
        <div className={cx(['collaborator-count'])}>
          {this.props.collaboratorCount.toString()}
        </div>
      );
    }
  }

  _renderCard(className) {
    return (
      <div className={cx([`tourbook`, `${className || ''}`])}>
        <div className={cx(['actions'])}>
          <ActionButton
            onClick={() => {
              this.props.dispatch(
                this.libraryPageActions._track('Views Tour Book Analytics', {
                  tourbookId: this.props.tourbookId,
                })
              );
              return this.props.navigate(`/analytics/${this.props.tourbookId}`);
            }}
            popoverText="Analytics"
            iconClass="chart-column"
            className={cx(['analytics'])}
          />
          <ActionButton
            onClick={() => {
              this.props.dispatch(
                this.libraryPageActions.setCurrentModal({
                  currentModal: 'collaborate',
                })
              );
              return this.props.dispatch(
                this.collaborateModalActions.set({
                  mode: this.props.mode,
                  tourbookId: this.props.tourbookId,
                  tourbook: this.props.tourbook,
                  trackingCategory: 'library',
                })
              );
            }}
            popoverText="Collaborate"
            iconClass="share-nodes"
            className={cx(['collaborate'])}
          >
            {this.renderCollaboratorCount()}
          </ActionButton>
          <ActionButton
            onClick={async function() {
              await this.props.dispatch(
                this.libraryPageActions.duplicateTourbook(this.props.tourbookId)
              );
              if (this.props.mode === 'sharedWith') {
                return this.props.dispatch(
                  this.libraryPageActions.setTab('owned')
                );
              }
            }.bind(this)}
            popoverText="Duplicate"
            iconClass="copy"
            className={cx(['duplicate'])}
          />
          {(() => {
            switch (this.props.mode) {
              case 'owned':
                return (
                  <ActionButton
                    onClick={() => {
                      return this.props.dispatch(
                        this.libraryPageActions.attemptDeleteTourbook(
                          this.props.tourbookId
                        )
                      );
                    }}
                    popoverText="Delete"
                    iconClass="trash-can"
                    className={cx(['delete'])}
                  />
                );
              case 'sharedWith':
                return (
                  <ActionButton
                    onClick={() => {
                      return this.props.dispatch(
                        this.libraryPageActions.attemptLeaveSharedTourbook(
                          this.props.tourbookId
                        )
                      );
                    }}
                    popoverText="Leave"
                    iconClass="arrow-right-from-bracket"
                    className={cx(['leave'])}
                  />
                );
            }
          })()}
        </div>
        <div
          onMouseEnter={() => this.setState({ inside: true })}
          onMouseLeave={() => this.setState({ inside: false })}
          onClick={() => this.props.navigate(`/tourbook/${this.props.tourbookId}`)}
          style={this._getTourbookStyle()}
          className={cx(['content'])}>
          <div className={cx(['edit'])}>
            <FontAwesomeIcon name="pencil" />
            <div className={cx(['text'])}>Edit Tour Book</div>
          </div>
          <div className={cx(['info'])}>
            <div className={cx(['title'])}>
              {this.props.tourbook.name}
            </div>
            <div className={cx(['date'])}>
              {moment(this.props.tourbook.date).format('MMMM Do, YYYY')}
            </div>
            {this.props.mode === 'sharedWith'
              ? <div className={cx(['email'])}>
              {`Created by ${this.props.tourbook.ownerEmail}`}
            </div>
              : undefined}
          </div>
        </div>
      </div>
    );
  }

  _getTourbookStyle() {
    let backgroundImage = `url('${this._getCoverImageUrl(
      this.props.tourbook.coverPhotoID
    )}')`;
    if (this.state.inside) {
      const layover = 'linear-gradient(rgba(0,0,0, 0.3), rgba(0,0,0,0.6)), ';
      backgroundImage = layover + backgroundImage;
    }
    return { backgroundImage };
  }
}
TourbookCardInner.initClass();

var ActionButton = ({ children, className, onClick, popoverText, iconClass }) => (
  <div onClick={onClick} className={cx([`action`, `${className || ''}`])}>
    <FontAwesomeIcon name={iconClass} />
    <div className={cx(['popover-container'])}>
      <Popover>
        {popoverText}
      </Popover>
    </div>
    {children}
  </div>);

module.exports = build({
  component: (props) => {
    const navigate = useNavigate();
    return <TourbookCardInner {...props} navigate={navigate} />;
  },
  displayName: 'TourbookCard',
  mapStateToProps,
});
