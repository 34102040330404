exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 7 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf/index.styl */\n.pdf---root---3VfYO_i-QQXuz7GfZU9Flu {\n  color: #000;\n}\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf/index.styl */\n.pdf---root---3VfYO_i-QQXuz7GfZU9Flu .pdf-template {\n  margin-bottom: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "pdf---root---3VfYO_i-QQXuz7GfZU9Flu"
};