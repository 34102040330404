const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const Itinerary = require('./itinerary');
const { findDOMNode } = require('react-dom');
const { useDrag, useDrop } = require('react-dnd');
const DRAG_TYPES = require('@enums/drag_types');
const { mapStyles } = require('./mapStyles');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

const PdfMapTemplate = (props) => {
  setComponentReady = props.readyRegistry.register('PdfMapTemplate');

  const mapRef = React.useRef();
  const [map, setMap] = React.useState();
  const [unhiddenEntries, setUnhiddenEntries] = React.useState({});
  const [visibleItineraryItems, setVisibleItineraryItems] = React.useState([]);
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const [mapMarkers, setMapMarkers] = React.useState([]);
  const mapMarkerLabels = [];
  const mapMarkersLoadStatus = {};

  const getStylesFromMapOptions = () => {
    let style = props.settings[props.mapOptionsKey]?.style?.split('.') || {};
    let styles = [];
    Object.keys(mapStyles).map((key) => {
      if (key === style[1]) {
        styles = mapStyles[key];
      }
    });
    return styles;
  };
  const DEFAULT_MAP_ZOOM = 5;
  const createMap = () => {
    const mapOptions = props.settings[props.mapOptionsKey] || {};
    let zoom = mapOptions.zoom ? mapOptions.zoom : DEFAULT_MAP_ZOOM;
    // 246 West End Ave New York, NY 10023
    const entryKeys = Object.keys(unhiddenEntries);
    const entryLoc =
      props.settings[props.mapOptionsKey]?.center || unhiddenEntries[entryKeys[0]]?.location || {};
    let location = {
      lat: entryLoc.latitude || 40.779455,
      lng: entryLoc.longitude || -73.957586,
    };

    const disableDraggableProps = !props.isEditing // Disable map interaction for PDF preview
      ? {
          draggable: false,
          gestureHandling: 'none',
          zoomControl: false,
          scrollwheel: false,
          disableDoubleClickZoom: true,
        }
      : {};

    setMap(
      new window.google.maps.Map(mapRef.current, {
        zoom: zoom,
        center: location,
        styles: getStylesFromMapOptions(),
        mapTypeControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        ...disableDraggableProps,
      })
    );
  };

  const displayOnlyVisibleListingsOnItinerary = (markers) => {
    const visibleItinerary = [];
    (markers || mapMarkers).forEach((mapMarker) => {
      // Find a google map marker that is visible on the map (i.e. within the bounds)
      const markerLat = mapMarker.position.lat();
      const markerLng = mapMarker.position.lng();
      const latLng = new window.google.maps.LatLng(markerLat, markerLng);

      if (map.getBounds()?.contains(latLng)) { // TODO map.getBounds() can be undefined but I cannot easily reproduce?
        // Find entries to the google map marker that is visible on the map
        const matchedEntries = Object.values(unhiddenEntries).filter((entry) => {
          const { latitude, longitude } = entry.location;
          return markerLat === latitude && markerLng === longitude;
        });

        visibleItinerary.push({
          label: mapMarker.label,
          propertyName: matchedEntries[0].fields.header.propertyName || '', // TODO
          address: matchedEntries[0].fields.header.CorrectedAddress1 || '', // TODO
        });
      }
    });
    setVisibleItineraryItems(visibleItinerary);
  };

  React.useEffect(() => {
    let filteredEntries = {};
    Object.keys(props.entries).forEach((entryId) => {
      if (props.entries[entryId].location) { // e.g. excluding tourbook-notes
        if (!props.entries[entryId].hidden) {
          filteredEntries[entryId] = props.entries[entryId];
        }
      }
    });
    setUnhiddenEntries(filteredEntries);
  }, [props.entries]);

  React.useEffect(() => {
    const tilesLoad = async (evt) => {
      setMarkers(unhiddenEntries);
      await _waitUntilPinsLoad();
    };
    if (_.size(unhiddenEntries) > 0) {
      if (mapRef.current && !map) {
        createMap();
      } else {
        map.addListener('tilesloaded', tilesLoad);
        map.addListener('dragend', () => {
          props.onMoveEnd(props, {
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
          });
          displayOnlyVisibleListingsOnItinerary();
        });
      }
    }
    return () => setComponentReady(false);
  }, [mapRef, map, unhiddenEntries]);

  React.useEffect(() => {
    if (map) {
      map.setZoom(props?.settings[props.mapOptionsKey]?.zoom ?? DEFAULT_MAP_ZOOM);
      map.setOptions({
        styles: getStylesFromMapOptions(),
      });
      window.google.maps.event.clearListeners(map, 'tilesloaded');
      setIsInitialLoad(false);
      setMarkers(unhiddenEntries);
    }
  }, [unhiddenEntries, props.settings[props.mapOptionsKey]?.style]);

  const setMarkers = (unhiddenEntries) => {
    mapMarkers.forEach((mark) => mark.setMap(null));

    const locs = {};
    const excludeKeys = ['tourbook-notes', 'tourbook-floorplan', 'tourbook-images'];
    Object.values(unhiddenEntries)
      .filter((entry) => excludeKeys.indexOf(entry.key) === -1)
      .sort((a, b) => {
        return a.order - b.order;
      })
      .forEach((entry, index) => {
        const { CorrectedAddress1, city, state } = entry.fields.header;
        const location = CorrectedAddress1
          ? `${CorrectedAddress1}, ${city}, ${state}`
          : entry.location.latitude + '-' + entry.location.longitude;
        locs[location] = [
          ...(locs[location] || []),
          {
            ...entry,
            markerIndex: index + 1, // not 0-indexed
          },
        ];
      });
    const markerBounds = new window.google.maps.LatLngBounds();
    const sortedKeys = Object.entries(locs)
      .sort(function compareFn(a, b) {
        const valueA = a[1][0];
        const valueB = b[1][0];
        return valueA.markerIndex - valueB.markerIndex;
      })
      .map((value) => value[0]);
    const googleMapMarkers = sortedKeys.map((key) => {
      const coords = locs[key][0].location;
      const latLng = new window.google.maps.LatLng(coords.latitude, coords.longitude);

      // Find ranges to display them hyphenated (vs others concatenated by commas)
      const ranges = []; // if locs[key][j].markerIndex are 1, 4, 5, 6, then ranges will have [1, '4-6']
      let i = 0;
      while (i < locs[key].length) {
        let rangeStartIndex = i;
        while (
          i + 1 < locs[key].length &&
          locs[key][i + 1].markerIndex === locs[key][i].markerIndex + 1
        ) {
          // continue until the range ends
          i++;
        }

        if (rangeStartIndex === i) {
          ranges.push(locs[key][rangeStartIndex].markerIndex);
        } else {
          ranges.push(locs[key][rangeStartIndex].markerIndex + '-' + locs[key][i].markerIndex);
        }
        i++;
      }

      const label = ranges.join(',');
      mapMarkerLabels.push(label);

      const marker = new window.google.maps.Marker({
        position: latLng,
        map: map,
        label,
      });

      markerBounds.extend(latLng);
      return marker;
    });

    if (googleMapMarkers.length > 1 && isInitialLoad) {
      map.setZoom(props.settings[props.mapOptionsKey]?.zoom ?? DEFAULT_MAP_ZOOM);
      const isCenterConfigured = !!props.settings[props.mapOptionsKey]?.center;
      const isZoomConfigured = !!props.settings[props.mapOptionsKey]?.zoom;
      const isNewTourbookInitialRender = !isCenterConfigured && !isZoomConfigured;
      if (isNewTourbookInitialRender) {
        map.fitBounds(markerBounds);
        map.setCenter(markerBounds.getCenter());
        props.onMoveEnd(props, {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        });
        props.onZoomChange(props, map.getZoom());
      }
    }

    setMapMarkers(googleMapMarkers);
    displayOnlyVisibleListingsOnItinerary(googleMapMarkers);
  };
  const _getCols = () => {
    if (unhiddenEntries) {
      if (Object.keys(unhiddenEntries).length < 11) {
        return 1;
      } else {
        if (Object.keys(unhiddenEntries).length < 29) {
          return 2;
        } else {
          return 3;
        }
      }
    } else {
      return 4;
    }
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAG_TYPES.MAP_LEGEND,
    item: (monitor) => {
      return { id: 'mapLegend' };
    },
    canDrag: function(p, monitor) {
      return props.isEditing; // Itinerary is not draggable in PDF preview
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const _resetMap = () => props.resetMap(props);

  const _waitUntilPinsLoad = async () => {
    const _resetMapMarkersLoadStatus = () => {
      for (const label of mapMarkerLabels) {
        mapMarkersLoadStatus[label] = false;
      }
      mapMarkersLoadStatus.pins = [];
    };

    const _areMapMarkersLoaded = () => {
      for (const label of mapMarkerLabels) {
        if (!mapMarkersLoadStatus[label]) return false;
      }
      return mapMarkersLoadStatus.pins.length === mapMarkerLabels.length;
    };

    const _recursivelyLookForPins = (node) => {
      if (node.nodeName === '#text' && node.wholeText in mapMarkersLoadStatus) {
        mapMarkersLoadStatus[node.wholeText] = true;
      }
      if (
        node.tagName === 'IMG' &&
        node.src === 'https://maps.gstatic.com/mapfiles/transparent.png'
      ) {
        mapMarkersLoadStatus.pins.push(node);
      }
      if (_areMapMarkersLoaded()) return;

      for (let child of node.childNodes) {
        _recursivelyLookForPins(child);
        if (_areMapMarkersLoaded()) return;
      }
    };

    for (let i = 0; i < 6; i++) { // try at most 6 times
      _resetMapMarkersLoadStatus();
      _recursivelyLookForPins(mapRef.current);
      if (_areMapMarkersLoaded()) {
        setComponentReady(true);
        break;
      }
      await new Promise((r) => setTimeout(r, 500)); // aka 'sleep;
    }
  };

  const _zoomIn = () => {
    const currentZoom = map.getZoom();
    props.onZoomChange(props, currentZoom + 1);
    displayOnlyVisibleListingsOnItinerary();
  };
  const _zoomOut = () => {
    const currentZoom = map.getZoom();
    props.onZoomChange(props, currentZoom - 1);
    displayOnlyVisibleListingsOnItinerary();
  };
  const updateItineraryLocation = (elem, moveOffsets, updatePaths) => {
    const { moveX, moveY } = moveOffsets;
    const dragSourceCss = getComputedStyle(elem);
    const currentTop = parseInt(dragSourceCss.top);
    const currentRight = parseInt(dragSourceCss.right);

    if (currentTop && currentRight) {
      if (elem.style) {
        elem.style.top = currentTop + moveY + 'px';
        elem.style.right = currentRight - moveX + 'px';
      } else {
        elem.style = {
          top: currentTop + moveY + 'px',
          right: currentRight - moveX + 'px',
        };
      }
      const { pathTop, pathRight } = updatePaths;
      props.dispatch(
        props.update({
          path: pathTop,
          value: elem.style.top,
        })
      );
      props.dispatch(
        props.update({
          path: pathRight,
          value: elem.style.right,
        })
      );
    }
  };
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DRAG_TYPES.MAP_LEGEND],
      drop: (item, monitor) => {
        const { x: moveX, y: moveY } = monitor.getDifferenceFromInitialOffset();
        updateItineraryLocation(
          findDOMNode(document.getElementById(item.id)),
          { moveX, moveY },
          {
            pathTop: ['content', 'settings', props.mapOptionsKey, 'legendTop'],
            pathRight: ['content', 'settings', props.mapOptionsKey, 'legendRight'],
          }
        );
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );
  const _renderControls = () => {
    return (
      <div className={cx(['controls'])}>
        <div className={cx(['button'])}>
          <a onClick={_resetMap} className={cx(['reset'])}>
            <FontAwesomeIcon name="crosshairs" />
          </a>
        </div>
        <div className={cx(['button'])}>
          <a onClick={_zoomIn} className={cx(['zoom-in'])}>
            <FontAwesomeIcon name="plus" />
          </a>
          <a onClick={_zoomOut} className={cx(['zoom-out'])}>
            <FontAwesomeIcon name="minus" />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className={cx(['root', 'pdf-template'])} ref={drop}>
      <div
        style={{ backgroundColor: props.settings.accentColor }}
        className={cx(['branded-bar'])}
      />
      <div ref={mapRef} className={cx(['map'])}></div>

      {props.isEditing ? _renderControls() : undefined}
      {props.settings[props.mapOptionsKey]?.itinerary && (
        <Itinerary
          settings={props.settings}
          mapOptionsKey={props.mapOptionsKey}
          cols={_getCols()}
          items={visibleItineraryItems}
          dragRef={drag}
        />
      )}
    </div>
  );
};

PdfMapTemplate.propTypes = {
  entries: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
  resetMap: PropTypes.func.isRequired,
  onZoomChange: PropTypes.func.isRequired,
  onMoveEnd: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    accentColor: PropTypes.string,
    mapOptions: PropTypes.object,
  }),
};

module.exports = PdfMapTemplate;
