const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const Input = require('./');

const mapDispatchToProps = (dispatch, ownProps) => ({
  update(value) {
    return dispatch(
      actions.tourbookEditorPage.update({ path: ownProps.path, value })
    );
  },

  updateChild(key, value) {
    return dispatch(
      actions.tourbookEditorPage.update({
        path: ownProps.path.concat(key),
        value,
      })
    );
  },

  updateUnit(value) {
    return dispatch(
      actions.tourbookEditorPage.update({
        path: ownProps.options.unitPath,
        value,
      })
    );
  },
});

const mapStateToProps = function (state, ownProps) {
  const data = { value: _.get(state.tourbookEditorPage.data, ownProps.path) };
  if (ownProps.options?.unitPath) {
    data.unit = _.get(state.tourbookEditorPage.data, ownProps.options.unitPath);
  }
  return data;
};

module.exports = build({
  component: Input,
  mapDispatchToProps,
  mapStateToProps,
});
