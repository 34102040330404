const _ = require('lodash');
const importedStyles = require('../../index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class HeaderSection extends React.Component {
  constructor(props) {
    super(props);
    this._hasValue = this._hasValue.bind(this);
    this._isFieldActive = this._isFieldActive.bind(this);
    this._getEntryNumber = this._getEntryNumber.bind(this);
  }

  static initClass() {
    this.propTypes = {
      currentField: PropTypes.object,
      entryId: PropTypes.string.isRequired,
      entryAccentColor: PropTypes.string.isRequired,
      fieldFormatterService: PropTypes.object.isRequired,
      entryNumberService: PropTypes.object.isRequired,
      headerFields: PropTypes.object.isRequired,
      isEditing: PropTypes.bool,
      navigateToCurrentEntryField: PropTypes.func.isRequired,
      tourbook: PropTypes.object.isRequired,
    };
  }

  render() {
    return (
      <div className={cx(['header-section'])}>
        <div
          style={{ borderColor: this.props.entryAccentColor }}
          className={cx(['listing-number'])}
        >
          {this._getEntryNumber(
            this.props.tourbook.content.entries,
            this.props.entryId
          )}
        </div>
        {this._shouldRenderField('tourTime') ? (
          <div {...this._getFieldProps(['field', 'tour-time'], 'tourTime')}>
            {this.props.headerFields.tourTime}
          </div>
        ) : undefined}
        {this._shouldRenderField('propertyName') ? (
          <div
            {...this._getFieldProps(['field', 'property-name'], 'propertyName')}
          >
            {this.props.headerFields.propertyName}
          </div>
        ) : undefined}
        <div {...this._getFieldProps(['field', 'full-address'], 'fullAddress')}>
          <div {...this._getAddressProps()}>
            {this.props.headerFields.CorrectedAddress1}
          </div>
          <div className={cx(['city-state-zip'])}>
            {this.props.fieldFormatterService.formatCityStateZip(
              this.props.headerFields
            )}
          </div>
        </div>
        {this._shouldRenderField('crossStreets') ? (
          <div
            {...this._getFieldProps(['field', 'cross-streets'], 'crossStreets')}
          >
            {this.props.headerFields.crossStreets}
          </div>
        ) : undefined}
        {this._shouldRenderField('floor') ||
        this._shouldRenderField('suite') ? (
          <div
            {...this._getFieldProps(['field', 'floor-suite'], 'floor', 'suite')}
          >
            {this.props.fieldFormatterService.formatFloorSuite(
              this.props.headerFields
            )}
          </div>
        ) : undefined}
      </div>
    );
  }

  _buildOnClick(fieldKey) {
    if (!this.props.isEditing) {
      return;
    }
    return () => {
      return this.props.navigateToCurrentEntryField({
        fieldKey: this._getFieldId(fieldKey),
        tabKey: 'address',
      });
    };
  }

  _getAddressProps() {
    return {
      className: cx([
        `address`,
        !this._shouldRenderField('propertyName') ? 'big' : '',
      ]),
    };
  }

  _getFieldId(fieldKey) {
    if (fieldKey === 'fullAddress') {
      return `${this.props.entryId}-address`;
    } else {
      return `content-entries-${this.props.entryId}-fields-header-${fieldKey}`;
    }
  }

  _getFieldProps(classNameArray, ...fieldKeys) {
    const fieldKeyToFocus =
      fieldKeys.length === 1 ? fieldKeys[0] : _.find(fieldKeys, this._hasValue);
    return {
      className: cx([
        ...classNameArray,
        _.some(fieldKeys, this._isFieldActive) ? 'active' : '',
      ]),
      onClick: this._buildOnClick(fieldKeyToFocus),
    };
  }

  _hasValue(fieldKey) {
    return this.props.headerFields[fieldKey];
  }

  _isFieldActive(fieldKey) {
    return (
      this.props.isEditing &&
      this.props.currentField.key === this._getFieldId(fieldKey)
    );
  }
  _getEntryNumber(entries, entryId) {
    return this.props.entryNumberService.getEntryNumber({ entries, entryId });
  }
  _shouldRenderField(fieldKey) {
    return this._hasValue(fieldKey) || this._isFieldActive(fieldKey);
  }
}
HeaderSection.initClass();

module.exports = HeaderSection;
