const _ = require('lodash');

const initialState = {
  currentModal: {},
  currentTab: 'owned',
  ownedTourbooks: {},
  sharedWithMeTourbooks: {},
};

const mapping = {
  addTourbook(state, { payload }) {
    const newOwnedTourbooks = _.assign({}, state.ownedTourbooks);
    newOwnedTourbooks[payload.tourbookId] = payload.tourbookMeta;
    return _.assign({}, state, { ownedTourbooks: newOwnedTourbooks });
  },

  deleteTourbook(state, { payload: tourbookId }) {
    const newOwnedTourbooks = _.assign({}, state.ownedTourbooks);
    delete newOwnedTourbooks[tourbookId];
    return _.assign({}, state, { ownedTourbooks: newOwnedTourbooks });
  },

  leaveSharedTourbook(state, { payload: tourbookId }) {
    const newSharedWithMeTourbooks = _.assign({}, state.sharedWithMeTourbooks);
    delete newSharedWithMeTourbooks[tourbookId];
    return _.assign({}, state, {
      sharedWithMeTourbooks: newSharedWithMeTourbooks,
    });
  },

  setCollaborateModalSharedUsers(state, { payload }) {
    const newCollaborateModalProps = _.assign({}, state.currentModalProps);
    newCollaborateModalProps.sharedUsers = payload;
    return _.assign({}, state, { currentModalProps: newCollaborateModalProps });
  },

  setCurrentModal(state, { payload }) {
    const { currentModal, currentModalProps } = payload;
    return _.assign({}, state, { currentModal, currentModalProps });
  },

  setOwnedTourbooks(state, { payload }) {
    return _.assign({}, state, { ownedTourbooks: payload });
  },

  setSharedWithMeTourbooks(state, { payload }) {
    return _.assign({}, state, { sharedWithMeTourbooks: payload });
  },

  setTab(state, { payload }) {
    return _.assign({}, state, { currentTab: payload });
  },
};

module.exports = { initialState, mapping, namespace: 'libraryPage' };
