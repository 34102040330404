const React = require('react');
const PropTypes = require('prop-types');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const { OverlayTrigger, Popover } = require('react-bootstrap');
const ReadyRegistry = require('@utils/ready_registry');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class LikesCountComponent extends React.Component {
  static initClass() {
    this.propTypes = {
      entryId: PropTypes.string.isRequired,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
      tourbookId: PropTypes.string.isRequired,
      tourbooksService: PropTypes.object.isRequired,
    };
  }

  constructor() {
    super();

    this.state = {
      likes: [],
      dislikes: [],
    };
  }

  async _updateLikesAndDislikes(props) {
    const entryLikes = await props.tourbooksService.getLikes(
      props.tourbookId,
      props.entryId
    );
    const entryDislikes = await props.tourbooksService.getDisLikes(
      props.tourbookId,
      props.entryId
    );

    const [likes = [], dislikes = []] = [entryLikes, entryDislikes].map(
      (elem) => Object.values(elem || {})
    );
    this.setState({
      likes,
      dislikes,
    });
  }

  componentWillMount() {
    if (this.props.entryId) {
      this.setComponentReady = this.props.readyRegistry.register(`LikesCountComponent-${this.props.entryId}`);
    }
  }

  async componentDidMount() {
    await this._updateLikesAndDislikes(this.props);
    if (this.setComponentReady) {
      this.setComponentReady(true);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.tourbookId !== this.props.tourbookId ||
      nextProps.entryId !== this.props.entryId
    ) {
      this._updateLikesAndDislikes(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  _renderUserOverlay = (overlayProps, users) => {
    let counter = 1;
    return (
      <Popover id={cx(['user-image-popover'])} {...overlayProps}>
        <ul className={cx(['user-menu'])}>
          {users.map((user, index) => {
            return (
              <li key={index}>{user.email || 'Anonymous user ' + counter++}</li>
            );
          })}
        </ul>
      </Popover>
    );
  };

  _getContent = (config) => (
    <div key={config.className} className={cx([config.className])}>
      {config.value.length} <FontAwesomeIcon name={config.icon}/>
    </div>
  );

  render() {
    const configs = [
      {
        className: 'like',
        icon: 'heart',
        value: this.state.likes,
      },
      {
        className: 'dislike',
        icon: 'thumbs-down',
        value: this.state.dislikes,
      },
    ];

    return (
      <div className={cx(['icons-group'])}>
        {configs.map((config) =>
          config.value.length ? (
            <OverlayTrigger
              key={config.className}
              placement="bottom"
              overlay={(props) => {
                return this._renderUserOverlay(props, config.value);
              }}
              trigger="click"
              rootClose
            >
              {this._getContent(config)}
            </OverlayTrigger>
          ) : (
            this._getContent(config)
          )
        )}
      </div>
    );
  }
}

LikesCountComponent.initClass();

module.exports = LikesCountComponent;
