const _ = require('lodash');
const { combineReducers } = require('redux');
const { handleActions } = require('redux-actions');

const configurations = [
  require('./library_page'),
  require('./profile_page'),
  require('./promise_inspections'),
  require('./tourbook_editor_page'),
  require('./user_session'),
  require('./collaborate_modal'),
];

const reducerMap = {};
configurations.forEach(function ({ initialState, mapping, namespace }) {
  const namespacedMapping = _.mapKeys(
    mapping,
    (value, key) => `${namespace}/${key}`
  );
  return (reducerMap[namespace] = handleActions(
    namespacedMapping,
    initialState
  ));
});
const reducer = combineReducers(reducerMap);

module.exports = reducer;
