const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const { locateService } = require('@services/service_locator');
const HeaderSectionContainer = require('./components/header_section/container');
const PdfPageWithFooterContainer = require('../pdf_page_with_footer/container');

const NotesSectionContainer = require('../shared/notes_section/container');

class pdfTourbookNotesTemplate extends React.Component {
  static initClass() {
    this.propTypes = {
      entry: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      fieldDefinitions: PropTypes.object.isRequired,
      isDraggingImage: PropTypes.bool,
      isEditing: PropTypes.bool,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
      tourbook: PropTypes.object.isRequired,
      pdfService: PropTypes.object.isRequired,
    };
  }

  componentWillMount() {
    if (this.props.entryId) {
      this.setComponentReady = this.props.readyRegistry.register(
        `pdfTourbookNotesTemplate-${this.props.entryId}`
      );
    }
  }

  componentDidMount() {
    if (this.setComponentReady) {
      this.setComponentReady(true);
    }
  }

  componentWillUnmount() {
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  constructor(props) {
    super(props);
    this.tourbookFieldsService = locateService('tourbookFieldsService');
    this.pageNumberService = locateService('pageNumberService');
  }

  render() {
    if (!this.props.pdfService.shouldPageBeIncludedInPdf(this.props.entry)) {
      return (
        <div className={cx(['root', 'pdf-template'])}>
          <div className={cx(['hidden-preview'])}>
            <div className={cx(['hidden-preview-message'])}>
              {'This page is marked private and will not display in the PDF'}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={cx([`root`, `pdf-template`, `${this.props.isEditing ? 'is-editing' : ''}`])}
        >
          <PdfPageWithFooterContainer
            hidePageNumber={this.props.entry.hidden}
            pageNumber={this._getPageNumber()}
            readyRegistry={this.props.readyRegistry}
          >
            <div className={cx(['entry-content'])}>
              <div className={cx([`rows`])}>
                <div className={cx(['row'])}>
                  <HeaderSectionContainer
                    entryId={this.props.entryId}
                    isEditing={this.props.isEditing}
                  />
                </div>
                <div
                  className={cx(['row', 'notes-wrapper'])}
                  ref={(node) => {
                    return (this.NotesSectionParent = node);
                  }}
                >
                  <NotesSectionContainer
                    entryId={this.props.entryId}
                    isEditing={this.props.isEditing}
                    parent={this.NotesSectionParent}
                  />
                </div>
              </div>
            </div>
          </PdfPageWithFooterContainer>
        </div>
      );
    }
  }

  _getPageNumber() {
    return this.pageNumberService.getEntryPageNumber(
      _.pick(this.props, ['entries', 'entryId', 'tourbook'])
    );
  }
}
pdfTourbookNotesTemplate.initClass();

module.exports = pdfTourbookNotesTemplate;
