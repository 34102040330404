const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const numeral = require('numeral');
const PropTypes = require('prop-types');
const React = require('react');
class ResultsTable extends React.Component {
  static initClass() {
    this.propTypes = {
      className: PropTypes.string.isRequired,
      identifierRenderFn: PropTypes.func.isRequired,
      tourbookTitleRenderFn: PropTypes.func.isRequired,
      suiteRenderFn: PropTypes.func.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          availableSpace: PropTypes.string,
          floor: PropTypes.any,
          leaseOrSale: PropTypes.string,
          suite: PropTypes.string,
          createdBy: PropTypes.string,
        }).isRequired
      ).isRequired,
      googlePropertySearch: PropTypes.object,
      n360PropertySearch: PropTypes.object,
      onRowClick: PropTypes.func.isRequired,
      titleRenderFn: PropTypes.func.isRequired,
      showTable: PropTypes.bool,
      updateRenderFn: PropTypes.func,
      updateRenderGoogleFn: PropTypes.func,
      updateRenderN360SuggestsFn: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this._getLeaseOrSale = this._getLeaseOrSale.bind(this);
    this._getSquareFootage = this._getSquareFootage.bind(this);
    this._getCreatedBy = this._getCreatedBy.bind(this);
    this.unexpandedLength = 3;
    this.state = { expanded: true };
  }

  componentWillMount() {
    this.props.updateRenderGoogleFn(false);
    return this.props.updateRenderN360SuggestsFn(false);
  }

  componentDidMount() {
    if (this.props.googlePropertySearch != null) {
      this.props.googlePropertySearch.blur();
    }
    if (this.props.n360PropertySearch.autosuggest?.state.isCollapsed === false) {
      return this.props.n360PropertySearch.autosuggest?.closeSuggestions;
    }
  }

  componentWillUnmount() {
    this.props.updateRenderGoogleFn(true);
    return this.props.updateRenderN360SuggestsFn(true);
  }

  render() {
    if (this.props.items.length === 0 || this.props.showTable === false) {
      return null;
    } else {
      return (
        <div
          ref={(node) => {
            return (this.resultTable = node);
          }}
          className={cx(['items'])}
        >
          {this.props.titleRenderFn(this.props.items.length)}
          <div
            style={{ overflow: 'scroll', maxHeight: 160 }}
            className={cx(['tablecontanier'])}
          >
            <table>
              <tbody>
                {this._getItemsToDisplay().map((item, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={(e) => this.props.onRowClick(item, e, index)}
                    >
                      {this.props.checkbox && (
                        <td>
                          <input type="checkbox" className={cx(['large'])} />
                        </td>
                      )}
                      <td className={cx(['floor'])}>
                        {item.floor ? `Floor ${item.floor}` : 'n/a'}
                      </td>
                      <td className={cx(['suite'])}>
                        {this.props.suiteRenderFn(item) || '-'}
                      </td>
                      <td className={cx(['tourbookTitle'])}>
                        {this.props.tourbookTitleRenderFn(item) || '-'}
                      </td>
                      <td className={cx(['square-footage'])}>
                        {this._getSquareFootage(item) || 'n/a'}
                      </td>
                      <td className={cx(['lease-or-sale'])}>
                        {this._getLeaseOrSale(item) || 'n/a'}
                      </td>
                      <td className={cx(['created-by'])}>
                        {this._getCreatedBy(item) || ''}
                      </td>
                      <td className={cx(['lastUpdated'])}>
                        {this._getlastUpdated(item) || ''}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  _getItemsToDisplay() {
    if (this.state.expanded) {
      return this.props.items;
    } else {
      return _.take(this.props.items, this.unexpandedLength);
    }
  }

  _getLeaseOrSale(item) {
    let returnValue = '';
    if (item.isSaleSpace != null) {
      if (item.isSalesSpace) {
        return (returnValue = 'Sale');
      } else {
        return (returnValue = 'Lease');
      }
    } else {
      if (item.leaseOrSale != null) {
        return item.leaseOrSale;
      } else {
        if (item.type != null) {
          return item.type;
        } else {
          return 'n/a';
        }
      }
    }
  }

  _getSquareFootage(item) {
    let value;
    if (item.tenantSize != null) {
      value = item.tenantSize;
    } else {
      if (item.smallestAvailableSpace != null) {
        value = item.smallestAvailableSpace;
      } else {
        if (item.availableSpace != null) {
          value = item.availableSpace;
        } else {
          if (item.vacantSpace != null) {
            value = item.vacantSpace;
          }
        }
      }
    }
    if (!value) {
      return;
    }
    if (value) {
      return numeral(value).format('0,0') + ' sf';
    }
  }

  _getCreatedBy(item) {
    // user entry uses "availableSpace", n360 space uses "smallestAvailableSpace"
    let value;
    if (item.tourbookCreatedBy != null) {
      value = item.tourbookCreatedBy;
    }
    if (!value) {
      return;
    }
    return this._emailToName(value);
  }

  _getlastUpdated(item) {
    // user entry uses "availableSpace", n360 space uses "smallestAvailableSpace"
    let value = null;
    if (item.tourbookDate != null) {
      value = new Date(item.tourbookDate);
      if (isNaN(value)) {
        value = item.tourbookDate;
      } else {
        value = value.toISOString().slice(0, 10);
      }
    }
    return value;
  }

  _emailToName(emailAddress) {
    const str = emailAddress.replace('@ngkf.com', '').replace('.', ' ');
    return this._toTitleCase(str);
  }

  _toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }
}
ResultsTable.initClass();

module.exports = ResultsTable;
