require('array.from').shim(); // necessary for react
require('cropperjs/dist/cropper.css');
require('rc-slider/assets/index.css');
require('react-datepicker/dist/react-datepicker.css');
require('react-geosuggest/module/geosuggest.css');
require('./global_styles/docraptor.styl');
require('./global_styles/index.styl');
require('./global_styles/react-datepicker.styl');
require('./global_styles/scrollbar.styl');

const { Provider } = require('react-redux');
const React = require('react');
const RouterBuilder = require('./router_builder');
const store = require('./store');
const UserSessionInitializerContainer = require('./components/user_session_initializer/container');
const { HTML5Backend } = require('react-dnd-html5-backend');
const { DndProvider } = require('react-dnd');

class Main extends React.Component {
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <UserSessionInitializerContainer>
            {RouterBuilder({ store })}
          </UserSessionInitializerContainer>
        </Provider>
      </DndProvider>
    );
  }
}

module.exports = Main;
