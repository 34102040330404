const _ = require('lodash');
const moment = require('moment');
const numeral = require('numeral');

class FieldFormatterService {
  format(value, { format, type }) {
    if (type === 'date') {
      if (value) {
        return this._formatDate({ format, value });
      } else {
        return '';
      }
    } else if (type === 'number') {
      return numeral(value).format(format);
    } else {
      return value;
    }
  }

  formatCityStateZip({ city, state, zip }) {
    const cityState = _.compact([city, state]).join(', ');
    return _.compact([cityState, zip]).join(' ');
  }

  formatFloorSuite({ floor, suite }) {
    const data = [];
    if (floor) {
      data.push(`Floor ${floor}`);
    }
    if (suite) {
      data.push(`Suite ${suite}`);
    }
    return _.compact(data).join(', ');
  }

  getAllowedValue({ fieldDefinition, value }) {
    if (!value) {
      return '';
    }
    if (fieldDefinition?.decimal) {
      return value.replace(/[^\d\.]/g, '').match(/\d*\.?\d{0,2}/)[0];
    } else if (fieldDefinition?.integer) {
      return value.replace(/[^\d]/g, '');
    } else {
      return value;
    }
  }

  _formatDate({ format, value }) {
    let momentDate = moment(value, ['MMMM D, YYYY']);
    if (!momentDate.isValid()) {
      momentDate = moment(value);
    }
    return momentDate.format(format);
  }
}

module.exports = FieldFormatterService;
