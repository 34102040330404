const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const CreatingEntryOverlay = require('./');
const DATA_SOURCE_TYPES = require('@enums/data_source_types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentModal',
  'setCurrentView',
  'track',
]);

const mapStateToProps = function (state) {
  const { entry, entryId, isFirstEntry, source, isDuplicate } =
    state.tourbookEditorPage.creatingEntryData;
  const isN360 = !isDuplicate && source === DATA_SOURCE_TYPES.N360
  const createEntryStatus =
    state.promiseInspections['tourbookEditorPage/createEntry'];
  const createError = createEntryStatus?.error;
  const isCreated = createEntryStatus?.fulfilled;
  const data = { createError, entryId, isCreated, isFirstEntry, isN360 };
  if (entry && isN360) {
    _.assign(data, {
      numberOfDataFieldsFound: _.chain(entry.fields).pickBy().size().value(),
      numberOfImagesFound: _.size(entry.n360Images),
    });
  }
  data.delayBetweenSteps = ENV.delays.createEntryOverlay;
  return data;
};

module.exports = build({
  component: CreatingEntryOverlay,
  mapDispatchToProps,
  mapStateToProps,
});
