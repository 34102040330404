const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const TabView = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'onTabChange',
  'setCurrentTab',
]);

const mapStateToProps = (state) => ({
  currentTab: state.tourbookEditorPage.currentTab,
});

module.exports = build({
  component: TabView,
  mapDispatchToProps,
  mapStateToProps,
});
