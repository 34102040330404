const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ImageContainer = require('@components/shared/image/container');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const { useDrag, useDrop } = require('react-dnd');
const DRAG_TYPES = require('@enums/drag_types');
const _ = require('lodash');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

const EntryImage = (props) => {
  const _renderImage = () => {
    return (
      <div className={cx(['wrapper'])}>
        <ImageContainer
          backgroundSize="cover"
          image={props.image}
          readyRegistry={new ReadyRegistry()}
          thumbnail={true}
          croppable={true}
          entryLocation={props.entryLocation}
          imagePath={props.imagePath}
          useDefaultSize={true}
          onDelete={props.onDelete}
        />
        <div className={cx(['overlay'])}>
          {props.isImageInPDF ? <div className={cx(['pdf'])}>PDF</div> : undefined}
        </div>
      </div>
    );
  };

  const _renderStatic = ({ iconName, label }) => {
    return (
      <div className={cx(['wrapper', 'static'])}>
        <FontAwesomeIcon name={iconName}/>
        <div className={cx(['text'])}>{label}</div>
      </div>
    );
  };

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DRAG_TYPES.ENTRY_IMAGE,
      item: (monitor) => {
        return props.image;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [props]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DRAG_TYPES.ENTRY_IMAGE],
      drop: (item, monitor) => {
        const target = props.image;
        if (!item.order || !target.order) {
          for (const [index, imageId] of Object.keys(props.images).entries()) {
            props.images[imageId] = {
              ...props.images[imageId],
              order: index,
            };
          }
        }
        const itemOrder = item.order || props.images[item.id].order;
        const targetOrder = target.order || props.images[target.id].order;

        // Swapping the order between the item and the target
        newImages = {
          ...props.images,
        };
        newImages[item.id] = {
          ...props.images[item.id],
          order: targetOrder,
        };
        newImages[target.id] = {
          ...props.images[target.id],
          order: itemOrder,
        };

        // IF indices happen to be same numbers, swapping won't work. Below handles that.
        const orderedImageIds = _.chain(newImages)
          .map((image, imageId) => ({ ...image, id: imageId }))
          .orderBy('order')
          .map((image) => image.id)
          .value();
        orderedImageIds.forEach((imageId, index) => {
          newImages[imageId]['order'] = index;
        });

        // Updating the entire images (instead of performing two smaller separate updates) to prevent race condition
        props.update({
          path: ['content', 'entries', props.entryId, 'images'],
          value: newImages,
        });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.images, props.image, props.update, props.entryId]
  );

  return (
    <div className={cx(['root'])} ref={drop} id="entry-image-drop">
      <span ref={drag}>
        {(() => {
          switch (props.image.type) {
            case 'streetview':
              return _renderStatic({
                iconName: 'street-view',
                label: 'Street View',
              });
            case 'map':
              return _renderStatic({
                iconName: 'earth-americas',
                label: 'Map View',
              });
            default:
              return _renderImage();
          }
        })()}
      </span>
    </div>
  );
};

EntryImage.propTypes = {
  entryId: PropTypes.string.isRequired,
  image: PropTypes.object,
  images: PropTypes.object,
  isImageInPDF: PropTypes.bool,
  onDelete: PropTypes.func,
  update: PropTypes.func.isRequired,
};

module.exports = EntryImage;
