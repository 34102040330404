/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const TourbookFieldsService = require('./tourbook_fields_service');
const { stubService } = require('./service_locator');

describe('TourbookFieldsService', function () {
  beforeEach(function () {
    this.fieldDefinitions = {};
    this.user = {
      firebaseProfile: { tourbookSettings: {} },
      id: 'user-1',
    };
    this.atlasFieldsService = stubService('atlasFieldsService');
    this.tourbookEntryFieldsService = stubService('tourbookEntryFieldsService');
    this.usersService = stubService('usersService');

    return (this.tourbookFieldsService = new TourbookFieldsService());
  });

  describe('formatValue', () =>
    it('handles number decimals correctly', function () {
      const result = this.tourbookFieldsService.formatValue(1.75, {
        decimal: true,
      });
      return expect(result).to.eql('1.75');
    }));

  describe('_getEstMonthlyRent', function () {
    beforeEach(function () {
      this.fields = {
        space: {
          leaseHighPrice: { value: { max: 8, min: 4 } },
          smallestAvailableSpace: { value: { max: 4, min: 0 } },
        },
      };
      this.fieldDefinitions = {
        leaseHighPrice: { units: ['PSF/YR', 'PSF/MO'] },
      };
      return (this._getEstMonthlyRent = function () {
        return this.tourbookFieldsService._getEstMonthlyRent({
          fieldDefinitions: this.fieldDefinitions,
          fields: this.fields,
        });
      });
    });

    it('returns the correct number when when entry has needed fields', function () {
      return expect(this._getEstMonthlyRent()).to.eql(1);
    });

    it('handles values of null correctly', function () {
      delete this.fields.space.smallestAvailableSpace.value.min;
      return expect(this._getEstMonthlyRent()).to.eql(2);
    });

    it('handles single value of empty string correctly', function () {
      this.fields.space.smallestAvailableSpace.value.min = '';
      return expect(this._getEstMonthlyRent()).to.eql(2);
    });

    it('returns undefined when entry does not have needed fields', function () {
      delete this.fields.space.leaseHighPrice.value;
      return expect(this._getEstMonthlyRent()).to.eql(undefined);
    });

    return it('does not divide by 12 when leaseHighPrice is per month', function () {
      this.fields.space.leaseHighPrice.unit = 'PSF/MO';
      return expect(this._getEstMonthlyRent()).to.eql(12);
    });
  });

  describe('getDefaultCover', () =>
    describe('without user defaults', function () {
      it('returns the default cover', function () {
        const result = this.tourbookFieldsService.getDefaultCover(this.user);
        return expect(result).to.have.keys([
          'fields',
          'imagesShown',
          'logo',
          'xceligentImages',
        ]);
      });

      return it('returns a cover with the correct background image', function () {
        const result = this.tourbookFieldsService.getDefaultCover(this.user);
        return expect(result.imagesShown.coverPhoto.cloudinaryPublicId).to.eql(
          'ptp/cover-photo/FlatironCOLOR'
        );
      });
    }));

  describe('getDefaultContactInfo', function () {
    describe('type is broker', () =>
      it('returns just the type', function () {
        const result =
          this.tourbookFieldsService.getDefaultContactInfo('broker');
        return expect(result).to.eql({
          header: 'Prepared By',
          type: 'broker',
        });
      }));

    describe('type is client', () =>
      it('returns just the type', function () {
        const result =
          this.tourbookFieldsService.getDefaultContactInfo('client');
        return expect(result).to.eql({
          header: 'Prepared For',
          type: 'client',
        });
      }));

    return describe('type is skipped', () =>
      it('returns just the type', function () {
        const result =
          this.tourbookFieldsService.getDefaultContactInfo('skipped');
        return expect(result).to.eql({ type: 'skipped' });
      }));
  });

  describe('getDefaultSettings', function () {
    describe('without most recent tourbook settings', function () {
      beforeEach(function () {
        return (this.usersService.getMostRecentlyCreatedTourbook = sinon
          .stub()
          .resolves());
      });

      return it(
        'returns the default settings',
        async function() {
          const result = await this.tourbookFieldsService.getDefaultSettings(
            this.user
          );
          return expect(result).to.eql({
            accentColor: '#E6AE6A',
            coverFontColor: 'white',
            display: {
              digitalAd: true,
              footerLogo: true,
              footerText: true,
              pageNumbers: true,
              summary: true,
              tourMap: true,
            },
            isMapColor: false,
            mapOptions: {
              itinerary: true,
              itineraryTitle: 'Itinerary',
              style: 'ngkf/ckglw6sj90ici1al9439saufx',
            },
            name: 'Untitled Book',
          });
        }
      );
    });

    return describe('with most recent tourbook settings', function () {
      beforeEach(function () {
        this.usersService.getMostRecentlyCreatedTourbook = sinon
          .stub()
          .resolves();
        return this.usersService.getMostRecentlyCreatedTourbook
          .withArgs('user-1')
          .resolves({
            content: {
              settings: {
                accentColor: '#000000',
                coverFontColor: 'black',
                display: {
                  footerLogo: false,
                  footerText: false,
                  pageNumbers: false,
                  summary: false,
                  tourMap: false,
                },
              },
            },
          });
      });

      return it(
        `returns the default settings with settings \
inherited from the most recent tourbook`,
        async function() {
          const result = await this.tourbookFieldsService.getDefaultSettings(
            this.user
          );
          return expect(result).to.eql({
            accentColor: '#000000',
            coverFontColor: 'white',
            display: {
              digitalAd: true,
              footerLogo: false,
              footerText: false,
              pageNumbers: false,
              summary: false,
              tourMap: false,
            },
            isMapColor: false,
            mapOptions: {
              itinerary: true,
              itineraryTitle: 'Itinerary',
              style: 'ngkf/ckglw6sj90ici1al9439saufx',
            },
            name: 'Untitled Book',
          });
        }
      );
    });
  });

  describe('getFieldLabel', function () {
    beforeEach(function () {
      this.field = { key: 'field1' };
      return (this.getFieldLabelCall = () =>
        this.tourbookFieldsService.getFieldLabel(
          this.field,
          this.fieldDefinitions
        ));
    });

    it('gets the field label if given', function () {
      this.field.label = 'Field Label';
      return expect(this.getFieldLabelCall()).to.eql('Field Label');
    });

    it("gets the field definition's label if the field.label is undefined", function () {
      this.fieldDefinitions.field1 = { label: 'Field Def Label' };
      return expect(this.getFieldLabelCall()).to.eql('Field Def Label');
    });

    return it('title cases the key if no label for the field or definition is given', function () {
      return expect(this.getFieldLabelCall()).to.eql('Field 1');
    });
  });

  describe('getFieldUnit', function () {
    beforeEach(function () {
      this.unit = null;
      this.fieldDefinition = {};
      return (this.getFieldUnitCall = () =>
        this.tourbookFieldsService.getFieldUnit({
          fieldDefinition: this.fieldDefinition,
          unit: this.unit,
        }));
    });

    it('gets the value unit if given', function () {
      this.unit = 'Field Unit';
      return expect(this.getFieldUnitCall()).to.eql('Field Unit');
    });

    return it("gets the field definition's unit if field.unit is undefined", function () {
      this.fieldDefinition = { units: ['Field Def Unit1', 'Field Def Unit2'] };
      return expect(this.getFieldUnitCall()).to.eql('Field Def Unit1');
    });
  });

  describe('getFieldValueAsString', function () {
    beforeEach(function () {
      this.fieldDefinition = {};
      this.unit = null;
      this.value = 'some value';
      this.withUnit = true;
      return (this.getFieldValueAsStringCall = function () {
        return this.tourbookFieldsService.getFieldValueAsString({
          fieldDefinition: this.fieldDefinition,
          unit: this.unit,
          value: this.value,
          withUnit: this.withUnit,
        });
      });
    });

    it('returns the value as is on a normal field', function () {
      return expect(this.getFieldValueAsStringCall()).to.eql('some value');
    });

    it('formats to integer on an integer field', function () {
      this.value = '12.454';
      this.fieldDefinition = { integer: true };
      return expect(this.getFieldValueAsStringCall()).to.eql('12');
    });

    it('formats to decimal (2 places) if decimal field', function () {
      this.value = '12.454';
      this.fieldDefinition = { decimal: true };
      return expect(this.getFieldValueAsStringCall()).to.eql('12.45');
    });

    it('formats to range if range field', function () {
      this.value = { max: 2, min: 1 };
      this.fieldDefinition = { type: 'range' };
      return expect(this.getFieldValueAsStringCall()).to.eql('1 - 2');
    });

    it('attaches a dollar sign if currency field', function () {
      this.fieldDefinition = { currency: true };
      return expect(this.getFieldValueAsStringCall()).to.eql('$some value');
    });

    it('returns empty string if a currency range has no value', function () {
      this.value = { max: '', min: '' };
      this.fieldDefinition = { currency: true, type: 'range' };
      return expect(this.getFieldValueAsStringCall()).to.eql('');
    });

    it('add unit if the field has one', function () {
      this.unit = 'PSF/MO';
      this.value = { max: 2, min: 1 };
      this.fieldDefinition = { type: 'range', units: ['PSF/YR', 'PSF/MO'] };
      return expect(this.getFieldValueAsStringCall()).to.eql('1 - 2 PSF/MO');
    });

    it('add unit if the field definition has one', function () {
      this.value = '12.454';
      this.fieldDefinition = { decimal: true, units: ['SF'] };
      return expect(this.getFieldValueAsStringCall()).to.eql('12.45 SF');
    });

    return it('does not add unit if withUnit is false', function () {
      this.value = '12.454';
      this.fieldDefinition = { decimal: true, units: ['SF'] };
      this.withUnit = false;
      return expect(this.getFieldValueAsStringCall()).to.eql('12.45');
    });
  });

  return describe('getSuggestedFields', function () {
    beforeEach(function () {
      this.atlasFieldsService.getSuggestedFieldKeys.returns([
        'field1',
        'field2',
        'field3',
        'field4',
        'field5',
        'field6',
        'field7',
        'field8',
      ]);
      this.tourbookFieldsService._getEstMonthlyRent = sinon
        .stub()
        .returns('12');
      return (this.fields = this.tourbookFieldsService.getSuggestedFields({
        fieldDefinitions: {},
        fields: {
          section1: {
            field1: {
              order: 0,
              value: 'a',
            },
          },
        },
        previousFields: {
          field3: { lastUsed: 1 },
          field4: { lastUsed: 2 },
          field5: { lastUsed: 3 },
          field6: {},
        },
        section: 'section1',
        xceligentData: {
          field2: 'value2',
          field3: 'value3',
        },
      }));
    });

    return it('returns the fields in the correct order', function () {
      return expect(this.fields).to.eql([
        {
          hasValue: true,
          isAtlas: true,
          key: 'field3',
          lastUsed: 1,
          value: 'value3',
        },
        {
          hasValue: true,
          isAtlas: false,
          key: 'estMonthlyRent',
          lastUsed: 0,
          value: '12',
        },
        {
          hasValue: true,
          isAtlas: true,
          key: 'field2',
          lastUsed: 0,
          value: 'value2',
        },
        {
          hasValue: false,
          isAtlas: true,
          key: 'field5',
          lastUsed: 3,
          value: undefined,
        },
        {
          hasValue: false,
          isAtlas: true,
          key: 'field4',
          lastUsed: 2,
          value: undefined,
        },
        {
          hasValue: false,
          isAtlas: true,
          key: 'field6',
          lastUsed: 1,
          value: undefined,
        },
        {
          hasValue: false,
          isAtlas: true,
          key: 'field7',
          lastUsed: 0,
          value: undefined,
        },
        {
          hasValue: false,
          isAtlas: true,
          key: 'field8',
          lastUsed: 0,
          value: undefined,
        },
      ]);
    });
  });
});
