const React = require('react');
const _ = require('lodash');
const BaseTab = require('../base_tab');
var classNames = require('classnames/bind');
const importedStyles = require('./index.styl');
const cx = classNames.bind(importedStyles);
const ContactInputsBuilder = require('../cover_view/inputs_builders/contact_inputs_builder');
const PdfBrokersTemplateContainer = require('../pdf_brokers_template/container');
const ReadyRegistry = require('@utils/ready_registry');
const TabViewContainer = require('../tab_view/container');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class BrokerView extends React.Component {
  static initClass() {
    this.propTypes = {
      contactInfos: PropTypes.object,
      brokerInfos: PropTypes.object,
      currentTab: PropTypes.object.isRequired,
      firebaseService: PropTypes.object.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      setCurrentTab: PropTypes.func.isRequired,
      tourbookFields: PropTypes.object.isRequired,
      track: PropTypes.func.isRequired,
      createBrokerInfo: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.state = this._buildStateFromProps(props);
  }

  componentWillMount() {
    if (!this.props.currentTab.key) {
      const brokerInfoIds = [];
      _.each(this.props.brokerInfos, (value, key) => {
        brokerInfoIds.push(key);
      });
      this.props.setCurrentTab({
        key: `contact_info_${brokerInfoIds[0]}`,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const resetState =
      Array.from(_.keys(nextProps.brokerInfos)).includes(
        this.state.newContactInfoId
      ) ||
      (this._shouldIncludeAddContactTab(nextProps.brokerInfos) &&
        !this.state.newContactInfoId) ||
      (!this._shouldIncludeAddContactTab(nextProps.brokerInfos) &&
        this.state.newContactInfoId);
    if (resetState) {
      return this.setState(this._buildStateFromProps(nextProps));
    }
  }

  _buildPdfTemplateComponent() {
    return <PdfBrokersTemplateContainer readyRegistry={new ReadyRegistry()} />;
  }
  _buildStateFromProps(props) {
    let newContactInfoId = null;
    if (this._shouldIncludeAddContactTab(props.brokerInfos)) {
      newContactInfoId = props.firebaseService.getUniqueId();
    }
    return { newContactInfoId };
  }

  _hasCrossedMaxAdditionalBrokers(brokerInfos) {
    const maxAdditionalBrokersAllowed = 3;
    const totalAdditionalBrokers = _.size(brokerInfos);
    return totalAdditionalBrokers >= maxAdditionalBrokersAllowed;
  }

  _shouldIncludeAddContactTab(brokerInfos) {
    return !this._hasCrossedMaxAdditionalBrokers(brokerInfos);
  }

  _getPropsForAddAdditionalBroker() {
    if (this._hasCrossedMaxAdditionalBrokers(this.props.brokerInfos)) {
      return null;
    }
    const addAdditionalBrokerRowProps = {
      className: cx([`add-additional-broker-row`]),
      onClick: () => {
        if (this.state.newContactInfoId) {
          return this.props.createBrokerInfo(this.state.newContactInfoId);
        }
      },
    };
    return (
      <div {...addAdditionalBrokerRowProps}>
        <div className={cx(['icon-wrapper'])}>
          <div className={cx(['plus-wrapper'])}>
            <FontAwesomeIcon name="plus" />
          </div>
        </div>
        <div className={cx(['text'])}>
          <div className={cx(['label'])}>{'Add Additional Broker'}</div>
        </div>
      </div>
    );
  }

  _shouldHideNextTab(infoId) {
    return (
      _.keys(this.props.brokerInfos).findIndex((key) => key === infoId) < 2
    );
  }
  _filterHeaderRoleField;

  _getTabConfigs() {
    const tabConfigs = [];

    _.each(this.props.brokerInfos, (info, infoId) => {
      const inputs = ContactInputsBuilder({
        forBrokerInfo: true,
        contactInfoId: infoId,
        contactInfoType: info.type,
        tourbookFields: this.props.tourbookFields,
      }).slice(2);
      return tabConfigs.push({
        component: BaseTab,
        componentProps: {
          inputs,
          postNextFactory: this._getPropsForAddAdditionalBroker.bind(this),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
        },
        showComplete: true,
        label: 'Broker Info',
        value: `contact_info_${infoId}`,
        contactInfoId: infoId,
      });
    });

    return tabConfigs;
  }
  render() {
    return (
      <TabViewContainer
        completeConfig={{
          label: 'Add Listing',
          style: 'add',
        }}
        onComplete={() => {
          this.props.track('Selects to Add a Listing', {
            additionalProperties: { source: 'next button' },
          });
          return this.props.setCurrentModal({ key: 'new-entry' });
        }}
        tabConfigs={this._getTabConfigs()}
      />
    );
  }
}

export default BrokerView;
