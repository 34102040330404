exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 5 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/crop_image_modal/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 22 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 103 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/helpers.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/helpers.styl */\n\n/* line 103 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/helpers.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/durations.styl */\n\n/* line 4 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/crop_image_modal/index.styl */\n.crop_image_modal---editor-wrapper---N0afc742p6ZyxtdLmNFkh {\n  margin-bottom: 40px;\n}\n", ""]);

// exports
exports.locals = {
	"editor-wrapper": "crop_image_modal---editor-wrapper---N0afc742p6ZyxtdLmNFkh"
};