const importedStyles = require('../../index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

const HeaderFields = (props) => {
  const coverHeaderCss = () => {
    const { top, left } = props.title;

    const additionalStyles = {};
    if (top) {
      additionalStyles['top'] = top;
    }
    if (left) {
      additionalStyles['left'] = left;
    }
    return additionalStyles;
  };

  const _getFieldId = (field) => {
    return `content-cover-fields-${field.key}`;
  };

  const _getFieldProps = (field) => {
    const fieldProps = {
      className: cx([
        `${field.key}`,
        `field`,
        `${_isFieldActive(field) ? 'active' : ''}`,
      ]),
    };
    if (props.isEditing) {
      fieldProps.onClick = () => {
        props.setCurrentTab({ key: 'tour_info' });
        return props.setCurrentField({ key: _getFieldId(field) });
      };
    }
    if (field.key === 'title') {
      fieldProps.style = { color: props.accentColor };
    }
    return fieldProps;
  };

  const _getFieldValue = (field) => {
    return props.fieldFormatterService.format(
      props.coverFields[field.key],
      field
    );
  };

  const _isFieldActive = (field) => {
    return props.isEditing && props.currentField.key === _getFieldId(field);
  };

  const dateField = props.tourbookFields.cover.fields[0];
  return (
    <>
      <div>
        {/* Date field is not draggable */}
        <div key={dateField.key} className={cx(['field-container'])}>
          <div {..._getFieldProps(dateField)}>{_getFieldValue(dateField)}</div>
        </div>
      </div>
      <div
        className={cx(['cover-header'])}
        id="coverHeader"
        style={coverHeaderCss()}
      >
        {props.tourbookFields.cover.fields.slice(1).map((field, i) => {
          return (
            <div key={field.key} className={cx(['field-container'])}>
              <div {..._getFieldProps(field)}>{_getFieldValue(field)}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};
HeaderFields.propTypes = {
  accentColor: PropTypes.string.isRequired,
  coverFields: PropTypes.object.isRequired,
  currentField: PropTypes.object,
  fieldFormatterService: PropTypes.object,
  isEditing: PropTypes.bool,
  tourbookFields: PropTypes.object,
};

module.exports = HeaderFields;
