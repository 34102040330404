const PropTypes = require('prop-types');
const _ = require('lodash');
import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';

const ErrorText = styled.div`
  color: red;
  font-size: 0.8rem;
`;

function TimeInput(props) {
  const { value, update, active } = props;
  const matchesTwelveHourRegex = (valueToTest) => {
    return /^(1[0-2]|0?[1-9]):[0-5][0-9](\s)?(AM|PM|am|pm)?$/.test(valueToTest);
  };
  const isEmptyOrWhitespace = (v) => {
    if (!v) return true;

    return v.length === 0 || v.trim().length === 0;
  };

  const [valid, setValid] = useState(true);
  const [inputValue, setInputValue] = useState(value || '');
  let inputEl = null;
  useEffect(() => {
    if (active) {
      inputEl.select();
    }
  }, [active]);
  const changeHandler = (event) => {
    const valueJustChangedByUser = event.target.value;
    setInputValue(valueJustChangedByUser);
    if (
      matchesTwelveHourRegex(valueJustChangedByUser) ||
      isEmptyOrWhitespace(valueJustChangedByUser)
    ) {
      setValid(true);
      return update(valueJustChangedByUser);
    }
    setValid(false);
  };

  return (
    <div>
      {!valid && <ErrorText>Must match 12 hour format; e.g. 1:23 PM</ErrorText>}
      <input
        value={inputValue}
        autoFocus={active}
        ref={(node) => (inputEl = node)}
        onChange={changeHandler}
      ></input>
    </div>
  );
}

TimeInput.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default TimeInput;
