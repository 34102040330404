const React = require('react');

class AdminPage extends React.Component {
  render() {
    return (
      <div>
        Admin
      </div>
    );
  }
}

module.exports = AdminPage;
