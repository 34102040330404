const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const CustomInput = require('./components/custom_input');
const DatePicker = require('react-datepicker').default;
const moment = require('moment');
const PropTypes = require('prop-types');
const React = require('react');

class DateInput extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      update: PropTypes.func.isRequired,
      value: PropTypes.string,
    };
  }
  render() {
    const selectedDate = this._getSelectedDate();
    return (
      <div className={cx(['root'])}>
        <DatePicker
          customInput={
            <CustomInput
              {...{
                active: this.props.active,
                onClear: () => this.props.update(''),
              }}
            />
          }
          dateFormat="MMMM d, yyyy"
          onChange={(value) =>
            this.props.update(this._convertDateToString(value))
          }
          popoverAttachment="top right"
          popoverTargetAttachment="bottom right"
          selected={selectedDate && selectedDate.toDate()}
        />
      </div>
    );
  }

  _convertDateToString(rawValue) {
    const value = moment(rawValue);
    if (value.isValid()) {
      // Extract just the date, disregarding time,
      // and create a new so it uses the current user's timezone
      return moment(value.format('YYYY-MM-DD')).toISOString();
    } else {
      return moment().toISOString();
    }
  }

  _getSelectedDate() {
    if (!this.props.value) {
      return;
    }
    let momentDate = moment(this.props.value, 'MMMM D, YYYY'); // 1.0 stores in this format
    if (!momentDate.isValid()) {
      momentDate = moment(this.props.value);
    } // 2.0 stores in ISO format
    if (momentDate.isValid()) {
      return momentDate;
    }
  }
}
DateInput.initClass();

module.exports = DateInput;
