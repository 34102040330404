///
/// Created in part by ChatGPT
///
const ClampContent = ({ children, lineCount, style }) => {
  return (
    <div
      style={{
        ...style, // order of expansion intentional; want to prevent future users from overriding clamping logic
        display: '-webkit-box',
        WebkitLineClamp: lineCount,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  );
};

export default ClampContent;
