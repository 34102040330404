const _ = require('lodash');
const snakeCase = require('snake-case');

const buildMockService = function (name) {
  const Service = require(`../src/services/${snakeCase(name)}`);
  const service = sinon.createStubInstance(Service);
  _.each(
    Service.nestedServices,
    (NestedService, key) =>
      (service[key] = sinon.createStubInstance(NestedService))
  );
  return service;
};

const buildMockServices = (names) =>
  _.chain(names)
    .map((name) => [`${name}`, buildMockService(name)])
    .fromPairs()
    .value();

module.exports = { buildMockService, buildMockServices };
