/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const ClearbitService = require('./clearbit_service');

describe('ClearbitService', function () {
  beforeEach(function () {
    this.icons = [
      { domain: 'www.teamico.co.jp', name: 'Ico' },
      { domain: 'www.a.gov', name: 'Z' },
      { domain: 'www.never-coming-out.co.jp', name: 'Last Guardian' },
      { domain: 'www.valve.com', name: 'Half Life' },
      { domain: 'www.blizzard.net', name: 'Diablo 1' },
      { domain: 'www.quantic-dream.co.fr', name: 'Indigo Prophecy' },
    ];
    this.sortedIcons = [
      { domain: 'www.blizzard.net', name: 'Diablo 1' },
      { domain: 'www.valve.com', name: 'Half Life' },
      { domain: 'www.a.gov', name: 'Z' },
      { domain: 'www.teamico.co.jp', name: 'Ico' },
      { domain: 'www.quantic-dream.co.fr', name: 'Indigo Prophecy' },
      { domain: 'www.never-coming-out.co.jp', name: 'Last Guardian' },
    ];
    this.iconResponse = { data: this.icons };
    return (this.service = new ClearbitService({
      axios: sinon.stub().resolves(this.iconResponse),
    }));
  });

  return describe('when I search for icons', function () {
    beforeEach(function () {
      return (this.iconPromise = this.service.search('term'));
    });

    return it('sorts the returned icons properly', function () {
      return expect(this.iconPromise).to.eventually.eql(this.sortedIcons);
    });
  });
});
