const _ = require('lodash');
const { build } = require('@components/container_helpers');
const PdfPageWithFooter = require('./');
const { getBrokerLogo } = require('@utils/tourbook_cover_parser');
const PropTypes = require('prop-types');

const mapStateToProps = function (state, ownProps) {
  let logo;
  const { cover, settings } = state.tourbookEditorPage.data.content;
  let brokerInfos = [];
  if (settings.display.footerText) {
    brokerInfos = _.filter(
      cover.contactInfos,
      (contactInfo) =>
        contactInfo.type === 'broker' &&
        _.some(['name', 'email', 'phone'], (field) => contactInfo[field])
    );
  }
  if (settings.display.footerLogo) {
    logo = getBrokerLogo(cover);
  }
  return {
    accentColor: settings.accentColor,
    brokerInfos,
    logo,
    shouldShowPageNumbers: (settings.display.pageNumbers && !ownProps.hidePageNumber)
  };
};

const propTypes = {
  hidePageNumber: PropTypes.bool.isRequired
};

module.exports = build({
  component: PdfPageWithFooter,
  mapStateToProps,
});
