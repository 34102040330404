const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const { locateService } = require('@services/service_locator');
const DataSectionContainer = require('./components/data_section/container');
const ImagesSectionContainer = require('./components/images_section/container');
const HeaderSectionContainer = require('./components/header_section/container');
const NotesSectionContainer = require('../shared/notes_section/container');
const PdfPageWithFooterContainer = require('../pdf_page_with_footer/container');
const {
  fieldKeyToFieldDefinitionKey,
} = require('@components/pages/tourbook_editor_page/multi_space_util');

class PdfEntryTemplate extends React.Component {
  static initClass() {
    this.propTypes = {
      entry: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      fieldDefinitions: PropTypes.object.isRequired,
      isDraggingImage: PropTypes.bool,
      isEditing: PropTypes.bool,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
      pdfService: PropTypes.object.isRequired,
      summaryService: PropTypes.object.isRequired,
    };
  }

  componentWillMount() {
    if (this.props.entryId) {
      this.setComponentReady = this.props.readyRegistry.register(
        `PdfEntryTemplate-${this.props.entryId}`
      );
    }
  }

  componentDidMount() {
    if (this.setComponentReady) {
      this.setComponentReady(true);
    }
  }

  componentWillUnmount() {
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  constructor(props) {
    super(props);
    this.tourbookFieldsService = locateService('tourbookFieldsService');
    this.pageNumberService = locateService('pageNumberService');
  }

  render() {
    return (
      <div className={cx([`root`, `pdf-template`, `${this.props.isEditing ? 'is-editing' : ''}`])}>
        <PdfPageWithFooterContainer
          pageNumber={this._getPageNumber()}
          hidePageNumber={this.props.entry.hidden}
          readyRegistry={this.props.readyRegistry}
        >
          <div className={cx(['entry-content'])}>
            <div
              className={cx([`rows`, `${this.props.isDraggingImage ? 'is-dragging-image' : ''}`])}
            >
              <div className={cx(['row'])}>
                <HeaderSectionContainer
                  entryId={this.props.entryId}
                  isEditing={this.props.isEditing}
                />
              </div>
              <div className={cx(['row'])}>
                <div className={cx(['data-section'])}>
                  {(() => {
                    if (this.props.entry.type) {
                      return (
                        <DataSectionContainer
                          entryId={this.props.entryId}
                          isEditing={this.props.isEditing}
                          section={this.props.summaryService.typeToSectionKey(
                            this.props.entry.type
                          )}
                        />
                      );
                    } else if (
                      this.props.isEditing &&
                      !this.props.entry.visitedTabs?.space_or_terms
                    ) {
                      return <div className={cx(['blank'])} />;
                    }
                  })()}
                </div>
                <div className={cx(['data-section', 'property'])}>
                  {this._shouldRenderPropertySectionZeroState() ? (
                    <div className={cx(['blank'])} />
                  ) : (
                    <DataSectionContainer
                      entryId={this.props.entryId}
                      isEditing={this.props.isEditing}
                      section="property"
                    />
                  )}
                </div>
              </div>
              {this.props.pdfService.shouldEntryNoteBeIncludedInPdf(this.props.entry) && (
                <div
                  className={cx(['row', 'notes-wrapper'])}
                  ref={(node) => {
                    return (this.NotesSectionParent = node);
                  }}
                >
                  <NotesSectionContainer
                    entryId={this.props.entryId}
                    isEditing={this.props.isEditing}
                    parent={this.NotesSectionParent}
                  />
                </div>
              )}
            </div>
            <div className={cx(['images-section'])}>
              <ImagesSectionContainer
                canDropImages={this._canDropImages()}
                entryId={this.props.entryId}
                isEditing={this.props.isEditing}
                readyRegistry={this.props.readyRegistry}
              />
            </div>
          </div>
        </PdfPageWithFooterContainer>
      </div>
    );
  }

  _canDropImages() {
    return (
      this.props.isEditing &&
      this.props.currentView.key === 'entry-images' &&
      this.props.entry.hideImagesZeroState
    );
  }

  _getPageNumber() {
    return this.pageNumberService.getEntryPageNumber(
      _.pick(this.props, ['entries', 'entryId', 'tourbook'])
    );
  }

  _hasPropertyData() {
    return _.chain(this.props.entry.fields.property)
      .map((field, key) => {
        const fieldDefinitionKey = fieldKeyToFieldDefinitionKey(key);
        return this.tourbookFieldsService.getFieldValueAsString({
          fieldDefinition: this.props.fieldDefinitions[fieldDefinitionKey],
          value: field.value,
        });
      })
      .some()
      .value();
  }

  _shouldRenderPropertySectionZeroState() {
    return (
      this.props.isEditing && !this._hasPropertyData() && !this.props.entry.visitedTabs?.property
    );
  }
}
PdfEntryTemplate.initClass();

module.exports = PdfEntryTemplate;
