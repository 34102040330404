const BaseTab = require('../base_tab');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const PdfMapTemplateContainer = require('../pdf_map_template/container');
const ReadyRegistry = require('@utils/ready_registry');

class MapView extends React.Component {
  static initClass() {
    this.propTypes = { track: PropTypes.func };
  }

  componentDidMount() {
    return this.props.track('Views Map');
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <BaseTab
          inputs={this._buildInputs(this.props.mapOptionsKey)}
          pdfTemplateComponentFactory={() => {
            return <PdfMapTemplateContainer isEditing={true} mapOptionsKey={this.props.mapOptionsKey} readyRegistry={new ReadyRegistry()} />;
          }}
          postInputsFactory={this._renderHint} />
      </div>
    );
  }

  _buildInputs(mapOptionsKey) {
    const inputs = [
      {
        disableEnterNavigation: true,
        path: ['content', 'settings', this.props.mapOptionsKey, 'style'],
        type: 'map_style',
      },
      {
        label: 'Legend Title',
        path: ['content', 'settings', this.props.mapOptionsKey, 'itineraryTitle'],
        type: 'text',
      },
      {
        disableEnterNavigation: true,
        label: 'Map Legend',
        path: ['content', 'settings', this.props.mapOptionsKey, 'itinerary'],
        type: 'toggle',
      },
    ];
    if (mapOptionsKey !== 'mapOptions') {
      inputs.push({
        disableEnterNavigation: true,
        label: 'Map Name',
        path: ['content', 'settings', this.props.mapOptionsKey, 'name'],
        type: 'text',
      });
    }
    return inputs;
  }

  _renderHint() {
    return (
      <div className={cx(['hint-container'])}>
        <div className={cx(['divider'])} />
        <div className={cx(['hint'])}>
          Feel free to move and adjust the map on the right to suit your needs.
        </div>
      </div>
    );
  }
}
MapView.initClass();

module.exports = MapView;
