const React = require('react');
const PropTypes = require('prop-types');
const importedStyles = require('.././index.styl');
var classNames = require('classnames/bind');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');
const cx = classNames.bind(importedStyles);
const InputSetContainer = require('../../input_set/container');

class EmptyFieldReplacements extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  static initClass() {
    this.propTypes = {
      optionList: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          path: PropTypes.arrayOf(PropTypes.string),
        })
      ).isRequired,
    };
  }

  _getFieldId(path) {
    return path.join('-');
  }

  toggleExpand() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    return (
      <div>
        <div
          className={cx(['empty-field-replacements', this.state.expanded ? 'expanded' : ''])}
          onClick={() => this.toggleExpand()}
        >
          <h6 className={cx(['selected-fields-heading'])}>Replacement Texts for Empty Fields</h6>
          <FontAwesomeIcon name="chevron-down" />
        </div>
        {this.state.expanded && <InputSetContainer inputs={this.props.optionList} />}
      </div>
    );
  }
}

EmptyFieldReplacements.initClass();

module.exports = EmptyFieldReplacements;
