const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PdfEntryWrapperTemplate = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'navigateToCurrentEntryField',
]);

const mapStateToProps = function (state, { entryId }) {
  const { data } = state.tourbookEditorPage;
  const entry = data.content.entries[entryId];
  const result = _.pick(entry.fields.header, [
    'city',
    'propertyName',
    'state',
    'zip',
  ]);
  result.address = entry.fields.header.CorrectedAddress1;
  result.entryAccentColor = data.content.settings.accentColor;
  result.tourbook = data;
  return result;
};

const propTypes = {
  entryId: PropTypes.string.isRequired,
  pageNumber: PropTypes.number,
};

module.exports = build({
  component: PdfEntryWrapperTemplate,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames: ['fieldFormatterService', 'entryNumberService'],
});
