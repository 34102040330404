const _ = require('lodash');
const nonNumberedTypes = ['tourbook-notes'];
class EntryNumberService {
  getEntryNumber({ entries, entryId }) {
    return (
      _.chain(entries)
        .map((entry, entryId) => ({
          order: entry.order,
          entryId,
          key: entry.key,
          hidden: entry.hidden,
        }))
        .filter(
          (entry) =>
            !nonNumberedTypes.includes(entry.key) && !entry.hidden
        )
        .sortBy('order')
        .findIndex({ entryId })
        .value() + 1
    );
  }
  shouldDisplayEntryNumber(type, entry) {
    return !nonNumberedTypes.includes(type) && !entry.hidden;
  }
}

module.exports = EntryNumberService;
