exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 23 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/import_from_xpro_modal/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/import_from_xpro_modal/index.styl */\n.import_from_xpro_modal---root---1fFVQZVWgwYJS1WcPVesYz {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n}\n\n/* line 8 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/import_from_xpro_modal/index.styl */\n.import_from_xpro_modal---text---1vZW8P58kRd2GnZK6LzDgn {\n  font-size: 24px;\n  font-weight: 500;\n  margin-bottom: 10px;\n}\n\n/* line 13 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/import_from_xpro_modal/index.styl */\n.import_from_xpro_modal---error---2UrL-uJsA0qjJX3sUVzv2z {\n  color: #f94d3e;\n  font-size: 18px;\n  margin-bottom: 10px;\n}\n\n/* line 18 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/import_from_xpro_modal/index.styl */\n.import_from_xpro_modal---contact---2jjWVlV0d6mq2uawnz8Y56 {\n  margin-bottom: 20px;\n}\n\n/* line 21 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/import_from_xpro_modal/index.styl */\n.import_from_xpro_modal---contact---2jjWVlV0d6mq2uawnz8Y56 a {\n  color: #006bb6;\n  font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"root": "import_from_xpro_modal---root---1fFVQZVWgwYJS1WcPVesYz",
	"text": "import_from_xpro_modal---text---1vZW8P58kRd2GnZK6LzDgn",
	"error": "import_from_xpro_modal---error---2UrL-uJsA0qjJX3sUVzv2z",
	"contact": "import_from_xpro_modal---contact---2jjWVlV0d6mq2uawnz8Y56"
};