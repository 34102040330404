const _ = require('lodash');

class PdfService {
  constructor({ axios }) {
    this.axios = axios;
  }

  shouldPageBeIncludedInPdf(page, entry) {
    // Only additional notes have restrictions - Additional images and Floor plans don't
    if (page.type === 'additional_note') {
      if (entry.fields.notes?.notes?.secret) {
        // The main note is a secret so all additional notes are excluded too
        return false;
      } else if (page.secret) {
        return false;
      }
    }
    return true;
  }
  shouldEntryNoteBeIncludedInPdf(entry) {
    return !(entry?.fields?.notes?.notes?.secret || entry?.fields?.notes?.secret);
  }
  shouldListingBeIncludedInPdf(entry) {
    return !entry.hidden;
  }
  async getFileAndFileSize(docraptorDownloadUrl) {
    const response = await this.axios.get(docraptorDownloadUrl, { responseType: 'blob' });
    return { file: response.data, fileSize: response.data.size };
  }
  downloadFile(title, data) {
    // Source: https://stackoverflow.com/a/65050772
    // Source: https://stackoverflow.com/a/45192210
    const fileName = `${title}.pdf`;

    window.URL = window.URL || window.webkitURL;

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(data));
    downloadLink.setAttribute('download', fileName);
    downloadLink.click();
  }
}

module.exports = PdfService;
