const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const EntrySummary = require('./');
const { locateServices } = require('@services/service_locator');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'navigateToEntryField',
  'setCurrentView',
]);

const mapStateToProps = function (state, ownProps) {
  const availableFieldDefinitions = {
    ...state.tourbookEditorPage.previousFields,
    ...state.tourbookEditorPage.fieldDefinitions,
  };
  const {
    data: { content },
    tourbookId,
  } = state.tourbookEditorPage;
  const { summaryService } = locateServices(['summaryService']);
  const summaryFields = summaryService.filterToPopulatedSummaryFields(
    summaryService.getOrderedSummaryFields(content.settings.summaryFields),
    state.tourbookEditorPage.data,
    availableFieldDefinitions
  );
  const { settings } = content;

  return {
    accentColor: content.settings.accentColor,
    entry: content.entries[ownProps.entryId],
    style: { fontSize: settings.fontSize ? `${settings.fontSize}px` : undefined },
    noteFontSize: settings.summary?.note?.fontSize,
    emptyFieldReplacements: settings.summary?.emptyFieldReplacements,
    availableFieldDefinitions,
    tourbookId,
    summaryFields,
  };
};

const serviceNames = [
  'fieldFormatterService',
  'tourbookEntryImagesService',
  'tourbookFields',
  'tourbookFieldsService',
  'tourbookEntryFieldsService',
  'entryNumberService',
  'firebaseService',
  'summaryService',
];

module.exports = build({
  component: EntrySummary,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames,
});
