const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class GooglePlaces extends React.Component {
  static initClass() {
    this.propTypes = {
      venues: PropTypes.array,
      wide: PropTypes.bool,
    };
  }

  render() {
    // for existing tourbooks with bad data
    const venues = this.props.venues?.filter((venue) => {
      if (venue.name.match(/[\u3400-\u9FBF]/)) {
        // non-latin characters break docraptor // TODO: remove once we remove reliance on docraptor
        return false;
      }
      return true;
    });
    return (
      <div className={cx([`root`, `${this.props.wide ? 'wide' : ''}`])}>
        {venues != null && venues.length > 0
          ? venues.map((venue, index) => (
              <div key={index} className={cx(['venue'])}>
                <img src={venue.photoUrl} />
                <div className={cx(['info'])}>
                  <div className={cx(['name'])}>{venue.name}</div>
                  <div className={cx(['category'])}>{venue.category}</div>
                </div>
              </div>
            ))
          : undefined}
      </div>
    );
  }
}
GooglePlaces.initClass();

module.exports = GooglePlaces;
