exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 29 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 22 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/sizes.styl */\n\n/* line 4 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/index.styl */\n.tourbook_editor_page---root---q0feOp56OEhdkg6QVmWfu {\n  display: -ms-flexbox;\n  display: flex;\n  font-family: $body-font;\n}\n\n/* line 8 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/index.styl */\n.tourbook_editor_page---root---q0feOp56OEhdkg6QVmWfu .tourbook_editor_page---view-navigation---f1r4E4TSYQDP32omZ3LH4 {\n  bottom: 0;\n  position: absolute;\n  top: 60px;\n  width: 255px;\n  font-family: $body-font;\n}\n\n/* line 15 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/index.styl */\n.tourbook_editor_page---root---q0feOp56OEhdkg6QVmWfu .tourbook_editor_page---view---1MCSl9xoEgmYArcl3xPVDt {\n  background-color: #f1f1f1;\n  bottom: 0;\n  left: 255px;\n  position: absolute;\n  right: 0;\n  top: 60px;\n}\n\n/* line 24 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/index.styl */\n.pdf-template {\n  box-sizing: content-box;\n  height: 611.9px;\n  overflow: hidden;\n  position: relative;\n  width: 792px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "tourbook_editor_page---root---q0feOp56OEhdkg6QVmWfu",
	"view-navigation": "tourbook_editor_page---view-navigation---f1r4E4TSYQDP32omZ3LH4",
	"view": "tourbook_editor_page---view---1MCSl9xoEgmYArcl3xPVDt"
};