const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PdfEntryImageDropTarget = require('../../../pdf_entry_image_drop_target');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');

class ImagesSection extends React.Component {
  static initClass() {
    this.propTypes = {
      canDropImages: PropTypes.bool,
      entry: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      isDraggingImage: PropTypes.bool,
      isEditing: PropTypes.bool,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
      setCurrentView: PropTypes.func.isRequired,
      setIsDraggingImage: PropTypes.func.isRequired,
      trackDragImageToPdf: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <div className={cx(['root'])}>
        {this._getSizes().map((size, index) => {
          return (
            <div {...this._getWrapperProps(index, size)}>
              <PdfEntryImageDropTarget {...this._getDropTargetProps(index)} />
            </div>
          );
        })}
      </div>
    );
  }

  _getDropTargetProps(index) {
    const imagePath = [
      'content',
      'entries',
      this.props.entryId,
      'imagesShown',
      index,
    ];
    return {
      canDropImages: this.props.canDropImages,
      entryLocation: this.props.entry.location,
      image: this.props.entry.imagesShown?.[index],
      imagePath,
      index,
      isDragging: this.props.isDraggingImage,
      isEditing: this.props.isEditing,
      readyRegistry: this.props.readyRegistry,
      setIsDragging: this.props.setIsDraggingImage,
      update: (value) => {
        this.props.update({ path: imagePath, value });
        if (value) {
          return this.props.trackDragImageToPdf(value);
        }
      },
    };
  }

  _getSizes() {
    let sizes = (this.props.entry.layout || '1_2').split('_');
    if (sizes.length === 1 && sizes[0] === '1') {
      sizes = ['3'];
    }
    return sizes;
  }

  _getWrapperProps(index, size) {
    const wrapperProps = {
      className: cx([`wrapper`, `size_${size}`]),
      key: index,
    };
    if (this.props.isEditing) {
      wrapperProps.onClick = () =>
        this.props.setCurrentView({
          entryId: this.props.entryId,
          key: 'entry-images',
        });
    }
    return wrapperProps;
  }
}
ImagesSection.initClass();

module.exports = ImagesSection;
