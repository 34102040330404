const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PropTypes = require('prop-types');
const RecommendedFieldsModal = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'addAdditionalFields',
  'trackImportRecommendedFields',
]);

const mapStateToProps = function (state, ownProps) {
  const entry =
    state.tourbookEditorPage.data.content.entries[ownProps.options.entryId];
  return {
    fieldDefinitions: state.tourbookEditorPage.fieldDefinitions || {},
    fields: entry.fields || {},
    previousFields: state.tourbookEditorPage.previousFields || {},
    n360Data: entry.n360Data || {},
  };
};

const propTypes = {
  options: PropTypes.shape({
    entryId: PropTypes.string,
  }).isRequired,
};

const serviceNames = ['tourbookFieldsService'];

module.exports = build({
  component: RecommendedFieldsModal,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames,
});
