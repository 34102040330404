const _ = require('lodash');
const { arrayMove } = require('react-sortable-hoc');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const InputRowContainer = require('@components/shared/input_row/container');
const Hint = require('@components/shared/hint');
const PropTypes = require('prop-types');
const React = require('react');
const ReactDOM = require('react-dom');
const SortableList = require('./sortable_list');
const $ = require('jquery');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

var InputSet = (function () {
  let scrollContainerId = undefined;
  InputSet = class InputSet extends React.Component {
    static initClass() {
      scrollContainerId = 'input-set-scrollable-parent';

      this.propTypes = {
        currentField: PropTypes.object,
        inputs: PropTypes.array.isRequired,
        keyboardShortcutService: PropTypes.object.isRequired,
        next: PropTypes.object,
        onNext: PropTypes.func,
        postInputsComponent: PropTypes.element,
        postNextComponent: PropTypes.element,
        reorderEntryFields: PropTypes.func.isRequired,
        scrollService: PropTypes.object.isRequired,
        setCurrentField: PropTypes.func.isRequired,
      };
    }

    constructor(props) {
      super(props);
      this._goToNextTab = this._goToNextTab.bind(this);
      this._navigateBackward = this._navigateBackward.bind(this);
      this._navigateForward = this._navigateForward.bind(this);
      this._onInputReorder = this._onInputReorder.bind(this);
      this._renderInputRow = this._renderInputRow.bind(this);
      this.state = { inputs: props.inputs };
      this.inputDoms = {};
    }

    componentDidUpdate(prevProps, prevState) {
      if (!this._areInputsEqual(prevState.inputs, this.state.inputs)) {
        this._initialize();
      }
      if (
        this.props.currentField.key &&
        !_.isEqual(this.props.currentField, prevProps.currentField)
      ) {
        return this.props.scrollService.scrollToElement({
          containerId: scrollContainerId,
          element: this.inputDoms[this.props.currentField.key],
        });
      }
    }

    componentWillMount() {
      this._initialize();
      return (this.unregisterShortcuts =
        this.props.keyboardShortcutService.register({
          13: () => this._navigateForward({ isEnterNavigation: true }),
          9: this._navigateForward,
          'shift-9': this._navigateBackward,
        }));
    }

    componentWillReceiveProps(nextProps) {
      return this.setState({ inputs: nextProps.inputs });
    }

    componentWillUnmount() {
      return typeof this.unregisterShortcuts === 'function'
        ? this.unregisterShortcuts()
        : undefined;
    }

    render() {
      return (
        <div className={cx(['root'])}>
          <div
            className={cx([
              `input-rows`,
              `${this.state.inputs.length > 1 ? 'multiple-inputs' : ''}`,
            ])}
          >
            {this._hasSortableInputs() ? (
              <div>
                {this._getPreSortableInputs().map((input) =>
                  this._renderInputRow(input)
                )}
                {this._renderSortableList()}
                {this._getPostSortableInputs().map((input) =>
                  this._renderInputRow(input)
                )}
              </div>
            ) : (
              this.state.inputs.map((input) => this._renderInputRow(input))
            )}
          </div>
          <div className={cx(['post-inputs-component'])}>
            {this.props.postInputsComponent}
          </div>
          {this._renderNext()}
          <div className={cx(['post-next-component'])}>
            {this.props.postNextComponent}
          </div>
        </div>
      );
    }

    _areInputsEqual(inputsA, inputsB) {
      const inputsAKeys = inputsA.map(this._getInputKey);
      const inputsBKeys = inputsB.map(this._getInputKey);
      return _.isEqual(inputsAKeys, inputsBKeys);
    }

    _decrementInternalIndex(currentField) {
      const { internalIndex, key } = currentField;
      return this.props.setCurrentField({
        internalIndex: internalIndex - 1,
        key,
      });
    }

    _getInputKey(input) {
      return input.path.join('-');
    }

    _getInputKeys() {
      return _.concat(_.map(this.state.inputs, this._getInputKey), 'next');
    }

    _getPostSortableInputs() {
      const index = _.findLastIndex(this.state.inputs, this._isInputSortable);
      return this.state.inputs.slice(index + 1);
    }

    _getPreSortableInputs() {
      const index = _.findIndex(this.state.inputs, this._isInputSortable);
      return this.state.inputs.slice(0, index);
    }

    _getSortableInputs() {
      return this.state.inputs.filter(this._isInputSortable);
    }

    _goToNextTab() {
      return this.props.onNext(this.props.next.value);
    }

    _hasSortableInputs() {
      return this._getSortableInputs().length > 0;
    }

    _incrementInternalIndex(currentField) {
      const { internalIndex, key } = currentField;
      return this.props.setCurrentField({
        internalIndex: internalIndex + 1,
        key,
      });
    }

    _initialize() {
      if (!this.props.currentField.key) {
        return this.props.setCurrentField({ key: this._getInputKeys()[0] });
      }
    }

    _isInputSortable(input) {
      return input.options?.sortable;
    }

    _navigateBackward() {
      const inputKeys = this._getInputKeys(this.props);
      const currentIndex = _.indexOf(inputKeys, this.props.currentField.key);
      const currentInput = this.state.inputs[currentIndex];
      const previousIndex = currentIndex - 1;
      if (
        this._shouldDecrementInternalIndex({
          currentField: this.props.currentField,
          currentInput,
        })
      ) {
        return this._decrementInternalIndex(this.props.currentField);
      } else if (previousIndex >= 0) {
        return this.props.setCurrentField({ key: inputKeys[previousIndex] });
      }
    }

    _navigateForward(...args) {
      let val = args[0],
        obj = val != null ? val : {},
        val1 = obj.currentField,
        currentField = val1 != null ? val1 : {},
        { isEnterNavigation } = obj;
      if (!currentField.key) {
        ({ currentField } = this.props);
      }
      const inputKeys = this._getInputKeys();
      const currentIndex = _.indexOf(inputKeys, currentField.key);
      const currentInput = this.state.inputs[currentIndex];
      if (currentInput?.disableEnterNavigation && isEnterNavigation) {
        return;
      }
      const nextIndex = currentIndex + 1;
      if (this._shouldIncrementInternalIndex({ currentField, currentInput })) {
        return this._incrementInternalIndex(currentField);
      } else if (nextIndex < inputKeys.length) {
        return this.props.setCurrentField({ key: inputKeys[nextIndex] });
      } else if (isEnterNavigation) {
        return this._goToNextTab();
      }
    }

    _onInputReorder({ newIndex, oldIndex }) {
      const newSortableInputs = arrayMove(
        this._getSortableInputs(),
        oldIndex,
        newIndex
      );
      const newInputs = _.concat(
        this._getPreSortableInputs(),
        newSortableInputs,
        this._getPostSortableInputs()
      );
      this.setState({ inputs: newInputs });
      const fieldKeys = _.map(
        newSortableInputs,
        (input) => input.path[input.path.length - 2]
      );
      return this.props.reorderEntryFields({
        fieldKeys,
        section: newSortableInputs[0].options.section,
      });
    }

    _renderInputRow(input, dragHandle) {
      const key = this._getInputKey(input);
      return (
        <InputRowContainer
          active={this.props.currentField.key === key}
          dragHandle={dragHandle}
          input={input}
          key={key}
          navigateForward={(value) => {
            if (input.options?.nextField) {
              return this.props.setCurrentField({
                key: _.find(input.options.nextField, ['value', value]).id,
              });
            } else {
              return this._navigateForward({ currentField: { key } });
            }
          }}
          onClick={() => this.props.setCurrentField({ key })}
          ref={(component) => {
            return (this.inputDoms[key] = ReactDOM.findDOMNode(component));
          }}
          shouldDisplayHint={true}
          className={input.spacedQuestion ? 'question' : undefined}
        />
      );
    }

    _renderNext() {
      if (!this.props.next) {
        return;
      }
      const active = this.props.currentField.key === 'next';
      const nextRowProps = {
        className: cx([`next-row`, `${active ? 'active' : ''}`]),
        onClick: this._goToNextTab,
        ref: (node) => {
          return (this.inputDoms['next'] = node);
        },
      };
      return (
        <div {...nextRowProps}>
          <div className={cx(['text'])}>
            <div className={cx(['prefix'])}>Next:</div>
            <div className={cx(['label'])}>
              {this._changeNextImagesToNextImagesFloorPlanAndAdditionalNote(
                this.props.next.label
              )}
            </div>
          </div>
          <div className={cx(['icon-wrapper'])}>
            {this.props.next.style === 'add' ? (
              <div className={cx(['plus-wrapper'])}>
                <FontAwesomeIcon name="plus" />
              </div>
            ) : (
              <FontAwesomeIcon name="chevron-right" />
            )}
          </div>
          <div className={cx(['hint-wrapper'])}>
            <Hint active={active} />
          </div>
        </div>
      );
    }

    _renderSortableList() {
      return (
        <SortableList
          getContainer={function (component) {
            return $(ReactDOM.findDOMNode(component)).closest(
              `#${scrollContainerId}`
            )[0];
          }}
          items={this._getSortableInputs()}
          lockAxis="y"
          lockToContainerEdges={true}
          onSortEnd={this._onInputReorder}
          renderInputRow={this._renderInputRow}
          useDragHandle={true}
        />
      );
    }

    _shouldDecrementInternalIndex({ currentField, currentInput }) {
      return currentInput?.internalNavigation && currentField.internalIndex;
    }

    _shouldIncrementInternalIndex({ currentField, currentInput }) {
      return (
        currentInput?.internalNavigation &&
        currentField.internalIndex + 1 < currentInput.internalNavigation
      );
    }

    _changeNextImagesToNextImagesFloorPlanAndAdditionalNote(label) {
      switch (label) {
        case 'Images':
          return 'Images, Floor Plans or Additional Notes';
        default:
          return label;
      }
    }
  };
  InputSet.initClass();
  return InputSet;
})();

module.exports = InputSet;
