const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class Image extends React.Component {
  static initClass() {
    this.propTypes = {
      backgroundSize: PropTypes.string,
      click: PropTypes.func,
      clickToEnlarge: PropTypes.bool,
      croppable: PropTypes.bool,
      entryLocation: PropTypes.object,
      height: PropTypes.string,
      image: PropTypes.object.isRequired,
      imagePath: PropTypes.array,
      logo: PropTypes.bool,
      onDelete: PropTypes.func,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      setFullImageUrl: PropTypes.func,
      setLargeImageReady: PropTypes.func,
      thumbnail: PropTypes.bool,
      width: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    this._isMounted = true;
    this.state = { error: false, imageLoaded: false };
    this.state.id = this.props.image
      ? this.props.image.cloudinaryPublicId // Based on the assumption that users won't add two same photos in a tourbook
      : this.props.imagePath?.join('-');
  }

  componentWillMount() {
    if (this.state.id) {
      this.setComponentReady = this.props.readyRegistry.register(`Image-${this.state.id}`);
    }
  }

  componentDidMount() {
    if (this.setComponentReady) {
      let intervalId;
      intervalId = setInterval(() => {
        if (this.state.imageLoaded) {
          this.setComponentReady(true);
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      }, 200);
    }

    const url = this._getUrl(this.props);
    if (url.error) {
      this.setState({ error: true });
    } else if (url) {
      this.setState({ url });
      this._loadImage(url);
      this._loadLargeImage();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    const url = this._getUrl(nextProps);
    if (url.error) {
      this.setState({ error: true });
    } else if (url) {
      this.setState({ url });
      this._loadImage(url);
      this._loadLargeImage();
    }
  }

  _loadImage(url) {
    if (!this.state.imageLoaded) {
      // This doesn't necessarily load the same image, but it gives us an approximation
      this.props.imageService.getImageFromUrl(url || this.state.url).then((image) => {
        if (this._isMounted) {
          this.setState({ imageLoaded: true });
        }
      });
    }
  }

  _loadLargeImage() {
    if (this.props.clickToEnlarge && !this.state.fullImageUrl) {
      const fullImageUrl = this._getUrl({
        ...this.props,
        thumbnail: false,
      });
      if (!fullImageUrl || fullImageUrl.error) {
        return;
      }

      this.setState({ fullImageUrl });
      if (this.props.setFullImageUrl) {
        this.props.setFullImageUrl(fullImageUrl);
      }

      // This doesn't necessarily load the same image, but it gives us an approximation
      this.props.imageService.getImageFromUrl(fullImageUrl).then((image) => {
        if (this._isMounted && this.props.setLargeImageReady) {
          this.props.setLargeImageReady(true);
        }
      });
    }
  }

  render() {
    if (this.state.error) {
      return this._renderError();
    }
    const style = {
      backgroundRepeat: 'no-repeat',
      height: this.props.height || '100%',
      width: this.props.width || '100%',
      backgroundImage: this.state.url ? `url(${this.state.url})` : undefined,
      backgroundPosition: this._getImagePosition(),
      backgroundSize: this._getImageSize(),
    };
    return (
      <div
        className={cx(['root', this.props.clickToEnlarge ? 'click-to-enlarge' : undefined])}
        style={{
          height: style.height,
          width: style.width,
        }}
      >
        <div
          ref={(node) => {
            return (this.imageRef = node);
          }}
          style={style}
          className={cx(['image'])}
          id={this.state.id}
          title={this.props.clickToEnlarge ? 'Click to enlarge' : undefined}
          onClick={(e) => {
            if (this.props.clickToEnlarge && this.props.click) {
              this.props.click();
            }
          }}
        />
        {this._renderDeleteIcon()}
        {this.state.imageLoaded && this._renderCropIcon()}
        {!this.state.imageLoaded && this._renderSpinningWheel()}
      </div>
    );
  }

  _getImagePosition() {
    if (!this.props.image) {
      return '';
    }
    if (this.props.image.offsetX != null && this.props.image.offsetY != null) {
      return `${this.props.image.offsetX}px ${this.props.image.offsetY}px`;
    } else if (this.props.logo) {
      return '0px 0px';
    } else {
      return 'center center';
    }
  }

  _getImageSize() {
    if (this.props.image.size) {
      return `${this.props.image.size}px`;
    } else {
      return this.props.backgroundSize;
    }
  }

  _getUrl(props) {
    if (!props.image || !this.imageRef) {
      return;
    }
    const options = {
      entryLocation: props.entryLocation,
      height: Math.round(this.imageRef.offsetHeight),
      logo: props.logo,
      thumbnail: props.thumbnail,
      width: Math.round(this.imageRef.offsetWidth),
    };
    try {
      return this.props.imageUrlService.getStaticUrlForImage(props.image, options);
    } catch (error) {
      console.error(error);
      return { error: true };
    }
  }

  _renderDeleteIcon() {
    if (!this.props.onDelete) {
      return;
    }
    return (
      <div className={cx(['delete'])} onClick={this.props.onDelete} title="Delete the image">
        <FontAwesomeIcon name="trash-can" />
      </div>
    );
  }

  _renderSpinningWheel() {
    if (this.props.imageLoaded) {
      return;
    }
    return (
      <div className={cx(['loading'])}>
        <FontAwesomeIcon name="spinner fa-spin-pulse" />
      </div>
    );
  }

  _renderCropIcon() {
    if (!this.props.croppable) {
      return;
    }
    const onClick = async () => {
      let width, height;
      if (this.props.useDefaultSize) {
        ({ width, height } = await this.props.imageService.getImageSize(
          this.props.image.cloudinaryPublicId
        ));
      }
      return this.props.setCurrentModal({
        ...this.props.image,
        entryLocation: this.props.entryLocation,
        height: this.props.useDefaultSize ? height : this.imageRef.offsetHeight,
        key: 'crop-image',
        path: this.props.imagePath,
        width: this.props.useDefaultSize ? width : this.imageRef.offsetWidth,
      });
    };
    return (
      <div onClick={onClick} className={cx(['crop'])}>
        <FontAwesomeIcon name="crop-simple" />
      </div>
    );
  }

  _renderError() {
    return (
      <div className={cx(['image', 'error'])}>
        <p>There was an error loading the data, sorry about that!</p>
      </div>
    );
  }
}
Image.initClass();

module.exports = Image;
