/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const TourbookEditorPageAnalyticsService = require('./tourbook_editor_page_analytics_service');
const { stubServices } = require('./service_locator');

describe('TourbookEditorPageAnalyticsService', function () {
  beforeEach(function () {
    ({
      cloudinaryService: this.cloudinaryService,
      tourbookFieldsService: this.tourbookFieldsService,
    } = stubServices(['cloudinaryService', 'tourbookFieldsService']));
    this.cloudinaryService.getImageUrl
      .withArgs({ cloudinaryPublicId: 'image1' })
      .returns('some url');

    return (this.service = new TourbookEditorPageAnalyticsService());
  });

  describe('getDataChangeEvent', () =>
    [
      {
        description: 'unwatched path',
        input: {
          path: ['some', 'path'],
        },
        output: undefined,
      },
      {
        description: 'background image (select suggestion)',
        input: {
          options: { uiType: 'suggestions' },
          path: ['content', 'cover', 'imagesShown', 'coverPhoto'],
          value: { cloudinaryPublicId: 'image1' },
        },
        output: {
          additionalProperties: {
            background_type: 'suggested background',
            cloudinary_url: 'some url',
          },
          name: 'Changes Background Image',
        },
      },
      {
        description: 'background image (select user previous)',
        input: {
          options: { uiType: 'user' },
          path: ['content', 'cover', 'imagesShown', 'coverPhoto'],
          value: { cloudinaryPublicId: 'image1' },
        },
        output: {
          additionalProperties: {
            background_type: 'previous background',
            cloudinary_url: 'some url',
          },
          name: 'Changes Background Image',
        },
      },
      {
        description: 'background image (upload)',
        input: {
          options: { uiType: 'upload' },
          path: ['content', 'cover', 'imagesShown', 'coverPhoto'],
          value: { cloudinaryPublicId: 'image1' },
        },
        output: {
          additionalProperties: {
            background_type: 'uploaded',
            cloudinary_url: 'some url',
          },
          name: 'Changes Background Image',
        },
      },
      {
        description: 'accent color',
        input: {
          path: ['content', 'settings', 'accentColor'],
          value: '#ff0000',
        },
        output: {
          additionalProperties: { color: '#ff0000' },
          name: 'Changes Accent Color',
        },
      },
      {
        description: 'cover font color',
        input: {
          path: ['content', 'settings', 'coverFontColor'],
          value: 'white',
        },
        output: {
          additionalProperties: { color: 'white' },
          name: 'Changes Font Color',
        },
      },
      {
        description: 'cover date',
        input: {
          path: ['content', 'cover', 'fields', 'date'],
          value: '2017-01-26T17:53:20+00:00',
        },
        output: {
          name: 'Edits Tour Date',
        },
      },
      {
        description: 'cover contact info type (create)',
        input: {
          path: ['content', 'cover', 'contactInfos', 'id1'],
          value: { type: 'broker' },
        },
        output: {
          additionalProperties: { choice: 'broker' },
          name: 'Selects Broker or Client Contact',
        },
      },
      {
        description: 'cover contact info type (update)',
        input: {
          path: ['content', 'cover', 'contactInfos', 'id1', 'type'],
          value: 'client',
        },
        output: {
          additionalProperties: { choice: 'client' },
          name: 'Selects Broker or Client Contact',
        },
      },
      {
        description: 'cover contact info logo (upload)',
        input: {
          options: {
            tourbook: {
              content: { cover: { contactInfos: { id1: { type: 'broker' } } } },
            },
            uiType: 'logo_search',
          },
          path: ['content', 'cover', 'contactInfos', 'id1', 'logo'],
          value: 'skipped',
        },
        output: {
          additionalProperties: {
            contact_type: 'broker',
            logo_type: 'logo finder',
          },
          name: 'Adds a Logo',
        },
      },
      {
        description: 'cover contact info logo (upload)',
        input: {
          options: {
            tourbook: {
              content: { cover: { contactInfos: { id1: { type: 'broker' } } } },
            },
            uiType: 'upload',
          },
          path: ['content', 'cover', 'contactInfos', 'id1', 'logo'],
          value: 'skipped',
        },
        output: {
          additionalProperties: {
            contact_type: 'broker',
            logo_type: 'uploaded',
          },
          name: 'Adds a Logo',
        },
      },
      {
        description: 'entry type',
        input: {
          path: ['content', 'entries', 'entry1', 'type'],
          value: 'Sale',
        },
        output: {
          additionalProperties: { choice: 'Sale' },
          entryId: 'entry1',
          name: 'Selects Lease or Sale',
        },
      },
      {
        description: 'entry notes toggle (no)',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'fields',
            'notes',
            'notesToggle',
          ],
          value: false,
        },
        output: {
          additionalProperties: { choice: 'no' },
          entryId: 'entry1',
          name: 'Selects To Add Listing Notes',
        },
      },
      {
        description: 'entry notes toggle (yes)',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'fields',
            'notes',
            'notesToggle',
          ],
          value: true,
        },
        output: {
          additionalProperties: { choice: 'yes' },
          entryId: 'entry1',
          name: 'Selects To Add Listing Notes',
        },
      },
      {
        description: 'entry notes bullets (on)',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'fields',
            'notes',
            'notes',
            'bullets',
          ],
          value: true,
        },
        output: {
          additionalProperties: { new_position: 'on' },
          entryId: 'entry1',
          name: 'Toggles Bullet Points in Notes',
        },
      },
      {
        description: 'entry notes bullets (off)',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'fields',
            'notes',
            'notes',
            'bullets',
          ],
          value: false,
        },
        output: {
          additionalProperties: { new_position: 'off' },
          entryId: 'entry1',
          name: 'Toggles Bullet Points in Notes',
        },
      },
      {
        description: 'entry image add',
        input: {
          options: { trigger: 'add' },
          path: ['content', 'entries', 'entry1', 'images', 'image1'],
          value: { cloudinaryPublicId: 'image1' },
        },
        output: {
          additionalProperties: {
            cloudinary_url: 'some url',
            pdf_display: false,
          },
          entryId: 'entry1',
          name: 'Adds an Image',
        },
      },
      {
        description: 'entry main page image layout (1_1_1)',
        input: {
          path: ['content', 'entries', 'entry1', 'layout'],
          value: '1_1_1',
        },
        output: {
          additionalProperties: { choice: 'small-small-small' },
          entryId: 'entry1',
          name: 'Changes the Main Page Image Layout',
        },
      },
      {
        description: 'entry main page image layout (1_2)',
        input: {
          path: ['content', 'entries', 'entry1', 'layout'],
          value: '1_2',
        },
        output: {
          additionalProperties: { choice: 'small-large' },
          entryId: 'entry1',
          name: 'Changes the Main Page Image Layout',
        },
      },
      {
        description: 'entry main page image layout (2_1)',
        input: {
          path: ['content', 'entries', 'entry1', 'layout'],
          value: '2_1',
        },
        output: {
          additionalProperties: { choice: 'large-small' },
          entryId: 'entry1',
          name: 'Changes the Main Page Image Layout',
        },
      },
      {
        description: 'entry main page image layout (1)',
        input: {
          path: ['content', 'entries', 'entry1', 'layout'],
          value: '1',
        },
        output: {
          additionalProperties: { choice: 'extra-large' },
          entryId: 'entry1',
          name: 'Changes the Main Page Image Layout',
        },
      },
      {
        description: 'entry extra pdf page page',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'pages',
            'images',
            'page1',
            'type',
          ],
          value: 'floor_plan',
        },
        output: {
          additionalProperties: { choice: 'floor_plan' },
          entryId: 'entry1',
          name: 'Selects Extra Page Type',
        },
      },
      {
        description: 'entry extra pdf page layout (1_1_1_1)',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'pages',
            'images',
            'page1',
            'layout',
          ],
          value: '1_1_1_1',
        },
        output: {
          additionalProperties: { choice: 'small-small-small-small' },
          entryId: 'entry1',
          name: 'Changes the Extra Page Image Layout',
        },
      },
      {
        description: 'entry extra pdf page layout (2_1_1)',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'pages',
            'images',
            'page1',
            'layout',
          ],
          value: '2_1_1',
        },
        output: {
          additionalProperties: { choice: 'large-small-small' },
          entryId: 'entry1',
          name: 'Changes the Extra Page Image Layout',
        },
      },
      {
        description: 'entry extra pdf page layout (2_2)',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'pages',
            'images',
            'page1',
            'layout',
          ],
          value: '2_2',
        },
        output: {
          additionalProperties: { choice: 'large-large' },
          entryId: 'entry1',
          name: 'Changes the Extra Page Image Layout',
        },
      },
      {
        description: 'entry extra pdf page layout (4)',
        input: {
          path: [
            'content',
            'entries',
            'entry1',
            'pages',
            'images',
            'page1',
            'layout',
          ],
          value: '4',
        },
        output: {
          additionalProperties: { choice: 'extra-large' },
          entryId: 'entry1',
          name: 'Changes the Extra Page Image Layout',
        },
      },
      {
        description: 'map style type',
        input: {
          path: ['content', 'settings', 'mapOptions', 'style'],
          value: 'ngkf/ckglw6sj90ici1al9439saufx',
        },
        output: {
          additionalProperties: { choice: 'emerald' },
          name: 'Changes Map Style',
        },
      },
      {
        description: 'map style itinerary (on)',
        input: {
          path: ['content', 'settings', 'mapOptions', 'itinerary'],
          value: true,
        },
        output: {
          additionalProperties: { new_position: 'on' },
          name: 'Toggles Map Itineray',
        },
      },
      {
        description: 'map style itinerary (off)',
        input: {
          path: ['content', 'settings', 'mapOptions', 'itinerary'],
          value: false,
        },
        output: {
          additionalProperties: { new_position: 'off' },
          name: 'Toggles Map Itineray',
        },
      },
      {
        description: 'map display (on)',
        input: {
          path: ['content', 'settings', 'display', 'tourMap'],
          value: true,
        },
        output: {
          additionalProperties: { new_position: 'on' },
          name: 'Toggles Map Display',
        },
      },
      {
        description: 'map display (off)',
        input: {
          path: ['content', 'settings', 'display', 'tourMap'],
          value: false,
        },
        output: {
          additionalProperties: { new_position: 'off' },
          name: 'Toggles Map Display',
        },
      },
      {
        description: 'summary display (on)',
        input: {
          path: ['content', 'settings', 'display', 'summary'],
          value: true,
        },
        output: {
          additionalProperties: { new_position: 'on' },
          name: 'Toggles Summary Display',
        },
      },
      {
        description: 'summary display (off)',
        input: {
          path: ['content', 'settings', 'display', 'summary'],
          value: false,
        },
        output: {
          additionalProperties: { new_position: 'off' },
          name: 'Toggles Summary Display',
        },
      },
      {
        description: 'footer logo (on)',
        input: {
          path: ['content', 'settings', 'display', 'footerLogo'],
          value: true,
        },
        output: {
          additionalProperties: { new_position: 'on' },
          name: 'Toggles Logo in Footer',
        },
      },
      {
        description: 'footer logo (off)',
        input: {
          path: ['content', 'settings', 'display', 'footerLogo'],
          value: false,
        },
        output: {
          additionalProperties: { new_position: 'off' },
          name: 'Toggles Logo in Footer',
        },
      },
      {
        description: 'footer broker contact (on)',
        input: {
          path: ['content', 'settings', 'display', 'footerText'],
          value: true,
        },
        output: {
          additionalProperties: { new_position: 'on' },
          name: 'Toggles Contact Info in Footer',
        },
      },
      {
        description: 'footer broker contact (off)',
        input: {
          path: ['content', 'settings', 'display', 'footerText'],
          value: false,
        },
        output: {
          additionalProperties: { new_position: 'off' },
          name: 'Toggles Contact Info in Footer',
        },
      },
    ].forEach(({ description, input, output }) =>
      describe(description, () =>
        it(`returns ${output != null ? output.name : undefined}`, function () {
          return expect(this.service.getDataChangeEvent(input)).to.eql(output);
        })
      )
    ));

  describe('getViewEventName', () =>
    [
      {
        description: 'no view, tab',
        input: {
          currentTab: {},
          currentView: {},
        },
        output: undefined,
      },
      {
        description: 'on cover view, appearance tab',
        input: {
          currentTab: { key: 'appearance' },
          currentView: { key: 'cover' },
        },
        output: 'Views Cover:Appearance',
      },
      {
        description: 'on cover view, tour info tab',
        input: {
          currentTab: { key: 'tour_info' },
          currentView: { key: 'cover' },
        },
        output: 'Views Cover:Tour-Info',
      },
      {
        description:
          'on cover view, add contact with no existing contact infos',
        input: {
          currentTab: { key: 'contact_info_id1' },
          currentView: { key: 'cover' },
          tourbook: { content: { cover: {} } },
        },
        output: 'Views Cover:Contact1',
      },
      {
        description: 'on cover view, add contact with existing contact info',
        input: {
          currentTab: { key: 'contact_info_id2' },
          currentView: { key: 'cover' },
          tourbook: {
            content: {
              cover: {
                contactInfos: {
                  id1: { some: 'contact data1' },
                },
              },
            },
          },
        },
        output: 'Views Cover:Contact2',
      },
      {
        description: 'on cover view, first contact info tab',
        input: {
          currentTab: { key: 'contact_info_id1' },
          currentView: { key: 'cover' },
          tourbook: {
            content: {
              cover: {
                contactInfos: {
                  id1: { some: 'contact data1' },
                  id2: { some: 'contact data1' },
                },
              },
            },
          },
        },
        output: 'Views Cover:Contact1',
      },
      {
        description: 'on cover view, second contact info tab',
        input: {
          currentTab: { key: 'contact_info_id2' },
          currentView: { key: 'cover' },
          tourbook: {
            content: {
              cover: {
                contactInfos: {
                  id1: { some: 'contact data1' },
                  id2: { some: 'contact data2' },
                },
              },
            },
          },
        },
        output: 'Views Cover:Contact2',
      },
      {
        description: 'on entry view, address tab',
        input: {
          currentTab: { key: 'address' },
          currentView: { key: 'entry' },
        },
        output: 'Views Listing:Address',
      },
      {
        description: 'on entry view, space or terms tab',
        input: {
          currentTab: { key: 'space_or_terms' },
          currentView: { key: 'entry' },
        },
        output: 'Views Listing:Space/Terms',
      },
      {
        description: 'on entry view, property tab',
        input: {
          currentTab: { key: 'property' },
          currentView: { key: 'entry' },
        },
        output: 'Views Listing:Property',
      },
      {
        description: 'on entry view, notes tab',
        input: {
          currentTab: { key: 'notes' },
          currentView: { key: 'entry' },
        },
        output: 'Views Listing:Notes',
      },
      {
        description: 'on entry images view, main images tab',
        input: {
          currentTab: { key: 'main_images' },
          currentView: { key: 'entry-images' },
        },
        output: 'Views Images:Listing-Images',
      },
      {
        description: 'on entry images view, add image page tab',
        input: {
          currentTab: { key: 'add_pdf_page' },
          currentView: { key: 'entry-images' },
        },
        output: 'Views Images:Extra-PDF-Page',
      },
      {
        description: 'on entry images view, additional page tab',
        input: {
          currentTab: { key: 'pdf_page_page1' },
          currentView: { key: 'entry-images' },
        },
        output: 'Views Images:Extra-PDF-Page',
      },
    ].forEach(({ description, input, output }) =>
      describe(description, () =>
        it(`returns ${output}`, function () {
          return expect(this.service.getViewEventName(input)).to.eql(output);
        })
      )
    ));

  return describe('getFieldAdditionalProperties', function () {
    beforeEach(function () {
      return this.tourbookFieldsService.getFieldLabel.returns('field label');
    });

    describe('xceligent field', function () {
      describe('with data', () =>
        it('returns the field name and field type', function () {
          const result = this.service.getFieldAdditionalProperties({
            hasValue: true,
            isAtlas: true,
          });
          return expect(result).to.eql({
            field_name: 'field label',
            field_type: 'xceligent data',
          });
        }));

      return describe('without data', () =>
        it('returns the field name and field type', function () {
          const result = this.service.getFieldAdditionalProperties({
            hasValue: false,
            isAtlas: true,
          });
          return expect(result).to.eql({
            field_name: 'field label',
            field_type: 'suggested',
          });
        }));
    });

    return describe('custom field', () =>
      it('returns the field name', function () {
        const result = this.service.getFieldAdditionalProperties({
          isAtlas: false,
        });
        return expect(result).to.eql({ field_name: 'field label' });
      }));
  });
});
