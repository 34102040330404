const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const SummaryView = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentField',
  'track',
  'update',
]);

const mapStateToProps = function (state) {
  const {
    content: { entries, settings },
  } = state.tourbookEditorPage.data;
  const doNotIncludeInSummaryKeys = [
    'tourbook-notes',
    'tourbook-floorplan',
    'tourbook-images',
  ];
  const filteredEntries = _.keyBy(
    _.chain(entries || [])
      .map((entry, entryId) => {
        return { ...entry, entryId };
      })
      .filter((x) => !doNotIncludeInSummaryKeys.includes(x.key) && !x.hidden)
      .value(),
    'entryId'
  );

  return {
    entries: filteredEntries,
    settings,
    currentField: state.tourbookEditorPage.currentField,
  };
};
module.exports = build({
  component: SummaryView,
  mapDispatchToProps,
  mapStateToProps,
});
