const _ = require('lodash');

const initialState = {
  userProfile: {}
};

const mapping = {
  setDataValue(state, { payload: { path, value } }) {
    let index = path.length - 1;
    let newObject = value;
    while (index >= 0) {
      const updatedPath = path.slice(0, index);
      const oldObject = _.get(state, updatedPath);
      if (newObject === null) {
        newObject = _.assign({}, oldObject);
        delete newObject[path[index]];
      } else {
        newObject = _.assign({}, oldObject, { [path[index]]: newObject });
      }
      index -= 1;
    }
    return _.assign({}, state, newObject);
  },

};

module.exports = { initialState, mapping, namespace: 'profilePage' };
