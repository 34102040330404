const { createAction } = require('redux-actions');
const { locateService } = require('@services/service_locator');
const promiseInspection = require('./promise_inspection_builder')(
  'collaborateModal'
);

const setAction = createAction('collaborateModal/set');
const setSharedUsersAction = createAction('collaborateModal/setSharedUsers');

module.exports = function () {
  let collaborateModalActions;
  const sharingService = locateService('sharingService');
  const tourbooksService = locateService('tourbooksService');
  const tourbookAnalyticsService = locateService('tourbookAnalyticsService');

  return collaborateModalActions = {
    set: setAction,

    initializeCollaborateModal(tourbookId) {
      return promiseInspection(
        'initializeCollaborateModal',
        async function(dispatch, getState) {
          tourbookAnalyticsService.trackTourbookEvent(
            'Opens "Share Tour Book" modal',
            {
              category: getState().collaborateModal.trackingCategory,
              targetUser: getState().userSession.id,
              tourbookId,
            }
          );
          const teardown = await sharingService.subscribeToTourbookSharedUsers(
            tourbookId,
            (sharedUsers) => dispatch(setSharedUsersAction(sharedUsers))
          );
          return teardown;
        }
      );
    },

    removeCollaborator(tourbookId, { userId, email }) {
      return promiseInspection(
        'removeCollaborator',
        async function(dispatch, getState) {
          await tourbooksService.leaveSharedTourbook({ tourbookId, userId });
          return await dispatch(
            collaborateModalActions._track('Removes User From Tour Book', {
              tourbookId,
              additionalProperties: {
                collab_email: email,
              },
            })
          );
        }
      );
    },

    _track(event, options) {
      return async function(dispatch, getState) {
        const newOptions = {
          tourbookId: options.tourbookId,
          category: getState().collaborateModal.trackingCategory,
        };
        if (options.targetUser != null) {
          newOptions.targetUser = options.targetUser;
        } else {
          newOptions.targetUser = getState().userSession.id;
        }
        if (options.tourbook != null) {
          newOptions.tourbook = options.tourbook;
        }
        if (options.additionalProperties != null) {
          newOptions.additionalProperties = options.additionalProperties;
        }
        return await tourbookAnalyticsService.trackTourbookEvent(
          event,
          newOptions
        );
      };
    },
  };
};
