const _ = require('lodash');
const ButtonWithShortcutContainer = require('../button_with_shortcut/container');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class CollapsibleQuestion extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
        })
      ).isRequired,
      onChange: PropTypes.func.isRequired,
      question: PropTypes.string.isRequired,
      value: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);
    this.state = { isEditing: !this._hasAnswer(props) };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isEditing && !nextProps.active) {
      return this.setState({ isEditing: !this._hasAnswer(nextProps) });
    }
  }

  render() {
    if (this.state.isEditing) {
      return (
        <div className={cx(['root', 'open'])}>
          <div className={cx(['question'])}>
            {this.props.question}
          </div>
          <div className={cx(['options'])}>
            {this.props.answers.map(({ label, value }, index) => {
              return (
                <ButtonWithShortcutContainer
                  className={cx(['button'])}
                  displayShortcut={this.props.active}
                  key={index}
                  label={label}
                  onSelect={() => {
                    this.props.onChange(value);
                    return this.setState({ isEditing: false });
                  }}
                  shortcut={index + 1} />
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={cx(['root', 'closed', `${this.props.active ? 'active' : ''}`])}>
          <div className={cx(['content'])}>
            <div className={cx(['question'])}>
              {this.props.question}
            </div>
            <div className={cx(['answer'])}>
              {this._getSelectedAnswer(this.props)?.label}
            </div>
          </div>
          <div className={cx(['links'])}>
            <a
              className={cx(['edit'])}
              onClick={() => this.setState({ isEditing: true })}>
              Edit
            </a>
          </div>
        </div>
      );
    }
  }

  _getSelectedAnswer(props) {
    return _.find(props.answers, ['value', props.value]);
  }

  _hasAnswer(props) {
    return this._getSelectedAnswer(props) != null;
  }
}
CollapsibleQuestion.initClass();

module.exports = CollapsibleQuestion;
