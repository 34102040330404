const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const PdfPageWithFooterContainer = require('../pdf_page_with_footer/container');
const tinycolor = require('tinycolor2');
const ImageContainer = require('@components/shared/image/container');
const LikesCountContainer = require('@components/shared/likes_count/container');
const { useState, useEffect } = require('react');
const {
  fieldKeyToFieldDefinitionKey,
} = require('@components/pages/tourbook_editor_page/multi_space_util');

const ClampContent = require('./ClampContent').default;

const SummaryImageContainer = function (props) {
  const setComponentReady = props.readyRegistry.register(`SummaryImageContainer${props.entryId}`);

  const getSummaryImagePersistPath = async function ({ entryId, tourbookId, firebaseService }) {
    const persistPath = ['content', 'entries', entryId, 'imagesShown', 'summary'];
    const persistValue = await firebaseService.getValue(
      `tourbooks/${tourbookId}/${persistPath.join('/')}`
    );
    const sourcePath = ['content', 'entries', entryId, 'imagesShown', 0];
    const sourceValue = await firebaseService.getValue(
      `tourbooks/${tourbookId}/${sourcePath.join('/')}`
    );
    const mustUpdateImage =
      persistValue && sourceValue.cloudinaryPublicId !== persistValue.cloudinaryPublicId;
    if (!persistValue || mustUpdateImage) {
      await firebaseService.setValue(
        `tourbooks/${tourbookId}/${persistPath.join('/')}`,
        sourceValue
      );
    }

    return persistPath;
  };

  const [summaryImagePersistPath, setSummaryImagePersistPath] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(async () => {
    setSummaryImagePersistPath(await getSummaryImagePersistPath(props));
    setImage(props.tourbookEntryImagesService.getSummaryImage(props.entry));
    setComponentReady(true);
    return () => {
      return setComponentReady(false);
    };
  }, [props]);

  return image && summaryImagePersistPath ? ( // don't render the ImageContainer until the summary image's path has been set in firebase
    <ImageContainer
      croppable={props.isEditing}
      image={image}
      imagePath={summaryImagePersistPath}
      backgroundSize="cover"
      height="100%"
      width="100%"
      readyRegistry={props.readyRegistry}
    />
  ) : (
    <></>
  );
};

function PdfSummaryTemplateVertical(props) {
  const entries = props.tourbook.content.entries;
  const availableFieldDefinitions = props.availableFieldDefinitions;

  const isTooLight = (color) => tinycolor(color).getBrightness() > 155; // 155 is an arbitrary decision; we could use 128, 100 etc...

  let foregroundColor = '#FFFFFF';
  if (isTooLight(props.accentColor)) {
    foregroundColor = '#000000';
  }
  const accentedStyle = {
    backgroundColor: props.accentColor,
    color: foregroundColor,
    fontWeight: 600,
  };
  const formatFloorAndSuite = function (entry) {
    let floorAndSuite = '';
    if (entry.fields.header.floor) {
      floorAndSuite += `, Floor ${entry.fields.header.floor}`;
    }
    if (entry.fields.header.suite) {
      floorAndSuite += `, Suite ${entry.fields.header.suite}`;
    }
    return floorAndSuite;
  };
  const _renderUnit = function (fieldDefinition, unit) {
    return props.tourbookFieldsService.getFieldUnit(
      {
        fieldDefinition,
        unit,
      },
      { asHtml: true }
    );
  };

  const _getAddressProps = function (isEditing, entryId) {
    const addressProps = {};
    if (isEditing) {
      addressProps.onClick = () => {
        return props.navigateToEntryField({
          entryId,
          fieldKey: _getFieldId({ key: 'address' }, entryId, 'address'),
        });
      };
    }
    return addressProps;
  };

  const _getFieldId = function (field, entryId, section) {
    const prefix = `content-entries-${entryId}-fields`;
    if (field.key === `${section}Header`) {
      return `${prefix}-header-${field.key}`;
    } else {
      return `${prefix}-${section}-${field.key}-value`;
    }
  };
  const _getFieldProps = function (isEditing, entryId, field, section, classNames) {
    const fieldProps = { className: cx(classNames) };
    if (isEditing) {
      const tabKey = ['space', 'terms', 'subLease'].includes(section) ? 'space_or_terms' : section;
      fieldProps.onClick = () => {
        return props.navigateToEntryField({
          fieldKey: _getFieldId(field, entryId, section),
          tabKey,
          entryId,
        });
      };
    }
    return fieldProps;
  };

  const _getRootProps = function () {
    return {
      className: cx(['root', 'pdf-template', `${props.isEditing ? 'is-editing' : ''}`]),
    };
  };

  const _getSummaryPageNumber = () => {
    return props.pageNumberService.getSummaryPageNumber(props.offset, props.tourbook);
  };

  const setComponentReady = props.readyRegistry.register(
    `pdf-summary-template-vertical-${props.tourbookId}-${_getSummaryPageNumber()}`
  );
  useEffect(() => {
    setComponentReady(true);
    return () => {
      return setComponentReady(false);
    };
  }, [props]);
  const fontSizeStyle = {
    'font-size': props.fontSize ? `${props.fontSize}px` : undefined,
  };
  return (
    <div key={`pdf-summary-template-vertical-${props.tourbookId}`} {..._getRootProps()}>
      <PdfPageWithFooterContainer
        hidePageNumber={false}
        pageNumber={_getSummaryPageNumber()}
        readyRegistry={props.readyRegistry}
      >
        <table>
          <tbody>
            {/* image and likes/dislikes */}
            <tr>
              <td></td>
              {/*intentionally empty column */}
              {props.entryIds.map((entryId) => {
                {
                  return (
                    <td key={'image' + entryId}>
                      <div className={cx(['summary-image-container'])}>
                        <div className={cx(['summary-image'])}>
                          <SummaryImageContainer
                            entryId={entryId}
                            entry={props.tourbook.content.entries[entryId]}
                            tourbookId={props.tourbookId}
                            firebaseService={props.firebaseService}
                            tourbookEntryImagesService={props.tourbookEntryImagesService}
                            isEditing={props.isEditing}
                            readyRegistry={props.readyRegistry}
                          />
                          {props.isEditing && (
                            <LikesCountContainer
                              entryId={entryId}
                              readyRegistry={props.readyRegistry}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  );
                }
              })}
            </tr>
            {/* property name */}
            <tr>
              <td className={cx(['label'])} style={accentedStyle}>
                Name
              </td>
              {props.entryIds.map((entryId) => {
                return (
                  <td
                    key={'propertyName' + entryId}
                    style={accentedStyle}
                    {..._getAddressProps(props.isEditing, entryId)}
                  >
                    <ClampContent lineCount={3} style={fontSizeStyle}>
                      {entries[entryId].fields.header.propertyName
                        ? entries[entryId].fields.header.propertyName
                        : '-'}
                    </ClampContent>
                  </td>
                );
              })}
            </tr>
            {/* property location */}
            <tr>
              <td className={cx(['label'])} style={accentedStyle}>
                Location
              </td>
              {props.entryIds.map((entryId) => {
                const { CorrectedAddress1, city } = entries[entryId].fields.header;
                let location = '';
                if (CorrectedAddress1) {
                  location += `${CorrectedAddress1}, `;
                }
                location += city + ' ' + formatFloorAndSuite(entries[entryId]);
                return (
                  <td
                    key={'propertyLocation' + entryId}
                    className={cx('field-cell')}
                    style={accentedStyle}
                    {..._getAddressProps(props.isEditing, entryId)}
                  >
                    <ClampContent lineCount={3} style={fontSizeStyle}>
                      {location || '-'}
                    </ClampContent>
                  </td>
                );
              })}
            </tr>
            {/* summary fields */}
            {props.summaryFields.map((field, fieldIndex) => {
              const label = props.tourbookFieldsService.getFieldLabel(
                { key: field.key },
                props.availableFieldDefinitions
              );
              return (
                <tr>
                  <td className={cx(['label'])} style={accentedStyle}>
                    {label}
                  </td>
                  {props.entryIds.map((entryId) => {
                    const isPropertyField = props.summaryService.isSectionField(
                      'property',
                      field.key,
                      entries[entryId]
                    );
                    const isAddressField = props.summaryService.isSectionField(
                      'address',
                      field.key,
                      entries[entryId]
                    );
                    let sectionType = props.summaryService.typeToSectionKey(
                      entries[entryId]['type']
                    );

                    if (isPropertyField) {
                      sectionType = 'property';
                    }
                    if (isAddressField) {
                      sectionType = 'address';
                    }
                    const value = props.summaryService.fieldToValue(
                      field,
                      sectionType,
                      entries[entryId]
                    );
                    const fieldDefinitionKey = fieldKeyToFieldDefinitionKey(field.key);
                    const fieldDefinition = availableFieldDefinitions[fieldDefinitionKey];
                    const fieldValue = props.tourbookFieldsService.getFieldValueAsString({
                      fieldDefinition,
                      value,
                    });

                    const unit = fieldValue
                      ? entries[entryId].fields[sectionType][field.key]?.unit ||
                        fieldDefinition?.units?.[0] ||
                        ''
                      : null;
                    const isFieldValueGoodToDisplay = fieldValue && fieldValue !== '0';

                    return (
                      <td
                        key={'field' + entryId + fieldDefinitionKey}
                        {..._getFieldProps(props.isEditing, entryId, field, sectionType, [
                          'inner-table-border-bottom',
                        ])}
                      >
                        {unit ? (
                          <div style={fontSizeStyle}>
                            {/* units dont need to be elided */}
                            {isFieldValueGoodToDisplay
                              ? fieldValue
                              : props.emptyFieldReplacements?.[field.key] ?? '-'}
                            {unit && isFieldValueGoodToDisplay && (
                              <span className={cx(['unit'])} style={fontSizeStyle}>
                                {_renderUnit(fieldDefinition, unit)}
                              </span>
                            )}
                          </div>
                        ) : (
                          <ClampContent
                            style={fontSizeStyle}
                            lineCount={
                              field.key.startsWith('description') ||
                              field.key.startsWith('condition')
                                ? 3
                                : 1
                            }
                          >
                            {fieldValue || '-'}
                          </ClampContent>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              <td className={cx(['notes', 'label'])} style={accentedStyle}>
                Notes
              </td>
              {props.entryIds.map((entryId) => {
                return (
                  <td
                    key={'notes' + entryId}
                    {..._getFieldProps(props.isEditing, entryId, 'notes', 'notes', ['inner-table'])}
                    style={{
                      fontSize:
                        props.noteFontSize || props.fontSize
                          ? `${props.noteFontSize || props.fontSize}px`
                          : undefined,
                    }}
                  >
                    {entries[entryId].fields?.notes?.notes?.content &&
                    !entries[entryId]?.fields?.notes?.notes?.secret
                      ? entries[entryId].fields?.notes?.notes?.content
                      : '-'}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </PdfPageWithFooterContainer>
    </div>
  );
}
PdfSummaryTemplateVertical.propTypes = {
  coverFields: PropTypes.object.isRequired,
  entryIds(props) {
    if (!props.hasOwnProperty('entryIds')) {
      return new Error('entryIds is required');
    }
    const { entryIds } = props;
    if (!Array.isArray(entryIds)) {
      return new Error('entryIds: not an array');
    }
    if (entryIds.length > 4) {
      return new Error('entryIds: maximum 4 elements allowed');
    }
  },
  fontSize: PropTypes.number,
  noteFontSize: PropTypes.number,
  emptyFieldReplacement: PropTypes.object,
  isEditing: PropTypes.bool,
  offset: PropTypes.number.isRequired,
  tourbook: PropTypes.object.isRequired,
  availableFieldDefinitions: PropTypes.object.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  entryNumberService: PropTypes.object.isRequired,
  firebaseService: PropTypes.object.isRequired,
  tourbookEntryFieldsService: PropTypes.object.isRequired,
  pageNumberService: PropTypes.object.isRequired,
  readyRegistry: PropTypes.object.isRequired,
  tourbookId: PropTypes.string.isRequired,
  tourbookFieldsService: PropTypes.object.isRequired,
  summaryFields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    })
  ).isRequired,
  summaryService: PropTypes.object.isRequired,
};
export default PdfSummaryTemplateVertical;
