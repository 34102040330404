const React = require('react');
const importedStyles = require('./new_tourbook_card.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const { locateServices } = require('@services/service_locator');
const { build } = require('@components/container_helpers');
const { useNavigate } = require('react-router-dom');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

const mapStateToProps = (state) => ({
  ownedTourbooks: state.libraryPage.ownedTourbooks,
});

class NewTourbookCardInner extends React.Component {
  static initClass() {
    this.prototype._createNewTourbook = async function() {
      const tourbook = await this.props.dispatch(
        this.libraryPageActions.createNewTourbook()
      );
      this.props.addFauxTourbook(tourbook);
      return setTimeout(() => {
        return this.props.navigate(`/tourbook/${tourbook.id}`);
      }, ENV.delays.redirectToTourbookEditor);
    };
  }

  constructor(props) {
    super(props);
    ({ libraryPageActions: this.libraryPageActions } = locateServices(['libraryPageActions']));
  }

  render() {
    return (
      <div onClick={() => this._createNewTourbook()} className={cx(['root'])}>
        <div className={cx(['plus-wrapper'])}>
          <FontAwesomeIcon name="plus" />
        </div>
        <div className={cx(['text'])}>New Tour Book</div>
      </div>
    );
  }
}
NewTourbookCardInner.initClass();

const NewTourbookCard = build({
  component: (props) => {
    const navigate = useNavigate();
    return <NewTourbookCardInner {...props} navigate={navigate} />;
  },
  displayName: 'NewTourbookCard',
  mapStateToProps,
});

module.exports = NewTourbookCard;
