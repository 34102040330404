/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const TourbookBuilderService = require('./tourbook_builder_service');
const { stubServices } = require('./service_locator');

describe('TourbookBuilderService', function () {
  beforeEach(function () {
    ({
      firebaseService: this.firebaseService,
      tourbookFieldsService: this.tourbookFieldsService,
    } = stubServices(['firebaseService', 'tourbookFieldsService']));
    this.firebaseService.getServerTimestamp.returns('now');
    this.user = {
      auth0Profile: { email: 'user3Email' },
      firebaseProfile: { picture: 'user3Picture' },
      id: 'user3Id',
    };
    return (this.tourbookBuilderService = new TourbookBuilderService());
  });

  describe('buildCopy', function () {
    beforeEach(
      async function() {
        const existingTourbook = {
          content: {
            cover: { fields: { title: 'tourbook-name1' } },
            entries: 'entries data',
            settings: {
              other: 'settings data',
            },
          },
          owner_email: 'user1Email',
          owner_id: 'user1Id',
          owner_picture: 'user1Picture',
          shared: {
            user2Id: {
              email: 'user2Email',
              picture: 'user2Picture',
            },
          },
          timestamps: {
            created_at: 1,
            updated_at: 2,
          },
        };
        this.firebaseService.getValue
          .withArgs('tourbooks/1')
          .resolves(existingTourbook);
        return this.result = await this.tourbookBuilderService.buildCopy(
          '1',
          this.user
        );
      }
    );

    it('has the same content as the existing tourbook with (Copy) appended to the name', function () {
      return expect(this.result.content).to.eql({
        cover: { fields: { title: 'tourbook-name1 (Copy)' } },
        entries: 'entries data',
        settings: {
          other: 'settings data',
        },
      });
    });

    it('has owner information update the user', function () {
      expect(this.result.owner_email).to.eql('user3Email');
      expect(this.result.owner_id).to.eql('user3Id');
      return expect(this.result.owner_picture).to.eql('user3Picture');
    });

    it('has shared removed', function () {
      return expect(this.result.shared).to.be.undefined;
    });

    return it('has the timestamps reset', function () {
      expect(this.result.timestamps.created_at).to.eql('now');
      return expect(this.result.timestamps.updated_at).to.eql('now');
    });
  });

  return describe('buildDefault', function () {
    beforeEach(
      async function() {
        this.tourbookFieldsService.getDefaultCover.returns(
          'default cover data'
        );
        this.tourbookFieldsService.getDefaultSettings.resolves(
          'default settings data'
        );
        return this.result = await this.tourbookBuilderService.buildDefault(
          this.user
        );
      }
    );

    it('has the default cover, no entries, and the default settings', function () {
      return expect(this.result.content).to.eql({
        cover: 'default cover data',
        settings: 'default settings data',
      });
    });

    it('has owner information', function () {
      expect(this.result.owner_email).to.eql('user3Email');
      expect(this.result.owner_id).to.eql('user3Id');
      return expect(this.result.owner_picture).to.eql('user3Picture');
    });

    return it('has timestamps', function () {
      expect(this.result.timestamps.created_at).to.eql('now');
      return expect(this.result.timestamps.updated_at).to.eql('now');
    });
  });
});
