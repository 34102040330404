exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 8 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/radio_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/radio_input/index.styl */\n.radio_input---root---1gY93ClpI0bekPI3FDghEv {\n  display: -ms-flexbox;\n  display: flex;\n}\n\n/* line 4 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/radio_input/index.styl */\n.radio_input---root---1gY93ClpI0bekPI3FDghEv input {\n  opacity: 0;\n  position: absolute;\n  width: auto;\n  z-index: -1;\n}\n", ""]);

// exports
exports.locals = {
	"root": "radio_input---root---1gY93ClpI0bekPI3FDghEv"
};