exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 19 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_image_drop_target/components/image/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_image_drop_target/components/image/index.styl */\n.image---root---3lYP6Nr9GIIlfvhcAmF_tp {\n  height: 100%;\n  position: relative;\n  width: 100%;\n}\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_image_drop_target/components/image/index.styl */\n.image---is-editing---1OMoKQx_RS3eDHv8qGqu3J {\n  cursor: pointer;\n}\n\n/* line 9 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_image_drop_target/components/image/index.styl */\n.image---draggable---1d14Y-BPCh8o1_fh59wI43 {\n  cursor: move;\n}\n\n/* line 12 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_image_drop_target/components/image/index.styl */\n.image---crop---jwckvzLfYKh5EBtsB7Exo {\n  cursor: pointer;\n  position: absolute;\n  right: 5px;\n  top: 5px;\n}\n\n/* line 18 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_image_drop_target/components/image/index.styl */\n.image---crop---jwckvzLfYKh5EBtsB7Exo i {\n  color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"root": "image---root---3lYP6Nr9GIIlfvhcAmF_tp",
	"is-editing": "image---is-editing---1OMoKQx_RS3eDHv8qGqu3J",
	"draggable": "image---draggable---1d14Y-BPCh8o1_fh59wI43",
	"crop": "image---crop---jwckvzLfYKh5EBtsB7Exo"
};