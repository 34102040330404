const _ = require('lodash');
const querystring = require('querystring');
const titleCase = require('title-case');

class GooglePlacesService {
  static initClass() {
    this.prototype.getNearbyVenues = async function (data) {
      let venues;
      if (!data) {
        return;
      }
      if (data.bundle === 'places') {
        data.type = 'tourist_attraction,park,university';
      } else {
        data.type = 'store,bank,bakery,restaurants,health_club,convenience';
        data.avoid = 'night_club';
      }
      data.radius = data.radius != null ? data.radius : 400;
      let qualifiedVenues = [];

      // until we get 6, or we exceed 25.5km, keep requerying
      while (qualifiedVenues.length < 6 && data.radius < 25500) {
        venues = await this._getNearbyVenues(data);
        qualifiedVenues = this._filterVenuesToQualified(venues, data);
        data.radius *= 4;
      }
      const extractedVenues = this._formatExtractedVenues(
        qualifiedVenues,
        data.latitude,
        data.longitude
      );
      const allVenuesWithPhotos = await this._getAllVenuesWithPhotos(
        extractedVenues
      );

      return { venues: allVenuesWithPhotos };
    };

    this.prototype._getNearbyVenues = async function ({
      latitude,
      longitude,
      radius,
      type,
    }) {
      const queryObj = { args: {} };
      queryObj.Base = this.queryBase();
      queryObj.args.action = 'location';
      queryObj.args.location = latitude + ',' + longitude;
      queryObj.args.radius = radius != null ? radius : 5000;
      queryObj.args.type = type;
      const vals = await this._makeRequest(queryObj);
      return ({ data, status, statustext, headers, config, request } = vals);
    };

    this.prototype._getOneNearbyPhotoUrl = async function (photoRef) {
      const queryObj = {
        Base: this.queryBase(),
        args: {
          action: 'photo',
          maxwidth: 300,
          maxheight: 300,
          photoreference: photoRef.photoUrl,
        },
      };
      const response = await this._makeRequest(queryObj);
      const { photoUrl } = response.data.data;
      return photoUrl;
    };

    this.prototype._getAllVenuesWithPhotos = async function (venues) {
      let index = 0;
      const venueCount = venues.length - 1;
      while (true) {
        const photoUrl = await this._getOneNearbyPhotoUrl(venues[index]);
        venues[index].photoUrl = photoUrl;
        index = index + 1;
        if (index > venueCount) {
          break;
        }
      }
      return venues;
    };

    this.prototype._makeRequest = async function (queryObj) {
      queryObj.timeout = 18000; // 18_000 ms -- 18seconds
      const requestBase = this.queryBase();
      const url = `${requestBase}?${querystring.stringify(queryObj.args)}`;
      const response = await this.axios(url);
      return response;
    };
  }

  constructor({ ENV: { firebaseFunctionsConfig }, axios }) {
    this.firebaseFunctionsConfig = firebaseFunctionsConfig;
    this.axios = axios;
  }

  queryBase() {
    return `${this.firebaseFunctionsConfig.url}/apiGoogleplaces`;
  }

  _filterVenuesToQualified(response, requestdata) {
    let topSixVenues;
    const { data, status, statustext, headers, config, request } = response;
    let { avoid } = requestdata;
    // if there are topics to avoid, then remove them here
    if (avoid) {
      avoid = avoid.split(',');
      if (avoid.length > 0) {
        _.remove(data.data, (o) => _.intersection(o.types, avoid).length > 0);
      }
    }

    // remove items with no photo_references
    _.remove(data.data, (o) => !o.photo_reference);

    const distfunc = this._calcDist;
    _.forEach(
      data.data,
      (o) =>
        (o.dist = distfunc(
          o.location.lat,
          o.location.lng,
          requestdata.latitude,
          requestdata.longitude
        ))
    );
    _.forEach(data.data, function (o) {
      const q = o.rating != null ? o.rating : 10;
      const n = o.user_ratings_total != null ? o.user_ratings_total : 3;
      const d = o.dist;
      return (o.quality = Math.log(q * n) / d);
    });

    // map the duplicated data out of the returned objects
    data.data = _.sortedUniqBy(data.data, (o) => o.name);
    data.data = _.sortBy(data.data, (o) => o.dist);
    data.data = _.filter(data.data, (o) => {
      if (o.name.match(/[\u3400-\u9FBF]/)) {
        // non-latin characters break docraptor // TODO: remove once we remove reliance on docraptor
        return false;
      }
      return true;
    });
    return (topSixVenues = _.take(data.data, Math.min(6, data.data.length)));
  }

  _formatExtractedVenues(data, latitude, longitude) {
    const extractedVenues = data.map(function (venue) {
      if (venue.hasOwnProperty('price_level') !== true) {
        venue.price_level = -1;
      }
      if (venue.hasOwnProperty('rating') !== true) {
        venue.rating = -1;
      }
      if (venue.hasOwnProperty('price_level') !== true) {
        venue.price_level = -1;
      }
      const extractedVenue = {
        id: venue.photo_reference,
        name: venue.name,
        price_level: venue.price_level,
        rating: venue.rating,
        vicinity: venue.vicinity,
        status: 'OK',
        category: venue.category,
        photoUrl: venue.photo_reference,
        location: venue.location,
      };
      extractedVenue.category = titleCase(extractedVenue.category);
      extractedVenue.category = extractedVenue.category.replace('_', ' ');
      return extractedVenue;
    });
    const distfunc = this._calcDist;
    _.forEach(
      extractedVenues,
      (venue) =>
        (venue.dist = distfunc(
          venue.location.lat,
          venue.location.lng,
          latitude,
          longitude
        ))
    );
    return extractedVenues;
  }

  _calcDist(lat1, lon1, lat2, lon2) {
    const deg2rad = (degrees) => degrees * (Math.PI / 180); // convert degrees to radians
    const R = 6371000; //  Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); //  deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; //
    return Math.round(d);
  }
}
GooglePlacesService.initClass();

module.exports = GooglePlacesService;
