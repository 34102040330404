const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const { PropTypes } = require('prop-types');
const React = require('react');

class PdfZeroStateTemplate extends React.Component {
  static initClass() {
    this.propTypes = {
      messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
  }

  render() {
    return (
      <div className={cx(['root', 'pdf-template'])}>
        <div className={cx(['line'])} />
        <div>
          {this.props.messages.map((message, index) =>
            <div key={index} className={cx(['message'])}>
              {message}
            </div>
          )}
        </div>
      </div>
    );
  }
}
PdfZeroStateTemplate.initClass();

module.exports = PdfZeroStateTemplate;
