const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const ViewNavigation = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'copyEntry',
  'reorderEntries',
  'setCurrentModal',
  'setCurrentView',
  'track',
  'setCollaborateModalState',
  'createNotesEntry',
  'createAdditionalMap',
  'deleteAdditionalMap',
  'toggleMapVisibility',
  'toggleEntryVisibility',
]);

const mapStateToProps = function (state) {
  const props = _.pick(state.tourbookEditorPage, [
    'currentField',
    'currentTab',
    'currentView',
  ]);
  const { content } = state.tourbookEditorPage.data;
  props.contactInfoIds = _.keys(content.cover.contactInfos);
  props.brokerInfoIds = _.keys(content?.broker?.brokerInfos);
  props.entries = _.chain(content.entries)
    .map((entry, entryId) => ({
      address: entry.fields?.header?.CorrectedAddress1,
      entryId,
      order: entry.order,
      key: entry.key,
      notesHeader: entry.fields?.header?.notesHeader,
      hidden: entry?.hidden || false,
    }))
    .sortBy('order')
    .value();
  props.settings = content.settings;
  return props;
};

module.exports = build({
  component: ViewNavigation,
  mapDispatchToProps,
  mapStateToProps,
});
