const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const EntryImagesZeroStateInput = require('./');

const mapDispatchToProps = function (dispatch, ownProps) {
  const { setCurrentModal, track, update } = actions.tourbookEditorPage;
  return {
    hideZeroState(value) {
      return dispatch(
        update({ path: ownProps.hideZeroStatePath, value: true })
      );
    },
    setCurrentModal(value) {
      return dispatch(setCurrentModal(value));
    },
    track(...args) {
      return dispatch(track(...Array.from(args || [])));
    },
  };
};

const mapStateToProps = function (state, ownProps) {
  const entryPath = _.initial(ownProps.path);
  const entryId = _.last(entryPath);
  const entry = _.get(state.tourbookEditorPage.data, entryPath);
  return {
    entryId,
    numberOfN360Images: _.size(entry.n360Images),
  };
};

module.exports = build({
  component: EntryImagesZeroStateInput,
  mapDispatchToProps,
  mapStateToProps,
});
