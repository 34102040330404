const { buildFieldPath } = require('../../shared/input_builders/helpers');

module.exports = function ({ entryId }) {
  return [
    {
      label: 'Header',
      path: buildFieldPath({
        entryId,
        fieldKey: 'notesHeader',
        section: 'header',
      }),
    },
    {
      active: true,
      path: buildFieldPath({ entryId, fieldKey: 'notes', section: 'notes' }),
      type: 'notes',
      useShiftEnterNavigation: true,
    },
  ];
};
