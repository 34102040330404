const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const HeaderSection = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'navigateToCurrentEntryField',
]);

const mapStateToProps = function (state, ownProps) {
  const { currentField, data } = state.tourbookEditorPage;
  const headerFields = data.content.entries[ownProps.entryId]?.fields?.header;

  const entryAccentColor = data.content.settings.accentColor;
  return {
    currentField,
    entryAccentColor,
    headerFields,
  };
};

const propTypes = {
  entryId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

module.exports = build({
  component: HeaderSection,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
});
