const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const SelectedFields = require('.');
const { locateServices } = require('@services/service_locator');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, ['update']);

const mapStateToProps = function (state) {
  const {
    content: { entries, settings },
  } = state.tourbookEditorPage.data;
  const fieldDefinitions = state.tourbookEditorPage.fieldDefinitions;
  const { summaryService } = locateServices([
    'summaryService',
    'tourbookEntryFieldsService',
    'tourbookFieldsService',
  ]);

  const filteredEntries = summaryService.filterToSummaryEntries(entries);

  const generateSelectedAndUnselectedLists = function (
    summaryFields,
    filteredEntries
  ) {
    const initialFilterConfig = {
      selected: {
        fields: [],
        customClass: '',
      },
      unSelected: {
        fields: [],
        customClass: 'unselected-heading',
      },
    };
    const filteredData = _.cloneDeep(initialFilterConfig);
    let fieldsPopulatedByBroker = summaryService.getFieldsPopulatedByBroker(
      filteredEntries,
      fieldDefinitions
    );
    // generate the selected/unselected lists
    fieldsPopulatedByBroker.forEach((field) => {
      const fieldKey = field.key;
      const matchingSummaryField = summaryFields.find(
        (sf) => fieldKey === sf.key
      );

      matchingSummaryField
        ? filteredData.selected.fields.push({
            ...field,
            order: matchingSummaryField.order,
          })
        : filteredData.unSelected.fields.push(field);
    });

    filteredData.unSelected.fields = filteredData.unSelected.fields.sort(
      (a, b) => {
        if (a.label > b.label) return 1;
        else if (a.label < b.label) return -1;
        return 0;
      }
    );

    filteredData.selected.fields = filteredData.selected.fields.sort(
      (a, b) => a.order - b.order
    );
    return filteredData;
  };
  const summaryFields = summaryService.getOrderedSummaryFields(
    settings.summaryFields || []
  );
  const filteredData = generateSelectedAndUnselectedLists(
    summaryFields,
    filteredEntries
  );

  return {
    summaryFields,
    fieldDefinitions,
    filteredData,
    rawSummaryFields: settings.summaryFields || [],
  };
};
module.exports = build({
  component: SelectedFields,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: [
    'tourbookEntryFieldsService',
    'tourbookFieldsService',
    'summaryService',
  ],
});
