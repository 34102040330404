const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const UserSessionInitializer = require('./');

const mapDispatchToProps = _.pick(actions.userSession, ['initialize']);

const mapStateToProps = (state) => ({
  initializeStatus: state.promiseInspections['userSession/initialize'],
});

module.exports = build({
  component: UserSessionInitializer,
  mapDispatchToProps,
  mapStateToProps,
});
