const { buildFieldPath } = require('../../shared/input_builders/helpers');

module.exports = function ({ entryId }) {
  const section = 'header';
  return [
    {
      label: 'Tour Time',
      path: buildFieldPath({ entryId, fieldKey: 'tourTime', section }),
      type: 'time',
    },
    {
      label: 'Property Name',
      path: buildFieldPath({ entryId, fieldKey: 'propertyName', section }),
    },
    {
      options: { entryId },
      path: [entryId, 'address'],
      type: 'address',
    },
    {
      label: 'Cross Streets',
      path: buildFieldPath({ entryId, fieldKey: 'crossStreets', section }),
    },
  ];
};
