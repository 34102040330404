const gray = {
    100: "#FAFAFA",
    200: "#D9DADE",
    300: "#A5A6A8",
    400: "#767779",
    500: "#4E4E50",
    600: "#2D2D2D",
  };

const primary = "#50B196";
const secondary = "#2F373A";
const foreground = "#ffffff";
const colors = {
  gray,
  primary,
  secondary,
  foreground,
};

module.exports = { colors };