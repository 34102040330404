const _ = require('lodash');
const moment = require('moment');

const getAmount = (obj) => obj.amount;

const getYearFromDate = function (date) {
  const dt = new Date(date);
  const year = dt.getFullYear();
  return year;
};

const formatDate = (date) => moment(date).format('MMM D, YYYY');

const isLeaseOrSale = function (isSaleSpace) {
  if (isSaleSpace) {
    return 'Sale';
  } else {
    return 'Lease';
  }
};

const converse = function (boolVal) {
  if (boolVal) {
    return false;
  } else {
    return true;
  }
};

const getSuiteStatus = function (occupied) {
  if (occupied) {
    return 'occupied';
  } else {
    return 'vacant';
  }
};

module.exports = {
  propertyFields: [
    { key: 'propertyId', newKey: 'id' },
    { key: 'propertyId' },
    { key: 'propertyName' },
    { key: 'streetAddress', newKey: 'CorrectedAddress1' },
    { key: 'city' },
    { key: 'county' },
    { key: 'crossStreets' },
    { key: 'state' },
    { key: 'zip' },
    { key: 'numberOfFloors', newKey: 'floors' },
    { key: 'propertySize', newKey: 'buildingArea' },
    { key: 'propertySizeUnit' },
    { key: 'lotLandSize', newKey: 'lotArea', suggested: true },
    { key: 'lotlandSizeUnit' },
    { key: 'ownershipEntityName' },
    {
      key: 'yearOfConstruction',
      newKey: 'yearBuilt',
      transform: getYearFromDate,
      suggested: true,
    },
    {
      key: 'yearOfRenovation',
      newKey: 'yearRenovated',
      transform: getYearFromDate,
      suggested: true,
    },
    { key: 'numberOfFloors', newKey: 'floors' },
    { key: 'latitude' },
    { key: 'longitude' },
    { key: 'propertyType', suggested: true },
    { key: 'propertySubtype', newKey: 'officeClass' },
    { key: 'majorMarketArea' },
    { key: 'submarket', suggested: true },
    {
      key: 'mediaList',
      newKey: 'media',
      transform(obj) {
        return _.map(obj, (image) => _.assign(image, { source: 'property' }));
      },
    },
    { key: 'operatingExpensesPerArea', suggested: true, transform: getAmount },
  ],

  spaceFields: [
    {
      key: 'tenantSize',
      newKey: 'smallestAvailableSpace',
      backup: 'vacantSpace',
    },
    { key: 'tenantSize', newKey: 'availableSize', backup: 'vacantSpace' },
    { key: 'isSaleSpace', newKey: 'leaseOrSale', transform: isLeaseOrSale },
    { key: 'isSaleSpace', newKey: 'isLease', transform: converse },
    { key: 'isSaleSpace', newKey: 'isSale' },
    { key: 'leaseRateLow' },
    { key: 'leaseRateHigh' },
    { key: 'listingType', suggested: true },
    { key: 'askingPrice', newKey: 'salePriceLow', transform: getAmount },
    { key: 'askingPricePerUnit', newKey: 'salePricePerSquareFoot' },
    { key: 'company', suggested: true },
    {
      key: 'contiguousMaxOverall',
      newKey: 'maxContiguous',
      suggested: true,
      transform: getAmount,
    },
    {
      key: 'divisibleMinOverall',
      newKey: 'minDivisible',
      suggested: true,
      transform: getAmount,
    },
    { key: 'estMonthlyRent' },
    { key: 'floor' },
    { key: 'comments' },
    { key: 'floorDescription' },
    { key: 'isSublease', newKey: 'sublease' },
    { key: 'leaseType' },
    { key: 'market', suggested: true },
    { key: 'occupancyDate', newKey: 'availableDate', transform: formatDate },
    { key: 'occupied', newKey: 'suiteStatus', transform: getSuiteStatus },
    { key: 'occupied', newKey: 'vacant', transform: converse, suggested: true },
    { key: 'propertySize', newKey: 'buildingArea' },
    { key: 'propertySubType', newKey: 'officeClass' },
    { key: 'propertySubType', newKey: 'subtype', suggested: true },
    { key: 'relationshipType', suggested: true },
    { key: 'spaceType', suggested: true },
    { key: 'submarket', suggested: true },
    { key: 'suite' },
    { key: 'tenantSiteType', suggested: true },

    // --- nothing in N360 database ---
    { key: 'term' },
    { key: 'capRate', suggested: true },
    { key: 'media' },
    { key: 'offices' },
    { key: 'restrooms', suggested: true },
    { key: 'tiAllowance', suggested: true },
    { key: 'driveIns', suggested: true },
    { key: 'parkingRatio' },
    { key: 'fencedYard', suggested: true },
    { key: 'pavedYard', suggested: true },
    { key: 'sprinkler', suggested: true },
    { key: 'officeAir', suggested: true },
    { key: 'officeHeat', suggested: true },
    { key: 'warehouseHVAC', suggested: true },
    { key: 'leaseRatePerType', suggested: true },
    { key: 'dockDoors', suggested: true },
    { key: 'clearHeightMin', suggested: true },
    { key: 'clearHeightMax', suggested: true },

    //  ----  not defined  ----
    { key: 'propertyId', newKey: 'id', suggested: true },
    { key: 'propertyId', newKey: 'propertyId', suggested: true },
    { key: 'tenantSpaceId', newKey: 'listingId', suggested: true },

    // --- to be added later ---
    { key: 'comments', newKey: 'spaceNotes' },
  ],
};

// REMOVED SPACE FIELDS
// {key: 'salePriceHigh'}
// {key: 'salePriceLow'}
