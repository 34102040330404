const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class ButtonWithShortcut extends React.Component {
  constructor(props) {
    super(props);
    this._onClick = this._onClick.bind(this);
  }

  static initClass() {
    this.propTypes = {
      className: PropTypes.string,
      displayShortcut: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired,
      shortcut: PropTypes.number.isRequired,
    };
  }

  componentWillMount() {
    if (this.props.displayShortcut) {
      return this._registerShortcut();
    }
  }

  componentWillUnmount() {
    return this._unregisterShortcut();
  }

  componentWillUpdate(nextProps) {
    if (this.props.displayShortcut !== nextProps.displayShortcut) {
      if (nextProps.displayShortcut) {
        return this._registerShortcut();
      } else {
        return this._unregisterShortcut();
      }
    }
  }

  render() {
    return (
      <div
        onClick={this._onClick}
        className={cx(['root', `${this.props.className}`])}>
        {this.props.displayShortcut
          ? <div className={cx(['shortcut'])}>
          {this.props.shortcut}
        </div>
          : undefined}
        <a>
          {this.props.label}
        </a>
      </div>
    );
  }

  _onClick(event) {
    event.stopPropagation();
    return this.props.onSelect();
  }

  _registerShortcut() {
    const mapping = { [48 + this.props.shortcut]: this.props.onSelect };
    return (this.unregisterShortcutFn =
      this.props.keyboardShortcutService.register(mapping));
  }

  _unregisterShortcut() {
    if (typeof this.unregisterShortcutFn === 'function') {
      this.unregisterShortcutFn();
    }
    return delete this.unregisterShortcutFn;
  }
}
ButtonWithShortcut.initClass();

module.exports = ButtonWithShortcut;
