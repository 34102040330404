const _ = require('lodash');
const { Promise } = require('bluebird');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const Dropzone = require('react-dropzone');
const Loading = require('@components/shared/loading');
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class ImageUploader extends React.Component {
  static initClass() {
    this.propTypes = {
      isLoading: PropTypes.bool,
      onFileTypeError: PropTypes.func.isRequired,
      onSubmitUrl: PropTypes.func.isRequired,
      onUploadFiles: PropTypes.func.isRequired,
      urlError: PropTypes.bool,
      showDisclaimer: PropTypes.bool,
    };

    this.prototype._debouncedOnUploadUrl = _.debounce(function () {
      if (!this.state.url || this.state.url === '') {
        return;
      }
      return this.props.onSubmitUrl(this.state.url);
    }, 100);

    this.prototype._onUploadFiles = async function (event) {
      const { files } = event.target;
      if (!files) {
        return;
      }
      if (!_.every(files, this._isValidFile)) {
        return this.props.onFileTypeError();
      }
      if (!_.every(files, this._isNotTooBig)) {
        return this.props.onFileSizeError();
      }
      const imagePromises = _.map(files, this._readImageFile);
      const images = await Promise.all(imagePromises);
      const imagesWithNames = images.map((image, i) => {
        return { image, name: files[i].name };
      });
      this.form.reset();
      return this.props.onUploadFiles(imagesWithNames);
    };
  }

  constructor(props) {
    super(props);
    this._onDropFiles = this._onDropFiles.bind(this);
    this._onUrlInputChange = this._onUrlInputChange.bind(this);
    this._onUrlInputKeyDown = this._onUrlInputKeyDown.bind(this);
    this._onUrlInputPaste = this._onUrlInputPaste.bind(this);
    this._renderFileUpload = this._renderFileUpload.bind(this);
    this._renderUrlUpload = this._renderUrlUpload.bind(this);
    this.state = {};
  }

  render() {
    const dropZoneProps = {
      activeClassName: cx(['active']),
      className: cx(['drop-zone']),
      onDrop: this._onDropFiles,
    };
    return (
      <div className={cx(['root'])}>
        <div className={cx(['inner'])}>
          <Dropzone {...dropZoneProps} />
          {this._renderFileUpload()}
          {this._renderUrlUpload()}
        </div>
        {this.props.urlError ? (
          <div className={cx(['url-error'])}>
            <FontAwesomeIcon name="image" />
            <span>Please type a valid HTTP URL.</span>
          </div>
        ) : undefined}
      </div>
    );
  }

  _isValidFile({ type }) {
    const validTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'application/pdf',
    ];
    return _.includes(validTypes, type);
  }

  _isNotTooBig({ size }) {
    const limit = 20;
    const sizeMB = (size / (1024 * 1024)).toFixed(2);
    if (sizeMB < limit) {
      return true;
    } else {
      return false;
    }
  }

  _onDropFiles(files) {
    return this._onUploadFiles({ target: { files } });
  }

  _onUrlInputChange(event) {
    return (this.state.url = event.target.value);
  }

  _onUrlInputKeyDown(event) {
    if (event.which !== 13) {
      return;
    }
    return this._debouncedOnUploadUrl();
  }

  _onUrlInputPaste(event) {
    this.state.url = event.target.value;
    return setTimeout(this._debouncedOnUploadUrl.bind(this), 100);
  }

  _readImageFile(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve({ path: reader.result }));
      return reader.readAsDataURL(file);
    });
  }

  _renderFileUpload() {
    return (
      <div className={cx(['upload-file-container'])}>
        <FontAwesomeIcon name="image" size="2x" />
        <h1>Upload an Image</h1>
        {this.props.isLoading ? (
          <div className={cx(['loading-container'])}>
            <Loading />
          </div>
        ) : (
          <form
            ref={(node) => {
              return (this.form = node);
            }}
          >
            <p>
              {'Drag images here or '}
              <span className={cx(['file-input'])}>
                <a className={cx(['uppercase'])}>Choose File</a>
                <input
                  multiple={this.props.multipleUpload}
                  onChange={this._onUploadFiles.bind(this)}
                  type="file"
                />
              </span>
            </p>
          </form>
        )}
      </div>
    );
  }

  _renderUrlUpload() {
    const inputProps = {
      className: cx([`${this.props.urlError ? 'input-error' : 'url-upload'}`]),
      onChange: this._onUrlInputChange,
      onKeyDown: this._onUrlInputKeyDown,
      onPaste: this._onUrlInputPaste,
      placeholder: 'http://site.example.com/image.jpg',
    };
    return (
      <div className={cx(['upload-url-container'])}>
        <p>Paste an image URL from the web</p>
        <input {...inputProps} />
        {this.props.showDisclaimer && (
          <p className={cx(['disclaimer-text'])}>
            Can’t find the photos you need? Check out our
            <a target="_blank" href="https://brandhub.nmrk.com/">
              Marketing Brand Hub
            </a>
          </p>
        )}
      </div>
    );
  }
}
ImageUploader.initClass();

module.exports = ImageUploader;
