const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const RadioInput = require('../radio_input');
const PropTypes = require('prop-types');
const React = require('react');
const SvgIcon = require('@components/shared/svg_icon');

class ExtraImagePageLayoutInput extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool,
      path: PropTypes.arrayOf(PropTypes.string),
      update: PropTypes.func.isRequired,
      value: PropTypes.string,
    };

    this.prototype.choices = ['1_1_1_1', '2_1_1', '2_2', '4'];
  }

  render() {
    return (
      <RadioInput
        active={this.props.active}
        choices={this.choices}
        name={this.props.path.join('/')}
        renderChoice={function({ checked, choice }) {
          return (
            <div className={cx(['wrapper', checked ? 'checked' : ''])}>
              <SvgIcon name={`entry-extra-image-page-layout-${choice}`} />
            </div>
          );
        }}
        update={this.props.update}
        value={this.props.value} />
    );
  }
}
ExtraImagePageLayoutInput.initClass();

module.exports = ExtraImagePageLayoutInput;
