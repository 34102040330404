const _ = require('lodash');
const { locateService } = require('./service_locator');
const regeneratorRuntime = require('regenerator-runtime'); // leave this in--it's necessary for this service--I'll send a cookie to whoever figures out what it's use for - JWR 

class TourbookMetaService {
  static initClass() {
    this.prototype.build = async function({ tourbook, tourbookId }) {
      const meta = _.mapValues(this._tourbookMetaFields(), (field) =>
        this._getMetaValue(field, tourbook)
      );
      meta.shared = await this._getIsShared({ tourbook, tourbookId });
      return meta;
    };

    this.prototype._getIsShared = async function(
      {
        tourbook,
        tourbookId,
      }
    ) {
      const shareTokens = await this.firebaseService.getValue(
        `shareTokens/${tourbookId}`
      );
      return _.size(tourbook.shared) + _.size(shareTokens) > 0;
    };
  }

  constructor() {
    this.buildEntry = this.buildEntry.bind(this);
    this._getEntriesMetas = this._getEntriesMetas.bind(this);
    this.firebaseService = locateService('firebaseService');
  }

  buildEntry(entry) {
    return _.mapValues(this._tourbookEntryMetaFields(), (field) =>
      this._getMetaValue(field, entry)
    );
  }

  deriveAddressFromEntry(entry) {
    return _.chain(['CorrectedAddress1', 'city', 'state'])
      .map((field) => entry.fields.header?.[field])
      .compact()
      .join(' ')
      .value()
      .toLowerCase();
  }

  deriveNameFromTourbook(tourbook) {
    const { area, location, title } = tourbook.content?.cover?.fields || {};
    return title || location || area || 'Untitled Book';
  }

  getMetaUpdates(path, tourbook) {
    const entryId = this._getEntryIdFromPath(path);
    const update = this._findMetaUpdate(path, tourbook, entryId);
    if (!update) {
      return [];
    }
    path = _.concat(entryId ? ['entries', entryId] : [], update.key);
    return [{ path, value: update.value }];
  }

  _decorateMetaField({ entryId, field, fieldId, tourbook }) {
    const data = entryId ? tourbook?.content?.entries?.[entryId] : tourbook;
    return _.assign({}, field, {
      key: fieldId,
      value: this._getMetaValue(field, data),
    });
  }

  _findMetaUpdate(path, tourbook, entryId) {
    let fieldPathRoot = [];
    if (entryId) {
      fieldPathRoot = _.concat(fieldPathRoot, ['content', 'entries', entryId]);
    }
    const data = entryId ? tourbook.content.entries[entryId] : tourbook;
    const fields = entryId
      ? this._tourbookEntryMetaFields()
      : this._tourbookMetaFields();
    return _.chain(fields)
      .map((field, fieldId) =>
        this._decorateMetaField({ entryId, field, fieldId, tourbook })
      )
      .find((field) =>
        this._isMatchingPath({ data, field, fieldPathRoot, path })
      )
      .value();
  }

  _getEntriesMetas(tourbook) {
    return _.mapValues(tourbook.content?.entries, this.buildEntry);
  }

  _getEntryIdFromPath(path) {
    const index = _.indexOf(path, 'entries');
    if (index === -1) {
      return;
    }
    return path[index + 1];
  }

  _getMetaValue(field, data) {
    let value;
    if (!data) {
      return null;
    }
    if (field.valueFn) {
      value = field.valueFn(data);
    } else if (field.path) {
      value = _.get(data, field.path.join('.'));
    }
    return value || null;
  }

  _getSizePath(entry) {
    if (entry?.type === 'Lease') {
      return ['fields', 'space', 'smallestAvailableSpace', 'value', 'min'];
    } else {
      return ['fields', 'terms', 'availableSize', 'value', 'min'];
    }
  }

  _isMatchingPath({ data, field, fieldPathRoot, path }) {
    const fieldPath =
      (typeof field.pathFn === 'function' ? field.pathFn(data) : undefined) ||
      field.path;
    if (fieldPath) {
      return _.isEqual(path, _.concat(fieldPathRoot, fieldPath));
    } else {
      return _.some(field.fields, (foo) =>
        _.isEqual(path, _.concat(fieldPathRoot, foo))
      );
    }
  }

  _tourbookEntryMetaFields() {
    return {
      address: {
        fields: [
          ['fields', 'header', 'CorrectedAddress1'],
          ['fields', 'header', 'city'],
          ['fields', 'header', 'state'],
        ],
        valueFn: this.deriveAddressFromEntry,
      },
      availableSpace: {
        pathFn: (entry) => this._getSizePath(entry),
        valueFn: (entry) => `${_.get(entry, this._getSizePath(entry))}`,
      },
      floor: { path: ['fields', 'header', 'floor'] },
      leaseOrSale: { path: ['type'] },
      propertyId: { path: ['fields', 'property', 'propertyId'] },
    };
  }

  _tourbookMetaFields() {
    return {
      coverPhotoID: {
        path: ['content', 'cover', 'imagesShown', 'coverPhoto'],
        valueFn(tourbook) {
          return tourbook.content?.cover?.imagesShown?.coverPhoto?.cloudinaryPublicId;
        },
      },
      date: { path: ['content', 'cover', 'fields', 'date'] },
      entries: { valueFn: this._getEntriesMetas },
      name: {
        fields: [
          ['content', 'cover', 'fields', 'title'],
          ['content', 'cover', 'fields', 'location'],
          ['content', 'cover', 'fields', 'area'],
        ],
        valueFn: this.deriveNameFromTourbook,
      },
      ownerEmail: { path: ['owner_email'] },
      ownerID: { path: ['owner_id'] },
    };
  }
}
TourbookMetaService.initClass();

module.exports = TourbookMetaService;
