const _ = require('lodash');

const ImageLibrary = {
  coverPhotos: [
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_123927136',
      mediaTitle: 'bw_123927136',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_124062109',
      mediaTitle: 'bw_124062109',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_1334183',
      mediaTitle: 'bw_1334183',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_266966192',
      mediaTitle: 'bw_266966192',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_293554931',
      mediaTitle: 'bw_293554931',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_414750247',
      mediaTitle: 'bw_414750247',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_444321109',
      mediaTitle: 'bw_444321109',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_473837794',
      mediaTitle: 'bw_473837794',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_506076541',
      mediaTitle: 'bw_506076541',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_523653265',
      mediaTitle: 'bw_523653265',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_532729993',
      mediaTitle: 'bw_532729993',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_540240925',
      mediaTitle: 'bw_540240925',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_550239322',
      mediaTitle: 'bw_550239322',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_560460310',
      mediaTitle: 'bw_560460310',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_560603776',
      mediaTitle: 'bw_560603776',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_562701070',
      mediaTitle: 'bw_562701070',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_572833648',
      mediaTitle: 'bw_572833648',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_57420016',
      mediaTitle: 'bw_57420016',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_625774088',
      mediaTitle: 'bw_625774088',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_635879729',
      mediaTitle: 'bw_635879729',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_643760311',
      mediaTitle: 'bw_643760311',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_737443402',
      mediaTitle: 'bw_737443402',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/bw_780200380',
      mediaTitle: 'bw_780200380',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/abstract_48',
      mediaTitle: 'abstract_48',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/abstract_38',
      mediaTitle: 'abstract_38',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/abstract_03',
      mediaTitle: 'abstract_03',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/gray_vertical_split',
      mediaTitle: 'gray_vertical_split',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/abstract_42',
      mediaTitle: 'abstract_42',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/geometric_02',
      mediaTitle: 'geometric_02',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/geometric_01',
      mediaTitle: 'geometric_01',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/blue_marble',
      mediaTitle: 'blue_marble',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_123927136',
      mediaTitle: 'color_123927136',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_1334183',
      mediaTitle: 'color_1334183',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_266966192',
      mediaTitle: 'color_266966192',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_293554931',
      mediaTitle: 'color_293554931',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_327640640',
      mediaTitle: 'color_327640640',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_414750247',
      mediaTitle: 'color_414750247',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_444321109',
      mediaTitle: 'color_444321109',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_523653265',
      mediaTitle: 'color_523653265',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_532729993',
      mediaTitle: 'color_532729993',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_540240925',
      mediaTitle: 'color_540240925',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_550239322',
      mediaTitle: 'color_550239322',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_560603776',
      mediaTitle: 'color_560603776',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_562701070',
      mediaTitle: 'color_562701070',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_572833648',
      mediaTitle: 'color_572833648',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_57420016',
      mediaTitle: 'color_57420016',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_625774088',
      mediaTitle: 'color_625774088',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_643760311',
      mediaTitle: 'color_643760311',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_737443402',
      mediaTitle: 'color_737443402',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/color_780200380',
      mediaTitle: 'color_780200380',
    },
    {
      cloudinaryPublicId: 'akron_canton-ready_i7oepj',
      mediaTitle: 'Akron/Canton, OH',
    },
    {
      cloudinaryPublicId: 'atlanta-ready_ieq8mw',
      mediaTitle: 'Atlanta, GA',
    },
    {
      cloudinaryPublicId: 'austin-ready_sikbt5',
      mediaTitle: 'Austin, TX',
    },
    {
      cloudinaryPublicId: 'birmingham-ready_m85ppr',
      mediaTitle: 'Birmingham, AL',
    },
    {
      cloudinaryPublicId: 'boulder-ready_gkpcml',
      mediaTitle: 'Boulder, CO',
    },
    {
      cloudinaryPublicId: 'charlotte-ready_kvgq3u',
      mediaTitle: 'Charlotte, NC',
    },
    {
      cloudinaryPublicId: 'chicago-ready_abdx6u',
      mediaTitle: 'Chicago, IL',
    },
    {
      cloudinaryPublicId: 'cincinnati-ready_w7awfa',
      mediaTitle: 'Cincinnati, OH',
    },
    {
      cloudinaryPublicId: 'cleveland-ready_hdfnph',
      mediaTitle: 'Cleveland, OH',
    },
    {
      cloudinaryPublicId: 'colorado_springs-ready_i8kwa5',
      mediaTitle: 'Colorado Springs, CO',
    },
    {
      cloudinaryPublicId: 'columbus-ready_gnhlbw',
      mediaTitle: 'Columbus, OH',
    },
    {
      cloudinaryPublicId: 'corpus_christie-ready_azkafr',
      mediaTitle: 'Corpus Christie, TX',
    },
    {
      cloudinaryPublicId: 'dallas-ready_h9llso',
      mediaTitle: 'Dallas, TX',
    },
    {
      cloudinaryPublicId: 'dayton-ready_dezs93',
      mediaTitle: 'Dayton, OH',
    },
    {
      cloudinaryPublicId: 'denver-ready_mmzri1',
      mediaTitle: 'Denver, CO',
    },
    {
      cloudinaryPublicId: 'des_moines-ready_rzhkvw',
      mediaTitle: 'Des Moines, IA',
    },
    {
      cloudinaryPublicId: 'fort_collins-ready_tstzlc',
      mediaTitle: 'Fort Collins, CO',
    },
    {
      cloudinaryPublicId: 'fort_worth-ready_cpa5eh',
      mediaTitle: 'Fort Worth, TX',
    },
    {
      cloudinaryPublicId: 'houston-ready_cykepv',
      mediaTitle: 'Houston, TX',
    },
    {
      cloudinaryPublicId: 'huntsville-ready_ymze9k',
      mediaTitle: 'Huntsville, AL',
    },
    {
      cloudinaryPublicId: 'inland_empire-ready_ebha16',
      mediaTitle: 'Inland Empire, CA',
    },
    {
      cloudinaryPublicId: 'kansas_city-ready_cc3bo5',
      mediaTitle: 'Kansas City, KS',
    },
    {
      cloudinaryPublicId: 'las_vegas-ready_wnfjg3',
      mediaTitle: 'Las Vegas, NV',
    },
    {
      cloudinaryPublicId: 'little_rock-ready_k056j0',
      mediaTitle: 'Little Rock, AR',
    },
    {
      cloudinaryPublicId: 'los_angeles-ready_etcof8',
      mediaTitle: 'Los Angeles, CA',
    },
    {
      cloudinaryPublicId: 'madison-ready_ntg8u6',
      mediaTitle: 'Madison, WI',
    },
    {
      cloudinaryPublicId: 'memphis-ready_x2h55g',
      mediaTitle: 'Memphis, TN',
    },
    {
      cloudinaryPublicId: 'miami_south_beach-ready_w48dak',
      mediaTitle: 'South Florida, FL',
    },
    {
      cloudinaryPublicId: 'milwaukee-ready_etym8s',
      mediaTitle: 'Milwaukee, WI',
    },
    {
      cloudinaryPublicId: 'minneapolis-ready_ndmzmu',
      mediaTitle: 'Minneapolis, MN',
    },
    {
      cloudinaryPublicId: 'nashville-ready_ykgnew',
      mediaTitle: 'Nashville, TN',
    },
    {
      cloudinaryPublicId: 'new_jersey-ready_gqu7ca',
      mediaTitle: 'New Jersey',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/FlatironCOLOR',
      mediaTitle: 'New York - Flatiron',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/DowntownCOLOR',
      mediaTitle: 'New York - Downtown',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/42nd',
      mediaTitle: 'New York - 42nd St.',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/SohoCOLOR',
      mediaTitle: 'New York - Soho',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/CentralParkCOLOR',
      mediaTitle: 'New York - Central Park',
    },
    {
      cloudinaryPublicId: 'northwest_arkansas-ready_g2kddg',
      mediaTitle: 'Northwest, AR',
    },
    {
      cloudinaryPublicId: 'oklahoma_city-ready_yehwed',
      mediaTitle: 'Oklahoma City, OK',
    },
    {
      cloudinaryPublicId: 'omaha-ready_fyqemk',
      mediaTitle: 'Omaha, NE',
    },
    {
      cloudinaryPublicId: 'orange_county-ready_txaeif',
      mediaTitle: 'Orange County, CA',
    },
    {
      cloudinaryPublicId: 'orlando-ready_djfwm5',
      mediaTitle: 'Orlando, FL',
    },
    {
      cloudinaryPublicId: 'phoenix-ready_q8yfim',
      mediaTitle: 'Phoenix, AZ',
    },
    {
      cloudinaryPublicId: 'raleigh-ready_yaksqs',
      mediaTitle: 'Raleigh/Durham, NC',
    },
    {
      cloudinaryPublicId: 'san_antonio-ready_ebmeum',
      mediaTitle: 'San Antonio, TX',
    },
    {
      cloudinaryPublicId: 'san_diego-ready_c2w01r',
      mediaTitle: 'San Diego, CA',
    },
    {
      cloudinaryPublicId: 'tampa-ready_ilwybj',
      mediaTitle: 'Tampa, FL',
    },
    {
      cloudinaryPublicId: 'tulsa-ready_ecydwz',
      mediaTitle: 'Tulsa, OK',
    },
    {
      cloudinaryPublicId: 'ventura_county-ready_ktwndj',
      mediaTitle: 'Ventura County, CA',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/LightGrayBackground',
      mediaTitle: 'Light Gray',
      textColor: 'black',
    },
    {
      cloudinaryPublicId: 'ptp/cover-photo/WhiteBackground',
      mediaTitle: 'White',
      textColor: 'black',
    },
  ],

  // links removed because images missing from cloudinary
  // cloudinaryPublicId: 'ptp/cover-photo/BankofAmericaCOLOR',
  // mediaTitle: 'New York - Midtown'
  // ,
  // cloudinaryPublicId: 'sarasota_manatee-ready_ztotbs'
  // mediaTitle: 'Sarasota/Manatee, FL'
  // ,
  // cloudinaryPublicId: 'st_louis-ready_fpnarx'
  // mediaTitle: 'St Louis, MO'
  // ,

  defaultCoverPhoto() {
    return _.find(this.coverPhotos, ['mediaTitle', 'New York - Flatiron']);
  },
};

module.exports = ImageLibrary;
