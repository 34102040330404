const React = require('react');
const Loading = require('./');

const loadingToggle = function (loadingProps, loadedComponent) {
  if (loadingProps.isLoading) {
    return <Loading {...loadingProps} />;
  } else {
    return loadedComponent;
  }
};

module.exports = loadingToggle;
