const { Container, Row, Col } = require('react-bootstrap');

const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const SvgIcon = require('@components/shared/svg_icon');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class Walkscore extends React.Component {
  static initClass() {
    this.propTypes = {
      nearbyTransportation: PropTypes.array,
      transitscore: PropTypes.number,
      transitscoreText: PropTypes.string,
      walkscore: PropTypes.number,
      walkscoreText: PropTypes.string,
      wide: PropTypes.bool,
    };
  }

  render() {
    const compliancetext = 'https://www.redfin.com/how-walk-score-works';
    const targettext = '__blank';
    const attributionText = 'Nationwide public transit data from Walk Score';
    return (
      <div className={cx([`root`, `${this.props.wide ? 'wide' : ''}`])}>
        <div className={cx(['nearby-transportation'])}>
          <div className={cx(['routes'])}>
            <Container>
              <Row>
                {/* this is the spot */}
                {this.props.nearbyTransportation != null
                  ? this.props.nearbyTransportation.map((route, index) => {
                      return (
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          key={index}
                          className={cx(['route'])}
                        >
                          <Row>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              className={`${cx([
                                'icon',
                              ])} align-self-start pr-1 align-items-center `}
                            >
                              <Row className="align-items-center">
                                <FontAwesomeIcon name={this._getRouteIconName(route)} />
                                <div className={`ps-1 pl-1 ${cx(['name'])} `}>{route.name}</div>
                              </Row>
                            </Col>

                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              className={`align-self-end pr-0`}
                            >
                              <Row>
                                <div className={`${cx(['distance'])}`}>
                                  {`${route.distance} mi`}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      );
                    })
                  : undefined}
              </Row>
            </Container>
          </div>
          <a href={compliancetext} target={targettext} className={cx(['transit-attribution'])}>
            {attributionText}
          </a>
        </div>
        <div className={cx(['scores'])}>
          {this._renderScore({
            bubbleIconName: 'talk',
            descriptionIconName: 'shoe-prints fa-rotate-270',
            score: this.props.walkscore,
            text: this.props.walkscoreText,
          })}
          {this._renderScore({
            bubbleIconName: 'bus',
            descriptionIconName: 'bus',
            score: this.props.transitscore,
            text: this.props.transitscoreText,
          })}
        </div>
        <div className={cx(['attribution'])} />
      </div>
    );
  }

  _getRouteIconName(route) {
    if (route.category === 'Rail') {
      return 'train';
    } else {
      return 'bus';
    }
  }

  _renderScore({ bubbleIconName, descriptionIconName, score, text }) {
    const compliancetext = 'https://www.redfin.com/how-walk-score-works';
    const targettext = '__blank';
    return (
      <div className={cx(['score'])}>
        <div className={cx(['bubble-wrapper'])}>
          <a href={compliancetext} target={targettext} className={cx(['IndexScore'])}>
            <div className={cx(['background'])}>
              <SvgIcon name={`${bubbleIconName}-bubble`} />
            </div>
            <div className={cx(['number'])}>{score}</div>
          </a>
        </div>
        <div className={cx(['description-wrapper'])}>
          <div className={cx(['icon'])}>
            <FontAwesomeIcon name={descriptionIconName} />
          </div>
          <div className={cx(['text'])}>{text}</div>
        </div>
      </div>
    );
  }
}
Walkscore.initClass();

module.exports = Walkscore;
