const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class SvgIcon extends React.Component {
  static initClass() {
    this.propTypes = { name: PropTypes.string.isRequired };
  }

  render() {
    if (['integration_test', 'test'].includes(process.env.NODE_ENV)) {
      return <svg className={cx(['root'])} />;
    } else {
      const Icon = require(`!svg-react-loader?name=Icon!../../../assets/icons/${this.props.name}.svg`);
      return <Icon className={cx(['root'])} />;
    }
  }
}
SvgIcon.initClass();

module.exports = SvgIcon;
