const Loading = require('@components/shared/loading');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const { useEffect } = require('react');

const ProfilePage = function (props) {

  const contactFields = [
    { key: 'first_name', label: 'First Name', required: true },
    { key: 'last_name', label: 'Last Name', required: true },
    { key: 'company', label: 'Company Name', required: true },
    { key: 'title', label: 'Title' },
    { key: 'phone', label: 'Phone' },
  ];

  useEffect(async function () {
    const cancellable = await props.subscribeToData();
    return () => cancellable();
  }, []);

  const onChange = async function (event) {
    await props.updateUserProfile(event.target.id, event.target.value)
  };

  const isLoading = JSON.stringify({}) === JSON.stringify(props.userProfile);
  return (
    isLoading ? <Loading></Loading> :
      <div className={cx(['profile-page'])}>
        <div className={cx(['content-wrapper'])}>
          <div className={cx(['content', 'section-title'])} >
            <div >Profile & Account settings</div>
            <div className={cx(['sub-section-title'])}>Contact Info</div>
            <div className={cx(['sub-section', 'contact-info'])}>
              <div className={cx(['fields'])}>
                <div className={cx(['field-group', 'email'])}>
                  <label>Email:</label><span>{props.userProfile.email}</span>
                </div>
              </div>

              <div className={cx(['fields'])}>
                {contactFields.map(cf => (
                  <div key={cf.key} className={cx(['field-group'])} >
                    <>
                      <label>{cf.label}:</label>
                      <input id={cf.key} className={cx(['form-control'])} value={props.userProfile[cf.key]} onChange={onChange} />
                      {cf.required && (<div className={cx(['required'])}>*</div>)}
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

module.exports = ProfilePage;