const _ = require('lodash');
const { locateServices } = require('./service_locator');

class ProfileService {
  static initClass() {
    this.prototype.subscribeToUpdates = async function (id, fn) {
      return await this.firebaseService.subscribeToUpdates(`users/${id}/profile`, fn);
    }
    this.prototype.updateUserProfile = async function (id, key, value) {
      return await this.firebaseService.setValue(`users/${id}/profile/${key}`, value);
    }
  }

  constructor() {
    ({
      firebaseService: this.firebaseService,
      tourbookMetaService: this.tourbookMetaService,
    } = locateServices(['firebaseService', 'tourbookMetaService']));
  }

}
ProfileService.initClass();

module.exports = ProfileService;
