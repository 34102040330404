const _ = require('lodash');

const mapping = {
  fulfilled(state, { payload: { key, value } }) {
    return _.assign({}, state, { [key]: { fulfilled: true, value } });
  },

  pending(state, { payload: key }) {
    return _.assign({}, state, { [key]: { pending: true } });
  },

  rejected(state, { payload: { key, reason } }) {
    return _.assign({}, state, { [key]: { error: reason, rejected: true } });
  },

  reset(state, { payload: key }) {
    return _.omit(state, key);
  },
};

module.exports = { initialState: {}, mapping, namespace: 'promiseInspections' };
