const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const Loading = require('@components/shared/loading');
const Switch = require('@components/shared/switch');

class AmenitiesInput extends React.Component {
  static initClass() {
    this.propTypes = {
      addAmenity: PropTypes.func.isRequired,
      amenitiesService: PropTypes.object,
      entryId: PropTypes.string.isRequired,
      loadingStatuses: PropTypes.object,
      removeAmenity: PropTypes.func.isRequired,
      track: PropTypes.func.isRequired,
      value: PropTypes.object,
    };
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <div className={cx(['message'])}>
          Please select the amenities you'd like to display:
        </div>
        <div className={cx(['amenities'])}>
          {this._getConfigs().map(({ description, key, type }, index) => {
            const isOn = this._isAmenityOn(
              this.props.value?.[key],
              type
            );
            const isLoading = this.props.loadingStatuses[type];
            return (
              <div key={index} className={cx(['row', type, isOn ? 'on' : ''])}>
                <div className={cx(['text'])}>
                  <div className={cx(['name'])}>
                    <span>
                      {this.props.amenitiesService.getTitle(type)}
                    </span>
                    {this.props.value?.[key]?.noData && (
                      <div className={cx(['no-data'])}>No data available</div>
                    )}
                    {this.props.value?.[key]?.error && (
                      <div className={cx(['error'])}>There was an error loading this data</div>
                    )}
                  </div>
                  <div className={cx(['description'])}>
                    {description}
                  </div>
                  {isLoading
                    ? <div className={cx(['loading'])}>
                    <Loading inline={true} />
                  </div>
                    : undefined}
                </div>
                <div className={cx(['switch'])}>
                  <Switch
                    onChange={(newValue) => {
                      this.props.track(`Toggles ${_.startCase(type)} Amenity`, {
                        additionalProperties: {
                          new_position: newValue ? 'on' : 'off',
                        },
                        entryId: this.props.entryId,
                      });
                      if (newValue) {
                        return this.props.addAmenity({ key, type });
                      } else {
                        return this.props.removeAmenity(key);
                      }
                    }}
                    value={isOn || isLoading} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  _getConfigs() {
    return [
      {
        description: 'A list of nearby public transportation options',
        key: 'top_right',
        type: 'walkscore',
      },
      {
        description: 'A collection of photos taken in the nearby area',
        key: 'top_left',
        type: 'nearby_places',
      },
      {
        description: 'A list of top attractions nearby',
        key: 'mid_left',
        type: 'googleplaces',
      },
      {
        description:
          'A list of other businesses in the same building or nearby',
        key: 'mid_right',
        type: 'googlebiz',
      },
    ];
  }

  _isAmenityOn(amenity, type) {
    return (
      amenity && amenity.type === type && !amenity.noData && !amenity.error
    );
  }
}
AmenitiesInput.initClass();

module.exports = AmenitiesInput;

