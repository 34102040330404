const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class RadioInput extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool,
      choices: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      renderChoice: PropTypes.func,
      update: PropTypes.func.isRequired,
      value: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    this._renderRadio = this._renderRadio.bind(this);
    this.inputs = {};
  }

  componentDidMount() {
    if (this.props.active) {
      return this.inputs[this.props.value].focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active === this.props.active) {
      return;
    }
    const fnName = this.props.active ? 'focus' : 'blur';
    return this.inputs[this.props.value][fnName]();
  }

  render() {
    return (
      <div className={cx(['root'])}>
        {this.props.choices.map(this._renderRadio)}
      </div>
    );
  }

  _renderRadio(choice, index) {
    const checked = choice === this.props.value;
    return (
      <label key={index}>
        <input
          checked={checked}
          name={this.props.name}
          onChange={() => this.props.update(choice)}
          ref={(node) => {
            return (this.inputs[choice] = node);
          }}
          type="radio" />
        {this.props.renderChoice({ checked, choice })}
      </label>
    );
  }
}
RadioInput.initClass();

module.exports = RadioInput;
