const React = require('react');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const ModalContainer = require('@components/shared/modal/container');
const {
  fieldKeyToFieldDefinitionKey,
} = require('@components/pages/tourbook_editor_page/multi_space_util');
const _ = require('lodash');

const AdditionalInputSelection = (props) => {
  const {
    field,
    data,
    fieldDefinitions,
    tourbookFieldsService,
    entry,
    section,
    onClose,
  } = props;
  const pathToIsUrl = [...field.path.slice(0, -1), 'isUrl'];
  const pathToAnchorText = [...field.path.slice(0, -1), 'anchorText'];

  const isUrl = _.get(data, pathToIsUrl);
  const anchorText = _.get(data, pathToAnchorText);

  const value = _.get(data, field.path.join('.'));
  const fieldDefinitionKey = field.fieldDefinitionKey
    ? field.fieldDefinitionKey
    : fieldKeyToFieldDefinitionKey(field.key);
  const fieldUnit = tourbookFieldsService.getFieldUnit({
    fieldDefinition: fieldDefinitions[fieldDefinitionKey],
    unit: field.unit,
  });
  const disableUrl = ['select', 'range'].includes(field.type);
  const modalProps = { onClose };
  return (
    <ModalContainer {...modalProps}>
      <div className={cx(['flex-container'])}>
        <div className={cx(['row'])}>
          <div className={cx(['field-label'])}>
            <span>
              {`Label: ${tourbookFieldsService.getFieldLabel(
                field,
                fieldDefinitions
              )}`}
            </span>
            {fieldUnit && (
              <span className={cx(['field-unit'])}>{` (${fieldUnit})`}</span>
            )}
          </div>
          {value && (
            <div className={cx(['field-value'])}>
              {`Value: ${tourbookFieldsService.getFieldValueAsString({
                fieldDefinition: fieldDefinitions[fieldDefinitionKey],
                value: entry.fields[section][field.key]?.value,
              })}`}
            </div>
          )}
        </div>
        <label className={cx([disableUrl ? 'disabled-url-select' : '', 'row'])}>
          <input
            type="checkbox"
            checked={isUrl}
            disabled={disableUrl}
            onChange={() => {
              return props.update({
                path: pathToIsUrl,
                value: !isUrl,
              });
            }}
          />
          Above field is a URL/link. Make it clickable in digital tourbook
        </label>
        <label
          className={cx([disableUrl ? 'disabled-url-select' : '', 'row'])}
          hidden={!isUrl}
        >
          <input
            placeholder="Enter the text to display for the hyperlink in digital/PDF formats"
            className={cx(['lightTextInput'])}
            value={anchorText}
            onChange={(anchorTextValue) => {
              return props.update({
                path: pathToAnchorText,
                value: anchorTextValue.target.value,
              });
            }}
          />
          Enter anchor text to style the hyperlink in digital / PDF formats
        </label>
      </div>
    </ModalContainer>
  );
};

AdditionalInputSelection.propTypes = {
  field: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  fieldDefinitions: PropTypes.object.isRequired,
  tourbookFieldsService: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

module.exports = AdditionalInputSelection;
