const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PropTypes = require('prop-types');
const UpdateEntryAddressModal = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'track',
  'update',
]);

const mapStateToProps = (state, ownProps) => ({
  fields:
    state.tourbookEditorPage.data.content.entries[ownProps.options.entryId]
      .fields,
});

const propTypes = {
  onClose: PropTypes.func.isRequired,
  options: PropTypes.shape({
    entryId: PropTypes.string.isRequired,
  }).isRequired,
};

module.exports = build({
  component: UpdateEntryAddressModal,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
});
