const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class CustomInput extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active && !this.props.active) {
      return this.input.blur();
    }
  }

  focus() { }
  // necessary for error not to be thrown by datepicker

  render() {
    return (
      <div className={cx(['root'])}>
        <input {...this._getInputProps()} />
        {this.props.value === ''
          ? <div onClick={() => this.input.focus()} className={cx(['fa', 'fa-calendar'])} />
          : <div onClick={this.props.onClear} className={cx(['clear'])}>
          <FontAwesomeIcon name="xmark" />
        </div>}
      </div>
    );
  }

  _getInputProps() {
    const passThrough = _.omit(this.props, ['active', 'onClear']);
    return _.assign(
      {
        ref: (node) => {
          return (this.input = node);
        },
      },
      passThrough
    );
  }
}

module.exports = CustomInput;
