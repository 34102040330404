const ModalContainer = require('@components/shared/modal/container');
const PdfContainer = require('../pdf/container');
const PropTypes = require('prop-types');
const React = require('react');

class PdfPreviewModal extends React.Component {
  constructor(props) {
    super(props);
    this._onClose = this._onClose.bind(this);
  }

  static initClass() {
    this.propTypes = { setCurrentModal: PropTypes.func.isRequired };
  }

  render() {
    return (
      <ModalContainer autoFocus={true} onClose={this._onClose}>
        <PdfContainer />
      </ModalContainer>
    );
  }

  _onClose() {
    return this.props.setCurrentModal({ key: 'send-to-client' });
  }
}
PdfPreviewModal.initClass();

module.exports = PdfPreviewModal;
