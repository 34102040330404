const _ = require('lodash');
const snakeCase = require('snake-case');
const {
  buildMockService,
  buildMockServices,
} = require('@test/mock_service_helpers');

const serviceCatalog = [
  'auth0Service',
  'amenitiesService',
  'analyticsService',
  'auth0Service',
  'clearbitService',
  'cloudinaryService',
  'customerCommunicationService',
  'dependencyCyclingService',
  'dependencyRecursiveService',
  'docraptorApiService',
  'docraptorHtmlService',
  'entriesByAddressService',
  'entryNumberService',
  'fieldFormatterService',
  'firebaseService',
  'googleAddressService',
  'googleMapQueryService',
  'imageUrlService',
  'keyboardShortcutService',
  'imageService',
  'mobileService',
  'n360Fields',
  'n360FieldsService',
  'n360Service',
  'n360QueryService',
  'navigationService',
  'organizationService',
  'pageNumberService',
  'pdfService',
  'profileService',
  'scrollService',
  'sharingService',
  'suggestedFields',
  'suggestedFieldsService',
  'summaryService',
  'tourbookAnalyticsService',
  'tourbookBuilderService',
  'tourbookEditorPageAnalyticsService',
  'tourbookEntryBuilderService',
  'tourbookEntryFields',
  'tourbookEntryFieldsService',
  'tourbookEntryImagesService',
  'tourbookFields',
  'tourbookFieldsService',
  'tourbookMetaService',
  'tourbookMigrationService',
  'tourbooksService',
  'userTourbooksService',
  'usersService',
  'userTourbooksService',
];

const actionsCatalog = [
  'libraryPageActions',
  'profilePageActions',
  'collaborateModalActions',
  'testActions',
];

const loadDependencies = function () {
  switch (process.env.NODE_ENV) {
    case 'test':
      return require('../../test/mock_dependencies');
    default:
      return require('./dependencies');
  }
};

const realBuilders = {};

_.each(
  serviceCatalog,
  (serviceName) =>
    (realBuilders[serviceName] = function () {
      const service = require('@services/' + snakeCase(serviceName));
      if (_.isFunction(service)) {
        return new service(loadDependencies());
      } else if (service.__esModule) { // typescript
        const serviceNameInPascal = _.upperFirst(_.camelCase(serviceName));
        return new service[serviceNameInPascal](loadDependencies());
      } else {
        return service;
      }
    })
);

_.each(
  actionsCatalog,
  (actionsName) =>
    (realBuilders[actionsName] = function () {
      const makeActions = require('@store/actions/' + snakeCase(actionsName));
      return makeActions();
    })
);

realBuilders.dependencies = loadDependencies;

let _mkErrorMessage = null;
let _serviceBuilders = null;
let _instanceCache = null;

const stubService = (name) => (_instanceCache[name] = buildMockService(name));

const stubServices = function (names) {
  const mockServices = buildMockServices(names);
  _.map(
    mockServices,
    (mockService, mockServiceName) =>
      (_instanceCache[mockServiceName] = mockService)
  );
  return _instanceCache;
};

const mockAllServices = function () {
  _serviceBuilders = {};
  _.map(
    serviceCatalog,
    (serviceName) =>
      (_serviceBuilders[serviceName] = () => buildMockService(serviceName))
  );
  _mkErrorMessage = (name) => `unmocked service: ${name}`;
  return (_instanceCache = {});
};

const resetServices = function () {
  _mkErrorMessage = (name) => `unregistered service: ${name}`;
  _serviceBuilders = _.assign({}, realBuilders);
  return (_instanceCache = {});
};

resetServices();

const setService = (name, instance) => (_instanceCache[name] = instance);

const setServices = (serviceInstances) =>
  _.each(serviceInstances, (instance, name) => setService(name, instance));

const locateService = function (name) {
  if (_serviceBuilders[name] == null) {
    throw new Error(_mkErrorMessage(name));
  }

  if (!_instanceCache[name]) {
    _instanceCache[name] = _serviceBuilders[name]();
  }

  return _instanceCache[name];
};

const locateServices = function (names) {
  const services = {};
  _.each(names, (name) => (services[name] = locateService(name)));
  return services;
};

module.exports = {
  locateService,
  locateServices,
  mockAllServices,
  resetServices,
  setService,
  setServices,
  stubService,
  stubServices,
};
