module.exports = {
  LOGO_DEFAULT_WIDTH: 10, // unit: vw
  LOGO_MIN_WIDTH: 1, // unit: vw
  LOGO_MAX_WIDTH: 45, // unit: vw
  LOGO_WIDTH_INCREMENT: 0.5, // unit: vw
  NEWMARK_LOGO_MIN_WIDTH: 72, // unit: px // minimum for 'Newmark' logo, from brandhub.nmrk.com

  FONT_SIZE_DEFAULT: 10,
  LINE_SPACING_DEFAULT: false,
};
