const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ImagePicker = require('./components/image_picker');
const ImageUploader = require('./components/image_uploader');
const LogoSearchContainer = require('./components/logo_search/container');
const ModalContainer = require('@components/shared/modal/container');
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class ChangeImageModal extends React.Component {
  static initClass() {
    this.propTypes = {
      imageUploadStatus: PropTypes.object,
      entryId: PropTypes.string,
      onClose: PropTypes.func.isRequired,
      selectableImagesByType: PropTypes.object.isRequired,
      updateImages: PropTypes.func.isRequired,
      uploadImages: PropTypes.func.isRequired,
      imageService: PropTypes.object.isRequired,
    };

    this.prototype._uploadImages = async function (images, uiType) {
      const results = await this.props.uploadImages(this.props.entryId, images);

      this.setState({
        ...this.state,
        urlError: results.some((result) => this._isUrlError(result.error)),
        failedFilenames: results
          .filter((result) => !!result.error)
          .map((result) => result.filename),
      });
      return this._selectImages(results, uiType);
    };
  }

  constructor(props) {
    super(props);
    this._selectImages = this._selectImages.bind(this);
    this.state = {
      fileTypeError: false,
      urlError: false,
      fileSizeError: false,
      fileQualityWarning: false,
      imagesToProcess: null,
      failedFilenames: [],
    };
    this.coverPhotoUpload = props.options.path.includes('coverPhoto');
  }

  async _checkPhotoQualityBeforeUpload(images, uiType) {
    // Check for photo quality only if ths is a cover photo - in landscape mode
    const suggestedMinWidth = 1100; // 100 DPI for Letter paper (3300 for 300 DPI)
    const suggestedMinHeight = 850; // 100 DPI for Letter paper (2550 for 300 DPI)
    if (this.coverPhotoUpload) {
      const img = await this.props.imageService.getImageFromUrl(images[0].image.path); // there should be only one image
      if (img.width < suggestedMinWidth || img.height < suggestedMinHeight) {
        this.setState({
          fileQualityWarning: true,
          imagesToProcess: { images, uiType },
        });
        return;
      }
    }
    return this._uploadImages(images, uiType);
  }

  render() {
    let url;
    return (
      <ModalContainer
        onClose={this.props.onClose}
        verticallyCentered={this._isVerticallyCentered()}
      >
        <div className={cx(['root'])}>
          {this.state.fileQualityWarning
            ? this._renderFileQualityWarning()
            : undefined}
          {this.state.failedFilenames.length > 0
            ? this._renderFileUploadFailure(this.state.failedFilenames)
            : undefined}
          {this.state.fileTypeError ? this._renderFileTypeError() : undefined}
          {this.state.fileSizeError ? (
            this._renderFileSizeError()
          ) : (
            <div>
              <div className={cx(['upload-container'])}>
                <ImageUploader
                  isLoading={this.props.imageUploadStatus?.pending}
                  onFileTypeError={() => this.setState({ fileTypeError: true })}
                  onFileSizeError={() => this.setState({ fileSizeError: true })}
                  onSubmitUrl={(url) => {
                    this.setState({ urlError: false });
                    return this._checkPhotoQualityBeforeUpload(
                      [{image: { path: url }}],
                      null
                    );
                  }}
                  onUploadFiles={(files) =>
                    this._checkPhotoQualityBeforeUpload(files, 'upload')
                  }
                  urlError={this.state.urlError}
                  multipleUpload={!this.coverPhotoUpload}
                  showDisclaimer={
                    this.props.selectableImagesByType.showDisclaimer
                  }
                />
              </div>
              {(() => {
                if (this.props.options.type === 'logo') {
                  return (
                    <div className={cx(['logo-search-container'])}>
                      <LogoSearchContainer
                        onSelect={(url) =>
                          this._checkPhotoQualityBeforeUpload(
                            [{image: { path: url }}],
                            'logo_search'
                          )
                        }
                      />
                    </div>
                  );
                } else if (this._hasSelectableImages()) {
                  return (
                    <div className={cx(['image-picker-container'])}>
                      <ImagePicker
                        imagesByType={this.props.selectableImagesByType}
                        multiple={this.props.options.type === 'entry'}
                        onSelectImages={this._selectImages}
                      />
                    </div>
                  );
                }
              })()}
            </div>
          )}
        </div>
      </ModalContainer>
    );
  }

  _hasSelectableImages() {
    return _.chain(this.props.selectableImagesByType)
      .values()
      .some((x) => x.length > 0)
      .value();
  }

  _isUrlError(message) {
    return _.some(['Unsupported source URL', 'Resource not found'], (error) =>
      _.includes(message, error)
    );
  }

  _isVerticallyCentered() {
    return !(this.props.options.type === 'logo' || this._hasSelectableImages());
  }

  _renderFileTypeError() {
    return (
      <div className={cx(['file-type-error-container'])}>
        <FontAwesomeIcon name="file-circle-xmark" />
        <p>The file you're trying to upload is not an image</p>
        <div className={cx(['divider'])} />
        <p className={cx(['small'])}>
          Please make sure you upload any of these options:
        </p>
        <p className={cx(['small'])}>.jpg, .png, .pdf</p>
      </div>
    );
  }

  _renderFileSizeError() {
    return (
      <div className={cx(['file-size-error-container'])}>
        <FontAwesomeIcon name="file-circle-xmark" />
        <p>
          The file you're trying to upload exceeds the current size limits of 20
          MB or 25 megapixels.
        </p>
        <div className={cx(['divider'])} />
        <p className={cx(['small'])}>
          Please compress and/or resize the image and try again.
        </p>
        <p className={cx(['small'])}>
          Support for uploading larger file sizes coming soon.
        </p>
      </div>
    );
  }

  _renderFileQualityWarning() {
    return (
      <div className={cx(['file-type-error-container'])}>
        <FontAwesomeIcon name="file-circle-xmark" />
        <p>
          The cover photo quality is low and will appear blurry if the tourbook
          is printed. Would you like to continue?
        </p>
        <button
          className={cx(['file-error-button'])}
          onClick={() => {
            const { images, uiType } = this.state.imagesToProcess;
            this.setState({ fileQualityWarning: false, imagesToProcess: null });
            this._uploadImages(images, uiType);
          }}
        >
          Yes, use the photo
        </button>
        <button
          className={cx(['file-error-button'])}
          onClick={() => {
            this.setState({ fileQualityWarning: false, imagesToProcess: null });
          }}
        >
          No, try a different photo
        </button>
      </div>
    );
  }

  _renderFileUploadFailure(failedFilenames) {
    return (
      <div className={cx(['general-error-container'])}>
        Below files failed to upload:
        <br />
        <span>
          {failedFilenames.map((filename) => (
            <>
              {filename}
              <br />
            </>
          ))}
        </span>
      </div>
    );
  }

  _selectImages(images, uiType) {
    const successfulImages = images
      .filter((image) => !image.error)
      .map((image) => (image.image ? image.image : image));

    this.props.updateImages(this.props.entryId, successfulImages, uiType);

    const allImagesUploadedSuccessfully = successfulImages.length === images.length;
    if (allImagesUploadedSuccessfully) {
      return this.props.onClose();
    }
  }
}
ChangeImageModal.initClass();

module.exports = ChangeImageModal;
