const _ = require('lodash');
const { locateServices } = require('./service_locator');
const madison = require('madison');

class EntriesByAddressService {
  constructor() {
    ({ firebaseService: this.firebaseService, tourbookMetaService: this.tourbookMetaService } =
      locateServices(['firebaseService', 'tourbookMetaService']));
  }

  async getAllFromAll({ state, city, streetAddress }) {
    state = this.__formatState(state);
    const returnEntries = await this.getEntriesAtSameAddress(state, city, streetAddress);

    return _.orderBy(returnEntries, ['tourbookDate'], ['desc']).filter((x) => x);
  }
  /**
   * Groups the updates for a specific tourbook together
   * @param {string} tourbookId
   * @param {object} tourbook
   * @param {string[]} path
   * @param {object} value
   * @returns an array of promises
   */
  async updateEntriesByAddress(tourbookId, tourbook, path, value) {
    const promises = [];
    const isTourbookLevelUpdateForEntriesByAddress = (path) => {
      const isForConfidential =
        path.length === 3 && path[1] === 'settings' && path[2] === 'confidential';
      const isForTourbookDate =
        path.length === 4 && path[1] === 'cover' && path[2] === 'fields' && path[3] === 'date';
      const isForTourbookName =
        path.length === 4 &&
        path[0] === 'content' &&
        path[1] === 'cover' &&
        path[2] === 'fields' &&
        path[3] === 'title';
      return isForConfidential || isForTourbookDate || isForTourbookName;
    };

    const isEntryLevelUpdateForEntriesByAddress = (path) => {
      // path[0, 1, 2] are always content, entries, <entryId>
      const isForAvailableSpace = this._isPathForAvailableSpace(path);
      const isForFloor = this._isPathForFloor(path);
      const isForPropertyId =
        path[3] === 'fields' && path[4] === 'property' && path[5] === 'propertyId';
      const isForSuite = path[3] === 'fields' && path[4] === 'header' && path[5] === 'suite';
      const isForType = path[3] === 'type';
      return isForAvailableSpace || isForFloor || isForPropertyId || isForSuite || isForType;
    };

    if (isTourbookLevelUpdateForEntriesByAddress(path)) {
      promises.push(
        this._updateEntriesByAddressForTourbook(
          tourbookId,
          tourbook,
          this.__getStoragePathFromTourbookPath(path),
          value
        )
      );
    } else if (isEntryLevelUpdateForEntriesByAddress(path)) {
      const entryId = path[2];
      const { entryState, entryCity, entryAddress } = this._getStateCityAddressFromEntry(
        tourbook.content.entries[entryId]
      );

      let entriesByAddress = await this.firebaseService.getValue(
        `entriesByAddress/${entryState}/${entryCity}/${entryAddress}`
      );
      const convertValueForStorage = (path, value) => {
        const isForSuite = this._isPathForSuite(path);
        if (isForSuite) {
          return `Ste ${value}`;
        }
        const isForAvailableSpace = this._isPathForAvailableSpace(path);
        if (isForAvailableSpace) {
          return `${value}`; // stringify
        }

        return value;
      };
      entriesByAddress = _.map(
        entriesByAddress,
        function (entry, storageId) {
          if (entry.tourbookId === tourbookId && entryId === entry.entryId) {
            entry[this.__getStoragePathFromTourbookPath(path)] = convertValueForStorage(
              path,
              value
            );
          }
          return entry;
        }.bind(this)
      );
      promises.push(
        this.firebaseService.setValue(
          `entriesByAddress/${entryState}/${entryCity}/${entryAddress}`,
          entriesByAddress
        )
      );
    }
    return promises;
  }
  /**
   * Adds an entryByAddress for a single entry
   * @param {string} tourbookId
   * @param {object} tourbook
   * @param {string} entryId
   * @param {object} entry
   * @returns a promise which will resolve when the entryByAddress has been added
   */
  async addEntryByAddress(tourbookId, tourbook, entryId, entry) {
    const { entryState, entryCity, entryAddress } = this._getStateCityAddressFromEntry(entry);
    const toStore = this._buildEntryByAddressPhysicalStorage(tourbookId, tourbook, entryId, entry);

    return await this.firebaseService.pushValue(
      `entriesByAddress/${entryState}/${entryCity}/${entryAddress}`,
      toStore
    );
  }
  /**
   * @param {string} state
   * @param {string} city
   * @param {string} address
   * @returns all entriesByAddress for a state/city/address combination
   */
  async getEntriesAtSameAddress(state, city, address) {
    state = this.firebaseService.serializeForFirebaseKey(this.__formatState(state));
    city = this.firebaseService.serializeForFirebaseKey(city);
    address = this.firebaseService.serializeForFirebaseKey(address);

    const entriesByAddress =
      (await this.firebaseService.getValue(`entriesByAddress/${state}/${city}/${address}`)) || {};
    return entriesByAddress;
  }
  /**
   * Removes an entryByAddress for a single entry
   * @param {string} tourbookId
   * @param {object} tourbook
   * @param {string} entryId
   * @param {object} entry
   * @returns a promise which will resolve when the entryByAddress has been removed
   */
  async removeEntryByAddress(tourbookId, entryId, entry) {
    const { entryState, entryCity, entryAddress } = this._getStateCityAddressFromEntry(entry);

    const entriesByAddress = await this.firebaseService.getValue(
      `entriesByAddress/${entryState}/${entryCity}/${entryAddress}`
    );
    const updatedEntriesByAddress = _.pickBy(entriesByAddress, function (value, key) {
      return value.tourbookId !== tourbookId || value.entryId !== entryId;
    });

    return await this.firebaseService.setValue(
      `entriesByAddress/${entryState}/${entryCity}/${entryAddress}`,
      updatedEntriesByAddress
    );
  }

  /**
   * Groups removal of the entriesByAddress physical storage so that successive updates to the same address are batched together
   * and we don't clobber other, in-flight updates.
   * @param {*} tourbookId
   * @param {*} tourbook
   * @returns an awaitable Promise
   */
  async removeEntriesByAddress(tourbookId, tourbook) {
    const entries = tourbook?.content?.entries || {};

    let entryAddressStrings = this._getEntryAddressStrings(entries);
    const promises = [];
    // get all the entriesByAddress, for each of the addresses
    for (const addressString of entryAddressStrings) {
      let entriesToFilter = await this.firebaseService.getValue(
        `entriesByAddress/${addressString}`
      );
      entriesToFilter = _.filter(entriesToFilter, (x) => x); // sometimes we get nulls/undefined in the array due to deletions in firebase
      // filter to those entriesByAddress which are not the tourbookId for the tourbook we're removing
      entriesToFilter = _.pickBy(entriesToFilter, function (value, key) {
        return value.tourbookId !== tourbookId;
      });

      // This allows the setValue operation to be batched with other operations
      promises.push(
        this.firebaseService.setValue(`entriesByAddress/${addressString}`, entriesToFilter)
      );
    }

    return await Promise.allSettled(promises);
  }
  __formatState(state) {
    if (!state) {
      return;
    }
    if (state.length <= 2) {
      return state;
    }
    return madison.getStateAbbrev(state);
  }
  _isPathForFloor(path) {
    return path.length === 7 && path[3] === 'fields' && path[5] === 'floor' && path[6] === 'value';
  }
  _isPathForAvailableSpace(path) {
    return (
      path.length === 8 &&
      path[3] === 'fields' &&
      path[5] === 'smallestAvailableSpace' &&
      path[6] === 'value' &&
      path[7] === 'min'
    );
  }
  _isPathForSuite(path) {
    return path.length === 7 && path[3] === 'fields' && path[5] === 'suite' && path[6] === 'value';
  }
  /**
   * convert the string[] path to a single storageValue
   * @param {string[]} path array of strings representing the path to the tourbook
   */
  __getStoragePathFromTourbookPath(path) {
    // first part of path is always 'content'
    const isForTourbookDate =
      path.length === 4 && path[1] === 'cover' && path[2] === 'fields' && path[3] === 'date';
    if (isForTourbookDate) {
      return 'tourbookDate';
    }
    const isForTourbookName =
      path.length === 4 && path[1] === 'cover' && path[2] === 'fields' && path[3] === 'title';
    if (isForTourbookName) {
      return 'tourbookName';
    }

    const isForOwnerEmail = path.length === 1 && path[0] === 'owner_email';
    if (isForOwnerEmail) {
      return 'tourbookCreatedBy';
    }
    const isForAvailableSpace = this._isPathForAvailableSpace(path);
    if (isForAvailableSpace) {
      return 'availableSpace';
    }
    const isForSuite = this._isPathForSuite(path);
    if (isForSuite) {
      return 'suite';
    }
    const isForFloor = this._isPathForFloor(path);
    if (isForFloor) {
      return 'floor';
    }
    return path[path.length - 1];
  }

  _getStateCityAddressFromEntry(entry) {
    const header = entry?.fields?.header || '';
    const formattedState = this.__formatState(header?.state);
    const entryState = this.firebaseService.serializeForFirebaseKey(formattedState || '');
    const entryCity = this.firebaseService.serializeForFirebaseKey(header?.city || '');
    const entryAddress = this.firebaseService.serializeForFirebaseKey(
      header?.CorrectedAddress1 || ''
    );
    return { entryState, entryCity, entryAddress };
  }
  _buildEntryByAddressPhysicalStorage(tourbookId, tourbook, entryId, entry) {
    const toStore = {};
    toStore.tourbookId = tourbookId;
    toStore.entryId = entryId;
    toStore.availableSpace = (
      !!entry?.fields?.space?.smallestAvailableSpace?.value
        ? `${entry.fields.space.smallestAvailableSpace.value.min}`
        : 0
    ).toString();
    toStore.floor = entry.fields.header.floor != null ? entry.fields.header.floor : null;
    toStore.confidential = !!tourbook?.content?.settings?.confidential;
    toStore.propertyId = entry.fields.property != null ? entry.fields.property.propertyId : null;
    toStore.suite = entry.fields.header.suite != null ? 'Ste ' + entry.fields.header.suite : null;
    toStore.tourbookDate =
      tourbook.content.cover?.fields != null ? tourbook.content.cover.fields.date : null;
    toStore.tourbookName =
      tourbook.content.cover?.fields.title != null ? tourbook.content.cover.fields.title : null;
    toStore.tourbookCreatedBy = tourbook.owner_email != null ? tourbook.owner_email : null;
    toStore.type = entry.type != null ? entry.type : null;
    return toStore;
  }
  _getEntryAddressStrings(entries) {
    let entryAddressStrings = [];
    _.forEach(entries, (entry, key) => {
      if (entry['key'] !== 'tourbook-notes') {
        const { entryState, entryCity, entryAddress } = this._getStateCityAddressFromEntry(entry);
        const addressString = `${entryState}/${entryCity}/${entryAddress}`;
        entryAddressStrings.push(addressString);
      }
    });
    return _.uniq(entryAddressStrings);
  }
  /**
   * @param {string} tourbookId
   * @param {object} tourbook
   * @param {string} key
   * @param {object} value
   * @returns an awaitable promise that will update all the entriesByAddress for the given tourbook
   */
  async _updateEntriesByAddressForTourbook(tourbookId, tourbook, key, value) {
    const entries = tourbook?.content?.entries || {};

    const promises = [];
    let entryAddressStrings = this._getEntryAddressStrings(entries);

    // create the promises, for all the entriesByAddress
    for (const addressString of entryAddressStrings) {
      let entriesToUpdate = await this.firebaseService.getValue(
        `entriesByAddress/${addressString}`
      );

      // filter to those entriesByAddress which are not the tourbookId for the tourbook we're removing
      entriesToUpdate = _.map(entriesToUpdate, function (entry, entryId) {
        if (entry.tourbookId === tourbookId) {
          entry[key] = value;
        }
        return entry;
      });

      // This allows the setValue operation to be batched with other operations
      promises.push(
        this.firebaseService.setValue(`entriesByAddress/${addressString}`, entriesToUpdate)
      );
    }

    return await Promise.allSettled(promises);
  }
}

module.exports = EntriesByAddressService;
