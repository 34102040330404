const { Promise } = require('bluebird');
const { locateServices } = require('./service_locator');

class DocraptorApiService {
  static initClass() {
    this.prototype.generatePdf = async function (tourbookHtml, name) {
      const response = await this.axios({
        data: this.getOptions(tourbookHtml, name),
        method: 'POST',
        url: `https://docraptor.com/docs${this._getUrlQuery()}`,
      });
      return await this._getStatus(response.data.status_id);
    };

    this.prototype._getStatus = async function (id) {
      const response = await this.axios({
        method: 'GET',
        url: `https://docraptor.com/status/${id}${this._getUrlQuery()}`,
      });
      switch (response.data.status) {
        case 'completed':
          return response.data.download_url;
        case 'failed':
          throw new Error(
            `PDF Generation failed: ${response.data.validation_errors}`
          );
        default:
          await Promise.delay(this.statusPollDelay);
          return await this._getStatus(id);
      }
    };
  }

  constructor({ ENV: { docraptorConfig }, axios, window }) {
    this.docraptorConfig = docraptorConfig;
    this.axios = axios;
    this.window = window;
    this.statusPollDelay = 3000;
    ({ docraptorHtmlService: this.docraptorHtmlService } = locateServices([
      'docraptorHtmlService',
    ]));
  }

  _getDownloadUrl = async (statusId) => {
    const url = 'https://docraptor.com/status/' + statusId;
    const params = { user_credentials: this.docraptorConfig.apiKey };
    const data = await this.axios.get(url, { params });
    let returnUrl = '';
    switch (data.data.status) {
      case 'completed':
        returnUrl = data.data.download_url;
        break;
      case 'failed':
        throw new Error(`PDF Generation failed: ${data.validation_errors}`);
      default:
        returnUrl = await this._retryDownloadUrl(statusId);
    }
    return returnUrl;
  };

  _retryDownloadUrl = async (id) => {
    await Promise.delay(3000);
    return await this._getDownloadUrl(id);
  };

  getOptions(tourbookHtml, name) {
    return {
      doc: {
        async: true,
        document_content: this.docraptorHtmlService.transform(tourbookHtml),
        type: 'pdf',
        javascript: true,
        name: `${name}.pdf`,
        prince_options: {
          css_dpi: 72,
          media: 'screen',
          baseurl: this.docraptorConfig.baseurl,
        },
        referrer: this.window.location.toString(),
        strict: 'none',
        test: this.docraptorConfig.test,
      },
    };
  }

  _getUrlQuery() {
    return `?user_credentials=${this.docraptorConfig.apiKey}`;
  }
}
DocraptorApiService.initClass();

module.exports = DocraptorApiService;
