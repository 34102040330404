const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PdfSummaryTemplate = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'navigateToCoverView',
]);

const mapStateToProps = function (state) {
  const { data } = state.tourbookEditorPage;

  return {
    accentColor: data.content.settings.accentColor,
    coverFields: data.content.cover.fields || {},
    tourbook: data,
  };
};
module.exports = build({
  component: PdfSummaryTemplate,
  mapDispatchToProps,
  mapStateToProps,
});
