const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const AutoSuggest = require('react-autosuggest');
const { locateService } = require('@services/service_locator');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class LogoSearch extends React.Component {
  static initClass() {
    this.propTypes = { onSelect: PropTypes.func.isRequired };

    this.prototype._updateSuggestions = _.debounce(async function (query) {
      let suggestions = await this.clearbitService.search(query);

      // Replacing Newmark logos from what Clearbit suggests
      if (suggestions.some((s) => s.name.toLowerCase() === 'newmark')) {
        suggestions = suggestions.filter((s) => s.name.toLowerCase() !== 'newmark')
        suggestions.push({
          name: "Newmark",
          domain: "nmrk.com",
          logo: 'https://res.cloudinary.com/ddw0vszek/image/upload/t_ptpthumbnail/ajhgoz5djzfb9fmnstot.png' // Black text
        })
        suggestions.push({
          name: "Newmark",
          domain: "nmrk.com",
          logo: 'https://res.cloudinary.com/ddw0vszek/image/upload/t_ptpthumbnail/his5c8v7dtlre4t3mcds.png' // White text
        })
        suggestions.push({
          name: "Newmark",
          domain: "nmrk.com",
          logo: 'https://res.cloudinary.com/ddw0vszek/image/upload/t_ptpthumbnail/vb8ibupkzntjghzxlzm4.png' // Just N
        })
        suggestions = suggestions.slice(-5) // Get the last five
      }
      return this.setState({ suggestions });
    }, 100);
  }

  constructor(props) {
    super(props);
    this._onSearchChange = this._onSearchChange.bind(this);
    this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
    this.clearbitService = locateService('clearbitService');
    this.state = { searchValue: '', suggestions: [] };
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <h1>Search For a Logo</h1>
        <div className={cx(['input-container'])}>
          <FontAwesomeIcon name="magnifying-glass" />
          <AutoSuggest
            focusFirstSuggestion={true}
            getSuggestionValue={function ({ name }) {
              return name;
            }}
            inputProps={{
              onChange: this._onSearchChange,
              placeholder: 'Type a company name here...',
              value: this.state.searchValue,
            }}
            onSuggestionSelected={this._onSuggestionSelected}
            onSuggestionsClearRequested={() =>
              this.setState({ suggestions: [] })
            }
            onSuggestionsFetchRequested={({ value }) =>
              this._updateSuggestions(value)
            }
            ref={(node) => {
              return (this.autosuggest = node);
            }}
            renderSuggestion={this._renderLogo}
            suggestions={this.state.suggestions}
          />
        </div>
      </div>
    );
  }

  _onSearchChange(event, { method, newValue }) {
    if (method === 'type') {
      return this.setState({ searchValue: newValue });
    }
  }

  _onSuggestionSelected(event, { suggestion }) {
    return this.props.onSelect(suggestion.logo);
  }

  _renderLogo(logo) {
    return (
      <div className={cx(['logo'])}>
        <img src={logo.logo} />
        <div className={cx(['name'])}>{logo.name}</div>
        <div className={cx(['domain'])}>{logo.domain}</div>
      </div>
    );
  }
}
LogoSearch.initClass();

module.exports = LogoSearch;
