const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ImageContainer = require('@components/shared/image/container');
const ReadyRegistry = require('@utils/ready_registry');
const { useDrag } = require('react-dnd');
const DRAG_TYPES = require('@enums/drag_types');

const Image = (props) => {
  const _getRootProps = () => {
    return {
      className: cx([
        `root`,
        `${props.draggable ? 'draggable' : ''}`,
        `${props.isEditing ? 'is-editing' : ''}`,
      ]),
    };
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAG_TYPES.ENTRY_IMAGE,
    item: (monitor) => {
      return props.image;
    },
    end: (item, monitor) => {
      if (
        monitor.getDropResult() &&
        monitor.getDropResult().index !== undefined
      ) {
        if (props.index !== monitor.getDropResult().index) {
          return props.update(null);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [props]);

  return (
    <div {..._getRootProps()} ref={props.draggable ? drag : undefined}>
      <ImageContainer
        backgroundSize="cover"
        croppable={props.croppable}
        entryLocation={props.entryLocation}
        height="100%"
        image={props.image}
        imagePath={props.imagePath}
        readyRegistry={props.readyRegistry}
        width="100%"
      />
    </div>
  );
};

Image.propTypes = {
  croppable: PropTypes.bool,
  draggable: PropTypes.bool,
  entryLocation: PropTypes.object,
  image: PropTypes.object,
  imagePath: PropTypes.array.isRequired,
  index: PropTypes.number,
  isEditing: PropTypes.bool,
  readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
  update: PropTypes.func.isRequired,
};

module.exports = Image;
