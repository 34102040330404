const { actions, build } = require('@components/container_helpers');
const EmailForm = require('./');

const sendToClientKey = 'tourbookEditorPage/sendToClient';

const mapDispatchToProps = (dispatch) => ({
  generatePdf(tourbookHtml) {
    return dispatch(actions.tourbookEditorPage.generatePdf(tourbookHtml));
  },
  resetSendToClientStatus() {
    return dispatch(actions.promiseInspections.reset(sendToClientKey));
  },
  sendToClient(message) {
    return dispatch(actions.tourbookEditorPage.sendToClient(message));
  },
});

const mapStateToProps = (state) => ({
  tourbook: state.tourbookEditorPage.data,
  sendToClientStatus: state.promiseInspections[sendToClientKey],
  userProfile: state.userSession.firebaseProfile
});

const serviceNames = ['pdfService', 'tourbookMetaService', 'usersService'];

module.exports = build({
  component: EmailForm,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames,
});
