const _ = require('lodash');
const importedStyles = require('./index.styl');
const ConfirmActionbar = require('@components/shared/confirm_action_bar');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ModalContainer = require('@components/shared/modal/container');
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class RecommendedFieldsModal extends React.Component {
  static initClass() {
    this.propTypes = {
      fieldDefinitions: PropTypes.object.isRequired,
      fields: PropTypes.object.isRequired,
      onClose: PropTypes.func.isRequired,
      options: PropTypes.shape({
        entryId: PropTypes.string.isRequired,
        section: PropTypes.string.isRequired,
      }),
      previousFields: PropTypes.object.isRequired,
      tourbookFieldsService: PropTypes.object.isRequired,
      trackImportRecommendedFields: PropTypes.func.isRequired,
      n360Data: PropTypes.object.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._import = this._import.bind(this);
    this._renderField = this._renderField.bind(this);
    this._toggleField = this._toggleField.bind(this);
    this.state = { selectedFields: [] };
  }

  render() {
    return (
      <ModalContainer onClose={this.props.onClose}>
        <div className={cx(['root'])}>
          <h1>
            Recommended Fields
          </h1>
          <p>
            Please select the fields you would like to import into your listing.
          </p>
          <div className={cx(['fields-container'])}>
            {this._renderAtlasFields()}
            {this._renderSuggestedFields()}
            {this._renderImport()}
          </div>
        </div>
      </ModalContainer>
    );
  }

  _getFields() {
    return this.props.tourbookFieldsService.getSuggestedFields({
      fieldDefinitions: this.props.fieldDefinitions,
      fields: this.props.fields,
      previousFields: this.props.previousFields,
      section: this.props.options.section,
      n360Data: this.props.n360Data,
    });
  }

  _getSectionType() {
    if (this.props.options.section === 'space_or_terms') {
      return { Lease: 'space', Sale: 'terms' }[this.props.entry.type];
    } else {
      return this.props.options.section;
    }
  }

  _import() {
    const type = this._getSectionType();
    this.props.addAdditionalFields({
      entryId: this.props.options.entryId,
      fields: this.state.selectedFields,
      section: type,
    });
    this.props.trackImportRecommendedFields(this.state.selectedFields);
    return this.props.onClose();
  }

  _renderAtlasFields() {
    const fields = _.chain(this._getFields())
      .filter({ hasValue: true, isAtlas: true })
      .map(this._renderField)
      .value();
    if (!fields.length) {
      return;
    }
    return (
      <div className={cx(['atlas-fields'])}>
        <h2>
          <span>
            Database
          </span>
        </h2>
        <div className={cx(['fields'])}>
          {fields}
        </div>
      </div>
    );
  }

  _renderField(field) {
    const fieldProps = {
      className: cx(['field', `${_.find(this.state.selectedFields, ['key', field.key]) ? 'selected' : ''
        }`]),
      key: field.key,
      onClick: () => this._toggleField(field),
    };
    return (
      <div {...fieldProps}>
        <div className={cx(['field-selector'])}>
          <FontAwesomeIcon name="check" />
        </div>
        <div className={cx(['field-label'])}>
          {this.props.tourbookFieldsService.getFieldLabel(
            field,
            this.props.fieldDefinitions
          )}
        </div>
        <div className={cx(['field-value'])}>
          {this.props.tourbookFieldsService.getSuggestedFieldValue(field)}
        </div>
      </div>
    );
  }

  _renderImport() {
    const count = _.size(this.state.selectedFields);
    if (!count) {
      return;
    }
    let label = 'Import Field';
    if (count > 1) {
      label += 's';
    }
    return <ConfirmActionbar cancel={this.props.onClose} confirm={this._import} label={label} />;
  }

  _renderSuggestedFields() {
    const fields = _.chain(this._getFields())
      .reject({ hasValue: true, isAtlas: true })
      .map(this._renderField)
      .value();
    return (
      <div className={cx(['suggested-fields'])}>
        <h2>
          Suggested Fields
        </h2>
        <div className={cx(['fields'])}>
          {fields}
        </div>
      </div>
    );
  }

  _toggleField(field) {
    const selectedField = _.find(this.state.selectedFields, ['key', field.key]);
    const selectedFields = selectedField
      ? _.without(this.state.selectedFields, selectedField)
      : _.concat(this.state.selectedFields, field);
    return this.setState({ selectedFields });
  }
}
RecommendedFieldsModal.initClass();

module.exports = RecommendedFieldsModal;
