const _ = require('lodash');
const ConfirmModal = require('@components/shared/confirm_modal');
const PropTypes = require('prop-types');
const React = require('react');

class DeleteEntryImageModal extends React.Component {
  static initClass() {
    this.defaultProps = { onClose: _.noop };

    this.propTypes = {
      deleteEntryImage: PropTypes.func.isRequired,
      onClose: PropTypes.func,
      options: PropTypes.shape({
        entryId: PropTypes.string.isRequired,
        imageId: PropTypes.string.isRequired,
      }).isRequired,
    };
  }

  render() {
    return (
      <ConfirmModal
        confirmLabel="Delete Image"
        message={[
          'Are you sure you want to delete this image from the listing?',
          'Doing so will remove the image from the digital tour book and the PDF document (if added)',
        ]}
        onCancel={this.props.onClose}
        onConfirm={() => {
          this.props.deleteEntryImage(this.props.options);
          return this.props.onClose();
        }} />
    );
  }
}
DeleteEntryImageModal.initClass();

module.exports = DeleteEntryImageModal;
