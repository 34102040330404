const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const PdfPageWithFooterContainer = require('../pdf_page_with_footer/container');

class PdfEntryWrapperTemplate extends React.Component {
  static initClass() {
    this.propTypes = {
      address: PropTypes.string,
      city: PropTypes.string,
      entryAccentColor: PropTypes.string,
      entryId: PropTypes.string.isRequired,
      fieldFormatterService: PropTypes.object.isRequired,
      entryNumberService: PropTypes.object.isRequired,
      isEditing: PropTypes.bool,
      propertyName: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    };
  }
  constructor(props) {
    super(props);
    this._getEntryNumber = this._getEntryNumber.bind(this);
  }
  render() {
    return (
      <div className={cx([`root`, `pdf-template`, `${this.props.isEditing ? 'is-editing' : ''}`])}>
        <PdfPageWithFooterContainer
          pageNumber={this.props.pageNumber}
          hidePageNumber={this.props.tourbook.content.entries[this.props.entryId].hidden}
          readyRegistry={this.props.readyRegistry}
        >
          <div className={cx(['wrapper'])}>
            <div className={cx(['header'])}>
              <div
                style={{ borderColor: this.props.entryAccentColor }}
                className={cx(['listing-number'])}
              >
                {this._getEntryNumber(this.props.tourbook.content.entries, this.props.entryId)}
              </div>
              <div {...this._getNameAddressProps()}>{this._getNameAddressText()}</div>
              <div {...this._getCityStateZipProps()}>{this._getCityStateZipText()}</div>
            </div>
            <div className={cx(['children-wrapper'])}>{this.props.children}</div>
          </div>
        </PdfPageWithFooterContainer>
      </div>
    );
  }

  _buildOnClick(fieldKey) {
    return () =>
      this.props.navigateToCurrentEntryField({ fieldKey, tabKey: 'address' });
  }

  _getCityStateZipProps() {
    const cityStateZipProps = {
      className: cx(['city-state-zip']),
    };
    if (this.props.isEditing) {
      return {
        ...cityStateZipProps,
        onClick: this._buildOnClick(`${this.props.entryId}-address`),
      };
    } else {
      return cityStateZipProps;
    }
  }

  _getCityStateZipText() {
    const arg = _.pick(this.props, ['city', 'state', 'zip']);
    return this.props.fieldFormatterService.formatCityStateZip(arg);
  }

  _getNameAddressProps() {
    const nameAddressProps = {
      className: cx(['name-address']),
    };
    if (this.props.isEditing) {
      const fieldKey = this.props.propertyName
        ? `content-entries-${this.props.entryId}-fields-header-propertyName`
        : `${this.props.entryId}-address`;
      return { ...nameAddressProps, onClick: this._buildOnClick(fieldKey) };
    } else {
      return nameAddressProps;
    }
  }

  _getNameAddressText() {
    return this.props.propertyName || this.props.address;
  }
  _getEntryNumber(entries, entryId) {
    return this.props.entryNumberService.getEntryNumber({ entries, entryId });
  }

}
PdfEntryWrapperTemplate.initClass();

module.exports = PdfEntryWrapperTemplate;
