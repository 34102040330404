const { buildFieldPath } = require('../../shared/input_builders/helpers');

module.exports = function ({ entryId, hasNotes }) {
  let inputs = [
    {
      disableEnterNavigation: true,
      options: {
        nextField: [
          {
            id: buildFieldPath({
              entryId,
              fieldKey: 'notesHeader',
              section: 'header',
            }).join('-'),
            value: true,
          },
          { id: 'next', value: false },
        ],
      },
      path: buildFieldPath({
        entryId,
        fieldKey: 'notesToggle',
        section: 'notes',
      }),
      spacedQuestion: true,
      type: 'notes_toggle',
    },
  ];
  if (hasNotes) {
    inputs = inputs.concat([
      {
        label: 'Header',
        path: buildFieldPath({
          entryId,
          fieldKey: 'notesHeader',
          section: 'header',
        }),
      },
      {
        path: buildFieldPath({ entryId, fieldKey: 'notes', section: 'notes' }),
        type: 'notes',
        useShiftEnterNavigation: true,
      },
    ]);
  }
  return inputs;
};
