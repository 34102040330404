const _ = require('lodash');

const getBrokerContactInfos = (cover) =>
  _.filter(
    cover.contactInfos,
    (contactInfo) =>
      contactInfo.type === 'broker' &&
      _.some(['name', 'email', 'phone'], (field) => contactInfo[field])
  );

const getBrokerLogo = (cover) =>
  _.chain(cover.contactInfos)
    .filter(
      (contactInfo, contactInfoId) =>
        contactInfo.type === 'broker' &&
        contactInfo.isLogoDisplayed &&
        contactInfo.logo != null
    )
    .map('logo')
    .first()
    .value();

module.exports = { getBrokerContactInfos, getBrokerLogo };
