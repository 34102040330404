const CollapsibleQuestion = require('@components/shared/collapsible_question');
const PropTypes = require('prop-types');
const React = require('react');

class NotesToggleInput extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      navigateForward: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
      value: PropTypes.bool,
    };
  }

  render() {
    return (
      <CollapsibleQuestion
        active={this.props.active}
        answers={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        onChange={(value) => {
          this.props.update(value);
          return this.props.navigateForward(value);
        }}
        question="Would you like to add notes to this listing?"
        value={this.props.value} />
    );
  }
}
NotesToggleInput.initClass();

module.exports = NotesToggleInput;
