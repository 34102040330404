const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ContactInfoContainer = require('./components/contact_info/container');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const { useEffect } = require('react');

const PdfBrokersTemplate = (props) => {
  const setComponentReady = props.readyRegistry.register('PdfBrokersTemplate');

  const _getContactInfoProps = ({ contactInfo, contactInfoId }) => {
    return {
      contactInfo,
      contactInfoId,
      isEditing: props.isEditing,
      readyRegistry: props.readyRegistry,
      shouldRenderLogo: _shouldRenderLogo(contactInfoId),
    };
  };
  const _getBrokerInfoProps = ({ contactInfo, contactInfoId }) => {
    return {
      contactInfo,
      contactInfoId,
      isEditing: props.isEditing,
      readyRegistry: props.readyRegistry,
      shouldRenderLogo: _shouldRenderBrokerLogo(contactInfoId),
    };
  };

  const _getRootProps = (shouldHideBrokerPage) => {
    return {
      className: cx([
        'root',
        'font-color-black',
        'pdf-template',
        'pdf-broker-template',
        props.isEditing ? 'is-editing' : '',
        shouldHideBrokerPage ? 'hide' : 'd',
      ]),
    };
  };

  const _shouldRenderLogo = (contactInfoId) => {
    return (
      props.contactInfos[contactInfoId].isLogoDisplayed &&
      props.contactInfos[contactInfoId].logo != null
    );
  };
  const _shouldRenderBrokerLogo = (contactInfoId) => {
    return (
      props.brokerInfos[contactInfoId].isLogoDisplayed &&
      props.brokerInfos[contactInfoId].logo != null
    );
  };

  const _getContactInfoStorageIds = () => {
    const storageIds = [];
    for (const brokerInfoId of _.keys(props.brokerInfos)) {
      const brokerInfo = props.brokerInfos[brokerInfoId];
      const hasFieldValueBesidesType = Object.values(brokerInfo).filter((value) => !!value).length > 1;
      if (hasFieldValueBesidesType) {
        storageIds.push(brokerInfoId);
      }
    }
    return storageIds;
  }

  // NB: these may not exist, hence the null-checking below
  const firstContactInfoStorageId = _.keys(props.contactInfos)[0];
  const [secondContactInfoStorageId, thirdContactInfoStorageId, fourthContactInfoStorageId] = 
    _getContactInfoStorageIds();

  const firstContactInfoId = `coverContactInfo${firstContactInfoStorageId}`;
  const secondContactInfoId = `coverContactInfo${secondContactInfoStorageId}`;
  const thirdContactInfoId = `coverContactInfo${thirdContactInfoStorageId}`;
  const fourthContactInfoId = `coverContactInfo${fourthContactInfoStorageId}`;

  // we can only have a max of two contact infos, so grab them if we have 'em
  const firstContactInfo = props.contactInfos
    ? props.contactInfos[firstContactInfoStorageId] || {}
    : undefined;
  const secondContactInfo = props.brokerInfos
    ? props.brokerInfos[secondContactInfoStorageId] || {}
    : undefined;
  const thirdContactInfo = props.brokerInfos
    ? props.brokerInfos[thirdContactInfoStorageId] || {}
    : undefined;
  const fourthContactInfo = props.brokerInfos
    ? props.brokerInfos[fourthContactInfoStorageId] || {}
    : undefined;

  const shouldHideBrokerPage = _.isEmpty(props.brokerInfos);

  useEffect(() => {
    setComponentReady(true);

    return () => setComponentReady(false);
  }, [props]);

  return (
    <div {..._getRootProps(shouldHideBrokerPage)} id="cover">
      <div className={cx(['contact-infos'])}>
        {firstContactInfoStorageId && (
          <div
            key={firstContactInfoStorageId}
            className={cx(['contact-info'])}
            id={firstContactInfoId}
          >
            {firstContactInfo.type !== 'skipped' ? (
              <ContactInfoContainer
                {..._getContactInfoProps({
                  contactInfo: firstContactInfo,
                  contactInfoId: firstContactInfoStorageId,
                })}
              />
            ) : undefined}
          </div>
        )}
        {secondContactInfoStorageId && (
          <div
            key={secondContactInfoStorageId}
            className={cx(['contact-info'])}
            id={secondContactInfoId}
          >
            {secondContactInfo.type !== 'skipped' ? (
              <ContactInfoContainer
                {..._getBrokerInfoProps({
                  contactInfo: secondContactInfo,
                  contactInfoId: secondContactInfoStorageId,
                })}
              />
            ) : undefined}
          </div>
        )}
        {thirdContactInfoStorageId && (
          <div
            key={thirdContactInfoStorageId}
            className={cx(['contact-info'])}
            id={thirdContactInfoId}
          >
            {thirdContactInfo.type !== 'skipped' ? (
              <ContactInfoContainer
                {..._getBrokerInfoProps({
                  contactInfo: thirdContactInfo,
                  contactInfoId: thirdContactInfoStorageId,
                })}
              />
            ) : undefined}
          </div>
        )}
        {fourthContactInfoStorageId && (
          <div
            key={fourthContactInfoStorageId}
            className={cx(['contact-info'])}
            id={fourthContactInfoId}
          >
            {fourthContactInfo.type !== 'skipped' ? (
              <ContactInfoContainer
                {..._getBrokerInfoProps({
                  contactInfo: fourthContactInfo,
                  contactInfoId: fourthContactInfoStorageId,
                })}
              />
            ) : undefined}
          </div>
        )}
      </div>
    </div>
  );
};

PdfBrokersTemplate.propTypes = {
  contactInfos: PropTypes.object,
  brokerInfos: PropTypes.object,
  isEditing: PropTypes.bool,
  readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
  options: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

module.exports = PdfBrokersTemplate;
