const _ = require('lodash');
const AddPdfPageInputsBuilder = require('./inputs_builders/add_pdf_page_inputs_builder');
const BaseTab = require('../base_tab');
const MainImagesInputsBuilder = require('./inputs_builders/main_images_inputs_builder');
const PdfEntryImagesTemplateContainer = require('../pdf_entry_images_template/container');
const PdfEntryTemplateContainer = require('../pdf_entry_template/container');
const PostInputSetAddButton = require('../post_input_set_add_button');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const TabViewContainer = require('../tab_view/container');

class EntryImagesView extends React.Component {
  static initClass() {
    this.propTypes = {
      entryHideImagesZeroState: PropTypes.bool,
      entryId: PropTypes.string.isRequired,
      entryImagePages: PropTypes.object,
      firebaseService: PropTypes.object.isRequired,
      getEntryDefaultStreetviewSettings: PropTypes.func.isRequired,
      getDigitalTourbookEntryImages: PropTypes.func.isRequired,
      setCurrentTab: PropTypes.func.isRequired,
      setCurrentView: PropTypes.func.isRequired,
      track: PropTypes.func.isRequired,
    };

    this.prototype.maxPages = 5;
  }

  constructor(props) {
    super(props);
    this._renderAddAnotherPage = this._renderAddAnotherPage.bind(this);
    this.state = {};
    if (_.size(props.entryImagePages) === 0) {
      this.state.newPageId = props.firebaseService.getUniqueId();
    }
  }

  componentWillMount() {
    this._initializeTab();
    this.props.getEntryDefaultStreetviewSettings(this.props.entryId);
    return this.props.getDigitalTourbookEntryImages(
      this.props.entryId,
      this.props.tourbookId
    );
  }

  componentWillReceiveProps(nextProps) {
    let needle;
    if (nextProps.entryId !== this.props.entryId) {
      this.setState({ newPageId: null });
      this._initializeTab();
    }
    if (
      ((needle = this.state.newPageId),
      Array.from(_.keys(nextProps.entryImagePages)).includes(needle))
    ) {
      return this.setState({ newPageId: null });
    }
  }

  render() {
    return (
      <TabViewContainer
        completeConfig={{
          label: 'Amenities',
          style: 'next',
        }}
        onComplete={() => {
          return this.props.setCurrentView({
            entryId: this.props.entryId,
            key: 'entry-amenities',
          });
        }}
        tabConfigs={this._getTabConfigs()}
      />
    );
  }

  _buildPdfPageTab({ displayAddAnotherPage, pageId }) {
    let postInputsFactory;
    const pageType = this._getPageType(pageId);
    const label = this._getPdfPageLabel(pageType);
    if (displayAddAnotherPage) {
      postInputsFactory = this._renderAddAnotherPage;
    }
    return {
      component: BaseTab,
      componentProps: {
        inputs: AddPdfPageInputsBuilder({
          entryId: this.props.entryId,
          pageId,
          pageType,
        }),
        pdfTemplateComponentFactory:
          this._buildPdfTemplateComponentFactory(pageId),
        postInputsFactory,
      },
      label,
      value: `pdf_page_${pageId}`,
    };
  }

  _buildPdfTemplateComponentFactory(pageId) {
    const componentProps = {
      entryId: this.props.entryId,
      isEditing: true,
      pageId,
      readyRegistry: new ReadyRegistry(),
    };
    const Component = pageId
      ? PdfEntryImagesTemplateContainer
      : PdfEntryTemplateContainer;
    return () => <Component {...componentProps} />;
  }

  _getPageType(pageId) {
    return this.props.entryImagePages?.[pageId]?.type;
  }

  _getPdfPageLabel(pageType) {
    switch (pageType) {
      case 'image':
        return 'Images Page';
      case 'floor_plan':
        return 'Floor Plan Page';
      case 'additional_note':
        return 'Additional Notes Page';
      default:
        return 'Add Image, Floorplan or Notes Page';
    }
  }

  _getTabConfigs() {
    const tabConfigs = [
      {
        component: BaseTab,
        componentProps: {
          inputs: MainImagesInputsBuilder({
            entryId: this.props.entryId,
            hideZeroState: this.props.entryHideImagesZeroState,
          }),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponentFactory(),
        },
        hideNext: !this.props.entryHideImagesZeroState,
        label: 'Listing Images',
        value: 'main_images',
      },
    ];
    const pageIds = _.keys(this.props.entryImagePages);
    if (this.state.newPageId) {
      pageIds.push(this.state.newPageId);
    }
    const numberOfPages = pageIds.length;
    pageIds.forEach((pageId, pageIndex) => {
      const displayAddAnotherPage = this._shouldDisplayAddAnotherPage({
        numberOfPages,
        pageId,
        pageIndex,
      });
      return tabConfigs.push(
        this._buildPdfPageTab({ displayAddAnotherPage, pageId })
      );
    });
    return tabConfigs;
  }

  _getInputs(builder) {
    const arg = _.pick(this.props, ['entry', 'entryId', 'fieldDefinitions']);
    arg.hasNotes = this.props.entry.fields.notes?.notesToggle;
    return builder(arg);
  }

  _initializeTab() {
    return this.props.setCurrentTab({ key: 'main_images' });
  }

  _renderAddAnotherPage() {
    const onClick = () => {
      const newPageId = this.props.firebaseService.getUniqueId();
      this.setState({ newPageId });
      this.props.setCurrentTab({ key: `pdf_page_${newPageId}` });
      return this.props.track('Adds Another Image Page', {
        entryId: this.props.entryId,
      });
    };
    return (
      <PostInputSetAddButton
        label="Add Another Image, Floor Plan or Notes Page"
        onClick={onClick}
      />
    );
  }

  _shouldDisplayAddAnotherPage({ numberOfPages, pageId, pageIndex }) {
    const isLastDefinedPage = pageIndex === numberOfPages - 1;
    const isLastPossiblePage = pageIndex === this.maxPages - 1;
    const pageType = this._getPageType(pageId);
    return (
      isLastDefinedPage &&
      ['image', 'floor_plan', 'additional_note'].includes(pageType) &&
      !isLastPossiblePage
    );
  }
}
EntryImagesView.initClass();

module.exports = EntryImagesView;
