const { OverlayTrigger, Popover, Button, Tooltip } = require('react-bootstrap');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const { useNavigate } = require('react-router-dom');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class UserNavigation extends React.Component {
  static initClass() {
    this.propTypes = {
      navigate: PropTypes.func.isRequired,
      user: PropTypes.object.isRequired,
    };
  }

  render() {
    return (
      <div className={cx(['root'])}>
        {this._renderLink({ className: cx(['logo']), label: 'Spaceful', path: '/library' })}
        <div className={cx(['controls'])}>
          <div className={cx(['control'])}>
            {this._renderLink({ label: 'Library', path: '/library' })}
          </div>
          <div className={cx(['control'])}>
            {this._renderLink({
              label: 'Help',
              path: 'mailto:spacefulsupport@nmrk.com',
            })}
          </div>
          <div className={cx(['control'])}>
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => {
                return this._renderUserOverlay(props);
              }}
              trigger="click"
            >
              <div className={cx(['menu-wrapper'])}>
                <FontAwesomeIcon name="bars" />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  }

  _renderLink({ className, label, onClick, path }) {
    if (path) {
      onClick = () => this.props.navigate(path);
    }
    return (
      <a className={cx([className])} onClick={onClick}>
        {label}
      </a>
    );
  }

  _renderUserOverlay(overlayProps) {
    const links = [{ label: 'Profile & Account', path: '/profile' }];
    links.push({ label: 'Logout', onClick: () => this.props.logout() });
    return (
      <Popover id={cx(['user-popover'])} {...overlayProps}>
        <ul
          className={cx(['user-menu'])}
        >
          {links.map((link, index) => {
            return <li key={index}>{this._renderLink(link)}</li>;
          })}
        </ul>
      </Popover>
    );
  }
}
UserNavigation.initClass();

module.exports = (props) => {
  const navigate = useNavigate();
  return <UserNavigation {...props} navigate={navigate} />
};
