const React = require('react');
const PropTypes = require('prop-types');

class FontAwesomeIcon extends React.Component {
  static initClass() {
    this.propTypes = {
      family: PropTypes.string,
      name: PropTypes.string.isRequired,
      size: PropTypes.string,
    };
  }

  iconClass = `fa-solid fa-${this.props.name}`;

  render() {
    if (this.props.family) {
      this.iconClass = `fa-${this.props.family} ${this.iconClass}`;
    }
    if (this.props.size) {
      this.iconClass += ` fa-${this.props.size}`;
    }
    return <i className={this.iconClass} />;
  }
}
FontAwesomeIcon.initClass();

module.exports = FontAwesomeIcon;
