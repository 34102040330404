const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const InputSet = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'reorderEntryFields',
  'setCurrentField',
]);

const mapStateToProps = (state) =>
  _.pick(state.tourbookEditorPage, ['currentField']);

module.exports = build({
  component: InputSet,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['keyboardShortcutService', 'scrollService'],
});
