const _ = require('lodash');
const { Promise } = require('bluebird');
const { locateServices } = require('./service_locator');

class AnalyticsService {
  static initClass() {
    this.prototype.identify = async function (user) {
      const properties = await this._getUserProperties(user);
      return this.window.analytics.identify(user.id, properties);
    };

    this.prototype.updateUserLastPublish = async function (
      user,
      brokerContactInfo
    ) {
      if (brokerContactInfo == null) {
        brokerContactInfo = {};
      }
      const publishedCount = await this.userTourbooksService.getPublishedCount(
        user.id
      );
      return this.window.analytics.identify(user.id, {
        LTP_Company: brokerContactInfo.company,
        LTP_Name: brokerContactInfo.name,
        LTP_Phone: brokerContactInfo.phone,
        published_tourbooks_count: publishedCount,
      });
    };

    this.prototype._getUserProperties = async function (user) {
      const profileProperties = this._getUserProfileProperties(user);
      return profileProperties
    };
  }

  constructor({ localStorage, window }) {
    this.localStorage = localStorage;
    this.window = window;
    this.sessionDict = {};
    this.sessions = [];
    this.totalTimeSpent = '--';
    this.totalViews = '--';
    this.uniqueViews = '--';
    this.photosUploaded = 0;
    this.ownerAndCollaborators = [];
    this.analyticsHasOwnerOrCollaborator = false;
    this.adminEmails = [];
    this.entries = {};
    this.loading = false;
    this.timeout;
    this.sentTo;
    this.eventQueue = [];

    ({
      userTourbooksService: this.userTourbooksService,
      firebaseService: this.firebaseService,
      tourbooksService: this.tourbooksService,
    } = locateServices([
      'userTourbooksService',
      'firebaseService',
      'tourbooksService',
    ]));
  }

  clearAnalytics() {
    __guardMethod__(this.window.analytics, 'user', (o) => o.user().logout());
    return this.window.mixpanel?.cookie?.clear();
  }

  sendEventQueue() {
    this.eventQueue.forEach(({ attributes, name }) => {
      return this.window.analytics.track(name, attributes);
    });
    return (this.eventQueue = []);
  }

  setAlias(id) {
    return this.window.analytics.setAlias(id);
  }

  track(name, attributes) {
    if (attributes == null) {
      attributes = {};
    }
    let userId = name.userId || attributes.userId;
    try {
      userId = this.window.analytics.user().id(); // TODO this doesn't seem to work no more
    } catch (e) {
      if (process.env.NODE_ENV === 'production') {
        console.error(e);
      }
    }
    if (userId) {
      this.window.analytics.track(name, attributes);
      if (!this.localStorage.impersonating) {
        return this._updateMixpanelLastSeen(userId);
      }
    } else {
      return this.eventQueue.push({ attributes, name });
    }
  }

  _getUserProfileProperties(user) {
    const { auth0Profile, firebaseProfile, id } = user;
    const auth0Properties = _.pick(auth0Profile, ['created_at', 'email']);
    const firebaseProperties = _.pick(firebaseProfile, [
      'company',
      'first_name',
      'last_name',
      'phone',
    ]);
    return _.assign(auth0Properties, firebaseProperties, {
      editor: 'v2',
      id,
      name: `${firebaseProfile?.first_name} ${firebaseProfile?.last_name}`,
    });
  }

  _updateMixpanelLastSeen(userId) {
    // This gives us a way to track when the user was last seen.
    // Mixpanel only updates last_seen property if a user property is set, not if an event is sent.
    return this.window.analytics.identify(userId, { last_seen_trigger: '' });
  }

  async _getAnalytics(tourbookID) {
    await this._attachFirebaseListeners(tourbookID);
    return new Promise((resolve) => {
      const timeout = setTimeout(async () => {
        if (!this.loading) {
          // TODO: Fix this. We don't have any `sentTo` entries in any of the analytics/<tourbookId> objects
          const snapshotValue = await this.firebaseService.getValue(`analytics/${tourbookID}`);
          this.sentTo = _.reverse(
            _.sortBy(snapshotValue?.sentTo, 'time')
          );
          clearTimeout(timeout);
          return resolve(this);
        }
      }, 250);
    });
  }

  _setDefaultAnalytics() {
    this.sessionDict = {};
    this.sessions = [];
    this.totalTimeSpent = '--';
    this.totalViews = '--';
    this.uniqueViews = '--';
    this.photosUploaded = 0;
    this.ownerAndCollaborators = [];
    this.analyticsHasOwnerOrCollaborator = false;
    this.adminEmails = [];
    this.entries = {};
    this.loading = false;
    this.timeout = null;
    this.sentTo = null;
  }

  async _attachFirebaseListeners(tourbookID) {
    this._setDefaultAnalytics();
    this.loading = true;
    const scope = this;

    
    const entrySnapshotVal = await this.firebaseService.getValue(`digitalTourbookEntryImages/${tourbookID}`);
    scope.photosUploaded = 
      _.chain(entrySnapshotVal)
        .toArray()
        .value().length;
    
    const originalSessionsVal = await this.firebaseService.getValue(`analytics/${tourbookID}/sessions`);
    let sessions = _.chain(originalSessionsVal).toArray().value();
    sessions = sessions
      .map((s) => {
        return { ...s, ticks: s.ticks || [] };
      })
      .map((s) => {
        return { ...s, ticks: _.chain(s.ticks).toArray().value() };
      })
      .map((s) => {
        return { firebaseSession: s, viewSession: {} };
      });
    this.hasSessions = sessions.length > 0;
    this._computeUser(sessions);
    sessions = this._removeAdminUsers(sessions);
    this._computeDate(sessions); // ticks
    this._computeDuration(sessions); // duration from frames
    this._computeListingsViewed(tourbookID, sessions);
    this._computeLocation(sessions);
    this._computeDevice(sessions);
    const summarySessions = this._removeOwnerAndCollaborators(sessions);
    this.totalTimeSpent = this._computeTotalTimeSpent(summarySessions);
    this.totalViews = this._computeTotalViews(summarySessions);
    this.uniqueViews = this._computeUniqueViews(summarySessions);
    this.sessions = _.orderBy(
      sessions.map((session) => ({
        ...session.viewSession,
        browserId: session.firebaseSession.browserID
      })),
      ['date', 'user'],
      ['desc']
    );
    this.loading = false;
  }

  _computeTotalTimeSpent(sessions) {
    let total = 0;
    sessions.map(({ firebaseSession, viewSession }) =>
      this._groupActivityWindows(firebaseSession.ticks).map(
        (activityWindow) =>
          (total += this._getActivityWindowDuration(activityWindow))
      )
    );

    if (total === 0) {
      return '--';
    }
    const seconds = this._addLeadingZeros(Math.floor((total / 1000) % 60));
    const minutes = this._addLeadingZeros(
      Math.floor((total / (60 * 1000)) % 60)
    );
    const hours = Math.floor(total / (60 * 60 * 1000));
    return `${hours}:${minutes}:${seconds}`;
  }

  _addLeadingZeros(n) {
    while (('' + n).length < 2) {
      n = '0' + n;
    }
    return n;
  }

  _formatDate(timestamp) {
    if (!timestamp) {
      return '-';
    }
    const date = new Date(timestamp);
    const month = this._addLeadingZeros(date.getMonth() + 1);
    const day = this._addLeadingZeros(date.getDate());
    const year = ('' + date.getFullYear()).slice(2, 4);
    const hours = this._addLeadingZeros(date.getHours());
    const minute = this._addLeadingZeros(date.getMinutes());

    return `${month}-${day}-${year}, ${hours}:${minute}`;
  }

  _computeDate(sessions) {
    sessions = sessions.map(({ firebaseSession, ...rest }) => {
      return {
        ticks: firebaseSession.ticks.sort(),
        firebaseSession,
        ...rest,
      };
    });
    sessions;
    return sessions.map(({ firebaseSession, viewSession }) => {
      viewSession.startTime = firebaseSession.ticks[0];
      viewSession.endTime = (firebaseSession.ticks?.length > 1
        ? firebaseSession.ticks[firebaseSession.ticks.length - 1]
        : viewSession.startTime)  + (60 * 1000); 

      return (viewSession.date = this._formatDate(firebaseSession.ticks[0]));
    });
  }

  _groupActivityWindows(ticks) {
    ticks.sort();
    const limit = (10 + 60) * 1000;
    const result = [];
    let last = ticks[0];
    let currentWindow = [];
    ticks.map(function (tick) {
      if (tick - last <= limit) {
        currentWindow.push(tick);
      } else {
        result.push(currentWindow);
        currentWindow = [tick];
      }
      return (last = tick);
    });
    result.push(currentWindow);
    return result;
  }

  _getActivityWindowDuration(activityWindow) {
    activityWindow.sort();
    const firstTick = activityWindow[0];
    const lastTick = activityWindow[activityWindow.length - 1];
    const oneMinute = 60 * 1000;
    if (firstTick === lastTick) {
      return oneMinute;
    } else {
      return lastTick - firstTick;
    }
  }

  _removeAdminUsers(sessions) {
    return sessions.filter(
      ({ viewSession }) =>
        (!viewSession.user.endsWith('@admin.com') &&
          !_.includes(this._adminEmails, viewSession.user)) ||
        _.includes(this.ownerAndCollaborators, viewSession.user)
    );
  }

  _computeUser(sessions) {
    let anonymousUserCount = 1;
    const anonymousUserMapping = {};
    return sessions.map(
      ({ firebaseSession, viewSession }) =>
        (viewSession.user = (() => {
          if (firebaseSession.hasOwnProperty('email')) {
            if (
              _.includes(
                this.ownerAndCollaborators,
                firebaseSession.email.toLowerCase()
              )
            ) {
              if (this.analyticsHasOwnerOrCollaborator !== true) {
                this.analyticsHasOwnerOrCollaborator = true;
              }
              viewSession.isOwnerOrCollaborator = true;
            }
            return firebaseSession.email.toLowerCase();
          } else {
            const browserID = firebaseSession.hasOwnProperty('browserID')
              ? firebaseSession.browserID
              : Math.floor(Math.random() * 10000000000);
            if (anonymousUserMapping.hasOwnProperty(browserID)) {
              return anonymousUserMapping[browserID];
            } else {
              const user = `Anonymous user ${anonymousUserCount}`;
              anonymousUserCount += 1;
              anonymousUserMapping[browserID] = user;
              return user;
            }
          }
        })())
    );
  }

  _computeTotalViews(sessions) {
    const totalViews = sessions.length;
    if (totalViews === 0) {
      return '--';
    } else {
      return totalViews;
    }
  }

  _computeUniqueViews(sessions) {
    const userSet = {};
    sessions.map(({ viewSession }) => (userSet[viewSession.user] = true));
    const uniqueViews = Object.keys(userSet).length;
    if (uniqueViews === 0) {
      return '--';
    } else {
      return uniqueViews;
    }
  }

  _computeDuration(sessions) {
    return sessions.map(
      function ({ firebaseSession, viewSession }) {
        let milliSeconds = 0;
        this._groupActivityWindows(firebaseSession.ticks).map(
          (activityWindow) =>
            (milliSeconds += this._getActivityWindowDuration(activityWindow))
        );
        const minutes = Math.max(1, Math.round(milliSeconds / (60 * 1000)));
        return (viewSession.duration = `${minutes} min${
          minutes === 1 ? '' : 's'
        }`);
      }.bind(this)
    );
  }

  _computeLocation(sessions) {
    return sessions.map(
      ({ firebaseSession, viewSession }) =>
        (viewSession.location =
          firebaseSession.location !== null &&
          firebaseSession.location !== undefined
            ? firebaseSession.location
            : '--')
    );
  }

  _computeListingsViewed(tourbookID, sessions) {
    return sessions.map(
      ({ firebaseSession, viewSession }) =>
        (viewSession.listingsViewed = _.chain(firebaseSession.listingsViewed)
          .keys()
          .map(
            async function (entryId) {
              let entry = await this.tourbooksService.getEntry(
                tourbookID,
                entryId
              );
              if (entry) {
                return entry.fields.header.CorrectedAddress1 || '(no address)';
              } else {
                return '(unknown)';
              }
            }.bind(this)
          )
          .sortBy()
          .value())
    );
  }

  _computeDevice(sessions) {
    const capitalize = (text) => text[0].toUpperCase() + text.slice(1);

    return sessions.map(
      ({ firebaseSession, viewSession }) =>
        (viewSession.device = firebaseSession.hasOwnProperty('device')
          ? capitalize(firebaseSession.device)
          : '--')
    );
  }

  _removeOwnerAndCollaborators(sessions) {
    return sessions.filter(
      ({ viewSession }) =>
        !_.includes(this.ownerAndCollaborators, viewSession.user)
    );
  }

  _addTick(sessionID, tick) {
    if (!this.sessionDict.hasOwnProperty(sessionID)) {
      this.sessionDict[sessionID] = {};
    }

    const session = this.sessionDict[sessionID];

    if (!session.hasOwnProperty('ticks')) {
      session.ticks = [];
    }
    return session.ticks.push(tick);
  }
}
AnalyticsService.initClass();

module.exports = AnalyticsService;

function __guardMethod__(obj, methodName, transform) {
  if (
    typeof obj !== 'undefined' &&
    obj !== null &&
    typeof obj[methodName] === 'function'
  ) {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
