module.exports = function ({ entryId, pageId, pageType }) {
  const imagesPath = ['content', 'entries', entryId, 'images'];
  let inputs = [
    {
      disableEnterNavigation: true,
      options: {
        nextField: [
          { id: imagesPath.join('-'), value: 'image' },
          { id: imagesPath.join('-'), value: 'floor_plan' },
          { id: imagesPath.join('-'), value: 'additional_note' },
          { id: 'next', value: 'skipped' },
        ],
      },
      path: ['content', 'entries', entryId, 'pages', 'images', pageId, 'type'],
      spacedQuestion: true,
      type: 'pdf_page_type',
    },
  ];
  if (['image', 'floor_plan'].includes(pageType)) {
    inputs.push({
      disableEnterNavigation: true,
      path: imagesPath,
      type: 'entry_images',
    });
  }
  if (pageType === 'image') {
    inputs.push({
      disableEnterNavigation: true,
      label: 'Layout',
      path: [
        'content',
        'entries',
        entryId,
        'pages',
        'images',
        pageId,
        'layout',
      ],
      type: 'extra_image_page_layout',
    });
  }

  if (pageType === 'additional_note') {
    inputs = [
      {
        options: {
          nextField: [
            {
              id: [
                'content',
                'entries',
                entryId,
                'pages',
                'images',
                pageId,
                'additionalNoteHeader',
              ].join('-'),
              value: true,
            },
            { id: 'next', value: false },
          ],
        },
        path: [
          'content',
          'entries',
          entryId,
          'pages',
          'images',
          pageId,
          'type',
        ],
        spacedQuestion: true,
        type: 'pdf_page_type',
      },
    ];
    inputs = inputs.concat([
      {
        label: 'Header',
        path: [
          'content',
          'entries',
          entryId,
          'pages',
          'images',
          pageId,
          'additionalNoteHeader',
        ],
      },
      {
        path: ['content', 'entries', entryId, 'pages', 'images', pageId],
        type: 'notes',
        useShiftEnterNavigation: true,
      },
    ]);
  }
  return inputs;
};
