const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const AdditionalFieldsInput = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'addAdditionalFields',
  'track',
  'trackManualAddField',
]);

const mapStateToProps = function (state, ownProps) {
  const props = _.pick(
    state.tourbookEditorPage.data.content.entries[ownProps.options.entryId],
    ['display', 'fields']
  );
  return _.assign(props, {
    fieldDefinitions: state.tourbookEditorPage.fieldDefinitions || {},
    previousFields: state.tourbookEditorPage.previousFields || {},
  });
};

const propTypes = {
  options: PropTypes.shape({
    entryId: PropTypes.string.isRequired,
  }).isRequired,
};

const serviceNames = [
  'scrollService',
  'tourbookFieldsService',
];

module.exports = build({
  component: AdditionalFieldsInput,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames,
});
