const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const DeleteMapModal = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'deleteEntry',
  'removeAdditionalField',
  'setCurrentView',
  'removeAdditionalBrokerPage',
]);

const mapStateToProps = function (state, ownProps) {
  const currentViewKey = state.tourbookEditorPage.currentView.key;
  const entries = state.tourbookEditorPage.data.content.entries;
  const entryId = ownProps.options.entryId;

  // If the very last entry is deleted, then jump away from the settings view as it can't display PDF preview
  const jumpToCoverView =
    (_.size(entries) === 1 && entryId in entries && currentViewKey === 'settings') ||
    ownProps.options.jumpToCoverView;

  return {
    jumpToCoverView,
  };
};

module.exports = build({
  component: DeleteMapModal,
  mapDispatchToProps,
  mapStateToProps,
});
