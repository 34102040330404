const { createAction } = require('redux-actions');
const { locateService } = require('@services/service_locator');
const promiseInspection = require('./promise_inspection_builder')(
  'libraryPage'
);

const deleteTourbookAction = createAction('libraryPage/deleteTourbook');
const leaveSharedTourbookAction = createAction(
  'libraryPage/leaveSharedTourbook'
);
const addTourbookAction = createAction('libraryPage/addTourbook');
const setOwnedTourbooksAction = createAction('libraryPage/setOwnedTourbooks');
const setSharedWithMeTourbooksAction = createAction(
  'libraryPage/setSharedWithMeTourbooks'
);
const setCurrentModalAction = createAction('libraryPage/setCurrentModal');
const setTabAction = createAction('libraryPage/setTab');

const actions = function () {
  let libraryPageActions;
  const tourbookMetaService = locateService('tourbookMetaService');
  const tourbooksService = locateService('tourbooksService');
  const userTourbooksService = locateService('userTourbooksService');
  const analyticsService = locateService('analyticsService');
  const tourbookAnalyticsService = locateService('tourbookAnalyticsService');
  const entriesByAddressService = locateService('entriesByAddressService');
  return (libraryPageActions = {
    createNewTourbook() {
      return promiseInspection('createNewTourbook', async function (dispatch, getState) {
        const user = getState().userSession;
        const { id, tourbook } = await tourbooksService.create(user);
        dispatch(
          libraryPageActions._track('Creates tour book', {
            tourbookId: id,
            tourbook,
          })
        );
        return { id, tourbook };
      });
    },

    attemptDeleteTourbook(tourbookId) {
      return (dispatch, getState) =>
        dispatch(
          setCurrentModalAction({
            currentModal: 'confirmation',
            currentModalProps: {
              message: 'Are you sure you want to delete this tour book?',
              confirmLabel: 'Delete',
              onConfirm() {
                return dispatch(libraryPageActions.deleteTourbook(tourbookId));
              },
            },
          })
        );
    },

    attemptLeaveSharedTourbook(tourbookId) {
      return (dispatch, getState) =>
        dispatch(
          setCurrentModalAction({
            currentModal: 'confirmation',
            currentModalProps: {
              message: 'Are you sure you want to leave this tour book?',
              confirmLabel: 'Leave',
              onConfirm() {
                return dispatch(libraryPageActions.leaveSharedTourbook(tourbookId));
              },
            },
          })
        );
    },

    deleteTourbook(tourbookId) {
      return promiseInspection('deleteTourbook', async function (dispatch, getState) {
        const tourbook = await tourbooksService.get(tourbookId);
        await entriesByAddressService.removeEntriesByAddress(tourbookId, tourbook);
        await tourbooksService.delete(tourbookId);
        dispatch(
          libraryPageActions._track('Deletes tour book', {
            tourbookId,
            tourbook,
          })
        );
        return await Promise.all([dispatch(deleteTourbookAction(tourbookId))]);
      });
    },

    duplicateTourbook(tourbookId) {
      return promiseInspection('duplicateTourbook', async function (dispatch, getState) {
        const user = getState().userSession;
        const { id: newTourbookId, tourbook: newTourbook } = await tourbooksService.copy(
          tourbookId,
          user
        );
        const tourbookMeta = await tourbookMetaService.build({
          tourbook: newTourbook,
          tourbookId: newTourbookId,
        });
        dispatch(
          libraryPageActions._track('Copies tour book', {
            tourbookId: newTourbookId,
            tourbook: newTourbook,
          })
        );
        const entries = newTourbook?.content?.entries;
        const entryPromises = [];
        _.forEach(
          entries,
          function (entry, entryId) {
            entryPromises.push(
              entriesByAddressService.addEntryByAddress(newTourbookId, newTourbook, entryId, entry)
            );
          }.bind(this)
        );
        await Promise.allSettled(entryPromises);
        return dispatch(addTourbookAction({ tourbookId: newTourbookId, tourbookMeta }));
      });
    },

    initialize() {
      return promiseInspection('initialize', async function (dispatch, getState) {
        analyticsService.track('Views tour book library', {
          category: 'library',
        });
        const user = getState().userSession;
        const ownedTourbooks = await userTourbooksService.getAllMeta(user.id);
        dispatch(setOwnedTourbooksAction(ownedTourbooks));
        const teardown = await userTourbooksService.subscribeToAllSharedMeta(
          user.id,
          (sharedTourbooks) => dispatch(setSharedWithMeTourbooksAction(sharedTourbooks))
        );
        return teardown;
      });
    },

    leaveSharedTourbook(tourbookId) {
      return promiseInspection('leaveSharedTourbook', async function (dispatch, getState) {
        const user = getState().userSession;
        await tourbooksService.leaveSharedTourbook({
          tourbookId,
          userId: user.id,
        });
        dispatch(leaveSharedTourbookAction(tourbookId));
        return await dispatch(
          libraryPageActions._track('Leaves a Shared Tour Book', {
            tourbookId,
          })
        );
      });
    },

    setCurrentModal: setCurrentModalAction,

    setTab(payload) {
      if (payload === 'shared') {
        analyticsService.track('Opens "Shared With Me" Tab', {
          category: 'library',
        });
      } else if (payload === 'owned') {
        analyticsService.track('Opens "My Tour Books" Tab', {
          category: 'library',
        });
      }
      return setTabAction(payload);
    },

    _track(event, options) {
      return async function (dispatch, getState) {
        const newOptions = {
          tourbookId: options.tourbookId,
          category: 'library',
        };
        if (options.targetUser != null) {
          newOptions.targetUser = options.targetUser;
        } else {
          newOptions.targetUser = getState().userSession.id;
        }
        if (options.tourbook != null) {
          newOptions.tourbook = options.tourbook;
        }
        if (options.additionalProperties != null) {
          newOptions.additionalProperties = options.additionalProperties;
        }
        return await tourbookAnalyticsService.trackTourbookEvent(event, newOptions);
      };
    },
  });
};

module.exports = actions;
