const _ = require('lodash');
const { default: Cropper } = require('react-cropper');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const { default: Slider } = require('rc-slider');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class ImageCropper extends React.Component {
  static initClass() {
    this.propTypes = {
      desiredHeight: PropTypes.number,
      desiredWidth: PropTypes.number,
      image: PropTypes.object.isRequired,
      imageUrlService: PropTypes.object.isRequired,
    };

    this.prototype.autoCropArea = 0.8;
    this.prototype.numberOfSliderSteps = 100;
    this.prototype.zoomRatio = 0.05;
  }

  constructor(props) {
    super(props);
    this._initializeSliderValue = this._initializeSliderValue.bind(this);
    this.state = { loading: true };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sliderValue && !prevState.sliderValue) {
      return;
    }
    return this.cropper.zoomTo(this._getZoom());
  }

  getData() {
    const { rotate, width: croppedWidth, x, y } = this.cropper.getData();
    const { naturalHeight, naturalWidth } = this.cropper.getImageData();
    const imageScale = this.props.desiredWidth / croppedWidth;
    const rotation = (rotate + 360) % 360;
    const imageWidth = [0, 180].includes(rotation)
      ? naturalWidth
      : naturalHeight;
    return {
      offsetX: -1 * x * imageScale,
      offsetY: -1 * y * imageScale,
      rotation,
      size: imageWidth * imageScale,
    };
  }

  render() {
    return (
      <div className={cx(['cropper-wrapper'])}>
        {this.state.loading && (
          <div className={cx(['loading'])}>
            <FontAwesomeIcon name="spinner fa-spin-pulse" size="3x" />
          </div>
        )}
        <Cropper
          aspectRatio={this.props.desiredWidth / this.props.desiredHeight}
          background={false}
          cropBoxMovable={false}
          cropBoxResizable={false}
          dragMode="move"
          ready={this._onCropperReady()}
          ref={(node) => {
            return (this.cropper = node);
          }}
          src={this._getUnrotatedImageUrl()}
          toggleDragModeOnDblclick={false}
          zoomOnWheel={true}
          className={cx(['cropper'])} />
        <div className={cx(['actions'])}>
          <div className={cx(['zoom'])}>
            <div
              onClick={() =>
                this.setState({ sliderValue: this.state.sliderValue - 1 })}
              className={cx(['minus'])}>
              <FontAwesomeIcon name="minus" />
            </div>
            <div className={cx(['slider'])}>
              <Slider
                max={this.numberOfSliderSteps}
                min={0}
                onChange={(value) => this.setState({ sliderValue: value })}
                value={this.state.sliderValue} />
            </div>
            <div
              onClick={() =>
                this.setState({ sliderValue: this.state.sliderValue + 1 })}
              className={cx(['plus'])}>
              <FontAwesomeIcon name="plus" />
            </div>
          </div>
          <div onClick={() => this.cropper.rotate(-90)} className={cx(['rotate'])}>
            <FontAwesomeIcon name="rotate" />
          </div>
        </div>
      </div>
    );
  }

  _convertZoomToSliderValue(zoom) {
    const change = Math.round(Math.log(zoom) / Math.log(1 + this.zoomRatio));
    const result = this.numberOfSliderSteps / 2 + change;
    return Math.min(this.numberOfSliderSteps, Math.max(0, result));
  }

  _getUnrotatedImageUrl() {
    const unrotatedImage = _.omit(this.props.image, ['rotation']);
    return this.props.imageUrlService.getStaticUrlForImage(unrotatedImage, {});
  }

  _getZoom() {
    const change = this.state.sliderValue - this.numberOfSliderSteps / 2;
    return Math.pow(1 + this.zoomRatio, change);
  }

  _initializeCanvasLocation(cropper) {
    const { offsetX, offsetY, rotation, size } = this.props.image;
    const { desiredWidth } = this.props;
    const cropBoxData = cropper.getCropBoxData();
    if (_.every([offsetX, offsetY, size], _.isNumber)) {
      cropper.rotateTo(rotation);
      const imageScale = desiredWidth / cropBoxData.width;
      return cropper.setCanvasData({
        left: cropBoxData.left + offsetX / imageScale,
        top: cropBoxData.top + offsetY / imageScale,
        width: size / imageScale,
      });
    } else {
      const imageData = cropper.getImageData();
      const scale = Math.max(
        cropBoxData.width / imageData.naturalWidth,
        cropBoxData.height / imageData.naturalHeight
      );
      const newWidth = imageData.naturalWidth * scale;
      const newHeight = imageData.naturalHeight * scale;
      return cropper.setCanvasData({
        height: newHeight,
        left: cropBoxData.left - (newWidth - cropBoxData.width) / 2,
        top: cropBoxData.top - (newHeight - cropBoxData.height) / 2,
        width: newWidth,
      });
    }
  }

  _initializeCropBoxLocation(cropper) {
    const { desiredHeight, desiredWidth } = this.props;
    const containerData = cropper.getContainerData();
    const cropBoxPercentages = [
      desiredWidth / containerData.width,
      desiredHeight / containerData.height,
    ];
    const [newWidth, newHeight] = Array.from(
      (() => {
        if (_.every(cropBoxPercentages, (x) => x < this.autoCropArea)) {
          return [desiredWidth, desiredHeight];
        } else {
          const scale =
            Math.max.apply(null, cropBoxPercentages) / this.autoCropArea;
          return [desiredWidth, desiredHeight].map((x) => x / scale);
        }
      })()
    );
    return cropper.setCropBoxData({
      height: newHeight,
      left: (containerData.width - newWidth) / 2,
      top: (containerData.height - newHeight) / 2,
      width: newWidth,
    });
  }

  _initializeCropper(cropper) {
    this._initializeCropBoxLocation(cropper);
    this._initializeCanvasLocation(cropper);
    return this._initializeSliderValue(cropper);
  }

  _initializeSliderValue(cropper) {
    const imageData = cropper.getImageData();
    const ratio = imageData.width / imageData.naturalWidth;
    return this.setState({
      sliderValue: this._convertZoomToSliderValue(ratio),
      loading: false,
    });
  }

  _onCropperReady() {
    if (this.cachedReadyFn) {
      return this.cachedReadyFn;
    }
    const context = this;
    return (this.cachedReadyFn = function () {
      return context._initializeCropper(this.cropper);
    });
  }
}
ImageCropper.initClass();

module.exports = ImageCropper;
