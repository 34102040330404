exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 18 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/main_images_layout_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/main_images_layout_input/index.styl */\n.main_images_layout_input---wrapper---3JPFZxdk0nI6fIMArKlZwN {\n  height: 38px;\n  margin-left: 5px;\n}\n\n/* line 10 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/main_images_layout_input/index.styl */\n\n/* line 11 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/main_images_layout_input/index.styl */\n.main_images_layout_input---wrapper---3JPFZxdk0nI6fIMArKlZwN.main_images_layout_input---checked---e6sCZRWMKP3rAYFz7RmfI svg {\n  fill: #006bb6;\n}\n\n/* line 14 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/main_images_layout_input/index.styl */\n.main_images_layout_input---wrapper---3JPFZxdk0nI6fIMArKlZwN svg {\n  fill: #e5e5e5;\n  height: 38px;\n  width: 47px;\n  cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "main_images_layout_input---wrapper---3JPFZxdk0nI6fIMArKlZwN",
	"checked": "main_images_layout_input---checked---e6sCZRWMKP3rAYFz7RmfI"
};