/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const GoogleAddressService = require('./google_address_service');

describe('GoogleAddressService', function () {
  beforeEach(function () {
    this.window = {};
    return (this.service = new GoogleAddressService({ window: this.window }));
  });

  describe('getProperty', function () {
    beforeEach(function () {
      return (this.googleAddress = {
        address_components: [
          {
            short_name: 'streetnumber1',
            types: ['street_number'],
          },
          {
            short_name: 'route1',
            types: ['route'],
          },
          {
            short_name: 'premise1',
            types: ['premise'],
          },
          {
            long_name: 'St. city1',
            types: ['locality'],
          },
          {
            short_name: 'state1',
            types: ['administrative_area_level_1'],
          },
          {
            short_name: 'zip1',
            types: ['postal_code'],
          },
        ],
        geometry: {
          location: {
            lat: 'lat1',
            lng: 'lng1',
          },
        },
      });
    });

    describe('with streetnumber and route', function () {
      beforeEach(function () {
        return (this.property = this.service.getProperty(this.googleAddress));
      });

      return it('uses the streetnumber and route in the property provided', function () {
        return expect(this.property).to.eql({
          CorrectedAddress1: 'streetnumber1 route1',
          address: 'streetnumber1 route1',
          city: 'Saint city1',
          latitude: 'lat1',
          longitude: 'lng1',
          state: 'state1',
          zip: 'zip1',
        });
      });
    });

    describe('without a streetnumber and route', function () {
      beforeEach(function () {
        this.googleAddress.address_components.splice(0, 2);
        return (this.property = this.service.getProperty(this.googleAddress));
      });

      return it('uses the streetnumber and route in the property provided', function () {
        return expect(this.property).to.eql({
          CorrectedAddress1: 'premise1',
          address: 'premise1',
          city: 'Saint city1',
          latitude: 'lat1',
          longitude: 'lng1',
          state: 'state1',
          zip: 'zip1',
        });
      });
    });

    return describe('without a locality', () =>
      it('uses political when locality is missing', function () {
        this.googleAddress.address_components[3].types = ['political'];
        this.property = this.service.getProperty(this.googleAddress);
        return expect(this.property).to.eql({
          CorrectedAddress1: 'streetnumber1 route1',
          address: 'streetnumber1 route1',
          city: 'Saint city1',
          latitude: 'lat1',
          longitude: 'lng1',
          state: 'state1',
          zip: 'zip1',
        });
      }));
  });

  describe('geocode', function () {
    beforeEach(function () {
      this.service.geocoder = { geocode: sinon.stub() };
      return (this.window.google = { maps: { GeocoderStatus: { OK: 'ok' } } });
    });

    describe('on success', function () {
      beforeEach(
        async function() {
          this.service.geocoder.geocode.callsArgWith(
            1,
            [{ some: 'result data' }],
            'ok'
          );
          return this.result = await this.service.geocode('123 My Street');
        }
      );

      return it('returns the first result', function () {
        return expect(this.result).to.eql({ some: 'result data' });
      });
    });

    return describe('on error', function () {
      beforeEach(
        async function() {
          this.service.geocoder.geocode.callsArgWith(
            1,
            [{ some: 'result data' }],
            'not ok'
          );
          try {
            return await this.service.geocode('123 My Street');
          } catch (e) {
            return (this.error = e);
          }
        }
      );

      return it('errors', function () {
        return expect(this.error).to.eql('Geocode unsuccessful: not ok');
      });
    });
  });

  return describe('getMainStreetviewLocation', function () {
    beforeEach(function () {
      this.service.directionsService = { route: sinon.stub() };
      return (this.window.google = {
        maps: {
          DirectionsStatus: { OK: 'ok' },
          DirectionsTravelMode: { DRIVING: 'driving' },
        },
      });
    });

    describe('on success', function () {
      beforeEach(
        async function() {
          const response = {
            routes: [
              {
                legs: [{ start_location: { some: 'location data' } }],
              },
            ],
          };
          this.service.directionsService.route.callsArgWith(1, response, 'ok');
          return this.result = await this.service.getMainStreetviewLocation(
            '123 My Street'
          );
        }
      );

      return it('returns the first result', function () {
        return expect(this.result).to.eql({ some: 'location data' });
      });
    });

    return describe('on error', function () {
      beforeEach(
        async function() {
          this.service.directionsService.route.callsArgWith(1, null, 'not ok');
          try {
            return await this.service.getMainStreetviewLocation(
              '123 My Street'
            );
          } catch (e) {
            return (this.error = e);
          }
        }
      );

      return it('errors', function () {
        return expect(this.error).to.eql('Directions unsuccessful: not ok');
      });
    });
  });
});
