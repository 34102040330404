const _ = require('lodash');
const { locateService, locateServices } = require('./service_locator');

class SummaryService {
  isLegacySummaryFields(summaryFields) {
    return summaryFields && summaryFields[0] && summaryFields[0].fieldPath;
  }
  portLegacySummaryFields(summaryFields) {
    return this.isLegacySummaryFields(summaryFields) ?
      _.chain(summaryFields)
        .map((lsf) => {
          return {
            order: lsf.order,
            key: lsf.fieldPath[lsf.fieldPath.length - 1],
          };
        })
        .value() : summaryFields;
  }
  getOrderedSummaryFields(summaryFields) {
    if (this.isLegacySummaryFields(summaryFields)) {
      summaryFields = this.portLegacySummaryFields(summaryFields);
    }

    return _.chain(summaryFields || [])
      .toArray()
      .value()
      .sort((a, b) => {
        return a.order - b.order;
      })
      .map((ele, i) => {
        return {
          ...ele,
          order: i,
        };
      });
  }
  fieldToValue(summaryField, section, entry) {
    let configuredValue = entry?.fields?.[section]?.[summaryField.key]?.value;
    const value = configuredValue
      ? entry.fields[section][summaryField.key].value
      : undefined;
    return value;
  }
  /**
   * Converts section types to their physical storage key
   * @param {string} sectionType one of: ['address', 'property', entry.type]
   * @returns a value at which the entry.fields[sectionType] can be safely accessed
   */
  typeToSectionKey(sectionType) {
    sectionType = (sectionType || '').toLowerCase();
    switch (sectionType) {
      case 'lease':
        return 'space';
      case 'sublease':
        return 'subLease';
      case 'sale':
        return 'terms';
      default:
        return sectionType || 'subLease';
    }
  }
  isSectionField(section, fieldKey, entry) {
    return !!entry?.fields?.[section]?.[fieldKey];
  }

  getFieldValueUnitAndSectionType(entry, field, fieldDefinition) {
    const tourbookFieldsService = locateService('tourbookFieldsService');
    const isPropertyField = this.isSectionField('property', field.key, entry);
    const isAddressField = this.isSectionField('address', field.key, entry);
    let sectionType = this.typeToSectionKey(entry['type']);

    if (isPropertyField) {
      sectionType = 'property';
    }
    if (isAddressField) {
      sectionType = 'address';
    }
    const value = this.fieldToValue(field, sectionType, entry);

    const fieldValue = tourbookFieldsService.getFieldValueAsString({
      fieldDefinition,
      value,
    });

    const unit = fieldValue
      ? entry.fields[sectionType][field.key]?.unit ||
        fieldDefinition?.units?.[0] ||
        ''
      : null;
    return { fieldValue, unit, sectionType };
  }
  filterToSummaryEntries(entries) {
    const excludeFromSummaryKeys = ['tourbook-notes'];
    const filteredEntries = _.keyBy(
      _.chain(entries || [])
        .map((entry, entryId) => {
          return { ...entry, entryId };
        })
        .filter((x) => !excludeFromSummaryKeys.includes(x.key))
        .value(),
      'entryId'
    );
    return filteredEntries;
  }
  getFieldsPopulatedByBroker(filteredEntries, fieldDefinitions) {
    const { tourbookEntryFieldsService, tourbookFieldsService } =
      locateServices(['tourbookEntryFieldsService', 'tourbookFieldsService']);

    let fieldsPopulatedByBroker = _.flatten(
      Object.keys(filteredEntries).map(function (entryId) {
        const entry = filteredEntries[entryId];
        const sections = ['property', 'address', entry['type']];
        const allFields = _.chain(sections)
          .map((section) => {
            return tourbookEntryFieldsService.getFieldsToDisplay({
              entry,
              section: this.typeToSectionKey(section),
            });
          })
          .flatten()
          .value();
        return allFields;
      }, this)
    );
    // just the ones that have been populated by the broker with a value
    fieldsPopulatedByBroker = fieldsPopulatedByBroker.filter(
      (field) => field.value !== undefined && field.value !== null
    );
    fieldsPopulatedByBroker = fieldsPopulatedByBroker.map((field) => {
      return {
        key: field.key,
        type: field.type,
        label: field.label,
      };
    });
    fieldsPopulatedByBroker = _.uniqWith(fieldsPopulatedByBroker, _.isEqual);
    fieldsPopulatedByBroker = fieldsPopulatedByBroker.map((field) => {
      return {
        ...field,
        label:
          field.label ||
          tourbookFieldsService.getFieldLabel(field, fieldDefinitions),
      };
    });
    return fieldsPopulatedByBroker;
  }
  filterToPopulatedSummaryFields(summaryFields, tourbook, fieldDefinitions) {
    const entries = tourbook?.content?.entries || [];
    const filteredEntries = this.filterToSummaryEntries(entries);
    const fieldsPopulatedByBroker = this.getFieldsPopulatedByBroker(
      filteredEntries,
      fieldDefinitions
    );
    return summaryFields.filter(
      (sf) => fieldsPopulatedByBroker.findIndex((v) => v.key === sf.key) !== -1
    );
  }
}

module.exports = SummaryService;
