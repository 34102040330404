module.exports = function ({ entryId, hideZeroState }) {
  let inputs;
  if (hideZeroState) {
    inputs = [
      {
        disableEnterNavigation: true,
        path: ['content', 'entries', entryId, 'images'],
        type: 'entry_images',
      },
      {
        disableEnterNavigation: true,
        label: 'Layout',
        path: ['content', 'entries', entryId, 'layout'],
        type: 'main_images_layout',
      },
    ];
  } else {
    inputs = [
      {
        disableEnterNavigation: true,
        hideZeroStatePath: [
          'content',
          'entries',
          entryId,
          'hideImagesZeroState',
        ],
        path: ['content', 'entries', entryId, 'images'],
        type: 'entry_images_zero_state',
      },
    ];
  }
  return inputs;
};
