const PropTypes = require('prop-types');
const React = require('react');
const InputRowContainer = require('@components/shared/input_row/container');

function PrivateNotesView(props) {
  props.track('Loads private notes');

  return (
    <InputRowContainer
      active={true}
      shouldAddColonToLabel={true}
      shouldDisplayHint={false}
      titleOverride={'Private Notes'}
      hideLock={true}
      hideHint={true}
      hideBullets={true}
      input={{
        path: ['content', 'private_notes', 'contents'],
        type: 'notes',
      }}
    />
  );
}

PrivateNotesView.propTypes = {
  track: PropTypes.func.isRequired,
};

export default PrivateNotesView;
