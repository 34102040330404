exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 8 : /opt/build/repo/react-app/editor_frontend/src/components/login/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/components/login/index.styl */\n.login---box-shadow---2PvdOqWEh8k_Crz-yG4S4l {\n  box-shadow: 0 0 7px 1px #000;\n}\n\n/* line 4 : /opt/build/repo/react-app/editor_frontend/src/components/login/index.styl */\n.login---logo---33SYHwr7diEbUD7CNQYmW6 {\n  color: #fff;\n  font-size: 28px;\n  font-weight: 600;\n  font-family: 'spaceful', Times, serif;\n}\n", ""]);

// exports
exports.locals = {
	"box-shadow": "login---box-shadow---2PvdOqWEh8k_Crz-yG4S4l",
	"logo": "login---logo---33SYHwr7diEbUD7CNQYmW6"
};