exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 17 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/map_view/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/map_view/index.styl */\n.map_view---root---Q8N6EPk2Oqi5OPUd-Gnmr {\n  height: 100%;\n}\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/map_view/index.styl */\n.map_view---divider---opwUBt8T-2OEEDwo7Qg1H {\n  background-color: #e5e5e5;\n  height: 1px;\n  margin: 0 auto 15px;\n  width: 35px;\n}\n\n/* line 12 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/map_view/index.styl */\n.map_view---hint---DtdsROIN41LITk2vta9iG {\n  color: #999;\n  font-size: 16px;\n  margin: 0 auto 40px;\n  text-align: center;\n  width: 280px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "map_view---root---Q8N6EPk2Oqi5OPUd-Gnmr",
	"divider": "map_view---divider---opwUBt8T-2OEEDwo7Qg1H",
	"hint": "map_view---hint---DtdsROIN41LITk2vta9iG"
};