const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const { default: PrivateNotesView } = require('.');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, ['track']);

module.exports = build({
  component: PrivateNotesView,
  mapDispatchToProps,
});
