const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const InputRow = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, ['setCurrentModal']);

const mapStateToProps = (state) =>
  _.pick(state.tourbookEditorPage, ['currentField']);

module.exports = build({
  component: InputRow,
  mapDispatchToProps,
  mapStateToProps,
});
