/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const WalkscoreService = require('./walkscore');

const stopsResponse = {
  data: [
    {
      distance: 0.00524724951221212,
      route_summary: [{ category: 'Bus', name: '720 Metro Rapid Line' }],
    },
    {
      distance: 0.0061926777829662515,
      route_summary: [{ category: 'Bus', name: '14/37 Metro Local Line' }],
    },
    {
      distance: 0.030297892978083642,
      route_summary: [{ category: 'Bus', name: '20 Metro Local Line' }],
    },
    {
      distance: 0.3486931345728543,
      route_summary: [{ category: 'Bus', name: '16/316 Metro Local Line' }],
    },
    {
      distance: 0.43360357750628425,
      route_summary: [{ category: 'Bus', name: '4 Metro Local Line' }],
    },
    {
      distance: 0.4482952866370404,
      route_summary: [
        { category: 'Bus', name: '4 Metro Local Line' },
        { category: 'Bus', name: '704 Metro Rapid Line' },
      ],
    },
    {
      distance: 0.49126518426580307,
      route_summary: [
        { category: 'Bus', name: '28 Metro Local Line' },
        { category: 'Bus', name: '728 Metro Rapid Line' },
      ],
    },
    {
      distance: 0.8617587436812453,
      route_summary: [{ category: 'Bus', name: '220 Metro Local Line' }],
    },
  ],
  status: 200,
};

const transitScoreResponse = {
  data: {
    description: 'Good Transit',
    transit_score: 66,
  },
  status: 200,
};

const walkscoreResponse = {
  data: {
    description: "Walker's Paradise",
    status: 1,
    walkscore: 97,
  },
  status: 200,
};

describe('WalkscoreService', function () {
  beforeEach(function () {
    this.axios = sinon.stub();
    return (this.walkscoreService = new WalkscoreService({
      ENV: { firebaseFunctionsConfig: { url: 'baseUrl' } },
      axios: this.axios,
    }));
  });

  return describe('get', function () {
    beforeEach(function () {
      return (this.data = {
        address: '123 My Street',
        city: 'My City',
        latitude: 1.2,
        longitude: 3.4,
        state: 'California',
        zip: '12345',
      });
    });

    return describe('data returned', function () {
      beforeEach(
        async function() {
          this.axios
            .onCall(0)
            .resolves({ data: stopsResponse })
            .onCall(1)
            .resolves({ data: transitScoreResponse })
            .onCall(2)
            .resolves({ data: walkscoreResponse });
          return this.result = await this.walkscoreService.get(this.data);
        }
      );

      it('makes requests to the proper url', function () {
        expect(this.axios).to.have.been.calledThrice;
        expect(this.axios).to.have.been.calledWith(
          'baseUrl/walkscore?action=stops' +
            '&lat=1.2&lon=3.4' +
            '&format=json&webtask_no_cache=1'
        );
        expect(this.axios).to.have.been.calledWith(
          'baseUrl/walkscore?action=transit-score' +
            '&city=My%20City&lat=1.2&lon=3.4&state=CA' +
            '&format=json&webtask_no_cache=1'
        );
        return expect(this.axios).to.have.been.calledWith(
          'baseUrl/walkscore?action=score' +
            '&address=123%20My%20Street%20My%20City%2C%20California%2012345&lat=1.2&lon=3.4' +
            '&format=json&webtask_no_cache=1'
        );
      });

      return it('returns the data', function () {
        return expect(this.result).to.eql({
          nearbyTransportation: [
            { category: 'Bus', distance: '0.0', name: '720' },
            { category: 'Bus', distance: '0.0', name: '14/37' },
            { category: 'Bus', distance: '0.0', name: '20' },
            { category: 'Bus', distance: '0.3', name: '16/316' },
            { category: 'Bus', distance: '0.4', name: '4' },
            { category: 'Bus', distance: '0.4', name: '704' },
            { category: 'Bus', distance: '0.5', name: '28' },
            { category: 'Bus', distance: '0.5', name: '728' },
            { category: 'Bus', distance: '0.9', name: '220' },
          ],
          transitscore: 66,
          transitscoreText: 'Good Transit',
          walkscore: 97,
          walkscoreText: "Walker's Paradise",
        });
      });
    });
  });
});
