/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const DocraptorHtmlService = require('./docraptor_html_service');

describe('DocraptorHtmlService', function () {
  beforeEach(function () {
    this.addClass = sinon.stub().returnsThis();
    this.$ = (input) => ({
      0: { outerHTML: input },
      addClass: this.addClass,
    });
    this.document = {
      styleSheets: [
        { cssRules: [{ cssText: 'css1' }, { cssText: 'css2' }] },
        { cssRules: [{ cssText: 'css3' }, { cssText: 'css4' }] },
      ],
    };
    return (this.docraptorHtmlService = new DocraptorHtmlService({
      $: this.$,
      document: this.document,
    }));
  });

  return describe('transform', function () {
    it('adds script tags to import the fonts', function () {
      const result = this.docraptorHtmlService.transform('tourbook html');
      return expect(result).to.include(`\
<script src='https://use.typekit.net/qer2cbg.js'></script>
<script> try{Typekit.load({async: true});}catch(e){} </script>\
`);
    });

    it('adds css from Chrome', function () {
      const result = this.docraptorHtmlService.transform('tourbook html');
      return expect(result).to.include(`\
<style>
  body { display: block; height: auto; width: auto; position: relative; }
  @page { size: US-Letter landscape; }
  @page { margin: 0px; }
  css1css2css3css4
</style>\
`);
    });

    it('adds css from IE', function () {
      this.document.styleSheets = [
        { cssText: 'css1' },
        { cssText: 'css2' },
        { cssText: 'css3' },
        { cssText: 'css4' },
      ];
      const result = this.docraptorHtmlService.transform('tourbook html');
      return expect(result).to.include(`\
<style>
  body { display: block; height: auto; width: auto; position: relative; }
  @page { size: US-Letter landscape; }
  @page { margin: 0px; }
  css1css2css3css4
</style>\
`);
    });

    it("normalizes IE's -ms-text-overflow", function () {
      this.document.styleSheets[0].cssRules[0].cssText = `\
.selector {
  -ms-text-overflow: ellipsis;
}\
`;
      const result = this.docraptorHtmlService.transform('tourbook html');
      expect(result).to.not.include('-ms-text-overflow: ellipsis;');
      return expect(result).to.include('text-overflow: ellipsis;');
    });

    it("normalizes Safari's background-repeat", function () {
      this.document.styleSheets[0].cssRules[0].cssText = `\
.selector {
  background-repeat: no-repeat no-repeat;
}\
`;
      const result = this.docraptorHtmlService.transform('tourbook html');
      expect(result).to.not.include('background-repeat: no-repeat no-repeat;');
      return expect(result).to.include('background-repeat: no-repeat;');
    });

    it('adds the docraptor class to the passed in html', function () {
      const result = this.docraptorHtmlService.transform('tourbook html');
      expect(this.addClass).to.have.been.calledWith('docraptor');
      return expect(result).to.include('tourbook html');
    });

    it('normalizes single quotes', function () {
      const result = this.docraptorHtmlService.transform(
        'tourbook html with ’'
      );
      return expect(result).to.include("tourbook html with '");
    });

    it('normalizes dashes', function () {
      const result = this.docraptorHtmlService.transform(
        'tourbook html with —'
      );
      return expect(result).to.include('tourbook html with -');
    });

    return it('removes 0 length spaces', function () {
      const result = this.docraptorHtmlService.transform(
        'tourbook html with ­'
      );
      return expect(result).to.include('tourbook html with ');
    });
  });
});
