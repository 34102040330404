const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');

class LoadingAnimation extends React.Component {
  render() {
    return (
      <div className={cx(['root', 'loading', this.props.inline ? 'inline' : ''])}>
        <div className={cx(['circles'])}>
          <div className={cx(['circle-1'])} />
          <div className={cx(['circle-2'])} />
          <div className={cx(['circle-3'])} />
        </div>
      </div>
    );
  }
}

module.exports = LoadingAnimation;
