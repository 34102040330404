const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PdfPreviewModal = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentModal',
]);

module.exports = build({
  component: PdfPreviewModal,
  mapDispatchToProps,
});
