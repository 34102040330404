const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');

class NearbyPlaces extends React.Component {
  static initClass() {
    this.propTypes = {
      entryId: PropTypes.string,
      photos: PropTypes.array,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
      wide: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.areImagesLoaded = this.props.photos.reduce((o, key) => ({ ...o, [key]: false }), {}); // url as key & boolean flag as value
  }

  componentWillMount() {
    if (this.props.entryId) {
      this.setComponentReady = this.props.readyRegistry.register(`NearbyPlaces-${this.props.entryId}`);
    }
  }

  componentDidMount() {
    if (this.setComponentReady) {
      let intervalId;
      intervalId = setInterval(() => {
        if (_.every(Object.values(this.areImagesLoaded))) {
          // If all images loaded, then the component is ready
          this.setComponentReady(true);
          if (intervalId) {
            clearInterval(intervalId);
          }
        } else {
          // If not all images are loaded, then check on those that weren't ready earlier
          for (const [url, isImageLoaded] of Object.entries(this.areImagesLoaded)) {
            if (!isImageLoaded) {
              this.areImagesLoaded[url] = !!document.getElementById(url)?.style?.backgroundImage;
            }
          }
        }
      }, 200);
    }
  }

  componentWillUnmount() {
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  render() {
    return (
      <div className={cx([`root`, `${this.props.wide ? 'wide' : ''}`])}>
        <div {...this._getImageProps(this.props.photos[0], null, 'large-image')} />
        <div className={cx(['small-images'])}>
          {this.props.photos.slice(1).map((photo, index) => {
            return <div {...this._getImageProps(photo, index, 'small-image')} />;
          })}
        </div>
      </div>
    );
  }

  _getImageProps(photo, index, className) {
    return {
      key: index,
      className: cx([className]),
      id: photo,
      style: {
        backgroundImage: `url(${photo})`,
      },
    };
  }
}
NearbyPlaces.initClass();

module.exports = NearbyPlaces;
