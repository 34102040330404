/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require('lodash');
const TourbookMetaService = require('./tourbook_meta_service');
const { buildMockService } = require('@test/mock_service_helpers');
const { setService } = require('./service_locator');

describe('TourbookMetaService', function () {
  beforeEach(function () {
    this.firebaseService = buildMockService('firebaseService');
    setService('firebaseService', this.firebaseService);

    return (this.tourbookMetaService = new TourbookMetaService());
  });

  describe('build', function () {
    const baseInput = {
      owner_email: 'user@example.com',
      owner_id: 'user1',
    };

    const baseOutput = {
      coverPhotoID: null,
      date: null,
      entries: {},
      name: 'Untitled Book',
      ownerEmail: 'user@example.com',
      ownerID: 'user1',
      shared: false,
    };

    const examples = [
      {
        description: 'no data',
        input: {},
        output: {},
      },
      {
        description: 'with title, location1 and location2',
        input: {
          content: {
            cover: {
              fields: {
                area: 'location2',
                location: 'location1',
                title: 'title',
              },
            },
          },
        },
        output: {
          name: 'title',
        },
      },
      {
        description: 'with no title, location1 and location2',
        input: {
          content: {
            cover: {
              fields: {
                area: 'location2',
                location: 'location1',
              },
            },
          },
        },
        output: {
          name: 'location1',
        },
      },
      {
        description: 'with no title, no location1 and location2',
        input: {
          content: {
            cover: {
              fields: {
                area: 'location2',
              },
            },
          },
        },
        output: {
          name: 'location2',
        },
      },
      {
        description: 'with date',
        input: {
          content: { cover: { fields: { date: 'October 27, 2016' } } },
        },
        output: {
          date: 'October 27, 2016',
        },
      },
      {
        description: 'with cover photo id',
        input: {
          content: {
            cover: {
              imagesShown: {
                coverPhoto: { cloudinaryPublicId: 'my-photo-id' },
              },
            },
          },
        },
        output: {
          coverPhotoID: 'my-photo-id',
        },
      },
      {
        description:
          'with entry without smallestSpaceAvailable or floor or type',
        input: {
          content: {
            entries: {
              entry1: {
                fields: {
                  header: {
                    CorrectedAddress1: '123 My Street',
                    city: 'My City',
                    state: 'My State',
                  },
                },
              },
            },
          },
        },
        output: {
          entries: {
            entry1: {
              address: '123 my street my city my state',
              availableSpace: null,
              floor: null,
              leaseOrSale: null,
            },
          },
        },
      },
      {
        description:
          'with lease entry with smallestSpaceAvailable and floor and type',
        input: {
          content: {
            entries: {
              entry1: {
                fields: {
                  header: {
                    CorrectedAddress1: '123 My Street',
                    city: 'My City',
                    floor: 2,
                    state: 'My State',
                  },
                  space: {
                    smallestAvailableSpace: { value: { min: 1000 } },
                  },
                },
                type: 'Lease',
              },
            },
          },
        },
        output: {
          entries: {
            entry1: {
              address: '123 my street my city my state',
              availableSpace: 1000,
              floor: 2,
              leaseOrSale: 'Lease',
            },
          },
        },
      },
      {
        description: 'with sale entry with availableSize',
        input: {
          content: {
            entries: {
              entry1: {
                fields: { terms: { availableSize: { value: { min: 1000 } } } },
                type: 'Sale',
              },
            },
          },
        },
        output: {
          entries: {
            entry1: {
              address: null,
              availableSpace: 1000,
              floor: null,
              leaseOrSale: 'Sale',
            },
          },
        },
      },
      {
        description: 'shared with user',
        input: {
          shared: {
            user2: {
              email: 'user2@example.com',
              picture: 'user-photo-id',
            },
          },
        },
        output: {
          shared: true,
        },
      },
      {
        description: 'shared with invited user',
        output: {
          shared: true,
        },
        shareTokens: {
          token1: 'user2@example.com',
        },
      },
    ];

    return examples.forEach(({ description, input, output, shareTokens }) =>
      it(
        description,
        async function() {
          this.firebaseService.getValue
            .withArgs('shareTokens/tourbook1')
            .resolves(shareTokens || null);
          const fullInput = _.merge({}, baseInput, input);
          const fullOutput = _.merge({}, baseOutput, output);
          const result = await this.tourbookMetaService.build({
            tourbook: fullInput,
            tourbookId: 'tourbook1',
          });
          return expect(result).to.eql(fullOutput);
        }
      )
    );
  });

  return describe('getMetaUpdates', function () {
    const makeDefaultTourbook = () => ({
      content: {
        cover: {
          fields: {
            area: 'area1',
            date: 'date1',
            extraneousField: 'extraneous',
            location: 'location1',
            title: 'title1',
          },
          imagesShown: { coverPhoto: { cloudinaryPublicId: 'image1' } },
        },
        entries: {
          entry1: {
            fields: {
              terms: {
                availableSize: { value: { min: 1000 } },
              },
              header: {
                city: 'city1',
                floor: '1',
                CorrectedAddress1: 'address1',
                state: 'state1',
              },
              space: {
                smallestAvailableSpace: { value: { min: 1000 } },
              },
            },
            type: 'Lease',
          },
        },
      },
    });

    return [
      {
        description: 'handles an irrelevant update correctly',
        path: ['content', 'cover', 'fields', 'extraneousField'],
        result: [],
        tourbook: makeDefaultTourbook(),
      },
      {
        description:
          'handles a name update correctly with title, location, and area',
        path: ['content', 'cover', 'fields', 'title'],
        result: [{ path: ['name'], value: 'title1' }],
        tourbook: makeDefaultTourbook(),
      },
      {
        description:
          'handles a name update correctly with no title, location, and area',
        path: ['content', 'cover', 'fields', 'location'],
        result: [{ path: ['name'], value: 'location1' }],
        tourbook: (function () {
          const tourbook = makeDefaultTourbook();
          delete tourbook.content.cover.fields.title;
          return tourbook;
        })(),
      },
      {
        description:
          'handles a name update correctly with no title, no location, and area',
        path: ['content', 'cover', 'fields', 'area'],
        result: [{ path: ['name'], value: 'area1' }],
        tourbook: (function () {
          const tourbook = makeDefaultTourbook();
          delete tourbook.content.cover.fields.title;
          delete tourbook.content.cover.fields.location;
          return tourbook;
        })(),
      },
      {
        description:
          'handles a name update correctly with no title, no location, and no area',
        path: ['content', 'cover', 'fields', 'title'],
        result: [{ path: ['name'], value: 'Untitled Book' }],
        tourbook: (function () {
          const tourbook = makeDefaultTourbook();
          delete tourbook.content.cover.fields.title;
          delete tourbook.content.cover.fields.location;
          delete tourbook.content.cover.fields.area;
          return tourbook;
        })(),
      },
      {
        description: 'handles a standard field correctly',
        path: ['content', 'cover', 'fields', 'date'],
        result: [{ path: ['date'], value: 'date1' }],
        tourbook: makeDefaultTourbook(),
      },
      {
        description: 'handles an entry field correctly',
        path: ['content', 'entries', 'entry1', 'fields', 'header', 'floor'],
        result: [{ path: ['entries', 'entry1', 'floor'], value: '1' }],
        tourbook: makeDefaultTourbook(),
      },
      {
        description: 'handles the entry address correctly',
        path: [
          'content',
          'entries',
          'entry1',
          'fields',
          'header',
          'CorrectedAddress1',
        ],
        result: [
          {
            path: ['entries', 'entry1', 'address'],
            value: 'address1 city1 state1',
          },
        ],
        tourbook: makeDefaultTourbook(),
      },
      {
        description: 'handles the cover image correctly',
        path: ['content', 'cover', 'imagesShown', 'coverPhoto'],
        result: [{ path: ['coverPhotoID'], value: 'image1' }],
        tourbook: makeDefaultTourbook(),
      },
      {
        description: 'handles the size field correctly with a Lease entry',
        // coffeelint: disable_line=max-line-length
        path: [
          'content',
          'entries',
          'entry1',
          'fields',
          'space',
          'smallestAvailableSpace',
          'value',
          'min',
        ],
        result: [
          { path: ['entries', 'entry1', 'availableSpace'], value: 1000 },
        ],
        tourbook: makeDefaultTourbook(),
      },
      {
        description: 'handles the size field correctly with a Sale entry',
        path: [
          'content',
          'entries',
          'entry1',
          'fields',
          'terms',
          'availableSize',
          'value',
          'min',
        ],
        result: [
          { path: ['entries', 'entry1', 'availableSpace'], value: 1000 },
        ],
        tourbook: (function () {
          const tourbook = makeDefaultTourbook();
          _.set(tourbook, ['content', 'entries', 'entry1', 'type'], 'Sale');
          return tourbook;
        })(),
      },
    ].forEach(({ description, path, result, tourbook, value }) =>
      it(description, function () {
        const actualResult = this.tourbookMetaService.getMetaUpdates(
          path,
          tourbook
        );
        return expect(actualResult).to.eql(result);
      })
    );
  });
});
