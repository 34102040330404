const CollapsibleQuestion = require('@components/shared/collapsible_question');
const PropTypes = require('prop-types');
const React = require('react');

class ContactInfoTypeInput extends React.Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
  }

  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      createContactInfo: PropTypes.func.isRequired,
      navigateForward: PropTypes.func.isRequired,
      options: PropTypes.shape({
        contactInfoId: PropTypes.string,
        isNew: PropTypes.bool,
      }),
      updateContactInfoType: PropTypes.func.isRequired,
      value: PropTypes.string,
    };
  }

  render() {
    const contactInfos = this.props.contactInfos || {};
    const contactInfoIds = _.keys(contactInfos);
    const contactInfoId = this.props.options.contactInfoId;
    const isFirstContact = _.findIndex(
      contactInfoIds,
      (id) => id === contactInfoId
    );
    const brokerLabel =
      !contactInfoIds.length || !isFirstContact
        ? 'Primary Broker Info'
        : 'Broker Info';
    return (
      <CollapsibleQuestion
        active={this.props.active}
        answers={[
          { label: brokerLabel, value: 'broker' },
          { label: 'Client Info', value: 'client' },
          { label: 'No, Skip', value: 'skipped' },
        ]}
        onChange={this._onChange}
        question="What kind of contact info would you like to add?"
        value={this.props.value}
      />
    );
  }

  _onChange(type) {
    if (this.props.options.isNew) {
      this.props.createContactInfo(this.props.options.contactInfoId, type);
    } else {
      this.props.updateContactInfoType(this.props.options.contactInfoId, type);
    }
    return this.props.navigateForward(type);
  }
}
ContactInfoTypeInput.initClass();

module.exports = ContactInfoTypeInput;
