const _ = require('lodash');
const titleCase = require('title-case');
const {
  fieldKeyToFieldDefinitionKey,
} = require('@components/pages/tourbook_editor_page/multi_space_util');

const buildFieldPath = ({ entryId, fieldKey, section }) => [
  'content',
  'entries',
  entryId,
  'fields',
  section,
  fieldKey,
];

const buildFieldValuePath = (args) => buildFieldPath(args).concat(['value']);

const buildFieldUnitPath = (args) => buildFieldPath(args).concat(['unit']);

const buildFieldInput = function (args) {
  let internalNavigation;
  let { entryId, fieldDefinitions, fieldKey, label, options, section, type } =
    args;
  const fieldDefinitionKey = fieldKeyToFieldDefinitionKey(fieldKey);
  const fieldDefinition = fieldDefinitions?.[fieldDefinitionKey];
  if (_.isObject(fieldDefinition)) {
    if (!label) {
      ({ label } = fieldDefinition);
    }
    if (!type) {
      ({ type } = fieldDefinition);
    }
    options = _.assign({ fieldDefinition }, options);
    if (fieldDefinition.units?.length > 1) {
      options.unitPath = buildFieldUnitPath({ entryId, fieldKey, section });
    }
  }
  if (type === 'range') {
    internalNavigation = 2;
  }
  if (!label) {
    label = titleCase(fieldKey);
  }
  return _.omitBy(
    {
      internalNavigation,
      label,
      options,
      path: buildFieldValuePath({ entryId, fieldKey, section }),
      type,
    },
    _.isUndefined
  );
};

const buildSortableFieldInputs = ({
  entry,
  entryId,
  fieldDefinitions,
  section,
}) =>
  _.chain(entry.fields[section])
    .map((field, fieldKey) => _.assign({ fieldKey }, field))
    .filter((field) => field.order != null)
    .sortBy('order')
    .map(({ fieldKey, label, isAdditionalField }) =>
      buildFieldInput({
        entryId,
        fieldDefinitions,
        fieldKey,
        label,
        options: { section, sortable: true, isAdditionalField },
        section,
      })
    )
    .value();

const getAddFieldsInput = (entryId, section) => ({
  disableEnterNavigation: true,
  options: { entryId, section },
  path: ['additionalFields'],
  type: 'additional_fields',
});

const getTypeInput = (entryId) => ({
  disableEnterNavigation: true,

  options: {
    nextField: [
      {
        id: buildFieldPath({
          entryId,
          fieldKey: 'subLeaseHeader',
          section: 'header',
        }).join('-'),
        value: 'subLease',
      },
      {
        id: buildFieldPath({
          entryId,
          fieldKey: 'spaceHeader',
          section: 'header',
        }).join('-'),
        value: 'Lease',
      },
      {
        id: buildFieldPath({
          entryId,
          fieldKey: 'termsHeader',
          section: 'header',
        }).join('-'),
        value: 'Sale',
      },
    ],
  },

  path: ['content', 'entries', entryId, 'type'],
  spacedQuestion: true,
  type: 'entry_type',
});

module.exports = {
  buildFieldInput,
  buildFieldPath,
  buildFieldValuePath,
  buildSortableFieldInputs,
  getAddFieldsInput,
  getTypeInput,
};
