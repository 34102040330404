const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const { default: BrokerView } = require('.');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentModal',
  'createBrokerInfo',
  'setCurrentTab',
  'track',
]);

const mapStateToProps = function (state) {
  const { currentTab, data } = state.tourbookEditorPage;
  return {
    contactInfos: _.get(data, ['content', 'cover', 'contactInfos']),
    brokerInfos: _.get(data, ['content', 'broker', 'brokerInfos']),
    currentTab,
  };
};

module.exports = build({
  component: BrokerView,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['firebaseService', 'tourbookFields'],
});
