const { build } = require('@components/container_helpers');
const LikesCountComponent = require('.');

const mapStateToProps = (state) => ({
  tourbookId: state.tourbookEditorPage.tourbookId
});

const serviceNames = [
  'tourbooksService'
];

module.exports = build({
  component: LikesCountComponent,
  mapStateToProps,
  serviceNames,
});

