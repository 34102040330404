/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Auth0Service = require('./auth0_service');
const { stubServices } = require('./service_locator');

describe('Auth0Service', function () {
  beforeEach(function () {
    this.auth0 = {
      getDelegationToken: sinon.stub(),
      getUserInfo: sinon.stub(),
      login: sinon.stub().callsArgWith(1, null, {
        accessToken: 'auth0AccessToken',
        idToken: 'auth0IdToken',
      }),
    };
    this.auth0.getDelegationToken
      .withArgs({ api: 'firebase', id_token: 'auth0IdToken' })
      .callsArgWith(1, null, { id_token: 'firebaseToken' });
    this.auth0.getUserInfo
      .withArgs('auth0AccessToken')
      .callsArgWith(1, null, { auth0: 'data', user_id: '123' });
    this.localStorage = {
      data: {},
      getItem(key) {
        return this.data[key];
      },
      removeItem(key) {
        return delete this.data[key];
      },
      setItem(key, value) {
        return (this.data[key] = value);
      },
    };

    ({
      analyticsService: this.analyticsService,
      firebaseService: this.firebaseService,
      organizationService: this.organizationService,
      sharingService: this.sharingService,
      tourbooksService: this.tourbooksService,
      usersService: this.usersService,
    } = stubServices([
      'analyticsService',
      'firebaseService',
      'organizationService',
      'sharingService',
      'tourbooksService',
      'usersService',
    ]));

    this.analyticsService.identify.resolves();
    this.firebaseService.authenticate.resolves('firebaseUser');
    this.organizationService.get.returns({ some: 'organization data' });
    this.sharingService.handleSharing.resolves();
    this.tourbooksService.copySample.resolves();
    this.usersService.getSubscription.withArgs('123').resolves('subscription');
    this.usersService.isAdmin.withArgs('123').resolves(false);
    this.usersService.isDisabled.withArgs('123').resolves(false);

    return (this.auth0Service = new Auth0Service({
      auth0: this.auth0,
      localStorage: this.localStorage,
    }));
  });

  describe('initialize', function () {
    beforeEach(function () {
      return this.usersService.getProfile
        .withArgs('123')
        .resolves('firebaseProfile');
    });

    describe('with angular tokens', function () {
      beforeEach(function () {
        return (this.localStorage.data = {
          'auth0-accessToken': '"auth0AccessToken"',
          'auth0-firebaseToken': '"firebaseToken"',
          'auth0-idToken': '"auth0IdToken"',
        });
      });

      return it(
        'returns the user',
        async function () {
          const result = await this.auth0Service.initialize();
          return expect(result).to.eql({
            auth0AccessToken: 'auth0AccessToken',
            auth0IdToken: 'auth0IdToken',
            auth0Profile: { auth0: 'data', user_id: '123' },
            firebaseProfile: 'firebaseProfile',
            firebaseToken: 'firebaseToken',
            firebaseUser: 'firebaseUser',
            id: '123',
            isAdmin: false,
            isDisabled: false,
            organization: { some: 'organization data' },
            subscription: 'subscription',
          });
        }
      );
    });

    describe('with react tokens', function () {
      beforeEach(function () {
        return (this.localStorage.data = {
          auth0AccessToken: 'auth0AccessToken',
          auth0IdToken: 'auth0IdToken',
          firebaseToken: 'firebaseToken',
        });
      });

      return it(
        'returns the user',
        async function () {
          const result = await this.auth0Service.initialize();
          return expect(result).to.eql({
            auth0AccessToken: 'auth0AccessToken',
            auth0IdToken: 'auth0IdToken',
            auth0Profile: { auth0: 'data', user_id: '123' },
            firebaseProfile: 'firebaseProfile',
            firebaseToken: 'firebaseToken',
            firebaseUser: 'firebaseUser',
            id: '123',
            isAdmin: false,
            isDisabled: false,
            organization: { some: 'organization data' },
            subscription: 'subscription',
          });
        }
      );
    });

    return describe('without tokens', () =>
      it(
        'returns null',
        async function () {
          const result = await this.auth0Service.initialize();
          return expect(result).to.be.null;
        }
      ));
  });

  describe('login', function () {
    beforeEach(
      async function () {
        this.usersService.getProfile
          .withArgs('123')
          .resolves('firebaseProfile');
        return this.result = await this.auth0Service.login({
          connection: 'standard',
          email: 'email1',
          password: 'pass1',
        });
      }
    );

    it('calls auth0.login with the correct options', function () {
      return expect(this.auth0.login).to.have.been.calledWith({
        connection: 'Username-Password-Authentication',
        email: 'email1',
        password: 'pass1',
        responseType: 'token',
      });
    });

    it('calls firebaseService.authenticate', function () {
      return expect(this.firebaseService.authenticate).to.have.been.calledWith(
        'firebaseToken'
      );
    });

    it('sets the token and profile in localStorage', function () {
      return expect(this.localStorage.data).to.eql({
        auth0AccessToken: 'auth0AccessToken',
        auth0IdToken: 'auth0IdToken',
        firebaseToken: 'firebaseToken',
      });
    });

    it('calls analyticsService.identify with the user', function () {
      return expect(this.analyticsService.identify).to.have.been.calledWith({
        auth0AccessToken: 'auth0AccessToken',
        auth0IdToken: 'auth0IdToken',
        auth0Profile: { auth0: 'data', user_id: '123' },
        firebaseProfile: 'firebaseProfile',
        firebaseToken: 'firebaseToken',
        firebaseUser: 'firebaseUser',
        id: '123',
        isAdmin: false,
        isDisabled: false,
        organization: { some: 'organization data' },
        subscription: 'subscription',
      });
    });

    it('calls analyticsService.sendEventQueue', function () {
      return expect(this.analyticsService.sendEventQueue).to.have.been.called;
    });

    it('calls analyticsService.track for log in', function () {
      return expect(this.analyticsService.track).to.have.been.calledWith(
        'Log in'
      );
    });

    return it('returns the user', function () {
      return expect(this.result).to.eql({
        auth0AccessToken: 'auth0AccessToken',
        auth0IdToken: 'auth0IdToken',
        auth0Profile: { auth0: 'data', user_id: '123' },
        firebaseProfile: 'firebaseProfile',
        firebaseToken: 'firebaseToken',
        firebaseUser: 'firebaseUser',
        id: '123',
        isAdmin: false,
        isDisabled: false,
        organization: { some: 'organization data' },
        subscription: 'subscription',
      });
    });
  });

  describe('signup', function () {
    beforeEach(
      async function () {
        this.usersService.createProfile
          .withArgs('123', { auth0: 'data', user_id: '123' })
          .resolves('firebaseProfile');
        return this.result = await this.auth0Service.signup({
          connection: 'standard',
          email: 'email1',
          password: 'pass1',
        });
      }
    );

    it('calls auth0.login with the correct options', function () {
      return expect(this.auth0.login).to.have.been.calledWith({
        connection: 'Username-Password-Authentication',
        email: 'email1',
        password: 'pass1',
        responseType: 'token',
      });
    });

    it('calls firebaseService.authenticate', function () {
      return expect(this.firebaseService.authenticate).to.have.been.calledWith(
        'firebaseToken'
      );
    });

    it('calls analyticsService.setAlias with the user id (before analyticsService.identify)', function () {
      expect(this.analyticsService.setAlias).to.have.been.calledWith('123');
      return expect(this.analyticsService.setAlias).to.have.been.calledBefore(
        this.analyticsService.identify
      );
    });

    it('calls tourbooksService.copySampleTourbook (before analyticsService.identify)', function () {
      expect(this.tourbooksService.copySample).to.have.been.called;
      return expect(this.tourbooksService.copySample).to.have.been.calledBefore(
        this.analyticsService.identify
      );
    });

    it('sets the token and profile in localStorage', function () {
      return expect(this.localStorage.data).to.eql({
        auth0AccessToken: 'auth0AccessToken',
        auth0IdToken: 'auth0IdToken',
        firebaseToken: 'firebaseToken',
      });
    });

    it('calls analyticsService.identify with the user', function () {
      return expect(this.analyticsService.identify).to.have.been.calledWith({
        auth0AccessToken: 'auth0AccessToken',
        auth0IdToken: 'auth0IdToken',
        auth0Profile: { auth0: 'data', user_id: '123' },
        firebaseProfile: 'firebaseProfile',
        firebaseToken: 'firebaseToken',
        firebaseUser: 'firebaseUser',
        id: '123',
        isAdmin: false,
        isDisabled: false,
        organization: { some: 'organization data' },
        subscription: 'subscription',
      });
    });

    it('calls analyticsService.sendEventQueue', function () {
      return expect(this.analyticsService.sendEventQueue).to.have.been.called;
    });

    it('calls sharingService.handleSharing', function () {
      return expect(this.sharingService.handleSharing).to.have.been.called;
    });

    it('calls analyticsService.track for Signed up', function () {
      return expect(this.analyticsService.track).to.have.been.calledWith(
        'Signed up'
      );
    });

    return it('returns the user', function () {
      return expect(this.result).to.eql({
        auth0AccessToken: 'auth0AccessToken',
        auth0IdToken: 'auth0IdToken',
        auth0Profile: { auth0: 'data', user_id: '123' },
        firebaseProfile: 'firebaseProfile',
        firebaseToken: 'firebaseToken',
        firebaseUser: 'firebaseUser',
        id: '123',
        isAdmin: false,
        isDisabled: false,
        organization: { some: 'organization data' },
        subscription: 'subscription',
      });
    });
  });

  return describe('logout', function () {
    beforeEach(function () {
      this.localStorage.data = {
        auth0AccessToken: 'auth0AccessToken',
        auth0IdToken: 'auth0IdToken',
        firebaseToken: 'firebaseToken',
        'auth0-accessToken': '"auth0AccessToken"',
        'auth0-firebaseToken': '"firebaseToken"',
        'auth0-idToken': '"auth0IdToken"',
      };
      return this.auth0Service.logout();
    });

    it('clears local storage of react and angular tokens', function () {
      return expect(this.localStorage.data).to.eql({});
    });

    it('calls analyticsService.track with Log out', function () {
      return expect(this.analyticsService.track).to.have.been.calledWith(
        'Log out'
      );
    });

    return it('calls analyticsService.clearAnalytics', function () {
      return expect(this.analyticsService.clearAnalytics).to.have.been.called;
    });
  });
});
