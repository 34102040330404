const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const LogoInput = require('./');

const mapDispatchToProps = (dispatch, ownProps) => ({
  setCurrentModal(modal) {
    return dispatch(actions.tourbookEditorPage.setCurrentModal(modal));
  },

  updateToggle(value) {
    return dispatch(
      actions.tourbookEditorPage.update({ path: ownProps.togglePath, value })
    );
  },

  updateSize(value) {
    return dispatch(
      actions.tourbookEditorPage.update({ path: ownProps.widthPath, value })
    );
  },
});

const mapStateToProps = (state, ownProps) => ({
  toggleValue: _.get(state.tourbookEditorPage.data, ownProps.togglePath),
  widthInVw: _.get(state.tourbookEditorPage.data, ownProps.widthPath),
});

module.exports = build({
  component: LogoInput,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['imageUrlService'],
});
