const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class ConfirmActionBar extends React.Component {
  static initClass() {
    this.propTypes = {
      cancel: PropTypes.func.isRequired,
      confirm: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    };
  }

  render() {
    return (
      <div>
        <div className={cx(['placeholder'])} />
        <div className={cx(['wrapper'])}>
          <a onClick={this.props.confirm} className={cx(['confirm'])}>
            {this.props.label}
          </a>
          <a onClick={this.props.cancel} className={cx(['cancel'])}>
            Cancel
          </a>
        </div>
      </div>
    );
  }
}
ConfirmActionBar.initClass();

module.exports = ConfirmActionBar;
