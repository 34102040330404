"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.N360QueryService = void 0;
const _ = require('lodash');
const { locateServices } = require('@services/service_locator');
const DATA_SOURCE_TYPES = require('@enums/data_source_types');
const { ParentQueryService, Query, NewEntryData, Entry } = require('./parent_query_service');
class N360QueryService extends ParentQueryService {
    constructor() {
        super();
        this.source = DATA_SOURCE_TYPES.N360;
        ({
            n360Service: this.n360Service,
            tourbookEntryBuilderService: this.tourbookEntryBuilderService,
            tourbookEntryFields: this.tourbookEntryFields,
            tourbookEntryFieldsService: this.tourbookEntryFieldsService,
        } = locateServices([
            'n360Service',
            'tourbookEntryBuilderService',
            'tourbookEntryFields',
            'tourbookEntryFieldsService',
        ]));
    }
    standardizeQuery(rawQuery) {
        const query = new Query();
        query.streetAddress = rawQuery._source.streetAddress;
        query.city = rawQuery._source.city;
        query.state = rawQuery._source.state;
        query.zip = rawQuery._source.zip;
        query.id = rawQuery._id;
        query.originalQuery = rawQuery;
        return query;
    }
    getProperty(query) {
        const _super = Object.create(null, {
            getProperty: { get: () => super.getProperty }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield _super.getProperty.call(this, query);
            try {
                data.property = yield this.n360Service.propertyDetailFetch(query.id);
                data.spaces = yield this.n360Service.spaceSearch({
                    propertyId: data.property.propertyId,
                });
            }
            catch (error) {
                data.error = true;
            }
            if (!data.spaces || (data.spaces.noResponse && !data.error)) {
                data.spaces = [];
            }
            data.property.media = yield this.n360Service.getPropertyMedia(data.property);
            data.property = this.tourbookEntryFieldsService.etlTransformations(data.property);
            return data;
        });
    }
    doesNeedMoreDataFromUserBeforeCreatingEntry(query, newEntryData) {
        const _super = Object.create(null, {
            doesNeedMoreDataFromUserBeforeCreatingEntry: { get: () => super.doesNeedMoreDataFromUserBeforeCreatingEntry }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const parentResult = yield _super.doesNeedMoreDataFromUserBeforeCreatingEntry.call(this, query, newEntryData);
            return parentResult || newEntryData.spaces.length > 1;
        });
    }
    getEntry(newEntryData, additionalInfo) {
        const _super = Object.create(null, {
            getEntry: { get: () => super.getEntry }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const entry = yield _super.getEntry.call(this, newEntryData, additionalInfo);
            const property = newEntryData.property;
            const space = (additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.space) || newEntryData.spaces[0];
            // Intermediate values
            const n360Images = yield this.tourbookEntryBuilderService.getN360Images({ property, space });
            const { imagesShown, images, pages } = this.tourbookEntryBuilderService.getImageUpdates({
                n360Images,
            });
            const fields = this.tourbookEntryFieldsService.getFields({ property, space });
            entry.fields = fields;
            entry.images = images;
            entry.imagesShown = imagesShown;
            entry.meta = this.tourbookEntryBuilderService.getEntryMeta({ fields, property, space });
            entry.n360Data = this.tourbookEntryFields.buildEntryN360Data({ property, space });
            entry.n360Images = n360Images;
            entry.pages.images = (pages === null || pages === void 0 ? void 0 : pages.images) || {};
            if (space) {
                entry.type = space.leaseOrSale || property.leaseOrSale || null;
            }
            return entry;
        });
    }
}
exports.N360QueryService = N360QueryService;
