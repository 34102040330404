const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const Switch = require('@components/shared/switch');

class ToggleInput extends React.Component {
  static initClass() {
    this.propTypes = {
      options: PropTypes.shape({
        isHidden: PropTypes.bool,
      }),
      update: PropTypes.func.isRequired,
      value: PropTypes.bool,
    };
  }

  render() {
    if (this.props.options?.isHidden) {
      return (
        <div className={cx(['message'])}>
          {this.props.options.messageIfHidden}
        </div>
      );
    } else {
      return (
        <Switch
          onChange={(newValue) => this.props.update(newValue)}
          value={
            this.props.value === undefined
              ? this.props.defaultEnable
              : this.props.value
          }
        />
      );
    }
  }
}
ToggleInput.initClass();

module.exports = ToggleInput;
