exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 35 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/color_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/z-indices.styl */\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/color_input/index.styl */\n.color_input---root---25UwCqc2hbYG0ONceOFkoi {\n  width: 100%;\n}\n\n/* line 9 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/color_input/index.styl */\n.color_input---current---3OCgINiwzEk9ZneUPAD3Jz {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n}\n\n/* line 14 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/color_input/index.styl */\n.color_input---text---ucgzVAlWl_nJ2bQqSb5jA {\n  margin-right: 10px;\n  text-align: right;\n  width: calc(100% - 40px);\n}\n\n/* line 19 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/color_input/index.styl */\n.color_input---circle---k0pvf1B-zcJm8YSPEZk3o {\n  border-radius: 15px;\n  box-shadow: 0 0 10px;\n  height: 30px;\n  width: 30px;\n}\n\n/* line 25 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/color_input/index.styl */\n.color_input---picker---1j_RSyWjy5wbIhaHcUpCL2 {\n  position: absolute;\n  z-index: 1000;\n}\n\n/* line 29 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/color_input/index.styl */\n.color_input---picker-overlay---1hpKVaG5xvGlq0PmMsMP4Q {\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: -1;\n}\n", ""]);

// exports
exports.locals = {
	"root": "color_input---root---25UwCqc2hbYG0ONceOFkoi",
	"current": "color_input---current---3OCgINiwzEk9ZneUPAD3Jz",
	"text": "color_input---text---ucgzVAlWl_nJ2bQqSb5jA",
	"circle": "color_input---circle---k0pvf1B-zcJm8YSPEZk3o",
	"picker": "color_input---picker---1j_RSyWjy5wbIhaHcUpCL2",
	"picker-overlay": "color_input---picker-overlay---1hpKVaG5xvGlq0PmMsMP4Q"
};