const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const ImportFromXproModal = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'importFromXpro',
]);

const mapStateToProps = (state) => ({
  importStatus: state.promiseInspections['tourbookEditorPage/importFromXpro'],
});

module.exports = build({
  component: ImportFromXproModal,
  mapDispatchToProps,
  mapStateToProps,
});
