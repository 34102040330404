exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 20 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_template/components/images_section/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 22 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/sizes.styl */\n\n/* line 7 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_template/components/images_section/index.styl */\n.images_section---wrapper---2XsR6Q-u1BCDlKnIJkflkw {\n  margin-bottom: 3px;\n}\n\n/* line 10 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_template/components/images_section/index.styl */\n.images_section---wrapper---2XsR6Q-u1BCDlKnIJkflkw:last-child {\n  margin-bottom: 0;\n}\n\n/* line 13 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_template/components/images_section/index.styl */\n.images_section---size_1---1zTQFk6RD0riDMrcrHPVDy {\n  height: 168.66666666666666px;\n}\n\n/* line 16 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_template/components/images_section/index.styl */\n.images_section---size_2---1gr_sDBrUmmeTS0s0hGTLb {\n  height: 340.3333333333333px;\n}\n\n/* line 19 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_entry_template/components/images_section/index.styl */\n.images_section---size_3---2Dqp3JGP-QKKKmxqggDVPT {\n  height: 512px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "images_section---wrapper---2XsR6Q-u1BCDlKnIJkflkw",
	"size_1": "images_section---size_1---1zTQFk6RD0riDMrcrHPVDy",
	"size_2": "images_section---size_2---1gr_sDBrUmmeTS0s0hGTLb",
	"size_3": "images_section---size_3---2Dqp3JGP-QKKKmxqggDVPT"
};