const DropdownContainer = require('@components/shared/dropdown/container');
const PropTypes = require('prop-types');
const React = require('react');

class SelectInput extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool,
      navigateForward: PropTypes.func.isRequired,
      options: PropTypes.shape({
        fieldDefinition: PropTypes.shape({
          options: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
      }).isRequired,
      update: PropTypes.func.isRequired,
      value: PropTypes.string,
    };
  }

  render() {
    if (this.props.active) {
      return (
        <DropdownContainer
          active={this.props.active}
          onChange={(value) => {
            this.props.update(value);
            return this.props.navigateForward();
          }}
          options={this._getFormattedOptions()}
          value={this.props.value || ''}
          width="100%" />
      );
    } else {
      return (
        <div>
          {this.props.value}
        </div>
      );
    }
  }

  _getFormattedOptions() {
    const formattedOptions = this.props.options.fieldDefinition.options.map(
      (option) => ({
        label: option,
        value: option,
      })
    );
    return [{ label: '(Blank)', value: '' }].concat(formattedOptions);
  }
}
SelectInput.initClass();

module.exports = SelectInput;
