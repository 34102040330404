const querystring = require('querystring');

// NB!:  - As of 2/14/2020  the Nearby Places Service now points to the NearbyPhotos Google Function

class NearbyPlacesService {
  static initClass() {
    this.prototype.getLocationImages = async function ({
      latitude,
      longitude,
    }) {
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      };
      const queryObj = { lat: latitude, long: longitude };
      const url = `${
        this.firebaseFunctionsConfig.url
      }/apiNearbyphotos?${querystring.stringify(queryObj)}`;
      const response = await this.axios(url, headers);
      const { photos } = response.data;
      if (photos.length === 0) {
        return { noData: true };
      } else {
        return { photos: photos.slice(0, 5) };
      }
    };
  }

  constructor({ ENV: { firebaseFunctionsConfig }, axios }) {
    this.firebaseFunctionsConfig = firebaseFunctionsConfig;
    this.axios = axios;
  }
}
NearbyPlacesService.initClass();

module.exports = NearbyPlacesService;
