const React = require('react');
const PropTypes = require('prop-types');
const InputRowContainer = require('@components/shared/input_row/container');
const importedStyles = require('.././index.styl');
var classNames = require('classnames/bind');
const SortableList = require('@components/pages/tourbook_editor_page/components/input_set/sortable_list');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');
const cx = classNames.bind(importedStyles);

const sectionConfigs = [
  { label: 'Selected', key: 'selected' },
  { label: 'Unused', key: 'unSelected' },
];

class SelectedFields extends React.Component {
  constructor(props) {
    super(props);
    this.renderInputRow = this.renderInputRow.bind(this);
    this._onInputReorder = this._onInputReorder.bind(this);
    this.state = { unselectedExpanded: true };
    if (
      this.props.summaryService.isLegacySummaryFields(
        this.props.rawSummaryFields
      )
    ) {
      this._onInputReorder(
        { newIndex: 0, oldIndex: 0 },
        this.props.summaryFields
      );
    }
  }

  static initClass() {
    this.propTypes = {
      update: PropTypes.func.isRequired,
      summaryFields: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired,
        })
      ).isRequired,
      rawSummaryFields: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired,
        })
      ).isRequired,
      tourbookEntryFieldsService: PropTypes.object.isRequired,
      tourbookFieldsService: PropTypes.object.isRequired,
      fieldDefinitions: PropTypes.object.isRequired,
      summaryService: PropTypes.object.isRequired,
      filteredData: PropTypes.shape({
        selected: PropTypes.shape({
          customClass: PropTypes.string.isRequired,
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string.isRequired,
              order: PropTypes.number.isRequired,
            })
          ),
          unselected: PropTypes.shape({
            customClass: PropTypes.string.isRequired,
            fields: PropTypes.arrayOf(
              PropTypes.shape({
                key: PropTypes.string.isRequired,
                order: PropTypes.number.isRequired,
              })
            ),
          }),
        }),
      }).isRequired,
    };
  }

  renderInputRow(item, dragHandle, defaultEnable) {
    return (
      <InputRowContainer
        active={false}
        dragHandle={dragHandle}
        shouldAddColonToLabel={true}
        shouldDisplayHint={false}
        input={{
          label: item.label,
          path: ['content', 'settings', 'summaryFields', item.key],
          toggleValue: { key: item.key, order: item.order },
          type: 'value-toggle', // TODO enum
          disableEnterNavigation: true,
          defaultEnable: defaultEnable,
        }}
      />
    );
  }

  _onInputReorder({ newIndex, oldIndex }, selectedFields) {

    const reorderingOccured = selectedFields.length > 0;
    if (reorderingOccured) {
      const curItem = selectedFields.splice(oldIndex, 1);
      selectedFields.splice(newIndex, 0, curItem[0]);
    }
    const toUpdate = this.props.summaryService
      .portLegacySummaryFields(selectedFields)
      .map((item, i) => ({
        key: item.key,
        order: i,
      }))
      .reduce((acc, selField) => {
        acc[selField.key] = selField;
        return acc;
      }, {});
    this.props.update({
      path: ['content', 'settings', 'summaryFields'],
      value: toUpdate,
    });
  }

  toggleExpand(key) {
    if (key === 'unSelected') {
      this.setState({ unselectedExpanded: !this.state.unselectedExpanded });
    }
  }

  render() {
    return (
      <div>
        {sectionConfigs.map((sec) => {
          const isSelectedList = sec.key === 'selected';
          return (
            <div key={sec.key}>
              <div
                className={cx([
                  this.props.filteredData[sec.key].customClass,
                  isSelectedList || this.state.unselectedExpanded
                    ? 'expanded'
                    : '',
                ])}
                onClick={() => {
                  return this.toggleExpand(sec.key);
                }}
              >
                <h6 className={cx(['selected-fields-heading'])}>
                  {sec.label} Fields
                </h6>
                {!isSelectedList && <FontAwesomeIcon name="chevron-down" />}
              </div>
              {(isSelectedList || this.state.unselectedExpanded) && (
                <SortableList
                  items={this.props.filteredData[sec.key].fields}
                  renderInputRow={(item, dragHandle) =>
                    this.renderInputRow(
                      item,
                      isSelectedList ? dragHandle : undefined,
                      isSelectedList
                    )
                  }
                  onSortEnd={(order) =>
                    this._onInputReorder(
                      order,
                      this.props.filteredData.selected.fields
                    )
                  }
                  useDragHandle={true}
                  lockAxis="y"
                  lockToContainerEdges={true}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

SelectedFields.initClass();

module.exports = SelectedFields;
