const md5 = require('blueimp-md5');
const _ = require('lodash');
const { locateServices } = require('./service_locator');

const PascalCase = _.flow(_.camelCase, _.upperFirst);

var UsersService = (function () {
  let toTitleCase = undefined;
  UsersService = class UsersService {
    static initClass() {
      this.prototype.canEdit = async function ({ tourbookId, userId }) {
        let tourbookShared;
        const tourbook = await this.tourbooksService.get(tourbookId);
        const tourbookOwned = userId === tourbook?.owner_id;
        if (tourbook?.shared != null) {
          tourbookShared = tourbook.shared[userId] != null;
        }
        const isAdmin = await this.isAdmin(userId);
        return tourbookOwned || tourbookShared || isAdmin;
      };

      this.prototype.createProfile = async function (userId, auth0Profile) {
        const profile = {
          email: auth0Profile.email.toLowerCase(),
          first_name: toTitleCase(
            auth0Profile.nickname.substr(
              0,
              auth0Profile.nickname.indexOf('.')
            ) || null
          ),
          headline: auth0Profile.headline || null,
          last_name: toTitleCase(
            auth0Profile.nickname.substr(
              auth0Profile.nickname.indexOf('.') + 1,
              auth0Profile.nickname.length
            ) || null
          ),
          picture: { url: auth0Profile.picture || null },
          public_profile_url: auth0Profile.publicProfileUrl || null,
          tourbookSettings: { amenities: true },
          title: auth0Profile.title || null,
          phone: auth0Profile.phone || null,
        };
        await this.firebaseService.updateValues(`users/${userId}`, {
          profile,
          timestamps: {
            created_at: this.firebaseService.now(),
          },
        });
        return profile;
      };

      this.prototype.updateProfile = async function (userId, auth0Profile) {
        const curUserNode = await this.firebaseService.getValue(
          `users/${userId}`
        );
        const curTimestamps = curUserNode.timestamps;
        const curProfile = curUserNode.profile;
        const profile = {
          email:
            auth0Profile.email != null
              ? auth0Profile.email.toLowerCase()
              : curProfile.email,
          first_name:
            auth0Profile.nickname != null
              ? toTitleCase(
                  auth0Profile.nickname.substr(
                    0,
                    auth0Profile.nickname.indexOf('.')
                  )
                )
              : curProfile.first_name,
          last_name:
            auth0Profile.nickname != null
              ? toTitleCase(
                  auth0Profile.nickname.substr(
                    auth0Profile.nickname.indexOf('.') + 1,
                    auth0Profile.nickname.length
                  )
                )
              : curProfile.last_name,
          picture: {
            url:
              auth0Profile.picture != null
                ? auth0Profile.picture
                : curProfile.picture.url,
          },
          tos: true,
          public_profile_url: auth0Profile.publicProfileUrl || null,
          tourbookSettings: { amenities: true },
          v2: true,
        };
        const timestamps = {
          updated_at: this.firebaseService.now(),
          created_at:
            curTimestamps.created_at != null
              ? curTimestamps.created_at
              : this.firebaseService.now(),
        };
        await this.firebaseService.updateValues(`users/${userId}`, {
          profile,
          timestamps,
        });
        return profile;
      };

      this.prototype.getMostRecentlyCreatedTourbook = async function (userId) {
        const userTourbooks = await this.firebaseService.getValue(
          `userTourbooks/${userId}`
        );
        if (!userTourbooks) {
          return undefined;
        }
        const keys = Object.keys(userTourbooks)
          .sort()
          .filter((key) => key !== 'sample');
        if (keys.length === 0) {
          return undefined;
        }
        const mostRecentTourbookId = keys[keys.length - 1];
        const tourbook = await this.firebaseService.getValue(
          `tourbooks/${mostRecentTourbookId}`
        );
        return tourbook;
      };

      this.prototype.getUserIdsByEmailPascalCase = async function (email) {
        const [name, address] = email.toLowerCase().split("@");
        const pascalCaseNamedEmail = name.split(".").map(token => PascalCase(token)).join(".") + "@" + address;
        return await this.firebaseService.getValue(
          `userEmailMap/${md5(pascalCaseNamedEmail)}`
        );
    }

      this.prototype.getUserIdsByEmail = async function (email) {
        let returnUserIdString = '';
        returnUserIdString = await this.firebaseService.getValue(
          `userEmailMap/${md5(email)}`
        );
        if (returnUserIdString === null) {
          returnUserIdString = await this.firebaseService.getValue(
            `userEmailMap/${md5(email.toLowerCase())}`
          );
        }

        if (email.substr(email.length - 8) === 'nmrk.com') {
          const oldEmail = email.replace('nmrk.com', 'ngkf.com');
          if (returnUserIdString === null) {
            returnUserIdString = await this.firebaseService.getValue(
              `userEmailMap/${md5(oldEmail)}`
            );
          }
          if (returnUserIdString === null) {
            returnUserIdString = await this.firebaseService.getValue(
              `userEmailMap/${md5(oldEmail.toLowerCase())}`
            );
          }
        }

        return returnUserIdString;
      };

      toTitleCase = (str) =>
        str.replace(
          /\w\S*/g,
          (
            txt // see comment below
          ) =>
            txt[0].toUpperCase() +
            txt.slice(1, +(txt.length - 1) + 1 || undefined).toLowerCase()
        );
    }

    constructor() {
      ({
        firebaseService: this.firebaseService,
        tourbooksService: this.tourbooksService,
      } = locateServices(['firebaseService', 'tourbooksService']));
    }

    addPreviousFields(userId, fields) {
      const updates = _.chain(fields)
        .keyBy('key')
        .mapValues((field) => {
          return {
            label: field.label || null,
            lastUsed: this.firebaseService.now(),
          };
        })
        .value();
      return this.firebaseService.updateValues(
        `userPreviousCustomFields/${userId}`,
        updates
      );
    }

    getProfile(userId) {
      return this.firebaseService.getValue(`users/${userId}/profile`);
    }

    isAdmin(userId) {
      return this.firebaseService.hasValue(`adminUsers/${userId}`);
    }

    isDisabled(userId) {
      return this.firebaseService.hasValue(`disabledUsers/${userId}`);
    }

    saveImage(userId, section, image) {
      return this.firebaseService.pushValue(
        `images/${userId}/${section}`,
        image
      );
    }

    subscribeToImages(userId, fn) {
      return this.firebaseService.subscribeToUpdates(`images/${userId}`, fn);
    }

    subscribeToPreviousFields(userId, fn) {
      return this.firebaseService.subscribeToUpdates(
        `userPreviousCustomFields/${userId}`,
        fn
      );
    }

    validateEmail(email) {
      if (!email) {
        return false;
      }
      const atIndex = email.indexOf('@');
      if (atIndex === -1) {
        return false;
      }
      const dotIndex = email.lastIndexOf('.');
      if (dotIndex < atIndex) {
        return false;
      }
      if ((email.match(/@/g) || []).length > 1) {
        return false;
      }
      return true;
    }
  };
  UsersService.initClass();
  return UsersService;
})();

module.exports = UsersService;
