const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const NewEntryModal = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'createEntry',
  'deleteNewEntryData',
  'getNewEntryData',
  'getN360Addresses',
  'track',
  'setFieldDefinitions',
  'addAdditionalFields',
]);

const mapStateToProps = (state) => {
  const { error, source } = state.tourbookEditorPage.newEntryData || {};
  return _.assign({}, state.tourbookEditorPage.newEntryData, {
    hasN360Error: error && source === DATA_SOURCE_TYPES.N360,
    createEntryStatus: state.promiseInspections['tourbookEditorPage/createEntry'],
    getNewEntryDataStatus: state.promiseInspections['tourbookEditorPage/getNewEntryData'],
    getN360Addresses: state.promiseInspections['tourbookEditorPage/getN360Addresses'],
    fieldDefinitions: state.tourbookEditorPage.fieldDefinitions,
  });
};

const propTypes = {
  options: PropTypes.shape({
    address: PropTypes.string,
    n360ListingId: PropTypes.string,
    n360PropertyId: PropTypes.string,
    n360SpaceId: PropTypes.string,
  }).isRequired,
};

module.exports = build({
  component: NewEntryModal,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames: ['n360QueryService'],
});
