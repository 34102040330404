const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PdfEntryImagesTemplate = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setIsDraggingImage',
  'trackDragImageToPdf',
  'update',
]);

const mapStateToProps = function (state, { entryId, pageId }) {
  const { data, isDraggingImage } = state.tourbookEditorPage;
  const entryPath = ['content', 'entries', entryId];
  const pagePath = entryPath.concat(['pages', 'images', pageId]);
  const entry = _.get(data, entryPath);
  return {
    entries: data.content.entries,
    entryLocation: entry.location,
    isDraggingImage,
    page: _.get(data, pagePath),
    pagePath,
    tourbook: data,
  };
};

const propTypes = {
  entryId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  pageId: PropTypes.string.isRequired,
};

module.exports = build({
  component: PdfEntryImagesTemplate,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames: ['fieldFormatterService'],
});
