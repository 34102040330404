const _ = require('lodash');
const Promise = require('bluebird');
const md5 = require('blueimp-md5');
const {
  getDatabase,
  ref,
  child,
  get,
  set,
  onValue,
  push,
  update,
  remove,
  serverTimestamp,
} = require('firebase/database');
const { getAuth, signInWithCustomToken } = require('firebase/auth');

class FirebaseService {
  static initClass() {
    this.prototype.getChildCount = async function (path) {
      const data = await this.getValue(path);
      return _.size(data);
    };

    this.prototype.hasValue = async function (path) {
      const snapshot = await get(child(this.defaultRootRef, path));
      return snapshot.exists();
    };

    this.prototype._getRefValue = async function (ref) {
      const snapshot = await get(ref);
      return snapshot.val();
    };
  }

  constructor({ axios, firebaseDefaultApp }) {
    this.axios = axios;
    this.firebaseDefaultApp = firebaseDefaultApp;
    this.defaultRootRef = ref(getDatabase(firebaseDefaultApp));
  }

  _displayInformativeError(error) {
    if (error.code === 'PERMISSION_DENIED') {
      console.error("Updating the Firebase database Failed because of 'Firebase Rules'");
    } else {
      console.error(error);
    }
  }

  authenticate(token) {
    const auth = getAuth(this.firebaseDefaultApp);
    return signInWithCustomToken(auth, token);
  }

  getUserIdsByEmail(email) {
    return this.getValue(`userEmailMap/${md5(email.toLowerCase())}`);
  }

  writeToEmailMap(email, uid) {
    return this.setValue(`userEmailMap/${md5(email)}`, [uid]);
  }

  deleteFromEmailMap(email) {
    return this.removeValue(`userEmailMap/${md5(email)}`);
  }

  getUniqueId() {
    return push(this.defaultRootRef).key;
  }

  getValue(path) {
    return this._getRefValue(child(this.defaultRootRef, path));
  }

  pushValue(path, value) {
    return push(child(this.defaultRootRef, path), value).catch((e) => {
      this._displayInformativeError(e);
    });
  }

  removeValue(path) {
    return remove(child(this.defaultRootRef, path));
  }

  setValue(path, value) {
    return set(child(this.defaultRootRef, path), value).catch((e) => {
      this._displayInformativeError(e);
    });
  }

  now() {
    return serverTimestamp();
  }
  /**
   * Firebase doesn't permit the use of ' $#[]./' in keys, so we need to serialize them
   * @param {string} str the string to be serialized
   * @returns a sanitized string
   */
  serializeForFirebaseKey(str) {
    return (str || '')
      .toLowerCase()
      .replaceAll(' ', '_')
      .replaceAll('#', '*')
      .replaceAll('.', '`')
      .replaceAll('/', '|');
  }

  // returns a Promise that resolves after the first value is returned
  subscribeToUpdates(path, fn) {
    const ref = child(this.defaultRootRef, path);
    return new Promise(function (resolve, reject) {
      const { query, off } = require('firebase/database');
      const callback = function (snapshot) {
        try {
          fn(snapshot.val());
        } catch (error) {
          reject(error);
        }
        return resolve(unsubscribe);
      };
      const queryHelper = query(ref);
      const unsubscribe = () => off(queryHelper, 'value', callback);
      return onValue(queryHelper, callback, reject);
    });
  }

  updateValues(path, valueMapping) {
    return update(child(this.defaultRootRef, path), valueMapping);
  }

  updateProfileEmail(userId, newEmail) {
    return this.updateValues(`users/${userId}/profile`, newEmail);
  }
}
FirebaseService.initClass();

module.exports = FirebaseService;
