class MobileService {
  constructor({ ENV: { mobileConfig } }) {
    ({ host: this.host } = mobileConfig);
  }

  getUrl(tourbookId) {
    return `${this.host}/#/tourbook/${tourbookId}`;
  }
}

module.exports = MobileService;
