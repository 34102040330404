/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const NavigationService = require('./navigation_service');

describe('NavigationService', function () {
  describe('getTourbookUrl', function () {
    beforeEach(function () {
      this.window = {
        location: {
          protocol: 'https',
          host: 'spaceful-test.com',
        },
      };
      return (this.navigationService = new NavigationService({
        window: this.window,
      }));
    });

    describe("when port doesn't exist", () =>
      it('returns the proper tourbook URL', function () {
        return expect(
          this.navigationService.getTourbookUrl('tourbook-id-1')
        ).to.eql('https://spaceful-test.com/v2/#/tourbook/tourbook-id-1');
      }));

    return describe('when port exists', () =>
      it('returns the proper tourbook URL', function () {
        this.window.location.port = 8085;
        return expect(
          this.navigationService.getTourbookUrl('tourbook-id-1')
        ).to.eql('https://spaceful-test.com:8085/v2/#/tourbook/tourbook-id-1');
      }));
  });

  return describe('goTo', function () {
    return describe('without angular integration', () =>
      it('pushes to the history', function () {
        const history = { push: sinon.stub() };
        const service = new NavigationService({
          angularIntegration: false,
          history,
        });
        service.goTo('/library');
        return expect(history.push).to.have.been.calledWith('/library');
      }));
  });
});
