const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const PropTypes = require('prop-types');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class PostInputSetAddButton extends React.Component {
  static initClass() {
    this.propTypes = {
      caption: PropTypes.string,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <div className={cx(['header'])}>
          <div className={cx(['separator', 'left'])} />
          <div onClick={this.props.onClick} className={cx(['plus-wrapper'])}>
            <FontAwesomeIcon name="plus" />
          </div>
          <div className={cx(['separator', 'right'])} />
        </div>
        <div onClick={this.props.onClick} className={cx(['content'])}>
          <div className={cx(['label'])}>
            {this.props.label}
          </div>
          {this.props.caption
            ? <div className={cx(['caption'])}>
            {this.props.caption}
          </div>
            : undefined}
        </div>
      </div>
    );
  }
}
PostInputSetAddButton.initClass();

module.exports = PostInputSetAddButton;
