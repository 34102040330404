const _ = require('lodash');
const { createAction } = require('redux-actions');
const promiseInspectionBuilder = require('./promise_inspection_builder');
const { locateService } = require('@services/service_locator');

const configurations = [
  locateService('libraryPageActions'),
  locateService('collaborateModalActions'),
  require('./tourbook_editor_page'),
  require('./user_session'),
  require('./profile_page_actions'),
];

module.exports = function () {
  const actions = { promiseInspections: promiseInspectionBuilder.actions };
  configurations.forEach(function ({
    identityActionTypes,
    mapping,
    mappingBuilder,
    namespace,
  }) {
    const identityActions = {};
    _.each(
      identityActionTypes,
      (key) => (identityActions[key] = createAction(`${namespace}/${key}`))
    );
    const promiseInspection = promiseInspectionBuilder(namespace);
    promiseInspection.reset = (key) =>
      promiseInspectionBuilder.actions.reset(`${namespace}/${key}`);
    if (mappingBuilder) {
      mapping = mappingBuilder({ promiseInspection });
    }
    const namespacedActions = _.assign({}, identityActions, mapping);
    return (actions[namespace] = _.mapValues(namespacedActions, (fn) =>
      fn.bind(namespacedActions)
    ));
  });
  return actions;
};
