const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const EntryImageDragSource = require('./components/entry_image_drag_source');
const PropTypes = require('prop-types');
const React = require('react');

const ImagesContainer = ({
  that,
  tourbookId,
  entryId,
  imgs = {},
  update,
  children,
}) => {
  const images = _.chain(imgs)
    .map((image, id) => {
      return { ...image, id };
    })
    .sortBy(['order'])
    .value();
  return (
    <div className={cx(['image-wrapper'])}>
      {_.map(images, (image, index) => {
        const { id } = image;
        const imagePath = that.props.path.concat(id);
        return (
          <EntryImageDragSource
            key={id}
            entryId={entryId}
            images={imgs}
            image={image}
            imageId={id}
            isImageInPDF={that._isImageInPDF(image)}
            index={index}
            entryLocation={that.props.entry.location}
            imagePath={imagePath}
            onDelete={() =>
              that.props.setCurrentModal({
                entryId,
                imageId: id,
                key: 'delete-entry-image',
              })
            }
            update={update}
          />
        );
      })}
      {children}
    </div>
  );
};

class EntryImagesInput extends React.Component {
  constructor(props) {
    super(props);
    this._openChangeImageModal = this._openChangeImageModal.bind(this);
  }

  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      cloudinaryService: PropTypes.object.isRequired,
      defaultStreetviewSettings: PropTypes.object,
      digitalTourbookEntryImages: PropTypes.object,
      entry: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      entryPath: PropTypes.string,
      path: PropTypes.array.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      setIsDraggingImage: PropTypes.func.isRequired,
      tourbookEntryImagesService: PropTypes.object.isRequired,
      tourbookId: PropTypes.string,
      track: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
      value: PropTypes.object,
    };

    this.prototype._getTenantImages = async function (tourbookId, entryId) {
      const imagesToReturn =
        await this.props.tourbookEntryImagesService.getDigitalTourbookEntryImages(
          entryId,
          tourbookId
        );
      return imagesToReturn;
    };
  }

  render() {
    return (
      <div className={cx(['root', this.props.active ? 'active' : ''])}>
        <label>Listing Images</label>
        <div className={cx(['message'])}>
          {`\
  All Listing Images are available in the digital tour book.
  Drag the images you would also like to add to the PDF.\
  `}
        </div>
        <div className={cx(['images'])}>
          <ImagesContainer
            that={this}
            tourbookId={this.props.tourbookId}
            entryId={this.props.entryId}
            imgs={this.props.value}
            update={this.props.update}
          >
            {this._getStaticImages().map((image, index) => {
              return <EntryImageDragSource key={index} image={image} />;
            })}
          </ImagesContainer>
        </div>
        <div className={cx(['link-wrapper'])}>
          <a onClick={this._openChangeImageModal}>{this._getLinkText()}</a>
        </div>
        {this._renderN360ImagesMessage()}
        {this._renderTenantImagesMessage()}
      </div>
    );
  }

  _getLinkText() {
    // add more images // adding this as comment so we can search 'add more images' and find the place it's used
    return _.compact([
      'Add',
      _.size(this.props.value) ? 'more' : undefined,
      'images',
    ]).join(' ');
  }

  _getStaticImages() {
    const staticImages = [];
    if (this.props.defaultStreetviewSettings) {
      staticImages.push(
        _.assign({ type: 'streetview' }, this.props.defaultStreetviewSettings)
      );
    }
    staticImages.push({
      center: [
        this.props.entry.location.latitude,
        this.props.entry.location.longitude,
      ],
      type: 'map',
      zoom: 16,
    });
    return staticImages;
  }

  _isImageInPDF(image) {
    const imagesInPdf = this.props.tourbookEntryImagesService.getImagesInPDF(
      this.props.entry
    );
    return _.some(imagesInPdf, (pdfImage) => {
      return this.props.cloudinaryService.equals(pdfImage, image);
    });
  }

  _onDrop(image, location) {
    if (location.page === 'primary') {
      return this.props.updateImagesShown({
        index: location.index,
        value: image,
      });
    }
  }

  _openChangeImageModal() {
    this.props.setCurrentModal({
      key: 'change-image',
      path: this.props.path,
      type: 'entry',
      entryId: this.props.entryId
    });
    return this.props.track('Selects to Add Images', {
      entryId: this.props.entryId,
    });
  }

  _renderN360ImagesMessage() {
    const count = _.differenceWith(
      this.props.entry.n360Images,
      _.values(this.props.value),
      _.isEqual
    ).length;
    if (!count) {
      return;
    }
    let msg = `${count} more N360 image`;
    if (count > 1) {
      msg += 's';
    }
    msg += ' available';
    return <div className={cx(['n360-remaining'])}>{msg}</div>;
  }

  _renderTenantImagesMessage() {
    let count;
    if (this.props.digitalTourbookEntryImages != null) {
      count = Object.keys(this.props.digitalTourbookEntryImages).length;
    }
    if (!count) {
      return;
    }
    return (
      <div className={cx(['root', this.props.active ? 'active' : ''])}>
        <label>Client Uploaded Images</label>
        <div className={cx(['message'])}>
          {`\
  Include any client-uploaded images in a PDF tourbook by
  dragging the images you would like to add.\
  `}
        </div>
        <div className={cx(['images'])}>
          {_.map(this.props.digitalTourbookEntryImages, (image, imageId) => {
            return (
              <EntryImageDragSource
                image={image}
                imageId={imageId}
                isImageInPDF={false}
                key={imageId}
                onDelete={() =>
                  this.props.setCurrentModal({
                    entryId: this.props.entryId,
                    imageId,
                    key: 'delete-entry-image',
                  })
                }
              />
            );
          })}
        </div>
      </div>
    );
  }
}
EntryImagesInput.initClass();

module.exports = EntryImagesInput;
