const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const { default: Select } = require('react-select');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class Dropdown extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool,
      onChange: PropTypes.func.isRequired,
      options: PropTypes.array.isRequired,
      value: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
      tourbookFieldsService: PropTypes.object,
    };
  }

  componentDidMount() {
    if (this.props.active) {
      this.select.focus();
    }
    return this.root.addEventListener('keydown', function (event) {
      if (event.keyCode === 13) {
        // enter
        return (event.bypassKeyboardShortcutService = true);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.active) {
      return this.select.focus();
    }
  }

  componentWillMount() {
    if (this.props.active) {
      return this._registerShortcuts();
    }
  }

  componentWillUnmount() {
    return this._unregisterShortcuts();
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.active !== nextProps.active) {
      if (nextProps.active) {
        return this._registerShortcuts();
      } else {
        return this._unregisterShortcuts();
      }
    }
  }

  render() {
    const selectedOption = this.props.options.find(o => o.value === this.props.value)
    return (
      <div
        ref={(node) => {
          return (this.root = node);
        }}
        style={{ width: this.props.width }}
        className={cx(['root'])}>
        <Select // TODO: fix custom styling for this
          arrowRenderer={function() {
            return <FontAwesomeIcon name="chevron-down" />;
          }}
          autosize={true}
          clearable={false}
          onChange={({ value }) => this.props.onChange(value)}
          openOnFocus={true}
          options={this.props.options}
          formatOptionLabel={(data, formatOptionLabelMeta) => {
            let { label, unit } = data;            
            if (unit) {
              return this.props.tourbookFieldsService.getFieldUnit(
                { unit },
                { asHtml: true }
              );
            }
            return <div>{label}</div>; // default case
          }}
          ref={(node) => {
            return (this.select = node);
          }}
          searchable={false}
          value={selectedOption} />
      </div>
    );
  }

  _registerShortcuts() {
    return (this.unregisterShortcutsFn =
      this.props.keyboardShortcutService.register(
        {
          13: () => this.select.selectFocusedOption(),
        },
        { prepend: true }
      ));
  }

  _unregisterShortcuts() {
    if (typeof this.unregisterShortcutsFn === 'function') {
      this.unregisterShortcutsFn();
    }
    return delete this.unregisterShortcutsFn;
  }
}
Dropdown.initClass();

module.exports = Dropdown;
