const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PdfCoverTemplateContainer = require('../pdf_cover_template/container');
const PdfEntryAmenitiesTemplateContainer = require('../pdf_entry_amenities_template/container');
const PdfEntryImagesTemplateContainer = require('../pdf_entry_images_template/container');
const PdfEntryTemplateContainer = require('../pdf_entry_template/container');
const PdfMapTemplateContainer = require('../pdf_map_template/container');
const PdfSummaryTemplateContainer = require('../pdf_summary_template/container');
const PdfSummaryTemplateVerticalContainer = require('../pdf_summary_template_vertical/container');
const PdfTourbookNotesTemplateContainer = require('../pdf_tourbook_notes_template/container');
const PdfBrokersTemplateContainer = require('../pdf_brokers_template/container');

const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');

class Pdf extends React.Component {
  static initClass() {
    this.propTypes = {
      broker: PropTypes.object,
      entries: PropTypes.object,
      onReady: PropTypes.func,
      settings: PropTypes.object,
      pdfService: PropTypes.object.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.readyRegistry = new ReadyRegistry(); // It is used to determine whether all components are rendered before actually generating a PDF file
  }

  componentDidMount() {
    return this.readyRegistry.addListener(() => {
      return typeof this.props.onReady === 'function'
        ? this.props.onReady(this.wrapper.outerHTML)
        : undefined;
    });
  }

  render() {
    const summaryVertical = this.props.settings.display.summaryVertical;

    return (
      <div
        ref={(node) => {
          return (this.wrapper = node);
        }}
        className={cx(['root'])}
      >
        <PdfCoverTemplateContainer readyRegistry={this.readyRegistry} />
        {this.props.broker?.brokerInfos ? (
          <PdfBrokersTemplateContainer readyRegistry={this.readyRegistry} />
        ) : undefined}
        {this._hasMap()
          ? Object.keys(this.props.settings)
              .sort()
              .filter((key) => key.startsWith('mapOptions'))
              .filter((key) => !this.props.settings[key].hidden)
              .map((key) => {
                return (
                  <PdfMapTemplateContainer mapOptionsKey={key} readyRegistry={this.readyRegistry} />
                );
              })
          : undefined}

        {this._hasSummary()
          ? // render the summary
            this._getSortedEntryIdChunks().map((entryIdChunk, index) => {
              return summaryVertical ? (
                <PdfSummaryTemplateVerticalContainer
                  entryIds={entryIdChunk}
                  key={index}
                  offset={index * 4}
                  readyRegistry={this.readyRegistry}
                />
              ) : (
                <PdfSummaryTemplateContainer
                  entryIds={entryIdChunk}
                  key={index}
                  offset={index * 4}
                  readyRegistry={this.readyRegistry}
                />
              );
            })
          : undefined}
        {this._getSortedEntryPairs()
          .filter(([entryId]) =>
            this.props.pdfService.shouldListingBeIncludedInPdf(
              this.props.entries[entryId]
            )
          )
          .map((...args) => {
            // render the pages
            const [entryId, entry] = Array.from(args[0]);
            if (entry.key === 'tourbook-notes') {
              return (
                <PdfTourbookNotesTemplateContainer
                  readyRegistry={this.readyRegistry}
                  entryId={entryId}
                />
              );
            }
            return [
              <PdfEntryTemplateContainer
                entryId={entryId}
                readyRegistry={this.readyRegistry}
              />,
              this._getEntryImagePageIds(entry)
                .filter((pageId) =>
                  this.props.pdfService.shouldPageBeIncludedInPdf(
                    entry.pages.images[pageId],
                    entry
                  )
                )
                .map((pageId) => {
                  return (
                    <PdfEntryImagesTemplateContainer
                      entryId={entryId}
                      key={pageId}
                      pageId={pageId}
                      readyRegistry={this.readyRegistry}
                    />
                  );
                }),
              <PdfEntryAmenitiesTemplateContainer
                entryId={entryId}
                readyRegistry={this.readyRegistry}
              />,
            ];
          })}
      </div>
    );
  }

  _getEntryImagePageIds(entry) {
    return _.keys(entry.pages?.images);
  }

  _getSortedEntryIdChunks() {
    const doNotIncludeInSummaryKeys = [
      'tourbook-notes',
      'tourbook-floorplan',
      'tourbook-images',
    ];

    const filteredEntries = _.keyBy(
      _.chain(this.props.entries || [])
        .map((entry, entryId) => {
          return { ...entry, entryId };
        })
        .filter((x) => !doNotIncludeInSummaryKeys.includes(x.key) && !x.hidden)
        .value(),
      'entryId'
    );
    const pairedEntries = _.chain(filteredEntries)
      .toPairs()
      .sortBy(function (...args) {
        const [entryId, entry] = Array.from(args[0]);
        return entry.order;
      })
      .value();

    return _.chain(pairedEntries).map('0').chunk(4).value();
  }

  _getSortedEntryPairs() {
    return _.chain(this.props.entries)
      .toPairs()
      .sortBy(function (...args) {
        const [entryId, entry] = Array.from(args[0]);
        return entry.order;
      })
      .value();
  }

  _hasMap() {
    return (
      this.props.settings.display.tourMap && _.size(this.props.entries) > 0
    );
  }

  _hasSummary() {
    return (
      this.props.settings.display.summary && _.size(this.props.entries) > 0
    );
  }
}
Pdf.initClass();

module.exports = Pdf;
