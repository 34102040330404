const { default: AutoScale } = require('react-auto-scale');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const InputSetContainer = require('../input_set/container');
const PropTypes = require('prop-types');
const React = require('react');

class BaseTab extends React.Component {
  static initClass() {
    this.propTypes = {
      inputs: PropTypes.array,
      next: PropTypes.object,
      onNext: PropTypes.func,
      pdfTemplateComponentFactory: PropTypes.func.isRequired,
      pdfTemplateProps: PropTypes.object,
      postInputsFactory: PropTypes.func,
      postNextFactory: PropTypes.func,
    };
  }

  render() {
    return (
      <div id="input-set-scrollable-parent" className={cx(['root'])}>
        <div className={cx(['input-set-wrapper'])}>
          <InputSetContainer
            inputs={this.props.inputs}
            next={this.props.next}
            onNext={this.props.onNext}
            postInputsComponent={
              typeof this.props.postInputsFactory === 'function'
                ? this.props.postInputsFactory()
                : undefined
            }
            postNextComponent={
              typeof this.props.postNextFactory === 'function'
                ? this.props.postNextFactory()
                : undefined
            }
          />
        </div>
        <div className={cx(['pdf-template-wrapper'])}>
          <AutoScale>
            {this.props.pdfTemplateComponentFactory(
              this.props.pdfTemplateProps
            )}
          </AutoScale>
        </div>
      </div>
    );
  }
}
BaseTab.initClass();

module.exports = BaseTab;
