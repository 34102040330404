const _ = require('lodash');
const { locateServices } = require('./service_locator');

class N360FieldsService {
  constructor() {
    this.mapN360AllSpacesData = this.mapN360AllSpacesData.bind(this);
    ({ n360Fields: this.n360Fields } = locateServices(['n360Fields']));
  }

  getSuggestedFieldKeys() {
    return _.chain(this.n360Fields.propertyFields)
      .concat(this.n360Fields.spaceFields)
      .filter((field) => field.suggested)
      .map((field) => field.newKey || field.key)
      .value();
  }

  mapPropertyData(n360Property) {
    if (!n360Property) {
      return;
    }
    const spacefulProperty = this._mapData(
      n360Property,
      this.n360Fields.propertyFields
    );
    // spacefulProperty.propertyNotes = _.chain([
    //   n360Property.buildingComments
    //   n360Property.siteComments
    // ]).compact().join('\n').value()
    spacefulProperty.fromN360 = true;
    return spacefulProperty;
  }

  _getTransformedLeaseRate(spaceData) {
    const output = {};
    ['leaseRateLow', 'leaseRateHigh'].forEach(function (key) {
      const value = spaceData[key];
      if (value) {
        return (output[key] = value / 12);
      }
    });
    return output;
  }

  _mapData(input, mapping) {
    const output = _.chain(mapping)
      .map(function (field) {
        let value = input[field.key] || input[field.backupKey];
        if (value && field.transform) {
          value = field.transform(value);
        }
        return [field.newKey || field.key, value];
      })
      .filter(function (...args) {
        const [field, value] = Array.from(args[0]);
        return value != null;
      })
      .fromPairs()
      .value();
    output.media = _.map(output.media, (image) =>
      _.assign({}, image, { n360: true })
    );
    return output;
  }

  mapN360AllSpacesData(spaces) {
    const input = _.assign({}, spaces);
    // value = space[field.key] or space[field.backupKey]
    const mappedSpaces = [];
    _.forEach(input, (space) => {
      const mapping = this.n360Fields.spaceFields;
      const mappedSpace = _.chain(mapping)
        .map(function (field) {
          let value;
          if (space[field.key] != null) {
            value = space[field.key];
          } else {
            if (space[field.backupKey] != null) {
              value = space[field.backupKey];
            }
          }
          if (value != null && field.transform != null) {
            value = field.transform(value);
          }
          return [field.newKey || field.key, value];
        })
        .filter(function (...args) {
          const [field, value] = Array.from(args[0]);
          return value != null;
        })
        .fromPairs()
        .value();
      if (space.leaseRatePerType === 'per Mo') {
        _.assign(mappedSpace, this._getTransformedLeaseRate(mappedSpace));
      }
      return mappedSpaces.push(mappedSpace);
    });
    return mappedSpaces;
  }
}

module.exports = N360FieldsService;
