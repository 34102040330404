const _ = require('lodash');
const { Promise } = require('bluebird');
const { locateServices } = require('./service_locator');
// base64Img = require 'base64-img-promise'

class TourbookEntryBuilderService {
  static initClass() {
    this.prototype.addressFieldKeys = [
      'CorrectedAddress1',
      'city',
      'state',
      'zip',
    ];

    (this.prototype.buildNote = async function (currentEntryCount) {
      return {
        fields: {
          header: {
            notesHeader: 'A new note',
          },
          notes: {
            notes: {
              bullets: false,
              content: '',
              secret: false,
            },
          },
        },
        key: 'tourbook-notes',
        order: currentEntryCount + 1,
      };
    }),

    this.prototype.getN360Images = async function ({ property, space }) {
      let imagesToUpload = [];
      let images = _.chain(property.media)
        .concat(space?.media)
        .filter()
        .sortBy('id')
        .value();
      images = await Promise.filter(images, (image) => {
        if (image.commercialSearch) { // use the CommercialSearch cloudinary account
          image.url = this.cloudinaryService.getImageUrl(image);
          this.cloudinaryService.isImageValid(image);
          return true;
        } else {
          return true;
        }
      });
      imagesToUpload = _.chain(images)
        .map((image) => _.defaults(image, { type: 'image' }))
        .map((image) =>
          _.defaults(image, { fileName: image.cloudinaryPublicId })
        )
        .map((image) =>
          _.defaults(image, { cloudinaryPublicId: image.fileName })
        )
        .map((image) => _.defaults(image, { extension: 'jpg' }))
        .map((image) => _.defaults(image, { isPrimary: false }))
        .map((image) => _.defaults(image, { userName: 'n/a' }))
        .map((image) => _.defaults(image, { dateModified: 'n/a' }))
        .map((image) => _.defaults(image, { path: image.url }))
        .value();
      const uploadedImages = this._uploadN360ImagesToCloudinary(imagesToUpload);

      return uploadedImages;
    };

    this.prototype._uploadN360ImagesToCloudinary = async function (images) {
      const uploadImages = _.map(images, (image) => {
        // id: image.fileName # this will be the Cloudinary public_id
        return { image: { path: image.path } };
      });
      // userName: image.userName
      const setsOfCloudinaryImages = await this.cloudinaryService.uploadImages(
        uploadImages
      );
      return _.chain(_.zip(images, setsOfCloudinaryImages))
        .map(function (...args) {
          const [input, cloudinaryImages] = Array.from(args[0]);
          return cloudinaryImages
            .filter((image) => !image.error)
            .map(function (image) {
              const mappedImage = _.assign({}, image, {
                n360: input.n360,
                mediaTitle: input.fileName,
                source: input.source,
                userName: image.userName != null ? image.userName : undefined, // TODO why not input.userName?
                imageType: input.imageType,
                isPrimary: input.isPrimary,
              });
              return _.omitBy(mappedImage, _.isUndefined);
            });
        })
        .flatten()
        .value();
    };
  }

  constructor() {
    ({
      amenitiesService: this.amenitiesService,
      cloudinaryService: this.cloudinaryService,
      firebaseService: this.firebaseService,
      googleAddressService: this.googleAddressService,
      tourbookEntryFieldsService: this.tourbookEntryFieldsService,
    } = locateServices([
      'amenitiesService',
      'cloudinaryService',
      'firebaseService',
      'googleAddressService',
      'tourbookEntryFieldsService',
    ]));
  }

  getEntryMeta({ fields, property, space }) {
    const originalAddressFields = _.pick(fields.header, this.addressFieldKeys);
    const meta = { originalAddressFields, source: space ? 'n360' : 'new' };
    if (space && property.id != null) {
      meta.n360PropertyId = property.id;
    }
    if (space?.listingID != null) {
      meta.n360ListingId = space.listingID;
    }
    if (space?.id != null) {
      meta.n360SpaceId = space.id;
    }
    return meta;
  }

  getImageUpdates(entry) {
    const imagesShown = _.chain(entry.n360Images)
      .reject(this._isFloorplan)
      .take(2)
      .value();
    const images = [].concat(imagesShown);
    const updates = { imagesShown };
    const floorPlanImage = _.find(entry.n360Images, this._isFloorplan);
    if (floorPlanImage) {
      const pageId = this.firebaseService.getUniqueId();
      updates.pages = {
        images: {
          [pageId]: { imagesShown: [floorPlanImage], type: 'floor_plan' },
        },
      };
      images.push(floorPlanImage);
    }
    updates.images = _.chain(images)
      .uniqBy('url')
      .map((image) => [this.firebaseService.getUniqueId(), image])
      .fromPairs()
      .value();
    return updates;
  }

  _isFloorplan(image) {
    return _.includes(_.toLower(image.imageType), 'plan');
  }
}
TourbookEntryBuilderService.initClass();

module.exports = TourbookEntryBuilderService;
