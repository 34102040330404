const importedStyles = require('../../index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class AdditionalNotesSection extends React.Component {
  constructor(props) {
    super(props);
    this._isFieldActive = this._isFieldActive.bind(this);
  }

  static initClass() {
    this.propTypes = {
      currentField: PropTypes.object,
      entryId: PropTypes.string.isRequired,
      isEditing: PropTypes.bool,
      navigateToCurrentEntryImagesField: PropTypes.func.isRequired,
      additionalNote: PropTypes.object,
      additionalNoteHeader: PropTypes.string,
      visitedAdditionalNotesTab: PropTypes.bool,
    };
  }

  render() {
    return (
      <div className={cx(['additional-note-section'])}>
        {[
          <div {...this._getAdditionalNoteHeaderFieldProps()}>
            {this.props.additionalNoteHeader}
          </div>,
          <div {...this._getAdditionalNoteFieldProps()}>
            {this._getAdditionalNoteLines().map((line, index) => {
              return (
                <div key={index} className={cx(['line', ` ${!line ? 'empty' : ''}`])}>
                  {this._hasBullets()
                    ? <div className={cx(['line-bullet'])}>
                    •
                  </div>
                    : undefined}
                  <div className={cx(['line-content'])}>
                    {line}
                  </div>
                </div>
              );
            })}
          </div>,
        ]}
      </div>
    );
  }

  // what about tabKey here should it have a number?
  _buildOnClick(fieldKey) {
    if (!this.props.isEditing) {
      return;
    }
    return () => {
      return this.props.navigateToCurrentEntryImagesField({
        fieldKey: this._getFieldId(fieldKey),
        tabKey: 'pdf_page_' + this.props.pageId,
      });
    };
  }

  _getFieldId(fieldKey) {
    let fieldIdToReturn = '';
    if (fieldKey === 'additionalNoteHeader') {
      fieldIdToReturn = `content-entries-${this.props.entryId}-pages-images-${this.props.pageId}-additionalNoteHeader`;
    } else {
      if (fieldKey === 'additionalNote') {
        fieldIdToReturn = `content-entries-${this.props.entryId}-pages-images-${this.props.pageId}-content`;
      } else {
        fieldIdToReturn = `content-entries-${this.props.entryId}-fields-${section}-${fieldKey}`;
      }
    }
    return fieldIdToReturn;
  }

  _getAdditionalNoteHeaderFieldProps() {
    return {
      className: cx([`field`, `additionalNoteHeader`, `${this._isFieldActive('additionalNoteHeader') ? 'active' : ''
        }`]),
      key: 'additionalNoteHeader',
      onClick: this._buildOnClick('additionalNoteHeader'),
    };
  }

  _getAdditionalNoteFieldProps() {
    return {
      className: cx([`field`, `additionalNote`, `${this._isFieldActive('additionalNote') ? 'active' : ''
        }`, `${this._hasBullets() ? 'bullets' : ''}`]),
      key: 'additionalNote',
      onClick: this._buildOnClick('additionalNote'),
    };
  }

  _getAdditionalNoteLines() {
    return this.props.additionalNote?.content?.split('\n') || [''];
  }

  _hasBullets() {
    return this.props.additionalNote?.bullets;
  }

  _isFieldActive(fieldKey) {
    return (
      this.props.isEditing &&
      this.props.currentField.key === this._getFieldId(fieldKey)
    );
  }

  _shouldShowAdditionalNotesZeroState() {
    return false;
  }
}
AdditionalNotesSection.initClass();

module.exports = AdditionalNotesSection;
