const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const ChangeImageModal = require('./');
const ImageLibrary = require('@services/image_library');
const store = require('@store');

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateImages(entryId, images, uiType) {
    if (ownProps.options.type === 'entry') {
      const existingImages =
        store.getState().tourbookEditorPage.data.content.entries[entryId]?.images || [];
      const lastIndexAmongUploaded = _.chain(existingImages)
        .sortBy('order')
        .map((image) => image.order)
        .max()
        .value();
      const firstNewIndex = lastIndexAmongUploaded
        ? lastIndexAmongUploaded + 1
        : 0;
        images = images.map((image, i) => ({
        ...image,
        order: firstNewIndex + i,
      }));
      return dispatch(
        actions.tourbookEditorPage.pushAll({
          analyticsOptions: { trigger: 'add' },
          path: ownProps.options.path,
          values: images,
        })
      );
    } else {
      return dispatch(
        actions.tourbookEditorPage.update({
          analyticsOptions: { uiType },
          path: ownProps.options.path,
          value: images[0],
        })
      );
    }
  },

  uploadImages(entryId, images, options) {
    return dispatch(
      actions.tourbookEditorPage.uploadImages({
        entryId,
        images,
        options,
        saveLocation: _getUserImagesSaveLocation(ownProps),
      })
    );
  },
});

const mapStateToProps = (state, ownProps) => ({
  imageUploadStatus:
    state.promiseInspections['tourbookEditorPage/upload/images'],
  selectableImagesByType: _getImages(state, ownProps),
  entryId: ownProps.options.entryId,
});

var _getImages = function (state, ownProps) {
  switch (ownProps.options.type) {
    case 'background':
      return {
        suggestions: _getBackgroundSuggestions(state),
        user: _.values(state.tourbookEditorPage.images?.cover).reverse(),
        showDisclaimer: true,
      };
    case 'entry':
      return {
        user: _getAddableImages(state, ownProps, 'userImages').reverse(),
        n360: _getAddableImages(state, ownProps, 'n360Images'),
      };
    default:
      return {};
  }
};

var _getAddableImages = function (state, ownProps, key) {
  const addableImagesPath = _.initial(ownProps.options.path).concat(key);
  const addableImages = _.values(
    _.get(state.tourbookEditorPage.data, addableImagesPath)
  );
  const currentImages = _.values(
    _.get(state.tourbookEditorPage.data, ownProps.options.path)
  );
  return _.differenceWith(addableImages, currentImages, _.isEqual);
};

var _getBackgroundSuggestions = function (state) {
  const organizationImages = state.userSession.organization.coverImages;
  const defaultImages = ImageLibrary.coverPhotos;
  return _.concat(organizationImages, defaultImages);
};

var _getUserImagesSaveLocation = function (ownProps) {
  switch (ownProps.options.type) {
    case 'background':
      return {
        namespace: 'cover',
        type: 'user',
      };
    case 'entry':
      return {
        path: _.initial(ownProps.options.path).concat('userImages'),
        type: 'tourbook',
      };
  }
};

module.exports = build({
  component: ChangeImageModal,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['imageService'],
});
