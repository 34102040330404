const styled = require('../../../node_modules/styled-components/dist/styled-components.js');
const { colors } = require('../../utils/styles');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);

const Page = styled.div`
  background: ${colors.gray['600']};
  width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  background: ${colors.secondary};
  max-width: 768px;
  max-height: 550px;
  width: 100%;
  height: 100%;
  border-radius: 6px;

  a {
    color: ${colors.primary};
  }
`;

const LogoWrapper = styled.div`
  width: 50%;
`;

const FormWrapper = styled.div`
  background: ${colors.foreground};
  width: 50%;
  border-radius: 6px 0 0 6px;
`;

function AuthForm({ title, children }) {
  return (
    <Page className="d-flex align-items-center justify-content-center p-3">
      <Container className={'d-flex ' + cx(['box-shadow'])}>
        <FormWrapper className="p-4">
          {title && <h4 className="mt-3 mb-4">{title}</h4>}
          {children}
        </FormWrapper>
        <LogoWrapper className="d-flex align-items-center justify-content-center">
          <div className={cx(['logo'])}>Spaceful</div>
        </LogoWrapper>
      </Container>
    </Page>
  );
}

function Login({ onClick }) {
  return (
    <AuthForm title="Login">
      <div className="p-5">
        <button
          type="button"
          className="mb-3 btn btn-secondary btn-lg btn-block"
          onClick={onClick}
        >
          Login
        </button>
      </div>
    </AuthForm>
  );
}

module.exports = Login;
