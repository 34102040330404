const BaseTab = require('../base_tab');
const PdfEntryAmenitiesTemplateContainer = require('../pdf_entry_amenities_template/container');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');

class EntryAmenitiesView extends React.Component {
  static initClass() {
    this.propTypes = {
      entryId: PropTypes.string.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      track: PropTypes.func.isRequired,
    };
  }

  componentWillMount() {
    return this.props.track('Views Listing:Amenities');
  }

  render() {
    return (
      <BaseTab
        inputs={[
          {
            disableEnterNavigation: true,
            options: { entryId: this.props.entryId },
            path: [
              'content',
              'entries',
              this.props.entryId,
              'pages',
              'amenities',
            ],
            type: 'amenities',
          },
        ]}
        next={{ label: 'Add Listing', style: 'add' }}
        onNext={() => {
          this.props.track('Selects to Add a Listing', {
            additionalProperties: { source: 'next button' },
            entryId: this.props.entryId,
          });
          return this.props.setCurrentModal({ key: 'new-entry' });
        }}
        pdfTemplateComponentFactory={() => {
          return (
            <PdfEntryAmenitiesTemplateContainer
              entryId={this.props.entryId}
              isEditing={true}
              readyRegistry={new ReadyRegistry()} />
          );
        }} />
    );
  }
}
EntryAmenitiesView.initClass();

module.exports = EntryAmenitiesView;
