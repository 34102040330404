const _ = require('lodash');
const { arrayMove } = require('react-sortable-hoc');
const SortableList = require('./sortable_list');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');
const { useState, useRef, useEffect } = require('react');

const BrokerItem = ({ anchorProps, item, setCurrentModal, currentView }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const actionMenuRef = useRef(null);
  useEffect(() => {
    if (actionMenuRef.current) {
      actionMenuRef.current.style.display = menuVisible ? 'block' : 'none';
    }
  }, [actionMenuRef, menuVisible]);
  return (
    <>
      <a {...anchorProps}>{item.label}</a>
      <a
        onClick={(e) => {
          const { clientY, clientX } = e;
          actionMenuRef.current.style.top = `${clientY + 6}px`;
          actionMenuRef.current.style.left = `${clientX - 162}px`;
          setMenuVisible(true);
        }}
        className={cx(['triple-dots'])}
      >
        <FontAwesomeIcon name="ellipsis-vertical" />
      </a>
      <div
        ref={actionMenuRef}
        className={cx(['action-menu', 'broker-action-menu'])}
        onMouseLeave={() => {
          setMenuVisible(false);
        }}
      >
        <a
          onClick={function () {
            setMenuVisible(false);
            return setCurrentModal({
              key: 'delete-broker',
              page: 'broker',
              jumpToCoverView: currentView.key === 'broker',
            });
          }}
          className={cx(['action-menu-parent'])}
        >
          <div className={cx(['action-menu-icon'])}>
            <FontAwesomeIcon name="trash-can" />
          </div>
          <div className={cx(['action-menu-text'])}>
            <span>Delete</span>
          </div>
        </a>
      </div>
    </>
  );
};

BrokerItem.propTypes = {
  anchorProps: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  setCurrentModal: PropTypes.func.isRequired,
};

const MapItem = ({
  anchorProps,
  item,
  createAdditionalMap,
  deleteAdditionalMap,
  hideAdditionalMap,
  unhideAdditionalMap,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const actionMenuRef = useRef(null);
  useEffect(() => {
    if (actionMenuRef.current) {
      actionMenuRef.current.style.display = menuVisible ? 'block' : 'none';
    }
  }, [actionMenuRef, menuVisible]);
  return (
    <>
      <a {...anchorProps}>{item.label}</a>
      <a
        onClick={(e) => {
          const { clientY, clientX } = e;
          actionMenuRef.current.style.top = `${clientY + 6}px`;
          actionMenuRef.current.style.left = `${clientX - 162}px`;
          setMenuVisible(true);
        }}
        className={cx(['triple-dots'])}
      >
        <FontAwesomeIcon name="ellipsis-vertical" />
      </a>
      <div
        ref={actionMenuRef}
        className={cx(['action-menu', 'map-action-menu'])}
        onMouseLeave={() => {
          setMenuVisible(false);
        }}
      >
        <a
          onClick={function () {
            setMenuVisible(false);
            return createAdditionalMap();
          }}
          className={cx(['action-menu-parent'])}
        >
          <div className={cx(['action-menu-icon'])}>
            <FontAwesomeIcon name="plus" />
          </div>
          <div className={cx(['action-menu-text'])}>
            <span>Add Another</span>
          </div>
        </a>
        {item.value.mapOptionsKey && item.value.mapOptionsKey !== 'mapOptions' && (
          <>
            {item.hidden ? (
              <a
                onClick={function () {
                  setMenuVisible(false);
                  return unhideAdditionalMap(item.value.mapOptionsKey);
                }}
                className={cx(['action-menu-parent'])}
              >
                <div className={cx(['action-menu-icon'])}>
                  <FontAwesomeIcon name="eye" />
                </div>
                <div className={cx(['action-menu-text'])}>
                  <span>Show this map</span>
                </div>
              </a>
            ) : (
              <a
                onClick={function () {
                  setMenuVisible(false);
                  return hideAdditionalMap(item.value.mapOptionsKey);
                }}
                className={cx(['action-menu-parent'])}
              >
                <div className={cx(['action-menu-icon'])}>
                  <FontAwesomeIcon name="eye-slash" />
                </div>
                <div className={cx(['action-menu-text'])}>
                  <span>Hide this map</span>
                </div>
              </a>
            )}
            <a
              onClick={function () {
                setMenuVisible(false);
                return deleteAdditionalMap(item.value.mapOptionsKey);
              }}
              className={cx(['action-menu-parent'])}
            >
              <div className={cx(['action-menu-icon'])}>
                <FontAwesomeIcon name="minus" />
              </div>
              <div className={cx(['action-menu-text'])}>
                <span>Delete this map</span>
              </div>
            </a>
          </>
        )}
      </div>
    </>
  );
};

MapItem.propTypes = {
  anchorProps: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  createAdditionalMap: PropTypes.func.isRequired,
  deleteAdditionalMap: PropTypes.func.isRequired,
  hideAdditionalMap: PropTypes.func.isRequired,
  unhideAdditionalMap: PropTypes.func.isRequired,
};

class ViewNavigation extends React.Component {
  static initClass() {
    this.propTypes = {
      contactInfoIds: PropTypes.arrayOf(PropTypes.string),
      brokerInfoIds: PropTypes.arrayOf(PropTypes.string),
      copyEntry: PropTypes.func.isRequired,
      toggleEntryVisibility: PropTypes.func.isRequired,
      currentField: PropTypes.object.isRequired,
      currentTab: PropTypes.object.isRequired,
      currentView: PropTypes.object.isRequired,
      entries: PropTypes.arrayOf(
        PropTypes.shape({
          address: PropTypes.string,
          entryId: PropTypes.string.isRequired,
          hidden: PropTypes.bool,
        })
      ),
      reorderEntries: PropTypes.func.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      setCurrentView: PropTypes.func.isRequired,
      settings: PropTypes.object.isRequired,
      track: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._isItemActive = this._isItemActive.bind(this);
    this._navigateToSettings = this._navigateToSettings.bind(this);
    this._navigateToPrivateNotes = this._navigateToPrivateNotes.bind(this);
    this._openCollaborateModal = this._openCollaborateModal.bind(this);
    this._onEntryReorder = this._onEntryReorder.bind(this);
    this._openAddListing = this._openAddListing.bind(this);
    this._createNotesEntry = this._createNotesEntry.bind(this);
    // this._openAddImages = this._openAddImages.bind(this);
    // this._openAddFloorplan = this._openAddFloorplan.bind(this);
    this._openSendToClient = this._openSendToClient.bind(this);
    this._renderDefaultItem = this._renderDefaultItem.bind(this);
    this._toggleExpandCreationOptions = this._toggleExpandCreationOptions.bind(this);
    this.state = {
      entries: props.entries,
      expandCreationOptions: props.expandCreationOptions || false,
    };
  }

  componentWillReceiveProps(nextProps) {
    return this.setState({
      entries: nextProps.entries,
      expandCreationOptions: nextProps.expandCreationOptions,
    });
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <div className={cx(['items'])}>
          {this._renderDefaultList()}
          {this._renderEntryList()}
          <ul className={cx(['add-listing'])}>
            <li className={cx(['item'])}>
              <a
                onClick={this._toggleExpandCreationOptions}
                className={cx(['view', 'add-listing'])}
              >
                <div className={cx(['plus-wrapper'])}>
                  <FontAwesomeIcon name="plus" />
                </div>

                <div className={cx(['text'])}>Add To Tourbook</div>
              </a>
            </li>
            {this.state.expandCreationOptions && (
              <ul className={cx(['sub-items'])}>
                <li className={cx(['item'])}>
                  <a onClick={this._openAddListing} className={cx(['view', 'add-listing'])}>
                    <div className={cx(['bullet'])}>•</div>
                    <div className={cx(['add-tourbook-pages'])}>Listing</div>
                  </a>
                </li>
                <li className={cx(['item'])}>
                  <a onClick={this._createNotesEntry} className={cx(['view', 'add-listing'])}>
                    <div className={cx(['bullet'])}>•</div>
                    <div className={cx(['text', 'add-tourbook-notes'])}>Notes Page</div>
                  </a>
                </li>
                {/*
                TODO: enable these when images + floorplans are built
                <li className={cx(['item'])}>
                  <a
                    onClick={this._openAddImages}
                    className={cx(['view', 'add-listing'])}
                  >
                    <div className={cx(['bullet'])}>•</div>
                    <div className={cx(['text'])}>Images Page</div>
                  </a>
                </li>
                <li className={cx(['item'])}>
                  <a
                    onClick={this._openAddFloorplan}
                    className={cx(['view', 'add-listing'])}
                  >
                    <div className={cx(['bullet'])}>•</div>
                    <div className={cx(['text'])}>Floorplan Page</div>
                  </a>
                </li> */}
              </ul>
            )}
          </ul>
        </div>
        <div className={cx(['footer'])}>
          <a onClick={this._navigateToPrivateNotes} className={cx(['private-notes'])}>
            <span>Private Notes</span>
          </a>
          {this.props.entries.length > 0 ? (
            <a
              onClick={this._navigateToSettings}
              className={cx([
                'settings',
                `${this._isItemActive({ value: { key: 'settings' } }) ? 'active' : ''}`,
              ])}
            >
              <FontAwesomeIcon name="gear" />
              <span>Tour Book Settings</span>
            </a>
          ) : undefined}

          <div className={cx(['shadow'])} />
          <a onClick={this._openCollaborateModal} className={cx(['collaborate'])}>
            <FontAwesomeIcon name="share-nodes" />
            <span>Collaborate</span>
          </a>
          <a onClick={this._openSendToClient} className={cx(['send-to-client'])}>
            Send To Client
          </a>
        </div>
      </div>
    );
  }

  _getDefaultItems() {
    const items = [{ label: 'Cover', value: { key: 'cover' } }];
    if (this.props.brokerInfoIds.length > 0) {
      items.push({ label: 'Broker', value: { key: 'broker' } });
    }
    if (this.props.entries?.length > 0 && this.props.settings.display.tourMap) {
      for (const key of Object.keys(this.props.settings).sort()) {
        // Get label
        let label;
        if (key.startsWith('mapOptions')) {
          if (key === 'mapOptions') {
            // Default map
            const isTheOnlyMap =
              Object.keys(this.props.settings).filter((k) => k.startsWith('mapOptions'))
                .length === 1;
            label = isTheOnlyMap ? 'Map' : 'Map (default)';
          } else {
            // Additional Map
            label = this.props.settings[key].name;
          }

          items.push({
            deletable: true,
            hidden: this.props.settings[key].hidden,
            label: label,
            value: { key: 'map', mapOptionsKey: key },
          });
        }
      }
      if (this.props.settings.display.summary) {
        items.push({
          deletable: true,
          label: 'Summary',
          value: { key: 'summary' },
        });
      }
    }
    return items;
  }

  _getEntryItems() {
    return this.state.entries.map(({ address, notesHeader, entryId, key, hidden }) => {
      const keyToLabel = {
        'tourbook-notes': notesHeader,
      };
      let label = '(No Address)';
      label = keyToLabel[key] || address; // regular listing entries don't have a key, so use their `address`
      return {
        label,
        value: { entryId, key },
        hidden,
      };
    });
  }

  _isItemActive(item) {
    return _.isEqual(item.value, this.props.currentView);
  }

  _isOnFinalContactTab() {
    return (
      this.props.currentTab.key === 'add_contact' ||
      (this.props.contactInfoIds.length === 2 &&
        this.props.currentTab.key === `contact_info_${this.props.contactInfoIds[1]}`)
    );
  }

  _navigateToPrivateNotes() {
    return this.props.setCurrentView({ key: 'private-notes' });
  }

  _navigateToSettings() {
    return this.props.setCurrentView({ key: 'settings' });
  }

  _openCollaborateModal() {
    return this.props.setCollaborateModalState().then(() => {
      return this.props.setCurrentModal({ key: 'collaborate' });
    });
  }

  _onEntryReorder({ newIndex, oldIndex }) {
    const reorderEntries = arrayMove(this.state.entries, oldIndex, newIndex);
    this.setState({ entries: reorderEntries });
    return this.props.reorderEntries(_.map(reorderEntries, 'entryId'));
  }

  _openAddListing() {
    this.props.track('Selects to Add a Listing', {
      additionalProperties: { source: 'global add button' },
    });
    return this.props.setCurrentModal({ key: 'new-entry' });
  }

  _createNotesEntry() {
    this.props.track('Selects to add a Tourbook Notes page', {
      additionalProperties: { source: 'global add button' },
    });
    return this.props.createNotesEntry();
  }

  // _openAddImages() {
  //   this.props.track('Selects to Add a Tourbook Images page', {
  //     additionalProperties: { source: 'global add button' },
  //   });
  //   return this.props.setCurrentModal({ key: 'new-tourbook-images' });
  // }

  // _openAddFloorplan() {
  //   this.props.track('Selects to Add a Tourbook Floorplan page', {
  //     additionalProperties: { source: 'global add button' },
  //   });
  //   return this.props.setCurrentModal({ key: 'new-tourbook-floorplan' });
  // }

  _openSendToClient() {
    this.props.track('Opens Send to Client Overlay');
    return this.props.setCurrentModal({ key: 'send-to-client' });
  }

  _renderDefaultItem(item, index) {
    const active = this._isItemActive(item);
    const itemProps = {
      className: cx(['item', 'default', `${active ? 'active' : ''}`]),
      key: index,
    };
    const anchorProps = {
      className: cx(['view', `${active ? 'active' : ''}`, `${item.hidden ? 'strike-through' : ''}`]),
      onClick: () => this.props.setCurrentView(item.value),
    };
    let anchorElement = <a {...anchorProps}>{item.label}</a>;
    if (item?.value?.key === 'broker') {
      anchorElement = (
        <BrokerItem
          anchorProps={anchorProps}
          item={item}
          setCurrentModal={this.props.setCurrentModal}
          currentView={this.props.currentView}
        />
      );
    }
    if (item?.value?.key === 'map') {
      anchorElement = (
        <MapItem
          anchorProps={anchorProps}
          item={item}
          createAdditionalMap={this.props.createAdditionalMap}
          deleteAdditionalMap={this.props.deleteAdditionalMap}
          hideAdditionalMap={(mapOptionsKey) => this.props.toggleMapVisibility(mapOptionsKey, true)}
          unhideAdditionalMap={(mapOptionsKey) =>
            this.props.toggleMapVisibility(mapOptionsKey, false)
          }
        />
      );
    }
    return <li {...itemProps}>{anchorElement}</li>;
  }

  _renderDefaultList() {
    return <ul>{this._getDefaultItems().map(this._renderDefaultItem)}</ul>;
  }

  _renderEntryList() {
    return (
      <SortableList
        clickHandlers={{
          copy: (entryId) => {
            this.props.copyEntry(entryId);
          },
          hide: (entryId) => this.props.toggleEntryVisibility(entryId, true),
          show: (entryId) => this.props.toggleEntryVisibility(entryId, false),
          delete: (entryId) =>
            this.props.setCurrentModal({
              entryId,
              key: 'delete-page',
              page: 'entry',
            }),
          view: (entryId, type) => {
            let key = 'entry';
            if (type) {
              key = _.compact([key, type]).join('-');
            }
            return this.props.setCurrentView({ entryId, key });
          },
        }}
        isItemActive={this._isItemActive}
        items={this._getEntryItems()}
        lockAxis="y"
        lockToContainerEdges={true}
        onSortEnd={this._onEntryReorder}
        useDragHandle={true}
      />
    );
  }

  _shouldHighlightAddSection() {
    return (
      this.props.currentView.key === 'cover' &&
      this._isOnFinalContactTab() &&
      this.props.currentField.key === 'next'
    );
  }

  _toggleExpandCreationOptions() {
    this.setState({
      ...this.state,
      expandCreationOptions: !this.state.expandCreationOptions,
    });
  }
}
ViewNavigation.initClass();

module.exports = ViewNavigation;
