const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const { SortableContainer, SortableElement, SortableHandle } = require('react-sortable-hoc');
const { useEffect, useState, useRef } = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

const DragHandle = SortableHandle(() => (
  <div className={cx(['reorder-handle'])}>
    <FontAwesomeIcon name="grip-vertical" />
  </div>
));

const renderSubItems = ({ clickHandlers, entryId, subItems }) => (
  <ul className={cx(['sub-items'])}>
    {subItems.map(({ active, label, type }, index) => (
      <li key={index} className={cx(['sub-item'])}>
        <a
          className={cx([active ? 'active' : ''])}
          onClick={function () {
            return clickHandlers.view(entryId, type);
          }}
        >
          <div className={cx(['bullet'])}>•</div>
          <div>{label}</div>
        </a>
      </li>
    ))}
  </ul>
);

const SortableItem = SortableElement(function (props) {
  const { clickHandlers, isItemActive, item, handleToggle } = props;
  const {
    label,
    value: { entryId, key },
    hidden,
  } = item;
  const isEntryDataActive = isItemActive({ value: { entryId, key: 'entry' } });
  const isEntryImagesActive = isItemActive({
    value: { entryId, key: 'entry-images' },
  });
  const isEntryAmenitiesActive = isItemActive({
    value: { entryId, key: 'entry-amenities' },
  });
  const isTourbookNotesActive = isItemActive({
    value: { entryId, key: 'entry-tourbook-notes' },
  });

  const entryActive =
    isEntryDataActive || isEntryImagesActive || isEntryAmenitiesActive || isTourbookNotesActive;
  let subItems = [
    { active: isEntryDataActive, label: 'Listing Data' },
    { active: isEntryImagesActive, label: 'Images', type: 'images' },
    { active: isEntryAmenitiesActive, label: 'Amenities', type: 'amenities' },
  ];
  let growOnClickClass = 'grow-on-click-3';
  // TODO: add a condition for images, and another for floorplans
  if (isTourbookNotesActive) {
    subItems = [];
    growOnClickClass = '';
  }
  return (
    <li className={cx(['item', growOnClickClass, 'entry', `${entryActive ? 'active' : ''}`])}>
      <div className={cx(['primary', hidden ? 'strike-through' : ''])}>
        <DragHandle />
        {item.hidden ? (
          <a
            onClick={function () {
              return clickHandlers.view(entryId, key);
            }}
            className={cx(['hidden', `view`])}
          >
            {label}
          </a>
        ) : (
          <a
            onClick={function () {
              return clickHandlers.view(entryId, key);
            }}
            className={cx([`view`, `${entryActive ? 'active' : ''}`])}
          >
            {label}
          </a>
        )}

        <a
          onClick={(e) => {
            handleToggle(e, entryId);
          }}
          className={cx(['triple-dots'])}
        >
          <FontAwesomeIcon name="ellipsis-vertical" />
        </a>
      </div>
      {renderSubItems({ clickHandlers, entryId, subItems })}
    </li>
  );
});

const SortableList = SortableContainer(function (props) {
  const { clickHandlers, isItemActive, items } = props;
  const [menuVisible, setMenuVisible] = useState(false);
  const actionMenuRef = useRef(null);
  const [entryId, setEntryId] = useState(null);
  useEffect(() => {
    if (actionMenuRef.current) {
      actionMenuRef.current.style.display = menuVisible ? 'block' : 'none';
    }
  }, [actionMenuRef, menuVisible]);

  const handleToggle = (event, _entryId) => {
    const { clientY } = event;
    actionMenuRef.current.style.top = `${clientY - 52}px`;
    actionMenuRef.current.id = _entryId;
    setMenuVisible(true);
    setEntryId(_entryId);
  };

  const _isHidden = (entryId) => {
    const entry = _.find(items, (obj) => {
      return obj.value.entryId == entryId;
    });
    return entry?.hidden;
  };

  return (
    <>
      <ul>
        {items.map((item, index) => (
          <SortableItem
            clickHandlers={clickHandlers}
            index={index}
            isItemActive={isItemActive}
            handleToggle={handleToggle}
            item={item}
            key={index}
          />
        ))}
      </ul>
      <div
        ref={actionMenuRef}
        className={cx(['action-menu'])}
        onMouseLeave={() => {
          setMenuVisible(false);
        }}
      >
        <a
          onClick={function () {
            setMenuVisible(false);
            return clickHandlers.copy(entryId);
          }}
          className={cx(['action-copy'])}
        >
          <div className={cx(['action-menu-icon'])}>
            <FontAwesomeIcon name="copy" family="sharp" />
          </div>
          <div className={cx(['action-menu-text'])}>
            <span>Duplicate</span>
          </div>
        </a>

        {_isHidden(actionMenuRef.current?.id) ? (
          <a
            onClick={function () {
              setMenuVisible(false);
              return clickHandlers.show(entryId);
            }}
            className={cx(['action-show'])}
          >
            <div className={cx(['action-menu-icon'])}>
              <FontAwesomeIcon name="eye" />
            </div>
            <div className={cx(['action-menu-text'])}>
              <span>Show</span>
            </div>
          </a>
        ) : (
          <a
            onClick={function () {
              setMenuVisible(false);
              return clickHandlers.hide(entryId);
            }}
            className={cx(['action-show'])}
          >
            <div className={cx(['action-menu-icon'])}>
              <span className="eye-slash">
                <FontAwesomeIcon name="eye-slash" />
              </span>
            </div>
            <div className={cx(['action-menu-text'])}>
              <span>Hide</span>
            </div>
          </a>
        )}

        <a
          onClick={function () {
            setMenuVisible(false);
            return clickHandlers.delete(entryId);
          }}
          className={cx(['action-delete'])}
        >
          <div className={cx(['action-menu-icon'])}>
            <FontAwesomeIcon name="trash-can" />
          </div>
          <div className={cx(['action-menu-text'])}>
            <span>Delete</span>
          </div>
        </a>
      </div>
    </>
  );
});

module.exports = SortableList;
