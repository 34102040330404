const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PdfPageTypeInput = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentField',
  'setPdfPageDefaultLayout',
  'removeEntryImagePage',
]);

module.exports = build({
  component: PdfPageTypeInput,
  mapDispatchToProps,
  serviceNames: ['keyboardShortcutService'],
});
