const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const ProfilePage = require('./');

const mapDispatchToProps = _.pick(actions.profilePage, [
    'setData',
    'setDataValue',
    'subscribeToData',
    'updateUserProfile',
    'track',
]);

const mapStateToProps = function (state) {
    const initializeStatuses = _.chain([
        'subscribeToData',
    ])
        .map((key) => [key, state.promiseInspections[`profilePage/${key}`]])
        .fromPairs()
        .value();
    return {
        initializeStatuses,
        userProfile: state.profilePage.userProfile,
    };
};

module.exports = build({
    component: ProfilePage,
    mapDispatchToProps,
    mapStateToProps,
});