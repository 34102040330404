const _ = require('lodash');
const AutoSuggest = require('react-autosuggest');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const escape = require('escape-string-regexp');
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class AdditionalFieldsInput extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      addAdditionalFields: PropTypes.func.isRequired,
      fieldDefinitions: PropTypes.object.isRequired,
      fields: PropTypes.object.isRequired,
      options: PropTypes.shape({
        entryId: PropTypes.string.isRequired,
        section: PropTypes.string.isRequired,
      }).isRequired,
      previousFields: PropTypes.object.isRequired,
      scrollService: PropTypes.object.isRequired,
      tourbookFieldsService: PropTypes.object.isRequired,
      track: PropTypes.func.isRequired,
      trackManualAddField: PropTypes.func.isRequired
    };
  }

  componentDidMount() {
    return this._updateFocus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      return this._updateFocus();
    }
  }

  constructor(props) {
    super(props);
    this._import = this._import.bind(this);
    this._onSearchChange = this._onSearchChange.bind(this);
    this._renderField = this._renderField.bind(this);
    this._updateSuggestions = this._updateSuggestions.bind(this);
    this.state = { searchValue: '', suggestions: [] };
  }

  render() {
    return (
      <div
        className={cx(['input-container', this.props.active ? 'active' : ''])}
      >
        <FontAwesomeIcon name="magnifying-glass" />
        <AutoSuggest
          getSuggestionValue={function () {
            return '';
          }}
          highlightFirstSuggestion={true}
          inputProps={{
            onChange: this._onSearchChange,
            placeholder: 'Type to add a custom field',
            value: this.state.searchValue,
          }}
          onSuggestionSelected={this._import}
          onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
          onSuggestionsFetchRequested={this._updateSuggestions}
          ref={(node) => {
            return (this.autosuggest = node);
          }}
          renderSuggestion={this._renderField}
          suggestions={this.state.suggestions}
        />
      </div>
    );
  }

  _getFieldLabel(field, query) {
    const label = this.props.tourbookFieldsService.getFieldLabel(
      field,
      this.props.fieldDefinitions
    );
    const matched = label.match(new RegExp(escape(query), 'i'));
    const split = label.split(matched);
    const front = split[0];
    const back = _.chain(split).drop(1).join(matched).value();
    return (
      <div className={cx(['field-label'])}>
        <span>{front}</span>
        <b>{matched}</b>
        <span>{back}</span>
      </div>
    );
  }

  _getFieldValue(field) {
    return (
      <div className={cx(['field-value'])}>
        {this.props.tourbookFieldsService.getSuggestedFieldValue(field)}
      </div>
    );
  }

  _getNewKey(value) {
    // TODO if users manually want to add new multiSpaceKey, convert the value (multiSpaceLabel) to multiSpaceKey
    const key = _.camelCase(value);
    let count = 0;
    while (this.props.fields[this.props.options.section][key + count]) {
      count += 1;
    }
    return key + count;
  }

  _getSuggestedFields() {
    return this.props.tourbookFieldsService.getSuggestedFields({
      fieldDefinitions: this.props.fieldDefinitions,
      fields: this.props.fields,
      previousFields: this.props.previousFields,
      section: this.props.options.section,
    });
  }

  _import(event, suggestion) {
    event.stopPropagation();
    const field =
      suggestion.method != null ? suggestion.suggestion : suggestion;
    this.props.trackManualAddField(field);
    return this.props.addAdditionalFields({
      entryId: this.props.options.entryId,
      fields: [field],
      section: this.props.options.section,
    });
  }

  _onSearchChange(event, { method, newValue }) {
    if (method === 'type') {
      this.setState({ searchValue: newValue });
    }
    return this.props.scrollService.scrollToElement({
      containerId: 'input-set-scrollable-parent',
      element: this.autosuggest.input,
    });
  }

  _renderField(field, { query }) {
    const fieldProps = {
      className: cx([
        'suggestions-field',
        `${field.newField ? 'new-field' : ''}`,
      ]),
      key: field.key,
      onClick: (event) => this._import(event, field),
    };
    return (
      <div {...fieldProps}>
        {this._getFieldLabel(field, query)}
        {this._getFieldValue(field)}
      </div>
    );
  }

  _updateFocus() {
    if (this.props.active) {
      return this.autosuggest.input.focus();
    } else {
      this.setState({ searchValue: '' });
      return this.autosuggest.input.blur();
    }
  }

  _updateSuggestions({ value }) {
    if (value === '') {
      return this.setState({ suggestions: [] });
    }
    const suggestions = _.chain(this._getSuggestedFields())
      .filter((field) => {
        const label = this.props.tourbookFieldsService.getFieldLabel(
          field,
          this.props.fieldDefinitions
        );
        return label.match(new RegExp(escape(value), 'i'));
      })
      .take(5)
      .concat({ key: this._getNewKey(value), label: value, newField: true })
      .value();
    return this.setState({ suggestions });
  }
}
AdditionalFieldsInput.initClass();

module.exports = AdditionalFieldsInput;
