const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const DataSection = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'navigateToCurrentEntryField',
]);

const mapStateToProps = function (state, ownProps) {
  const { currentField, data, fieldDefinitions } = state.tourbookEditorPage;
  const settings = state.tourbookEditorPage.data.content.settings;
  return {
    currentField,
    entry: data.content.entries[ownProps.entryId],
    fieldDefinitions,
    style: { fontSize: settings.fontSize ? `${settings.fontSize}px` : undefined },
    lineSpacing: settings.lineSpacing ? { lineHeight: 1.2 } : {},
  };
};

const propTypes = {
  entryId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  section: PropTypes.string.isRequired,
};

const serviceNames = ['tourbookEntryFieldsService', 'tourbookFieldsService'];

module.exports = build({
  component: DataSection,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames,
});
