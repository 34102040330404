const importedStyles = require('./index.styl');
const CollapsibleQuestion = require('@components/shared/collapsible_question');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const { default: Slider } = require('rc-slider');
const {
  LOGO_DEFAULT_WIDTH,
  LOGO_MIN_WIDTH,
  LOGO_MAX_WIDTH,
  LOGO_WIDTH_INCREMENT,
  NEWMARK_LOGO_MIN_WIDTH,
} = require('@const');

class LogoInput extends React.Component {
  constructor(props) {
    super(props);
    this._openChangeImageModal = this._openChangeImageModal.bind(this);
  }

  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      imageUrlService: PropTypes.object.isRequired,
      navigateForward: PropTypes.func.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      widthInVw: PropTypes.number,
      toggleValue: PropTypes.bool,
      updateToggle: PropTypes.func.isRequired,
      value: PropTypes.object,
    };
  }

  render() {
    return (
      <div className={cx(['root'])}>
        {this._renderToggle()}
        {this.props.toggleValue ? this._renderImage() : undefined}
      </div>
    );
  }

  _openChangeImageModal() {
    return this.props.setCurrentModal({
      key: 'change-image',
      path: this.props.path,
      type: 'logo',
    });
  }

  _renderImage() {
    const suggestedMinWidthInVw = (100 * NEWMARK_LOGO_MIN_WIDTH) / window.innerWidth;

    const image = this.props.value || {};
    return (
      <div className={cx(['logo-details'])}>
        <div
          onClick={this._openChangeImageModal}
          className={cx(['image-wrapper'])}
        >
          <img
            src={this.props.imageUrlService.getStaticUrlForImage(image, {
              logo: true,
            })}
          />
          <div className={cx(['link'])}>Change Image</div>
        </div>
        <div className={cx(['sizing-wrapper'])}>
          {this.props.widthInVw < suggestedMinWidthInVw && (
            <div className={cx(['sizing-warning'])}>
              Warning: Logo is smaller than the minimum allowed by Newmark's
              brand guidelines
            </div>
          )}
          <div>
            <div className={cx(['sizing-text'])}>Size</div>
            <Slider
              value={
                this.props.widthInVw || LOGO_DEFAULT_WIDTH
              }
              min={LOGO_MIN_WIDTH}
              max={LOGO_MAX_WIDTH}
              step={LOGO_WIDTH_INCREMENT}
              onChange={(value) => {
                this.props.updateSize(value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  _renderToggle() {
    return (
      <CollapsibleQuestion
        active={this.props.active}
        answers={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        onChange={(value) => {
          this.props.updateToggle(value);
          this.props.navigateForward();
          if (value) {
            return this.props.setCurrentModal({
              key: 'change-image',
              path: this.props.path,
              type: 'logo',
            });
          }
        }}
        question="Would you like to add a logo?"
        value={this.props.toggleValue}
      />
    );
  }
}
LogoInput.initClass();

module.exports = LogoInput;
