const _ = require('lodash');
const BaseTab = require('../base_tab');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const PdfSummaryTemplateContainer = require('../pdf_summary_template/container');
const ReadyRegistry = require('@utils/ready_registry');
const SummaryInputsBuilder = require('./input_builders/summary_inputs_builder');
const InputRowContainer = require('@components/shared/input_row/container');
const PdfSummaryTemplateVerticalContainer = require('../pdf_summary_template_vertical/container');
const EmptyFieldReplacements = require('./empty_field_replacements/container');
const SelectedFieldsContainer = require('./selected_fields/container');

class SummaryView extends React.Component {
  constructor(props) {
    super(props);
    this._getOffset = this._getOffset.bind(this);
  }

  static initClass() {
    this.prototype.entriesPerPage = 4;
    this.propTypes = {
      currentField: PropTypes.object,
      entries: PropTypes.object.isRequired,
      setCurrentField: PropTypes.func.isRequired,
      track: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
      settings: PropTypes.object.isRequired,
    };
  }

  addDefaultValues() {
    if (!this.props.settings.display?.summaryVertical) {
      this.props.update({
        path: ['content', 'settings', 'display', 'summaryVertical'],
        value: false,
      });
    }
  }
  componentDidMount() {
    this.addDefaultValues();
    return this.props.track('Views Summary');
  }

  componentDidUpdate(prevProps) {
    return this._updateCurrentField(prevProps);
  }

  render() {
    const summaryVertical = this.props.settings.display.summaryVertical;
    return (
      <div className={cx(['root'])}>
        <BaseTab
          inputs={SummaryInputsBuilder({
            entriesPerPage: this.entriesPerPage,
            entryCount: _.size(this.props.entries),
          })}
          pdfTemplateComponentFactory={function (props) {
            return summaryVertical ? (
              <PdfSummaryTemplateVerticalContainer {...props} />
            ) : (
              <PdfSummaryTemplateContainer {...props} />
            );
          }}
          pdfTemplateProps={{
            entryIds: this._getEntryIds(),
            isEditing: true,
            offset: this._getOffset(),
            readyRegistry: new ReadyRegistry(),
          }}
          displaySettings={this.props.settings.display}
          update={this.props.update}
          summaryFields={this.props.settings.summaryFields}
          postInputsFactory={this._renderHint}
        />
      </div>
    );
  }

  _getEntryIds() {
    return _.chain(this.props.entries)
      .map(({ order }, entryId) => ({
        entryId,
        order,
      }))
      .sortBy('order')
      .map('entryId')
      .drop(this._getOffset())
      .take(4)
      .value();
  }

  _getOffset() {
    return (_.toInteger(this.props.currentField.key?.split('summary-')[1]) || 1) - 1;
  }

  _renderHint() {
    return (
      <div className={cx(['hint-container'])}>
        <div className={cx(['divider'])} />
        <div className={cx(['hint'])}>
          {`\
  This is an automatically generated summary for the PDF tour book.
  To edit any data, you must change it on the listing itself.\
  `}
        </div>
        <InputRowContainer
          active={false}
          shouldAddColonToLabel={true}
          shouldDisplayHint={false}
          input={{
            label: 'Display Vertical',
            path: ['content', 'settings', 'display', 'summaryVertical'],
            type: 'toggle',
            disableEnterNavigation: true,
          }}
        />
        <InputRowContainer
          active={false}
          shouldAddColonToLabel={true}
          shouldDisplayHint={false}
          input={{
            label: 'Note Font Size',
            path: ['content', 'settings', 'summary', 'note', 'fontSize'],
            type: 'slider',
          }}
        />
        <EmptyFieldReplacements />
        <SelectedFieldsContainer />
      </div>
    );
  }

  _updateCurrentField(prevProps) {
    const prevEntryCount = _.size(prevProps.entries);
    if (prevEntryCount === _.size(this.props.entries)) {
      return;
    }
    const index = _.toInteger(prevProps.currentField.key?.split('summary-')[1]);
    if (_.ceil(prevEntryCount / this.entriesPerPage) >= index) {
      return;
    }
    if (prevEntryCount <= this.entriesPerPage) {
      return this.props.setCurrentField(null);
    } else {
      return this.props.setCurrentField({ key: 'summary-1' });
    }
  }
}
SummaryView.initClass();

module.exports = SummaryView;
