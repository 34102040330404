const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);

module.exports = ({ children }) => (
  <div className={cx(['popover'])}>
    <div className={cx(['popover-diamond'])} />
    <div className={cx(['popover-content'])}>{children}</div>
  </div>
);
