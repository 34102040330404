const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const { locateService } = require('@services/service_locator');
const PdfEntryWrapperTemplateContainer = require('../pdf_entry_wrapper_template/container');
const PdfEntryImageDropTarget = require('../pdf_entry_image_drop_target');
const PdfZeroStateTemplate = require('../pdf_zero_state_template');
const AdditionalNoteSectionContainer = require('./components/additional_notes_section/container');
const store = require('@store');

class PdfEntryImagesTemplate extends React.Component {
  static initClass() {
    this.propTypes = {
      entryId: PropTypes.string.isRequired,
      entryLocation: PropTypes.object.isRequired,
      fieldDefinitions: PropTypes.object,
      isDraggingImage: PropTypes.bool,
      isEditing: PropTypes.bool,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
      page: PropTypes.object,
      pagePath: PropTypes.array.isRequired,
      setIsDraggingImage: PropTypes.func.isRequired,
      trackDragImageToPdf: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    };
  }

  componentWillMount() {
    if (this.props.entryId) {
      this.setComponentReady = this.props.readyRegistry.register(`PdfEntryImagesTemplate-${this.props.entryId}`);
    }
  }

  componentDidMount() {
    if (this.setComponentReady) {
      this.setComponentReady(true)
    }
  }

  componentWillUnmount() {
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  constructor(props) {
    super(props);
    this._renderImage = this._renderImage.bind(this);
    this.pageNumberService = locateService('pageNumberService');
    this.tourbookFieldsService = locateService('tourbookFieldsService');
  }

  render() {
    const parentSecret = this._getParentSecret();
    if (parentSecret || this.props.page?.secret) {
      return (
        <div className={cx(['root', 'pdf-template'])}>
          <div className={cx(['hidden-preview'])}>
            <div className={cx(['hidden-preview-message'])}>
              {parentSecret
                ? 'The main note is marked private thus no additional notes will be displayed in the PDF'
                : 'This page is marked private and will not display in the PDF'}
            </div>
          </div>
        </div>
      );
    }
    if (['image', 'floor_plan'].includes(this.props.page?.type)) {
      return (
        <PdfEntryWrapperTemplateContainer {...this._getWrapperProps()}>
          {this._renderImages()}
        </PdfEntryWrapperTemplateContainer>
      );
    } else if (['additional_note'].includes(this.props.page?.type)) {
      return (
        <PdfEntryWrapperTemplateContainer {...this._getWrapperProps()}>
          {this._renderAdditionalNote()}
        </PdfEntryWrapperTemplateContainer>
      );
    } else if (this.props.isEditing) {
      return (
        <PdfZeroStateTemplate
          messages={[
            'Select what kind of page you’d like to add to your PDF tour book',
          ]}
        />
      );
    } else {
      return null;
    }
  }

  _getImageSizes() {
    switch (this.props.page?.type) {
      case 'image':
        return this.props.page?.layout.split('_');
      case 'floor_plan':
        return ['4'];
      default:
        return [];
    }
  }

  _getPageNumber() {
    return this.pageNumberService.getImagePageNumber({
      entries: this.props.entries,
      entryId: this.props.entryId,
      imagePageId: this.props.pageId,
      tourbook: this.props.tourbook,
    });
  }

  _getParentSecret() {
    const state = store.getState();
    const parentPath = this.props.pagePath
      .slice(0, 3) // path to entry
      .concat(['fields', 'notes', 'notes']);
    const parentNote = _.get(state.tourbookEditorpage?.data, parentPath);
    return parentNote?.secret;
  }

  _getWrapperProps() {
    return {
      entryId: this.props.entryId,
      isEditing: this.props.isEditing,
      pageNumber: this._getPageNumber(),
      readyRegistry: this.props.readyRegistry,
    };
  }

  _renderImage(size, index) {
    const image = this.props.page?.imagesShown?.[index];
    const imagePath = this.props.pagePath.concat(['imagesShown', index]);
    return (
      <div key={index} className={cx([`size_${size}`])}>
        <PdfEntryImageDropTarget
          canDropImages={this.props.isEditing}
          entryLocation={this.props.entryLocation}
          image={image}
          imagePath={imagePath}
          index={index}
          isDragging={this.props.isDraggingImage}
          isEditing={this.props.isEditing}
          readyRegistry={this.props.readyRegistry}
          setIsDragging={this.props.setIsDraggingImage}
          update={(value) => {
            this.props.update({ path: imagePath, value });
            if (value) {
              return this.props.trackDragImageToPdf(value);
            }
          }}
        />
      </div>
    );
  }

  _renderImages() {
    const sizes = this._getImageSizes();
    const images = sizes.map(this._renderImage);
    if (images.length > 1) {
      const pivot = Math.floor(images.length / 2);
      return (
        <div className={cx(['images-wrapper'])}>
          <div className={cx(['column'])}>{_.take(images, pivot)}</div>
          <div className={cx(['column'])}>{_.slice(images, pivot)}</div>
        </div>
      );
    } else {
      return <div className={cx(['images-wrapper'])}>{images}</div>;
    }
  }

  _renderAdditionalNote() {
    //console.log JSON.stringify @props
    return (
      <AdditionalNoteSectionContainer
        {...{
          entryId: this.props.entryId,
          isEditing: this.props.isEditing,
          pageId: this.props.pageId,
          additionalNote: this.props.page,
          additionalNoteHeader: this.props.page?.additionalNoteHeader,
          visitedAdditionalNoteTab: true,
        }}
      />
    );
  }
}
PdfEntryImagesTemplate.initClass();

module.exports = PdfEntryImagesTemplate;
