const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const { locateService } = require('@services/service_locator');
const EntrySummaryContainer = require('./components/entry_summary/container');
const PdfPageWithFooterContainer = require('../pdf_page_with_footer/container');

class PdfSummaryTemplate extends React.Component {
  static initClass() {
    this.propTypes = {
      coverFields: PropTypes.object.isRequired,
      entryIds(props) {
        if (!props.hasOwnProperty('entryIds')) {
          return new Error('entryIds is required');
        }
        const { entryIds } = props;
        if (!Array.isArray(entryIds)) {
          return new Error('entryIds: not an array');
        }
        if (entryIds.length > 4) {
          return new Error('entryIds: maximum 4 elements allowed');
        }
      },
      isEditing: PropTypes.bool,
      navigateToCoverView: PropTypes.func.isRequired,
      offset: PropTypes.number.isRequired,
      tourbook: PropTypes.object.isRequired,
    };
  }

  componentWillMount() {
    this.setComponentReady = this.props.readyRegistry.register('PdfSummaryTemplate');
  }

  componentDidMount() {
    if (this.setComponentReady) {
      this.setComponentReady(true)
    }
  }

  componentWillUnmount() {
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  constructor(props) {
    super(props);
    this.pageNumberService = locateService('pageNumberService');
    this.entryNumberService = locateService('entryNumberService');
  }

  render() {
    return (
      <div {...this._getRootProps()}>
        <PdfPageWithFooterContainer
          hidePageNumber={false}
          pageNumber={this._getSummaryPageNumber()}
          readyRegistry={this.props.readyRegistry}
        >
          <div className={cx(['summary-pdf-content'])}>
            <div {...this._getFieldProps('title', 'name')}>{this.props.coverFields.title}</div>
            <div {...this._getFieldProps('area', 'location')}>{this.props.coverFields.area}</div>
            <div className={cx(['summaries', `child-count-${this.props.entryIds.length}`])}>
              {this.props.entryIds.map((entryId, index) => {
                return (
                  <div key={index} className={cx(['summary'])}>
                    <EntrySummaryContainer
                      entryId={entryId}
                      isEditing={this.props.isEditing}
                      number={this.entryNumberService.getEntryNumber({
                        entries: this.props.tourbook.content.entries,
                        entryId,
                      })}
                      readyRegistry={this.props.readyRegistry}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </PdfPageWithFooterContainer>
      </div>
    );
  }

  _getFieldProps(fieldKey, className) {
    const fieldProps = { className: cx([className]) };
    if (this.props.isEditing) {
      fieldProps.onClick = () => {
        return this.props.navigateToCoverView({
          asfieldKey: `content-cover-fields-${fieldKey}`,
          tabKey: 'tour_info',
        });
      };
    }
    return fieldProps;
  }

  _getRootProps() {
    return {
      className: cx([
        'root',
        'pdf-template',
        'x',
        `${this.props.isEditing ? 'is-editing' : ''}`,
      ]),
    };
  }

  _getSummaryPageNumber() {
    return this.pageNumberService.getSummaryPageNumber(
      this.props.offset,
      this.props.tourbook
    );
  }
}
PdfSummaryTemplate.initClass();

module.exports = PdfSummaryTemplate;
