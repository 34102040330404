const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const { locateServices } = require('@services/service_locator');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class Modal extends React.Component {
  static initClass() {
    this.defaultProps = {
      autoFocus: false,
      onClose: _.noop,
      verticallyCentered: false,
    };

    this.propTypes = {
      autoFocus: PropTypes.bool,
      keyboardShortcuts: PropTypes.object,
      onClose: PropTypes.func.isRequired,
      verticallyCentered: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    ({
      keyboardShortcutService: this.keyboardShortcutService,
      scrollService: this.scrollService,
    } = locateServices(['keyboardShortcutService', 'scrollService']));
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      return this.content.focus();
    }
  }

  componentWillMount() {
    this.scrollService.toggleBodyScroll(false);
    const keyboardShortcuts = _.assign(
      { 27: this.props.onClose },
      this.props.keyboardShortcuts
    );
    return (this.restoreShortcutsFn =
      this.keyboardShortcutService.overwrite(keyboardShortcuts));
  }

  componentWillUnmount() {
    this.scrollService.toggleBodyScroll(true);
    return typeof this.restoreShortcutsFn === 'function'
      ? this.restoreShortcutsFn()
      : undefined;
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <a onClick={() => this.props.onClose()} className={cx(['close'])}>
          <FontAwesomeIcon name="xmark" size="2x" />
        </a>
        <div {...this._getContentProps()}>
          {this.props.children}
        </div>
      </div>
    );
  }

  _getContentProps() {
    return {
      className: cx(['content', `${this.props.verticallyCentered ? 'vertically-centered' : ''
        }`]),
      ref: (node) => {
        return (this.content = node);
      },
      tabIndex: -1,
    };
  }
}
Modal.initClass(); // necessary so this div can be focused

module.exports = Modal;
