const getInputs = function (param) {
  if (param == null) {
    param = {};
  }
  const { contactInfoId, contactInfoType, tourbookFields, forBrokerInfo } =
    param;

  const contactInfoPath = forBrokerInfo
    ? ['content', 'broker', 'brokerInfos', contactInfoId]
    : ['content', 'cover', 'contactInfos', contactInfoId];
  const firstInputId = contactInfoPath
    .concat(tourbookFields.cover.roleFields[0].key)
    .join('-');
  let inputs = [
    {
      disableEnterNavigation: true,
      options: {
        contactInfoId,
        isNew: !contactInfoType,
        nextField: [
          { id: firstInputId, value: 'broker' },
          { id: firstInputId, value: 'client' },
          { id: 'next', value: 'skipped' },
        ],
      },
      path: contactInfoPath.concat('type'),
      spacedQuestion: true,
      type: 'contact_info_type',
    },
  ];
  if (['broker', 'client'].includes(contactInfoType)) {
    const fieldInputs = getFieldInputs(contactInfoPath, tourbookFields);
    const logoInput = getLogoInput(contactInfoPath);
    inputs = inputs.concat(fieldInputs, logoInput);
  }
  return inputs;
};

var getFieldInputs = (contactInfoPath, tourbookFields) =>
  tourbookFields.cover.roleFields.map((config) => ({
    label: config.label,
    path: contactInfoPath.concat(config.key),
    type: config.type,
  }));

var getLogoInput = function (contactInfoPath) {
  const logoPath = contactInfoPath.concat('logo');
  return {
    disableEnterNavigation: true,
    imageOptions: { height: '116px', width: '260px' },
    path: logoPath,
    togglePath: contactInfoPath.concat('isLogoDisplayed'),
    widthPath: contactInfoPath.concat('widthInVw'),
    type: 'logo',
  };
};

module.exports = getInputs;
