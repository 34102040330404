const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const RecommendedFieldsSection = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentModal',
  'track',
]);

const mapStateToProps = function (state, ownProps) {
  const entry =
    state.tourbookEditorPage.data.content.entries[ownProps.options.entryId];
  return {
    fieldDefinitions: state.tourbookEditorPage.fieldDefinitions || {},
    fields: entry.fields,
    previousFields: state.tourbookEditorPage.previousFields || {},
  };
};

const propTypes = {
  options: PropTypes.shape({
    entryId: PropTypes.string.isRequired,
  }).isRequired,
};

const serviceNames = ['tourbookFieldsService'];

module.exports = build({
  component: RecommendedFieldsSection,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames,
});
