/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require('lodash');
const CloudinaryService = require('./cloudinary_service');
const { stubService } = require('./service_locator');

const runExamples = (examples, fn) =>
  examples.forEach((example) =>
    it(example.description, function () {
      return fn.call(this, example);
    })
  );

describe('CloudinaryService', function () {
  beforeEach(function () {
    this.cloudinaryConfig = {
      defaultCloud: 'default',
      listingCloud: 'listing',
    };
    this.webtaskConfig = {
      url: 'webtask.url',
    };
    this.axios = sinon.stub();
    this.analyticsService = stubService('analyticsService');

    return (this.cloudinaryService = new CloudinaryService({
      ENV: {
        cloudinaryConfig: this.cloudinaryConfig,
        webtaskConfig: this.webtaskConfig,
      },
      axios: this.axios,
    }));
  });

  describe('getImageUrl', function () {
    it('uses the images rotation', function () {
      const url = this.cloudinaryService.getImageUrl({
        cloudinaryPublicId: 'image1',
        rotation: 90,
      });
      return expect(url).to.eql(
        'https://res.cloudinary.com/default/image/upload/t_ptplisting_90/image1'
      );
    });

    return it('defaults rotation to zero', function () {
      const url = this.cloudinaryService.getImageUrl({
        cloudinaryPublicId: 'image1',
      });
      return expect(url).to.eql(
        'https://res.cloudinary.com/default/image/upload/t_ptplisting_0/image1'
      );
    });
  });

  describe('getLogoUrl', function () {
    it('uses the images rotation', function () {
      const url = this.cloudinaryService.getLogoUrl({
        cloudinaryPublicId: 'image1',
        rotation: 90,
      });
      return expect(url).to.eql(
        'https://res.cloudinary.com/default/image/upload/t_ptplogo_90/image1'
      );
    });

    return it('defaults rotation to zero', function () {
      const url = this.cloudinaryService.getLogoUrl({
        cloudinaryPublicId: 'image1',
      });
      return expect(url).to.eql(
        'https://res.cloudinary.com/default/image/upload/t_ptplogo_0/image1'
      );
    });
  });

  describe('getThumbnailUrl', () =>
    it('uses the proper transform', function () {
      const url = this.cloudinaryService.getThumbnailUrl({
        cloudinaryPublicId: 'image1',
      });
      return expect(url).to.eql(
        'https://res.cloudinary.com/default/image/upload/t_ptpthumbnail/image1'
      );
    }));

  describe('getUrl', () =>
    runExamples(
      [
        {
          description: 'without input returns a blank url',
          output: '',
        },
        {
          description: 'without a cloudinary ID returns a blank url',
          output: '',
        },
        {
          description: 'pdf without transform',
          input: [{ cloudinaryPage: 1, cloudinaryPublicId: 'image1' }],
          outputPath: 'default/image/upload/pg_1/image1',
        },
        {
          description: 'pdf with transform',
          input: [
            { cloudinaryPage: 1, cloudinaryPublicId: 'image1' },
            't_ptplisting_0',
          ],
          outputPath: 'default/image/upload/pg_1/t_ptplisting_0/image1',
        },
        {
          description: 'with transform',
          input: [{ cloudinaryPublicId: 'image1' }, 't_ptplisting_0'],
          outputPath: 'default/image/upload/t_ptplisting_0/image1',
        },
        {
          description: 'without transform',
          input: [{ cloudinaryPublicId: 'image1' }],
          outputPath: 'default/image/upload/image1',
        },
        {
          description: 'from cs',
          input: [{ cloudinaryPublicId: 'image1', cs: true }],
          outputPath: 'listing/image/upload/image1',
        },
      ],
      function ({ input, output, outputPath }) {
        if (outputPath) {
          output = `https://res.cloudinary.com/${outputPath}`;
        }
        return expect(
          this.cloudinaryService.getUrl(...Array.from(input || []))
        ).to.eql(output);
      }
    ));

  describe('equals', () =>
    describe('with a non pdf-page', () =>
      runExamples(
        [
          {
            description: 'non-pdf/non-pdf',
            input: {
              imageA: { cloudinaryPublicId: 'image1', type: 'image' },
              imageB: { cloudinaryPublicId: 'image2', type: 'image' },
            },
            output: false,
          },
          {
            description: 'non-pdf/pdf',
            input: {
              imageA: { cloudinaryPublicId: 'image1', type: 'image' },
              imageB: {
                cloudinaryPage: 1,
                cloudinaryPublicId: 'image2',
                type: 'image',
              },
            },
            output: false,
          },
          {
            description: 'same pdf, different pages',
            input: {
              imageA: {
                cloudinaryPage: 2,
                cloudinaryPublicId: 'image1',
                type: 'image',
              },
              imageB: {
                cloudinaryPage: 1,
                cloudinaryPublicId: 'image1',
                type: 'image',
              },
            },
            output: false,
          },
          {
            description: 'different pdf, same pages',
            input: {
              imageA: {
                cloudinaryPage: 1,
                cloudinaryPublicId: 'image1',
                type: 'image',
              },
              imageB: {
                cloudinaryPage: 1,
                cloudinaryPublicId: 'image2',
                type: 'image',
              },
            },
            output: false,
          },
          {
            description: 'same pdf, same pages',
            input: {
              imageA: {
                cloudinaryPage: 1,
                cloudinaryPublicId: 'image1',
                type: 'image',
              },
              imageB: {
                cloudinaryPage: 1,
                cloudinaryPublicId: 'image1',
                type: 'image',
              },
            },
            output: true,
          },
          {
            description: 'same non-pdf',
            input: {
              imageA: { cloudinaryPublicId: 'image1', type: 'image' },
              imageB: { cloudinaryPublicId: 'image1', type: 'image' },
            },
            output: true,
          },
        ],
        function ({ input: { imageA, imageB }, output }) {
          return expect(this.cloudinaryService.equals(imageA, imageB)).to.eql(
            output
          );
        }
      )));

  describe('isImageValid', function () {
    beforeEach(function () {
      this.image = { cloudinaryPublicId: 'image1', cs: true };
      return (this.imageUrl =
        'https://res.cloudinary.com/listing/image/upload/t_ptplisting_0/image1');
    });

    describe('cloudinay request fails due to 404', function () {
      beforeEach(function () {
        this.requestError = new Error('404');
        this.requestError.response = { status: 404 };
        return this.axios.withArgs(this.imageUrl).rejects(this.requestError);
      });

      return it(
        'returns false',
        async function () {
          const result = await this.cloudinaryService.isImageValid(this.image);
          return expect(result).to.eql(false);
        }
      );
    });

    describe('cloudinay request fails for other reason', function () {
      beforeEach(function () {
        this.requestError = new Error('unknown reason');
        return this.axios.withArgs(this.imageUrl).rejects(this.requestError);
      });

      return it(
        'returns false',
        async function () {
          let error;
          try {
            await this.cloudinaryService.isImageValid(this.image);
          } catch (e) {
            error = e;
          }
          return expect(error).to.eql(this.requestError);
        }
      );
    });

    return describe('cloudinay request succeeds', function () {
      beforeEach(function () {
        return this.axios.withArgs(this.imageUrl).resolves();
      });

      return it(
        'returns true',
        async function () {
          const result = await this.cloudinaryService.isImageValid(this.image);
          return expect(result).to.eql(true);
        }
      );
    });
  });

  return describe('uploadImages', function () {
    describe('called with no images', () =>
      it(
        'returns an empty array',
        async function () {
          const result = await this.cloudinaryService.uploadImages([]);
          return expect(result).to.eql([]);
        }
      ));

    describe('called with images', function () {
      beforeEach(function () {
        return (this.images = [{ path: 'image1' }, { path: 'image2' }]);
      });

      describe('on success', function () {
        beforeEach(function () {
          this.uploadedImages = [
            {
              format: 'jpg',
              public_id: 'image1',
            },
            {
              format: 'jpg',
              public_id: 'image2',
            },
          ];
          return this.axios.resolves({
            data: _.map(this.uploadedImages, JSON.stringify),
            status: 200,
          });
        });

        return it(
          'makes the correct http call and returns the uploaded images',
          async function () {
            const result = await this.cloudinaryService.uploadImages(
              this.images
            );
            expect(this.axios).to.be.calledWith({
              data: { images: this.images },
              method: 'post',
              url: 'webtask.url/cloudinary_test',
            });
            return expect(result).to.eql([
              [
                {
                  cloudinaryPublicId: 'image1',
                  type: 'image',
                },
              ],
              [
                {
                  cloudinaryPublicId: 'image2',
                  type: 'image',
                },
              ],
            ]);
          }
        );
      });

      describe('some images error', function () {
        beforeEach(
          async function () {
            const uploadedImages = [
              { format: 'jpg', public_id: 'image1' },
              { error: { message: 'error2' } },
            ];
            this.axios.resolves({
              data: _.map(uploadedImages, JSON.stringify),
              status: 200,
            });
            return this.result = await this.cloudinaryService.uploadImages(
              this.images
            );
          }
        );

        it('only returns the valid images', function () {
          return expect(this.result).to.eql([
            [
              {
                cloudinaryPublicId: 'image1',
                type: 'image',
              },
            ],
            [],
          ]);
        });

        return it('reports errors to analyticsService', function () {
          return expect(this.analyticsService.track).to.be.calledWithExactly(
            'Image failed to upload',
            {
              image: {
                error: { message: 'error2' },
                path: 'image2',
              },
            }
          );
        });
      });

      return describe('all images error', function () {
        beforeEach(
          async function () {
            this.axios.resolves({
              data: _.map(
                [
                  { error: { message: 'error1' } },
                  { error: { message: 'error2' } },
                ],
                JSON.stringify
              ),
              status: 200,
            });
            try {
              return await this.cloudinaryService.uploadImages(this.images);
            } catch (err) {
              return (this.err = err);
            }
          }
        );

        it('throws', function () {
          expect(this.err).to.exist;
          return expect(this.err.message).to.eql(
            'Cloudinary upload error: error1\nerror2'
          );
        });

        return it('reports errors to analyticsService', function () {
          expect(this.analyticsService.track).to.be.calledWithExactly(
            'Image failed to upload',
            {
              image: {
                error: { message: 'error1' },
                path: 'image1',
              },
            }
          );
          return expect(this.analyticsService.track).to.be.calledWithExactly(
            'Image failed to upload',
            {
              image: {
                error: { message: 'error2' },
                path: 'image2',
              },
            }
          );
        });
      });
    });

    return describe('with multi-page pdf', function () {
      beforeEach(function () {
        this.images = [{ path: 'pdf1' }];
        this.uploadedImages = [{ format: 'pdf', pages: 4, public_id: 'pdf1' }];
        return this.axios.resolves({
          data: _.map(this.uploadedImages, JSON.stringify),
        });
      });

      return it(
        'with expandPDF, expands the pdf into separate images',
        async function () {
          const result = await this.cloudinaryService.uploadImages(this.images);
          return expect(result).to.eql([
            [
              {
                cloudinaryPage: 1,
                cloudinaryPublicId: 'pdf1',
                type: 'image',
              },
              {
                cloudinaryPage: 2,
                cloudinaryPublicId: 'pdf1',
                type: 'image',
              },
              {
                cloudinaryPage: 3,
                cloudinaryPublicId: 'pdf1',
                type: 'image',
              },
              {
                cloudinaryPage: 4,
                cloudinaryPublicId: 'pdf1',
                type: 'image',
              },
            ],
          ]);
        }
      );
    });
  });
});
