const _ = require('lodash');

class ClearbitService {
  static initClass() {
    this.prototype.search = async function(search) {
      const res = await this.axios({
        method: 'get',
        skipAuthorization: true,
        url: `https://autocomplete.clearbit.com/v1/companies/suggest?query=${search}`,
      });
      return res.data.sort(this._iconCompare.bind(this));
    };
  }

  constructor({ axios }) {
    this.axios = axios;
  }

  _iconCompare(a, b) {
    const aIsPrimary = this._isPrimaryDomain(a.domain);
    const bIsPrimary = this._isPrimaryDomain(b.domain);
    if (aIsPrimary === bIsPrimary) {
      return this._strcmp(a.name, b.name);
    }
    if (aIsPrimary) {
      return -1;
    } else {
      return 1;
    }
  }

  _isPrimaryDomain(domain) {
    const primaryDomains = ['com', 'net', 'org', 'gov'];
    return _.reduce(
      primaryDomains,
      (isPrimary, testDomain) => isPrimary || _.endsWith(domain, testDomain),
      false
    );
  }

  _strcmp(a, b) {
    if (a === b) {
      return 0;
    } else {
      if (a > b) {
        return 1;
      } else {
        return -1;
      }
    }
  }
}
ClearbitService.initClass();

module.exports = ClearbitService;
