const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ImageContainer = require('@components/shared/image/container');
const React = require('react');
const PropTypes = require('prop-types');
const ReadyRegistry = require('@utils/ready_registry');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class ImageListWithDetailViewer extends React.Component {
  static initClass() {
    this.propTypes = {
      suggestion: PropTypes.object, // one of the N360 search results, which are called "suggestions"
      displayVertically: PropTypes.bool,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          cloudinaryPublicId: PropTypes.string,
          source: PropTypes.string,
          type: PropTypes.string,
        })
      ),
      shouldDisplay: PropTypes.bool.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._isMounted = true;
    this.state = {
      images: this.props.images || null,
      largeImageDisplay: undefined,
    };
    this.largeImageRef = React.createRef(); // for keyboard event listener
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.shouldDisplay) {
      // If the entire list is hidden, then clear the temporary state
      this.setState({ largeImageDisplay: undefined });
    }
    if (nextProps.images) {
      if (this.props.suggestion?._id !== nextProps.suggestion?._id) {
        this.setState({ images: nextProps.images });
      } else {
        // keeping the url and isLargeImageLoaded if the image ID is the same
        const images = [];
        for (const newImage of nextProps.images) {
          const oldMatchingImage =
            this.state.images?.filter((i) => i.id === newImage.id)?.[0] || {};
          images.push({
            ...oldMatchingImage,
            ...newImage,
          });
        }
        this.setState({ images });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.shouldDisplay) {
      this.largeImageRef.current.focus(); // for onKeyDown
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _displayLargeImage(imageIndex) {
    this.setState({
      largeImageDisplay: {
        index: imageIndex,
      },
    });
  }

  _handleImageArrows(event) {
    if (event.code === 'ArrowLeft') {
      this._displayPreviousImage();
    } else if (event.code === 'ArrowRight') {
      this._displayNextImage();
    }
  }

  _displayPreviousImage() {
    if (this.state.largeImageDisplay) {
      const newIndex = (this.state.largeImageDisplay?.index - 1) % this.state.images.length;
      this._displayLargeImage(newIndex);
    }
  }

  _displayNextImage() {
    if (this.state.largeImageDisplay) {
      const newIndex = (this.state.largeImageDisplay?.index + 1) % this.state.images.length;
      this._displayLargeImage(newIndex);
    }
  }

  _getSelectedSuggestionAddress() {
    // Similar format as _renderSuggestion() from new_entry_modal/components/n360_property_search/index.js
    let address = [];
    if (this.props.suggestion) {
      const {
        label,
        _source: { streetAddress, propertyName, city, state, zip },
      } = this.props.suggestion;

      const cityAndState = city + ', ' + state + ' ' + zip;
      if (propertyName != null && streetAddress !== propertyName) {
        address = [label, streetAddress, cityAndState];
      } else {
        address = [label, cityAndState, '-']; // "blank" text to keep the same height
      }
    }
    return address;
  }

  render() {
    const fullImageUrl = this.state.images?.[this.state.largeImageDisplay?.index]?.url;
    const fullImageReady =
      this.state.images?.[this.state.largeImageDisplay?.index]?.isLargeImageLoaded;

    return (
      <div
        className={cx([
          !this.props.shouldDisplay
            ? 'hide-image-list'
            : this.props.displayVertically
            ? 'image-list-vertically'
            : 'image-list-horizontally',
        ])}
      >
        {this._getSelectedSuggestionAddress().map((addressPart, i) => (
          <div key={i} className={cx([addressPart === '-' ? 'blank-address-part' : ''])}>
            {addressPart}
          </div>
        ))}

        {/* "Thumbnail" Images */}
        {this.state.images === null ? (
          <div className={cx(['loading-or-no-images'])}>
            Loading
            <br />
            Images
          </div>
        ) : this.state.images?.length === 0 ? (
          <div className={cx(['loading-or-no-images'])}>No Images</div>
        ) : (
          this.state.images?.map((image, i) => {
            return (
              <div className={cx(['image-parent'])} key={i}>
                <ImageContainer
                  backgroundSize="contain"
                  croppable={false}
                  height="150px"
                  width="225px"
                  image={image}
                  readyRegistry={new ReadyRegistry()}
                  clickToEnlarge={true}
                  click={() => this._displayLargeImage(i)}
                  setLargeImageReady={(status) => {
                    const images = [...this.state.images];
                    images[i].isLargeImageLoaded = status;
                    this.setState({ images });
                  }}
                  setFullImageUrl={(url) => {
                    const images = [...this.state.images];
                    images[i].url = url;
                    this.setState({ images });
                  }}
                  useDefaultSize={true}
                />
              </div>
            );
          })
        )}

        {/* Large Image Viewer (aka Detail Viewer) on Click */}
        <div
          className={cx([this.state.largeImageDisplay ? undefined : 'hide-large-image'])}
          tabIndex={0} // Focus on this element to use onKeyDown
          ref={this.largeImageRef}
          onKeyDown={(event) => this._handleImageArrows(event)}
        >
          <div
            className={cx(['detail-viewer-arrow', 'detail-viewer-arrow-left'])}
            onClick={() => this._displayPreviousImage()}
          >
            <FontAwesomeIcon name="angle-left" size="3x" />
          </div>
          {fullImageUrl && fullImageReady ? (
            <div
              className={cx(['detail-viewer-on-click'])}
              style={{
                backgroundImage: fullImageUrl ? `url(${fullImageUrl})` : undefined,
              }}
              title="Click to close"
              onClick={(e) => {
                this.setState({
                  largeImageDisplay: undefined,
                });
              }}
            ></div>
          ) : (
            <div className={cx(['loading-large-image'])}>
              <FontAwesomeIcon name="spinner fa-spin-pulse" size="3x" />
            </div>
          )}
          <div
            className={cx(['detail-viewer-arrow', 'detail-viewer-arrow-right'])}
            onClick={() => this._displayNextImage()}
          >
            <FontAwesomeIcon name="angle-right" size="3x" />
          </div>
        </div>
      </div>
    );
  }
}
ImageListWithDetailViewer.initClass();

module.exports = ImageListWithDetailViewer;
