const _ = require('lodash');
const { Promise } = require('bluebird');
const { locateServices } = require('@services/service_locator');
const DATA_SOURCE_TYPES = require('@enums/data_source_types');

const identityActionTypes = [
  'setCreatingEntryData',
  'setCurrentField',
  'setCurrentModal',
  'setCurrentTab',
  'setCurrentView',
  'setData',
  'setDataValue',
  'setEntryDefaultStreetviewSettings',
  'setDigitalTourbookEntryImages',
  'setFieldDefinitions',
  'setIsDraggingImage',
  'setNewEntryData',
  'setSuggestions',
  'setPreviousFields',
  'setTourbookId',
  'setUserImages',
  'updateCreatingEntryData',
  'resetNavigation',
];

const mappingBuilder = function ({ promiseInspection }) {
  const {
    amenitiesService,
    analyticsService,
    cloudinaryService,
    collaborateModalActions,
    customerCommunicationService,
    docraptorApiService,
    entriesByAddressService,
    firebaseService,
    googleMapQueryService,
    mobileService,
    n360Service,
    n360QueryService,
    tourbookAnalyticsService,
    tourbookEditorPageAnalyticsService,
    tourbookEntryBuilderService,
    tourbookEntryFieldsService,
    tourbookEntryImagesService,
    tourbookFieldsService,
    tourbookMetaService,
    tourbooksService,
    userTourbooksService,
    usersService,
  } = locateServices([
    'amenitiesService',
    'analyticsService',
    'cloudinaryService',
    'collaborateModalActions',
    'customerCommunicationService',
    'docraptorApiService',
    'entriesByAddressService',
    'firebaseService',
    'googleMapQueryService',
    'mobileService',
    'n360Service',
    'n360QueryService',
    'tourbookAnalyticsService',
    'tourbookEditorPageAnalyticsService',
    'tourbookEntryBuilderService',
    'tourbookEntryFieldsService',
    'tourbookEntryImagesService',
    'tourbookFieldsService',
    'tourbookMetaService',
    'tourbooksService',
    'userTourbooksService',
    'usersService',
  ]);

  return {
    addAdditionalFields({ entryId, fields, section }) {
      return (dispatch, getState) => {
        const entry = getState().tourbookEditorPage.data.content.entries[entryId];
        const { id } = getState().userSession;
        const updates = tourbookEntryFieldsService.getAdditionalFieldUpdates({
          entry,
          fields,
          section,
        });
        return Promise.all([
          usersService.addPreviousFields(id, fields),
          Promise.map(updates, ({ path: entryPath, value }) => {
            return dispatch(
              this.update({
                path: ['content', 'entries', entryId].concat(entryPath),
                value,
              })
            );
          }),
          dispatch(
            this.setCurrentField({
              key: `content-entries-${entryId}-fields-${section}-${fields[0].key}-value`,
            })
          ),
        ]);
      };
    },
    removeAdditionalField(path) {
      return (dispatch, getState) => {
        const { tourbookId } = getState().tourbookEditorPage;

        return Promise.all([
          dispatch(this.track('Remove field', { path })),
          tourbooksService.deleteAtPath(tourbookId, path.slice(0, path.length - 1)),
        ]);
      };
    },

    addEntryAmenity({ entryId, key, type }) {
      return promiseInspection(
        [`addEntryAmenity/${entryId}/${type}`],
        async function (dispatch, getState) {
          const { data: tourbook } = getState().tourbookEditorPage;
          const entryPath = ['content', 'entries', entryId];
          const entry = _.get(tourbook, entryPath);
          const value = await amenitiesService.getData(type, entry);
          return await dispatch(
            this.update({
              path: entryPath.concat(['pages', 'amenities', key]),
              value,
            })
          );
        }.bind(this)
      );
    },

    toggleEntryVisibility(entryId, hidden) {
      return promiseInspection(
        ['toggleEntryVisibility'],
        async function (dispatch, getState) {
          const { data: tourbook, tourbookId } = getState().tourbookEditorPage;
          const {
            content: { entries },
          } = tourbook;
          const entry = entries[entryId];
          _.set(entry, ['hidden'], hidden);
          const actionsToDispatch = [
            dispatch(
              this.update({
                path: ['content', 'entries', entryId],
                value: entry,
              })
            ),
          ];
          if (hidden) {
            actionsToDispatch.push(dispatch(this.trackHideEntry(tourbookId, entryId)));
          } else {
            actionsToDispatch.push(dispatch(this.trackUnHideEntry(tourbookId, entryId)));
          }

          return await Promise.all(actionsToDispatch);
        }.bind(this)
      );
    },

    toggleMapVisibility(mapOptionsKey, hidden) {
      return promiseInspection(
        ['toggleMapVisibility'],
        async function (dispatch, getState) {
          const mapOptions = getState().tourbookEditorPage.data.content.settings[mapOptionsKey];
          _.set(mapOptions, ['hidden'], hidden);
          return await dispatch(
            this.update({
              path: ['content', 'settings', mapOptionsKey],
              value: mapOptions,
            })
          );
        }.bind(this)
      );
    },

    removeAdditionalBrokerPage() {
      return promiseInspection(
        ['removeAdditionalBrokerPage'],
        async function (dispatch) {
          return await Promise.all([
            dispatch(
              this.update({
                path: ['content', 'broker', 'brokerInfos'],
                value: null,
              })
            ),
          ]);
        }.bind(this)
      );
    },

    copyEntry(entryId) {
      return promiseInspection(
        ['copyEntry'],
        async function (dispatch, getState) {
          const { data: tourbook, tourbookId } = getState().tourbookEditorPage;
          const {
            content: { entries },
          } = tourbook;
          const entry = _.clone(entries[entryId]);
          if (entry.hideImagesZeroState && _.isEmpty(entry.images)) {
            delete entry.hideImagesZeroState;
          }
          entry.order = (_.max(_.map(entries, 'order')) || 0) + 1;
          _.set(entry, ['meta', 'source'], 'previous tour book');
          const newEntryId = firebaseService.getUniqueId();
          const itineraryOff = _.size(entries) === 10;
          await dispatch(
            this.update({
              path: ['content', 'entries', newEntryId],
              value: entry,
            })
          );
          await entriesByAddressService.addEntryByAddress(tourbookId, tourbook, newEntryId, entry);
          dispatch(this.setCurrentView({ entryId: newEntryId, key: 'entry' }));
          dispatch(this.track('Copies Listing', { entryId: newEntryId }));
          return await Promise.all([
            dispatch(this.resetMap(itineraryOff)),
            userTourbooksService.updateEntryMeta({
              entry,
              entryId: newEntryId,
              tourbook,
              tourbookId,
            }),
          ]);
        }.bind(this)
      );
    },

    createContactInfo(contactInfoId, type) {
      return (dispatch, getState) => {
        return Promise.all([
          dispatch(
            this.update({
              path: ['content', 'cover', 'contactInfos', contactInfoId],
              value: tourbookFieldsService.getDefaultContactInfo(type),
            })
          ),
        ]);
      };
    },

    createBrokerInfo(contactInfoId) {
      return (dispatch) => {
        const list = [
          dispatch(
            this.update({
              path: ['content', 'broker', 'brokerInfos', contactInfoId],
              value: { type: 'broker' },
            })
          ),
          dispatch(
            this.navigateToBrokerView({
              tabKey: `contact_info_${contactInfoId}`,
            })
          ),
        ];
        return Promise.all(list);
      };
    },

    createEntry(param) {
      return promiseInspection(
        ['createEntry'],
        async function (dispatch, getState) {
          const {
            tourbookEditorPage: { newEntryData },
          } = getState();

          let result;
          switch (newEntryData.source) {
            case DATA_SOURCE_TYPES.N360:
              result = await n360QueryService.createEntry(param);
              break;
            case DATA_SOURCE_TYPES.GOOGLE:
              result = await googleMapQueryService.createEntry(param);
              break;
          }

          const { data: tourbook, tourbookId } = getState().tourbookEditorPage;
          const { entryId, entry } = result;
          return await entriesByAddressService.addEntryByAddress(
            tourbookId,
            tourbook,
            entryId,
            entry
          );
        }.bind(this)
      );
    },

    createAdditionalMap() {
      return promiseInspection(
        ['createAdditionalMap'],
        async function (dispatch, getState) {
          const settings = getState().tourbookEditorPage.data.content.settings;

          // Find the smallest index that does not exist (e.g. in case a user deleted i=2 but kept i=1 and i=3)
          // e.g. if i=1,2,3 exists, then i=4 is used
          const sortedMapOptionsKeys = Object.keys(settings).filter(k => k.startsWith('mapOptions')).sort();
          let i = 1;
          for (; i < sortedMapOptionsKeys.length; i++) {
            if (!sortedMapOptionsKeys.includes(`mapOptions${i}`)) {
              break;
            }
          }

          dispatch(
            this.update({
              path: ['content', 'settings', `mapOptions${i}`],
              value: {
                name: 'Additional Map',
                itinerary: true,
                itineraryTitle: 'Itinerary',
                style: 'ngkf/ckglw6sj90ici1al9439saufx',
              },
            })
          );
        }.bind(this)
      );
    },

    createNotesEntry() {
      return promiseInspection(
        ['createNotesEntry'],
        async function (dispatch, getState) {
          const {
            tourbookEditorPage: { data: tourbook },
          } = getState();
          const entriesCount = _.size(tourbook.content.entries);
          let entry = await tourbookEntryBuilderService.buildNote(entriesCount);
          const entryId = firebaseService.getUniqueId();
          await dispatch(
            this.update({
              path: ['content', 'entries', entryId],
              value: entry,
            })
          );
          await dispatch(this.updateCreatingEntryData({ entry, entryId }));
          await dispatch(
            this.setCurrentView({
              entryId: entryId,
              key: 'entry-tourbook-notes',
            })
          );

          return Promise.all([
            this.trackCreateEntry({
              entryId,
            }),
          ]);
        }.bind(this)
      );
    },
    deleteEntry(entryId) {
      return promiseInspection(['deleteEntry'], (dispatch, getState) => {
        const { currentView, data: tourbook, tourbookId } = getState().tourbookEditorPage;
        dispatch(this.track('Deletes Listing', { entryId }));
        if (currentView.entryId === entryId) {
          dispatch(this.setCurrentView({ key: 'cover' }));
        }
        const existingEntry = tourbook.content.entries[entryId];
        return Promise.all([
          dispatch(this.update({ path: ['content', 'entries', entryId], value: null })),
          dispatch(this.resetMap()),
          entriesByAddressService.removeEntryByAddress(tourbookId, entryId, existingEntry),
          userTourbooksService.deleteEntryMeta({
            entryId,
            tourbook,
            tourbookId,
          }),
        ]);
      });
    },

    deleteAdditionalMap(mapOptionsKey) {
      return promiseInspection(
        ['deleteAdditionalMap'],
        async function (dispatch, getState) {
          dispatch(
            this.update({
              path: ['content', 'settings', mapOptionsKey],
              value: null,
            })
          );
        }.bind(this)
      );
    },

    deleteEntryImage({ entryId, imageId }) {
      return promiseInspection(['deleteEntryImage'], async (dispatch, getState) => {
        const { data: tourbook } = getState().tourbookEditorPage;
        const entry = tourbook.content.entries[entryId];
        const entryImagePaths = tourbookEntryImagesService.getDeleteImagePaths(entry, imageId);

        dispatch(
          this.track('Deletes an Image', {
            additionalProperties: {
              cloudinary_url: cloudinaryService.getImageUrl(entry.images[imageId]),
              pdf_display: entryImagePaths.length > 1,
            },
            entryId,
          })
        );
        await Promise.map(entryImagePaths, (imagePath) => {
          return dispatch(
            this.update({
              path: ['content', 'entries', entryId].concat(imagePath),
              value: null,
            })
          );
        });

        // Updating the 'order' of other images
        const images = getState().tourbookEditorPage.data.content.entries?.[entryId]?.images || [];
        const orderedImageIds = _.chain(images)
          .map((image, imageId) => ({ ...image, id: imageId }))
          .orderBy('order')
          .map((image) => image.id)
          .value();
        orderedImageIds.forEach((imageId, index) => {
          images[imageId]['order'] = index;
        });
        dispatch(
          this.update({
            path: ['content', 'entries', entryId, 'images'],
            value: images,
          })
        );
      });
    },

    deleteNewEntryData() {
      return (dispatch) => {
        dispatch(this.setNewEntryData());
        return dispatch(promiseInspection.reset('getNewEntryData'));
      };
    },

    generatePdf(pdfPreviewHtml) {
      return promiseInspection(
        ['generatePdf'],
        async function (dispatch, getState) {
          const tourbookData = getState().tourbookEditorPage.data;
          const title = tourbookMetaService.deriveNameFromTourbook(tourbookData);
          try {
            return await docraptorApiService.generatePdf(pdfPreviewHtml, title);
          } catch (error) {
            dispatch(
              this.track('Fails to Download PDF', {
                additionalProperties: { error_message: error.toString() },
              })
            );
            throw error;
          }
        }.bind(this)
      );
    },

    getEntryDefaultStreetviewSettings(entryId) {
      return async function (dispatch, getState) {
        const { data, entryDefaultStreetviewSettings } = getState().tourbookEditorPage;
        if (entryDefaultStreetviewSettings?.[entryId]) {
          return;
        }
        const entry = data.content.entries[entryId];
        const settings = await tourbookEntryImagesService.getDefaultStreetviewSettings(entry);
        return dispatch(this.setEntryDefaultStreetviewSettings({ entryId, settings }));
      }.bind(this);
    },

    getDigitalTourbookEntryImages(entryId) {
      return async function (dispatch, getState) {
        const { tourbookId } = getState().tourbookEditorPage;
        const images = await tourbookEntryImagesService.getDigitalTourbookEntryImages(
          entryId,
          tourbookId
        );
        return dispatch(this.setDigitalTourbookEntryImages({ entryId, images }));
      }.bind(this);
    },

    getFieldDefinitions() {
      return promiseInspection(
        ['getFieldDefinitions'],
        async function (dispatch) {
          const fieldDefinitions = await tourbookFieldsService.getFieldDefinitions();
          return dispatch(this.setFieldDefinitions(fieldDefinitions));
        }.bind(this)
      );
    },

    getN360Addresses(term) {
      return promiseInspection(
        ['getN360Addresses'],
        async function (dispatch) {
          const n360Addresses = await n360Service.propertySearch(term);
          if (n360Addresses.length > 0) {
            return dispatch(this.setSuggestions(n360Addresses));
          }
        }.bind(this)
      );
    },

    getNewEntryData(options) {
      return promiseInspection(
        ['getNewEntryData'],
        async function (dispatch, getState) {
          let shouldCreate;
          if (options.n360Address) {
            shouldCreate = await n360QueryService.hasEnoughDataToCreateEntry(options.n360Address);
          } else if (options.googleAddress) {
            shouldCreate = await googleMapQueryService.hasEnoughDataToCreateEntry(
              options.googleAddress
            );
          }

          if (shouldCreate) {
            return await dispatch(this.createEntry(true));
          }
        }.bind(this)
      );
    },

    navigateToCoverView({ fieldKey, tabKey }) {
      return (dispatch, getState) => {
        dispatch(this.setCurrentView({ key: 'cover' }));
        dispatch(this.setCurrentTab({ key: tabKey }));
        return dispatch(this.setCurrentField({ key: fieldKey }));
      };
    },

    navigateToBrokerView({ tabKey }) {
      return (dispatch) => {
        dispatch(this.setCurrentView({ key: 'broker' }));
        return dispatch(this.setCurrentTab({ key: tabKey }));
      };
    },

    navigateToCurrentEntryField({ fieldKey, tabKey, key = 'entry' }) {
      return (dispatch, getState) => {
        const { entryId } = getState().tourbookEditorPage.currentView;
        return dispatch(this.navigateToEntryField({ entryId, fieldKey, tabKey, key }));
      };
    },

    navigateToEntryField({ entryId, fieldKey, tabKey, key = 'entry' }) {
      return (dispatch, getState) => {
        dispatch(this.setCurrentView({ entryId, key }));
        dispatch(this.setCurrentTab({ key: tabKey }));
        return dispatch(this.setCurrentField({ key: fieldKey }));
      };
    },

    navigateToCurrentEntryImagesField({ fieldKey, tabKey }) {
      return (dispatch, getState) => {
        const { entryId } = getState().tourbookEditorPage.currentView;
        return dispatch(this.navigateToEntryImagesField({ entryId, fieldKey, tabKey }));
      };
    },

    navigateToEntryImagesField({ entryId, fieldKey, tabKey }) {
      return (dispatch, getState) => {
        dispatch(this.setCurrentView({ entryId, key: 'entry-images' }));
        dispatch(this.setCurrentTab({ key: tabKey }));
        return dispatch(this.setCurrentField({ key: fieldKey }));
      };
    },

    onTabChange() {
      return (dispatch, getState) => {
        const { currentTab, currentView, data: tourbook } = getState().tourbookEditorPage;
        if (currentView.key === 'entry') {
          dispatch(
            this.update({
              path: ['content', 'entries', currentView.entryId, 'visitedTabs', currentTab.key],
              value: true,
            })
          );
        }
        const eventName = tourbookEditorPageAnalyticsService.getViewEventName({
          currentTab,
          currentView,
          tourbook,
        });
        if (eventName) {
          return dispatch(this.track(eventName, { entryId: currentView.entryId }));
        }
      };
    },

    pushAll({ analyticsOptions, path, values }) {
      return (dispatch, getState) => {
        return Promise.map(values, (value) => {
          const valuePath = path.concat(firebaseService.getUniqueId());
          return dispatch(this.update({ analyticsOptions, path: valuePath, value }));
        });
      };
    },

    recordTourbookPublish(publishMethod) {
      return async function (dispatch, getState) {
        let needle;
        const {
          tourbookEditorPage: { data: tourbook, tourbookId },
          userSession,
        } = getState();
        const mixpanelMessageMap = {
          digitalPreview: 'Previews Digital Tour Book',
          pdfDownload: 'Downloads PDF',
        };
        if (((needle = publishMethod), Array.from(_.keys(mixpanelMessageMap)).includes(needle))) {
          const eventTitle = mixpanelMessageMap[publishMethod];
          dispatch(
            this.track(eventTitle, {
              additionalProperties: {
                new_publish: !(await tourbooksService.getIsPublished(tourbookId)),
              },
            })
          );
        }
        const brokerContactInfo = _.find(tourbook.content.cover.contactInfos, ['type', 'broker']);
        await userTourbooksService.recordTourbookPublish(userSession.id, tourbookId, publishMethod);
        return await analyticsService.updateUserLastPublish(userSession, brokerContactInfo);
      }.bind(this);
    },

    removeEntryAmenity({ entryId, key }) {
      return async function (dispatch, getState) {
        await dispatch(
          this.update({
            path: ['content', 'entries', entryId, 'pages', 'amenities', key],
            value: null,
          })
        );
        const tourbook = getState().tourbookEditorPage.data;
        const entryAmenities = tourbook.content.entries[entryId].pages?.amenities;
        if (_.size(entryAmenities) === 0) {
          return dispatch(this.track('Turns Amenities Page Off', { entryId }));
        }
      }.bind(this);
    },

    removeEntryImagePage({ entryId, pageId, fieldKey, tabKey }) {
      return async function (dispatch, getState) {
        await dispatch(
          this.update({
            path: ['content', 'entries', entryId, 'pages', 'images', pageId],
            value: null,
          })
        );
        const tourbook = getState().tourbookEditorPage.data;
        const nextPage = _.last(Object.keys(tourbook.content.entries[entryId].pages.images));
        tabKey = 'pdf_page_' + nextPage;
        fieldKey = 'content-entries-' + entryId + '-pages-images-' + nextPage + '-type';
        return dispatch(this.navigateToEntryImagesField({ entryId, fieldKey, tabKey }));
      }.bind(this);
    },

    reorderEntries(entryIds) {
      return (dispatch, getState) => {
        const { tourbookId } = getState().tourbookEditorPage;
        dispatch(this.track('Reorders Listings'));
        return tourbooksService.reorderEntries(tourbookId, entryIds);
      };
    },

    reorderEntryFields({ fieldKeys, section }) {
      return function (dispatch, getState) {
        const {
          currentView: { entryId },
          tourbookId,
        } = getState().tourbookEditorPage;
        return tourbooksService.reorderEntryFields({
          entryId,
          fieldKeys,
          section,
          tourbookId,
        });
      };
    },

    resetMap(itineraryOff) {
      return async function (dispatch, getState) {
        await dispatch(this.updateMap({ center: null, zoom: null }));
        const currentView = getState().tourbookEditorPage.currentView;
        if (itineraryOff) {
          return dispatch(
            this.update({
              path: ['content', 'settings', currentView.mapOptionsKey, 'itinerary'],
              value: false,
            })
          );
        }
      }.bind(this);
    },

    sendToClient({ emails, message, docraptorPdfUrl }) {
      return promiseInspection(
        ['sendToClient'],
        async function (dispatch, getState) {
          const userId = getState().userSession.id;
          const { data: tourbook, tourbookId } = getState().tourbookEditorPage;
          const trackingDetails = tourbookAnalyticsService.getTrackingDetails({
            content: tourbook.content,
            id: tourbookId,
            isOwner: userId === tourbook.owner_id,
            timestamps: tourbook.timestamps,
          });
          const eventProperties = _.assign({}, trackingDetails, {
            category: 'editor',
            digital_tourbook_url: mobileService.getUrl(tourbookId),
            email_message: message.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            new_publish: !(await tourbooksService.getIsPublished(tourbookId)),
          });

          try {
            await Promise.map(
              emails,
              async function (email) {
                eventProperties.email_address = email;
                return await customerCommunicationService.sendDigitalTourbookToClient(
                  userId,
                  eventProperties,
                  docraptorPdfUrl
                );
              }.bind(this)
            );
          } catch (error) {
            const additionalProperties = _.assign(
              { error_message: error.toString() },
              eventProperties
            );
            dispatch(this.track('Fails to Email Client', additionalProperties));
            throw error;
          }
          await tourbooksService.trackSentTo(tourbookId, eventProperties.email_address);
          return await dispatch(this.recordTourbookPublish('email'));
        }.bind(this)
      );
    },

    setDisplayMap(value) {
      return async function (dispatch, getState) {
        const { key } = getState().tourbookEditorPage.currentView;
        const path = ['content', 'settings', 'display', 'tourMap'];
        await dispatch(this.update({ path, value }));
        const newView = (() => {
          if (value) {
            return 'map';
          } else if (key === 'map') {
            return 'cover';
          }
        })();
        if (newView) {
          return dispatch(this.setCurrentView({ key: newView }));
        }
      }.bind(this);
    },

    setPdfPageDefaultLayout(pdfPagePath) {
      return (dispatch, getState) => {
        const pdfPage = _.get(getState().tourbookEditorPage.data, pdfPagePath);
        if (!pdfPage.layout) {
          return dispatch(
            this.update({
              analyticsOptions: { skip: true },
              path: pdfPagePath.concat(['layout']),
              value: '1_1_1_1',
            })
          );
        }
      };
    },

    subscribeToData() {
      return promiseInspection(
        ['subscribeToData'],
        async function (dispatch, getState) {
          const { tourbookId } = getState().tourbookEditorPage;
          const unsubscribe = await tourbooksService.subscribeToUpdates(tourbookId, (value) => {
            if (!value) {
              throw new Error(`tourbook not found: ${tourbookId}`);
            }
            return dispatch(this.setData(value));
          });
          const userCanEdit = await usersService.canEdit({
            tourbookId,
            userId: getState().userSession.id,
          });
          if (!userCanEdit) {
            throw new Error('unauthorized');
          }
          return unsubscribe;
        }.bind(this)
      );
    },

    subscribeToPreviousFields() {
      return promiseInspection(['subscribeToPreviousFields'], (dispatch, getState) => {
        const { id } = getState().userSession;
        return usersService.subscribeToPreviousFields(id, (value) => {
          return dispatch(this.setPreviousFields(value));
        });
      });
    },

    subscribeToUserImages() {
      return promiseInspection(['subscribeToUserImages'], (dispatch, getState) => {
        const userId = getState().userSession.id;
        return usersService.subscribeToImages(userId, (images) => {
          return dispatch(this.setUserImages(images));
        });
      });
    },

    track(eventName, param) {
      if (param == null) {
        param = {};
      }
      const { additionalProperties } = param;
      return function (dispatch, getState) {
        const { tourbookEditorPage, userSession } = getState();
        const { data: tourbook, tourbookId } = tourbookEditorPage;

        return tourbookAnalyticsService.track(eventName, {
          additionalProperties: _.assign({ category: 'editor' }, additionalProperties),
          content: tourbook.content,
          id: tourbookId,
          isOwner: userSession.id === tourbook.owner_id,
          timestamps: tourbook.timestamps,
        });
      };
    },

    trackCreateEntry({ entryId, source, space, userEntryId }) {
      return (dispatch) => {
        const options = { entryId };
        if (userEntryId) {
          return dispatch(this.track('Adds Previous Tour Book Listing', options));
        } else if (space) {
          options.additionalProperties = { source };
          return dispatch(this.track('Adds N360 Listing', options));
        } else {
          return dispatch(this.track('Adds New Listing', options));
        }
      };
    },

    trackCropImage(image) {
      return (dispatch) => {
        return dispatch(
          this.track('Crops Image', {
            additionalProperties: {
              cloudinary_url: cloudinaryService.getImageUrl(image),
              pdf_display: true,
            },
          })
        );
      };
    },

    trackDragImageToPdf(image) {
      return (dispatch, getState) => {
        const { currentView } = getState().tourbookEditorPage;
        const [eventName, additionalProperties] = Array.from(
          (() => {
            switch (image.type) {
              case 'streetview':
                return ['Drags Street View to PDF', {}];
              case 'map':
                return ['Drags Map View to PDF', {}];
              default:
                return [
                  'Drags an Image to PDF',
                  {
                    cloudinary_url: cloudinaryService.getImageUrl(image),
                    pdf_display: true,
                  },
                ];
            }
          })()
        );
        return dispatch(
          this.track(eventName, {
            additionalProperties,
            entryId: currentView.entryId,
          })
        );
      };
    },

    trackForField(eventName, field) {
      return (dispatch, getState) => {
        const { currentView, fieldDefinitions } = getState().tourbookEditorPage;
        const additionalProperties =
          tourbookEditorPageAnalyticsService.getFieldAdditionalProperties(field, fieldDefinitions);
        return dispatch(
          this.track(eventName, {
            additionalProperties,
            entryId: currentView.entryId,
          })
        );
      };
    },

    trackImportRecommendedFields(fields) {
      return (dispatch) => {
        return fields.forEach((field) => {
          return dispatch(this.trackForField('Imports a Recommended Field', field));
        });
      };
    },

    trackManualAddField(field) {
      return (dispatch) => {
        const eventName = field.isAtlas
          ? 'Manually Adds a Recommended Field'
          : 'Adds a Custom Field';
        return dispatch(this.trackForField(eventName, field));
      };
    },
    trackUnHideEntry(tourbookId, entryId) {
      return (dispatch) => {
        const options = { tourbookId, entryId };
        return dispatch(this.track('UnHides Listing', options));
      };
    },
    trackHideEntry(tourbookId, entryId) {
      return (dispatch) => {
        const options = { tourbookId, entryId };
        return dispatch(this.track('Hides Listing', options));
      };
    },
    update({ analyticsOptions, path, value }) {
      return (dispatch, getState) => {
        dispatch(this.setDataValue({ path, value }));
        const { data: tourbook, tourbookId } = getState().tourbookEditorPage;
        if (!analyticsOptions?.skip) {
          const event = tourbookEditorPageAnalyticsService.getDataChangeEvent({
            options: _.assign({ tourbook }, analyticsOptions),
            path,
            value,
          });
          if (event) {
            dispatch(
              this.track(event.name, {
                additionalProperties: event.additionalProperties,
                entryId: event.entryId,
              })
            );
          }
        }
        const updates = tourbookMetaService.getMetaUpdates(path, tourbook);
        const promises = [tourbooksService.updateAtPath(tourbookId, path, value)];
        if (updates) {
          updates.forEach((update) =>
            promises.push(
              userTourbooksService.updateMetaField({
                path: update.path,
                tourbook,
                tourbookId,
                value: update.value,
              })
            )
          );
        }
        const entriesByAddressUpdates = entriesByAddressService.updateEntriesByAddress(
          tourbookId,
          tourbook,
          path,
          value
        );
        if (entriesByAddressUpdates.length > 0) {
          promises.push(...entriesByAddressUpdates);
        }

        return Promise.all(promises);
      };
    },

    updateContactInfoType(contactInfoId, type) {
      return async function (dispatch, getState) {
        const path = ['content', 'cover', 'contactInfos', contactInfoId];
        await dispatch(
          this.update({
            path: path.concat(['type']),
            value: type,
          })
        );
        if (type !== 'skipped') {
          return dispatch(
            this.update({
              path: path.concat(['header']),
              value: tourbookFieldsService.getContactInfoHeader(type),
            })
          );
        }
      }.bind(this);
    },

    updateMap({ center, zoom }) {
      return (dispatch, getState) => {
        const currentView = getState().tourbookEditorPage.currentView;
        return Promise.all([
          dispatch(
            this.update({
              path: ['content', 'settings', currentView.mapOptionsKey, 'center'],
              value: center,
            })
          ),
          dispatch(
            this.update({
              path: ['content', 'settings', currentView.mapOptionsKey, 'zoom'],
              value: zoom,
            })
          ),
        ]);
      };
    },

    uploadImages({ entryId, images, options, saveLocation }) {
      return promiseInspection(
        ['upload/images'],
        async function (dispatch, getState) {
          const uploadResults = _.flatten(await cloudinaryService.uploadImages(images)).map(
            (image) => _.assign({}, options, image)
          );
          let uploadedImages = uploadResults.filter((result) => !result.error);

          // Adding orders based on existing images
          const existingImages =
            getState().tourbookEditorPage.data.content.entries?.[entryId]?.images || [];
          const lastIndexAmongUploaded = _.chain(existingImages)
            .sortBy('order')
            .map((image) => image.order)
            .max()
            .value();
          const firstNewIndex = lastIndexAmongUploaded ? lastIndexAmongUploaded + 1 : 0;
          uploadedImages = uploadedImages.map((image, i) => ({
            ...image,
            order: firstNewIndex + i,
          }));

          if (saveLocation?.type === 'user') {
            const userId = getState().userSession.id;
            await Promise.map(uploadedImages, (uploadedImage) =>
              usersService.saveImage(userId, saveLocation.namespace, uploadedImage)
            );
          } else if (saveLocation?.type === 'tourbook') {
            await dispatch(
              this.pushAll({
                path: saveLocation.path,
                values: uploadedImages,
              })
            );
          }
          return uploadResults;
        }.bind(this)
      );
    },

    setCollaborateModalState() {
      return promiseInspection(['setCollaborateModalState'], async function (dispatch, getState) {
        const { data: tourbook, tourbookId } = getState().tourbookEditorPage;
        const meta = await tourbookMetaService.build({
          tourbook,
          tourbookId,
        });
        return dispatch(
          collaborateModalActions.set({
            tourbookId,
            tourbook: meta,
            trackingCategory: 'editor',
          })
        );
      });
    },
  };
};

module.exports = {
  identityActionTypes,
  mappingBuilder,
  namespace: 'tourbookEditorPage',
};
