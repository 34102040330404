const PropTypes = require('prop-types');
const React = require('react');

class MapEditor extends React.Component {
  static initClass() {
    this.propTypes = {
      desiredHeight: PropTypes.number.isRequired,
      desiredWidth: PropTypes.number.isRequired,
      entryLatitude: PropTypes.string.isRequired,
      entryLongitude: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    };
  }

  componentDidMount() {
    this.googleMap = new google.maps.Map(this.mapDiv, this._getMapOptions());
    return new google.maps.Marker({
      map: this.googleMap,
      position: {
        lat: parseFloat(this.props.entryLatitude),
        lng: parseFloat(this.props.entryLongitude),
      },
    });
  }

  getData() {
    const latLng = this.googleMap.getCenter();
    return {
      center: [latLng.lat(), latLng.lng()],
      maptypeid: this.googleMap.getMapTypeId(),
      zoom: this.googleMap.getZoom(),
    };
  }

  render() {
    return (
      <div
        ref={(node) => {
          return (this.mapDiv = node);
        }}
        style={{
          height: this.props.desiredHeight,
          width: this.props.desiredWidth,
        }}
      />
    );
  }

  _getMapOptions() {
    return {
      center: {
        lat: parseFloat(this.props.image.center[0]),
        lng: parseFloat(this.props.image.center[1]),
      },
      mapTypeControl: true,
      mapTypeControlOptions: {
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE,
        ],
      },
      mapTypeId: this.props.image.maptypeid || google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      zoom: this.props.image.zoom,
      zoomControl: true,
    };
  }
}
MapEditor.initClass();

module.exports = MapEditor;
