const _ = require('lodash');
const Loading = require('@components/shared/loading');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const UserNavigationContainer = require('./components/user_navigation/container');
const Login = require('./../login/index');
const { useNavigate } = require('react-router-dom');

class App extends React.Component {
  static initClass() {
    this.propTypes = {
      loginStatus: PropTypes.object,
      logout: PropTypes.func.isRequired,
      navigate: PropTypes.func.isRequired,
      user: PropTypes.object,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.user &&
      nextProps.user
    ) {
      return this.props.navigate('/library');
    } else if (this.props.user && !nextProps.user) {
      return this.props.navigate('/');
    }
  }

  render() {
    return (
      <div
        // intentionally not passing through cx here
        className="root"
      >
        {this.props.initializeStatus?.loading || this.props.loginStatus?.loading ? (
          <Loading />
        ) : this.props.initializeStatus?.error || this.props.loginStatus?.loading ? (
          <span>An error occurred</span>
        ) : (
          <div>
            {this.props.user ? (
              <>
                <UserNavigationContainer
                  logout={this.props.logout}
                  user={this.props.user}
                />
                <div
                  // intentionally not passing through cx here
                  className="content"
                >
                  {this.props.children}
                </div>
              </>
            ) : (
              <Login
                onClick={() => this.props.login({ connection: 'standard' })}
              />
            )}
            <div className={cx(['app-footer'])}>
              <span>
                Your use of this site is subject to its
                <a
                  href="https://marketing.bespaceful.com/#/tos"
                  target="_blank"
                >
                  terms of use
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
App.initClass();

module.exports = (props) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.user) {
      const convergenceRedirect = localStorage.getItem('convergenceRedirect');
      if (convergenceRedirect) {
        localStorage.removeItem('convergenceRedirect');
        const endpoint = convergenceRedirect
          .replace(`${window.location.origin}/#`, '')
          .replace(`${window.location.origin}`, '');
        navigate(endpoint);
      }
    }
  }, [props.user, localStorage]);

  React.useEffect(() => {
    if (props.user && window.location.hash.startsWith('#access_token=')) {
      navigate('/library');
    }
  }, [props.user, window.location.hash]);

  return <App {...props} navigate={navigate} />
};
