const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ModalContainer = require('@components/shared/modal/container');
const { default: ProgressBar } = require('react-progressbar');
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class CreatingEntryOverlay extends React.Component {
  static initClass() {
    this.defaultProps = {
      numberOfDataFieldsFound: 0,
      numberOfImagesFound: 0,
    };

    this.propTypes = {
      createError: PropTypes.any,
      entryId: PropTypes.string,
      isCreated: PropTypes.bool,
      isFirstEntry: PropTypes.bool.isRequired,
      isN360: PropTypes.bool.isRequired,
      numberOfDataFieldsFound: PropTypes.number,
      numberOfImagesFound: PropTypes.number,
      onClose: PropTypes.func.isRequired,
      setCurrentView: PropTypes.func.isRequired,
      track: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._goToNextStep = this._goToNextStep.bind(this);
    this._renderIconContainer = this._renderIconContainer.bind(this);
    this.state = { currentIndex: 0 };
  }

  componentDidMount() {
    return setTimeout(this._goToNextStep, this.props.delayBetweenSteps);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentIndex !== this.state.currentIndex) {
      return setTimeout(this._goToNextStep, this.props.delayBetweenSteps);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createError != null) {
      this._handleError(nextProps.createError);
    }
    if (
      this.state.waitingOnIsCreated &&
      this._didIsCreatedBecomeTrue(nextProps)
    ) {
      this.setState({ waitingOnIsCreated: false });
      return this._onFinishPullingThirdPartyData(nextProps);
    }
  }

  render() {
    const stepConfigs = this._getStepConfigs();
    const percentComplete = Math.round(
      ((this.state.currentIndex + 1) / stepConfigs.length) * 100
    );
    return (
      <ModalContainer onClose={this.props.onClose} verticallyCentered={!this.props.createError}>
        {this.props.createError
          ? <div className={cx(['root'])}>
          <div className={cx(['error-container'])}>
            <div className={cx(['error-message'])}>
              There was an error creating your listing. We're sorry about that!
            </div>
            <div className={cx(['error-hint'])}>
              <span>
                If the problem continues, please contact us
              </span>
              <span>
                .
              </span>
            </div>
          </div>
        </div>
          : <div className={cx(['root'])}>
          <div className={cx(['progress-bar-container'])}>
            <ProgressBar color="#2588F8" completed={percentComplete} />
          </div>
          <div className={cx(['icons-container'])}>
            {stepConfigs.map(this._renderIconContainer)}
          </div>
        </div>}
      </ModalContainer>
    );
  }

  _didIsCreatedBecomeTrue(nextProps) {
    return this.props.isCreated !== nextProps.isCreated && nextProps.isCreated;
  }

  _getStepConfigs() {
    const prefix = this.props.isFirstEntry ? 'Generating' : 'Updating';
    let stepConfigs = [
      {
        iconName: 'map-location-dot',
        key: 'generating_map',
        label: `${prefix} Map`,
      },
      {
        iconName: 'table-list',
        key: 'generating_summary',
        label: `${prefix} Summary`,
      },
      {
        iconName: 'database',
        key: 'pulling_third_party_data',
        label: 'Pulling Third-Party Data',
      },
    ];
    if (this.props.isN360) {
      stepConfigs = stepConfigs.concat([
        {
          iconName: 'magnifying-glass',
          key: 'finding_images',
          label: 'Finding Images',
          message: `${this.props.numberOfImagesFound} images found`,
        },
        {
          iconName: 'images',
          key: 'finding_data_fields',
          label: 'Finding Data Fields',
          message: `${this.props.numberOfDataFieldsFound} data fields found`,
        },
      ]);
    }
    return stepConfigs;
  }

  _goToCreatedEntry(entryId) {
    return this.props.setCurrentView({ entryId, key: 'entry' });
  }

  _goToNextStep() {
    switch (this.state.currentIndex) {
      case 0:
      case 1:
      case 3:
        return this.setState({ currentIndex: this.state.currentIndex + 1 });
      case 4:
        return this._goToCreatedEntry(this.props.entryId);
      default:
        if (this.props.isCreated) {
          return this._onFinishPullingThirdPartyData(this.props);
        } else {
          return this.setState({ waitingOnIsCreated: true });
        }
    }
  }

  _handleError(error) {
    return this.props.track('Fails to Add Listing', {
      additionalProperties: { error },
    });
  }

  _onFinishPullingThirdPartyData(props) {
    if (props.isN360) {
      return this.setState({ currentIndex: 3 });
    } else {
      return this._goToCreatedEntry(props.entryId);
    }
  }

  _renderIconContainer({ iconName, key, label, message }, index) {
    const containerProps = {
      className: cx([`icon-container`, `${key || ''}`, `${index === this.state.currentIndex ? 'active' : ''
        }`, `${index < this.state.currentIndex ? 'complete' : ''}`]),
      key: index,
    };
    return (
      <div {...containerProps}>
        <div className={cx(['icon'])}>
          <FontAwesomeIcon name={iconName} size="3x" />
        </div>
        <div className={cx(['label'])}>
          {label}
        </div>
        {message
          ? <div className={cx(['divider'])} />
          : undefined}
        {message
          ? <div className={cx(['message'])}>
          {message}
        </div>
          : undefined}
      </div>
    );
  }
}
CreatingEntryOverlay.initClass();

module.exports = CreatingEntryOverlay;
