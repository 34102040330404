const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const TourbookEditorPage = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'getFieldDefinitions',
  'resetNavigation',
  'setCurrentModal',
  'setCurrentView',
  'setTourbookId',
  'subscribeToData',
  'subscribeToPreviousFields',
  'subscribeToUserImages',
  'track',
]);

const mapStateToProps = function (state) {
  const initializeStatuses = _.chain([
    'getFieldDefinitions',
    'subscribeToData',
    'subscribeToPreviousFields',
    'subscribeToUserImages',
  ])
    .map((key) => [key, state.promiseInspections[`tourbookEditorPage/${key}`]])
    .fromPairs()
    .value();
  return {
    currentModal: state.tourbookEditorPage.currentModal,
    currentView: state.tourbookEditorPage.currentView,
    initializeStatuses,
  };
};

module.exports = build({
  component: TourbookEditorPage,
  mapDispatchToProps,
  mapStateToProps,
});
