const AdditionalFieldsContainer = require('./components/additional_fields_input/container');
const AddressInputContainer = require('./components/address_input/container');
const AmenitiesInputContainer = require('./components/amenities_input/container');
const ArrowInput = require('./components/arrow_input');
const ColorInput = require('./components/color_input');
const ContactInfoTypeInputContainer = require('./components/contact_info_type_input/container');
const DateInput = require('./components/date_input');
const EntryImagesInputContainer = require('./components/entry_images_input/container');
const EntryImagesZeroStateInputContainer = require('./components/entry_images_zero_state_input/container');
const EntryTypeInput = require('./components/entry_type_input');
const ExtraImagePageLayoutInput = require('./components/extra_image_page_layout_input');
const ImageInputContainer = require('./components/image_input/container');
const LogoInputContainer = require('./components/logo_input/container');
const MainImagesLayoutInput = require('./components/main_images_layout_input');
const MapStyleInput = require('./components/map_style_input');
const NotesInputContainer = require('./components/notes_input/container');
const NotesToggleInput = require('./components/notes_toggle_input');
const PdfPageTypeInput = require('./components/pdf_page_type_input/container');
const RangeInputContainer = require('./components/range_input/container');
const React = require('react');
const SelectInput = require('./components/select_input');
const SliderContainer = require('./components/slider/container');
const TextInputContainer = require('./components/text_input/container');
const ToggleInput = require('./components/toggle_input');
const ValueToggleInput = require('./components/value_toggle_input');
const { default: TimeInput } = require('./components/time_input/index');

class Input extends React.Component {
  render() {
    const InputComponent = this._getInputComponent(this.props.type);
    return <InputComponent {...this.props} />;
  }

  _getInputComponent(type) {
    switch (type) {
      case 'additional_fields':
        return AdditionalFieldsContainer;
      case 'address':
        return AddressInputContainer;
      case 'amenities':
        return AmenitiesInputContainer;
      case 'arrow':
        return ArrowInput;
      case 'color':
        return ColorInput;
      case 'contact_info_type':
        return ContactInfoTypeInputContainer;
      case 'date':
        return DateInput;
      case 'entry_images':
        return EntryImagesInputContainer;
      case 'entry_images_zero_state':
        return EntryImagesZeroStateInputContainer;
      case 'entry_type':
        return EntryTypeInput;
      case 'extra_image_page_layout':
        return ExtraImagePageLayoutInput;
      case 'image':
        return ImageInputContainer;
      case 'main_images_layout':
        return MainImagesLayoutInput;
      case 'logo':
        return LogoInputContainer;
      case 'map_style':
        return MapStyleInput;
      case 'notes_toggle':
        return NotesToggleInput;
      case 'notes':
        return NotesInputContainer;
      case 'pdf_page_type':
        return PdfPageTypeInput;
      case 'range':
        return RangeInputContainer;
      case 'select':
        return SelectInput;
      case 'slider':
        return SliderContainer;
      case 'value-toggle':
        return ValueToggleInput;
      case 'toggle':
        return ToggleInput;
      case 'time':
        return TimeInput;
      case 'text':
      default:
        return TextInputContainer;
    }
  }
}

module.exports = Input;
