const _ = require('lodash');
const { locateServices } = require('./service_locator');

class TourbookEntryFieldsService {
  static initClass() {
    this.prototype.headerFields = {
      notesHeader: 'Notes',
      propertyHeader: 'Property',
      spaceHeader: 'Space',
      termsHeader: 'Terms',
      subLeaseHeader: 'Space'
    };
  }

  constructor() {
    ({
      tourbookEntryFields: this.tourbookEntryFields,
      tourbookFieldsService: this.tourbookFieldsService,
    } = locateServices(['tourbookFieldsService', 'tourbookEntryFields']));
  }

  getAdditionalFieldUpdates({ entry, fields, section }) {
    const maxOrder = _.chain(entry.fields?.[section])
      .map(({ order }) => order || 0)
      .max()
      .value();
    return fields.map((field, index) => ({
      path: ['fields', section, field.key],

      value: {
        isAdditionalField: true,
        label: field.label || null,
        order: maxOrder + index + 1,
        value: field.value || null,
        isUrl: field.key === 'url',
      },
    }));
  }

  getFields(data) {
    const fields = this.tourbookEntryFields.buildEntryFields(data);
    fields.notes = this._getNotesSection(data);
    fields.property.propertyId = data.property.id;
    _.merge(fields.header, this.headerFields);
    return fields;
  }

  getFieldsToDisplay({ entry, section }) {
    return _.chain(entry.fields[section])
      .map((field, key) => _.assign({ key, section }, field))
      .sortBy('order')
      .value();
  }

  etlTransformations(property) {
    const sqftInAcre = 43560;
    property.lotArea = property.lotArea / sqftInAcre;
    return property;
  }

  _getNotesSection(data) {
    const notes = _.compact([
      data.property.propertyNotes,
      data.space?.spaceNotes,
    ]);
    if (notes.length > 0) {
      return {
        notes: { content: notes.join('\n') },
        notesToggle: true,
      };
    } else {
      return {};
    }
  }
}
TourbookEntryFieldsService.initClass();

module.exports = TourbookEntryFieldsService;
