const { build } = require('@components/container_helpers');
const N360PropertySearch = require('./');

const mapStateToProps = (state) =>
  _.assign({}, state.tourbookEditorPage.newEntryData, {
    createEntryStatus:
      state.promiseInspections['tourbookEditorPage/createEntry'],
    getNewEntryDataStatus:
      state.promiseInspections['tourbookEditorPage/getNewEntryData'],
  });

module.exports = build({
  component: N360PropertySearch,
  serviceNames: ['n360Service'],
  mapStateToProps,
});
