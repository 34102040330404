const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const DropdownContainer = require('@components/shared/dropdown/container');

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    return this._updateFocus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active === this.props.active) {
      return;
    }
    return this._updateFocus();
  }

  render() {
    if (this.props.active) {
      return (
        <div className={cx(['active'])}>
          <input
            onChange={() => this._onChange(this.input.value)}
            ref={(node) => {
              return (this.input = node);
            }}
            type="text"
            value={this.props.value || ''}
          />
          {this.props.value &&
          this.props.options?.fieldDefinition?.units?.length > 0 ? (
            <div className={cx(['unit'])}>{this._getUnit()}</div>
          ) : undefined}
        </div>
      );
    } else {
      return <div className={cx(['inactive'])}>{this._renderValue()}</div>;
    }
  }

  _onChange(value) {
    if (this.props.options?.fieldDefinition) {
      value = this.props.fieldFormatterService.getAllowedValue({
        fieldDefinition: this.props.options.fieldDefinition,
        value,
      });
    }
    return this.props.update(value);
  }

  _renderValue() {
    if (this.props.options?.fieldDefinition) {
      return this.props.tourbookFieldsService.getFieldValueAsHtml({
        fieldDefinition: this.props.options.fieldDefinition,
        value: this.props.value,
        withUnit: true,
        unit: this.props.unit 
      });
    } else {
      return this.props.value;
    }
  }

  _hasUnitSelect() {
    return this.props.options.fieldDefinition.units?.length > 1;
  }

  _getUnit() {
    if (this._hasUnitSelect()) {
      return this._renderUnitSelect();
    } else {
      return this.props.options.fieldDefinition.units[0];
    }
  }

  _renderUnitSelect() {
    return (
      <DropdownContainer
        onChange={(value) => this.props.updateUnit(value)}
        options={this.props.options.fieldDefinition.units.map(
          (unit, index) => ({
            label: unit,
            value: unit,
            unit,
          })
        )}
        value={this.props.unit || this.props.options.fieldDefinition.units[0]}
        width="85px"
      />
    );
  }

  _updateFocus() {
    if (this.props.active) {
      return this.input.select();
    }
  }
}

module.exports = TextInput;
