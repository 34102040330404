const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class TabView extends React.Component {
  constructor(props) {
    super(props);
    this._onNext = this._onNext.bind(this);
  }

  static initClass() {
    this.propTypes = {
      completeConfig: PropTypes.shape({
        label: PropTypes.string.isRequired,
        style: PropTypes.string.isRequired,
      }),
      currentTab: PropTypes.object.isRequired,
      onComplete: PropTypes.func.isRequired,
      onTabChange: PropTypes.func.isRequired,
      setCurrentTab: PropTypes.func.isRequired,
      tabConfigs: PropTypes.arrayOf(
        PropTypes.shape({
          component: PropTypes.func.isRequired,
          componentProps: PropTypes.object,
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
    };
  }

  componentWillMount(nextProps) {
    return this.props.onTabChange();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentTab.key !== nextProps.currentTab.key) {
      return this.props.onTabChange();
    }
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <ul className={cx(['tab-links'])}>
          {this.props.tabConfigs.map(({ label, value }, index) => {
            const anchorProps = {
              className: cx([`${value === this.props.currentTab.key ? 'active' : ''}`]),
              onClick: () => this.props.setCurrentTab({ key: value }),
            };
            return (
              <li key={index} className={cx(['tab-link'])}>
                <div className="tab-link-container">
                  <a {...anchorProps}>{label}</a>
                  <FontAwesomeIcon name="chevron-right" />
                </div>
              </li>
            );
          })}
        </ul>
        <div className={cx(['tab-pane'])}>{this._getSelectedTabComponent()}</div>
      </div>
    );
  }

  _getSelectedTabComponent() {
    const index = _.findIndex(
      this.props.tabConfigs,
      ({ value }) => value === this.props.currentTab.key
    );
    if (index === -1) {
      return '';
    }
    const completeConfig = _.assign({ value: 'complete' }, this.props.completeConfig);

    const {
      component: Component,
      componentProps,
      hideNext,
      showComplete,
    } = this.props.tabConfigs[index];
    let next = completeConfig;
    const nextConfig = this.props.tabConfigs[index + 1];
    if (!showComplete && nextConfig) {
      next = nextConfig;
    }
    const tabProps = _.assign({}, componentProps);
    if (!hideNext) {
      _.assign(tabProps, { next, onNext: this._onNext });
    }
    return <Component {...tabProps} />;
  }

  _onNext(value) {
    if (value === 'complete') {
      return this.props.onComplete();
    } else {
      return this.props.setCurrentTab({ key: value });
    }
  }
}
TabView.initClass();

module.exports = TabView;
