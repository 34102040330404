const { actions, build } = require('@components/container_helpers');
const PdfCoverTemplate = require('./');

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ..._.pick(actions.tourbookEditorPage, ['update']),
});

const mapStateToProps = function (state) {
  let {
    cover,
    settings: { coverFontColor },
  } = state.tourbookEditorPage.data.content;
  if (!coverFontColor) {
    coverFontColor = 'white';
  }
  return {
    contactInfos: cover.contactInfos,
    coverFontColor,
    coverPhoto: cover.imagesShown.coverPhoto,
    options: cover.options || {},
  };
};

module.exports = build({
  component: PdfCoverTemplate,
  mapDispatchToProps,
  mapStateToProps,
});
