const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const AmenitiesInput = require('./');

const { addEntryAmenity, removeEntryAmenity, track } =
  actions.tourbookEditorPage;

const mapDispatchToProps = function (dispatch, ownProps) {
  const { entryId } = ownProps.options;
  return {
    addAmenity({ key, type }) {
      return dispatch(addEntryAmenity({ entryId, key, type }));
    },
    removeAmenity(key) {
      return dispatch(removeEntryAmenity({ entryId, key }));
    },
    track(...args) {
      return dispatch(track(...Array.from(args || [])));
    },
  };
};

const mapStateToProps = function (state, ownProps) {
  const { entryId } = ownProps.options;
  const loadingStatuses = _.chain([
    'nearby_places',
    'walkscore',
    'googleplaces',
    'googlebiz',
  ])
    .map(function (type) {
      const key = `tourbookEditorPage/addEntryAmenity/${entryId}/${type}`;
      const status = state.promiseInspections[key]?.pending;
      return [type, status];
    })
    .fromPairs()
    .value();
  return { entryId, loadingStatuses };
};

module.exports = build({
  component: AmenitiesInput,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['amenitiesService'],
});
