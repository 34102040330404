const { ChromePicker } = require('react-color');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');

class ColorInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { displayPicker: props.active };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active === nextProps.active) {
      return;
    }
    return this.setState({ displayPicker: nextProps.active });
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <div
          onClick={() => this.setState({ displayPicker: true })}
          className={cx(['current'])}>
          <div className={cx(['text'])}>
            {this.props.value}
          </div>
          <div
            style={{ backgroundColor: this.props.value, color: this.props.value }}
            className={cx(['circle'])} />
        </div>
        {this.state.displayPicker ? this._renderPicker() : undefined}
      </div>
    );
  }

  _renderPicker() {
    return (
      <div className={cx(['picker'])}>
        <div
          onClick={() => this.setState({ displayPicker: false })}
          className={cx(['picker-overlay'])} />
        <ChromePicker
          color={this.props.value}
          disableAlpha={true}
          onChange={({ hex }) => this.props.update(hex)} />
      </div>
    );
  }
}

module.exports = ColorInput;
