const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const AdditionalInputSelection = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, ['update']);

const mapStateToProps = function (state, ownProps) {
  const { currentField, data, fieldDefinitions } = state.tourbookEditorPage;
  const path = ownProps.options.path;
  const entryId = path[path.indexOf('entries') + 1];
  const key = path[path.indexOf('value') - 1];
  return {
    data,
    field: {
      ...currentField,
      key,
      type: ownProps.options.type,
      path,
    },
    section: ownProps.options.options.section,
    entry: data.content.entries[entryId],
    fieldDefinitions,
  };
};

const serviceNames = ['tourbookFieldsService'];

module.exports = build({
  component: AdditionalInputSelection,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames,
});
