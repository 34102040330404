const _ = require('lodash');
const ImageLibrary = require('./image_library');
const numeral = require('numeral');
const { locateService } = require('./service_locator');
const {
  fieldKeyToFieldDefinitionKey,
} = require('@components/pages/tourbook_editor_page/multi_space_util');

class TourbookFieldsService {
  static initClass() {
    this.prototype.getDefaultSettings = async function (user) {
      const mostRecentTourbook = await locateService(
        'usersService'
      ).getMostRecentlyCreatedTourbook(user.id);
      const filteredLastTourbookSettings = (function () {
        const settings = mostRecentTourbook?.content.settings;
        if (!settings) {
          return {};
        }
        return {
          accentColor: settings.accentColor,
          display: {
            footerLogo: settings.display.footerLogo,
            footerText: settings.display.footerText,
            pageNumbers: settings.display.pageNumbers,
            tourMap: settings.display.tourMap,
            summary: settings.display.summary,
          },
        };
      })();
      const defaultSettings = {
        accentColor: '#000F9F',
        coverFontColor: 'white',
        display: {
          digitalAd: true,
          footerLogo: true,
          footerText: true,
          pageNumbers: true,
          summary: true,
          tourMap: true,
        },
        isMapColor: false,
        mapOptions: {
          itinerary: true,
          itineraryTitle: 'Itinerary',
          style: 'ngkf/ckglw6sj90ici1al9439saufx',
        },
      };
      return _.merge({}, defaultSettings, filteredLastTourbookSettings);
    };
  }

  constructor() {
    this._getEstMonthlyRent = this._getEstMonthlyRent.bind(this);
    this.n360FieldsService = locateService('n360FieldsService');
    this.suggestedFieldsService = locateService('suggestedFieldsService');
    this.firebaseService = locateService('firebaseService');
    this.tourbookFields = locateService('tourbookFields');
  }

  formatRangeValue(range, definition) {
    range = _.mapValues(range, (value) => this.formatValue(value, definition));
    if (
      range.min &&
      range.max &&
      range.max !== 0 &&
      range.max !== null &&
      range.max !== undefined &&
      range.max !== '0'
    ) {
      return `${range.min} - ${range.max}`;
    } else {
      return range.min || range.max;
    }
  }

  formatValue(value, definition) {
    if (value === '') {
      return '';
    }
    if (definition?.integer) {
      return numeral(value).format('0,0');
    } else if (definition?.decimal) {
      value = '' + value;
      if (_.includes(value, '.')) {
        return numeral(value).format('0,0.00');
      } else {
        return numeral(value).format('0,0');
      }
    } else {
      return value;
    }
  }

  getContactInfoHeader(type) {
    const headers = {
      broker: 'Prepared By',
      client: 'Prepared For',
    };
    return headers[type];
  }

  getDefaultContactInfo(type) {
    if (type === 'skipped') {
      return { type };
    }
    const header = this.getContactInfoHeader(type);
    return { header, type };
  }

  getDefaultCover(user) {
    const userSettings = user.firebaseProfile.tourbookSettings;

    return {
      fields: this._buildDefaultMap(this.tourbookFields.cover.fields),
      imagesShown: {
        coverPhoto: this._getDefaultCoverImage(userSettings),
      },
      logo: {
        imagesShown: {
          prepared_by: userSettings.prepared_by?.logo || null,
          prepared_for: userSettings.prepared_for?.logo || null,
        },
      },
      n360Images: ImageLibrary.coverPhotos,
    };
  }

  getDefaultEntryMapSettings(entry) {
    return {
      center: [entry.location.latitude, entry.location.longitude],
      type: 'map',
      zoom: 16,
    };
  }

  getDefaultEntryPages(entry) {
    return { image: { imagesShown: [] } };
  }

  getFieldDefinitions() {
    return this.firebaseService.getValue('fieldDefinitions');
  }

  getFieldLabel(field, fieldDefinitions) {
    const fieldDefinitionKey = fieldKeyToFieldDefinitionKey(field.key);
    return (
      field.label ||
      fieldDefinitions[fieldDefinitionKey]?.label ||
      this._titleCaseId(field.key)
    );
  }
  getFieldUnit({ fieldDefinition, unit }, args) {
    unit = unit || fieldDefinition?.units?.[0] || '';
    if (args?.asHtml) {
      const isSuperscriptPresent = !!unit.match(/\^/) && !!unit.match(/\^\S/); // check for ^ and texts afterwards
      if (isSuperscriptPresent) {
        const charWithSymbol = unit.match(/\^\S/)[0]; // ^ and [A-Za-z0-9]
        const superscriptedChar = charWithSymbol.replace('^', ''); // [A-Za-z0-9]
        const [textBefore, textAfter] = unit.split(charWithSymbol);
        return (
          <>
            {textBefore}
            <sup style={args?.superscriptStyle}>{superscriptedChar}</sup>
            {textAfter}
          </>
        );
      }
    }
    return unit;
  }

  getFieldValueAsHtml({ fieldDefinition, unit, value }, args) {
    let fieldValue = this.getFieldValueAsString({ fieldDefinition, value });
    unit = this.getFieldUnit(
      { fieldDefinition, unit },
      { ...(args || {}), asHtml: true }
    );
    if (unit) {
      fieldValue = (
        <>
          {fieldValue} {unit}
        </>
      );
    }
    return fieldValue;
  }

  getFieldValueAsString({ fieldDefinition, unit, value, withUnit }) {
    const formatFnName =
      fieldDefinition?.type === 'range'
        ? 'formatRangeValue'
        : 'formatValue';
    let string = this[formatFnName](value, fieldDefinition);
    if (!string) {
      return '';
    }
    if (fieldDefinition?.currency) {
      string = `$${string}`;
    }
    if (withUnit) {
      unit = this.getFieldUnit({ fieldDefinition, unit });
      if (unit) {
        string += ` ${unit}`;
      }
    }
    return string;
  }
  getFieldValueAsLink({ anchorText, value }) {
    let link = value;
    if (!link) {
      return <></>;
    }
    if (link.indexOf('http://') > -1 || link.indexOf('https://') > -1) {
      return <a href={link} target="_blank">{anchorText}</a>;
    }
    return <a href={'https://' + link + '/'} target="_blank">{anchorText}</a>;
  }
  getSuggestedFieldValue(field) {
    if (field.newField) {
      return 'Create New Field';
    } else if (field.value && ['number', 'string'].includes(typeof field)) {
      return field.value;
    } else if (field.lastUsed) {
      return 'Previously Used';
    } else {
      return 'Suggested';
    }
  }

  getSuggestedFields({
    fieldDefinitions,
    fields,
    previousFields,
    section,
    n360Data,
  }) {
    let sugFields = _.chain([
      ...this.n360FieldsService.getSuggestedFieldKeys(),
      ...this.suggestedFieldsService.getSuggestedFieldKeys(),
    ])
      .reject((key) => previousFields[key] != null)
      .map((key) => ({
        key,
        lastUsed: 0,
      }))
      .concat(
        _.map(previousFields, (field, key) =>
          _.assign({ key, lastUsed: 1 }, field)
        )
      )
      .reject(({ key }) => key === 'estMonthlyRent')
      .map(function (field) {
        const fieldValue = n360Data?.[field.key];
        return _.assign(field, {
          hasValue: fieldValue != null,
          isAtlas: true,
          value: fieldValue,
        });
      })
      .reject(({ key }) => fields?.[section]?.[key]?.value);
    if (
      fields?.space?.leaseHighPrice?.value &&
      fields?.space?.smallestAvailableSpace?.value
    ) {
      sugFields = sugFields.concat(
        this._getEstMonthlyRentField({
          fieldDefinitions,
          fields,
          previousFields,
        })
      );
    }
    sugFields = sugFields
      .orderBy(['hasValue', 'lastUsed', 'key'], ['desc', 'desc', 'asc'])
      .value();
    return sugFields;
  }

  _buildDefaultMap(fields) {
    const map = {};
    for (let field of Array.from(fields)) {
      map[field.key] =
        (typeof field.default === 'function' ? field.default() : undefined) ||
        field.default ||
        '';
    }
    return map;
  }

  _getDefaultCoverImage(userSettings) {
    const cloudinaryPublicId =
      userSettings?.coverPhoto?.cloudinaryPublicId || ImageLibrary.defaultCoverPhoto().cloudinaryPublicId;
    return { cloudinaryPublicId };
  }
  /**accepts single object with a shape like { fieldDefinitions, fields }
   * expects fields to be an object like
   * {
   *   space:{
   *     leaseHighPrice: {
   *       unit: object(?)
   *       value:{
   *         min: Number,
   *         max: Number
   *       }
   *     },
   *     smallestAvailableSpace: {
   *       value:{
   *         min: Number,
   *         max: Number
   *       }
   *     },
   *   },
   *
   */
  _getEstMonthlyRent({ fieldDefinitions, fields }) {
    let leaseRateMax, leaseRateMin, spaceSizeMax, spaceSizeMin;
    const leaseHighPrice = this._getRangeAvg(fields.space.leaseHighPrice.value);
    const smallestAvailableSpace = this._getRangeAvg(
      fields.space.smallestAvailableSpace.value
    );
    if (!leaseHighPrice || !smallestAvailableSpace) {
      return;
    }
    if (fields.space.leaseHighPrice.value.min > 0) {
      leaseRateMin = fields.space.leaseHighPrice.value.min;
    }
    if (fields.space.leaseHighPrice.value.max > 0) {
      leaseRateMax = fields.space.leaseHighPrice.value.max;
    }
    const leaseRate = _.filter(
      [leaseRateMin, leaseRateMax],
      (rate) => rate !== undefined
    );
    if (fields.space.smallestAvailableSpace.value.min > 0) {
      spaceSizeMin = fields.space.smallestAvailableSpace.value.min;
    }
    if (fields.space.smallestAvailableSpace.value.max > 0) {
      spaceSizeMax = fields.space.smallestAvailableSpace.value.max;
    }
    const spaceSize = _.filter(
      [spaceSizeMin, spaceSizeMax],
      (size) => size !== undefined
    );
    const def = fieldDefinitions.leaseHighPrice;
    let rateUnit = this.getFieldUnit({
      fieldDefinition: fieldDefinitions.leaseHighPrice,
      unit: fields.space.leaseHighPrice.unit,
    });
    rateUnit = rateUnit?.toUpperCase();
    // Return based on Rate Range
    const retRange = (() => {
      switch (false) {
        case leaseRate.length !== 2 || spaceSize.length !== 2:
          return this._rangeBothTwo(leaseRate, spaceSize, rateUnit, def);
        case leaseRate.length !== 2 || spaceSize.length !== 1:
          return this._rangeOneSize(leaseRate, spaceSize, rateUnit, def);
        case leaseRate.length !== 1 || spaceSize.length !== 2:
          return this._rangeOneRate(leaseRate, spaceSize, rateUnit, def);
        case leaseRate.length !== 1 || spaceSize.length !== 1:
          return this._rangeBothOne(leaseRate, spaceSize, rateUnit, def);
      }
    })();
    return retRange;
  }

  _rangeBothTwo(rates, sizes, rateUnit, def) {
    if (rateUnit === 'PSF/YR') {
      const moRentMin = _.floor((rates[0] * sizes[0]) / 12);
      const moRentMax = _.floor((rates[1] * sizes[1]) / 12);
      // @formatMoRentRangeValue {'min': moRentMin, 'max': moRentMax}
      // @formatRangeValue {'min': moRentMin, 'max': moRentMax}, def
      return { min: moRentMin, max: moRentMax };
    }
  }

  _rangeOneSize(rates, sizes, rateUnit, def) {
    if (rateUnit === 'PSF/YR') {
      const moRentMin = _.floor((rates[0] * sizes[0]) / 12);
      const moRentMax = _.floor((rates[1] * sizes[0]) / 12);
      //@formatRangeValue {'min': moRentMin, 'max': moRentMax}, def
      return { min: moRentMin, max: moRentMax };
    }
  }

  _rangeOneRate(rates, sizes, rateUnit, def) {
    if (rateUnit === 'PSF/YR') {
      const moRentMin = _.floor((rates[0] * sizes[0]) / 12);
      const moRentMax = _.floor((rates[0] * sizes[1]) / 12);
      // @formatRangeValue {'min': moRentMin, 'max': moRentMax}, def
      return { min: moRentMin, max: moRentMax };
    }
  }

  _rangeBothOne(rates, sizes, rateUnit, def) {
    if (rateUnit === 'PSF/YR') {
      const moRentMin = _.floor((rates[0] * sizes[0]) / 12);
      // @formatValue moRentMin, def
      return { min: moRentMin, max: null };
    }
  }

  _getEstMonthlyRentField({ fieldDefinitions, fields, previousFields }) {
    const key = 'estMonthlyRent';
    const value = this._getEstMonthlyRent({ fieldDefinitions, fields });
    const lastUsed = previousFields?.[key]?.lastUsed || 0;
    return { hasValue: value != null, isAtlas: false, key, lastUsed, value };
  }

  _getRangeAvg(field) {
    if (!field) {
      return;
    }
    const parsedMin = parseFloat(field.min);
    const parsedMax = parseFloat(field.max);
    if (isNaN(parsedMin) && isNaN(parsedMax)) {
      return '';
    } else if (isNaN(parsedMin)) {
      return parsedMax;
    } else if (isNaN(parsedMax)) {
      return parsedMin;
    } else {
      return (parsedMin + parsedMax) / 2;
    }
  }

  _titleCaseId(id) {
    return _.chain(id)
      .snakeCase()
      .split('_')
      .map(_.capitalize)
      .join(' ')
      .value();
  }
}
TourbookFieldsService.initClass();

module.exports = TourbookFieldsService;
