const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');

const TourbookNotes = require('./');
const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'track',
  'setCurrentTab',
  'setCurrentField',
  'setCurrentModal',
]);
const mapStateToProps = function (state, ownProps) {
  const { data } = state.tourbookEditorPage;
  return {
    entryId: ownProps.entryId,
    entry: data.content.entries[ownProps.entryId],
  };
};
const serviceNames = ['tourbookEntryFieldsService'];

module.exports = build({
  component: TourbookNotes,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames,
});
