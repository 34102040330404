const { build } = require('@components/container_helpers');
const Pdf = require('./');

const mapStateToProps = function (state) {
  const {
    content: { broker, entries, settings },
  } = state.tourbookEditorPage.data;
  return { broker, entries, settings };
};

module.exports = build({
  component: Pdf,
  mapStateToProps,
  serviceNames: ['pdfService'],
});
