const _ = require('lodash');
const AppearanceInputsBuilder = require('./inputs_builders/appearance_inputs_builder');
const BaseTab = require('../base_tab');
const ContactInputsBuilder = require('./inputs_builders/contact_inputs_builder');
const PdfCoverTemplateContainer = require('../pdf_cover_template/container');
const PropTypes = require('prop-types');
const React = require('react');
var classNames = require('classnames/bind');
const importedStyles = require('./index.styl');
const cx = classNames.bind(importedStyles);
const ReadyRegistry = require('@utils/ready_registry');
const TabViewContainer = require('../tab_view/container');
const TourInfoInputsBuilder = require('./inputs_builders/tour_info_inputs_builder');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class CoverView extends React.Component {
  static initClass() {
    this.propTypes = {
      contactInfos: PropTypes.object,
      brokerInfos: PropTypes.object,
      currentTab: PropTypes.object.isRequired,
      firebaseService: PropTypes.object.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      setCurrentTab: PropTypes.func.isRequired,
      tourbookFields: PropTypes.object.isRequired,
      track: PropTypes.func.isRequired,
      createBrokerInfo: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.state = this._buildStateFromProps(props);
  }

  componentWillMount() {
    if (!this.props.currentTab.key) {
      return this.props.setCurrentTab({ key: 'appearance' });
    }
  }

  componentWillReceiveProps(nextProps) {
    let needle;
    const shouldResetState =
      ((needle = this.state.newContactInfoId),
      Array.from(_.keys(nextProps.contactInfos)).includes(needle)) ||
      (this._shouldIncludeAddContactTab(nextProps.contactInfos) &&
        !this.state.newContactInfoId) ||
      (!this._shouldIncludeAddContactTab(nextProps.contactInfos) &&
        this.state.newContactInfoId);
    if (shouldResetState) {
      return this.setState(this._buildStateFromProps(nextProps));
    }
  }

  render() {
    return (
      <TabViewContainer
        completeConfig={{
          label: 'Add Listing',
          style: 'add',
        }}
        onComplete={() => {
          this.props.track('Selects to Add a Listing', {
            additionalProperties: { source: 'next button' },
          });
          return this.props.setCurrentModal({ key: 'new-entry' });
        }}
        tabConfigs={this._getTabConfigs()}
      />
    );
  }

  _hasCrossedMaxAdditionalBrokers(brokerInfos) {
    const maxAdditionalBrokersAllowed = 3;
    const totalAdditionalBrokers = _.size(brokerInfos);
    return totalAdditionalBrokers >= maxAdditionalBrokersAllowed;
  }

  _getPropsForAddAdditionalBroker(contactIndex) {
    const primayBrokerIndex = 0;

    if (
      contactIndex !== primayBrokerIndex ||
      this._hasCrossedMaxAdditionalBrokers()
    ) {
      return null;
    }
    const addAdditionalBrokerRowProps = {
      className: cx([`add-additional-broker-row`]),
      onClick: () =>
        this.props.createBrokerInfo(this.props.firebaseService.getUniqueId()),
    };
    return (
      <div {...addAdditionalBrokerRowProps}>
        <div className={cx(['icon-wrapper'])}>
          <div className={cx(['plus-wrapper'])}>
            <FontAwesomeIcon name="plus" />
          </div>
        </div>
        <div className={cx(['text'])}>
          <div className={cx(['label'])}>{'Add Additional Broker'}</div>
        </div>
      </div>
    );
  }

  _buildPdfTemplateComponent() {
    return (
      <PdfCoverTemplateContainer
        isEditing={true}
        readyRegistry={new ReadyRegistry()}
      />
    );
  }

  _buildStateFromProps(props) {
    let newContactInfoId = null;
    if (this._shouldIncludeAddContactTab(props.contactInfos)) {
      newContactInfoId = props.firebaseService.getUniqueId();
    }
    return { newContactInfoId };
  }

  _getContactTabLabel(type) {
    switch (type) {
      case 'broker':
        return 'Broker Info';
      case 'client':
        return 'Client Info';
      default:
        return 'Add Contact';
    }
  }

  _getTabConfigs() {
    let contactIndex = 0;
    const tabConfigs = [
      {
        component: BaseTab,
        componentProps: {
          inputs: AppearanceInputsBuilder(),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
        },
        label: 'Appearance',
        value: 'appearance',
      },
      {
        component: BaseTab,
        componentProps: {
          inputs: TourInfoInputsBuilder(this.props.tourbookFields),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
        },
        label: 'Tour Info',
        value: 'tour_info',
      },
    ];

    _.each(this.props.contactInfos, (info, infoId) => {
      const inputs = ContactInputsBuilder({
        contactInfoId: infoId,
        contactInfoType: info.type,
        tourbookFields: this.props.tourbookFields,
      });
      return tabConfigs.push({
        component: BaseTab,
        componentProps: {
          inputs,
          postNextFactory: this._getPropsForAddAdditionalBroker.bind(
            this,
            contactIndex++
          ),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
        },
        label: this._getContactTabLabel(info.type),
        value: `contact_info_${infoId}`,
      });
    });
    if (this.state.newContactInfoId) {
      tabConfigs.push({
        component: BaseTab,
        componentProps: {
          inputs: ContactInputsBuilder({
            contactInfoId: this.state.newContactInfoId,
            tourbookFields: this.props.tourbookFields,
          }),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
        },
        label: this._getContactTabLabel(),
        value: `contact_info_${this.state.newContactInfoId}`,
      });
    }
    return tabConfigs;
  }

  _shouldIncludeAddContactTab(contactInfos) {
    const size = _.size(contactInfos);
    return (
      size === 0 || (size === 1 && _.values(contactInfos)[0].type !== 'skipped')
    );
  }
}
CoverView.initClass();

module.exports = CoverView;
