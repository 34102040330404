const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const Loading = require('@components/shared/loading');
const ModalContainer = require('@components/shared/modal/container');
const PropTypes = require('prop-types');
const React = require('react');

class ImportFromXproModal extends React.Component {
  static initClass() {
    this.propTypes = {
      importFromXpro: PropTypes.func.isRequired,
      importStatus: PropTypes.object,
      onClose: PropTypes.func.isRequired,
      options: PropTypes.shape({
        request: PropTypes.arrayOf(
          PropTypes.shape({
            listingId: PropTypes.number.isRequired,
            propertyId: PropTypes.number.isRequired,
            spaceId: PropTypes.number.isRequired,
          })
        ),
      }),
    };
  }

  componentWillMount() {
    return this.props.importFromXpro(this.props.options.request);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.importStatus?.pending && nextProps.importStatus?.fulfilled) {
      return this.props.onClose();
    }
  }

  render() {
    return (
      <ModalContainer onClose={this.props.onClose} verticallyCentered={true}>
        {this.props.importStatus?.rejected ? (
          <div className={cx(['root'])}>
            <div className={cx(['error'])}>
              There was a problem importing your spaces. Please try again.
            </div>
          </div>
        ) : (
          <div className={cx(['root'])}>
            <div className={cx(['text'])}>{this._getText()}</div>
            <Loading inline={true} />
          </div>
        )}
      </ModalContainer>
    );
  }

  _getText() {
    const count = this.props.options.request.length;
    let text = `Importing ${count} space`;
    if (count !== 1) {
      text += 's';
    }
    return text;
  }
}
ImportFromXproModal.initClass();

module.exports = ImportFromXproModal;
