const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const LoadingToggle = require('@components/shared/loading/toggle');
const ModalContainer = require('@components/shared/modal/container');
const PropTypes = require('prop-types');
const React = require('react');
const ResultsTable = require('./components/results_table');
const N360PropertySearchContainer = require('./components/n360_property_search/container');
const GooglePropertySearch = require('./components/google_property_search');
const store = require('@store');
const {
  columnsToDisplayForMultiSpaceSummaryTable,
  spaceMetadatas,
  generateMultiSpace,
} = require('@components/pages/tourbook_editor_page/multi_space_util');
const DATA_SOURCE_TYPES = require('@enums/data_source_types');
const ImageListWithDetailViewerContainer = require('@components/shared/image_list_with_detail_viewer/container');

class NewEntryModal extends React.Component {
  static initClass() {
    this.propTypes = {
      createEntry: PropTypes.func.isRequired,
      createEntryStatus: PropTypes.object,
      deleteNewEntryData: PropTypes.func.isRequired,
      getNewEntryData: PropTypes.func.isRequired,
      getN360Addresses: PropTypes.func.isRequired,
      getNewEntryDataStatus: PropTypes.object,
      hasN360Error: PropTypes.bool,
      onClose: PropTypes.func.isRequired,
      options: PropTypes.shape({
        address: PropTypes.string,
        n360ListingId: PropTypes.string,
        n360PropertyId: PropTypes.string,
        n360SpaceId: PropTypes.string,
      }).isRequired,
      property: PropTypes.object,
      track: PropTypes.func.isRequired,
      userEntries: PropTypes.array,
      n360PropertySearch: PropTypes.object,
      googlePropertySearch: PropTypes.object,
      getN360PropertySearchHeight: PropTypes.func,
      spaces: PropTypes.array,
      source: PropTypes.string.isRequired,
    };
  }

  //    --- NEW ENTRY MODAL ---
  constructor(props) {
    super(props);
    this._updateModalInputValue = this._updateModalInputValue.bind(this);
    this._updateRenderResults = this._updateRenderResults.bind(this);
    this._updateRenderGoogle = this._updateRenderGoogle.bind(this);
    this._updateRenderN360Suggests = this._updateRenderN360Suggests.bind(this);
    this._updateSuggestionHighlighted = this._updateSuggestionHighlighted.bind(this);
    this._updateSuggestionMedia = this._updateSuggestionMedia.bind(this);
    this._updateSuggestions = this._updateSuggestions.bind(this);
    this._updateN360PropertySearchHeight = this._updateN360PropertySearchHeight.bind(this);
    this._onFocus_N360PropertySearch = this._onFocus_N360PropertySearch.bind(this);
    this.state = {
      n360PropertySearchFocused: true,
      googlePropertySearchFocused: false,
      n360PropertySearchHeight: 0,
      renderN360Suggests: true,
      renderResults: false, // 'true' when the user selects an N360 suggestion - it will display multi floor/suite info as well as "duplicate" listings from other users
      renderGoogle: true,
      multiSpaceSelection: [], // selections of multiple spaces from one property
      suggestionMedia: undefined, // photos from N360PropertySearchContainer
      suggestionHighlighted: undefined, // hover-overed suggestion from N360PropertySearchContainer
    };
  }

  componentDidMount() {
    if (this.props.options.address) {
      this.n360PropertySearch._update(this.props.options.address);
      this.props.getNewEntryData(this.props.options);
    }
    return this.n360PropertySearch.autosuggest?.input.focus();
  }

  _getNonConfidentialEntries() {
    const filteredUserEntries = (this.props.userEntries ? this.props.userEntries : []).filter(
      (entry) => !entry.confidential
    );
    return filteredUserEntries;
  }

  _getOnlyImagesFromMedia(images) {
    return images?.filter((m) => m.extension !== 'pdf'); // TODO improve
  }

  componentWillUnmount() {
    this.props.deleteNewEntryData();
    return (this.unmounting = true);
  }

  _updateSuggestionHighlighted(value) {
    this.setState({ suggestionHighlighted: value });
  }

  _updateSuggestionMedia(value) {
    this.setState({ suggestionMedia: value });
  }

  _updateSuggestions(value) {
    this.setState({ suggestions: value });
  }

  _updateModalInputValue(value) {
    this.setState({ modal_InputValue: value });
    this.googlePropertySearch.geoSuggest.setState({
      value,
      activeSuggest: true,
      isSuggestsHidden: false,
      userInput: value,
    });
    return this.googlePropertySearch.geoSuggest.showSuggests();
  }

  _updateRenderResults(booVal) {
    return this.setState({ renderResults: booVal });
  }

  _updateRenderGoogle(booVal) {
    return this.setState({ renderGoogle: booVal });
  }

  _updateRenderN360Suggests(booVal) {
    return this.setState({ renderN360Suggests: booVal });
  }

  _updateN360PropertySearchHeight(value) {
    if (this.n360PropertySearch != null) {
      return this.setState({ n360PropSearchHeight: value });
    }
  }

  _updateMultiSpaceSelection(value) {
    return this.setState({ multiSpaceSelection: value });
  }

  render() {
    return (
      <ModalContainer {...this._getModalProps()}>
        <div className={cx(['root'])}>
          <div className={cx(['search'])}>
            <div className={cx(['title'])}>Search N360 for an address:</div>
          </div>
          {this._renderN360PropertySearch()}
          {this._renderGooglePropertySearch()}
          {LoadingToggle(
            { inline: true, isLoading: this._isLoading() },
            (() => {
              if (this.props.property) {
                return (
                  <div
                    ref={(node) => {
                      return (this.results = node);
                    }}
                    className={cx(['results'])}
                  >
                    {this._renderResultsMessage()}
                    {this.state.renderResults && (
                      <ImageListWithDetailViewerContainer
                        images={this._getOnlyImagesFromMedia(this.props.property?.media)}
                        shouldDisplay={true}
                      />
                    )}
                    {this._renderUserEntries()}
                    {this.props.hasN360Error
                      ? this._renderN360Error()
                      : this._renderN360Spaces()}
                    {this._renderCreateButton()}
                  </div>
                );
              } else if (this.state.notFound) {
                return (
                  <div className={cx(['not-found'])}>
                    <div className={cx(['line-1'])}>
                      We could not find that address
                    </div>
                    <div className={cx(['line-2'])}>
                      Make sure you've spelled the address correctly,
                    </div>
                    <div className={cx(['line-3'])}>
                      including a city, state, or zip code.
                    </div>
                  </div>
                );
              } else if (this.props.getNewEntryDataStatus?.error) {
                return (
                  <div className={cx(['results'])}>
                    {this._renderN360Error()}
                    {this._renderCreateButton()}
                  </div>
                );
              }
            })()
          )}
        </div>
        {!this.state.renderResults &&
          !this.state.googlePropertySearchFocused &&
          Object.values(this.state.suggestions || {}).map((suggestion) => {
            // Rendering all images in advance and conditionally displaying relevant parts (e.g. whatever suggestionHighlighted._id matches to)
            return (
              <ImageListWithDetailViewerContainer
                suggestion={suggestion}
                images={this._getOnlyImagesFromMedia(this.state.suggestionMedia?.[suggestion._id])}
                shouldDisplay={suggestion._id === this.state.suggestionHighlighted?._id}
                displayVertically={true}
              />
            );
          })}
      </ModalContainer>
    );
  }

  _getModalProps() {
    return {
      onClose: this.props.onClose,
      verticallyCentered: false,
    };
  }

  _getResultsCount() {
    return _.size(this._getNonConfidentialEntries()) + _.size(this.props.spaces);
  }

  _isLoading() {
    return this.props.getNewEntryDataStatus?.pending;
  }

  _renderN360PropertySearch() {
    return (
      <N360PropertySearchContainer
        modalInputValueFn={this._updateModalInputValue}
        updateN360PropertySearchHeightFn={this._updateN360PropertySearchHeight}
        hideGeoSuggestFn={this._hideGeoSuggestFn}
        searchFn={this.props.getN360Addresses}
        googlePropertySearch={this.googlePropertySearch}
        userEntriesResultsTable={this.userEntries}
        n360SpacesResultsTable={this.n360Spaces}
        updateRenderResultsFn={this._updateRenderResults}
        updateRenderGoogleFn={this._updateRenderGoogle}
        renderN360Suggests={this.state.renderN360Suggests}
        setSuggestionHighlighted={this._updateSuggestionHighlighted}
        setSuggestions={this._updateSuggestions}
        setSuggestionMedia={this._updateSuggestionMedia}
        onFocus={this._onFocus_N360PropertySearch}
        //modal: this
        onSelect={(suggestion) => {
          this.setState({ renderResults: true });
          this._updateSuggestionHighlighted(undefined);
          return this.props.getNewEntryData({ n360Address: suggestion });
        }}
        onSuggestionHighlighted={() => {
          if (this.state.googlePropertySearchFocused) {
            this.setState({ googlePropertySearchFocused: false });
          }
        }}
        ref={(node) => {
          return (this.n360PropertySearch = node);
        }}
        types={['n360api']}
      />
    );
  }

  _onFocus_N360PropertySearch() {
    return this.setState({ renderResults: false });
  }

  _renderGooglePropertySearch() {
    return (
      <GooglePropertySearch
        ignoreBlur={true}
        placeholder="THIS PLACEHOLDER SHOULD BE INVISIBLE"
        initialValue={this.state.modal_InputValue}
        inputValue={this.state.modal_InputValue}
        n360PropertySearchHeight={this.state.n360PropSearchHeight}
        visibility={this._getGeoSearchVisibility()}
        marginTop={this._getGeoSearchMarginTop()}
        n360PropertySearch={this.n360PropertySearch}
        getNewEntryDataFn={this.props.getNewEntryData}
        renderGoogle={this.state.renderGoogle}
        setGooglePropertySearchFocused={(val) =>
          this.setState({ googlePropertySearchFocused: val })
        }
        updateRenderResultsFn={this._updateRenderResults}
        updateRenderN360SuggestsFn={this._updateRenderN360Suggests}
        ref={(node) => {
          return (this.googlePropertySearch = node);
        }}
        types={['geocode']}
      />
    );
  }

  _renderResultsMessage() {
    if (this.state.renderResults) {
      // @_hideN360PropertySearchFn()
      const count = this._getResultsCount();
      if (!(count > 0)) {
        return;
      }
      const sources = [];
      if (this._getNonConfidentialEntries().length > 0) {
        sources.push('your past tour books');
      }
      if (this.props.spaces?.length > 0 && this.props.source === DATA_SOURCE_TYPES.N360) {
        sources.push("N360's databases");
      }
      return (
        <div
          ref={(node) => {
            return (this.resultsMessage = node);
          }}
          className={cx(['message'])}
        >
          <div className={cx(['count'])}>{`${count} Listings Found`}</div>
          <div className={cx(['description'])}>
            <div>{`We found listings from ${sources.join(' and ')}. `}</div>
            <div>Choose one, or create your own.</div>
          </div>
        </div>
      );
    }
  }

  _renderUserEntries() {
    if (this.state.renderResults) {
      return (
        <ResultsTable
          identifierRenderFn={function (entry) {
            return entry.tourbookName || 'Untitled Book';
          }}
          tourbookTitleRenderFn={function (entry) {
            return entry.tourbookName || 'Untitled Book';
          }}
          suiteRenderFn={function (entry) {
            return entry.suite || '-';
          }}
          items={this._getNonConfidentialEntries()}
          googlePropertySearch={this.googlePropertySearch}
          n360PropertySearch={this.n360PropertySearch}
          onRowClick={({ entryId, tourbookId }) => {
            return this.props.createEntry({
              userEntryId: entryId,
              userTourbookId: tourbookId,
            });
          }}
          titleRenderFn={function (count) {
            return (
              <div className={cx(['title'])}>
                <div className={cx(['text'])}>
                  {`Existing Tour Book Listings (${count}):`}
                </div>
              </div>
            );
          }}
          updateRenderFn={this._updateRenderFn}
          updateRenderGoogleFn={this._updateRenderGoogle}
          updateRenderN360SuggestsFn={this._updateRenderN360Suggests}
          ref={(node) => {
            return (this.userEntries = node);
          }}
          showTable={this.state.renderResults}
          className={cx(['user'])}
        />
      );
    }
  }

  _renderN360Error() {
    return (
      <div className={cx(['error'])}>
        <div className={cx(['line-1'])}>
          N360 listings data and images coming soon...
        </div>
        <div className={cx(['line-2'])} />
      </div>
    );
  }
  _renderN360Spaces() {
    if (this.state.renderResults) {
      return (
        <ResultsTable
          identifierRenderFn={(entry) => this._formatSuite(entry)}
          tourbookTitleRenderFn={function (entry) {
            return '';
          }}
          suiteRenderFn={(entry) => this._formatSuite(entry)}
          items={
            this.props.source === DATA_SOURCE_TYPES.N360 && !this.props.spaces?.noReponse
              ? this.props.spaces
              : []
          }
          googlePropertySearch={this.googlePropertySearch}
          n360PropertySearch={this.n360PropertySearch}
          updateRenderGoogleFn={this._updateRenderGoogle}
          updateRenderN360SuggestsFn={this._updateRenderN360Suggests}
          onRowClick={(space, event, index) => {
            if (event.target.type === 'checkbox') {
              this._updateMultiSpaceSelection(
                event.target.checked
                  ? [...this.state.multiSpaceSelection, { ...space, index }]
                  : [...this.state.multiSpaceSelection.filter((s) => s.index !== index)]
              );
            } else {
              this.props.createEntry({ space });
            }
          }}
          // checkbox={true}
          titleRenderFn={function (count) {
            return (
              <div className={cx(['title'])}>
                <div className={cx(['text'])}>{`Database (${count}):`}</div>
              </div>
            );
          }}
          ref={(node) => {
            return (this.n360Spaces = node);
          }}
          showTable={this.state.renderResults}
          className={cx(['n360'])}
        />
      );
    }
  }

  _formatSuite(space) {
    if (space.suite != null) {
      if (space.suite === '') {
        return '-';
      } else {
        return 'Ste ' + space.suite;
      }
    } else {
      return '-';
    }
  }

  _renderCreateButton() {
    if (this.state.renderResults) {
      return (
        <a
          onClick={(event) => {
            if (this.state.multiSpaceSelection.length > 0) {
              // Single-and-multi select case when user DOES select N360 result
              // Add just one listing to the tourbook then update the listing's metadata
              const newSpace = this.state.multiSpaceSelection[0];
              this.props.createEntry(newSpace).then(() => {
                this.props.addAdditionalFields({
                  entryId: this._findExistingEntry(newSpace.propertyId).key,
                  fields: this._getSpaceMetadataToAdd(
                    this.state.multiSpaceSelection
                  ),
                  section: 'space',
                });
                this.state.multiSpaceSelection = [];
              });
            } else {
              // Single-select case, when user does NOT select an N360 result
              this.props.createEntry({ space: {} }); // Just add property, not space
            }
          }}
          className={cx(['create'])}
        >
          Create New Listing
        </a>
      );
    }
  }

  _findExistingEntry(propertyId) {
    const state = store.getState();
    let entries = state.tourbookEditorPage.data.content.entries;
    entries = Object.entries(entries); // object -> [[key, value], [entryId, entry], ...]
    entries = entries.reverse(); // This assumes that one is interested in the most recent entry if duplicates
    const matchingEntries = entries.filter(
      ([_, entry]) => entry.fields.property.propertyId === propertyId
    );
    return matchingEntries.length
      ? { key: matchingEntries[0][0], value: matchingEntries[0][1] }
      : {};
  }

  _getSpaceMetadataToAdd(multiSpaces) {
    return multiSpaces
      .map((space) =>
        spaceMetadatas
          .filter(({ key }) => {
            return (
              columnsToDisplayForMultiSpaceSummaryTable.map((f) => f.key).includes(key) ||
              (space[key] !== undefined && space[key] !== null)
            );
          })
          .map(({ key: fieldDefinitionKey, label: fieldDefinitionLabel, type }) => {
            const defaultLabel = this.props.fieldDefinitions[fieldDefinitionKey]?.label;
            fieldDefinitionLabel = defaultLabel ? defaultLabel : fieldDefinitionLabel;

            let label = '';
            if (space['suite']) {
              label = `Suite ${space['suite']} - ${fieldDefinitionLabel}`;
            } else if (space['floor']) {
              label = `Floor ${space['floor']} - ${fieldDefinitionLabel}`;
            }
            const key = generateMultiSpace({
              fieldDefinitionKey,
              label,
            }).key;

            let value = space[fieldDefinitionKey] || 0;
            if (type === 'range') {
              value = { min: String(value) };
            }

            return {
              key,
              label,
              value,
              newField: true,
            };
          })
      )
      .flat();
  }

  _hideGeoSuggestFn() {
    if (this.googlePropertySearch != null) {
      this.googlePropertySearch.geoSuggest.hideSuggests();
      return this.googlePropertySearch._hideSubTitle();
    }
  }

  _showGeoSuggestFn() {
    if (this.googlePropertySearch != null) {
      return this.googlePropertySearch._showSubTitle;
    }
  }

  _getGeoSearchVisibility() {
    if ((this.state.modal_n360PropSearchHeight = 0)) {
      return 'hidden';
    } else {
      return 'visible';
    }
  }

  _getGeoSearchMarginTop() {
    return (this.state.modal_n360PropSearchHeight = 0);
  }

  _hideN360PropertySearchFn() {
    if (this.n360PropertySearch != null) {
      return (this.n360PropertySearch.autosuggest.visibility = 'hidden');
    }
  }
}
NewEntryModal.initClass();

module.exports = NewEntryModal;
