const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const Image = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentModal',
]);

module.exports = build({
  component: Image,
  mapDispatchToProps,
  serviceNames: ['imageService', 'imageUrlService'],
});
