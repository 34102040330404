const { actions, build } = require('@components/container_helpers');
const DownloadPdfModal = require('./');

const statusKey = 'tourbookEditorPage/generatePdf';

const mapDispatchToProps = function (dispatch) {
  const { generatePdf, recordTourbookPublish } = actions.tourbookEditorPage;
  return {
    generatePdf(tourbookHtml) {
      return dispatch(generatePdf(tourbookHtml));
    },
    recordTourbookPublish(publishMethod) {
      return dispatch(recordTourbookPublish(publishMethod));
    },
    resetStatus() {
      return dispatch(actions.promiseInspections.reset(statusKey));
    },
  };
};

const mapStateToProps = function (state) {
  const status = state.promiseInspections[statusKey];
  return {
    error: status?.error,
    pending: status?.pending,
    pdfUrl: status?.value,
  };
};

module.exports = build({
  component: DownloadPdfModal,
  mapDispatchToProps,
  mapStateToProps,
});
