exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 5 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/index.styl */\n.date_input---root---1BNiJXdlX-SEvqNVjgJfzl {\n  width: 100%;\n}\n\n/* line 4 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/date_input/index.styl */\n.date_input---root---1BNiJXdlX-SEvqNVjgJfzl .react-datepicker__input-container {\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"root": "date_input---root---1BNiJXdlX-SEvqNVjgJfzl"
};