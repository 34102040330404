exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 20 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/range_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/range_input/index.styl */\n.range_input---root---18Hyhuw6L9enDIBpDJW2Hc {\n  display: -ms-flexbox;\n  display: flex;\n}\n\n/* line 4 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/range_input/index.styl */\n.range_input---currency---Ef96lqnqZcUkNxT8iut__,\n.range_input---min---2vkDKVoyFd0s_Rg0uznjg3,\n.range_input---divider---FywSnC9dccx_5Lr8oRgeH,\n.range_input---max---3X0nT1eTQoFXQ8FI3q91aY {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  height: 100%;\n}\n\n/* line 9 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/range_input/index.styl */\n\n/* line 10 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/range_input/index.styl */\n.range_input---min---2vkDKVoyFd0s_Rg0uznjg3 input,\n.range_input---max---3X0nT1eTQoFXQ8FI3q91aY input {\n  text-align: center;\n}\n\n/* line 13 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/range_input/index.styl */\n.range_input---divider---FywSnC9dccx_5Lr8oRgeH {\n  margin: 0 5px;\n}\n\n/* line 16 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/range_input/index.styl */\n.range_input---units---3BZFq2CsVMneQWF5WeZfZr {\n  margin-left: 5px;\n}\n\n/* line 19 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/range_input/index.styl */\n.range_input---currency---Ef96lqnqZcUkNxT8iut__ {\n  margin-right: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "range_input---root---18Hyhuw6L9enDIBpDJW2Hc",
	"currency": "range_input---currency---Ef96lqnqZcUkNxT8iut__",
	"min": "range_input---min---2vkDKVoyFd0s_Rg0uznjg3",
	"divider": "range_input---divider---FywSnC9dccx_5Lr8oRgeH",
	"max": "range_input---max---3X0nT1eTQoFXQ8FI3q91aY",
	"units": "range_input---units---3BZFq2CsVMneQWF5WeZfZr"
};