const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const App = require('./');

const mapDispatchToProps = _.pick(actions.userSession, [
  'login',
  'logout',
  'signup',
]);

const mapStateToProps = (state) => ({
  loginStatus: state.promiseInspections['userSession/login'],
  signupStatus: state.promiseInspections['userSession/signup'],
  user: state.userSession,
});

module.exports = build({
  component: App,
  mapDispatchToProps,
  mapStateToProps,
});
