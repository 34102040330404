const { locateService } = require('@services/service_locator');

const identityActionTypes = ['set'];

const mappingBuilder = function ({ promiseInspection }) {
  const auth0Service = locateService('auth0Service');

  return {
    initialize() {
      return promiseInspection(
        'initialize',
        async function (dispatch) {
          const user = await auth0Service.initialize();
          return dispatch(this.set(user));
        }.bind(this)
      );
    },

    login(options) {
      return promiseInspection(
        'login',
        async function (dispatch) {
          const user = await auth0Service.login(options);
          return dispatch(this.set(user));
        }.bind(this)
      );
    },

    logout() {
      return (dispatch) => {
        auth0Service.logout();
        return dispatch(this.set(null));
      };
    },

    signup(options) {
      return promiseInspection(
        'signup',
        async function (dispatch) {
          const user = await auth0Service.signup(options);
          return dispatch(this.set(user));
        }.bind(this)
      );
    },
  };
};

module.exports = {
  identityActionTypes,
  mappingBuilder,
  namespace: 'userSession',
};
