const _ = require('lodash');

const spaceMetadatas = [
  { key: 'availableDate', label: 'Available Date' },
  { key: 'availableSize', label: 'Available Size', type: 'range' },
  { key: 'comments', label: 'Comments' },
  { key: 'floor', label: 'Floor', required: true, sort: 1 },
  { key: 'floorDescription', label: 'Floor Description' },
  { key: 'listingType', label: 'Listing Type' },
  { key: 'officeClass', label: 'Office Class', type: 'select' },
  { key: 'price', label: 'Price', required: true, sort: 3 },
  {
    key: 'smallestAvailableSpace',
    label: 'Size',
    type: 'range',
    required: true,
    sort: 2,
  },
  { key: 'spaceType', label: 'Space Type' },
  { key: 'submarket', label: 'Submarket' },
  { key: 'subtype', label: 'Subtype' },
  { key: 'suite', label: 'Suite' },
  { key: 'suiteStatus', label: 'Suite Status' },
  { key: 'tenantSiteType', label: 'Tenant Site Type' },
];

const columnsToDisplayForMultiSpaceSummaryTable = spaceMetadatas.filter((m) => m.required).sort((m1, m2) => m1.sort - m2.sort);

/**
 * fieldKey can either be fieldDefinitionKey or multiSpaceKey
 *
 *                          example
 * fieldDefinitionKey       availableDate                   Can be used to access fieldDefinitions array to get data type and other info
 * fieldDefinitionLabel     Available Date
 *
 * multiSpaceKey            suite1605-availableDate         space (key) = "suite1605", fieldDefinition (key) = "availableDate"
 * multiSpaceLabel          Suite 1605 - Available Date
 */

const isMultiSpace = (keyOrLabel) => keyOrLabel.includes('-');

const generateMultiSpace = ({
  key,
  label,
  spaceKey,
  fieldDefinitionKey,
  spaceLabel,
  fieldDefinitionLabel,
}) => {
  if (
    !key &&
    !label &&
    (!spaceKey || !fieldDefinitionKey) &&
    (!spaceLabel || !fieldDefinitionLabel)
  ) {
    return {};
  }

  if ((spaceKey && fieldDefinitionKey) || key) {
    if (spaceKey && fieldDefinitionKey) {
      key = key ? key : `${spaceKey}-${fieldDefinitionKey}`;
    } else if (key) {
      spaceKey = key.split('-')[0];
      fieldDefinitionKey = key.split('-')[1];
    }
    spaceLabel = _.startCase(spaceKey);
    fieldDefinitionLabel = _.startCase(fieldDefinitionKey);
    label = `${spaceLabel} - ${fieldDefinitionLabel}`;
  }

  if ((spaceLabel && fieldDefinitionLabel) || label) {
    if (spaceLabel && fieldDefinitionLabel) {
      label = `${spaceLabel} - ${fieldDefinitionLabel}`;
    } else if (label) {
      spaceLabel = label.replace(/ /g, '').split('-')[0];
      fieldDefinitionLabel = label.replace(/ /g, '').split('-')[1];
    }
    spaceKey = spaceKey ? spaceKey : _.camelCase(spaceLabel);
    fieldDefinitionKey = fieldDefinitionKey
      ? fieldDefinitionKey
      : _.camelCase(fieldDefinitionLabel);
    key = key ? key : `${spaceKey}-${fieldDefinitionKey}`;
  }

  return {
    key,
    label,
    spaceKey,
    fieldDefinitionKey,
    spaceLabel,
    fieldDefinitionLabel,
  };
};

const fieldKeyToFieldDefinitionKey = (fieldKey) =>
  isMultiSpace(fieldKey)
    ? generateMultiSpace({ key: fieldKey }).fieldDefinitionKey
    : fieldKey;

module.exports = {
  columnsToDisplayForMultiSpaceSummaryTable,
  spaceMetadatas,
  isMultiSpace,
  generateMultiSpace,
  fieldKeyToFieldDefinitionKey,
};
