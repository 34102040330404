const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ModalContainer = require('../modal/container');
const PropTypes = require('prop-types');
const React = require('react');

class ConfirmModal extends React.Component {
  static initClass() {
    this.defaultProps = {
      cancelLabel: 'Cancel',
      onCancel: _.noop,
    };

    this.propTypes = {
      cancelLabel: PropTypes.string,
      confirmLabel: PropTypes.string.isRequired,
      message: PropTypes.array.isRequired,
      onCancel: PropTypes.func,
      onConfirm: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <ModalContainer onClose={this.props.onCancel} verticallyCentered={true}>
        <div className={cx(['root'])}>
          <div className={cx(['messages'])}>
            {_.map(this.props.message, (message, index) =>
              <div key={index} className={cx(['message'])}>
                {message}
              </div>
            )}
          </div>
          <a onClick={this.props.onConfirm} className={cx(['confirm'])}>
            {this.props.confirmLabel}
          </a>
          <a onClick={this.props.onCancel} className={cx(['cancel'])}>
            {this.props.cancelLabel}
          </a>
        </div>
      </ModalContainer>
    );
  }
}
ConfirmModal.initClass();

module.exports = ConfirmModal;
