const _ = require('lodash');
const madison = require('madison');
const numeral = require('numeral');
const Promise = require('bluebird');
const querystring = require('querystring');
const { locateService } = require('../service_locator');

class WalkscoreService {
  static initClass() {
    // data = {address, city, latitude, longitude, state, zip}
    this.prototype.get = async function (data) {
      const [stopsData, transitScoreData, walkScoreData] = Array.from(
        await Promise.all([
          this._getStopsData(data),
          this._getTransitScoreData(data),
          this._getWalkScoreData(data),
        ])
      );
      return _.assign({}, stopsData, transitScoreData, walkScoreData);
    };

    this.prototype._getStopsData = async function (
      {
        latitude,
        longitude,
      }
    ) {
      const queryObject = { action: 'stops', lat: latitude, lon: longitude };
      const {
        data: { data, status },
      } = await this._makeRequest(queryObject);
      if (status !== 200) {
        return {};
      }
      return { nearbyTransportation: this._parseStops(data) };
    };

    this.prototype._getTransitScoreData = async function (
      {
        city,
        latitude,
        longitude,
        state,
      }
    ) {
      if (state.length > 2) {
        state = madison.getStateAbbrev(state);
      }
      const queryObject = {
        action: 'transit-score',
        city,
        lat: latitude,
        lon: longitude,
        state,
      };
      const {
        data: { data, status },
      } = await this._makeRequest(queryObject);
      if (status !== 200) {
        return {};
      }
      return {
        transitscore: data.transit_score,
        transitscoreText: data.description,
      };
    };

    this.prototype._getWalkScoreData = async function (
      {
        address,
        city,
        latitude,
        longitude,
        state,
        zip,
      }
    ) {
      const fullAddress = `${address} ${city}, ${state} ${zip}`;
      const queryObject = {
        action: 'score',
        address: fullAddress,
        lat: latitude,
        lon: longitude,
      };
      const {
        data: { data, status },
      } = await this._makeRequest(queryObject);
      if (status !== 200 || data.status !== 1) {
        return {};
      }
      return { walkscore: data.walkscore, walkscoreText: data.description };
    };
  }

  constructor({ ENV: { firebaseFunctionsConfig }, axios }) {
    this.firebaseFunctionsConfig = firebaseFunctionsConfig;
    this.axios = axios;
    this.auth0Service = locateService('auth0Service');
  }

  _getHeaders() {
    return { "Authorization": `Bearer ${this.auth0Service.localStorage.auth0IdToken}` };
  }

  _makeRequest(queryObject) {
    queryObject = _.assign({}, queryObject, {
      format: 'json',
      webtask_no_cache: 1,
    });
    const url = `${this.firebaseFunctionsConfig.url
      }/apiWalkscore?${querystring.stringify(queryObject)}`;
    const headers = this._getHeaders();
    return this.axios({ url, headers });
  }

  _parseStops(data) {
    return _.chain(data)
      .map((stop) =>
        _.map(stop.route_summary, (route) => ({
          category: route.category,
          distance: stop.distance,
          name: route.name.split(' ')[0],
        }))
      )
      .flatten()
      .groupBy('name')
      .map((routeGroup) => ({
        category: routeGroup[0].category,
        distance: _.chain(routeGroup).map('distance').min().value(),
        name: routeGroup[0].name,
      }))
      .orderBy(['category', 'distance'], ['desc', 'asc'])
      .take(9)
      .each((route) => (route.distance = numeral(route.distance).format('0.0')))
      .value();
  }
}
WalkscoreService.initClass();

module.exports = WalkscoreService;
