/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const UserEntriesService = require('./user_entries_service');
const { stubService } = require('./service_locator');

describe('UserEntriesService', function () {
  beforeEach(function () {
    this.userTourbooksService = stubService('userTourbooksService');
    return (this.userEntriesService = new UserEntriesService());
  });

  return describe('getAll', function () {
    beforeEach(function () {
      this.userTourbooksService.getAllMeta.resolves({
        tourbook1: {
          date: '11/10/11',
          entries: {
            entry1: {
              address: 'address1 city1 ny',
              floor: 'floor1',
              space: 'space1',
            },
            entry2: {
              address: 'address2 city2 ny',
              floor: 'floor2',
              space: 'space2',
            },
          },
          name: 'name1',
        },
        tourbook2: {
          date: '11/10/11',
          entries: {
            entry3: {
              address: 'address3 city3 ny',
              floor: 'floor3',
              space: 'space3',
            },
          },
          name: 'name2',
        },
      });
      this.userTourbooksService.getAllSharedMeta.resolves({
        tourbook3: {
          date: '11/11/11',
          entries: {
            entry4: {
              address: 'address1 city1 ny',
              floor: 'floor4',
              space: 'space4',
            },
          },
          name: 'name3',
        },
      });
      return (this.property = {
        CorrectedAddress1: 'address1',
        city: 'city1',
        state: 'new york',
      });
    });

    return it(
      'returns a list of entries with a specific address ordered by most recent date',
      async function() {
        const result = await this.userEntriesService.getAll(
          'user1',
          this.property
        );
        return expect(result).to.eql([
          {
            address: 'address1 city1 ny',
            entryId: 'entry4',
            floor: 'floor4',
            space: 'space4',
            tourbookDate: '11/11/11',
            tourbookId: 'tourbook3',
            tourbookName: 'name3',
          },
          {
            address: 'address1 city1 ny',
            entryId: 'entry1',
            floor: 'floor1',
            space: 'space1',
            tourbookDate: '11/10/11',
            tourbookId: 'tourbook1',
            tourbookName: 'name1',
          },
        ]);
      }
    );
  });
});
