const React = require('react');
const { locateServices } = require('@services/service_locator');
const Loading = require('@components/shared/loading');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const bowser = require('bowser');
const Popover = require('@components/shared/popover');
const { useParams, useNavigate } = require('react-router-dom');

const ListingPopoverContent = ({ listings = [] }) => {
  return (
    <Popover>
      <ul>
        {listings.map((listing, i) => {
          return <li key={i}>{listing}</li>;
        })}
      </ul>
    </Popover>
  );
}

const ListingPopover = ({ listingPromises }) => {
  const [listings, setListings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const serviceWithData = await Promise.all(listingPromises).then(
        (values) => {
          return values;
        }
      );
      setListings(serviceWithData);
      setIsLoading(false);
    };
    getData();
  }, [setIsLoading, setListings, listingPromises]);

  if (isLoading) {
    return <></>;
  }
  return (
    <ListingPopoverContent listings={listings} />
  );
};

const AnalyticsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    analyticsService,
    tourbooksService,
    cloudinaryService,
    navigationService,
    mobileService,
  } = locateServices([
    'analyticsService',
    'tourbooksService',
    'cloudinaryService',
    'navigationService',
    'mobileService',
  ]);
  const sentToDisplayLimit = 3;
  const { tourbookId } = params;
  const [data, setData] = React.useState();
  const [tourbook, setTourbook] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const serviceWithData = await analyticsService._getAnalytics(tourbookId);
      const tourbookRes = await tourbooksService.get(tourbookId);
      const tourbookLikes = await tourbooksService.getLikes(tourbookId);
      const tourbookDisLikes = await tourbooksService.getDisLikes(tourbookId);

      const [likes = [], dislikes = []] = [tourbookLikes, tourbookDisLikes].map(tbLikeInfo => {
        const likeEntryIds = Object.keys(tbLikeInfo || {});
        return likeEntryIds.reduce((acc, entryId) => [
          ...acc,
          ...(Object.values(tbLikeInfo[entryId]) || []).map(value => ({
            ...value,
            entryId
          })),
        ], []);
      });


      setTourbook(tourbookRes);
      setData({
        ...serviceWithData,
        likes,
        dislikes
      });
      setIsLoading(false);
      return serviceWithData;
    };
    getData();
  }, [setIsLoading, setData, analyticsService._getAnalytics]);
  if (isLoading && !data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const isEntryEmpty = (entryId) =>
    _.isEmpty(
      tourbook?.content?.entries && tourbook?.content?.entries[entryId]
    );

  const getCountsBasedOnSession = (likes, session) => {
    return likes.filter(
      (like) =>
        (like.email === session.user || like.browserId === session.browserId) &&
        !isEntryEmpty(like.entryId) &&
        like.time >= session.startTime &&
        like.time < session.endTime
    );
  };

  const removeEmptyEntries = (entries) =>
    entries?.filter(({ entryId }) => !isEntryEmpty(entryId)) || [];

  const sessionsTableData = data?.sessions.length
    ? data?.sessions.map((session) => ({
        ...session,
        likes: getCountsBasedOnSession(data.likes, session),
        dislikes: getCountsBasedOnSession(data.dislikes, session),
      }))
    : Array.from(_.range(5)).map((number) => {
        return {
          user: `Anonymous User ${number}`,
          date: Date.now(),
          duration: '12 mins',
          likes: [],
          dislikes: [],
          listingsViewed: [],
          location: 'New York, NY',
          device: 'Desktop',
        };
      });

  const getEntryName = (entry) => {
    return (
      entry?.property?.name ||
      entry?.fields?.header?.CorrectedAddress1 ||
      '(No Address)'
    );
  };

  return (
    <div className={cx(['tourbook-analytics'])}>
      <div className={cx(['general-info'])}>
        <h1>Analytics</h1>
        <div className={cx(['general-info-content'])}>
          <div className={cx(['left-general'])}>
            <div
              className={cx(['tourbook-img'])}
              style={{
                backgroundImage:
                  tourbook &&
                  'url(' +
                    cloudinaryService.getImageUrl(
                      tourbook.content.cover.imagesShown.coverPhoto
                    ) +
                    ')',
              }}
            >
              <div
                onClick={() => {
                  navigate(`/tourbook/${tourbookId}`);
                }}
                className={cx(['overlay'])}
              >
                <div className={cx(['text'])}>Open Tour Book</div>
              </div>
            </div>
            <div className={cx(['tourbook-info'])}>
              <h4 className={cx(['section-label'])}>Name:</h4>
              <p className={cx(['title'])}>
                {tourbook?.content.cover.fields.title}
              </p>
              <h4 className={cx(['section-label'])}>Date:</h4>
              <p className={cx(['date'])}>
                {tourbook?.content.cover.fields.date
                  ? analyticsService._formatDate(
                      tourbook.content.cover.fields.date
                    )
                  : '(No Tour Date)'}
              </p>
              <a
                href={mobileService.getUrl(tourbookId)}
                target="_blank"
                className={cx(['analytics-link'])}
              >
                Visit Digital Tour Book
              </a>
            </div>
          </div>
          <div className={cx(['right-general'])}>
            <h4 className={cx(['section-label', 'sent-label'])}>Sent To</h4>
            {!analyticsService.sentTo ||
            analyticsService?.sentTo?.length === 0 ? (
              <div>This tour book has not been sent to the client</div>
            ) : (
              <>
                {_.range(
                  analyticsService?.sentTo?.length > sentToDisplayLimit
                    ? sentToDisplayLimit
                    : analyticsService.sentTo.length
                ).map((index) => {
                  const { email, time } = analyticsService.sentTo[index];
                  return (
                    <div className={cx(['sent-to-user'])}>
                      <span className={cx(['sent-email'])}>{email}</span>
                      <span className={cx(['sent-date'])}>{new Date(time).toLocaleDateString()}</span>
                    </div>
                  );
                })}
              </>
            )}
            <div
              className={cx([
                'send-to-client-container',
                analyticsService?.sentTo?.length > 0 ? 'left' : '',
              ])}
            >
              <div className={cx(['send-to-client'])}>
                <a
                  href={`${navigationService.getTourbookPath(
                    tourbookId
                  )}?sendToClient=true`}
                  className={cx(['analytics-link'])}
                >
                  <div className={cx(['fa', 'fa-share'])}>Send to Client</div>
                </a>
                {/* TODO: 
                    spaceful-popover-list.more-emails(
                    ng-if="sentToHidden.length > 0"
                    popover-list-data="sentToHidden"
                    popover-list-placement="bottom"
                    popover-list-trigger="mouseenter"
                    popover-list-label="'+ ' + sentToHidden.length + ' More'"
                  )
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(['summary-metrics'])}>
        <div className={cx(['metric'])}>
          <p className={cx(['value'])}>{data?.totalViews}</p>
          <p className={cx(['description'])}>Total Views</p>
        </div>
        <div className={cx(['separator'])}></div>
        <div className={cx(['metric'])}>
          <p className={cx(['value'])}>{data?.uniqueViews}</p>
          <p className={cx(['description'])}>Unique Views</p>
        </div>
        <div className={cx(['separator'])}></div>
        <div className={cx(['metric'])}>
          <p className={cx(['value', 'total-time-spent'])}>
            {data?.totalTimeSpent}
          </p>
          <p className={cx(['description'])}>Total Time Spent</p>
        </div>
        <div className={cx(['separator'])}></div>
        <div className={cx(['metric'])}>
          <p className={cx(['value'])}>{data?.photosUploaded || '--'}</p>
          <p className={cx(['description'])}>Photos Uploaded</p>
        </div>
        <div className={cx(['separator'])}></div>
        <div className={cx(['metric'])}>
          <p className={cx(['value'])}>
            {removeEmptyEntries(data?.likes).length || '--'}
          </p>
          <p className={cx(['description'])}>Likes</p>
        </div>
        <div className={cx(['separator'])}></div>
        <div className={cx(['metric'])}>
          <p className={cx(['value'])}>
            {removeEmptyEntries(data?.dislikes).length || '--'}
          </p>
          <p className={cx(['description'])}>DisLikes</p>
        </div>
      </div>
      {!data?.hasSessions && <hr />}
      <div className={cx(['sessions-log'])}>
        <div
          className={cx([
            'table',
            !data?.hasSessions ? 'blur' : '',
            bowser.name === 'Internet Explorer' ? 'is-ie' : '',
          ])}
        >
          <div className={cx(['header-row'])}>
            <div className={cx(['header-row-content'])}>
              <div className={cx(['cell', 'user'])}>User</div>
              <div className={cx(['cell', 'date'])}>Date</div>
              <div className={cx(['cell', 'duration'])}>Duration</div>
              <div className={cx(['cell', 'listingsViewed'])}>
                Listings Viewed
              </div>
              <div className={cx(['cell', 'likes'])}>Likes</div>
              <div className={cx(['cell', 'disLikes'])}>DisLikes</div>
              <div className={cx(['cell', 'location'])}>Location</div>
              <div className={cx(['cell', 'device'])}>Device</div>
            </div>
            {sessionsTableData.map((session, i) => {
              return (
                <div key={i} className={cx(['analytics-row'])}>
                  <div className={cx(['row-content'])}>
                    <div className={cx(['cell', 'body-cell', 'user'])}>
                      <span>{session.user}</span>
                      {session.isOwnerOrCollaborator && (
                        <span className={cx(['owner-or-collaborator'])}>
                          {session.user}
                        </span>
                      )}
                    </div>
                    <div className={cx(['cell', 'body-cell', 'date'])}>
                      {session.date}
                    </div>
                    <div className={cx(['cell', 'body-cell', 'duration'])}>
                      {session.duration}
                    </div>
                    <div
                      className={cx(['cell', 'body-cell', 'listingsViewed'])}
                    >
                      {session?.listingsViewed?.length < 1 ? (
                        <span className={cx(['owner-or-collaborator'])}>0</span>
                      ) : (
                        <span>
                          <div className={cx(['popover-container'])}>
                            <span className={cx(['popover-label'])}>
                              {session?.listingsViewed?.length}
                            </span>
                            <div className={cx(['popover-wrapper'])}>
                              <ListingPopover
                                listingPromises={session?.listingsViewed}
                              />
                            </div>
                          </div>
                        </span>
                      )}
                    </div>
                    <div className={cx(['cell', 'body-cell', 'likes'])}>
                      <span>
                        <div className={cx(['popover-container'])}>
                          <span
                            className={cx([
                              'popover-label',
                              (session.likes || []).length === 0 && 'zeroState',
                            ])}
                          >
                            {(session.likes || []).length}
                          </span>
                          {(session.likes || []).length > 0 && (
                            <div className={cx(['popover-wrapper'])}>
                              <ListingPopoverContent
                                listings={(session.likes || []).map((like) =>
                                  getEntryName(
                                    tourbook?.content?.entries[like.entryId]
                                  )
                                )}
                              />
                            </div>
                          )}
                        </div>
                      </span>
                    </div>
                    <div className={cx(['cell', 'body-cell', 'disLikes'])}>
                      <span>
                        <div className={cx(['popover-container'])}>
                          <span
                            className={cx([
                              'popover-label',
                              (session.dislikes || []).length === 0 &&
                                'zeroState',
                            ])}
                          >
                            {(session.dislikes || []).length}
                          </span>
                          {(session.dislikes || []).length > 0 && (
                            <div className={cx(['popover-wrapper'])}>
                              <ListingPopoverContent
                                listings={(session.dislikes || []).map((like) =>
                                  getEntryName(
                                    tourbook?.content.entries[like.entryId]
                                  )
                                )}
                              />
                            </div>
                          )}
                        </div>
                      </span>
                    </div>
                    <div className={cx(['cell', 'body-cell', 'location'])}>
                      {session.location}
                    </div>
                    <div className={cx(['cell', 'body-cell', 'device'])}>
                      {session.device}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={cx(['disclaimer'])}>
          {data?.analyticsHasOwnerOrCollaborator && (
            <span className={cx(['analytics-disclaimer'])}>
              | * The tour book owner/collaborator is not counted in any summary
              metrics
            </span>
          )}
        </div>
        {!data?.hasSessions && (
          <div className={cx(['zero-overlay'])}>
            <div className={cx(['zero-state-logo'])} />
            <h5>There is no data yet for this tour book</h5>
            <h6>
              Send your client a link to the digital tour book to start tracking
              analytics
            </h6>
            <div className={cx(['send-to-client'])}>
              <a
                href={`${navigationService.getTourbookPath(
                  tourbookId
                )}?sendToClient=true`}
                className={cx(['analytics-link'])}
              >
                <div className={cx(['fa', 'fa-share'])}>Send to Client</div>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

module.exports = AnalyticsPage;
