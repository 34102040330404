/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const AmenitiesService = require('./amenities_service');

describe('AmenitiesService', function () {
  beforeEach(function () {
    this.entry = {
      fields: {
        header: {
          CorrectedAddress1: '123 My Street',
          city: 'My City',
          state: 'My State',
          zip: '12345',
        },
      },
      location: {
        latitude: 1.2,
        longitude: 3.4,
      },
    };
    this.amenitiesService = new AmenitiesService({ ENV: {} });
    sinon.stub(this.amenitiesService.factual, 'getNearbyBusinesses');
    sinon.stub(this.amenitiesService.foursquare, 'getNearbyVenues');
    sinon.stub(this.amenitiesService.nearbyPlaces, 'getLocationImages');
    return sinon.stub(this.amenitiesService.walkscore, 'get');
  });

  describe('getData', function () {
    describe('type is factual', function () {
      beforeEach(
        async function() {
          this.amenitiesService.factual.getNearbyBusinesses.resolves({
            factual: 'data',
          });
          return this.result = await this.amenitiesService.getData(
            'factual',
            this.entry
          );
        }
      );

      it('calls the factualService with the proper attributes', function () {
        return expect(
          this.amenitiesService.factual.getNearbyBusinesses
        ).to.have.been.calledWith({
          address: '123 My Street',
          latitude: 1.2,
          longitude: 3.4,
        });
      });

      return it('returns the factual data merged with type and isAmenity: true', function () {
        return expect(this.result).to.eql({
          factual: 'data',
          isAmenity: true,
          type: 'factual',
        });
      });
    });

    describe('type is foursquare', function () {
      beforeEach(
        async function() {
          this.amenitiesService.foursquare.getNearbyVenues.resolves({
            foursquare: 'data',
          });
          return this.result = await this.amenitiesService.getData(
            'foursquare',
            this.entry
          );
        }
      );

      it('calls the factualService with the proper attributes', function () {
        return expect(
          this.amenitiesService.foursquare.getNearbyVenues
        ).to.have.been.calledWith({
          latitude: 1.2,
          longitude: 3.4,
        });
      });

      return it('returns the factual data merged with type and isAmenity: true', function () {
        return expect(this.result).to.eql({
          foursquare: 'data',
          isAmenity: true,
          type: 'foursquare',
        });
      });
    });

    describe('type is nearby_places', function () {
      beforeEach(
        async function() {
          this.amenitiesService.nearbyPlaces.getLocationImages.resolves({
            nearby_places: 'data',
          });
          return this.result = await this.amenitiesService.getData(
            'nearby_places',
            this.entry
          );
        }
      );

      it('calls the nearbyPlacesService with the proper attributes', function () {
        return expect(
          this.amenitiesService.nearbyPlaces.getLocationImages
        ).to.have.been.calledWith({
          latitude: 1.2,
          longitude: 3.4,
        });
      });

      return it('returns the nearby places data merged with type and isAmenity: true', function () {
        return expect(this.result).to.eql({
          isAmenity: true,
          nearby_places: 'data',
          type: 'nearby_places',
        });
      });
    });

    return describe('type is walkscore', function () {
      beforeEach(
        async function() {
          this.amenitiesService.walkscore.get.resolves({ walkscore: 'data' });
          return this.result = await this.amenitiesService.getData(
            'walkscore',
            this.entry
          );
        }
      );

      it('calls the factualService with the proper attributes', function () {
        return expect(
          this.amenitiesService.walkscore.get
        ).to.have.been.calledWith({
          address: '123 My Street',
          city: 'My City',
          latitude: 1.2,
          longitude: 3.4,
          state: 'My State',
          zip: '12345',
        });
      });

      return it('returns the factual data merged with type and isAmenity: true', function () {
        return expect(this.result).to.eql({
          isAmenity: true,
          type: 'walkscore',
          walkscore: 'data',
        });
      });
    });
  });

  describe('getEntryUpdates', function () {
    beforeEach(
      async function() {
        this.amenitiesService.factual.getNearbyBusinesses.resolves({
          factual: 'data',
        });
        this.amenitiesService.foursquare.getNearbyVenues.resolves({
          foursquare: 'data',
        });
        this.amenitiesService.nearbyPlaces.getLocationImages.resolves({
          nearby_places: 'data',
        });
        this.amenitiesService.walkscore.get.resolves({ walkscore: 'data' });
        return this.result = await this.amenitiesService.getEntryUpdates(
          this.entry
        );
      }
    );

    return it('returns updates for the amenities pages', function () {
      return expect(this.result).to.eql({
        pages: {
          amenities: {
            bottom_left: {
              factual: 'data',
              isAmenity: true,
              type: 'factual',
            },
            bottom_right: {
              foursquare: 'data',
              isAmenity: true,
              type: 'foursquare',
            },
            top_left: {
              isAmenity: true,
              nearby_places: 'data',
              type: 'nearby_places',
            },
            top_right: {
              isAmenity: true,
              type: 'walkscore',
              walkscore: 'data',
            },
          },
        },
      });
    });
  });

  return describe('getTitle', () =>
    it('returns the proper title for each type', function () {
      expect(this.amenitiesService.getTitle('factual')).to.eql(
        'Nearby Businesses'
      );
      expect(this.amenitiesService.getTitle('foursquare')).to.eql(
        'Top Spots Around'
      );
      expect(this.amenitiesService.getTitle('nearby_places')).to.eql(
        'Nearby Photos'
      );
      return expect(this.amenitiesService.getTitle('walkscore')).to.eql(
        'Transportation Options'
      );
    }));
});
