const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const ContactInfo = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentField',
  'setCurrentTab',
]);

const mapStateToProps = (state) =>
  _.pick(state.tourbookEditorPage, ['currentField']);

module.exports = build({
  component: ContactInfo,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['tourbookFields', 'imageService'],
});
