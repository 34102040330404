const { locateService } = require('@services/service_locator');

const identityActionTypes = [
  'setDataValue',
];

const mappingBuilder = function ({ promiseInspection }) {
  let profilePageActions;
  const profileService = locateService('profileService');

  return profilePageActions = {

    updateUserProfile(key, value) {

      return promiseInspection(
        ['updateUserProfile'],
        async function (dispatch, getState) {
          const state = getState();
          const userSession = state.userSession;
          const id = userSession.id;
          const unsubscribe = await profileService.updateUserProfile(id, key, value);
          return unsubscribe;
        }.bind(this)
      );
    },

    subscribeToData() {
      return promiseInspection(
        ['subscribeToData'],
        async function (dispatch, getState) {
          const state = getState();
          const userSession = state.userSession;
          const id = userSession.id;
          const unsubscribe = await profileService.subscribeToUpdates(
            id,
            (value) => {
              if (!value) {
                throw new Error(`profile not found: ${id}`);
              }
              return dispatch(this.setDataValue({ path: ['userProfile'], value }));
            }
          );
          return unsubscribe;
        }.bind(this)
      );
    },
  };
};

module.exports =
{
  identityActionTypes,
  mappingBuilder,
  namespace: 'profilePage',
};