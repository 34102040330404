const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const GooglePlaces = require('./components/googleplaces');
const NearbyPlaces = require('./components/nearby_places');
const PdfEntryWrapperTemplateContainer = require('../pdf_entry_wrapper_template/container');
const PdfZeroStateTemplate = require('../pdf_zero_state_template');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const { locateService } = require('@services/service_locator');
const Walkscore = require('./components/walkscore');

class PdfEntryAmenitiesTemplate extends React.Component {
  static initClass() {
    this.propTypes = {
      amenities: PropTypes.object,
      amenitiesService: PropTypes.object,
      entryId: PropTypes.string.isRequired,
      isEditing: PropTypes.bool,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
    };
  }

  componentWillMount() {
    if (this.props.entryId) {
      this.setComponentReady = this.props.readyRegistry.register(`PdfEntryAmenitiesTemplate-${this.props.entryId}`);
    }
  }

  componentDidMount() {
    if (this.setComponentReady) {
      this.setComponentReady(true)
    }
  }

  componentWillUnmount() {
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  constructor(props) {
    super(props);
    this.amenitiesService = locateService('amenitiesService');
    this.pageNumberService = locateService('pageNumberService');
  }

  render() {
    const amenities = this._getAmenities();
    const size = amenities.length;
    if (size > 0) {
      return (
        <PdfEntryWrapperTemplateContainer {...this._getWrapperProps()}>
          <div className={cx(['root'])}>
            {amenities.map((amenity, index) => {
              const classObject = this._getAmenityContainerClasses({
                index,
                size,
              });
              return (
                <div
                  className={cx(['amenity-container', classObject])}
                  key={index}
                >
                  <div className={cx(['title'])}>
                    {this.amenitiesService.getTitle(amenity.type)}
                  </div>
                  <div className={cx(['amenity'])}>
                    {this._renderAmenity({ amenity, index, size })}
                  </div>
                </div>
              );
            })}
          </div>
        </PdfEntryWrapperTemplateContainer>
      );
    } else if (this.props.isEditing) {
      return (
        <PdfZeroStateTemplate
          messages={[
            'Amenities Turned Off',
            `\
  No amenities will be displayed in the tour book.
  To turn on amenities, toggle them in the editor to the left.\
  `,
          ]}
        />
      );
    } else {
      return null;
    }
  }

  _getAmenities() {
    const typeOrder = [
      'walkscore',
      'nearby_places',
      'googleplaces',
      'googlebiz',
    ];
    return _.chain(this.props.amenities)
      .values()
      .filter(
        ({ error, noData, type }) =>
          Array.from(typeOrder).includes(type) && !error && !noData
      )
      .sortBy(({ type }) => typeOrder.indexOf(type))
      .value();
  }

  _getAmenityContainerClasses({ index, size }) {
    return {
      bottom: !(index === 0 || (size === 4 && index === 1)),
      right:
        (size === 3 && index === 2) || (size === 4 && [1, 3].includes(index)),
      wide: this._isWide({ index, size }),
    };
  }

  _getComponent(type) {
    switch (type) {
      case 'nearby_places':
        return NearbyPlaces;
      case 'walkscore':
        return Walkscore;
      case 'googleplaces':
        return GooglePlaces;
      case 'googlebiz':
        return GooglePlaces;
    }
  }

  _getPageNumber() {
    return this.pageNumberService.getAmenitiesPageNumber(
      _.pick(this.props, ['entries', 'entryId', 'tourbook'])
    );
  }

  _getWrapperProps() {
    return {
      entryId: this.props.entryId,
      isEditing: this.props.isEditing,
      pageNumber: this._getPageNumber(),
      readyRegistry: this.props.readyRegistry,
    };
  }

  _isWide({ index, size }) {
    return size < 3 || (size === 3 && index === 0);
  }

  _renderAmenity({ amenity, index, size }) {
    const wide = this._isWide({ index, size });
    let componentProps = _.assign({ wide }, amenity);
    const Component = this._getComponent(amenity.type);
    if (amenity.type === 'nearby_places') {
      componentProps = {
        ...componentProps,
        entryId: this.props.entryId,
        readyRegistry: this.props.readyRegistry,
      };
    }
    return <Component {...componentProps} />;
  }
}
PdfEntryAmenitiesTemplate.initClass();

module.exports = PdfEntryAmenitiesTemplate;
