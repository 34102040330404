exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 26 : /opt/build/repo/react-app/editor_frontend/src/components/shared/popover/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 5 : /opt/build/repo/react-app/editor_frontend/src/components/shared/popover/index.styl */\n.popover---popover---2KyXXLx1pR6ZHUMBIKjg5H {\n  background: #2c2c2c;\n  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);\n  position: absolute;\n  transform: translateX(-50%);\n}\n\n/* line 11 : /opt/build/repo/react-app/editor_frontend/src/components/shared/popover/index.styl */\n.popover---popover---2KyXXLx1pR6ZHUMBIKjg5H .popover---popover-content---16QQmEqgBn3enUIYMfBQZl {\n  color: #999;\n  font-size: 12px;\n  font-weight: 500;\n  padding: 2px 12px;\n  position: relative;\n}\n\n/* line 18 : /opt/build/repo/react-app/editor_frontend/src/components/shared/popover/index.styl */\n.popover---popover---2KyXXLx1pR6ZHUMBIKjg5H .popover---popover-diamond---oFs9mCDSSq9AFaf4T-ZfZ {\n  background: #2c2c2c;\n  border-radius: 2px 0 0 0;\n  height: 9px;\n  left: calc(50% - (9px/2));\n  position: absolute;\n  top: -4.5px;\n  transform: rotate(45deg);\n  width: 9px;\n}\n", ""]);

// exports
exports.locals = {
	"popover": "popover---popover---2KyXXLx1pR6ZHUMBIKjg5H",
	"popover-content": "popover---popover-content---16QQmEqgBn3enUIYMfBQZl",
	"popover-diamond": "popover---popover-diamond---oFs9mCDSSq9AFaf4T-ZfZ"
};