const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ImageContainer = require('@components/shared/image/container');
const HeaderFieldsContainer = require('./components/header_fields/container');
const ContactInfoContainer = require('./components/contact_info/container');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const { findDOMNode } = require('react-dom');
const { useDrag, useDrop } = require('react-dnd');
const DRAG_TYPES = require('@enums/drag_types');
const { useEffect } = require('react');

const PdfCoverTemplate = (props) => {
  const setComponentReady = props.readyRegistry.register('PdfCoverTemplate');

  const _getContactInfoProps = ({ contactInfo, contactInfoId }) => {
    return {
      contactInfo,
      contactInfoId,
      isEditing: props.isEditing,
      readyRegistry: props.readyRegistry,
      shouldRenderLogo: _shouldRenderLogo(contactInfoId),
    };
  };

  const _getRootProps = () => {
    return {
      style: { color: props.coverFontColor },
      className: cx([
        'root',
        'pdf-template',
        props.isEditing ? 'is-editing' : '',
      ]),
    };
  };

  const _shouldRenderLogo = (contactInfoId) => {
    return (
      props.contactInfos[contactInfoId].isLogoDisplayed &&
      props.contactInfos[contactInfoId].logo != null
    );
  };

  // stores ids and paths to Firebase db
  const dragMetadata = {
    coverHeader: {
      id: 'coverHeader',
      paths: {
        top: ['content', 'cover', 'options', 'title', 'top'],
        left: ['content', 'cover', 'options', 'title', 'left'],
      },
    },
  };
  _.map(props.contactInfos, (contactInfo, contactInfoId) => {
    // if contact exists, then add the contact id and paths
    const contactId = `coverContactInfo${contactInfoId}`;
    dragMetadata[contactId] = {
      id: contactId,
      paths: {
        top: [
          'content',
          'cover',
          'options',
          'contactInfo',
          contactInfoId,
          'top',
        ],
        left: [
          'content',
          'cover',
          'options',
          'contactInfo',
          contactInfoId,
          'left',
        ],
      },
    };
  });

  // update 'top' and 'left' of an element to move/drag-and-drop
  const updateElemPosition = (
    elementId,
    { leftDiff, topDiff },
    updatePaths
  ) => {
    const elem = findDOMNode(document.getElementById(elementId));

    // Get x, y offsets from the drag source element to the drop target element
    if (!dragMetadata[elementId].offsets) {
      let tempElem = elem.parentNode;
      dragMetadata[elementId].offsets = {
        top: 0,
        left: 0,
      };
      // at the root level, the parentNode is null
      while (tempElem !== null && tempElem.id !== 'cover') {
        const tempElemCss = getComputedStyle(tempElem);
        dragMetadata[elementId].offsets.top +=
          tempElemCss.top === 'auto' ? 0 : parseInt(tempElemCss.top);
        dragMetadata[elementId].offsets.left +=
          tempElemCss.left === 'auto' ? 0 : parseInt(tempElemCss.left);
        tempElem = tempElem.parentNode;
      }
    }

    const dragSourceCss = getComputedStyle(elem);

    const newTop = parseInt(dragSourceCss.top) + topDiff;
    const newLeft = parseInt(dragSourceCss.left) + leftDiff;

    const offsets = dragMetadata[elementId].offsets; // just for convenience

    const coverDropCss = getComputedStyle(
      findDOMNode(document.getElementById('cover'))
    );
    const coverWidth = parseInt(coverDropCss.width);
    const coverHeight = parseInt(coverDropCss.height);

    // Reject any changes that would result any part of the element body to be outside of the cover
    if (
      newTop + offsets.top < 0 ||
      newLeft + offsets.left < 0 ||
      newLeft + offsets.left + parseInt(dragSourceCss.width) > coverWidth ||
      newTop + offsets.top + parseInt(dragSourceCss.height) > coverHeight
    ) {
      return;
    }

    if (elem.style) {
      elem.style.top = newTop + 'px';
      elem.style.left = newLeft + 'px';
    } else {
      elem.style = {
        top: newTop + 'px',
        left: newLeft + 'px',
      };
    }

    // update Firebase db if paths are given (it's given after drag-n-drop, but not after initial rendering)
    const { top, left } = updatePaths;
    props.dispatch(
      props.update({
        path: left,
        value: newLeft,
      })
    );
    props.dispatch(
      props.update({
        path: top,
        value: newTop,
      })
    );
  };

  // drag component of the cover header
  const [, headerDrag] = useDrag(
    () => ({
      type: DRAG_TYPES.COVER_HEADER,
      item: (monitor) => {
        return {
          id: dragMetadata.coverHeader.id,
          paths: dragMetadata.coverHeader.paths,
        };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [dragMetadata]
  );

  // drop component for cover header and contact infos
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DRAG_TYPES.COVER_HEADER, DRAG_TYPES.COVER_CONTACT_INFO],
      drop: (item, monitor) => {
        const { x: leftDiff, y: topDiff } =
          monitor.getDifferenceFromInitialOffset();
        updateElemPosition(
          item.id,
          {
            leftDiff,
            topDiff,
          },
          item.paths
        );
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props]
  );

  const coverContactInfoCss = (contactInfoOptions) => {
    const { top, left } = contactInfoOptions;

    const additionalStyles = {};
    if (top) {
      additionalStyles['top'] = top;
    }
    if (left) {
      additionalStyles['left'] = left;
    }
    return additionalStyles;
  };
  // NB: these may not exist, hence the null-checking below
  const firstContactInfoStorageId = _.keys(props.contactInfos)[0];
  const secondContactInfoStorageId = _.keys(props.contactInfos)[1];

  const firstContactInfoId = `coverContactInfo${firstContactInfoStorageId}`;
  const secondContactInfoId = `coverContactInfo${secondContactInfoStorageId}`;

  const [, firstContactInfoDrag] = useDrag(
    () => ({
      type: DRAG_TYPES.COVER_CONTACT_INFO,
      item: (monitor) => {
        return {
          id: dragMetadata[firstContactInfoId].id,
          paths: dragMetadata[firstContactInfoId].paths,
        };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [firstContactInfoId, dragMetadata]
  );
  const [, secondContactInfoDrag] = useDrag(
    () => ({
      type: DRAG_TYPES.COVER_CONTACT_INFO,
      item: (monitor) => {
        return {
          id: dragMetadata[secondContactInfoId].id,
          paths: dragMetadata[secondContactInfoId].paths,
        };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [secondContactInfoId, dragMetadata]
  );

  // we can only have a max of two contact infos, so grab them if we have 'em
  const firstContactInfo = props.contactInfos
    ? props.contactInfos[firstContactInfoStorageId] || {}
    : undefined;
  const secondContactInfo = props.contactInfos
    ? props.contactInfos[secondContactInfoStorageId] || {}
    : undefined;

  useEffect(() => {
    setComponentReady(true);

    return () => setComponentReady(false);
  }, [props]);
  
  return (
    <div {..._getRootProps()} ref={drop} id="cover">
      <ImageContainer
        backgroundSize="cover"
        image={props.coverPhoto}
        readyRegistry={props.readyRegistry}
      />
      <div className={cx(['headers'])} ref={headerDrag}>
        <HeaderFieldsContainer isEditing={props.isEditing} />
      </div>
      <div className={cx(['contact-infos'])}>
        {firstContactInfoStorageId && (
          <div
            key={firstContactInfoStorageId}
            className={cx(['contact-info'])}
            ref={firstContactInfoDrag}
            id={firstContactInfoId}
            style={coverContactInfoCss(
              props.options.contactInfo?.[firstContactInfoStorageId] || {}
            )}
          >
            {firstContactInfo.type !== 'skipped' ? (
              <ContactInfoContainer
                {..._getContactInfoProps({
                  contactInfo: firstContactInfo,
                  contactInfoId: firstContactInfoStorageId,
                })}
              />
            ) : undefined}
          </div>
        )}
        {secondContactInfoStorageId && (
          <div
            key={secondContactInfoStorageId}
            className={cx(['contact-info'])}
            ref={secondContactInfoDrag}
            id={secondContactInfoId}
            style={coverContactInfoCss(
              props.options.contactInfo?.[secondContactInfoStorageId] || {}
            )}
          >
            {secondContactInfo.type !== 'skipped' ? (
              <ContactInfoContainer
                {..._getContactInfoProps({
                  contactInfo: secondContactInfo,
                  contactInfoId: secondContactInfoStorageId,
                })}
              />
            ) : undefined}
          </div>
        )}
      </div>
    </div>
  );
};

PdfCoverTemplate.propTypes = {
  contactInfos: PropTypes.object,
  coverFontColor: PropTypes.string.isRequired,
  coverPhoto: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
  options: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

module.exports = PdfCoverTemplate;
