const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const EntryView = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentTab',
  'setCurrentView',
]);

const mapStateToProps = function (state, ownProps) {
  const { currentTab, data, fieldDefinitions } = state.tourbookEditorPage;
  const entry = data.content.entries[ownProps.entryId];
  return { currentTab, entry, fieldDefinitions };
};

const propTypes = { entryId: PropTypes.string.isRequired };

const serviceNames = ['tourbookEntryFieldsService'];

module.exports = build({
  component: EntryView,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames,
});
