const { build } = require('@components/container_helpers');
const PdfEntryTemplate = require('./');
const PropTypes = require('prop-types');

const mapStateToProps = function (state, ownProps) {
  const { currentView, data, fieldDefinitions, isDraggingImage } = state.tourbookEditorPage;
  return {
    currentView,
    entries: data.content.entries,
    entry: data.content.entries[ownProps.entryId],
    fieldDefinitions,
    isDraggingImage,
    tourbook: data,
  };
};

const propTypes = { entryId: PropTypes.string.isRequired };

module.exports = build({
  component: PdfEntryTemplate,
  mapStateToProps,
  propTypes,
  serviceNames: ['pdfService', 'summaryService'],
});
