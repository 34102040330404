const _ = require('lodash');
const { locateService, locateServices } = require('./service_locator');

class CloudinaryService {
  static initClass() {
    this.prototype.isImageValid = async function (image) {
      const url = this.getImageUrl(image);
      try {
        await this.axios(url);
        return true;
      } catch (error) {
        if (error.response?.status === 404) {
          return false;
        }
        throw error;
      }
    };

    // returns an array of arrays of images (with the same length as imagesInput)
    this.prototype.uploadImages = async function (imagesInput) {
      if (imagesInput.length === 0) {
        return [];
      }
      const auth0IdToken = this.auth0Service.localStorage.auth0IdToken;
      const responses = await Promise.allSettled(
        imagesInput.map(({ image: imageInput }) =>
          this.axios({
            data: { images: [imageInput] },
            headers: { Authorization: `Bearer ${auth0IdToken}` },
            withCredentials: false,
            method: 'post',
            url: `${this.firebaseFunctionsConfig.url}/apiCloudinary`,
          })
        )
      );
      const imageErrors = [];
      const results = [];
      for (let [input, response] of Array.from(_.zip(imagesInput, responses))) {
        if (response.status === 'rejected') {
          this.analyticsService.track('Image failed to upload', {
            image: _.assign({}, input, response.reason),
          });
          imageErrors.push(response.error);
          results.push([{ error: response.reason, filename: input.name }]);
        }

        const output = JSON.parse(response.value.data[0]);
        if (output.error) {
          this.analyticsService.track('Image failed to upload', {
            image: _.assign({}, input, output),
          });
          imageErrors.push(output.error);
          results.push([{ error: output.error, filename: input.name }]);
        } else {
          results.push(this._expandAndRenameFields(output));
        }
      }
      return results;
    };
  }

  constructor({ ENV: { cloudinaryConfig, firebaseFunctionsConfig }, axios, cloudinary }) {
    this.cloudinary = cloudinary;
    this.cloudinaryConfig = cloudinaryConfig;
    this.firebaseFunctionsConfig = firebaseFunctionsConfig;
    this.axios = axios;
    ({ analyticsService: this.analyticsService } = locateServices(['analyticsService']));

    this.auth0Service = locateService('auth0Service');
  }

  equals(imageA, imageB) {
    return (
      imageA.cloudinaryPublicId === imageB.cloudinaryPublicId &&
      imageA.cloudinaryPage === imageB.cloudinaryPage
    ); // for PDFs
  }

  getImageUrl(image, options) {
    if (options == null) {
      options = {};
    }
    if (options.thumbnail) {
      return this._getThumbnailUrl(image);
    }
    if (options.logo) {
      return this.getLogoUrl(image);
    }
    return this._getUrl(image, `t_ptplisting_${image?.rotation || 0}`);
  }

  getLogoUrl(image) {
    return this._getUrl(image, `t_ptplogo_${image?.rotation || 0}`);
  }

  _getThumbnailUrl(image) {
    return this._getUrl(image, 't_ptpthumbnail');
  }

  _getUrl(image, transform) {
    if (!image?.cloudinaryPublicId) {
      return '';
    }
    const cloud = this._getCloudName(image);
    let url = `https://res.cloudinary.com/${cloud}/image/upload/`;
    if (image.cloudinaryPage) {
      url += `pg_${image.cloudinaryPage}/`;
    }
    if (transform) {
      url += `${transform}/`;
    }
    url = url + image.cloudinaryPublicId;
    if (
      ['.jpg', '.png'].some((extension) => {
        return url.slice(-4) === extension;
      })
    ) {
      url = url;
    } else {
      url = url + '.png';
    }
    return url;
  }
  async downloadAndUploadFile(pdfURL, tourbookName) {
    try {
      // Download file from DocRaptor
      const response = await fetch(pdfURL);
      const blob = await response.blob();

      // Create FormData object and append the blob
      const formData = new FormData();
      const fileName = `${tourbookName}.pdf`;
      const renamedFile = new File([blob], fileName, { type: 'application/pdf' });

      formData.append('file', renamedFile);
      const cloudinaryUploadPreset = this.cloudinaryConfig.pdfUploadPreset;
      formData.append('upload_preset', cloudinaryUploadPreset);
      const cloudinaryEndpoint = this.cloudinaryConfig.uploadUrl;

      // Upload file to Cloudinary
      const cloudinaryResponse = await fetch(cloudinaryEndpoint, {
        method: 'POST',
        body: formData,
      });

      // Handle Cloudinary response
      const data = await cloudinaryResponse.text();
      const url = JSON.parse(data).url;
      return url;
    } catch (error) {
      console.error('Error:', error);
    }
  }
  _expandAndRenameFields(rawImage) {
    if (rawImage.pages != null) {
      return _.map(__range__(1, rawImage.pages, true), (i) => {
        return this._renameFields(_.assign({}, rawImage, { page: i }));
      });
    } else {
      return [this._renameFields(rawImage)];
    }
  }

  _getCloudName(image) {
    return this.cloudinaryConfig.defaultCloud;
  }

  _renameFields(image) {
    const res = {
      cloudinaryPublicId: image.public_id,
      type: 'image',
    };
    if (image.page != null) {
      res.cloudinaryPage = image.page;
    }
    return res;
  }
}
CloudinaryService.initClass();

module.exports = CloudinaryService;

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
