const _ = require('lodash');
const { locateServices } = require('./service_locator');

class UserTourbooksService {
  static initClass() {
    this.prototype.updateMeta = async function (tourbookId, tourbook) {
      return this._setMeta({
        path: [],
        tourbook,
        tourbookId,
        value: await this.tourbookMetaService.build({ tourbook, tourbookId }),
      });
    };

    this.prototype._setMeta = async function ({ path, tourbook, tourbookId, value }) {
      const updates = {
        [`userTourbooks/${tourbook.owner_id}/${tourbookId}/${path.join('/')}`]: value,
      };
      _.keys(tourbook.shared).forEach(
        (userId) =>
          (updates[`userSharedTourbooks/${userId}/${tourbookId}/${path.join('/')}`] = value)
      );
      this._setMetaAllTourbooks({ path, tourbook, tourbookId, value });
      return await this.firebaseService.updateValues('/', updates);
    };

    this.prototype._setMetaAllTourbooks = async function ({ path, tourbook, tourbookId, value }) {
      const updates = {};
      updates[`tourbooksMeta/${tourbookId}/${path.join('/')}`] = value;
      return await this.firebaseService.updateValues('/', updates);
    };
  }

  constructor() {
    ({ firebaseService: this.firebaseService, tourbookMetaService: this.tourbookMetaService } =
      locateServices(['firebaseService', 'tourbookMetaService']));
  }

  deleteEntryMeta({ entryId, tourbook, tourbookId }) {
    return this._setMeta({
      path: ['entries', entryId],
      tourbook,
      tourbookId,
      value: null,
    });
  }

  deleteMeta(tourbookId, tourbook) {
    return this._setMeta({
      path: [],
      tourbook,
      tourbookId,
      value: null,
    });
  }

  async getTourbook(tourbookId) {
    return await this.firebaseService.getValue(`tourbooks/${tourbookId}`);
  }

  getAllMeta(userId) {
    return this.firebaseService.getValue(`userTourbooks/${userId}`);
  }

  getAllSharedMeta(userId) {
    return this.firebaseService.getValue(`userSharedTourbooks/${userId}`);
  }

  async getOwnedCount(userId) {
    return await this.firebaseService.getChildCount(`/userTourbooks/${userId}`);
  }

  async getPublishedCount(userId) {
    return await this.firebaseService.getChildCount(`userTourbooksPublished/${userId}`);
  }

  recordTourbookPublish(userId, tourbookId, publishMethod) {
    return this.firebaseService.pushValue(`userTourbooksPublished/${userId}/${tourbookId}`, {
      publishMethod,
      timestamp: this.firebaseService.now(),
    });
  }

  subscribeToAllSharedMeta(userId, fn) {
    return this.firebaseService.subscribeToUpdates(`userSharedTourbooks/${userId}`, fn);
  }

  updateEntryMeta({ entry, entryId, tourbook, tourbookId }) {
    return this._setMeta({
      path: ['entries', entryId],
      tourbook,
      tourbookId,
      value: this.tourbookMetaService.buildEntry(entry),
    });
  }

  updateMetaField(options) {
    return this._setMeta(options);
  }
}
UserTourbooksService.initClass();

module.exports = UserTourbooksService;
