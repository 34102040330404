/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const TourbookEntryFieldsService = require('./tourbook_entry_fields_service');

describe('TourbookEntryFieldsService', function () {
  beforeEach(function () {
    return (this.tourbookEntryFieldsService = new TourbookEntryFieldsService());
  });

  describe('getData', function () {
    beforeEach(function () {
      return (this.data = { property: {}, space: {} });
    });

    it('has the proper structure', function () {
      const result = this.tourbookEntryFieldsService.getData(this.data);
      expect(result.fields.header).to.eql({
        CorrectedAddress1: null,
        city: null,
        crossStreets: null,
        floor: null,
        notesHeader: 'Notes',
        propertyHeader: 'Property',
        propertyName: null,
        spaceHeader: 'Space',
        state: null,
        suite: null,
        termsHeader: 'Terms',
        zip: null,
      });
      expect(result.fields.property).to.eql({
        buildingArea: { order: 0, value: null },
        floors: { order: 1, value: null },
        officeClass: { order: 3, value: null },
        yearBuilt: { order: 2, value: null },
      });
      expect(result.fields.notes).to.eql({});
      expect(result.fields.space).to.eql({
        availableDate: { order: 4, value: null },
        leaseHighPrice: { order: 1, value: { max: null, min: null } },
        leaseRateType: { order: 2, value: null },
        smallestAvailableSpace: { order: 0, value: { max: null, min: null } },
        term: { order: 3, value: null },
      });
      expect(result.fields.terms).to.eql({
        availableSize: { order: 0, value: { max: null, min: null } },
        capRate: { order: 3, value: null },
        salePrice: { order: 1, value: { max: null, min: null } },
        salePricePerSquareFoot: { order: 2, value: { max: null, min: null } },
      });
      return expect(result.xceligentData).to.eql({
        blockLot: null,
        clearHeight: {
          max: null,
          min: null,
        },
        divisiblity: null,
        dockDoors: null,
        driveIns: null,
        fencedYard: null,
        generalUse: null,
        isLease: null,
        isSale: null,
        leaseOrSale: null,
        leaseRatePerType: null,
        listingType: null,
        lossFactor: null,
        lotArea: null,
        maxContiguous: null,
        minDivisible: null,
        nnnExpenses: null,
        officeAir: null,
        officeHeat: null,
        offices: null,
        operatingExpensesPerArea: null,
        owner: null,
        parkingRatio: null,
        parkingSpaces: null,
        pavedYard: null,
        restrooms: null,
        saleType: null,
        sprinkler: null,
        sublease: null,
        tiAllowance: null,
        vacant: null,
        warehouseHVAC: null,
        zoning: null,
      });
    });

    it('title cases the address', function () {
      this.data.property.CorrectedAddress1 = 'some address';
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.fields.header.CorrectedAddress1).to.eql(
        'Some Address'
      );
    });

    it('title cases and converts roman numerals', function () {
      this.data.property.CorrectedAddress1 = 'some address iv';
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.fields.header.CorrectedAddress1).to.eql(
        'Some Address IV'
      );
    });

    it('title cases and converts cardinal direcitons', function () {
      this.data.property.CorrectedAddress1 = '211 sE walnut st';
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.fields.header.CorrectedAddress1).to.eql(
        '211 SE Walnut St'
      );
    });

    it('title cases and converts cardinal direcitons', function () {
      this.data.property.CorrectedAddress1 = '43 s chestnut st';
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.fields.header.CorrectedAddress1).to.eql(
        '43 S Chestnut St'
      );
    });

    it('title cases and converts cardinal direcitons', function () {
      this.data.property.CorrectedAddress1 = 'sw maple ave';
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.fields.header.CorrectedAddress1).to.eql(
        'SW Maple Ave'
      );
    });

    it('title cases and converts cardinal direcitons', function () {
      this.data.property.CorrectedAddress1 = '734 Oak nw';
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.fields.header.CorrectedAddress1).to.eql(
        '734 Oak NW'
      );
    });

    it('changes listingType to Yes if Sublease and changes the key to sublease', function () {
      this.data.space.listingType = 'Sublease';
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.xceligentData.sublease).to.eql('Yes');
    });

    it('changes the key for saleHighPrice, transforms to a range', function () {
      this.data.space.salePriceHigh = 1000000;
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.fields.terms.salePrice.value).to.eql({
        max: 1000000,
        min: null,
      });
    });

    it('grabs both min and max for clearHeight', function () {
      this.data.space = { clearHeightMax: 2, clearHeightMin: 1 };
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.xceligentData.clearHeight).to.eql({
        max: 2,
        min: 1,
      });
    });

    it('only grabs the max if min and max are equal', function () {
      this.data.space = { leaseAreaHighPrice: 1, leaseAreaLowPrice: 1 };
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.fields.space.leaseHighPrice.value).to.eql({
        max: 1,
        min: null,
      });
    });

    it('transforms lotArea from sqft to ac if fromETL', function () {
      this.data.property = { fromETL: true, lotArea: '43560' };
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.xceligentData.lotArea).to.eql('1.000');
    });

    it('transforms vacant from bool to string', function () {
      this.data.space = { vacant: true };
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.xceligentData.vacant).to.eql('Yes');
    });

    it('does not transform bool to string if field is empty', function () {
      this.data.space = { vacant: undefined };
      const result = this.tourbookEntryFieldsService.getData(this.data);
      return expect(result.xceligentData.vacant).to.eql(null);
    });

    it('adds header fields', function () {
      const result = this.tourbookEntryFieldsService.getData(this.data);
      expect(result.fields.header.notesHeader).to.eql('Notes');
      expect(result.fields.header.propertyHeader).to.eql('Property');
      expect(result.fields.header.spaceHeader).to.eql('Space');
      return expect(result.fields.header.termsHeader).to.eql('Terms');
    });

    return describe('notes', function () {
      describe('with null space', () =>
        it('does not error', function () {
          delete this.data.space;
          const result = this.tourbookEntryFieldsService.getData(this.data);
          return expect(result.fields.notes).to.eql({});
        }));

      describe('no notes', () =>
        it('doesnt set notesToggle', function () {
          const result = this.tourbookEntryFieldsService.getData(this.data);
          return expect(result.fields.notes).to.eql({});
        }));

      describe('with space notes', () =>
        it('sets notes and notesToggle', function () {
          this.data.space.spaceNotes = 'space notes';
          const result = this.tourbookEntryFieldsService.getData(this.data);
          return expect(result.fields.notes).to.eql({
            notes: { content: 'space notes' },
            notesToggle: true,
          });
        }));

      describe('with property notes', () =>
        it('sets notes and notesToggle', function () {
          this.data.property.propertyNotes = 'property notes';
          const result = this.tourbookEntryFieldsService.getData(this.data);
          return expect(result.fields.notes).to.eql({
            notes: { content: 'property notes' },
            notesToggle: true,
          });
        }));

      return describe('with property and space notes', () =>
        it('sets notes and notesToggle', function () {
          this.data.property.propertyNotes = 'property notes';
          this.data.space.spaceNotes = 'space notes';
          const result = this.tourbookEntryFieldsService.getData(this.data);
          return expect(result.fields.notes).to.eql({
            notes: { content: 'property notes\nspace notes' },
            notesToggle: true,
          });
        }));
    });
  });

  return describe('getAdditionalFieldsUpdates', function () {
    beforeEach(function () {
      const entry = {
        fields: {
          section1: {
            field3: { order: 0 },
          },
        },
      };
      const fields = [
        { key: 'field1' },
        { key: 'field2', label: 'label2' },
        { key: 'field4', value: 'value4' },
      ];
      return (this.updates =
        this.tourbookEntryFieldsService.getAdditionalFieldUpdates({
          entry,
          fields,
          section: 'section1',
        }));
    });

    return it('returns the updates to the entry for adding the fields', function () {
      return expect(this.updates).to.eql([
        {
          path: ['fields', 'section1', 'field1'],
          value: {
            isAdditionalField: true,
            label: null,
            order: 1,
            value: null,
          },
        },
        {
          path: ['fields', 'section1', 'field2'],
          value: {
            isAdditionalField: true,
            label: 'label2',
            order: 2,
            value: null,
          },
        },
        {
          path: ['fields', 'section1', 'field4'],
          value: {
            isAdditionalField: true,
            label: null,
            order: 3,
            value: 'value4',
          },
        },
      ]);
    });
  });
});
