const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const EntryImagesView = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'getEntryDefaultStreetviewSettings',
  'getDigitalTourbookEntryImages',
  'setCurrentTab',
  'setCurrentView',
  'track',
]);

const mapStateToProps = (state, ownProps) => ({
  entryHideImagesZeroState: _getEntryData(state, ownProps, [
    'hideImagesZeroState',
  ]),
  entryImagePages: _getEntryData(state, ownProps, ['pages', 'images']),
});

var _getEntryData = function (state, ownProps, path) {
  const fullPath = ['content', 'entries', ownProps.entryId].concat(path);
  return _.get(state.tourbookEditorPage.data, fullPath);
};

module.exports = build({
  component: EntryImagesView,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['firebaseService'],
});
