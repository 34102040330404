const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class Switch extends React.Component {
  static initClass() {
    this.propTypes = {
      onChange: PropTypes.func.isRequired,
      value: PropTypes.bool,
    };
  }

  render() {
    const rootProps = {
      className: cx(['root', `${this.props.value ? 'on' : ''}`]),
      onClick: () => this.props.onChange(!this.props.value),
    };
    return (
      <div {...rootProps}>
        <div className={cx(['switch-container'])}>
          <div className={cx(['switch'])} />
        </div>
      </div>
    );
  }
}
Switch.initClass();

module.exports = Switch;
