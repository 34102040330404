const CollapsibleQuestion = require('@components/shared/collapsible_question');
const PropTypes = require('prop-types');
const React = require('react');

class EntryTypeInput extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      navigateForward: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
      value: PropTypes.string,
    };
  }

  render() {
    return (
      <CollapsibleQuestion
        active={this.props.active}
        answers={[
          { label: 'Sub Lease', value: 'subLease' },
          { label: 'For Lease', value: 'Lease' },
          { label: 'For Sale', value: 'Sale' },
        ]}
        onChange={(value) => {
          this.props.update(value);
          return this.props.navigateForward(value);
        }}
        question="What kind of space is this?"
        value={this.props.value} />
    );
  }
}
EntryTypeInput.initClass();

module.exports = EntryTypeInput;
