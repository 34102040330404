exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 7 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/slider/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/slider/index.styl */\n.slider---value---DH_EV_gMtLn9R0bruu490 {\n  padding-top: 1px;\n  padding-right: 15px;\n}\n\n/* line 5 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/slider/index.styl */\n.slider---slider---2uKF-Ev0j6v2HwwH2jf48p {\n  margin-top: 7px;\n  width: 150px;\n}\n", ""]);

// exports
exports.locals = {
	"value": "slider---value---DH_EV_gMtLn9R0bruu490",
	"slider": "slider---slider---2uKF-Ev0j6v2HwwH2jf48p"
};