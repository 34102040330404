const _ = require('lodash');

const initialState = {
  currentModal: {},
  currentView: {},
  data: {},
};

const mapping = {
  setCreatingEntryData(state, { payload: creatingEntryData }) {
    return _.assign({}, state, { creatingEntryData });
  },

  setCurrentField(state, { payload: currentField }) {
    currentField = _.assign({ internalIndex: 0 }, currentField);
    return _.assign({}, state, { currentField });
  },

  setCurrentModal(state, { payload: currentModal }) {
    return _.assign({}, state, { currentModal });
  },

  setCurrentTab(state, { payload: currentTab }) {
    return _.assign({}, state, {
      currentField: {},
      currentModal: {},
      currentTab,
    });
  },

  setCurrentView(state, { payload: currentView }) {
    if (_.isEqual(state.currentView, currentView)) {
      return state;
    } else {
      return _.assign({}, state, {
        currentField: {},
        currentModal: {},
        currentTab: {},
        currentView,
      });
    }
  },

  setData(state, { payload: data }) {
    return _.assign({}, state, { data });
  },

  setDataValue(state, { payload: { path, value } }) {
    let index = path.length - 1;
    let newObject = value;
    while (index >= 0) {
      const updatedPath = path.slice(0, index);
      const oldObject = _.get(state.data, updatedPath);
      if (newObject === null) {
        newObject = _.assign({}, oldObject);
        delete newObject[path[index]];
      } else {
        newObject = _.assign({}, oldObject, { [path[index]]: newObject });
      }
      index -= 1;
    }
    const newData = _.assign({}, state.data, newObject);
    return _.assign({}, state, { data: newData });
  },

  setEntryDefaultStreetviewSettings(state, { payload: { entryId, settings } }) {
    const entryDefaultStreetviewSettings = _.clone(
      state.entryDefaultStreetviewSettings || {}
    );
    entryDefaultStreetviewSettings[entryId] = settings;
    return _.assign({}, state, { entryDefaultStreetviewSettings });
  },

  setDigitalTourbookEntryImages(state, { payload: { entryId, images } }) {
    const digitalTourbookEntryImages = {};
    digitalTourbookEntryImages[entryId] = images;
    return _.assign({}, state, { digitalTourbookEntryImages });
  },

  setFieldDefinitions(state, { payload: fieldDefinitions }) {
    return _.assign({}, state, { fieldDefinitions });
  },

  setIsDraggingImage(state, { payload: isDraggingImage }) {
    return _.assign({}, state, { isDraggingImage });
  },

  setNewEntryData(state, { payload: newEntryData }) {
    return _.assign({}, state, { newEntryData });
  },

  //#######################################################################################

  setSuggestions(state, { payload: suggestions }) {
    return _.assign({}, state, { suggestions });
  },

  // updateInputValue: (state, {payload: suggestions}) ->
  //   _.assign {}, state, {suggestions}

  // clearSuggestions: (state, {payload: suggestions}) ->
  //   _.assign {}, state, {suggestions}

  // loadSuggestionsBegin: (state, {payload: suggestions}) ->
  //   _.assign {}, state, {suggestions}

  // maybeUpdateSuggestions: (state, {payload: suggestions}) ->
  //   _.assign {}, state, {suggestions}

  //#######################################################################################

  setPreviousFields(state, { payload: previousFields }) {
    return _.assign({}, state, { previousFields });
  },

  setTourbookId(state, { payload: tourbookId }) {
    return _.assign({}, state, { tourbookId });
  },

  setUserImages(state, { payload: images }) {
    return _.assign({}, state, { images });
  },

  updateCreatingEntryData(state, { payload: updates }) {
    const creatingEntryData = _.assign({}, state.creatingEntryData, updates);
    return _.assign({}, state, { creatingEntryData });
  },

  resetNavigation(state, ...rest) {
    const obj = rest[0];
    return _.assign({}, state, {
      currentModal: {},
      currentView: {},
      currentTab: {},
      currentField: {},
    });
  },
};

module.exports = { initialState, mapping, namespace: 'tourbookEditorPage' };
