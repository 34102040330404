const _ = require('lodash');
const { locateServices } = require('./service_locator');

class TourbookEditorPageAnalyticsService {
  static initClass() {
    this.prototype.dataChangeEventBuilders = [
      {
        additionalPropertiesBuilder({ options, value }) {
          return {
            background_type: this._getBackgroundType(options.uiType),
            cloudinary_url: this.cloudinaryService.getImageUrl(value),
          };
        },
        name: 'Changes Background Image',
        path: ['content', 'cover', 'imagesShown', 'coverPhoto'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { color: value };
        },
        name: 'Changes Accent Color',
        path: ['content', 'settings', 'accentColor'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { color: value };
        },
        name: 'Changes Font Color',
        path: ['content', 'settings', 'coverFontColor'],
      },
      {
        name: 'Edits Tour Date',
        path: ['content', 'cover', 'fields', 'date'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { choice: value.type };
        },
        name: 'Selects Broker or Client Contact',
        pathBuilder(path) {
          return ['content', 'cover', 'contactInfos', path[3]];
        },
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { choice: value };
        },
        name: 'Selects Broker or Client Contact',
        pathBuilder(path) {
          return ['content', 'cover', 'contactInfos', path[3], 'type'];
        },
      },
      {
        additionalPropertiesBuilder({ options, path, value }) {
          return {
            contact_type: _.get(
              options.tourbook,
              _.initial(path).concat('type')
            ),
            logo_type: this._getLogoType(options.uiType),
          };
        },
        name: 'Adds a Logo',
        pathBuilder(path) {
          return ['content', 'cover', 'contactInfos', path[3], 'logo'];
        },
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { choice: value };
        },
        hasEntryId: true,
        name: 'Selects Lease or Sale',
        pathBuilder(path) {
          return ['content', 'entries', path[2], 'type'];
        },
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { choice: value ? 'yes' : 'no' };
        },
        hasEntryId: true,
        name: 'Selects To Add Listing Notes',
        pathBuilder(path) {
          return [
            'content',
            'entries',
            path[2],
            'fields',
            'notes',
            'notesToggle',
          ];
        },
      },
      {
        additionalPropertiesBuilder({ value }) {
          return this._formatNewPosition(value);
        },
        hasEntryId: true,
        name: 'Toggles Bullet Points in Notes',
        pathBuilder(path) {
          return [
            'content',
            'entries',
            path[2],
            'fields',
            'notes',
            'notes',
            'bullets',
          ];
        },
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { choice: this._formatEntryMainPageImageLayout(value) };
        },
        hasEntryId: true,
        name: 'Changes the Main Page Image Layout',
        pathBuilder(path) {
          return ['content', 'entries', path[2], 'layout'];
        },
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { choice: value };
        },
        hasEntryId: true,
        name: 'Selects Extra Page Type',
        pathBuilder(path) {
          return [
            'content',
            'entries',
            path[2],
            'pages',
            'images',
            path[5],
            'type',
          ];
        },
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { choice: this._formatEntryExtraPdfPageLayout(value) };
        },
        hasEntryId: true,
        name: 'Changes the Extra Page Image Layout',
        pathBuilder(path) {
          return [
            'content',
            'entries',
            path[2],
            'pages',
            'images',
            path[5],
            'layout',
          ];
        },
      },
      {
        additionalPropertiesBuilder({ value }) {
          return {
            cloudinary_url: this.cloudinaryService.getImageUrl(value),
            pdf_display: false,
          };
        }, // newly added image can't be in the pdf
        hasEntryId: true,
        isMatch({ options, path }) {
          const pathToMatch = [
            'content',
            'entries',
            path[2],
            'images',
            path[4],
          ];
          return _.isEqual(path, pathToMatch) && options.trigger === 'add';
        },
        name: 'Adds an Image',
      },
      {
        additionalPropertiesBuilder({ value }) {
          return { choice: value.replace(/^googlemaps\./, '') };
        },
        name: 'Changes Map Style',
        path: ['content', 'settings', 'mapOptions', 'style'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return this._formatNewPosition(value);
        },
        name: 'Toggles Map Itineray',
        path: ['content', 'settings', 'mapOptions', 'itinerary'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return this._formatNewPosition(value);
        },
        name: 'Toggles Map Display',
        path: ['content', 'settings', 'display', 'tourMap'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return this._formatNewPosition(value);
        },
        name: 'Toggles Summary Display',
        path: ['content', 'settings', 'display', 'summary'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return this._formatNewPosition(value);
        },
        name: 'Toggles Logo in Footer',
        path: ['content', 'settings', 'display', 'footerLogo'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return this._formatNewPosition(value);
        },
        name: 'Toggles Contact Info in Footer',
        path: ['content', 'settings', 'display', 'footerText'],
      },
      {
        additionalPropertiesBuilder({ value }) {
          return this._formatNewPosition(value);
        },
        name: 'Toggles Page Numbers in Footer',
        path: ['content', 'settings', 'display', 'pageNumbers'],
      },
    ];
  }

  constructor() {
    ({
      cloudinaryService: this.cloudinaryService,
      tourbookFieldsService: this.tourbookFieldsService,
    } = locateServices(['cloudinaryService', 'tourbookFieldsService']));
  }

  getDataChangeEvent(input) {
    const builder = _.find(
      this.dataChangeEventBuilders,
      function (changeEvent) {
        if (changeEvent.path) {
          return _.isEqual(input.path, changeEvent.path);
        } else if (changeEvent.pathBuilder) {
          return _.isEqual(input.path, changeEvent.pathBuilder(input.path));
        } else if (changeEvent.isMatch) {
          return changeEvent.isMatch(input);
        }
      }
    );
    if (!builder) {
      return;
    }
    const result = { name: builder.name };
    if (builder.additionalPropertiesBuilder) {
      result.additionalProperties = builder.additionalPropertiesBuilder.call(
        this,
        input
      );
    }
    if (builder.hasEntryId) {
      result.entryId = input.path[2];
    }
    return result;
  }

  getFieldAdditionalProperties(field, fieldDefinitions) {
    const properties = {
      field_name: this.tourbookFieldsService.getFieldLabel(
        field,
        fieldDefinitions
      ),
    };
    if (field.isAtlas) {
      properties.field_type = field.hasValue ? 'n360 data' : 'suggested';
    }
    return properties;
  }

  getViewEventName({ currentTab, currentView, tourbook }) {
    if (currentView.key === 'cover') {
      if (currentTab.key === 'appearance') {
        return 'Views Cover:Appearance';
      } else if (currentTab.key === 'tour_info') {
        return 'Views Cover:Tour-Info';
      } else {
        const contactInfoIds = _.keys(tourbook.content.cover.contactInfos);
        for (let index = 0; index < contactInfoIds.length; index++) {
          const contactInfoId = contactInfoIds[index];
          if (currentTab.key === `contact_info_${contactInfoId}`) {
            return `Views Cover:Contact${index + 1}`;
          }
        }
        return `Views Cover:Contact${_.size(contactInfoIds) + 1}`;
      }
    } else if (currentView.key === 'entry') {
      switch (currentTab.key) {
        case 'address':
          return 'Views Listing:Address';
        case 'space_or_terms':
          return 'Views Listing:Space/Terms';
        case 'property':
          return 'Views Listing:Property';
        case 'notes':
          return 'Views Listing:Notes';
      }
    } else if (currentView.key === 'entry-images') {
      if (currentTab.key === 'main_images') {
        return 'Views Images:Listing-Images';
      } else if (
        currentTab.key === 'add_pdf_page' ||
        /^pdf_page_/.test(currentTab.key)
      ) {
        return 'Views Images:Extra-PDF-Page';
      }
    }
  }

  _formatEntryExtraPdfPageLayout(layout) {
    return layout
      .replace(/1/g, 'small')
      .replace(/2/g, 'large')
      .replace(/4/g, 'extra-large')
      .replace(/_/g, '-');
  }

  _formatEntryMainPageImageLayout(layout) {
    return layout
      .replace(/^1$/g, 'extra-large')
      .replace(/1/g, 'small')
      .replace(/2/g, 'large')
      .replace(/_/g, '-');
  }

  _formatNewPosition(value) {
    return { new_position: value ? 'on' : 'off' };
  }

  _getBackgroundType(uiType) {
    switch (uiType) {
      case 'suggestions':
        return 'suggested background';
      case 'user':
        return 'previous background';
      case 'upload':
        return 'uploaded';
    }
  }

  _getLogoType(uiType) {
    switch (uiType) {
      case 'logo_search':
        return 'logo finder';
      case 'upload':
        return 'uploaded';
    }
  }
}
TourbookEditorPageAnalyticsService.initClass();

module.exports = TourbookEditorPageAnalyticsService;
