const _ = require('lodash');
const Autosuggest = require('react-autosuggest');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class N360PropertySearch extends React.Component {
  static initClass() {
    this.propTypes = {
      onFocus: PropTypes.func,
      searchFn: PropTypes.func,
      googlePropertySearch: PropTypes.object,
      onSuggestionHighlighted: PropTypes.func,
      propertySearchFocused: PropTypes.bool,
      setSuggestionHighlighted: PropTypes.func,
      setSuggestions: PropTypes.func,
      setSuggestionMedia: PropTypes.func,
      suggestions: PropTypes.array,
      modalInputValueFn: PropTypes.func.isRequired,
      updateN360PropertySearchHeightFn: PropTypes.func.isRequired,
      updateRenderResultsFn: PropTypes.func,
      updateRenderGoogleFn: PropTypes.func,
      modal: PropTypes.object,
    };

    this.prototype._updateSuggestions = _.debounce(async function (value) {
      // if @props.googlePropertySearch? then @props.googlePropertySearch._hideSubTitle
      const updatedTime = new Date().getTime();
      let suggestions = await this.props.n360Service.propertySearch(value);
      suggestions = suggestions
        .filter((s) => {
          // Throw out "invalid" suggestions
          // s._source.streetAddress may have dummy-like data, so it is ignored
          const { city, state, zip } = s._source;
          return city.trim().length > 0 && state.trim().length > 0 && zip?.trim().length > 0;
        })
        .slice(0, 6);
      if (this.state.updatedTime > updatedTime) return; // suggestions for later-requested `value` are already in!
      this.props.updateN360PropertySearchHeightFn(this._getSuggestionsHeight());
      this.props.googlePropertySearch.setState.message = 'or select an address from Google Maps:';

      if (!this._isMounted) {
        return;
      }

      this.setState({
        isLoading: false,
        suggestions,
        updatedTime,
        suggestionsHeight: this._getSuggestionsHeight(),
      });
      if (this.props.setSuggestions) {
        this.props.setSuggestions(suggestions);
      }
      this._getMedia();
    }, 100);
  }

  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
    this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
    this.state = {
      value: '',
      updatedTime: new Date().getTime(),
      suggestions: [],
      isLoading: false,
      suggestionsHeight: this._getSuggestionsHeight(),
      suggestionImages: {},
      suggestionHighlighted: undefined, // a suggestion that the user has their mouse hover over
    };
    this.lastRequestId = null;
  }

  async _getMedia() {
    const suggestionImages = {};
    for (const suggestion of Object.values(this.state.suggestions || {})) {
      const propertyId = suggestion._id;
      if (this.state.suggestionImages[propertyId]) {
        suggestionImages[propertyId] = this.state.suggestionImages[propertyId];
      } else {
        const media = await this.props.n360Service.getPropertyMedia({ id: propertyId });
        suggestionImages[propertyId] = media;
      }
    }
    if (this._isMounted) {
      this.setState({ suggestionImages });
      if (this.props.setSuggestionMedia) {
        this.props.setSuggestionMedia(suggestionImages);
      }
    }
  }

  render() {
    let preRenderedSuggestions = [];
    if (this.props.renderN360Suggests) {
      if (this.state.suggestions?.length > 0) {
        preRenderedSuggestions = this.state.suggestions;
      }
      return (
        <div className={cx(['root'])}>
          <div className={cx(['input-container'])}>
            <Autosuggest
              alwaysRenderSuggestions={true}
              focusFirstSuggestion={true}
              getSuggestionValue={function ({ name }) {
                return name;
              }}
              inputProps={{
                onChange: this._onChange,
                onFocus: this.props.onFocus,
                placeholder: 'Enter the address for a property to include',
                value: this.state.value,
              }}
              onSuggestionHighlighted={({ suggestion }) => {
                this.props.onSuggestionHighlighted();
                if (!suggestion) {
                  // Don't clear out when no suggestion is highlighted, so the suggestion images stay visible and users can interact with them
                  return;
                } else if (this.state.suggestionHighlighted?._id !== suggestion?._id) {
                  this.setState({ suggestionHighlighted: suggestion });
                  if (this.props.setSuggestionHighlighted) {
                    this.props.setSuggestionHighlighted(suggestion);
                  }
                }
              }}
              onSuggestionSelected={this._onSuggestionSelected}
              onSuggestionsFetchRequested={({ value }) => this._updateSuggestions(value)}
              ref={(node) => {
                return (this.autosuggest = node);
              }}
              renderSuggestion={this._renderSuggestion}
              suggestions={preRenderedSuggestions}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  _onChange(event, { newValue }) {
    if (this.unmounting) {
      return;
    }
    if (typeof newValue !== 'undefined') {
      this.setState({
        value: newValue,
        suggestionsHeight: this._getSuggestionsHeight(),
      });
      return this.props.modalInputValueFn(newValue);
    }
  }

  _onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    if (this.unmounting) {
      return;
    }
    this.props.googlePropertySearch.blur();
    this.props.updateRenderResultsFn(true);
    this.props.updateRenderGoogleFn(false);
    return this.props.onSelect(suggestion);
  }

  _renderSuggestion(suggestion) {
    let suggLineThree, suggLineTwo;
    const cityStateZip =
      suggestion._source.city +
      ', ' +
      suggestion._source.state +
      ' ' +
      suggestion._source.zip +
      ', USA';
    const labelText = suggestion.label;
    if (suggestion._source.propertyName != null) {
      if (suggestion._source.propertyName !== suggestion._source.streetAddress) {
        suggLineTwo = suggestion._source.streetAddress;
        suggLineThree = cityStateZip;
      } else {
        suggLineTwo = cityStateZip;
        suggLineThree = '';
      }
    } else {
      suggLineTwo = cityStateZip;
      suggLineThree = '';
    }
    return (
      <div className={cx(['suggestedProperty'])}>
        <div className={cx(['label'])}>{labelText}</div>
        <div className={cx(['propertyType'])}>{suggestion._source.propertyType}</div>
        <div className={cx(['p'])} />
        <div className={cx(['suggestionLineTwo'])}>{suggLineTwo}</div>
        <div className={cx(['propertyId'])}>
          {'ID: '}
          {suggestion._id}
        </div>
        <div className={cx(['p'])} />
        <div className={cx(['suggestionLineThree'])}>{suggLineThree}</div>
      </div>
    );
  }

  _getSuggestionsHeight() {
    if (this.autosuggest != null) {
      return this.autosuggest.suggestionsContainer.offsetHeight;
    } else {
      return 0;
    }
  }

  _getDefaultProps() {
    return {
      multi: false,
      debounce: 200,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
}
N360PropertySearch.initClass();

module.exports = N360PropertySearch;
