const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const Image = require('../image');
const ReadyRegistry = require('@utils/ready_registry');
const DRAG_TYPES = require('@enums/drag_types');
const { useDrop } = require('react-dnd');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

const ImageWrapper = (props) => {
  const _getBlankProps = () => {
    return {
      className: cx([
        `blank`,
        `${props.isDragging ? 'is-dragging' : ''}`,
        `${props.isEditing ? 'is-editing' : ''}`,
      ]),
    };
  };
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DRAG_TYPES.ENTRY_IMAGE],
      drop: (item, monitor) => {
        props.update(monitor.getItem());
        return { index: props.index };
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  return (
    <div className={cx(['root'])} ref={drop}>
      {props.image ? (
        <Image
          croppable={props.canDropImages}
          draggable={props.canDropImages}
          entryLocation={props.entryLocation}
          image={props.image}
          imagePath={props.imagePath}
          index={props.index}
          isEditing={props.isEditing}
          readyRegistry={props.readyRegistry}
          update={props.update}
        />
      ) : props.canDropImages ? (
        <div {..._getBlankProps()}>
          <FontAwesomeIcon name="arrow-right-arrow-left" />
          <div className={cx(['text'])}>Drag images here</div>
        </div>
      ) : (
        <div {..._getBlankProps()} />
      )}
    </div>
  );
};

ImageWrapper.propTypes = {
  canDropImages: PropTypes.bool,
  entryLocation: PropTypes.object,
  image: PropTypes.object,
  imagePath: PropTypes.array,
  index: PropTypes.number,
  isDragging: PropTypes.bool,
  isEditing: PropTypes.bool,
  readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
  setIsDragging: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

module.exports = ImageWrapper;
