const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const PropTypes = require('prop-types');
const React = require('react');
const CollapsibleQuestion = require('@components/shared/collapsible_question');

class PdfPageTypeInput extends React.Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
  }

  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      navigateForward: PropTypes.func.isRequired,
      path: PropTypes.arrayOf(PropTypes.string).isRequired,
      setPdfPageDefaultLayout: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
      value: PropTypes.string,
    };
  }

  render() {
    return (
      <CollapsibleQuestion
        active={this.props.active}
        answers={[
          { label: 'Images', value: 'image' },
          { label: 'Floor Plans', value: 'floor_plan' },
          { label: 'Add Notes', value: 'additional_note' },
          { label: 'Delete', value: 'skipped' },
        ]}
        onChange={this._onChange}
        question="What kind of page would you like to add to this listing?"
        value={this.props.value} />
    );
  }

  _onChange(type) {
    this.props.update(type);
    if (type === 'image') {
      this.props.setPdfPageDefaultLayout(_.initial(this.props.path));
    }
    if (type === 'skipped') {
      const entryId = this.props.path[2];
      const pageId = _.last(_.initial(this.props.path));
      this.props.removeEntryImagePage({ entryId, pageId });
    }
    return this.props.navigateForward(type);
  }
}
PdfPageTypeInput.initClass();

module.exports = PdfPageTypeInput;
