const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const CropImageModal = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'trackCropImage',
  'update',
]);

const mapStateToProps = (state, ownProps) => {
  return {
    image: _.get(state.tourbookEditorPage.data, ownProps.options.path),
  };
};

module.exports = build({
  component: CropImageModal,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['imageUrlService'],
});
