const _ = require('lodash');
const { default: GeoSuggest } = require('react-geosuggest');
// GeoSuggest = require('react-geosuggest').default
const ReactDOM = require('react-dom');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const { locateService } = require('@services/service_locator');

var GooglePropertySearch = (function () {
  let typeIsArray = undefined;
  GooglePropertySearch = class GooglePropertySearch extends React.Component {
    static initClass() {
      this.propTypes = {
        ignoreBlur: PropTypes.bool,
        visibility: PropTypes.string,
        n360PropertySearchHeight: PropTypes.number,
        initialValue: PropTypes.string,
        renderSuggestItem: PropTypes.func,
        subTitle: PropTypes.object,
        setGooglePropertySearchFocused: PropTypes.func,
        n360PropertySearch: PropTypes.object,
        updateRenderResultsFn: PropTypes.func,
        updateRenderN360SuggestsFn: PropTypes.func,
      };

      typeIsArray =
        Array.isArray ||
        ((value) => ({}.toString.call(value) === '[object Array]'));
    }

    constructor(props) {
      super(props);
      this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
      this._onChange = this._onChange.bind(this);

      this.state = {
        suggestions: [],
        isLoading: false,
        marginTop: 0,
        message: this._getGeoSearchMessage(),
      };
    }

    componentWillUpdate() {
      return (ReactDOM.findDOMNode(this).style.marginTop =
        this.props.n360PropertySearchHeight);
    }

    render() {
      return (
        <div className={cx(['root'])}>
          <div
            style={{
              visibility: this.props.visibility,
              marginTop: this.props.n360PropertySearchHeight,
            }}
            ref={(node) => {
              return (this.subTitle = node);
            }}
            className={cx(['subtitle'])}>
            {this._getGeoSearchMessage()}
          </div>
          <div
            className={cx(['input-container'])}
            onMouseEnter={() => {
              this.props.setGooglePropertySearchFocused(true);
            }}
          >
            <GeoSuggest
              inputProps={{
                ignoreBlur: false,
                onChange: this._onChange,
                placeholder: this.props.inputValue,
                value: this.props.inputValue,
              }}
              onSuggestSelect={this._onSuggestionSelected}
              onFocus={() =>
                this.setState({
                  isSuggestsHidden: false,
                })}
              ref={(node) => {
                return (this.geoSuggest = node);
              }}
              renderSuggestItem={this.renderSuggestItem} />
          </div>
        </div>
      );
    }

    _onSuggestionSelected({ gmaps }) {
      if (this.unmounting) {
        return;
      }
      const { input } = this.geoSuggest.input;
      this.setState({ isSuggestsHidden: true });
      if (gmaps != null) {
        this.props.getNewEntryDataFn({ googleAddress: gmaps });
        this.props.updateRenderResultsFn(true);
        return this.props.updateRenderN360SuggestsFn(false);
      } else {
        this.props.track('Fails to Search for Property Address', {
          additionalProperties: { search: input.value },
        });
        return this.setState({ notFound: true });
      }
    }

    _onChange(event, { newValue }) {
      if (this.unmounting) {
        return;
      }
      if (this.props.getNewEntryDataStatus != null) {
        this.props.deleteNewEntryData();
      }
      if (this.state.notFound) {
        this.setState({ notFound: false });
      }
      if (this.props.n360PropertySearchHeight > 0) {
        return this.setState({ visibility: 'visible' });
      } else {
        return this.setState({ visibility: 'hidden' });
      }
    }

    blur() {
      return this._hideAll();
    }

    _hideAll() {
      this._hideSubTitle();
      if (this.geoSuggest != null) {
        this.geoSuggest.hideSuggests();
      }
      return this.setState({ isSuggestsHidden: true });
    }

    _hideSubTitle() {
      if (this.subTitle != null) {
        this.subTitle.textContent = '';
        this.subTitle.style = { visibility: 'hidden', marginTop: 0 };
        this.setState({ visibility: 'hidden' });
        return this.setState({ isSuggestsHidden: true });
      }
    }

    _showSubTitle() {
      if (this.subTitle != null) {
        this.subTitle.textContent = this._getGeoSearchMessage();
        this.subTitle.style = {
          visibility: 'visible',
          marginTop: this.props.n360PropertySearchHeight,
        };
        this.setState({ visibility: 'visibile' });
        return this.setState({ isSuggestsHidden: false });
      }
    }

    _getGeoSearchMessage() {
      let returnMessage = '';
      if (this.props.n360PropertySearchHeight > 0) {
        returnMessage =
          ' or select an address from Google Maps to create a new listing...';
      }
      return returnMessage;
    }
  };
  GooglePropertySearch.initClass();
  return GooglePropertySearch;
})();

module.exports = GooglePropertySearch;
