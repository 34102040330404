const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const Hint = require('@components/shared/hint');
const InputContainer = require('@components/shared/input/container');
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class InputRow extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      className: PropTypes.string,
      currentField: PropTypes.object.isRequired,
      dragHandle: PropTypes.element,
      input: PropTypes.shape({
        label: PropTypes.string,
        disableEnterNavigation: PropTypes.bool,
        useShiftEnterNavigation: PropTypes.any,
        options: PropTypes.shape({
          section: PropTypes.string,
        }),
      }).isRequired,
      navigateForward: PropTypes.func,
      onClick: PropTypes.func,
      shouldAddColonToLabel: PropTypes.bool,
      shouldDisplayHint: PropTypes.bool.isRequired,
      titleOverride: PropTypes.string,
      hideLock: PropTypes.bool,
      hideHint: PropTypes.bool,
      hideBullets: PropTypes.bool,
    };

    this.defaultProps = { shouldAddColonToLabel: true };
  }

  render() {
    return (
      <div {...this._getRootProps()}>
        {this.props.dragHandle}
        {this.props.input.label ? (
          [
            <div key={0} className={cx(['label-container'])}>
              <label>
                {this.props.input.label}
                {this.props.shouldAddColonToLabel ? ':' : undefined}
              </label>
            </div>,
            <div key={1} className={cx(['input-container'])}>
              <InputContainer {...this._getInputProps()}  value={this.props.value}  disabled={this.props.disabled}/>
            </div>,
          ]
        ) : (
          <InputContainer {...this._getInputProps()} value={this.props.value} disabled={this.props.disabled}/>
        )}
        {this.props.shouldDisplayHint &&
        !this.props.input.disableEnterNavigation ? (
          <div className={cx(['hint-wrapper'])}>
            <Hint
              active={this.props.active}
              useShiftEnterNavigation={this.props.input.useShiftEnterNavigation}
            />
          </div>
        ) : undefined}
        {['space', 'property', 'subLease', 'terms'].includes(this.props.input.options?.section) &&
        this.props.input.label ? (
          <>
            <div
              className={cx(['additional-input-selection-button'])}
              onClick={() =>
                this.props.setCurrentModal({
                  key: 'additional-input-selection',
                  ...this.props.input,
                })
              }
            >
              <FontAwesomeIcon name="ellipsis-vertical" />
            </div>
            <span
              className={cx(['delete-icon'])}
              onClick={() =>
                this.props.setCurrentModal({
                  key: 'delete-field',
                  ...this.props.input,
                })
              }
            >
              <FontAwesomeIcon name="trash-can" />
            </span>
          </>
        ) : undefined}
      </div>
    );
  }

  _getInputProps() {
    return _.assign({}, this.props.input, {
      active: this.props.active,
      internalIndex: this.props.currentField.internalIndex,
      navigateForward: this.props.navigateForward,
      titleOverride: this.props.titleOverride,
      hideLock: this.props.hideLock,
      hideHint: this.props.hideHint,
      hideBullets: this.props.hideBullets,
      value: this.props.value,
      disabled: this.props.disabled,
    });
  }

  _getRootProps() {
    return {
      className: cx([
        'root',
        this.props.active ? 'active' : '',
        this.props.input.label ? 'inline' : '',
        this.props.className,
      ]),
      onClick: this.props.onClick,
    };
  }
}
InputRow.initClass();

module.exports = InputRow;
