const { locateServices } = require('./service_locator');

class CustomerCommunicationService {
  constructor({ axios, ENV: { customerIoConfig } }) {
    ({
      analyticsService: this.analyticsService,
      cloudinaryService: this.cloudinaryService,
      docraptorApiService: this.docraptorApiService,
    } = locateServices(['analyticsService', 'cloudinaryService', 'docraptorApiService']));
    this.axios = axios;
    this.customerIoConfig = customerIoConfig;
  }

  async sendDigitalTourbookToClient(userId, eventProperties, docraptorPdfUrl) {
    if (docraptorPdfUrl) {
      const pdfUrl = await this.cloudinaryService.downloadAndUploadFile(
        docraptorPdfUrl,
        eventProperties['tourbook_name']
      );
      eventProperties.pdf_url = pdfUrl;
    }

    const identifier = userId;

    return this.axios.post(
      `https://track.customer.io/api/v1/customers/${encodeURIComponent(identifier)}/events`,
      {
        name: 'Send Digital Tourbook To Client',
        data: {
          // TODO
          ...eventProperties,
          properties: { ...eventProperties },
          userId: userId,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(
            `${this.customerIoConfig.siteId}:${this.customerIoConfig.apiKey}`
          )}`,
        },
      }
    );
  }
}
module.exports = CustomerCommunicationService;
