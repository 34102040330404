const { createAction } = require('redux-actions');

const actions = {};
['fulfilled', 'pending', 'rejected', 'reset'].forEach(
  (type) => (actions[type] = createAction(`promiseInspections/${type}`))
);

const promiseInspectionBuilder = (namespace) =>
  (function(key, promiseBuilder) {
    key = `${namespace}/${key}`;

    return async function(dispatch, ...args) {
      let reason, value;
      dispatch(actions.pending(key));

      try {
        value = await promiseBuilder(dispatch, ...Array.from(args));
      } catch (e) {
        reason = e;
      }

      if (reason) {
        dispatch(actions.rejected({ key, reason }));
        throw reason;
      } else {
        dispatch(actions.fulfilled({ key, value }));
        return value;
      }
    };
  });

promiseInspectionBuilder.actions = actions;

module.exports = promiseInspectionBuilder;
