const querystring = require('querystring');
const { locateService } = require('./service_locator');

class ImageUrlService {
  constructor({ ENV: { googleConfig } }) {
    this.googleConfig = googleConfig;
    this.cloudinaryService = locateService('cloudinaryService');
  }

  getStaticUrlForImage(image, options) {
    if (options == null) {
      options = {};
    }
    if (image.url != null) {
      return image.url;
    }
    if (options.logo) {
      return this.cloudinaryService.getLogoUrl(image);
    } else {
      switch (image.type) {
        case 'streetview':
          return this._getStaticStreetviewUrl(image, options);
        case 'map':
          return this._getStaticMapUrl(image, options);
        default:
          return this.cloudinaryService.getImageUrl(image, options);
      }
    }
  }

  _getStaticMapUrl(image, options) {
    if (options == null) {
      options = {};
    }
    const { latitude, longitude } = options.entryLocation;
    const queryObj = {
      center: image.center.join(','),
      key: this.googleConfig.key,
      maptype: image.maptypeid || 'roadmap',
      markers: [latitude, longitude].join(','),
      scale: 2,
      size: [options.width, options.height].join('x'),
      zoom: image.zoom,
    };
    return `https://maps.googleapis.com/maps/api/staticmap?${querystring.stringify(
      queryObj
    )}`;
  }

  _getStaticStreetviewUrl(image, options) {
    if (options == null) {
      options = {};
    }
    const queryObj = {
      heading: image.pov?.heading,
      key: this.googleConfig.key,
      pitch: image.pov?.pitch,
      size: [options.width, options.height].map((x) => x * 2).join('x'),
      fov: 180 / Math.pow(2, image.pov?.zoom),
    };
    if (image.pano) {
      queryObj.pano = image.pano;
    } else {
      queryObj.location = image.position.join(',');
    }
    return `https://maps.googleapis.com/maps/api/streetview?${querystring.stringify(
      queryObj
    )}`;
  }
}

module.exports = ImageUrlService;
