const PropTypes = require('prop-types');
const React = require('react');
const Switch = require('@components/shared/switch');

/**
 * The toggleInput allows users to toggle a boolean on-or-off
 * The ValueToggleInput allows users to turn a value on or off
 * e.g. supplying 
 * toggleValue={key: "propertyAddress", order: 4}  
 * path=['entryId', 'content', 'settings' , 'summaryFields', 'propertyAddress']
 * 
 * Would set propertyAddress = {key: "propertyAddress", order: 4}  when toggled on, and to null (deleting the value) when toggled off
 */
class ValueToggleInput extends React.Component {
  static initClass() {
    this.propTypes = {
      update: PropTypes.func.isRequired,
      toggleValue: PropTypes.object.isRequired,
      defaultEnable: PropTypes.bool.isRequired,
    };
  }

  render() {
    const convertUndefinedToNull = function (obj) {
      const copy = _.cloneDeep(obj);
      const keys = Object.keys(copy);
      for (var i = 0; i < keys.length; i++) {
        if (copy[keys[i]] === undefined) {
          copy[keys[i]] = null;
        }
      }
      return copy;
    }
    return (
        <Switch
        onChange={(newValue) => {
            if (newValue) {
              return this.props.update(convertUndefinedToNull(this.props.toggleValue));
            }
          return this.props.update(null);
          }}
          value={this.props.defaultEnable}
        />
      );
  }
}
ValueToggleInput.initClass();

module.exports = ValueToggleInput;
