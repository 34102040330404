const Promise = require('bluebird');
const _ = require('lodash');
const { locateServices } = require('./service_locator');
const queryString = require('query-string');

var Auth0Service = (function () {
  let getDelegationToken = undefined;
  Auth0Service = class Auth0Service {
    static initClass() {
      this.prototype.authenticationTokenMapping = {
        auth0AccessToken: 'auth0-accessToken',
        auth0IdToken: 'auth0-idToken',
        firebaseToken: 'auth0-firebaseToken',
      };

      this.prototype.initialize = async function () {
        let tokens = this._getTokens();
        if (!_.every(tokens)) {
          return null;
        }
        try {
          const user = await this._getProfile(tokens);
          await this._completeAuth(user);
          return user;
        } catch (error) {
          return this.logout();
        }
      };

      this.prototype.login = async function ({ connection, email, password }) {
        const { accessToken: auth0AccessToken, idToken: auth0IdToken } = await this._authenticateAuth0(email, password);
        const user = await this._getProfile({ auth0AccessToken, auth0IdToken });
        await this._completeLogin(user);
        return user;
      };

      this.prototype._completeAuth = async function (user) {
        this._saveSessionToLocalStorage(user);
        await this.analyticsService.identify(user);
        return this.analyticsService.sendEventQueue();
      };

      this.prototype._completeLogin = async function (user) {
        await this._completeAuth(user);
        return this.analyticsService.track('Log in');
      };

      this.prototype._completeSignup = async function (user) {
        this.analyticsService.setAlias(user.id);
        await this.tourbooksService.copySample(user);
        await this._completeAuth(user);
        this.sharingService.handleSharing();
        return this.analyticsService.track('Signed up');
      };

      getDelegationToken = (auth0Client, options) =>
        new Promise((resolve, reject) =>
          auth0Client.delegation(options, function (err, res) {
            if (err) {
              return reject(err);
            }
            return resolve(res);
          })
        );

      this.prototype._getFirebaseToken = async function (auth0IdToken) {
        const options = {
          id_token: auth0IdToken,
          scope: 'openid offline_access profile email',
          api_type: 'firebase',
          grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        };
        const delegationResult = await getDelegationToken(
          this.auth0.client,
          options
        );
        return delegationResult.idToken;
      };

      this.prototype._getProfile = async function ({
        auth0AccessToken,
        auth0IdToken,
        createFirebaseProfile,
      }) {
        const auth0Profile = await this._getAuth0Profile(auth0AccessToken);
        const firebaseToken = await this._getFirebaseToken(auth0IdToken);
        const firebaseUser = await this.firebaseService.authenticate(
          firebaseToken
        );

        const id = auth0Profile.user_id;
        const userIdsWithLowerCaseEmail =
          await this.firebaseService.getUserIdsByEmail(auth0Profile.email);
        let firebaseProfile = await this.usersService.getProfile(id);

        createFirebaseProfile =
          createFirebaseProfile ||
          firebaseProfile === undefined ||
          firebaseProfile === null;
        const profileExistsWithLowerCaseEmail =
          userIdsWithLowerCaseEmail && userIdsWithLowerCaseEmail.length > 0;

        if (createFirebaseProfile) {
          await this.firebaseService.writeToEmailMap(
            auth0Profile.email.toLowerCase(),
            id
          );
          firebaseProfile = await this.usersService.createProfile(
            id,
            auth0Profile
          );
        } else if (!profileExistsWithLowerCaseEmail) {
          await this.firebaseService.deleteFromEmailMap(auth0Profile.email);
          await this.firebaseService.writeToEmailMap(
            auth0Profile.email.toLowerCase(),
            id
          );
          await this.firebaseService.updateProfileEmail(
            id,
            email.toLowerCase()
          );
        }
        firebaseProfile = await this.usersService.getProfile(id); // Getting the updated profile

        if (firebaseProfile?.first_name === null) {
          this.usersService.updateProfile(id, auth0Profile);
        }

        const [isAdmin, isDisabled] = Array.from(
          await Promise.all([
            this.usersService.isAdmin(id),
            this.usersService.isDisabled(id),
          ])
        );

        const user = {
          auth0AccessToken,
          auth0IdToken,
          auth0Profile,
          firebaseProfile,
          firebaseToken,
          firebaseUser,
          id,
          isAdmin,
          isDisabled,
        };
        user.organization = this.organizationService.get(user);
        return user;
      };
    }

    constructor({ auth0, localStorage }) {
      this.auth0 = auth0; // auth0 originates from react-app/editor_frontend/src/services/dependencies.js - require('auth0-js').WebAuth(...)
      this.localStorage = localStorage;
      ({
        analyticsService: this.analyticsService,
        firebaseService: this.firebaseService,
        organizationService: this.organizationService,
        sharingService: this.sharingService,
        tourbooksService: this.tourbooksService,
        usersService: this.usersService,
      } = locateServices([
        'analyticsService',
        'firebaseService',
        'organizationService',
        'sharingService',
        'tourbooksService',
        'usersService',
      ]));
    }

    logout() {
      this._clearLocalStorage();
      this.analyticsService.track('Log out');
      return this.analyticsService.clearAnalytics();
    }

    _authenticateAuth0(email, password) {
      const cookieName = "convergenceRedirect";
      localStorage.setItem(cookieName, window.location.href);
      
      return this.auth0.authorize({
        connection: 'N360AAD', // Check https://manage.auth0.com/dashboard/us/spaceful/connections/enterprise/samlp
        responseType: 'token',
      });
    }

    _clearLocalStorage() {
      const keysToRemove = _.keys(this.authenticationTokenMapping).concat(
        _.values(this.authenticationTokenMapping)
      );
      return keysToRemove.forEach((key) => this.localStorage.removeItem(key));
    }

    _getAuth0Profile(auth0AccessToken) {
      const auth0Client = this.auth0.client;
      return new Promise((resolve, reject) =>
        auth0Client.userInfo(auth0AccessToken, function (err, res) {
          if (err) {
            return reject(err);
          }
          // to be backwards-compatible with v7 of auth0.js, need to set this:
          res.user_id = res.user_id || res.sub;
          return resolve(res);
        })
      );
    }

    _getTokens() {
      let tokens = this._createAndPopulateTokens();
      if (!_.every(tokens) && window.localStorage) {
        for (const key in tokens) {
          if (!tokens[key]) {
            tokens[key] = window.localStorage[key];
          }
        }
      }
      return tokens;
    }

    _createAndPopulateTokens() {
      return _.mapValues(
        this.authenticationTokenMapping,
        function (storageTokenName, tokenName) {
          let keyStr, urlToken;
          switch (storageTokenName) {
            case 'auth0-accessToken':
              keyStr = 'access_token';
              break;
            case 'auth0-idToken':
              keyStr = 'id_token';
              break;
            case 'auth0-firebaseToken':
              keyStr = 'firebaseToken';
              break;
          }
          if (keyStr === 'firebaseToken') {
            return (urlToken =
              'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ijc5YjQwMjJhOTQ1NjYzYWY4ZjVhMTY1NDdiODlkMGE3OGVmYzEyNDYifQ.eyJ1aWQiOiJzYW1scHxOMzYwQUFEfGNhcmxvc3F1ZXphZGEiLCJpYXQiOjE2MzUyMTk0ODksImV4cCI6MTYzNTIyMzA4OSwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eXRvb2xraXQuZ29vZ2xlYXBpcy5jb20vZ29vZ2xlLmlkZW50aXR5LmlkZW50aXR5dG9vbGtpdC52MS5JZGVudGl0eVRvb2xraXQiLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay03OGkxekBzcGFjZWZ1bC1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay03OGkxekBzcGFjZWZ1bC1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20ifQ.Z4ol8KjXNQbEIV2RO_b-tcKHR0IRRpSRH55c7DBbCWOky2IATCyyb5qIDFcoE2wCYH5ltyBv8JxbeZFutSSyg3HNKuFRrlwI6gKfdCmFnO3JpZbFu8n66iW3oD_jEMclEisDZi2BVxCY3F7zc1PTPYQmLpatoXHsymVpNjXnyfxGDvJ5_9M8lJKn3oCy6KPcGfAN_2pFULStFubCzBhIEpHi7sU6eBu-HwRCUHVCBw3_EHAvxeAoHngG6k147S9Fs1n3Ko24bFHG14Q5oPu5n4ocmYQGoOcdoZIQFlfj048VJDxJ8YwTaylR9Vc0EKpTzAkEk-AF5UtUyFnhdPERLA');
          } else {
            return (urlToken = queryString.parse(window.location.hash)[keyStr]); // Getting tokens from url
          }
        }
      );
    }

    _saveSessionToLocalStorage(data) {
      const dataToSave = _.pick(data, _.keys(this.authenticationTokenMapping));
      return _.each(dataToSave, (value, key) =>
        this.localStorage.setItem(key, value)
      );
    }
  };
  Auth0Service.initClass();
  return Auth0Service;
})();

module.exports = Auth0Service;
