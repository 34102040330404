const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const Loading = require('@components/shared/loading');
const ModalContainer = require('@components/shared/modal/container');
const PdfContainer = require('../pdf/container');
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class DownloadPdfModal extends React.Component {
  constructor(props) {
    super(props);
    this._onDownload = this._onDownload.bind(this);
    this._onReady = this._onReady.bind(this);
    this.props.resetStatus();
  }

  static initClass() {
    this.propTypes = {
      generatePdf: PropTypes.func.isRequired,
      onClose: PropTypes.func.isRequired,
      pdfUrl: PropTypes.string,
      recordTourbookPublish: PropTypes.func.isRequired,
      resetStatus: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <ModalContainer onClose={this.props.onClose} verticallyCentered={true}>
        {this.props.pdfUrl ? (
          <div className={cx(['root'])}>
            <div className={cx(['check'])}>
              <FontAwesomeIcon name="check" size="4x" />
            </div>
            <div className={cx(['text'])}>PDF has been generated</div>
            <div className={cx(['links'])}>
              <a
                href={this.props.pdfUrl}
                onClick={this._onDownload}
                className={cx(['download'])}
              >
                Download PDF
              </a>
              <a onClick={this.props.onClose} className={cx(['cancel'])}>
                Cancel
              </a>
            </div>
          </div>
        ) : typeof this.props.error !== 'undefined' ? (
          <div className={cx(['root'])}>
            <div className={cx(['error'])}>
              There was a problem generating the PDF. Please try again.
            </div>
            <a onClick={this.props.resetStatus} className={cx(['try-again'])}>
              Try Again
            </a>
          </div>
        ) : (
          <div className={cx(['root'])}>
            <div className={cx(['text'])}>Your PDF is being generated ({this.props.pending ? 'Generating' : 'Prepping'})</div>
            <div className={cx(['warning'])}>
              Depending on the size of the PDF, this could take a few minutes.
            </div>
            <Loading inline={true} />
            <div className={cx(['pdf-wrapper'])}>
              <PdfContainer onReady={this._onReady} />
            </div>
          </div>
        )}
      </ModalContainer>
    );
  }

  _onDownload() {
    this.props.recordTourbookPublish('pdfDownload');
    return this.props.onClose();
  }

  _onReady(pdfPreviewHtml) {
    return this.props.generatePdf(pdfPreviewHtml);
  }
}
DownloadPdfModal.initClass();

module.exports = DownloadPdfModal;
