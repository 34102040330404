const LoadingToggle = require('@components/shared/loading/toggle');
const React = require('react');

class UserSessionInitializer extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.initializeStatus?.fulfilled) {
      this.props.initialize().then((unsubscribeFn) => {
        this.teardown = unsubscribeFn;
      });
    }
  }

  componentWillUnmount() {
    return typeof this.teardown === 'function' ? this.teardown() : undefined;
  }

  render() {
    return LoadingToggle(
      {
        isLoading: !this.props.initializeStatus?.fulfilled,
      },
      this.props.children
    );
  }
}

module.exports = UserSessionInitializer;
