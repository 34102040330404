/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const PageNumberService = require('./page_number_service');

describe('PageNumberService', function () {
  beforeEach(function () {
    return (this.pageNumberService = new PageNumberService());
  });

  describe('_getIntroPagesCount', function () {
    beforeEach(function () {
      return (this.tourbook = {
        content: {
          settings: {
            display: {
              summary: false,
              tourMap: false,
            },
          },
        },
      });
    });

    it('cover, map page, and empty summary are enabled', function () {
      this.tourbook.content.settings.display.summary = true;
      this.tourbook.content.settings.display.tourMap = true;
      this.tourbook.content.entries = { entry1: 'entry1' };
      const pageCount = this.pageNumberService._getIntroPagesCount(
        this.tourbook
      );
      return expect(pageCount).to.eql(3);
    });

    it('cover and map page are enabled', function () {
      this.tourbook.content.settings.display.tourMap = true;
      const pageCount = this.pageNumberService._getIntroPagesCount(
        this.tourbook
      );
      return expect(pageCount).to.eql(2);
    });

    it('only the cover page is shown', function () {
      const pageCount = this.pageNumberService._getIntroPagesCount(
        this.tourbook
      );
      return expect(pageCount).to.eql(1);
    });

    it('cover page and multiple-entry summary page is enabled', function () {
      this.tourbook.content.settings.display.summary = true;
      this.tourbook.content.entries = {
        entry1: 'entry1',
        entry2: 'entry2',
        entry3: 'entry3',
        entry4: 'entry4',
        entry5: 'entry5',
      };
      const pageCount = this.pageNumberService._getIntroPagesCount(
        this.tourbook
      );
      return expect(pageCount).to.eql(3);
    });

    return it('ignores entries when the summary page is disabled', function () {
      this.tourbook.content = {
        entries: {
          entry1: 'entry1',
          entry2: 'entry2',
          entry3: 'entry3',
          entry4: 'entry4',
          entry5: 'entry5',
        },
      };
      const pageCount = this.pageNumberService._getIntroPagesCount(
        this.tourbook
      );
      return expect(pageCount).to.eql(1);
    });
  });

  describe('_getSummaryPageCount', function () {
    beforeEach(function () {
      return (this.entries = {});
    });

    it('one entry', function () {
      this.entries = { entry1: 'entry1' };
      const pageCount = this.pageNumberService._getSummaryPageCount(
        this.entries
      );
      return expect(pageCount).to.eql(1);
    });

    it('up to four entries', function () {
      this.entries = {
        entry1: 'entry1',
        entry2: 'entry2',
        entry3: 'entry3',
        entry4: 'entry4',
      };
      const pageCount = this.pageNumberService._getSummaryPageCount(
        this.entries
      );
      return expect(pageCount).to.eql(1);
    });

    it('more than four and less than nine entries', function () {
      this.entries = {
        entry1: 'entry1',
        entry2: 'entry2',
        entry3: 'entry3',
        entry4: 'entry4',
        entry5: 'entry5',
      };
      const pageCount = this.pageNumberService._getSummaryPageCount(
        this.entries
      );
      return expect(pageCount).to.eql(2);
    });

    return it('many entries', function () {
      for (let i = 0; i < 30; i++) {
        this.entries['entry' + i] = 'entry' + i;
      }
      const pageCount = this.pageNumberService._getSummaryPageCount(
        this.entries
      );
      return expect(pageCount).to.eql(8);
    });
  });

  describe('_getEntryPageCount', function () {
    beforeEach(function () {
      return (this.entry = {});
    });

    it('returns the number of pages for an entry', function () {
      const pageCount = this.pageNumberService._getEntryPageCount(this.entry);
      return expect(pageCount).to.eql(1);
    });

    it('includes the image pages', function () {
      this.entry = { pages: { images: { image1: 'image' } } };
      const pageCount = this.pageNumberService._getEntryPageCount(this.entry);
      return expect(pageCount).to.eql(2);
    });

    it('includes the amenities page', function () {
      this.entry = { pages: { amenities: { 1: 'amenities' } } };
      const pageCount = this.pageNumberService._getEntryPageCount(this.entry);
      return expect(pageCount).to.eql(2);
    });

    it('includes the image and amenities pages', function () {
      this.entry = {
        pages: {
          amenities: { amenity1: 'amenities' },
          images: { image1: 'image' },
        },
      };
      const pageCount = this.pageNumberService._getEntryPageCount(this.entry);
      return expect(pageCount).to.eql(3);
    });

    return it('works for multiple image pages', function () {
      this.entry = {
        pages: {
          amenities: { 1: 'amenities' },
          images: {
            image1: 'image',
            image2: 'image',
            image3: 'image',
            image4: 'image',
            image5: 'image',
          },
        },
      };
      const pageCount = this.pageNumberService._getEntryPageCount(this.entry);
      return expect(pageCount).to.eql(7);
    });
  });

  describe('_getPreviousEntriesPageCount', function () {
    it('returns 0 for the first entry in a tourbook', function () {
      const entries = {
        entry1: { order: 0 },
        entry2: { order: 1 },
      };
      const previousEntriesPageCount =
        this.pageNumberService._getPreviousEntriesPageCount(entries, 'entry1');
      return expect(previousEntriesPageCount).to.eql(0);
    });

    it('correctly returns when preceded by entries with no extra pages', function () {
      const entries = {
        entry1: { order: 0 },
        entry2: { order: 1 },
        entry3: { order: 2 },
      };
      const previousEntriesPageCount =
        this.pageNumberService._getPreviousEntriesPageCount(entries, 'entry3');
      return expect(previousEntriesPageCount).to.eql(2);
    });

    it('returns correctly when entries have been re-ordered', function () {
      const entries = {
        entry1: { order: 2 },
        entry2: { order: 0 },
        entry3: { order: 1 },
      };
      const previousEntriesPageCount =
        this.pageNumberService._getPreviousEntriesPageCount(entries, 'entry3');
      return expect(previousEntriesPageCount).to.eql(1);
    });

    it('correctly returns when preceded by entries with extra pages', function () {
      const entries = {
        entry1: { order: 0 },
        entry2: {
          order: 1,
          pages: {
            amenities: { 1: 'amenities' },
            images: {
              1: 'image',
              2: 'image',
              3: 'image',
              4: 'image',
              5: 'image',
            },
          },
        },
        entry3: {
          order: 2,
          pages: {
            images: {
              1: 'image',
              2: 'image',
              3: 'image',
              4: 'image',
              5: 'image',
            },
          },
        },
        entry4: {
          order: 3,
          pages: { amenities: { 1: 'amenity' } },
        },
        entry5: { order: 4 },
        entry6: { order: 5 },
      };
      const previousEntriesPageCount =
        this.pageNumberService._getPreviousEntriesPageCount(entries, 'entry5');
      return expect(previousEntriesPageCount).to.eql(16);
    });

    it('correctly returns when preceded by entries with extra pages, out of order', function () {
      const entries = {
        entry1: { order: 5 },
        entry2: {
          order: 2,
          pages: {
            amenities: { 1: 'amenities' },
            images: {
              1: 'image',
              2: 'image',
              3: 'image',
              4: 'image',
              5: 'image',
            },
          },
        },
        entry3: {
          order: 4,
          pages: {
            images: {
              1: 'image',
              2: 'image',
              3: 'image',
              4: 'image',
              5: 'image',
            },
          },
        },
        entry4: {
          order: 3,
          pages: { amenities: { 1: 'amenity' } },
        },
        entry5: { order: 0 },
        entry6: { order: 1 },
      };
      const previousEntriesPageCount =
        this.pageNumberService._getPreviousEntriesPageCount(entries, 'entry3');
      return expect(previousEntriesPageCount).to.eql(11);
    });

    return it("doesn't count amenity pages that aren't shown", function () {
      const entries = {
        entry1: { order: 0 },
        entry2: {
          order: 1,
          pages: {
            amenities: {
              bottom_left: {
                noData: true,
              },
            },
          },
        },
        entry3: {
          order: 2,
        },
      };
      const previousEntriesPageCount =
        this.pageNumberService._getPreviousEntriesPageCount(entries, 'entry3');
      return expect(previousEntriesPageCount).to.eql(2);
    });
  });

  describe('_getAmenityPageCount', function () {
    it('returns 0 pages when there are no amenities', function () {
      const entry = {
        order: 1,
        pages: { images: { 1: 'image1' } },
      };
      return expect(this.pageNumberService._getAmenityPageCount(entry)).to.eql(
        0
      );
    });

    it('returns 1 when an entry has amenities that will show', function () {
      const entry = {
        order: 1,
        pages: { amenities: { 1: 'amenity1' } },
      };
      return expect(this.pageNumberService._getAmenityPageCount(entry)).to.eql(
        1
      );
    });

    it('returns 0 when the amenities have no data', function () {
      const entry = {
        order: 1,
        pages: {
          amenities: {
            bottom_left: {
              noData: true,
            },
          },
        },
      };
      return expect(this.pageNumberService._getAmenityPageCount(entry)).to.eql(
        0
      );
    });

    return it('will show when at least one amenity has data', function () {
      const entry = {
        order: 1,
        pages: {
          amenities: {
            bottom_left: {
              noData: true,
            },
            bottom_right: {
              type: 'factual',
            },
          },
        },
      };
      return expect(this.pageNumberService._getAmenityPageCount(entry)).to.eql(
        1
      );
    });
  });

  describe('_amenityHasData', function () {
    it('returns true if noData is not present', function () {
      const amenity = {
        type: 'factual',
      };
      return expect(this.pageNumberService._amenityHasData(amenity)).to.eql(
        true
      );
    });

    it('returns true if amenity has data', function () {
      const amenity = {
        type: 'factual',
        noData: false,
      };
      return expect(this.pageNumberService._amenityHasData(amenity)).to.eql(
        true
      );
    });

    return it('returns false if amenity has no data', function () {
      const amenity = {
        type: 'factual',
        noData: true,
      };
      return expect(this.pageNumberService._amenityHasData(amenity)).to.eql(
        false
      );
    });
  });

  describe('getEntryPageNumber', function () {
    it('returns the page number for a given entry', function () {
      const entries = {
        entry1: { order: 0 },
        entry2: { order: 1 },
      };
      const entryPageIndex = this.pageNumberService.getEntryPageNumber({
        entries,
        entryId: 'entry1',
        tourbook: {},
      });
      return expect(entryPageIndex).to.eql(2);
    });

    return it('takes previous entries into account', function () {
      const entries = {
        entry1: { order: 0 },
        entry2: {
          order: 1,
          pages: {
            amenities: { 1: 'amenities' },
            images: {
              1: 'image',
              2: 'image',
              3: 'image',
              4: 'image',
              5: 'image',
            },
          },
        },
        entry3: { order: 2 },
      };
      const entryPageIndex = this.pageNumberService.getEntryPageNumber({
        entries,
        entryId: 'entry3',
        tourbook: {},
      });
      return expect(entryPageIndex).to.eql(10);
    });
  });

  describe('getAmenitiesPageNumber', () =>
    it('returns the page number of an amenities page', function () {
      const entries = {
        entry1: { order: 0 },
        entry2: {
          order: 1,
          pages: {
            amenities: {
              amenity1: 'amenity1',
            },
          },
        },
      };
      const amenitiesPageIndex = this.pageNumberService.getAmenitiesPageNumber({
        entries,
        entryId: 'entry2',
        tourbook: {},
      });
      return expect(amenitiesPageIndex).to.eql(4);
    }));

  describe('_getImagePageCount', function () {
    it('returns 0 when there are no images pages in an entry', function () {
      const entry = {};
      const imagePageCount = this.pageNumberService._getImagePageCount(entry);
      return expect(imagePageCount).to.eql(0);
    });

    it('returns a count of the images pages in an entry', function () {
      const entry = {
        order: 0,
        pages: {
          images: {
            image1: 'image1',
            image2: 'image1',
            image3: 'image1',
            image4: 'image1',
            image5: 'image1',
          },
        },
      };
      const imagePageCount = this.pageNumberService._getImagePageCount(entry);
      return expect(imagePageCount).to.eql(5);
    });

    it("doesn't count images pages with type skipped", function () {
      const entry = {
        order: 0,
        pages: {
          images: {
            image1: {
              type: 'skipped',
            },
          },
        },
      };
      const imagePageCount = this.pageNumberService._getImagePageCount(entry);
      return expect(imagePageCount).to.eql(0);
    });

    return it('does count pages of the other two types', function () {
      const entry = {
        order: 0,
        pages: {
          images: {
            image1: {
              type: 'floor-plan',
            },
            image2: {
              type: 'image',
            },
          },
        },
      };
      const imagePageCount = this.pageNumberService._getImagePageCount(entry);
      return expect(imagePageCount).to.eql(2);
    });
  });

  describe('getImagePageNumber', function () {
    beforeEach(function () {
      return (this.entries = {
        entry1: { order: 0 },
        entry2: {
          order: 1,
          pages: {
            images: {
              image1: 'image1',
              image2: 'image2',
            },
          },
        },
      });
    });

    it('returns the page number for an images page', function () {
      const imagePageIndex = this.pageNumberService.getImagePageNumber({
        entries: this.entries,
        entryId: 'entry2',
        imagePageId: 'image1',
        tourbook: {},
      });
      return expect(imagePageIndex).to.eql(4);
    });

    it('takes previous images pages into account', function () {
      const imagePageIndex = this.pageNumberService.getImagePageNumber({
        entries: this.entries,
        entryId: 'entry2',
        imagePageId: 'image2',
        tourbook: {},
      });
      return expect(imagePageIndex).to.eql(5);
    });

    return it("doesn't count skipped pages", function () {
      this.entries = {
        entry1: {
          pages: {
            images: {
              image1: {
                type: 'skipped',
              },
              image2: { someData: 'image page 2' },
            },
          },
        },
      };
      const imagePageIndex = this.pageNumberService.getImagePageNumber({
        entries: this.entries,
        entryId: 'entry1',
        imagePageId: 'image2',
        tourbook: {},
      });
      return expect(imagePageIndex).to.eql(3);
    });
  });

  describe('getSummaryPageNumber', function () {
    it('returns correctly for the first summary page', function () {
      const summaryPageIndex = this.pageNumberService.getSummaryPageNumber(
        0,
        {}
      );
      return expect(summaryPageIndex).to.eql(2);
    });

    it('returns correctly when the map page is turned on', function () {
      const summaryPageIndex = this.pageNumberService.getSummaryPageNumber(0, {
        content: { settings: { display: { tourMap: true } } },
      });
      return expect(summaryPageIndex).to.eql(3);
    });

    it('returns correctly for the second summary page', function () {
      const summaryPageIndex = this.pageNumberService.getSummaryPageNumber(
        4,
        {}
      );
      return expect(summaryPageIndex).to.eql(3);
    });

    return it('returns correctly for an arbitrary summary page', function () {
      const summaryPageIndex = this.pageNumberService.getSummaryPageNumber(
        16,
        {}
      );
      return expect(summaryPageIndex).to.eql(6);
    });
  });

  return describe('_getMapPageCount', () =>
    it('correctly detects a deleted map page', function () {
      const mapPageCount = this.pageNumberService._getMapPageCount({});
      return expect(mapPageCount).to.eql(0);
    }));
});
