const _ = require('lodash');
const Promise = require('bluebird');
const NestedServicesHelpers = require('./nested_services_helpers');
const WalkscoreService = require('./amenities_service/walkscore');
const NearbyPlacesService = require('./amenities_service/nearby_places');
const GooglePlacesService = require('./amenities_service/googleplaces');

class AmenitiesService {
  static initClass() {
    this.nestedServices = {
      nearbyPlaces: NearbyPlacesService,
      googlePlaces: GooglePlacesService,
      walkscore: WalkscoreService,
    };

    this.prototype.getData = async function (type, entry) {
      const result = { isAmenity: true, type }; // isAmenity can be removed once the 1.0 editor is retired
      try {
        const data = await this._getDataByType(type, entry);
        _.assign(result, data);
        result.error = false;
      } catch (error) {
        result.error = true;
      }
      return result;
    };

    this.prototype.getEntryUpdates = async function (entry) {
      const mapping = {
        top_right: 'walkscore'
      };
      const promiseMapping = _.mapValues(mapping, (type) =>
        this.getData(type, entry)
      );
      const result = await Promise.props(promiseMapping);
      return { pages: { amenities: result } };
    };
  }

  constructor(dependencies) {
    this._getDataByType = this._getDataByType.bind(this);
    NestedServicesHelpers.build({
      dependencies,
      nestedServices: this.constructor.nestedServices,
      parent: this,
    });
  }

  getTitle(type) {
    return {
      nearby_places: 'Nearby Photos',
      walkscore: 'Transportation Options',
      googleplaces: 'Top Spots Around',
      googlebiz: 'Nearby Businesses',
    }[type];
  }

  async _getDataByType(type, entry) {
    const {
      CorrectedAddress1: address,
      city,
      state,
      zip,
    } = entry.fields.header;
    const { latitude, longitude } = entry.location;

    switch (type) {
      case 'nearby_places':
        return await this.nearbyPlaces.getLocationImages({
          latitude,
          longitude,
        });
      case 'walkscore':
        return await this.walkscore.get({
          address,
          city,
          latitude,
          longitude,
          state,
          zip,
        });
      case 'googleplaces':
        return await this.googlePlaces.getNearbyVenues({
          latitude,
          longitude,
          bundle: 'places',
        });
      case 'googlebiz':
        return await this.googlePlaces.getNearbyVenues({
          latitude,
          longitude,
          bundle: 'biz ',
        });
      default:
        return Promise.reject(new Error(`Unsupported Amenity Type: ${type}`));
    }
  }
}
AmenitiesService.initClass();

module.exports = AmenitiesService;
