const _ = require('lodash');
const React = require('react');
const AppContainer = require('./components/app/container');
const { LibraryPage } = require('./components/pages/library_page');
const TourbookEditorPageContainer = require('./components/pages/tourbook_editor_page/container');
const { HashRouter, Routes, Route, Navigate, Await } = require('react-router-dom');
const AdminPage = require('./components/pages/admin_page');
const AnalyticsPage = require('./components/pages/analytics_page');
const ProfilePageContainer = require('./components/pages/profile_page/container');

module.exports = function ({ store }) {
  const routeConfigs = [
    {
      component: AdminPage,
      path: '/admin',
    },
    {
      component: LibraryPage,
      path: '/library',
    },
    {
      component: ProfilePageContainer,
      path: '/profile',
    },
    {
      component: TourbookEditorPageContainer,
      path: '/tourbook/:tourbookId',
    },
    {
      component: AnalyticsPage,
      path: '/analytics/:tourbookId',
    },
    {
      component: LibraryPage,
      path: '*',
    },
  ];
  const hasSession = store.getState().userSession != null;

  return (
    <HashRouter>
      <AppContainer>
        <Routes>
          {routeConfigs.map(({ component: Component, path }, index) => (
            <Route
              path={path}
              element={
                <React.Suspense>
                  <Await
                    resolve={hasSession}
                    errorElement={
                      <>
                        There was an issue logging you in. Please reach out to
                        spacefulsupport@nmrk.com if the issue persists
                      </>
                    }
                    children={<Component />}
                  ></Await>
                </React.Suspense>
              }
              key={index}
            />
          ))}
        </Routes>
      </AppContainer>
    </HashRouter>
  );
};
