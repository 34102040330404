const _ = require('lodash');
const importedStyles = require('./index.styl');
const ConfirmActionBar = require('@components/shared/confirm_action_bar');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ImageContainer = require('@components/shared/image/container');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class ImagePicker extends React.Component {
  static initClass() {
    this.propTypes = {
      imagesByType: PropTypes.object.isRequired,
      multiple: PropTypes.bool,
      onSelectImages: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._renderTabLink = this._renderTabLink.bind(this);
    this.state = {
      selectedImages: [],
      tab: this._getTabs()[0],
    };
  }

  render() {
    return (
      <div>
        <ul className={cx(['tab-links'])}>{_.map(this._getTabs(), this._renderTabLink)}</ul>
        <div className={cx(['tab-pane'])}>
          {this._renderTabPane({
            images: this.props.imagesByType[this.state.tab],
            shouldShowCaptions: this.state.tab === 'suggestions',
          })}
          {this.props.multiple && this.state.selectedImages.length > 0 ? (
            <ConfirmActionBar
              cancel={() => this.props.onSelectImages([])}
              confirm={() => this.props.onSelectImages(this.state.selectedImages)}
              label={this._getImportLinkText()}
            />
          ) : undefined}
        </div>
      </div>
    );
  }

  _getImportLinkText() {
    let text = 'Import image';
    if (this.state.selectedImages.length > 1) {
      text += 's';
    }
    return text;
  }

  _getTabs() {
    return ['suggestions', 'n360', 'user'].filter((key) => {
      return _.size(this.props.imagesByType[key]) > 0;
    });
  }

  _isImageSelected(image) {
    return _.includes(this.state.selectedImages, image);
  }

  _onImageClick(image) {
    if (this.props.multiple) {
      const newSelection = this._isImageSelected(image)
        ? _.without(this.state.selectedImages, image)
        : this.state.selectedImages.concat(image);
      return this.setState({ selectedImages: newSelection });
    } else {
      return this.props.onSelectImages([image], this.state.tab);
    }
  }

  _renderTabLink(tab, index) {
    const count = _.size(this.props.imagesByType[tab]);
    const tabLinkProps = {
      className: cx([`tab-link`, `${tab === this.state.tab ? 'active' : ''}`]),
      key: index,
      onClick: () => this.setState({ tab }),
    };
    return (
      <li {...tabLinkProps}>
          {tab === 'user'
            ? [
                <span key={0}>Your Images</span>,
                <div key={1} className={cx(['count'])}>
                  {`(${count})`}
                </div>,
              ]
            : tab === 'n360'
            ? [
                <span key={1}>Images</span>,
                <div key={2} className={cx(['count'])}>
                  {`(${count})`}
                </div>,
              ]
            : 'Suggestions'}
      </li>
    );
  }

  _renderTabPane({ images, shouldShowCaptions }) {
    return (
      <div className={cx(['images'])}>
        {_.map(_.values(images), (image, index) => {
          const selected = this._isImageSelected(image);
          const containerProps = {
            key: index,
            className: cx(['image-container']),
            onClick: () => this._onImageClick(image),
          };
          return (
            <div {...containerProps}>
              <div className={cx(['image'])}>
                <ImageContainer
                  backgroundSize="cover"
                  height="100%"
                  image={image}
                  readyRegistry={new ReadyRegistry()}
                  thumbnail={true}
                  width="100%"
                />
                {selected ? (
                  <div className={cx(['overlay', 'selected'])}>
                    <FontAwesomeIcon name="check" />
                  </div>
                ) : (
                  <div className={cx(['overlay', 'hover'])}>
                    <FontAwesomeIcon name="plus" />
                  </div>
                )}
              </div>
              {shouldShowCaptions && image.mediaTitle != null ? (
                <div className={cx(['caption'])}>{image.mediaTitle}</div>
              ) : undefined}
            </div>
          );
        })}
      </div>
    );
  }
}
ImagePicker.initClass();

module.exports = ImagePicker;
