const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class EntryImagesZeroStateInput extends React.Component {
  constructor(props) {
    super(props);
    this._openChangeImageModal = this._openChangeImageModal.bind(this);
  }

  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      entryId: PropTypes.string.isRequired,
      hideZeroState: PropTypes.func.isRequired,
      numberOfN360Images: PropTypes.number.isRequired,
      path: PropTypes.array.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      track: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <div className={cx(['root', this.props.active ? 'active' : ''])}>
        <div className={cx(['icon-wrapper'])}>
          <div className={cx(['icon', this._getIconClassName()])} />
        </div>
        <div className={cx(['title'])}>
          Add images to this listing
        </div>
        <div className={cx(['message'])}>
          {Array.from(this._getMessageParts())}
        </div>
        <div className={cx(['link-wrapper'])}>
          <a onClick={this._openChangeImageModal} className={cx(['primary'])}>
            Add Images
          </a>
          <a onClick={this.props.hideZeroState} className={cx(['skip'])}>
            Skip
          </a>
        </div>
      </div>
    );
  }

  _getIconClassName() {
    if (this.props.numberOfN360Images > 0) {
      return 'N360';
    } else {
      return 'blank';
    }
  }

  _getMessageParts() {
    let messageParts = [
      `\
All the images you add to this listing will be available in the digital
tour book, and then you can choose which images you would also like to
display in the PDF tour book.\
`,
    ];
    if (this.props.numberOfN360Images > 0) {
      messageParts = messageParts.concat([
        ` We found ${this.props.numberOfN360Images} image`,
        this.props.numberOfN360Images > 1 ? 's' : undefined,
        ' from ',
        <span className={cx(['company'])}>
          N360
        </span>,
        ' and have already added some to this listing for you.',
      ]);
    }
    return messageParts;
  }

  _openChangeImageModal() {
    this.props.setCurrentModal({
      key: 'change-image',
      path: this.props.path,
      type: 'entry',
    });
    this.props.track('Selects to Add Images', { entryId: this.props.entryId });
    return this.props.hideZeroState();
  }
}
EntryImagesZeroStateInput.initClass();

module.exports = EntryImagesZeroStateInput;
