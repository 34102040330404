const _ = require('lodash');
const { locateServices } = require('./service_locator');

class SuggestedFieldsService {
  constructor() {
    ({ suggestedFields: this.suggestedFields } = locateServices(['suggestedFields']));
  }

  getSuggestedFieldKeys() {
    return _.chain(this.suggestedFields.propertyFields)
      .concat(this.suggestedFields.spaceFields)
      .filter((field) => field.suggested)
      .map((field) => field.newKey || field.key)
      .value();
  }
}

module.exports = SuggestedFieldsService;
