exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 8 : /opt/build/repo/react-app/editor_frontend/src/components/shared/svg_icon/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/shared/svg_icon/index.styl */\n.svg_icon---root---2VMjHTMJNIOjf4kCeCU7u1 {\n  fill: #fff;\n}\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/shared/svg_icon/index.styl */\n\n/* line 7 : /opt/build/repo/react-app/editor_frontend/src/components/shared/svg_icon/index.styl */\n.svg_icon---root---2VMjHTMJNIOjf4kCeCU7u1.svg_icon---inherit-fill---4HJXd-Ydf7c77Zhuu6XAI g,\n.svg_icon---root---2VMjHTMJNIOjf4kCeCU7u1.svg_icon---inherit-fill---4HJXd-Ydf7c77Zhuu6XAI path,\n.svg_icon---root---2VMjHTMJNIOjf4kCeCU7u1.svg_icon---inherit-fill---4HJXd-Ydf7c77Zhuu6XAI polygon,\n.svg_icon---root---2VMjHTMJNIOjf4kCeCU7u1.svg_icon---inherit-fill---4HJXd-Ydf7c77Zhuu6XAI rect {\n  fill: inherit;\n}\n", ""]);

// exports
exports.locals = {
	"root": "svg_icon---root---2VMjHTMJNIOjf4kCeCU7u1",
	"inherit-fill": "svg_icon---inherit-fill---4HJXd-Ydf7c77Zhuu6XAI"
};