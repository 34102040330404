const _ = require('lodash');
const {
  buildFieldPath,
  buildSortableFieldInputs,
  getAddFieldsInput,
  getTypeInput,
} = require('../../shared/input_builders/helpers');

module.exports = function ({ entry, entryId, fieldDefinitions }) {
  const headerInput = {
    label: 'Header',
    path: buildFieldPath({
      entryId,
      fieldKey: 'spaceHeader',
      section: 'header',
    }),
  };
  const section = 'space';
  return _.chain([getTypeInput(entryId)])
    .concat([headerInput])
    .concat(
      buildSortableFieldInputs({ entry, entryId, fieldDefinitions, section })
    )
    .concat([getAddFieldsInput(entryId, section)])
    .value();
};
