const _ = require('lodash');

class ReadyRegistry {
  constructor() {
    this.list = {};
  }

  addListener(callback) {
    this.callback = callback;
    return this._executeCallbackIfReady();
  }

  register(id) {
    if (!id || Object.keys(id).includes(id)) {
      throw new Error(`Provide a unique id when registering a component for ReadyRegistry: ${id} in ${Object.keys(id)}`);
    }
    this.list[id] = false;

    return (isReady) => {
      this.list[id] = isReady;
      return this._executeCallbackIfReady();
    };
  }

  _executeCallbackIfReady() {
    if (_.size(this.list) > 0 && _.every(Object.values(this.list)) && this.callback != null) {
      this.callback();
      return (this.callback = function () {});
    }
  }
}

module.exports = ReadyRegistry;
