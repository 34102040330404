const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const HeaderFields = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentField',
  'setCurrentTab',
]);

const mapStateToProps = function (state) {
  const {
    currentField,
    data: { content },
  } = state.tourbookEditorPage;
  return {
    accentColor: content.settings.accentColor,
    coverFields: content.cover.fields || {},
    title: content.cover.options?.title || {},
    currentField,
  };
};

module.exports = build({
  component: HeaderFields,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: ['fieldFormatterService', 'tourbookFields'],
});
