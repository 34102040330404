const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const DropdownContainer = require('@components/shared/dropdown/container');

class RangeInput extends React.Component {
  constructor(props) {
    super(props);
    this._getAllowedValue = this._getAllowedValue.bind(this);
  }

  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      fieldFormatterService: PropTypes.object.isRequired,
      internalIndex: PropTypes.number,
      options: PropTypes.shape({
        fieldDefinition: PropTypes.object.isRequired,
      }),
      tourbookFieldsService: PropTypes.object.isRequired,
      unit: PropTypes.string,
      updateChild: PropTypes.func.isRequired,
      updateUnit: PropTypes.func.isRequired,
      value: PropTypes.object,
    };
  }

  componentDidMount() {
    if (this.props.active) {
      return this._updateFocus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.active || !this._needsFocusChange(prevProps)) {
      return;
    }
    return this._updateFocus();
  }

  render() {
    if (this.props.active) {
      return (
        <div className={cx(['root'])}>
          {this.props.options.fieldDefinition.currency ? (
            <div className={cx(['currency'])}>$</div>
          ) : undefined}
          <div className={cx(['min'])}>
            {this._renderInput({
              key: 'min',
              placeholder: 'Minimum',
              refName: 'minInput',
            })}
          </div>
          <div className={cx(['divider'])}>-</div>
          <div className={cx(['max'])}>
            {this._renderInput({
              key: 'max',
              placeholder: 'Maximum',
              refName: 'maxInput',
            })}
          </div>
          {this._hasUnit() ? (
            <div className={cx(['units'])}>{this._getUnit()}</div>
          ) : undefined}
        </div>
      );
    } else {
      return <div className={cx(['root'])}>{this._renderValue()}</div>;
    }
  }

  _getAllowedValue(value) {
    return this.props.fieldFormatterService.getAllowedValue({
      fieldDefinition: this.props.options.fieldDefinition,
      value,
    });
  }

  _getUnit() {
    if (this._hasUnitSelect()) {
      return this._renderUnitSelect();
    } else {
      return this.props.options.fieldDefinition.units[0];
    }
  }

  _hasUnit() {
    return this.props.options.fieldDefinition.units;
  }

  _hasUnitSelect() {
    return this.props.options.fieldDefinition.units?.length > 1;
  }

  _needsFocusChange(prevProps) {
    return _.some(
      ['active', 'internalIndex'],
      (key) => prevProps[key] !== this.props[key]
    );
  }
  _renderInput({ key, placeholder, refName }) {
    return (
      <input
        onChange={() =>
          this.props.updateChild(
            key,
            this._getAllowedValue(this[refName].value)
          )
        }
        placeholder={placeholder}
        ref={(node) => {
          return (this[refName] = node);
        }}
        type="text"
        value={this.props.value?.[key] || ''}
      />
    );
  }

  _renderUnitSelect() {
    return (
      <DropdownContainer
        onChange={(value) => this.props.updateUnit(value)}
        options={this.props.options.fieldDefinition.units.map(
          (unit, index) => ({
            label: unit,
            value: unit,
            unit,
          })
        )}
        value={this.props.unit || this.props.options.fieldDefinition.units[0]}
        width="85px"
      />
    );
  }

  _renderValue() {
    return this.props.tourbookFieldsService.getFieldValueAsHtml(
      {
        fieldDefinition: this.props.options.fieldDefinition,
        unit: this.props.unit,
        value: this.props.value,
      },
      { superscriptStyle: { top: '5px' } }
    );
  }

  _updateFocus() {
    if (this.props.internalIndex === 1) {
      return this.maxInput.select();
    } else {
      return this.minInput.select();
    }
  }
}
RangeInput.initClass();

module.exports = RangeInput;
