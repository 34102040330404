const _ = require('lodash');
const url = require('url');
const moment = require('moment');
const { locateService } = require('./service_locator');
const Analytics = require('analytics-node');

class TourbookAnalyticsService {
  static initClass() {
    this.prototype.trackTourbookEvent = async function (
      event,
      options,
      optionalTrackFn
    ) {
      let tourbook;
      if (options.tourbook != null) {
        ({ tourbook } = options);
      } else {
        tourbook = await this.tourbooksService.get(options.tourbookId);
      }
      const nextOptions = {
        id: options.tourbookId,
        content: tourbook.content,
        additionalProperties: _.assign(
          { category: options.category },
          options.additionalProperties
        ),
        isOwner: tourbook.owner_id === options.targetUser,
        timestamps: tourbook.timestamps,
      };
      return this.track(event, nextOptions, optionalTrackFn);
    };
  }

  constructor({ ENV: { segmentConfig }, window }) {
    this.window = window;
    this.analyticsService = locateService('analyticsService');
    this.fieldFormatterService = locateService('fieldFormatterService');
    this.mobileService = locateService('mobileService');
    this.tourbookMetaService = locateService('tourbookMetaService');
    this.tourbooksService = locateService('tourbooksService');
    this.firebaseService = locateService('firebaseService');
    this.analytics = new Analytics(segmentConfig.key);
  }

  getTrackingDetails({ content, entryId, id, isOwner, timestamps }) {
    const contactInfos = _.values(content.cover.contactInfos);
    const data = {
      contact1_type: _.get(contactInfos, ['0', 'type']),
      contact2_type: _.get(contactInfos, ['1', 'type']),
      created_at: timestamps ? moment(timestamps.created_at).toISOString() : '',
      digital_url: this.mobileService.getUrl(id),
      editor_url: this._getUrl(id),
      id,
      isOwner,
      mapOn: content.settings.display.tourMap,
      name: this.tourbookMetaService.deriveNameFromTourbook({ content }),
      numListings: _.size(content.entries),
      summaryOn: content.settings.display.summary,
      title: content.cover.fields?.title || '',
      tour_date: content.cover.fields?.date || '',
    };
    return _.chain(data)
      .mapKeys((value, key) => `tourbook_${key}`)
      .tap((details) => {
        if (entryId) {
          return _.assign(
            details,
            this._getEntryDetails(content.entries[entryId])
          );
        }
      })
      .mapValues(function (value) {
        if (_.isUndefined(value)) {
          return null;
        } else {
          return value;
        }
      })
      .value();
  }

  track(event, options, trackFn) {
    if (trackFn == null) {
      trackFn = (...args) =>
        this.analyticsService.track(...Array.from(args || []));
    }
    const details = _.assign(
      {},
      options.additionalProperties,
      this.getTrackingDetails(options)
    );
    return trackFn(event, details);
  }

  trackTourbookEventOnBackend(event, options) {
    const backendTrackFn = (event, details) => {
      const properties = {};
      for (let key in details) {
        if (properties[key] === null) {
          properties[key] = '';
        } else {
          properties[key] = details[key];
        }
      }
      this.analytics.track({
        userId: options.targetUser,
        event,
        properties,
      });
    };

    return this.trackTourbookEvent(event, options, backendTrackFn);
  }

  _formatAddress(fields) {
    return [
      fields.CorrectedAddress1,
      this.fieldFormatterService.formatCityStateZip(fields),
    ].join(', ');
  }

  _getClientName(content) {
    const contactInfo = _.find(content.cover.contactInfos, ['type', 'client']);
    return contactInfo?.name;
  }

  _getEntryDetails(entry) {
    let original_address;
    const address = this._formatAddress(entry.fields.header);
    if (entry.meta?.originalAddressFields) {
      original_address = this._formatAddress(entry.meta.originalAddressFields);
    }
    const data = {
      address,
      original_address,
      source: entry.meta?.source,
      type: entry.type,
    };
    return _.mapKeys(data, (value, key) => `listing_${key}`);
  }

  _getUrl(id) {
    return url.format({
      hash: `#/tourbook/${id}`,
      host: this.window.location.host,
      pathname: '/',
      protocol: this.window.location.protocol,
    });
  }
}
TourbookAnalyticsService.initClass();

module.exports = TourbookAnalyticsService;
