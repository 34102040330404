const _ = require('lodash');

var OrganizationService = (function () {
  let organizations = undefined;
  OrganizationService = class OrganizationService {
    static initClass() {
      organizations = [
        {
          data: {
            accentColor: null,
            coverImages: [
              {
                cloudinaryPublicId: 'ptp/cover-photo/ColliersCover',
                mediaTitle: 'Colliers-1',
              },
            ],
            logo: null,
          },
          isMember(user) {
            return user.auth0Profile.email.match(/@colliers.com$/);
          },
        },
        {
          data: {
            accentColor: '#000',
            coverImages: [
              {
                cloudinaryPublicId: 'jll-background-1_irkn9x',
                mediaTitle: 'JLL-1',
              },
              {
                cloudinaryPublicId: 'jll-background-2_knjhgn',
                mediaTitle: 'JLL-2',
              },
              {
                cloudinaryPublicId: 'jll-background-3_ngcwzv',
                mediaTitle: 'JLL-3',
              },
            ],
            logo: { cloudinaryPublicId: 'jll-logo-white_aw0qsx' },
          },
          isMember(user) {
            return user.auth0Profile.email.match(/@am\.jll\.com$/);
          },
        },
        {
          data: {
            accentColor: null,
            coverImages: [],
            logo: null,
          },
          isMember() {
            return true;
          },
        },
      ];
    }

    get(user) {
      const organization = _.find(organizations, ({ isMember }) =>
        isMember(user)
      );
      return organization.data;
    }
  };
  OrganizationService.initClass();
  return OrganizationService;
})();

module.exports = OrganizationService;
