const _ = require('lodash');
const { build } = require('@components/container_helpers');
const PdfEntryAmenitiesTemplate = require('./');
const PropTypes = require('prop-types');

const mapStateToProps = function (state, { entryId, pageId }) {
  const { data } = state.tourbookEditorPage;
  const entryPath = ['content', 'entries', entryId];
  const amenitiesPath = entryPath.concat(['pages', 'amenities']);
  return {
    amenities: _.get(data, amenitiesPath),
    entries: data.content.entries,
    entry: _.get(data, entryPath),
    settings: data.content.settings,
    tourbook: data,
  };
};

const propTypes = {
  entryId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

module.exports = build({
  component: PdfEntryAmenitiesTemplate,
  mapStateToProps,
  propTypes,
});
