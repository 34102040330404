const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const EntrySummaryRow = require('./components/entry_summary_row');
const ImageContainer = require('@components/shared/image/container');
const LikesCountContainer = require('@components/shared/likes_count/container');
const {
  fieldKeyToFieldDefinitionKey,
} = require('@components/pages/tourbook_editor_page/multi_space_util');

class EntrySummary extends React.Component {
  static initClass() {
    this.propTypes = {
      accentColor: PropTypes.string.isRequired,
      entry: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      availableFieldDefinitions: PropTypes.object.isRequired,
      fieldFormatterService: PropTypes.object.isRequired,
      isEditing: PropTypes.bool,
      navigateToEntryField: PropTypes.func.isRequired,
      number: PropTypes.number.isRequired,
      setCurrentView: PropTypes.func.isRequired,
      style: PropTypes.object,
      noteFontSize: PropTypes.number,
      emptyFieldReplacements: PropTypes.object,
      summaryFields: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired,
        })
      ).isRequired,
      tourbookId: PropTypes.string.isRequired,
      tourbookEntryFieldsService: PropTypes.object.isRequired,
      tourbookEntryImagesService: PropTypes.object.isRequired,
      tourbookFields: PropTypes.object.isRequired,
      tourbookFieldsService: PropTypes.object.isRequired,
      entryNumberService: PropTypes.object.isRequired,
      firebaseService: PropTypes.object.isRequired,
      summaryService: PropTypes.object.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.state = { summaryImagePersistPath: null };
  }

  async componentDidUpdate(prevProps) {
    const mustRemountComponent = prevProps?.entryId !== this.props.entryId;
    if (!mustRemountComponent) {
      return;
    }

    await this.componentDidMount(prevProps);
  }

  async componentDidMount(prevProps) {
    if (this.props.entryId === prevProps?.entryId) {
      return;
    }

    const persistPath = [
      'content',
      'entries',
      this.props.entryId,
      'imagesShown',
      'summary',
    ];
    const persistValue = await this.props.firebaseService.getValue(
      `tourbooks/${this.props.tourbookId}/${persistPath.join('/')}`
    );
    const sourcePath = [
      'content',
      'entries',
      this.props.entryId,
      'imagesShown',
      0,
    ];
    const sourceValue = await this.props.firebaseService.getValue(
      `tourbooks/${this.props.tourbookId}/${sourcePath.join('/')}`
    );
    const mustUpdateImage =
      persistValue &&
      sourceValue.cloudinaryPublicId !== persistValue.cloudinaryPublicId;
    if (!persistValue || mustUpdateImage) {
      await this.props.firebaseService.setValue(
        `tourbooks/${this.props.tourbookId}/${persistPath.join('/')}`,
        sourceValue
      );
    }

    const summaryImagePersistPath = persistPath;

    this.setState({ summaryImagePersistPath });
  }

  render() {
    const image = this._getSummaryImage();
    return (
      <div
        className={cx([
          'summary-root',
          `${this.props.isEditing ? 'is-editing' : ''}`,
        ])}
      >
        <div className={cx(['summary-image-horizontal'])}>
          {image && ( // wait for the image to be set by the componentDidMount; may 'flicker' the first time--not afterwards though
            <ImageContainer
              croppable={this.props.isEditing}
              image={image}
              imagePath={this.state.summaryImagePersistPath}
              backgroundSize="cover"
              height="100%"
              width="100%"
              readyRegistry={this.props.readyRegistry}
            />
          )}
          {this.props.isEditing && (
            <LikesCountContainer entryId={this.props.entryId} readyRegistry={this.props.readyRegistry} />
          )}
        </div>
        <div className={cx(['summarycontent'])}>
          <div className={cx(['header'])}>
            <div {...this._getIndexProps()}>{this.props.number}</div>
            <div className={cx(['header-text'])}>
              <div {...this._getHeaderNameProps()}>
                {this._getHeaderNameText()}
              </div>
              <div {...this._getHeaderFloorSuiteProps()}>
                {this.props.fieldFormatterService.formatFloorSuite(
                  this.props.entry.fields.header
                )}
              </div>
            </div>
          </div>
          <div className={cx(['fields'])}>
            {this.props.summaryFields.slice(0, 9).reduce((acc, field) => {
              const fieldDefinitionKey = fieldKeyToFieldDefinitionKey(
                field.key
              );
              const fieldDefinition = this.props.availableFieldDefinitions[fieldDefinitionKey];
              const { fieldValue, unit, sectionType } =
                this.props.summaryService.getFieldValueUnitAndSectionType(
                  this.props.entry,
                  field,
                  fieldDefinition
                );
              const cfgobj = {
                style: this.props.style,
                inline: field.key !== 'notes',
                key: `${field.key}-${sectionType}-${field.label}`,
                label: this._getFieldLabel(field),
                unit: this._getFieldUnit(
                  this.props.availableFieldDefinitions[fieldDefinitionKey],
                  unit
                ),
                value: fieldValue,
                emptyFieldReplacement: this.props.emptyFieldReplacements?.[field.key],
              };
              if (fieldValue) {
                cfgobj.onClick = this._getFieldOnClick(field, sectionType);
              }
              return [...acc, <EntrySummaryRow {...cfgobj} />];
            }, [])}
            {this.props.entry.fields.notes?.notes?.secret !== true ? (
              <div className={cx(['notes'])}>
                <div className={cx(['label'])} style={this.props.style}>
                  Notes:
                </div>
                <div
                  style={{
                    ...this.props.style,
                    fontSize:
                      this.props.noteFontSize || this.props.style.fontSize
                        ? `${this.props.noteFontSize || this.props.style.fontSize}px`
                        : undefined,
                  }}
                >
                  {this._renderNotes(this.props.entry)}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    );
  }

  _buildFieldOnClick({ fieldKeys, section, tabKey }) {
    const sectionFields = this.props.entry.fields[section];

    let fieldKey = _.find(fieldKeys, function (key) {
      if (['header', 'address', 'notes'].includes(section)) {
        return sectionFields[key];
      } else {
        return sectionFields[key]?.value;
      }
    });
    if (!fieldKey) {
      fieldKey = fieldKeys[0];
    }
    return () => {
      return this.props.navigateToEntryField({
        entryId: this.props.entryId,
        fieldKey: this._getFieldId({ fieldKey, section }),
        tabKey,
      });
    };
  }

  _getFieldId({ fieldKey, section }) {
    if (fieldKey === 'CorrectedAddress1') {
      return `${this.props.entryId}-address`;
    } else {
      let id = `content-entries-${this.props.entryId}-fields-${section}-${fieldKey}`;
      if (!['header', 'notes'].includes(section)) {
        id += '-value';
      }
      return id;
    }
  }

  _getFieldLabel(field) {
    if (field.key === 'notes') {
      return this.props.entry.fields.header.notesHeader;
    } else {
      return this.props.tourbookFieldsService.getFieldLabel(
        { key: field.key },
        this.props.availableFieldDefinitions
      );
    }
  }

  _getFieldOnClick(field, section) {
    if (this.props.isEditing) {
      let tabKey = section;
      if (['space', 'terms', 'subLease'].includes(tabKey)) {
        tabKey = 'space_or_terms';
      }
      return this._buildFieldOnClick({
        fieldKeys: [field.key],
        section,
        tabKey,
      });
    }
  }

  _getFieldUnit(fieldDefinition, unit) {
    return this.props.tourbookFieldsService.getFieldUnit({
      fieldDefinition,
      unit: unit,
    });
  }

  _hasNotes() {
    return (
      this.props.entry.fields.notes?.notesToggle && this.props.entry.fields.notes?.notes?.content
    );
  }

  _getHeaderFloorSuiteProps() {
    const headerFloorSuiteProps = {
      className: cx(['header-floor-suite']),
      style: { ...this.props.style },
    };
    if (this.props.isEditing) {
      return {
        ...headerFloorSuiteProps,
        onClick: this._buildFieldOnClick({
          fieldKeys: ['floor', 'suite'],
          section: 'header',
          tabKey: 'address',
          display: 'inline-block',
        }),
      };
    } else {
      return headerFloorSuiteProps;
    }
  }

  _getHeaderNameProps() {
    const headerNameProps = {
      className: cx(['header-name']),
      style: { ...this.props.style },
    };
    if (this.props.isEditing) {
      return {
        ...headerNameProps,
        onClick: this._buildFieldOnClick({
          fieldKeys: ['propertyName', 'CorrectedAddress1'],
          section: 'header',
          tabKey: 'address',
        }),
      };
    } else {
      return headerNameProps;
    }
  }

  _getHeaderNameText() {
    const { CorrectedAddress1, propertyName } = this.props.entry.fields.header;
    return propertyName || CorrectedAddress1;
  }

  _getSummaryImage() {
    const summaryImage = this.props.tourbookEntryImagesService.getSummaryImage(
      this.props.entry
    );
    return summaryImage;
  }

  _getIndexProps() {
    const baseProps = {
      className: cx(['header-index']),
      style: {
        borderColor: this.props.accentColor,
      },
    };
    let toReturn = { ...baseProps, style: { ...this.props.style } };
    if (
      !this.props.entryNumberService.shouldDisplayEntryNumber(
        this.props.entry.key, this.props.entry
      )
    ) {
      toReturn.style.display = 'none';
    }
    return toReturn;
  }

  _renderNotes(entry) {
    if (entry.fields.notes && entry.fields.notes.notes?.secret !== true) {
      const { bullets, content } = entry.fields.notes.notes || {};
      const lines = content?.split('\n') || [''];
      return lines.map(function (line, index) {
        let out = '';
        if (bullets) {
          out += '• ';
        }
        out += line;
        return [out, <br key={index} />];
      });
    } else {
      return '';
    }
  }
}

EntrySummary.initClass();

module.exports = EntrySummary;
