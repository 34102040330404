const _ = require('lodash');

const initialState = {
  sharedUsers: undefined,
  tourbook: undefined,
  tourbookId: undefined,
  trackingCategory: undefined,
};

const mapping = {
  setSharedUsers(state, { payload: sharedUsers }) {
    return _.assign({}, state, { sharedUsers });
  },

  set(state, { payload }) {
    return _.assign({}, state, payload);
  },
};

module.exports = { initialState, mapping, namespace: 'collaborateModal' };
