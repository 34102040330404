const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const AddressInput = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(
  actions.tourbookEditorPage,
  'setCurrentModal'
);

const mapStateToProps = function (state, ownProps) {
  const entry =
    state.tourbookEditorPage.data.content.entries[ownProps.options.entryId];
  return { fields: entry.fields.header };
};

const propTypes = {
  options: PropTypes.shape({
    entryId: PropTypes.string.isRequired,
  }).isRequired,
};

module.exports = build({
  component: AddressInput,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
});
