const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const Loading = require('@components/shared/loading');
const PdfContainer = require('../../../pdf/container');
const PropTypes = require('prop-types');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');
const Textarea = require('react-textarea-autosize').default;

class EmailForm extends React.Component {
  static initClass() {
    this.propTypes = {
      goBack: PropTypes.func.isRequired,
      generatePdf: PropTypes.func.isRequired,
      pdfService: PropTypes.object.isRequired,
      resetSendToClientStatus: PropTypes.func.isRequired,
      sendToClient: PropTypes.func.isRequired,
      sendToClientStatus: PropTypes.object,
      tourbook: PropTypes.object.isRequired,
      tourbookMetaService: PropTypes.object.isRequired,
      usersService: PropTypes.object.isRequired,
      userProfile: PropTypes.object.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._onEmailBlur = this._onEmailBlur.bind(this);
    this._onMessageBlur = this._onMessageBlur.bind(this);
    this._onMessageChange = this._onMessageChange.bind(this);
    this._onMessageFocus = this._onMessageFocus.bind(this);
    this._onPdfReady = this._onPdfReady.bind(this);
    this.fileSizeLimitInMb = 40; // 40MB
    this.fileSizeLimit = this.fileSizeLimitInMb * 1024 * 1024;
    this.pdfTitle = this.props.tourbookMetaService.deriveNameFromTourbook(this.props.tourbook);
    this.state = {
      emailMessage: `Here is a link to the digital tourbook for our upcoming tour.\n\nBest,\n${props.userProfile.first_name} ${props.userProfile.last_name}`,
      silentlyGeneratePdf: true,
      pdfStatus: 'Prepping Data (should take <5 seconds)',
    };
  }

  componentWillUnmount() {
    return this.props.resetSendToClientStatus();
  }

  render() {
    return (
      <div>
        {this._renderBlurb()}
        {this._renderForm()}
        <div className={cx(['pdf-wrapper'])}>
          <PdfContainer onReady={this._onPdfReady} />
        </div>
      </div>
    );
  }

  _convertEmailString(emailString) {
    if (emailString.trim().length === 0) {
      return [];
    }
    return _.chain(emailString)
      .split(/[\s;,]+/)
      .map(_.trim)
      .compact()
      .value();
  }

  _getEmailErrorMessage(emails) {
    const invalidEmails = _.reject(emails, this.props.usersService.validateEmail);
    if (emails.length === 1 && invalidEmails.length) {
      return 'Oops! This email address does not appear to be valid.';
    } else if (emails.length > 1 && invalidEmails.length) {
      const invalidEmailStrings = _.chain(invalidEmails)
        .map((email) => `\"${email}\"`)
        .join(', ')
        .value();
      return `\
Oops! The email${invalidEmails.length > 1 ? 's' : ''}
${invalidEmailStrings}
${invalidEmails.length > 1 ? 'do' : 'does'}
not appear to be valid.\
`
        .split('\n')
        .join(' ');
    } else {
      return '';
    }
  }

  _onEmailBlur(event) {
    const emails = this._convertEmailString(event.target.value);
    if (!emails.length) {
      return;
    }
    const message = this._getEmailErrorMessage(emails);
    return this.setState({ emailError: message });
  }

  _onMessageBlur() {
    return this.setState({ messageFocused: false });
  }

  _onMessageChange(event) {
    return this.setState({ emailMessage: event.target.value, messageError: '' });
  }

  _onMessageFocus() {
    return this.setState({ messageFocused: true });
  }

  _onPdfReady(pdfPreviewHtml) {
    return this.setState({ pdfPreviewHtml }, () => this._getPdfSize());
  }

  _renderBlurb() {
    return (
      <div className={cx(['email-blurb'])}>
        <h1>Digital tour book</h1>
        <p>
          {`\
  With a digital tour book, your client can view all the space
  details and photos on their computer or mobile device. Even better,
  your client can take photos and notes while on the tour to view later.\
  `}
        </p>
        <a onClick={this.props.goBack} className={cx(['go-back'])}>
          <FontAwesomeIcon name="chevron-left" />
          <span>Go Back</span>
        </a>
      </div>
    );
  }

  _renderEmailInput() {
    return (
      <div>
        <h2>Send to:</h2>
        <div className={cx(['email-input'])}>
          <FontAwesomeIcon name="envelope" />
          <Textarea
            onBlur={this._onEmailBlur}
            placeholder="Enter email address"
            ref={(node) => {
              return (this.emailInput = node);
            }}
            spellCheck={false}
          />
          <div className={cx(['email-error', 'error'])}>{this.state.emailError}</div>
        </div>
      </div>
    );
  }

  _renderForm() {
    return (
      <div className={cx(['email-form'])}>
        {this._renderEmailInput()}
        {this._renderMessageInput()}
        {this._renderIncludePdfCheckbox()}
        {this._renderPdfStatus()}
        {this._renderSendEmail()}
        {this.props.sendToClientStatus?.fulfilled && (
          <div className={cx(['send-status'])}>
            <FontAwesomeIcon name="check" />
            <span>Your email has been sent!</span>
          </div>
        )}
        {this.props.sendToClientStatus?.rejected && (
          <div className={cx(['send-status'])}>
            <FontAwesomeIcon name="triangle-exclamation" />
            <span>Sorry, but email sending failed</span>
          </div>
        )}
      </div>
    );
  }

  _renderIncludePdfCheckbox() {
    return (
      <>
        {this.state.silentlyGeneratePdf ? (
          <div>
            We are checking the size of your PDF to verify if it can be sent with your email. If you
            don't want to include a PDF, you can send your email now.
          </div>
        ) : this.state.isPdfSmallEnough === undefined ? undefined : this.state.isPdfSmallEnough ? (
          <div className={cx(['include-pdf-checkbox'])}>
            <label>
              <input
                ref={(node) => {
                  return (this.includePdfCheckbox = node);
                }}
                type="checkbox"
              />
              <span>Include PDF tour book in email</span>
            </label>
          </div>
        ) : (
          <div>
            Your PDF is too large to be sent with your email, please{' '}
            <span
              className={cx(['fake-a-tag'])}
              onClick={() => this.props.pdfService.downloadFile(this.pdfTitle, this.state.pdf)}
            >
              download it
            </span>{' '}
            and send it separately
          </div>
        )}
      </>
    );
  }

  _renderPdfStatus() {
    return (
      <>
        {this.state.silentlyGeneratePdf && (
          <div className={cx(['actions'])}>
            <div className={cx(['loading'])}>
              <Loading />
            </div>
            <div className={cx(['loading-message'])}>{this.state.pdfStatus}</div>
          </div>
        )}
      </>
    );
  }

  _renderSendEmail() {
    return (
      <>
        {this.props.sendToClientStatus?.pending && (
          <div className={cx(['actions'])}>
            <div className={cx(['loading'])}>
              <Loading />
            </div>
          </div>
        )}
        <a onClick={this._sendEmail.bind(this)} className={cx(['send-email'])}>
          Send Email
        </a>
      </>
    );
  }

  _renderMessageInput() {
    const user = this.props.userProfile;
    return (
      <div>
        <h2>Message:</h2>
        <div className={cx(['personal-message'])}>
          <textarea
            onBlur={this._onMessageBlur}
            onChange={this._onMessageChange}
            value={this.state.emailMessage}
            onFocus={this._onMessageFocus}
            placeholder={`Here is a link to the digital tourbook for our upcoming tour.\n\nBest,\n${user?.first_name} ${user?.last_name}`}
            ref={(node) => {
              return (this.textInput = node);
            }}
          />
          <div className={cx(['message-error', 'error'])}>{this.state.messageError}</div>
        </div>
      </div>
    );
  }

  async _getPdfSize() {
    if (!this.state.silentlyGeneratePdf) return;

    this.setState({ pdfStatus: 'Creating PDF on server' });
    const docraptorPdfUrl = await this.props.generatePdf(
      this.state.pdfPreviewHtml.replace(/t_ptplisting_/gi, 't_ptpemail_')
    );

    if (!this.state.silentlyGeneratePdf) return;

    this.setState({ pdfStatus: 'Downloading PDF to measure its size' });
    const { fileSize, file } = await this.props.pdfService.getFileAndFileSize(docraptorPdfUrl);

    if (!this.state.silentlyGeneratePdf) return;

    this.setState({
      docraptorPdfUrl,
      pdf: file,
      pdfStatus: '',
      isPdfSmallEnough: fileSize <= this.fileSizeLimit,
      silentlyGeneratePdf: false,
    });
  }

  _sendEmail() {
    const emails = this._convertEmailString(this.emailInput.value);
    const message = this.textInput.value;
    const emailErrorMessage =
      emails.length === 0
        ? 'Please include an email address before sending.'
        : this._getEmailErrorMessage(emails);
    if (emailErrorMessage) {
      return this.setState({ emailError: emailErrorMessage });
    } else if (!message) {
      return this.setState({
        messageError: 'Please include a message to your client before sending.',
      });
    } else if (this.includePdfCheckbox && this.includePdfCheckbox.checked) {
      if (this.state.docraptorPdfUrl) {
        return this.props.sendToClient({
          emails,
          message,
          docraptorPdfUrl: this.state.docraptorPdfUrl,
        });
      }
    } else {
      this.setState({ silentlyGeneratePdf: false });
      return this.props.sendToClient({ emails, message });
    }
  }
}
EmailForm.initClass();

module.exports = EmailForm;
