const { actions, build } = require('@components/container_helpers');
const SettingsView = require('./');

const mapStateToProps = function (state) {
  const { cover, entries, settings } = state.tourbookEditorPage.data.content;
  return {
    cover,
    entryId: Object.keys(entries)[0],
    settings,
  };
};

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'track',
  'update',
]);

module.exports = build({
  component: SettingsView,
  mapDispatchToProps,
  mapStateToProps,
});
