/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const {
  locateService,
  locateServices,
  stubService,
  stubServices,
  resetServices,
  setService,
  setServices,
} = require('./service_locator');
const TestService = require('./test_service');
const TestService2 = require('./test_service2');

describe('di', function () {
  beforeEach(() => resetServices());

  it('allows single service location', () =>
    expect(locateService('testService')).to.be.an.instanceof(TestService));

  it('allows location of actions', function () {
    expect(locateService('testActions').action1).to.exist;
    return expect(locateService('testActions').action2).to.exist;
  });

  it('allows multiple service location', function () {
    const { testService, testService2 } = locateServices([
      'testService',
      'testService2',
    ]);
    expect(testService).to.be.an.instanceof(TestService);
    return expect(testService2).to.be.an.instanceof(TestService2);
  });

  it('has nice error messages in case of missing services', () =>
    expect(() => locateService(['foo'])).to.throw('unregistered service: foo'));

  describe('when stubbing', function () {
    describe('a service', () =>
      it('sets and caches stubbed service', function () {
        // ensure service started as real service
        expect(locateService('testService')).to.be.an.instanceof(TestService);
        let stubbedTestService = stubService('testService');
        expect(Object.keys(stubbedTestService)).to.eql(['method']);
        expect(stubbedTestService.method.isSinonProxy).to.be.true;
        // check that stubbed service was cached for future references
        stubbedTestService = locateService('testService');
        expect(Object.keys(stubbedTestService)).to.eql(['method']);
        return expect(stubbedTestService.method.isSinonProxy).to.be.true;
      }));

    return describe('multiple services', () =>
      it('sets and caches stubbed services', function () {
        // ensure service started as real service
        expect(locateService('testService')).to.be.an.instanceof(TestService);
        expect(locateService('testService2')).to.be.an.instanceof(TestService2);
        const stubs = stubServices(['testService', 'testService2']);
        let { testService, testService2 } = stubs;
        expect(Object.keys(testService)).to.eql(['method']);
        expect(testService.method.isSinonProxy).to.be.true;
        expect(Object.keys(testService2)).to.eql(['method2']);
        expect(testService2.method2.isSinonProxy).to.be.true;
        // check that stubbed service was cached for future references
        ({ testService, testService2 } = locateServices([
          'testService',
          'testService2',
        ]));
        expect(Object.keys(testService)).to.eql(['method']);
        expect(testService.method.isSinonProxy).to.be.true;
        expect(Object.keys(testService2)).to.eql(['method2']);
        return expect(testService2.method2.isSinonProxy).to.be.true;
      }));
  });

  it('allows the setting of a single service instance', function () {
    expect(locateService('testService')).to.be.an.instanceof(TestService);
    setService('testService', { myInstance: true });
    return expect(locateService('testService')).to.eql({ myInstance: true });
  });

  return it('allows the setting of multiple service instances', function () {
    expect(locateService('testService')).to.be.an.instanceof(TestService);
    setServices({
      testService: { name: 'testService', myInstance: true },
      testService2: { name: 'testService2', myInstance: true },
    });
    expect(locateService('testService')).to.eql({
      name: 'testService',
      myInstance: true,
    });
    return expect(locateService('testService2')).to.eql({
      name: 'testService2',
      myInstance: true,
    });
  });
});
