const _ = require('lodash');
const { connect } = require('react-redux');
const actions = require('@store/actions');
const { locateService } = require('@services/service_locator');

const build = function ({
  component,
  displayName,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
  serviceNames,
}) {
  const mergeProps = buildMergeProps(serviceNames);
  const container = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(component);
  container.displayName = displayName || component.name + 'Container';
  container.propTypes = propTypes;
  return container;
};

// service dependency injection
var buildMergeProps = function (serviceNames) {
  if (!serviceNames) {
    return;
  }
  return function (stateProps, dispatchProps, ownProps) {
    const servicesToInject = {};
    _.map(
      serviceNames,
      (name) => (servicesToInject[name] = locateService(name))
    );
    return _.assign({}, ownProps, stateProps, dispatchProps, servicesToInject);
  };
};

module.exports = { actions, build };
