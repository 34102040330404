const _ = require('lodash');
const AddressInputsBuilder = require('./inputs_builders/address_inputs_builder');
const BaseTab = require('../base_tab');
const NotesInputsBuilder = require('./inputs_builders/notes_inputs_builder');
const PdfEntryTemplateContainer = require('../pdf_entry_template/container');
const PropertyInputsBuilder = require('./inputs_builders/property_inputs_builder');
const PropTypes = require('prop-types');
const React = require('react');
const SelectTypeInputsBuilder = require('./inputs_builders/select_type_inputs_builder');
const SpaceInputsBuilder = require('./inputs_builders/space_inputs_builder');
const SpaceSubleaseInputsBuilder = require('./inputs_builders/space_sublease_inputs_builder');
const TabViewContainer = require('../tab_view/container');
const TermsInputsBuilder = require('./inputs_builders/terms_inputs_builder');
const ReadyRegistry = require('@utils/ready_registry');
const RecommendedFieldsSectionContainer = require('@components/pages/tourbook_editor_page/components/recommended_fields_section/container');

class EntryView extends React.Component {
  constructor(props) {
    super(props);
    this._buildPdfTemplateComponent =
      this._buildPdfTemplateComponent.bind(this);
    this._makeRecommendedFieldsSectionFactory =
      this._makeRecommendedFieldsSectionFactory.bind(this);
  }

  static initClass() {
    this.propTypes = {
      currentTab: PropTypes.object.isRequired,
      entry: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      fieldDefinitions: PropTypes.object.isRequired,
      setCurrentTab: PropTypes.func.isRequired,
      setCurrentView: PropTypes.func.isRequired,
      tourbookEntryFieldsService: PropTypes.object.isRequired,
    };
  }

  componentWillMount() {
    if (!this.props.currentTab?.key) {
      return this._initializeTab();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.entryId !== this.props.entryId) {
      return this._initializeTab();
    }
  }

  render() {
    return (
      <TabViewContainer
        completeConfig={{
          label: 'Images',
          style: 'add',
        }}
        onComplete={() => {
          return this.props.setCurrentView({
            entryId: this.props.entryId,
            key: 'entry-images',
          });
        }}
        tabConfigs={this._getTabConfigs()} />
    );
  }

  _buildPdfTemplateComponent() {
    return (
      <PdfEntryTemplateContainer
        entryId={this.props.entryId}
        isEditing={true}
        readyRegistry={new ReadyRegistry()} />
    );
  }

  _makeRecommendedFieldsSectionFactory(section) {
    return () =>
      <RecommendedFieldsSectionContainer options={{ entryId: this.props.entryId, section }} />;
  }

  _getHeaderFieldWithDefault(fieldKey) {
    return (
      this.props.entry.fields.header[fieldKey] ||
      this.props.tourbookEntryFieldsService.headerFields[fieldKey]
    );
  }

  _getInputs(builder) {
    const arg = _.pick(this.props, ['entry', 'entryId', 'fieldDefinitions']);
    arg.hasNotes = this.props.entry.fields.notes?.notesToggle;
    return builder(arg);
  }

  _getSpaceOrTermsConfig() {
    switch (this.props.entry.type) {
      case 'subLease':
        return {
          inputsBuilder: SpaceSubleaseInputsBuilder,
          label: this._getHeaderFieldWithDefault('subLeaseHeader'),
        };
      case 'Lease':
        return {
          inputsBuilder: SpaceInputsBuilder,
          label: this._getHeaderFieldWithDefault('spaceHeader'),
        };
      case 'Sale':
        return {
          inputsBuilder: TermsInputsBuilder,
          label: this._getHeaderFieldWithDefault('termsHeader'),
        };
      default:
        return {
          inputsBuilder: SelectTypeInputsBuilder,
          label: this._getHeaderFieldWithDefault('spaceHeader'),
        };
    }
  }

  _getSpaceOrTermsPostInputsFactory() {
    switch (this.props.entry.type) {
      case 'subLease':
        return this._makeRecommendedFieldsSectionFactory('subLease');
      case 'Lease':
        return this._makeRecommendedFieldsSectionFactory('space');
      case 'Sale':
        return this._makeRecommendedFieldsSectionFactory('terms');
      default:
        return undefined;
    }
  }

  _getTabConfigs() {
    const spaceOrTermsConfig = this._getSpaceOrTermsConfig();
    return [
      {
        component: BaseTab,
        componentProps: {
          inputs: this._getInputs(AddressInputsBuilder),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
        },
        label: 'Address',
        value: 'address',
      },
      {
        component: BaseTab,
        componentProps: {
          inputs: this._getInputs(spaceOrTermsConfig.inputsBuilder),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
          postInputsFactory: this._getSpaceOrTermsPostInputsFactory(),
        },
        label: spaceOrTermsConfig.label,
        value: 'space_or_terms',
      },
      {
        component: BaseTab,
        componentProps: {
          inputs: this._getInputs(PropertyInputsBuilder),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
          postInputsFactory:
            this._makeRecommendedFieldsSectionFactory('property'),
        },
        label: this._getHeaderFieldWithDefault('propertyHeader'),
        value: 'property',
      },
      {
        component: BaseTab,
        componentProps: {
          inputs: this._getInputs(NotesInputsBuilder),
          pdfTemplateComponentFactory: this._buildPdfTemplateComponent,
        },
        label: this._getHeaderFieldWithDefault('notesHeader'),
        value: 'notes',
      },
    ];
  }

  _initializeTab(props) {
    return this.props.setCurrentTab({ key: 'address' });
  }
}
EntryView.initClass();

module.exports = EntryView;
