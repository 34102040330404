const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class ArrowInput extends React.Component {
  render() {
    return (
      <div className={cx(['root', this.props.active ? 'active' : ''])}>
        <FontAwesomeIcon name="chevron-right" />
      </div>
    );
  }
}

module.exports = ArrowInput;
