const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class Hint extends React.Component {
  static initClass() {
    this.propTypes = {
      active: PropTypes.bool.isRequired,
      userShiftEnterNavigation: PropTypes.string,
    };
  }

  render() {
    return (
      <div className={cx(['hint', this.props.active ? 'active' : ''])}>
        <span>
          {'press '}
        </span>
        <span className={cx(['green'])}>
          {this.props.useShiftEnterNavigation ? 'Shift + ' : undefined}
          Enter
        </span>
      </div>
    );
  }
}
Hint.initClass();

module.exports = Hint;
