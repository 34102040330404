const _ = require('lodash');

const getSummaryLabel = function (start, end) {
  let label = 'Listing';
  if (start !== end) {
    label += 's';
  }
  label += ` ${start}`;
  if (start !== end) {
    label += `-${end}`;
  }
  return label;
};

const getInputs = function ({ entriesPerPage, entryCount }) {
  if (entryCount <= entriesPerPage) {
    return [];
  }
  const numButtons = _.ceil(entryCount / entriesPerPage);
  return _.map(_.range(numButtons), function (index) {
    const start = index * entriesPerPage + 1;
    const end = _.min([start + entriesPerPage - 1, entryCount]);
    return {
      disableEnterNavigation: true,
      label: getSummaryLabel(start, end),
      path: [`summary-${start}`],
      type: 'arrow',
    };
  });
};

module.exports = getInputs;
