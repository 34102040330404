const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const { default: Slider } = require('rc-slider');

class SliderInput extends React.Component {
  render() {
    return (
      <>
        <div className={cx(['value'])}>{this.props.value}px</div>
        <Slider className={cx(['slider'])} {...this.props} />
      </>
    );
  }
}

module.exports = SliderInput;
