exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 14 : /opt/build/repo/react-app/editor_frontend/src/components/shared/hint/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 22 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/durations.styl */\n\n/* line 4 : /opt/build/repo/react-app/editor_frontend/src/components/shared/hint/index.styl */\n.hint---hint---2nS8VMKPIekXyXgU13zQgm {\n  font-size: 11px;\n  opacity: 0;\n  transition: opacity 100ms;\n}\n\n/* line 9 : /opt/build/repo/react-app/editor_frontend/src/components/shared/hint/index.styl */\n.hint---hint---2nS8VMKPIekXyXgU13zQgm.hint---active---2AlIzv0XGmLmLV_XHV6ye2 {\n  opacity: 1;\n}\n\n/* line 12 : /opt/build/repo/react-app/editor_frontend/src/components/shared/hint/index.styl */\n.hint---hint---2nS8VMKPIekXyXgU13zQgm .hint---green---2joJcR3vQgEO2Y7jIqy4tK {\n  color: #7c878e;\n  font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"hint": "hint---hint---2nS8VMKPIekXyXgU13zQgm",
	"active": "hint---active---2AlIzv0XGmLmLV_XHV6ye2",
	"green": "hint---green---2joJcR3vQgEO2Y7jIqy4tK"
};