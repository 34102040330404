const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const { locateServices } = require('@services/service_locator');

class EntrySummaryRow extends React.Component {
  static initClass() {
    this.propTypes = {
      inline: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      unit: PropTypes.string,
      value: PropTypes.any.isRequired,
      key: PropTypes.string,
      style: PropTypes.object,
      emptyFieldReplacement: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    this.state = {};
    ({ tourbookFieldsService: this.tourbookFieldsService } = locateServices([
      'tourbookFieldsService',
    ]));
  }

  componentDidMount() {
    return this._resize();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.label !== this.props.label ||
      prevProps.unit !== this.props.unit
    ) {
      return this._resize();
    }
  }

  render() {
    const isFieldValueGoodToDisplay = this.props.value && this.props.value !== '0';
    return (
      <div
        className={cx([
          `root`,
          `${this.props.onClick != null ? 'clickable' : ''}`,
          `${this.props.inline ? 'inline' : ''}`,
        ])}
        onClick={this.props.onClick}
        ref={(node) => {
          return (this.rootDomNode = node);
        }}
      >
        {this.props.label ? (
          <div
            {...this._getLabelProps()}
            ref={(node) => {
              return (this.labelDomNode = node);
            }}
          >
            <span>{this.props.label}:</span>
          </div>
        ) : undefined}
        <div {...this._getValueProps()}>
          {isFieldValueGoodToDisplay ? this.props.value : this.props.emptyFieldReplacement ?? '-'}
          {this.props.unit && isFieldValueGoodToDisplay ? (
            <span {...this._getUnitProps()}> {this._renderUnit()}</span>
          ) : undefined}
        </div>
      </div>
    );
  }

  _renderUnit() {
    return this.tourbookFieldsService.getFieldUnit(
      {
        unit: this.props.unit,
        value: this.props.value,
      },
      { asHtml: true }
    );
  }

  _getLabelProps() {
    return { className: cx(['label']), style: { ...this.props.style } };
  }

  _getValueProps() {
    const valueProps = { className: cx(['value']), style: { ...this.props.style } };
    if (this.props.inline && this.state.valueWidth) {
      valueProps.style.width = this.state.valueWidth;
    }
    return valueProps;
  }

  _getUnitProps() {
    return { className: cx(['unit']), style: { ...this.props.style } };
  }

  _resize() {
    if (!this.props.inline || !this.labelDomNode || !this.rootDomNode) {
      return;
    }
    return this.setState({
      valueWidth:
        this.rootDomNode.offsetWidth - this.labelDomNode.offsetWidth - 1,
    });
  }
}
EntrySummaryRow.initClass();

module.exports = EntrySummaryRow;
