const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class NotesSection extends React.Component {
  static initClass() {
    this.propTypes = {
      currentField: PropTypes.object,
      entryId: PropTypes.string.isRequired,
      isEditing: PropTypes.bool,
      entryKey: PropTypes.string,
      navigateToCurrentEntryField: PropTypes.func.isRequired,
      notes: PropTypes.object,
      notesHeader: PropTypes.string,
      notesToggle: PropTypes.bool,
      parent: PropTypes.object,
      visitedNotesTab: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this._isFieldActive = this._isFieldActive.bind(this);
    this.numColumns = 2;
    this.state = { noteSizeWarning: false };
  }

  componentDidUpdate() {
    return this._checkTextOverflow();
  }

  componentDidMount() {
    return this._checkTextOverflow();
  }

  _isSecret() {
    return this.props.notes?.secret;
  }

  render() {
    return this._isSecret() ? (
      <div />
    ) : (
      <div
        data-warning="warning-border"
        className={cx([
          'notes-section',
          this.state.noteSizeWarning ? 'warning' : '',
        ])}
      >
        {(() => {
          return [
            <div key='warning'>
              {this.state.noteSizeWarning ? (
                <div data-warning="warning-message" className={cx(['notes-size-warning'])}>
                  The notes field contains overflow text! Use an Additional Notes Page
                </div>
              ) : undefined}
            </div>,
            <div {...this._getNotesFieldProps()}>
              <div
                className={cx(['notes-lines-wrapper'])}
                ref={(node) => {
                  return (this.Wrapper = node);
                }}
              >
                <div className={cx(['notes-lines-wrapper-compare'])}>
                  {this._getNotesLines().map((line, index) => {
                    return (
                      <div
                        key={`${index}-${line}`}
                        className={cx([`line`, `${!line ? 'empty' : ''}`])}
                      >
                        {this._hasBullets() ? (
                          <div className={cx(['line-bullet'])}>•</div>
                        ) : undefined}
                        <div className={cx(['line-content'])}>{line}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>,
          ];
        })()}
      </div>
    );
  }

  _checkTextOverflow(props) {
    if (!this.Wrapper) {
      return;
    }

    let doesTextOverflowDownwards;
    if (this._hasBullets()) {
      doesTextOverflowDownwards = this.Wrapper.scrollHeight / this.numColumns > this.props.parent?.clientHeight;
    } else {
      doesTextOverflowDownwards = this.Wrapper.scrollHeight > this.props.parent?.clientHeight;
    }

    let doesTextOverflowToRight;
    const displaysMultipleColumns = this._getNotesLines().length >= 2 && this._getNotesLines()[1].length > 0;
    if (this._hasBullets() && displaysMultipleColumns) {
      doesTextOverflowToRight = this.Wrapper.clientWidth < this.Wrapper.scrollWidth;
    } else {
      doesTextOverflowToRight = this.props.parent?.clientWidth < this.Wrapper.scrollWidth;
    }

    const doesTextOverflow = doesTextOverflowDownwards || doesTextOverflowToRight;
    if (this.state.noteSizeWarning !== doesTextOverflow) {
      this.setState({ noteSizeWarning: doesTextOverflow });
    }
  }

  _buildOnClick(fieldKey) {
    if (!this.props.isEditing) {
      return;
    }
    return () => {
      return this.props.navigateToCurrentEntryField({
        fieldKey: this._getFieldId(fieldKey),
        tabKey: 'notes',
        key: 'entry' + (this.props.entryKey ? `-${this.props.entryKey}` : ''),
      });
    };
  }

  _getFieldId(fieldKey) {
    const section = fieldKey === 'notesHeader' ? 'header' : 'notes';
    return `content-entries-${this.props.entryId}-fields-${section}-${fieldKey}`;
  }

  _getHeaderFieldProps() {
    return {
      className: cx([
        `field`,
        `header`,
        `${this._isFieldActive('notesHeader') ? 'active' : ''}`,
      ]),
      key: 'notesHeader',
      onClick: this._buildOnClick('notesHeader'),
    };
  }

  _getNotesFieldProps() {
    return {
      className: cx([
        `field`,
        `notes`,
        `${this._isFieldActive('notes') ? 'active' : ''}`,
        `${this._hasBullets() ? 'bullets' : ''}`,
      ]),
      key: 'notes',
      onClick: this._buildOnClick('notes'),
    };
  }

  _getNotesLines() {
    return this.props.notes?.content?.split('\n') || [''];
  }

  _hasBullets() {
    return this.props.notes?.bullets;
  }

  _isFieldActive(fieldKey) {
    return (
      this.props.isEditing &&
      this.props.currentField.key === this._getFieldId(fieldKey)
    );
  }
}
NotesSection.initClass();

module.exports = NotesSection;
