/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// coffeelint: disable=max_line_length
require('jsdom-global/register');
const _ = require('lodash');
const { locateService, stubService } = require('@services/service_locator');

describe('SharingService', function () {
  beforeEach(function () {
    this.usersService = stubService('usersService');
    this.tourbookAnalyticsService = stubService('tourbookAnalyticsService');
    this.tourbookAnalyticsService.trackTourbookEvent.resolves();
    this.tourbookAnalyticsService.trackTourbookEventOnBackend.resolves();
    this.firebaseService = locateService('firebaseService');
    this.navigationService = locateService('navigationService');
    this.sharingService = locateService('sharingService');
    this.trackingCategory = 'example-category';
    this.currentUser = 'userId-1';
    this.currentUserEmail = 'currentUser@test.com';
    this.email = 'foo@test.com';
    this.tourbookId = 'tourbookId';
    this.tourbookMeta = {
      ownerID: 'userId-1',
    };
    return (this.userId = 'userId');
  });

  describe('shareTourbook', function () {
    beforeEach(function () {
      return (this.sharingService._shareTourbookWithUserId = sinon
        .stub()
        .resolves());
    });

    describe('when 1 users correspond to the email', function () {
      beforeEach(function () {
        return (this.usersService.getUserIdsByEmail = sinon
          .stub()
          .resolves([this.userId]));
      });

      return it(
        'calls out to _shareTourbookWithUserId',
        async function() {
          await this.sharingService.shareTourbook({
            emailInput: this.email,
            currentUser: this.currentUser,
            currentUserEmail: this.currentUserEmail,
            tourbookMeta: this.tourbookMeta,
            tourbookId: this.tourbookId,
            trackingCategory: this.trackingCategory,
          });
          return expect(
            this.sharingService._shareTourbookWithUserId
          ).to.be.calledWith({
            email: this.email,
            userId: this.userId,
            currentUser: this.currentUser,
            currentUserEmail: this.currentUserEmail,
            tourbookId: this.tourbookId,
            tourbookMeta: this.tourbookMeta,
            trackingCategory: this.trackingCategory,
          });
        }
      );
    });

    describe('when no users correspond to the email', function () {
      beforeEach(function () {
        return (this.usersService.getUserIdsByEmail = sinon
          .stub()
          .resolves(null));
      });

      return it('throws an error', function () {
        return expect(
          this.sharingService.shareTourbook({
            emailInput: this.email,
            currentUser: this.currentUser,
            currentUserEmail: this.currentUserEmail,
            tourbookMeta: this.tourbookMeta,
            tourbookId: this.tourbookId,
            trackingCategory: this.trackingCategory,
          })
        ).to.be.rejectedWith('User Not Found');
      });
    });

    return describe('when multiple uids correspond to the given email', () =>
      it('uses the first uid', async function () {
        this.usersService.getUserIdsByEmail = sinon
          .stub()
          .resolves(['userId-1', 'userId-2']);
        await this.sharingService.shareTourbook({
          emailInput: this.email,
          currentUser: this.currentUser,
          currentUserEmail: this.currentUserEmail,
          tourbookMeta: this.tourbookMeta,
          tourbookId: this.tourbookId,
          trackingCategory: this.trackingCategory,
        });
        return expect(
          this.sharingService._shareTourbookWithUserId
        ).to.be.calledWith({
          email: this.email,
          'userId-1': 'userId-1',
          currentUser: this.currentUser,
          currentUserEmail: this.currentUserEmail,
          tourbookId: this.tourbookId,
          tourbookMeta: this.tourbookMeta,
          trackingCategory: this.trackingCategory,
        });
      }));
  });

  return describe('_shareTourbookWithUserId', function () {
    beforeEach(
      async function() {
        this.usersService.getUserIdsByEmail = sinon
          .stub()
          .resolves([this.userId]);
        return await this.sharingService._shareTourbookWithUserId({
          email: this.email,
          userId: this.userId,
          currentUser: this.currentUser,
          currentUserEmail: this.currentUserEmail,
          tourbookMeta: this.tourbookMeta,
          tourbookId: this.tourbookId,
          trackingCategory: this.trackingCategory,
        });
      }
    );

    it(
      'updates /tourbook/$tourbook-id/shared/$uid',
      async function() {
        const value = await this.firebaseService.getValue(
          `tourbooks/${this.tourbookId}/shared/${this.userId}`
        );
        return expect(value).to.eql({ email: this.email });
      }
    );

    it(
      'updates /userTourbooks/$ownerID/$tourbook-id',
      async function() {
        const isShared = await this.firebaseService.getValue(
          `userTourbooks/${this.tourbookMeta.ownerID}/${this.tourbookId}/shared`
        );
        return expect(isShared).to.be.true;
      }
    );

    it(
      'updates /userSharedTourbooks/$user-id/$tourbook-id',
      async function() {
        const firebaseTourbook = await this.firebaseService.getValue(
          `userSharedTourbooks/${this.userId}/${this.tourbookId}`
        );
        const expectedTourbook = _.assign({}, this.tourbookMeta, {
          shared: true,
        });
        return expect(firebaseTourbook).to.eql(expectedTourbook);
      }
    );

    it('tracks "Shares tour book with collaborator" in mixpanel', function () {
      expect(
        this.tourbookAnalyticsService.trackTourbookEvent.lastCall.args[0]
      ).to.eql('Shares tour book with collaborator');
      return expect(
        this.tourbookAnalyticsService.trackTourbookEvent.lastCall.args[1]
      ).to.eql({
        category: 'example-category',
        targetUser: this.currentUser,
        tourbookId: this.tourbookId,
        additionalProperties: {
          collab_email: this.email,
        },
      });
    });

    return it('tracks "Receives a Shared Tour Book" in mixpanel on the backend', function () {
      expect(
        this.tourbookAnalyticsService.trackTourbookEventOnBackend.lastCall
          .args[0]
      ).to.eql('Receives a Shared Tour Book');
      return expect(
        this.tourbookAnalyticsService.trackTourbookEventOnBackend.lastCall
          .args[1]
      ).to.eql({
        category: 'example-category',
        currentUser: this.currentUser,
        targetUser: this.userId,
        tourbookId: this.tourbookId,
        additionalProperties: {
          shared_by_email: this.currentUserEmail,
        },
      });
    });
  });
});
