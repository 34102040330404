const { locateServices } = require('./service_locator');

class TourbookBuilderService {
  static initClass() {
    this.prototype.buildCopy = async function(id, user) {
      const tourbook = await this.firebaseService.getValue(`tourbooks/${id}`);
      delete tourbook.shared;
      tourbook.content.cover.fields.title += ' (Copy)';
      this._updateOwner(tourbook, user);
      this._updateTimestamps(tourbook);
      return tourbook;
    };

    this.prototype.buildDefault = async function(user) {
      const tourbook = {
        content: {
          cover: this.tourbookFieldsService.getDefaultCover(user),
          settings: await this.tourbookFieldsService.getDefaultSettings(user),
        },
      };
      this._updateOwner(tourbook, user);
      this._updateTimestamps(tourbook);
      return tourbook;
    };
  }

  constructor() {
    ({
      firebaseService: this.firebaseService,
      tourbookFieldsService: this.tourbookFieldsService,
      userTourbooksService: this.userTourbooksService,
    } = locateServices([
      'firebaseService',
      'tourbookFieldsService',
      'userTourbooksService',
    ]));
  }

  _updateOwner(tourbook, user) {
    tourbook.owner_id = user.id;
    tourbook.owner_email = user.auth0Profile.email;
    return (tourbook.owner_picture = user.firebaseProfile.picture || null);
  }

  _updateTimestamps(tourbook) {
    return (tourbook.timestamps = {
      created_at: this.firebaseService.now(),
      updated_at: this.firebaseService.now(),
    });
  }
}
TourbookBuilderService.initClass();

module.exports = TourbookBuilderService;
