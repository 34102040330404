"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Entry = exports.NewEntryData = exports.Query = exports.ParentQueryService = void 0;
const _ = require('lodash');
const { locateServices } = require('@services/service_locator');
const store = require('@store');
const DATA_SOURCE_TYPES = require('@enums/data_source_types');
class Entry {
}
exports.Entry = Entry;
class Query {
}
exports.Query = Query;
class NewEntryData {
    /* NewEntryData object holds the basic information about entry being added.
     * It is also used to propagate information throughout the application */
    constructor(query, source) {
        this.query = query;
        this.source = source;
    }
}
exports.NewEntryData = NewEntryData;
class ParentQueryService {
    constructor() {
        this.dispatch = store.dispatch;
        this._loadActions();
        ({
            amenitiesService: this.amenitiesService,
            entriesByAddressService: this.entriesByAddressService,
            firebaseService: this.firebaseService,
            googleAddressService: this.googleAddressService,
            tourbooksService: this.tourbooksService,
            userTourbooksService: this.userTourbooksService,
        } = locateServices([
            'amenitiesService',
            'entriesByAddressService',
            'firebaseService',
            'googleAddressService',
            'tourbooksService',
            'userTourbooksService',
        ]));
    }
    _loadActions() {
        return __awaiter(this, void 0, void 0, function* () {
            const intervalId = setInterval(() => {
                const actions = require('@store/actions');
                if (actions) {
                    clearInterval(intervalId);
                    ({
                        setCreatingEntryData: this.setCreatingEntryData,
                        setCurrentModal: this.setCurrentModal,
                        setNewEntryData: this.setNewEntryData,
                        track: this.track,
                        trackCreateEntry: this.trackCreateEntry,
                        update: this.update,
                        updateCreatingEntryData: this.updateCreatingEntryData,
                        resetMap: this.resetMap,
                    } = actions.tourbookEditorPage);
                }
            }, 200);
        });
    }
    getProperty(query) {
        return __awaiter(this, void 0, void 0, function* () {
            return new NewEntryData(query, this.source);
        });
    }
    getEntry(newEntryData, additionalInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const { query } = newEntryData;
            const { latitude, longitude } = query;
            const shouldIncludeAmenities = this.userSession.firebaseProfile.tourbookSettings
                ? this.userSession.firebaseProfile.tourbookSettings.amenities
                : true;
            const entry = {
                fields: undefined,
                layout: '1_1_1',
                location: { latitude, longitude },
                order: this._getOrder(),
                pages: {
                    amenities: shouldIncludeAmenities ? yield this._getAmenities(query) : {},
                },
            };
            return entry;
        });
    }
    hasEnoughDataToCreateEntry(rawQuery) {
        return __awaiter(this, void 0, void 0, function* () {
            this._getCurrentTourbookInfo();
            // Convert various rawQuery into a standardized format
            const query = this.standardizeQuery(rawQuery);
            yield this._getCoordinates(query);
            const newEntryData = yield this.getProperty(query);
            newEntryData.userEntries = yield this._findSameEntries(query);
            this._trackAddressSearch(query);
            /* put the search propertyAndSpace into the global state
             *    - keeping the info in case we need to wait for user interaction
             *    - passing the data to NewEntryModal as props
             */
            this.dispatch(this.setNewEntryData(newEntryData));
            const proceedToCreateEntry = true;
            const askUserForMoreInfo = false;
            if (yield this.doesNeedMoreDataFromUserBeforeCreatingEntry(query, newEntryData)) {
                // Showing previously created entries and/or multiple spaces for users to make narrower selection
                return askUserForMoreInfo;
            }
            else {
                return proceedToCreateEntry;
            }
        });
    }
    _getNewEntryData() {
        const state = store.getState();
        const { tourbookEditorPage: { newEntryData }, } = state;
        return newEntryData;
    }
    _getCurrentTourbookInfo() {
        var _a, _b;
        const state = store.getState();
        this.tourbook = state.tourbookEditorPage.data;
        this.tourbookId = state.tourbookEditorPage.tourbookId;
        this.entries = ((_b = (_a = this.tourbook) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.entries) || {};
        this.userSession = state.userSession;
    }
    _trackAddressSearch(query) {
        this.dispatch(this.track(`Searches for a Property Address by ${this.source}`, {
            additionalProperties: { search: query },
        }));
    }
    _displayCreatingEntryModal(userEntryId, userTourbookId, source) {
        return __awaiter(this, void 0, void 0, function* () {
            // Pass the data to the modal & open it
            this.dispatch(this.setCreatingEntryData({
                isFirstEntry: _.size(this.entries) === 0,
                source,
                isDuplicate: !!userEntryId,
            }));
            this.dispatch(this.setCurrentModal({ key: 'creating-entry' }));
        });
    }
    _duplicateEntry(userEntryId, userTourbookId) {
        return __awaiter(this, void 0, void 0, function* () {
            const entry = yield this.tourbooksService.getEntry(userTourbookId, userEntryId);
            _.set(entry, ['meta', 'source'], 'previous tour book');
            return entry;
        });
    }
    createEntry(additionalInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const newEntryData = this._getNewEntryData();
            const { query, source } = newEntryData;
            const { userEntryId, userTourbookId, space } = additionalInfo || {};
            this._displayCreatingEntryModal(userEntryId, userTourbookId, source);
            let entry = userEntryId && userTourbookId
                ? yield this._duplicateEntry(userEntryId, userTourbookId)
                : yield this.getEntry(newEntryData, additionalInfo);
            entry = this._generalUpdate(entry, query);
            const entryId = this.firebaseService.getUniqueId();
            yield this.dispatch(this.update({
                path: ['content', 'entries', entryId],
                value: entry,
            }));
            yield this._performMiscellaneousTasks({
                entryId,
                entry,
                userEntryId,
                space,
                source,
            });
            return { entryId, entry };
        });
    }
    _generalUpdate(entry, query) {
        entry.fields.property.propertyId = query.id;
        entry.pages.images = _.filter(entry.pages.images, (img) => !img.secret); // can only copy non-secret images
        return entry;
    }
    _performMiscellaneousTasks({ entryId, entry, userEntryId, space, source }) {
        return __awaiter(this, void 0, void 0, function* () {
            // Display creating-entry modal
            this.dispatch(this.updateCreatingEntryData({ entry, entryId }));
            // Analytics tracking
            this.dispatch(this.trackCreateEntry({
                entryId,
                source,
                space,
                userEntryId,
            }));
            // Reset the map so the new entry is visible in the map
            const itineraryOff = _.size(this.entries) === 10;
            this.dispatch(this.resetMap(itineraryOff));
            // Update /userTourbooks, /userSharedTourbooks, /tourbooksMeta, etc. in database
            this.userTourbooksService.updateEntryMeta({
                entry,
                entryId,
                tourbook: this.tourbook,
                tourbookId: this.tourbookId,
            });
        });
    }
    _findSameEntries(query) {
        return __awaiter(this, void 0, void 0, function* () {
            const sameEntries = yield this.entriesByAddressService.getAllFromAll({ state: query.state, city: query.city, streetAddress: query.streetAddress }); // e.g. in the same tourbook, in another tourbook by the same people or by others
            return sameEntries;
        });
    }
    doesNeedMoreDataFromUserBeforeCreatingEntry(query, newEntryData) {
        return __awaiter(this, void 0, void 0, function* () {
            const noDuplicates = newEntryData.userEntries.length === 0;
            const proceedToCreateEntry = false;
            const askUserForMoreInfo = true;
            if (noDuplicates) {
                return proceedToCreateEntry;
            }
            else {
                return askUserForMoreInfo;
            }
        });
    }
    _getCoordinates(query) {
        return __awaiter(this, void 0, void 0, function* () {
            const address = `${query.streetAddress} ${query.city} ${query.state} ${query.zip}`;
            let geocoded;
            try {
                geocoded = yield this.googleAddressService.geocode(address);
            }
            catch (e) {
                console.error('Invalid address to get coordinates for: ', address);
                throw e;
            }
            query.latitude = geocoded.geometry.location.lat();
            query.longitude = geocoded.geometry.location.lng();
        });
    }
    _getAmenities(query) {
        return __awaiter(this, void 0, void 0, function* () {
            const locationInfo = {
                fields: {
                    header: {
                        CorrectedAddress1: query.streetAddress,
                        city: query.city,
                        state: query.state,
                        zip: query.zip,
                    },
                },
                location: {
                    latitude: query.latitude,
                    longitude: query.longitude,
                },
            };
            const result = yield this.amenitiesService.getEntryUpdates(locationInfo);
            return result.pages.amenities;
        });
    }
    _getOrder() {
        return (_.max(_.map(this.entries, 'order')) || 0) + 1;
    }
}
exports.ParentQueryService = ParentQueryService;
