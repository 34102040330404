/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const makeLibraryPageActions = require('./library_page_actions');
const { locateService, mockAllServices } = require('@services/service_locator');
const sinon = require('sinon');

describe('libraryPageActions', function () {
  beforeEach(() => mockAllServices());

  return describe('initialize', function () {
    beforeEach(function () {
      this.subscribed = true;
      this.userTourbooksService = locateService('userTourbooksService');
      this.userTourbooksService.subscribeToAllSharedMeta = sinon
        .stub()
        .resolves(() => {
          return (this.subscribed = false);
        });
      this.userTourbooksService.getAllMeta = sinon.stub().resolves();

      this.libraryPageActions = makeLibraryPageActions();
      this.dispatch = sinon.stub();
      return (this.getState = sinon.stub().returns({
        userSession: {},
      }));
    });

    return it(
      'returns an unsubscribe function',
      async function() {
        const unsubscribe = await this.libraryPageActions.initialize()(
          this.dispatch,
          this.getState
        );
        unsubscribe();
        return expect(this.subscribed).to.be.false;
      }
    );
  });
});
