const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const SliderInput = require('./index');

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: (value) => {
      dispatch(
        actions.tourbookEditorPage.update({
          path: ownProps.path,
          value
        })
      );
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: _.get(state.tourbookEditorPage.data, ownProps.path) || 0,
    max: 15,
    min: 8,
  };
};

module.exports = build({
  component: SliderInput,
  mapDispatchToProps,
  mapStateToProps,
});
