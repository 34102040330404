const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class MapStyleInput extends React.Component {
  static initClass() {
    this.prototype._styles = ['streets', 'emerald', 'light', 'high-contrast'];
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <div className={cx(['label'])}>
          Choose one of the below map styles:
        </div>
        <div className={cx(['styles'])}>
          {_.map(this._styles, (style) => {
            return (
              <div {...this._getStyleProps(style)}>
                <div className={cx(['image', style])}>
                  <div className={cx(['overlay'])}>
                    <FontAwesomeIcon name="check" size="2x" />
                  </div>
                </div>
                <div className={cx(['name'])}>
                  {this._getStyleName(style)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  _getStyleName(style) {
    return style.split('-').join(' ');
  }

  _getStyleProps(style) {
    const currentStyle = this.props.value?.split('.')[1];
    const active = currentStyle === style;
    const styleProps = {
      className: cx(['style', active ? 'active' : '']),
      key: style,
    };
    if (!active) {
      styleProps.onClick = () => this.props.update(`googlemaps.${style}`);
    }
    return styleProps;
  }
}
MapStyleInput.initClass();

module.exports = MapStyleInput;
