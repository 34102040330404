const moment = require('moment');

module.exports = {
  amenities: {
    bottom_left: {
      icon: 'fa-briefcase',
      id: 'factual',
      label: 'Your Neighbors',
    },
    bottom_right: {
      icon: 'fa-cutlery',
      id: 'foursquare',
      label: 'Top Spots Around',
    },
    top_left: {
      icon: 'fa-camera-retro',
      id: 'panoramio',
      label: 'Nearby Photos',
    },
    top_right: {
      icon: 'fa-bus',
      id: 'walkscore',
      label: 'Public Transportation',
    },
    mid_left: { icon: 'fa-bus', id: 'googleplaces', label: 'Places Nearby' },
    mid_right: { icon: 'fa-bus', id: 'googlebiz', label: 'Businesses Nearby' },
  },

  blacklistedAdditionalFields: [
    // 'latestRenovation'
    'medianExpenses',
    'propertyNotes',
    'spaceNotes',
    'termNotes',
  ],

  cover: {
    fields: [
      {
        default() {
          return moment().startOf('day').toISOString();
        },
        format: 'MMMM D, YYYY',
        key: 'date',
        label: 'Date',
        type: 'date',
      },
      { default: 'Wayne Enterprises Space Tour', key: 'title', label: 'Title' },
      { default: 'Downtown', key: 'location', label: 'Location 1' },
      { default: 'Gotham, NY', key: 'area', label: 'Location 2' },
    ],

    roleFields: [
      { key: 'header', label: 'Header' },
      { key: 'name', label: 'Full Name' },
      { key: 'title', label: 'Job Title' },
      { key: 'company', label: 'Company Name' },
      { key: 'phone', label: 'Phone' },
      { key: 'email', label: 'Email' },
    ],
  },

  settings: {
    defaults: {
      accentColor: '#E6AE6A',
      amenities: true,
      footer: {
        footerLogo: true,
        footerText: true,
        pageNumbers: true,
      },
      isMapColor: false,
      name: 'Untitled Book',
    },

    footerFields: [
      { key: 'name', label: 'Name' },
      { key: 'phone', label: 'Phone' },
      { key: 'fax', label: 'Fax' },
      { key: 'email', label: 'Email' },
    ],
  },

  summary: {
    Lease: [
      { key: 'smallestAvailableSpace' },
      { key: 'leaseHighPrice' },
      { key: 'term' },
      { key: 'availableDate' },
      { key: 'notes' },
    ],

    Sale: [
      { key: 'availableSize' },
      { key: 'buildingArea' },
      { key: 'salePrice' },
      { key: 'capRate' },
      { key: 'salePricePerSquareFoot' },
      { key: 'notes' },
    ],
  },
};
