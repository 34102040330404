const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const ImageContainer = require('@components/shared/image/container');
const ReadyRegistry = require('@utils/ready_registry');

class ImageInput extends React.Component {
  render() {
    const wrapperProps = {
      className: cx(['wrapper']),
      onClick: () => {
        return this.props.setCurrentModal({
          key: 'change-image',
          path: this.props.path,
          type: 'background',
          entryId: this.props.entryId,
        });
      },
      style: {
        height: this.props.imageOptions?.height,
        width: this.props.imageOptions?.width,
      },
    };
    return (
      <div className={cx(['root'])}>
        <div {...wrapperProps}>
          <div className={cx(['image-wrapper'])}>
            <ImageContainer
              backgroundSize="cover"
              image={this.props.value}
              readyRegistry={new ReadyRegistry()} />
          </div>
          <div className={cx(['link'])}>
            Change Background
          </div>
        </div>
      </div>
    );
  }
}

module.exports = ImageInput;
