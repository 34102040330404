const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const ContactInfoTypeInput = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'createContactInfo',
  'updateContactInfoType',
]);
const mapStateToProps = function (state) {
  const { currentTab, data } = state.tourbookEditorPage;
  return {
    contactInfos: _.get(data, ['content', 'cover', 'contactInfos']),
  };
};

module.exports = build({
  component: ContactInfoTypeInput,
  mapDispatchToProps,
  mapStateToProps,
});
