const { createMemoryHistory } = require('history');
const environmentVariables = require('../environment_variables');
const { buildMockFirebase } = require('./mock_firebase_helpers');
const sinon = require('sinon');

const { firebaseDefaultApp, firebasePropertiesApp } = buildMockFirebase();

const globalExtensions = {
  ENV: environmentVariables.test,
  cancelAnimationFrame() {},
  localStorage: {},
  requestAnimationFrame() {},
};

const firebase = { database: { ServerValue: { TIMESTAMP: Date.now() } } };

const windowExtensions = {
  analytics: {},
  google: {
    maps: {
      DirectionsService() {
        return { route() {} };
      },
      DirectionsTravelMode: {},
      Geocoder() {
        return { geocode() {} };
      },
      GeocoderStatus: { OK: 1 },
      places: {
        AutocompleteService() {
          return { getPlacePredictions() {} };
        },
      },
    },
  },
  location: {
    protocol: 'https',
    host: 'spaceful-test.com',
    port: '8080',
  },
};

module.exports = {
  ENV: environmentVariables.test,
  auth0: {},
  axios: sinon.stub(),
  firebase,
  firebaseDefaultApp,
  firebasePropertiesApp,
  globalExtensions,
  history: createMemoryHistory(),
  window: windowExtensions,
};
