const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const EntryAmenitiesView = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentModal',
  'track',
]);

module.exports = build({
  component: EntryAmenitiesView,
  mapDispatchToProps,
});
