exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 21 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_zero_state_template/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_zero_state_template/index.styl */\n.pdf_zero_state_template---root---9ja3NC4Rg3NDPY5tiEgQu {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-pack: center;\n      justify-content: center;\n  text-align: center;\n}\n\n/* line 10 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_zero_state_template/index.styl */\n.pdf_zero_state_template---line---4H0i6PAHrS9CFWC6R2WrH {\n  border-bottom: 1px solid #c0c2ce;\n  margin-bottom: 20px;\n  width: 50px;\n}\n\n/* line 15 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_zero_state_template/index.styl */\n.pdf_zero_state_template---message---Y8x_AjZrbU9ctAWPDLBhf {\n  font-size: 20px;\n  margin-bottom: 10px;\n  width: 100%;\n}\n\n/* line 20 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/pdf_zero_state_template/index.styl */\n.pdf_zero_state_template---message---Y8x_AjZrbU9ctAWPDLBhf:first-child {\n  font-size: 24px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "pdf_zero_state_template---root---9ja3NC4Rg3NDPY5tiEgQu",
	"line": "pdf_zero_state_template---line---4H0i6PAHrS9CFWC6R2WrH",
	"message": "pdf_zero_state_template---message---Y8x_AjZrbU9ctAWPDLBhf"
};