const _ = require('lodash');
const { actions } = require('@components/container_helpers');
const { locateService } = require('@services/service_locator');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');

class PdfPageWithFooter extends React.Component {
  static initClass() {
    this.propTypes = {
      accentColor: PropTypes.string.isRequired,
      brokerInfos: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string,
          name: PropTypes.string,
          phone: PropTypes.string,
        })
      ).isRequired,
      children: PropTypes.node.isRequired,
      logo: PropTypes.object,
      pageNumber: PropTypes.number,
      readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
      shouldShowPageNumbers: PropTypes.bool.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.cloudinaryService = locateService('cloudinaryService');
    this.pageNumberService = locateService('pageNumberService');
  }

  componentWillMount() {
    if (this.props.pageNumber) {
      this.setComponentReady = this.props.readyRegistry.register(`PdfPageWithFooter-${this.props.pageNumber}`);
    }
  }

  componentDidMount() {
    if (this.setComponentReady) {
      if (!this.props.logo) {
        this.setComponentReady(true);
      } else {
        // To be handled in logo <img>'s onLoad
      }
    }
  }

  componentWillUnmount() {
    if (this.setComponentReady) {
      this.setComponentReady(false);
    }
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <div style={{ backgroundColor: this.props.accentColor }} className={cx(['branded-bar'])} />
        <div className={cx([`page`, `${this._shouldShowFooter() ? 'with-footer' : ''}`])}>
          {this.props.children}
        </div>
        {this._shouldShowFooter() ? (
          <div className={cx(['footer'])}>
            {this.props.logo ? (
              <div onClick={() => this._navigateToSettings()} className={cx(['footer-element'])}>
                <div className={cx(['logo-container'])}>
                  <img
                    src={this._getLogoUrl(this.props.logo)}
                    onLoad={() => {
                      if (this.setComponentReady) {
                        this.setComponentReady(true);
                      }
                    }}
                  />
                </div>
              </div>
            ) : undefined}
            {this.props.brokerInfos != null
              ? this.props.brokerInfos.map((brokerInfo, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => this._navigateToSettings()}
                      className={cx(['footer-element'])}
                    >
                      <div className={cx(['contact-info'])}>
                        <div className={cx(['line1', this._getClassNameForBrokerLine(brokerInfo)])}>
                          {brokerInfo.name}
                        </div>
                        <div className={cx(['line2', this._getClassNameForBrokerLine(brokerInfo)])}>
                          {this._getBrokerLine2(brokerInfo)}
                        </div>
                      </div>
                    </div>
                  );
                })
              : undefined}
            {this.props.shouldShowPageNumbers ? (
              <div onClick={() => this._navigateToSettings()} className={cx(['page-number'])}>
                {this.props.pageNumber}
              </div>
            ) : undefined}
          </div>
        ) : undefined}
      </div>
    );
  }

  _getBrokerLine2(brokerInfo) {
    return _.compact([brokerInfo.email, brokerInfo.phone]).join(' ');
  }

  _getClassNameForBrokerLine(brokerInfo) {
    if (!brokerInfo.name || !this._getBrokerLine2(brokerInfo)) {
      return 'single-line';
    } else {
      return '';
    }
  }

  _getLogoUrl(logo) {
    return this.cloudinaryService.getLogoUrl(logo);
  }

  _shouldShowFooter() {
    return (
      this.props.logo != null ||
      this.props.shouldShowPageNumbers ||
      this.props.brokerInfos.length > 0
    );
  }

  _navigateToSettings() {
    return this.props.dispatch(actions.tourbookEditorPage.setCurrentView({ key: 'settings' }));
  }
}
PdfPageWithFooter.initClass();

module.exports = PdfPageWithFooter;
