const React = require('react');
const PropTypes = require('prop-types');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);

const Itinerary = (props) => {
  const mapLegendCss = () => {
    const { legendTop, legendRight } = props.settings[props.mapOptionsKey] || {};

    const additionalStyles = {};
    if (legendTop) {
      additionalStyles['top'] = legendTop;
    }
    if (legendRight) {
      additionalStyles['right'] = legendRight;
    }
    return additionalStyles;
  };

  const { itineraryTitle } = props.settings[props.mapOptionsKey];
  const borderColorProp = {
    style: { borderColor: props.settings.accentColor },
  };
  return (
    <div
      id="mapLegend"
      cols={props.cols}
      className={cx(['itinerary'])}
      style={mapLegendCss()}
      ref={props.dragRef}
      title="The cursor has to land on the map and outside the original map legend in order to register as a valid drop (in drag and drop)"
    >
      {itineraryTitle ? (
        <div
          {...{
            ...borderColorProp,
            className: cx(['header']),
          }}
        >
          {itineraryTitle}
        </div>
      ) : undefined}
      {props.items.map((item, index) => {
        const markerProps = _.assign({}, borderColorProp, {
          className: cx([`marker`, `${item.label.includes('-') ? 'multi' : ''}`]),
        });
        return (
          <div key={index} className={cx(['stop'])}>
            <div {...markerProps}>{item.label}</div>
            <div className={cx(['info'])}>
              <div className={cx(['name'])}>{item.propertyName}</div>
              <div className={cx(['address'])}>{item.address}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Itinerary.propTypes = {
  settings: PropTypes.shape({
    accentColor: PropTypes.string,
    mapOptions: PropTypes.shape({
      legendTop: PropTypes.string,
      legendRight: PropTypes.string,
    }),
  }),
  mapOptionsKey: PropTypes.string.isRequired,
  cols: PropTypes.number.isRequired,
  dragRef: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      propertyName: PropTypes.string,
      address: PropTypes.string,
    }).isRequired,
  ),
};

module.exports = Itinerary;
