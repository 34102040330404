const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const PdfSummaryTemplateVertical = require('.').default;
const { locateServices } = require('@services/service_locator');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'setCurrentView',
  'navigateToEntryField',
]);

const mapStateToProps = function (state) {
  const availableFieldDefinitions = {
    ...state.tourbookEditorPage.previousFields,
    ...state.tourbookEditorPage.fieldDefinitions,
  };
  const { data, tourbookId } = state.tourbookEditorPage;
  const { summaryService } = locateServices(['summaryService']);
  const {
    content: { settings },
  } = data;
  const summaryFields = summaryService.filterToPopulatedSummaryFields(
    summaryService.getOrderedSummaryFields(settings.summaryFields),
    data,
    availableFieldDefinitions
  );
  return {
    accentColor: settings.accentColor,
    coverFields: data.content.cover.fields || {},
    fontSize: settings.fontSize,
    noteFontSize: settings.summary?.note?.fontSize,
    emptyFieldReplacements: settings.summary?.emptyFieldReplacements,
    tourbook: data,
    availableFieldDefinitions,
    tourbookId,
    summaryFields,
  };
};
module.exports = build({
  component: PdfSummaryTemplateVertical,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames: [
    'entryNumberService',
    'tourbookEntryImagesService',
    'tourbookEntryFieldsService',
    'pageNumberService',
    'tourbookFieldsService',
    'firebaseService',
    'summaryService',
  ],
});
