const _ = require('lodash');

class KeyboardShortcutService {
  constructor({ $, document }) {
    this.shortcuts = {};
    this.reassignments = {};
    $(document).on('keydown', (event) => this._onKeyDown(event));
  }

  overwrite(newShortcuts) {
    this.overrides = _.mapValues(newShortcuts, (callback) => [callback]);
    return () => delete this.overrides;
  }

  reassign(oldKey, newKey) {
    this.reassignments[newKey] = oldKey;
    this.reassignments[oldKey] = 'REASSIGNED';
    return () => {
      delete this.reassignments[newKey];
      return delete this.reassignments[oldKey];
    };
  }

  register(keyToCallbackMapping, param) {
    if (param == null) {
      param = {};
    }
    const { prepend } = param;
    _.each(keyToCallbackMapping, (callback, key) => {
      if (!this.shortcuts[key]) {
        this.shortcuts[key] = [];
      }
      return (this.shortcuts[key] = prepend
        ? _.concat([callback], this.shortcuts[key])
        : _.concat(this.shortcuts[key], [callback]));
    });
    return () => {
      return _.each(keyToCallbackMapping, (callback, key) => {
        return (this.shortcuts[key] = _.without(this.shortcuts[key], callback));
      });
    };
  }

  _getShortcuts(key) {
    const mapping = this.overrides || this.shortcuts;
    key = this.reassignments[key] || key;
    return mapping[key] || [];
  }

  _onKeyDown(event) {
    if (event.originalEvent?.bypassKeyboardShortcutService) {
      return;
    }
    const keys = [
      event.ctrlKey ? 'ctrl' : undefined,
      event.shiftKey ? 'shift' : undefined,
      event.which,
    ];
    const key = _.join(_.filter(keys), '-');
    const shortcuts = this._getShortcuts(key);
    if (!(shortcuts.length > 0)) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    return _.each(shortcuts, (callback) => callback(event));
  }
}

module.exports = KeyboardShortcutService;
