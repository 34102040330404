const PropTypes = require('prop-types');
const React = require('react');

class StreetviewEditor extends React.Component {
  static initClass() {
    this.propTypes = {
      desiredHeight: PropTypes.number.isRequired,
      desiredWidth: PropTypes.number.isRequired,
      entryLatitude: PropTypes.number.isRequired,
      entryLongitude: PropTypes.number.isRequired,
      image: PropTypes.object.isRequired,
    };
  }

  handleLoad() {
    let pos;
    const options = {
      addressControl: true,
      fullscreenControl: true,
      panControl: true,
      pov: this.props.image.pov,
      visible: true,
      zoomControl: true,
    };
    if (this.props.image.pano) {
      options.pano = this.props.image.pano;
    } else if (this.props.image.position) {
      options.position = new google.maps.LatLng(
        this.props.image.position[0],
        this.props.image.position[1]
      );
    }

    if (this.props.image.position) {
      pos = new google.maps.LatLng(
        this.props.image.position[0],
        this.props.image.position[1]
      );
    } else {
      pos = { lat: this.props.entryLatitude, lng: this.props.entryLongitude };
    }

    options.key = 'AIzaSyA2tueI9IkBDjeMmEVVLbrpeICVG1ztS8E';
    this.map = new google.maps.Map(
      this.mapDiv,
      { center: pos, zoom: 14, visible: true },
      { streetViewControl: false }
    );
    this.panorama = new google.maps.StreetViewPanorama(
      this.panoramaDiv,
      options
    );
    this.map.setStreetView(this.panorama);
    return window.removeEventListener('onload', this.handleLoad);
  }

  constructor(props) {
    super(props);
    this.mapDiv = null;
    this.panoramaDiv = null;
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    window.addEventListener('onload', this.handleLoad);
    return setTimeout(this.handleLoad, 10);
  }

  componentWillUnmount() {
    return window.removeEventListener('onload', this.handleLoad);
  }

  getData() {
    return {
      pano: this.panorama.getPano(),
      pov: this.panorama.getPov(),
    };
  }

  render() {
    return (
      <aux>
        <div
          ref={(node) => {
            return (this.mapDiv = node);
          }}
          style={{
            height: 0, //@props.desiredHeight
            width: 0, //@props.desiredWidth
            background_color: '#ffffff',
          }} />
        <div
          ref={(node) => {
            return (this.panoramaDiv = node);
          }}
          style={{
            height: 600, //@props.desiredHeight
            width: 1024, //@props.desiredWidth
            background_color: '#ffffff',
          }} />
      </aux>
    );
  }

  _getPanoramaOptions() {
    let options;
    const { pano, position, pov } = this.props.image;
    return (options = {
      addressControl: false,
      fullscreenControl: false,
      panControl: false,
      pov,
      source: outdoor,
      visible: true,
      zoomControl: false,
    });
  }
}
StreetviewEditor.initClass();

module.exports = StreetviewEditor;
