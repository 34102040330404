const _ = require('lodash');
const ConfirmModal = require('@components/shared/confirm_modal');
const PropTypes = require('prop-types');
const React = require('react');

class DeletePageModal extends React.Component {
  static initClass() {
    this.defaultProps = { onClose: _.noop };

    this.propTypes = {
      deleteEntry: PropTypes.func.isRequired,
      jumpToCoverView: PropTypes.bool,
      onClose: PropTypes.func,
      options: PropTypes.shape({
        entryId: PropTypes.string,
        page: PropTypes.string,
      }),
      entryType: PropTypes.string.isRequired,
      removeAdditionalField: PropTypes.func,
      setCurrentView: PropTypes.func,
    };
  }

  render() {
    const specialConditionForBrokerPage = this.props.entryType === 'broker' ? 'broker page' : '';
    const whatToDelete =
      specialConditionForBrokerPage ||
      (this.props.entryType === 'page' ? 'page' : this.props.entryType);
    return (
      <ConfirmModal
        confirmLabel="Delete"
        message={[`Are you sure you would like to delete this ${whatToDelete}?`]}
        onCancel={this.props.onClose}
        onConfirm={() => {
          switch (this.props.entryType) {
            case 'field':
              this.props.removeAdditionalField(this.props.options.path);
              break;
            case 'broker':
              if (this.props.jumpToCoverView) {
                this.props.setCurrentView({ key: 'cover' });
              }
              this.props.removeAdditionalBrokerPage();
              break;
            case 'page':
              this.props.deleteEntry(this.props.options.entryId);
              if (this.props.jumpToCoverView) {
                this.props.setCurrentView({ key: 'cover' });
              }
              break;
            default:
              console.error('confirmed but had no case speficied; argh!');
              break;
          }
          return this.props.onClose();
        }}
      />
    );
  }
}
DeletePageModal.initClass();

module.exports = DeletePageModal;
