const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const ModalContainer = require('@components/shared/modal/container');

class UpdateEntryAddressModal extends React.Component {
  static initClass() {
    this.propTypes = {
      fields: PropTypes.object.isRequired,
      onClose: PropTypes.func.isRequired,
      options: PropTypes.shape({
        entryId: PropTypes.string.isRequired,
      }).isRequired,
      track: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._onNextField = this._onNextField.bind(this);
    this._onOverwrite = this._onOverwrite.bind(this);
    this._onPreviousField = this._onPreviousField.bind(this);
    this._renderInput = this._renderInput.bind(this);
    this.inputs = {};
    this.state = { currentFieldIndex: 0 };
  }

  componentDidMount() {
    return this._focusActiveField();
  }

  componentDidUpdate() {
    return this._focusActiveField();
  }

  render() {
    const modalProps = {
      keyboardShortcuts: this._getKeyboardShortcuts(),
      onClose: this.props.onClose,
    };
    return (
      <ModalContainer {...modalProps}>
        <div className={cx(['root'])}>
          <div className={cx(['title'])}>
            Caution:
          </div>
          <div className={cx(['message'])}>
            {`\
  If you change the address, it will NOT update the map,
  or any other data that depends on the address.
  To change the location of this listing, delete the listing and start again.\
  `}
          </div>
          <div className={cx(['inputs'])}>
            {this._getInputConfigs().map(this._renderInput)}
          </div>
          <div className={cx(['links'])}>
            <a onClick={this._onOverwrite} className={cx(['overwrite'])}>
              Overwrite
            </a>
            <a onClick={this.props.onClose}>
              Cancel
            </a>
          </div>
        </div>
      </ModalContainer>
    );
  }

  _focusActiveField() {
    const inputConfig = this._getInputConfigs()[this.state.currentFieldIndex];
    const key = inputConfig?.path.join('-');
    return this.inputs[key]?.select();
  }

  _getInputConfigs() {
    const configs = [
      { key: 'CorrectedAddress1', label: 'Address' },
      { key: 'city', label: 'City' },
      { key: 'state', label: 'State' },
      { key: 'zip', label: 'Zip' },
    ];
    return configs.map(({ key, label }) => {
      return {
        label,
        path: [
          'content',
          'entries',
          this.props.options.entryId,
          'fields',
          'header',
          key,
        ],
        value: this.props.fields.header[key],
      };
    });
  }

  _getKeyboardShortcuts() {
    return {
      13: this._onNextField,
      9: this._onNextField,
      'shift-9': this._onPreviousField,
    };
  }

  _onNextField() {
    return this.setState({
      currentFieldIndex: Math.min(3, this.state.currentFieldIndex + 1),
    });
  }

  _onOverwrite() {
    let hasOverwrite = false;
    _.map(this._getInputConfigs(), ({ key, path, value: oldValue }) => {
      const newValue = this.inputs[path.join('-')].value;
      if (oldValue !== newValue) {
        this.props.update({ path, value: newValue });
        return (hasOverwrite = true);
      }
    });
    if (hasOverwrite) {
      this.props.track('Overwrites the Listing Address', {
        entryId: this.props.options.entryId,
      });
    }
    return this.props.onClose();
  }

  _onPreviousField() {
    return this.setState({
      currentFieldIndex: Math.max(0, this.state.currentFieldIndex - 1),
    });
  }

  _renderInput({ label, path, value }, index) {
    const key = path.join('-');
    const inputProps = {
      defaultValue: value || '',
      onBlur: () => this.setState({ currentFieldIndex: null }),
      onFocus: () => this.setState({ currentFieldIndex: index }),
      ref: (node) => {
        return (this.inputs[key] = node);
      },
      type: 'text',
    };
    const rowProps = {
      className: cx(['input-row', `${index === this.state.currentFieldIndex ? 'active' : ''
        }`]),
      key: index,
      onClick: () => this.inputs[key].focus(),
    };
    return (
      <div {...rowProps}>
        <label>
          {`${label}:`}
        </label>
        <input {...inputProps} />
        <div className={cx(['hint'])}>
          <span>
            {'press '}
          </span>
          <span className={cx(['green'])}>
            Enter
          </span>
        </div>
      </div>
    );
  }
}
UpdateEntryAddressModal.initClass();

module.exports = UpdateEntryAddressModal;
