/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const FieldFormatterService = require('./field_formatter_service');

describe('FieldFormatterService', function () {
  beforeEach(function () {
    return (this.service = new FieldFormatterService());
  });

  describe('format', function () {
    const examples = [
      {
        description: 'date (1.0)',
        input: {
          format: 'MMMM D, YYYY',
          type: 'date',
          value: 'January 30, 2017',
        },
        output: 'January 30, 2017',
      },
      {
        description: 'date (2.0)',
        input: {
          format: 'MMMM D, YYYY',
          type: 'date',
          value: '2017-01-30T08:00:00.000Z',
        },
        output: 'January 30, 2017',
      },
    ];

    return examples.forEach(({ description, input, output }) =>
      it(description, function () {
        const result = this.service.format(input.value, {
          format: input.format,
          type: input.type,
        });
        return expect(result).to.eql(output);
      })
    );
  });

  describe('formatCityStateZip', function () {
    const examples = [
      {
        description: 'full data',
        input: { city: 'My City', state: 'My State', zip: '12345' },
        output: 'My City, My State 12345',
      },
      {
        description: 'no zip',
        input: { city: 'My City', state: 'My State' },
        output: 'My City, My State',
      },
      {
        description: 'no state',
        input: { city: 'My City', zip: '12345' },
        output: 'My City 12345',
      },
      {
        description: 'no city',
        input: { state: 'My State', zip: '12345' },
        output: 'My State 12345',
      },
      {
        description: 'only city',
        input: { city: 'My City' },
        output: 'My City',
      },
      {
        description: 'only state',
        input: { state: 'My State' },
        output: 'My State',
      },
      {
        description: 'only zip',
        input: { zip: '12345' },
        output: '12345',
      },
    ];

    return examples.forEach(({ description, input, output }) =>
      it(description, function () {
        const result = this.service.formatCityStateZip(input);
        return expect(result).to.eql(output);
      })
    );
  });

  describe('formatFloorSuite', function () {
    const examples = [
      {
        description: 'full data',
        input: { floor: '1', suite: '100' },
        output: 'Floor 1, Suite 100',
      },
      {
        description: 'only floor',
        input: { floor: '1' },
        output: 'Floor 1',
      },
      {
        description: 'only suite',
        input: { suite: '100' },
        output: 'Suite 100',
      },
    ];

    return examples.forEach(({ description, input, output }) =>
      it(description, function () {
        const result = this.service.formatFloorSuite(input);
        return expect(result).to.eql(output);
      })
    );
  });

  return describe('getAllowedValue', function () {
    describe('decimal field', function () {
      beforeEach(function () {
        return (this.getDecimalAllowedValue = (value) => {
          return this.service.getAllowedValue({
            fieldDefinition: { decimal: true },
            value,
          });
        });
      });

      it('returns empty string for non-numbers', function () {
        return expect(this.getDecimalAllowedValue('abc')).to.eql('');
      });

      it('returns numbers without fractional parts as is', function () {
        return expect(this.getDecimalAllowedValue('12')).to.eql('12');
      });

      it('returns numbers with fractional parts as is', function () {
        return expect(this.getDecimalAllowedValue('12.34')).to.eql('12.34');
      });

      return it('removes disallowed character in middle of numbers', function () {
        return expect(this.getDecimalAllowedValue('12,345.67')).to.eql(
          '12345.67'
        );
      });
    });

    describe('integer field', function () {
      beforeEach(function () {
        return (this.getIntegerAllowedValue = (value) => {
          return this.service.getAllowedValue({
            fieldDefinition: { integer: true },
            value,
          });
        });
      });

      it('returns empty string for non-numbers', function () {
        return expect(this.getIntegerAllowedValue('abc')).to.eql('');
      });

      it('returns numbers as is', function () {
        return expect(this.getIntegerAllowedValue('12')).to.eql('12');
      });

      return it('removes disallowed character in middle of numbers', function () {
        return expect(this.getIntegerAllowedValue('12,345')).to.eql('12345');
      });
    });

    return describe('other field', () =>
      it('allows all values', function () {
        return expect(this.service.getAllowedValue({ value: 'abc' })).to.eql(
          'abc'
        );
      }));
  });
});
