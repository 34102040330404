const _ = require('lodash');
const importedStyles = require('./index.styl');
const tourbookCardClassNames = require('./tourbook_card.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const React = require('react');
const PropTypes = require('prop-types');
const TourbookCard = require('./tourbook_card');
const { build } = require('@components/container_helpers');
const { CollaborateModal } = require('@components/shared/collaborate_modal');
const ConfirmModal = require('@components/shared/confirm_modal');
const { TransitionGroup, CSSTransition } = require('react-transition-group');
const mapStateToProps = (state) => ({
  currentModal: state.libraryPage.currentModal,
  currentModalProps: state.libraryPage.currentModalProps,
  currentTab: state.libraryPage.currentTab,
  ownedTourbooks: state.libraryPage.ownedTourbooks,
  sharedWithMeTourbooks: state.libraryPage.sharedWithMeTourbooks,
  userSession: state.userSession,
});
const NewTourbookCard = require('./new_tourbook_card');
const { locateService } = require('@services/service_locator');
const { useNavigate } = require('react-router-dom');

class LibraryPageInner extends React.Component {
  static initClass() {
    this.propTypes = {
      currentTab: PropTypes.oneOf(['owned', 'shared']),
      currentModal: PropTypes.string,
      currentModalProps: PropTypes.object,
      dispatch: PropTypes.func,
      ownedTourbooks: PropTypes.object,
      navigate: PropTypes.func.isRequired,
      sharedWithMeTourbooks: PropTypes.object,
      userSession: PropTypes.object,    
    };

    this.prototype.componentWillMount = async function () {
      this.isNewUser = false;
      this.teardown = await this.props.dispatch(
        this.libraryPageActions.initialize()
      );
    };
  }

  constructor(props) {
    super(props);
    this.libraryPageActions = locateService('libraryPageActions');
  }

  componentWillUnmount() {
    return typeof this.teardown === 'function' ? this.teardown() : undefined;
  }

  render() {
    return (
      <div className={cx(['root'])}>
        <div className={cx(['tabs'])}>
          <a {...this._getTabProps('owned')}>My Tour Books</a>
          <a {...this._getTabProps('shared')}>Shared With Me</a>
        </div>
        {(() => {
          switch (this.props.currentTab) {
            case 'owned':
              return (
                <OwnedTourbooks
                  tourbooks={this._getSortedTourbooks(
                    this.props.ownedTourbooks
                  )}
                />
              );
            case 'shared':
              return (
                <SharedTourbooks
                  createNewTourbook={() => this._createNewTourbook()}
                  tourbooks={this._getSortedTourbooks(
                    this.props.sharedWithMeTourbooks
                  )}
                />
              );
          }
        })()}
        {this.renderModal()}
      </div>
    );
  }

  renderModal() {
    const onClose = () =>
      this.props.dispatch(this.libraryPageActions.setCurrentModal({}));
    switch (this.props.currentModal) {
      case 'collaborate':
        return <CollaborateModal onClose={onClose} />;
      case 'confirmation':
        return (
          <ConfirmModal
            message={[this.props.currentModalProps.message]}
            onCancel={onClose}
            confirmLabel={this.props.currentModalProps.confirmLabel}
            onConfirm={() => {
              this.props.currentModalProps.onConfirm();
              return onClose();
            }}
          />
        );
    }
  }

  _getSortedTourbooks(tourbooks) {
    return _.chain(tourbooks)
      .map((tourbook, tourbookId) => ({
        tourbook,
        tourbookId,
      }))
      .sortBy('tourbookId')
      .reverse()
      .value();
  }

  _getTabProps(tab) {
    return {
      className: cx([
        `tab`,
        `${this.props.currentTab === tab ? 'active' : ''}`,
      ]),
      onClick: () => this.props.dispatch(this.libraryPageActions.setTab(tab)),
    };
  }

  _createNewTourbook() {
    return this.props
      .dispatch(this.libraryPageActions.createNewTourbook())
      .then((tourbook) => {
        return this.props.navigate(`/tourbook/${tourbook.id}`);
      });
  }
}
LibraryPageInner.initClass();

const _tourbookPropTypes = {
  tourbooks: PropTypes.arrayOf(
    PropTypes.shape({
      tourbook: PropTypes.object.isRequired,
      tourbookId: PropTypes.string,
    })
  ),
};

class OwnedTourbooks extends React.Component {
  constructor(props) {
    super(props);
    this.addFauxTourbook = this.addFauxTourbook.bind(this);
  }

  static initClass() {
    this.propTypes = _tourbookPropTypes;
  }

  componentWillMount() {
    this.setState({ tourbooks: this.props.tourbooks });
    return (this.shouldTransition = false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tourbooks != null) {
      this.setState({ tourbooks: nextProps.tourbooks });
    }
    const currentLength = Object.keys(this.props.tourbooks).length;
    const nextLength = Object.keys(nextProps.tourbooks).length;
    const difference = Math.abs(currentLength - nextLength);
    if (difference <= 1) {
      return (this.shouldTransition = true);
    } else {
      return (this.shouldTransition = false);
    }
  }

  render() {
    const transitionGroupProps = {
      className: cx(['owned-tourbooks']),
      enter: this.shouldTransition ? true : false,
    };
    return (
      <TransitionGroup {...transitionGroupProps}>
        <NewTourbookCard addFauxTourbook={this.addFauxTourbook} />,
        {_.map(this.state.tourbooks, ({ tourbook, tourbookId }, i) => {
          return this.wrapInCSSTransition(
            <TourbookCard
              mode="owned"
              tourbook={tourbook}
              tourbookId={tourbookId} />
          );
        })}
      </TransitionGroup>
    );
  }

  wrapInCSSTransition(element) {
    const transitionProps = {
      className: cx([
        `${tourbookCardClassNames['tourbook-card-exit'] ? 'exit' : ''}`,
        `${tourbookCardClassNames['tourbook-card-active'] ? 'exitActive' : ''}`,
        `${tourbookCardClassNames['tourbook-card-enter'] ? 'enter' : ''}`,
        `${tourbookCardClassNames['tourbook-card-enter-active']
          ? 'enterActive'
          : ''
        } `,
      ]),
      timeout: ENV.delays.tourbookCardTransition,
      key: element.props.tourbookId,
    };
    return <CSSTransition {...transitionProps}>{element}</CSSTransition>;
  }

  addFauxTourbook(newTourbook) {
    newTourbook.tourbook.coverPhotoID = 'ptp/cover-photo/FlatironCOLOR';
    newTourbook.tourbook.name = 'Wayne Enterprises Space Tour';
    const fauxTourbook = {
      tourbookId: newTourbook.id,
      tourbook: newTourbook.tourbook,
    };
    const { tourbooks } = this.state;
    tourbooks.unshift(fauxTourbook);
    return this.setState(tourbooks);
  }
}
OwnedTourbooks.initClass();

class SharedTourbooks extends React.Component {
  static initClass() {
    this.propTypes = _.assign({}, _tourbookPropTypes, {
      createNewTourbook: PropTypes.func.isRequired,
    });
  }

  render() {
    if (Object.keys(this.props.tourbooks).length > 0) {
      return (
        <div>
          {_.map(this.props.tourbooks, ({ tourbook, tourbookId }, i) => {
            return (
              <TourbookCard
                key={tourbookId}
                mode="sharedWith"
                tourbook={tourbook}
                tourbookId={tourbookId}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={cx(['no-shared'])}>
          <div className={cx(['logo'])} />
          <div className={cx(['no-shared-title'])}>
            No tour books have been shared with you yet.
          </div>
          <div className={cx(['no-shared-subtitle-container'])}>
            <span className={cx(['no-shared-subtitle'])}>
              {'In the meantime, '}
            </span>
            <a
              onClick={this.props.createNewTourbook}
              className={cx(['no-shared-subtitle'])}
            >
              create your own tour book
            </a>
            <span className={cx(['no-shared-subtitle'])}>
              {' and share it with your team!'}
            </span>
          </div>
        </div>
      );
    }
  }
}
SharedTourbooks.initClass();

const LibraryPage = build({
  component: (props) => {
    const navigate = useNavigate();
    return <LibraryPageInner {...props} navigate={navigate} />;
  },
  displayName: 'LibraryPage',
  mapStateToProps,
});

module.exports = {
  LibraryPage,
  OwnedTourbooks,
  SharedTourbooks,
};
