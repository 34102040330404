const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const {
  SortableContainer,
  SortableElement,
  SortableHandle,
} = require('react-sortable-hoc');
const React = require('react');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

class DragHandle extends React.Component {
  render() {
    return (
      <div className={cx(['reorder-handle'])}>
        <FontAwesomeIcon name="grip-vertical" />
      </div>
    );
  }
}

class SortableItem extends React.Component {
  render() {
    return (
      <div>
        {this.props.renderInputRow(
          this.props.item,
          <DragHandleHoc />
        )}
      </div>
    );
  }
}

class SortableList extends React.Component {
  render() {
    return (
      <div>
        {this.props.items.map((item, index) => {
          return (
            <SortableItemHoc
              index={index}
              item={item}
              key={index}
              renderInputRow={this.props.renderInputRow} />
          );
        })}
      </div>
    );
  }
}

var DragHandleHoc = SortableHandle(DragHandle);
var SortableItemHoc = SortableElement(SortableItem);
const SortableListHoc = SortableContainer(SortableList, { withRef: true });

module.exports = SortableListHoc;
