const _ = require('lodash');
const md5 = require('blueimp-md5');
const Promise = require('bluebird');

class GoogleAddressService {
  static initClass() {
    this.prototype._cityNormalizations = {
      'Mt.': 'Mount',
      'St.': 'Saint',
    };
  }

  constructor({ window }) {
    this.window = window;
  }

  geocode(address) {
    if (!this.geocoder) {
      this.geocoder = new this.window.google.maps.Geocoder();
    }
    return new Promise((resolve, reject) => {
      return this.geocoder.geocode({ address }, (results, status) => {
        if (status === this.window.google.maps.GeocoderStatus.OK) {
          return resolve(results[0]);
        } else {
          return reject(`Geocode unsuccessful: ${status}`);
        }
      });
    });
  }

  _getAddressParts(googleAddress) {
    const componentMapping = this._createComponentMapping(googleAddress);
    let address = _.filter([
      componentMapping['street_number']?.short_name,
      componentMapping['route']?.short_name,
    ]).join(' ');
    if (address === '') {
      address = componentMapping['premise']?.short_name;
    }
    let city = componentMapping['locality']?.long_name || componentMapping['political']?.long_name;
    _.map(
      this._cityNormalizations,
      (to, from) => (city = _.replace(city, from, to))
    );
    return {
      address,
      city,
      state: componentMapping['administrative_area_level_1']?.short_name,
      zip: componentMapping['postal_code']?.short_name,
    };
  }

  getMainStreetviewLocation(address) {
    if (!this.directionsService) {
      this.directionsService = new this.window.google.maps.DirectionsService();
    }
    const request = {
      destination: address,
      origin: address,
      travelMode: this.window.google.maps.DirectionsTravelMode.DRIVING,
    };
    return new Promise((resolve, reject) => {
      return this.directionsService.route(request, (res, status) => {
        if (status === this.window.google.maps.DirectionsStatus.OK) {
          return resolve(res.routes[0].legs[0].start_location);
        } else {
          return reject(`Directions unsuccessful: ${status}`);
        }
      });
    });
  }

  getProperty(googleAddress) {
    const parts = this._getAddressParts(googleAddress);
    return _.assign(
      { CorrectedAddress1: parts.address },
      parts,
      this._getLocation(googleAddress),
      { googlePlace: googleAddress },
    );
  }

  _createComponentMapping(googleAddress) {
    const componentMapping = {};
    for (let component of Array.from(googleAddress.address_components)) {
      if (component.types) {
        componentMapping[component.types[0]] = component;
      }
    }
    return componentMapping;
  }

  _getLocation(googleAddress) {
    const { location } = googleAddress.geometry;
    return {
      latitude:
        (typeof location.lat === 'function' ? location.lat() : undefined) ||
        location.lat,
      longitude:
        (typeof location.lng === 'function' ? location.lng() : undefined) ||
        location.lng,
    };
  }
}
GoogleAddressService.initClass();

module.exports = GoogleAddressService;
