const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const NotesSection = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'navigateToCurrentEntryField',
]);

const mapStateToProps = function (state, ownProps) {
  const { currentField, data } = state.tourbookEditorPage;
  const entry = data.content.entries[ownProps.entryId];
  const { notes, notesToggle } = entry.fields.notes || {};
  const { notesHeader } = entry.fields.header;
  return {
    currentField,
    entryKey: entry.key,
    notes,
    notesHeader,
    notesToggle,
    visitedNotesTab: entry.visitedTabs?.notes,
  };
};

const propTypes = {
  entryId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

module.exports = build({
  component: NotesSection,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
});
