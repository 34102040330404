const importedStyles = require('../../index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');
const store = require('@store');
const _ = require('lodash');
const {
  isMultiSpace,
  fieldKeyToFieldDefinitionKey,
  generateMultiSpace,
  columnsToDisplayForMultiSpaceSummaryTable,
} = require('@components/pages/tourbook_editor_page/multi_space_util');

class DataSection extends React.Component {
  constructor(props) {
    super(props);
    this._isFieldActive = this._isFieldActive.bind(this);
  }

  static initClass() {
    this.propTypes = {
      currentField: PropTypes.object,
      entry: PropTypes.object.isRequired,
      entryId: PropTypes.string.isRequired,
      fieldDefinitions: PropTypes.object.isRequired,
      isEditing: PropTypes.bool,
      navigateToCurrentEntryField: PropTypes.func.isRequired,
      section: PropTypes.string.isRequired,
      style: PropTypes.object,
      tourbookEntryFieldsService: PropTypes.object.isRequired,
      tourbookFieldsService: PropTypes.object.isRequired,
    };
  }

  render() {
    const state = store.getState();
    const space =
      state.tourbookEditorPage.data.content.entries[this.props.entryId].fields
        .space;
    const uniqueSpaces = this._getUniqueSpaces();
    return (
      <div className={cx(['fields'])}>
        <div
          {...this._getFieldProps(
            { key: `${this.props.section}Header` },
            this._getFields().length,
            ['field', 'header']
          )}
        >
          {this.props.entry.fields.header[`${this.props.section}Header`]}
        </div>
        {uniqueSpaces.length > 0
          ? this._renderUniqueSpaces(space, uniqueSpaces)
          : this._renderEachField()}
      </div>
    );
  }

  _buildOnClick(field) {
    if (!this.props.isEditing) {
      return;
    }
    return () => {
      const tabKey = ['space', 'terms', 'subLease'].includes(this.props.section)
        ? 'space_or_terms'
        : this.props.section;
      return this.props.navigateToCurrentEntryField({
        fieldKey: this._getFieldId(field),
        tabKey,
      });
    };
  }

  _getFieldFormattedValue(field) {
    const fieldDefinitionKey = fieldKeyToFieldDefinitionKey(field.key);
    if (field.isUrl) {
      return this.props.tourbookFieldsService.getFieldValueAsLink({
        anchorText:
          field.anchorText || // if anchorText isn't supplied, use the configured value (e.g. google.com will produce a link to google.com with anchortext of google.com)
          this.props.entry?.fields?.[this.props.section]?.[field.key]?.value,
        value: this.props.entry?.fields?.[this.props.section]?.[field.key]?.value,
      });
    }
    return this.props.tourbookFieldsService.getFieldValueAsString({
      fieldDefinition: this.props.fieldDefinitions[fieldDefinitionKey],
      value: this.props.entry?.fields?.[this.props.section]?.[field.key]?.value,
    });
  }

  _getFieldId(field) {
    const prefix = `content-entries-${this.props.entryId}-fields`;
    if (field.key === `${this.props.section}Header`) {
      return `${prefix}-header-${field.key}`;
    } else {
      return `${prefix}-${this.props.section}-${field.key}-value`;
    }
  }

  _getFieldLabel(field) {
    return this.props.tourbookFieldsService.getFieldLabel(
      field,
      this.props.fieldDefinitions
    );
  }

  _getFieldProps(field, index, classNames) {
    return {
      className: cx([
        ...classNames,
        field.key,
        this._isFieldActive(field) ? 'active' : '',
      ]),
      key: index,
      onClick: this._buildOnClick(field),
    };
  }

  _getFieldUnit(field) {
    const fieldDefinitionKey = fieldKeyToFieldDefinitionKey(field.key);
    return this.props.tourbookFieldsService.getFieldUnit(
      {
        fieldDefinition: this.props.fieldDefinitions[fieldDefinitionKey],
        unit: field.unit,
      },
      { asHtml: true }
    );
  }

  _getFields() {
    return this.props.tourbookEntryFieldsService.getFieldsToDisplay({
      entry: this.props.entry,
      section: this.props.section,
    });
  }

  _isFieldActive(field) {
    return (
      this.props.isEditing &&
      this.props.currentField.key === this._getFieldId(field)
    );
  }

  _shouldRenderField(field) {
    return this._getFieldFormattedValue(field) || this._isFieldActive(field);
  }

  _getUniqueSpaces() {
    const uniqueSpaces = [];
    for (const field of this._getFields()) {
      if (isMultiSpace(field.key) && field.key.includes('-floor')) {
        const spaceKey = generateMultiSpace({ key: field.key }).spaceKey;
        if (!uniqueSpaces.map((s) => s.spaceKey).some((k) => k === spaceKey)) {
          uniqueSpaces.push({ spaceKey, floor: field.value });
        }
      }
    }
    uniqueSpaces.sort((s1, s2) => s1.floor - s2.floor);
    return uniqueSpaces.map((s) => s.spaceKey);
  }

  _renderUniqueSpaces(space, uniqueSpaces) {
    return (
      <>
        <div className={cx(['multi-space-row'])}>
          {columnsToDisplayForMultiSpaceSummaryTable.map(({ key, label }) => {
            const fieldDefinitionKey = fieldKeyToFieldDefinitionKey(key);
            const defaultLabel =
              this.props.fieldDefinitions[fieldDefinitionKey]?.label;
            label = defaultLabel ? defaultLabel : label;

            return (
              <div
                className={cx(['multi-space-cell'])}
                key={key}
                style={this.props.lineSpacing}
              >
                <div className={cx(['field-label'])}>
                  <span style={this.props.style}>{label}</span>
                </div>
                {this._getFieldUnit({ key: fieldDefinitionKey }) ? (
                  <span
                    className={cx(['field-unit'])}
                    style={this.props.style}
                  >
                    {` (`}
                    {this._getFieldUnit({ key: fieldDefinitionKey })}
                    {`)`}
                  </span>
                ) : undefined}
              </div>
            );
          })}
        </div>
        {uniqueSpaces.map((spaceKey, index) => {
          return (
            <div className={cx(['multi-space-row'])}>
              {columnsToDisplayForMultiSpaceSummaryTable.map(({ key }) => {
                const multiSpaceParts = generateMultiSpace({
                  spaceKey,
                  fieldDefinitionKey: key,
                });
                const field = {
                  ...space[multiSpaceParts.key],
                  key: multiSpaceParts.key,
                  fieldDefinitionKey: multiSpaceParts.fieldDefinitionKey,
                };
                return (
                  <div
                    onClick={this._buildOnClick(field)}
                    style={this.props.lineSpacing}
                  >
                    <div
                      className={cx(['multi-space-cell'])}
                      style={this.props.style}
                    >
                      <div>{this._getFieldFormattedValue(field) || '-'}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  }

  _renderEachField() {
    return this._getFields().map((field, index) => {
      if (this._shouldRenderField(field)) {
        return (
          <div
            {...this._getFieldProps(field, index, ['field'])}
            style={this.props.lineSpacing}
          >
            <div className={cx(['field-label'])} style={this.props.style}>
              <span>{this._getFieldLabel(field)}</span>
              {this._getFieldUnit(field) ? (
                <span
                  className={cx(['field-unit'])}
                  style={this.props.style}
                >
                    {` (`}{this._getFieldUnit(field)}{`)`}
                  
                </span>
              ) : undefined}
              <span>:</span>
            </div>
            <div className={cx(['field-value'])} style={this.props.style}>
              {this._getFieldFormattedValue(field)}
            </div>
          </div>
        );
      }
    });
  }
}
DataSection.initClass();

module.exports = DataSection;
