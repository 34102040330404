const { compose, applyMiddleware, createStore } = require('redux');
const { default: reduxThunk } = require('redux-thunk');

const checkWindowType = typeof window === 'object';
const checkExtWinExists = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ != null;
const checks = checkWindowType && checkExtWinExists;

const composeEnhancers = checks
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
  : compose;

const middlewares = [reduxThunk];
if (process.env.NODE_ENV === 'local' || 'development') {
  const { createLogger } = require('redux-logger');
  middlewares.push(createLogger({ collapsed: true, timestamp: false }));
}

module.exports = function (...args) {
  const obj = args[0],
    val = obj.initialState,
    initialState = val != null ? val : {},
    { reducer } = obj;
  if (process.env.NODE_ENV === 'local' || 'development') {
    return createStore(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(...Array.from(middlewares || [])))
    );
  } else {
    return createStore(
      reducer,
      initialState,
      applyMiddleware(...Array.from(middlewares || []))
    );
  }
};
