const _ = require('lodash');
const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    this._onEdit = this._onEdit.bind(this);
  }

  static initClass() {
    this.propTypes = {
      fields: PropTypes.shape({
        CorrectedAddress1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
      }).isRequired,
      options: PropTypes.shape({
        entryId: PropTypes.string.isRequired,
      }).isRequired,
      setCurrentModal: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <div className={cx(['root', this.props.active ? 'active' : ''])}>
        <div className={cx(['top-row'])}>
          <label>
            Address:
          </label>
          <a onClick={this._onEdit}>
            Edit
          </a>
        </div>
        <div className={cx(['value'])}>
          {this._getValue()}
        </div>
      </div>
    );
  }

  _getValue(type) {
    return _.chain(['CorrectedAddress1', 'city', 'state', 'zip'])
      .map((key) => this.props.fields[key])
      .join(', ')
      .value();
  }

  _onEdit() {
    return this.props.setCurrentModal({
      entryId: this.props.options.entryId,
      key: 'update-entry-address',
    });
  }
}
AddressInput.initClass();

module.exports = AddressInput;
