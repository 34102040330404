exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 5 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/toggle_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 103 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/helpers.styl */\n\n/* line 103 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/helpers.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 22 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/durations.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/toggle_input/index.styl */\n.toggle_input---message---yWfXHKgGQTfKyq13ASEU5 {\n  color: #f00;\n  font-size: 11px;\n  text-align: right;\n}\n", ""]);

// exports
exports.locals = {
	"message": "toggle_input---message---yWfXHKgGQTfKyq13ASEU5"
};