exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 8 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/arrow_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/arrow_input/index.styl */\n.arrow_input---root---3Hc7LrEUnlIOBY3e-N8FSE.arrow_input---active---Mdni3HkrL4lDoiuEUS_J1 i {\n  color: #006bb6;\n}\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/arrow_input/index.styl */\n.arrow_input---root---3Hc7LrEUnlIOBY3e-N8FSE i {\n  color: #999;\n  margin-top: 6px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "arrow_input---root---3Hc7LrEUnlIOBY3e-N8FSE",
	"active": "arrow_input---active---Mdni3HkrL4lDoiuEUS_J1"
};