const _ = require('lodash');
const { build } = require('@components/container_helpers');
const EmptyFieldReplacements = require('.');
const { locateService } = require('@services/service_locator');

const mapStateToProps = function (state) {
  const entries = state.tourbookEditorPage.data.content.entries;
  const fieldDefinitions = state.tourbookEditorPage.fieldDefinitions;
  const summaryService = locateService('summaryService');

  const generateOptionList = function () {
    const filteredEntries = summaryService.filterToSummaryEntries(entries);

    const fieldsPopulatedByBroker = summaryService.getFieldsPopulatedByBroker(
      filteredEntries,
      fieldDefinitions
    );
    const optionList = fieldsPopulatedByBroker
      .map((f) => ({
        ...f,
        path: ['content', 'settings', 'summary', 'emptyFieldReplacements', f.key],
      }))
      .sort((a, b) => a.order - b.order);
    return optionList;
  };
  const optionList = generateOptionList();

  return {
    optionList,
  };
};

module.exports = build({
  component: EmptyFieldReplacements,
  mapStateToProps,
});
