const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const additionalNoteSection = require('./');
const PropTypes = require('prop-types');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'navigateToCurrentEntryImagesField',
]);

const mapStateToProps = function (state, ownProps) {
  let additionalNote;
  const { currentField, data } = state.tourbookEditorPage;
  const entry = data.content.entries[ownProps.entryId];
  const page = entry.pages.images[ownProps.pageId] || {};
  if (page != null) {
    additionalNote = page;
    additionalNote.additionalNote = page.content;
  }
  const { additionalNoteHeader } = page;
  return {
    currentField,
    additionalNote,
    additionalNoteHeader,
    visitedAdditionalNoteTab: entry.visitedTabs?.additionalNote,
  };
};

const propTypes = {
  entryId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

module.exports = build({
  component: additionalNoteSection,
  mapDispatchToProps,
  mapStateToProps,
  propTypes,
});
