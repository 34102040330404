const _ = require('lodash');
const Promise = require('bluebird');
const { locateServices } = require('./service_locator');

class TourbooksService {
  static initClass() {
    this.prototype.copy = async function(id, user) {
      const tourbook = await this.tourbookBuilderService.buildCopy(id, user);
      const result = await this._saveNew(tourbook);    
      return result;
    };

    this.prototype.create = async function(user) {
      const tourbook = await this.tourbookBuilderService.buildDefault(user);
      return await this._saveNew(tourbook);
    };

    this.prototype.delete = async function(id) {
      const tourbook = await this.get(id);
      await this.userTourbooksService.deleteMeta(id, tourbook);
      await this.firebaseService.removeValue(`tourbooks/${id}`, tourbook);
    };

    this.prototype.getIsPublished = async function(id) {
      const owner = await this.getOwner(id);
      const sharedUsers = await this.getSharedUserIds(id);
      const tourbookUsers = [owner].concat(sharedUsers);
      return await Promise.reduce(
        tourbookUsers,
        async function(previouslyPublished, tourbookUser) {
          const publishEventsPath = `userTourbooksPublished/${tourbookUser}/${id}`;
          const publishEvents = await this.firebaseService.getValue(
            publishEventsPath
          );
          return previouslyPublished || publishEvents !== null;
        }.bind(this),
        false
      );
    };

    this.prototype.getSharedUserIds = async function(id) {
      return _.keys(
        await this.firebaseService.getValue(`tourbooks/${id}/shared`)
      );
    };

    this.prototype.leaveSharedTourbook = async function(
      {
        tourbookId,
        userId,
      }
    ) {
      const sharedUsers = await this.getSharedUserIds(tourbookId);
      const ownerId = await this.getOwner(tourbookId);
      return this.firebaseService.updateValues('/', {
        [`tourbooks/${tourbookId}/shared/${userId}`]: null,
        [`userSharedTourbooks/${userId}/${tourbookId}`]: null,
        [`userTourbooks/${ownerId}/${tourbookId}/shared`]:
          sharedUsers.length > 1,
      });
    };

    this.prototype._saveNew = async function(tourbook) {
      const id = this.firebaseService.getUniqueId();
      await this.firebaseService.setValue(`tourbooks/${id}`, tourbook);
      await this.userTourbooksService.updateMeta(id, tourbook);
      return { id, tourbook };
    };
  }

  constructor() {
    ({
      firebaseService: this.firebaseService,
      tourbookBuilderService: this.tourbookBuilderService,
      userTourbooksService: this.userTourbooksService,
    } = locateServices([
      'tourbookBuilderService',
      'firebaseService',
      'userTourbooksService',
    ]));
  }

  async copySample(user) {
    const tourbook = await this.tourbookBuilderService.buildCopy(
      'sample',
      user
    );
    return await this._saveNew(tourbook);
  }

  get(id) {
    return this.firebaseService.getValue(`tourbooks/${id}`);
  }

  getCover(id) {
    return this.firebaseService.getValue(`tourbooks/${id}/content/cover`);
  }

  getEntry(id, entryId) {
    return this.firebaseService.getValue(
      `tourbooks/${id}/content/entries/${entryId}`
    );
  }

  getOwner(id) {
    return this.firebaseService.getValue(`tourbooks/${id}/owner_id`);
  }

  getSettings(id) {
    return this.firebaseService.getValue(`tourbooks/${id}/settings`);
  }

  getSharedUsers(id) {
    return this.firebaseService.getValue(`tourbooks/${id}/shared`);
  }

  reorderEntries(id, entryIds) {
    const updates = {};
    entryIds.forEach((entryId, index) => (updates[`${entryId}/order`] = index));
    return this.firebaseService.updateValues(
      `tourbooks/${id}/content/entries`,
      updates
    );
  }

  reorderEntryFields({ entryId, fieldKeys, section, tourbookId }) {
    const updates = {};
    fieldKeys.forEach(
      (fieldKey, index) => (updates[`${fieldKey}/order`] = index)
    );
    const path = `tourbooks/${tourbookId}/content/entries/${entryId}/fields/${section}`;
    return this.firebaseService.updateValues(path, updates);
  }

  subscribeToUpdates(id, fn) {
    return this.firebaseService.subscribeToUpdates(`tourbooks/${id}`, fn);
  }

  trackSentTo(id, email) {
    return this.firebaseService.pushValue(`analytics/${id}/sentTo`, {
      email,
      time: this.firebaseService.now(),
    });
  }

  updateAtPath(id, path, value) {
    if (!path) {
      throw new Error('missing parameter path');
    }
    const fullPath = ['tourbooks', id].concat(path).join('/');
    return this.firebaseService.setValue(fullPath, value);
  }
  
  deleteAtPath(id, path) {
    if (!path) {
      throw new Error('missing parameter path');
    }
    const fullPath = ['tourbooks', id].concat(path).join('/');
    return this.firebaseService.removeValue(fullPath);
  }

  getLikes(tourbookId, entryId = '') {
    return this.firebaseService.getValue(`digitalTourbookEntryLikes/${tourbookId+ (entryId ? '/' + entryId : '')}`);
  }
  
  getDisLikes(tourbookId, entryId = '') {
    return this.firebaseService.getValue(`digitalTourbookEntryDisLikes/${tourbookId + (entryId ? '/' + entryId : '')}`);
  }
}
TourbooksService.initClass();

module.exports = TourbooksService;
