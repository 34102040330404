const _ = require('lodash');
const { actions, build } = require('@components/container_helpers');
const SendToClientModal = require('./');

const mapDispatchToProps = _.pick(actions.tourbookEditorPage, [
  'recordTourbookPublish',
  'setCurrentModal',
  'track',
]);

const mapStateToProps = (state, ownProps) => ({
  tourbookId: state.tourbookEditorPage.tourbookId,
});

const serviceNames = ['mobileService'];

module.exports = build({
  component: SendToClientModal,
  mapDispatchToProps,
  mapStateToProps,
  serviceNames,
});
