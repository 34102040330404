/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const OrganizationService = require('./organization_service');

describe('OrganizationService', function () {
  beforeEach(function () {
    this.user = { auth0Profile: {} };
    return (this.service = new OrganizationService());
  });

  return describe('get', function () {
    describe('user is in the Colliers organization', function () {
      beforeEach(function () {
        return (this.user.auth0Profile.email = 'john@colliers.com');
      });

      return it('returns the Colliers organization data', function () {
        return expect(this.service.get(this.user)).to.eql({
          accentColor: null,
          coverImages: [
            {
              cloudinaryPublicId: 'ptp/cover-photo/ColliersCover',
              mediaTitle: 'Colliers-1',
            },
          ],
          logo: null,
        });
      });
    });

    describe('user is in the JLL organization', function () {
      beforeEach(function () {
        return (this.user.auth0Profile.email = 'john@am.jll.com');
      });

      return it('returns the JLL organization data', function () {
        return expect(this.service.get(this.user)).to.eql({
          accentColor: '#000',
          coverImages: [
            {
              cloudinaryPublicId: 'jll-background-1_irkn9x',
              mediaTitle: 'JLL-1',
            },
            {
              cloudinaryPublicId: 'jll-background-2_knjhgn',
              mediaTitle: 'JLL-2',
            },
            {
              cloudinaryPublicId: 'jll-background-3_ngcwzv',
              mediaTitle: 'JLL-3',
            },
          ],
          logo: {
            cloudinaryPublicId: 'jll-logo-white_aw0qsx',
          },
        });
      });
    });

    return describe('user is not in any organization', function () {
      beforeEach(function () {
        return (this.user.auth0Profile.email = 'john@doe.com');
      });

      return it('returns empty organization data', function () {
        return expect(this.service.get(this.user)).to.eql({
          accentColor: null,
          coverImages: [],
          logo: null,
        });
      });
    });
  });
});
