const { actions, build } = require('@components/container_helpers');
const PdfBrokersTemplate = require('./');

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ..._.pick(actions.tourbookEditorPage, ['update']),
});

const mapStateToProps = function (state) {
  let { cover, broker } = state.tourbookEditorPage.data.content;
  return {
    contactInfos: cover.contactInfos,
    brokerInfos: broker?.brokerInfos,
    options: cover.options || {},
  };
};

module.exports = build({
  component: PdfBrokersTemplate,
  mapDispatchToProps,
  mapStateToProps,
});
