const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const EmailForm = require('./components/email_form/container');
const ModalContainer = require('@components/shared/modal/container');
const PropTypes = require('prop-types');
const React = require('react');

class SendToClientModal extends React.Component {
  static initClass() {
    this.propTypes = {
      mobileService: PropTypes.object.isRequired,
      onClose: PropTypes.func.isRequired,
      recordTourbookPublish: PropTypes.func.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      tourbookId: PropTypes.string.isRequired,
      track: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this._downloadPdf = this._downloadPdf.bind(this);
    this._goToDefault = this._goToDefault.bind(this);
    this._goToEmail = this._goToEmail.bind(this);
    this._previewPdf = this._previewPdf.bind(this);
    this.state = { screen: 'default' };
  }

  render() {
    return (
      <ModalContainer onClose={this.props.onClose} verticallyCentered={true}>
        <div className={cx(['root'])}>
          {(() => {
            if (this.state.screen === 'default') {
              return this._renderDefault();
            } else if (this.state.screen === 'email') {
              return <EmailForm goBack={this._goToDefault} />;
            }
          })()}
        </div>
      </ModalContainer>
    );
  }

  _downloadPdf() {
    return this.props.setCurrentModal({ key: 'download-pdf' });
  }

  _getPreviewDigitalAnchorProps() {
    return {
      className: cx(['preview']),
      href: this.props.mobileService.getUrl(this.props.tourbookId),
      onClick: () => {
        return this.props.recordTourbookPublish('digitalPreview');
      },
      target: '_blank',
    };
  }

  _goToDefault() {
    return this.setState({ screen: 'default' });
  }

  _goToEmail() {
    this.props.track('Starts Email to Client');
    return this.setState({ screen: 'email' });
  }

  _previewPdf() {
    this.props.track('Previews PDF');
    return this.props.setCurrentModal({ key: 'pdf-preview' });
  }

  _renderDefault() {
    return (
      <div>
        <div className={cx(['digital'])}>
          <div className={cx(['content'])}>
            <h1>
              Digital tour book
            </h1>
            <p>
              {`\
  With a digital tour book, your client can view all the
  space details and photos on their computer or mobile device.
  Even better, your client can take their own photos and notes
  while on the tour to view later.\
  `}
            </p>
            <a className={cx(['email-to-client'])} onClick={this._goToEmail}>
              Email To Client
            </a>
            <a {...this._getPreviewDigitalAnchorProps()}>
              Preview Digital
            </a>
          </div>
          <div className={cx(['image'])} />
        </div>
        <div className={cx(['divider'])} />
        <div className={cx(['pdf'])}>
          <div className={cx(['image'])} />
          <div className={cx(['content'])}>
            <h1>
              PDF tour book
            </h1>
            <p>
              {`\
  Download a PDF tour book to print out or
  send to your client as an email attachment.\
  `}
            </p>
            <a className={cx(['download'])} onClick={this._downloadPdf}>
              Download PDF
            </a>
            <a className={cx(['preview'])} onClick={this._previewPdf}>
              Preview PDF
            </a>
          </div>
        </div>
      </div>
    );
  }
}
SendToClientModal.initClass();

module.exports = SendToClientModal;
