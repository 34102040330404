const _ = require('lodash');
const { locateService } = require('./service_locator');

class TourbookEntryImagesService {
  static initClass() {
    this.prototype.getDefaultStreetviewSettings = async function (entry) {
      const {
        CorrectedAddress1: address,
        city,
        state,
        zip,
      } = entry.fields.header;
      const fullAddress = [address, city, state, zip].join(' ');
      const position =
        await this.googleAddressService.getMainStreetviewLocation(fullAddress);
      return {
        position: [position.lat(), position.lng()],
        pov: { heading: 1, pitch: 0, zoom: 1 },
      };
    };

    this.prototype.getDigitalTourbookEntryImages = async function (
      entryId,
      tourbookId
    ) {
      const digitalTourbookEntryImages = await this.firebaseService.getValue(
        `digitalTourbookEntryImages/${tourbookId}/${entryId}`
      );
      if (!digitalTourbookEntryImages) {
        return undefined;
      }
      const keys = Object.keys(digitalTourbookEntryImages)
        .sort()
        .filter((key) => key !== 'sample');
      if (keys.length === 0) {
        return undefined;
      }
      _.forEach(
        digitalTourbookEntryImages,
        (image, key, digitalTourbookEntryImages) =>
          (digitalTourbookEntryImages[key].type = 'image')
      );
      return digitalTourbookEntryImages;
    };
  }

  constructor() {
    this.googleAddressService = locateService('googleAddressService');
    this.cloudinaryService = locateService('cloudinaryService');
    this.firebaseService = locateService('firebaseService');
  }

  getDeleteImagePaths(entry, imageId) {
    const imagesWithPaths = this._getImagesOnPDFWithPaths(entry, {
      respectLayout: false,
    });
    const imageToDelete = entry.images[imageId];
    return _.chain(imagesWithPaths)
      .filter(({ image }) =>
        this.cloudinaryService.equals(image, imageToDelete)
      )
      .map(({ imagePath }) => imagePath)
      .concat([['images', imageId]])
      .value();
  }

  getImagesInPDF(entry) {
    const imagesWithPaths = this._getImagesOnPDFWithPaths(entry, {
      respectLayout: true,
    });
    return imagesWithPaths.map(({ image }) => image);
  }

  getSummaryImage(entry) {
    const haveSummaryImage =
      entry && entry['imagesShown'] && entry['imagesShown']['summary'];
    if (!haveSummaryImage) {
      return;
    }
    return entry['imagesShown']['summary'];
  }
  _getAmenitiesPageImagesPath(entry) {
    return _.chain([
      'top_left',
      'top_right',
      'bottom_left',
      'bottom_right',
      'mid_left',
    ])
      .filter(
        (slot) => entry.pages?.amenities?.[slot]?.type === 'image'
      )
      .map((slot) => ['pages', 'amenities', 'imagesShown', slot])
      .value();
  }

  _getExtraPageImagesCount({ layout, type }) {
    switch (type) {
      case 'floor_plan':
        return 1;
      case 'image':
        return _.split(layout, '_').length;
      default:
        return 0;
    }
  }

  _getExtraPagesImagesPaths(entry, respectLayout) {
    return _.chain(entry.pages?.images)
      .map(({ imagesShown, layout, type }, pageId) => {
        const count = respectLayout
          ? this._getExtraPageImagesCount({ layout, type })
          : 4;
        return _.times(count, (index) => [
          'pages',
          'images',
          pageId,
          'imagesShown',
          index,
        ]);
      })
      .flatten()
      .value();
  }

  _getClientUploadedImagesCount({ layout, type }) {
    switch (type) {
      case 'floor_plan':
        return 1;
      case 'image':
        return _.split(layout, '_').length;
      default:
        return 0;
    }
  }

  // Returns [{imagePath, image}, ...] of the images used on the PDF
  // If respectLayout is true, filters out the images not shown on the PDF
  _getImagesOnPDFWithPaths(entry, { respectLayout }) {
    const imagePaths = _.concat(
      this._getMainPageImagesPaths(entry, respectLayout),
      this._getExtraPagesImagesPaths(entry, respectLayout),
      this._getAmenitiesPageImagesPath(entry)
    );
    return _.chain(imagePaths)
      .map((imagePath) => ({
        image: _.get(entry, imagePath),
        imagePath,
      }))
      .filter(
        ({ image }) => image?.type === 'image'
      )
      .value();
  }

  _getMainPageImagesCount(layout) {
    if (layout == null) {
      layout = '1_2';
    }
    return _.split(layout, '_').length;
  }

  _getMainPageImagesPaths(entry, respectLayout) {
    const count = respectLayout
      ? this._getMainPageImagesCount(entry.layout)
      : 3;
    return _.times(count, (index) => ['imagesShown', index]);
  }

  _updateEntryImages(entryImageObject, tourbookId, entryId) {
    this.firebaseService.setValue(
      `tourbooks/${tourbookId}/content/entries/${entryId}/images`,
      entryImageObject
    );
  }
}
TourbookEntryImagesService.initClass();

module.exports = TourbookEntryImagesService;
