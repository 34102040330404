exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 10 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/base_tab/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/sizes.styl */\n\n/* line 26 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/sizes.styl */\n\n/* line 17 : /opt/build/repo/react-app/editor_frontend/src/global_styles/pdf-template-wrapper.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/pdf-template-wrapper.styl */\n\n/* line 5 : /opt/build/repo/react-app/editor_frontend/src/global_styles/pdf-template-wrapper.styl */\n.base_tab---root---m74g5kao5MXsxUA-ojOYU {\n  height: 100%;\n  overflow: auto;\n  padding: 20px;\n}\n\n/* line 10 : /opt/build/repo/react-app/editor_frontend/src/global_styles/pdf-template-wrapper.styl */\n.base_tab---pdf-template-wrapper---3Pp6mg71skjnOlbNa0phHE {\n  box-sizing: border-box;\n  display: inline-block;\n  padding-left: 20px;\n  position: fixed;\n  right: 20px;\n  width: calc((100% - 295px) * .6);\n  z-index: 1;\n}\n\n/* line 6 : /opt/build/repo/react-app/editor_frontend/src/components/pages/tourbook_editor_page/components/base_tab/index.styl */\n.base_tab---input-set-wrapper---2azQJCaEE4tEeTHAeViZ9l {\n  box-sizing: border-box;\n  display: inline-block;\n  width: 40%;\n  z-index: 2;\n}\n", ""]);

// exports
exports.locals = {
	"root": "base_tab---root---m74g5kao5MXsxUA-ojOYU",
	"pdf-template-wrapper": "base_tab---pdf-template-wrapper---3Pp6mg71skjnOlbNa0phHE",
	"input-set-wrapper": "base_tab---input-set-wrapper---2azQJCaEE4tEeTHAeViZ9l"
};