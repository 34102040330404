module.exports = () => [
  {
    disableEnterNavigation: true,
    imageOptions: { height: '164px', width: '204px' },
    path: ['content', 'cover', 'imagesShown', 'coverPhoto'],
    type: 'image',
  },
  {
    disableEnterNavigation: true,
    label: 'Accent Color',
    path: ['content', 'settings', 'accentColor'],
    type: 'color',
  },
  {
    disableEnterNavigation: true,
    label: 'Title Color',
    path: ['content', 'settings', 'coverFontColor'],
    type: 'color',
  },
];
