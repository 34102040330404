exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 20 : /opt/build/repo/react-app/editor_frontend/src/components/app/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/components/app/index.styl */\n.app---app-footer---2FfJiu9uxSWQ1UBdit1jWZ {\n  position: fixed;\n  bottom: 8px;\n  text-align: center;\n  width: 100%;\n  pointer-events: none;\n}\n\n/* line 7 : /opt/build/repo/react-app/editor_frontend/src/components/app/index.styl */\n.app---app-footer---2FfJiu9uxSWQ1UBdit1jWZ span {\n  background-color: #000;\n  display: inline-block;\n  padding: 1px 5px;\n  font-size: 13px;\n  opacity: 0.5;\n  color: #fff;\n}\n\n/* line 14 : /opt/build/repo/react-app/editor_frontend/src/components/app/index.styl */\n.app---app-footer---2FfJiu9uxSWQ1UBdit1jWZ span a {\n  color: #fff;\n  font-weight: 500;\n  margin-left: 4px;\n  cursor: pointer;\n  text-decoration: underline;\n  pointer-events: all !important;\n}\n", ""]);

// exports
exports.locals = {
	"app-footer": "app---app-footer---2FfJiu9uxSWQ1UBdit1jWZ"
};