const { Promise } = require('bluebird');
const _ = require('lodash');
const { locateService } = require('@services/service_locator');

class SharingService {
  static initClass() {
    this.prototype.shareTourbook = async function(options) {
      const {
        emailInput,
        currentUser,
        currentUserEmail,
        tourbookMeta,
        tourbookId,
        trackingCategory,
      } = options;
      const uids = await this.usersService.getUserIdsByEmail(emailInput);
      if (!uids) {
        throw new Error('User Not Found');
      } else {
        return this._shareTourbookWithUserId({
          email: emailInput,
          userId: uids[0],
          currentUser,
          currentUserEmail,
          tourbookMeta,
          tourbookId,
          trackingCategory,
        });
      }
    };

    this.prototype._shareTourbookWithUserId = async function(options) {
      const {
        email,
        userId,
        currentUser,
        currentUserEmail,
        tourbookMeta,
        tourbookId,
        trackingCategory,
      } = options;
      const userData = { email };
      const newTourbookMeta = _.assign({}, tourbookMeta, { shared: true });
      await this.firebaseService.updateValues('/', {
        [`tourbooks/${tourbookId}/shared/${userId}`]: userData,
        [`userSharedTourbooks/${userId}/${tourbookId}`]: newTourbookMeta,
        [`userTourbooks/${tourbookMeta.ownerID}/${tourbookId}/shared`]: true,
      });

      // Sends an email to `collab_email` using Customer.io
      await this.tourbookAnalyticsService.trackTourbookEvent(
        'Shares tour book with collaborator',
        {
          tourbookId,
          category: trackingCategory,
          targetUser: currentUser,
          additionalProperties: {
            collab_email: email,
          },
        }
      );

      return await this.tourbookAnalyticsService.trackTourbookEventOnBackend(
        'Receives a Shared Tour Book',
        {
          tourbookId,
          category: trackingCategory,
          targetUser: userId,
          currentUser,
          additionalProperties: {
            shared_by_email: currentUserEmail,
          },
        }
      );
    };
  }

  constructor({ window }) {
    this.window = window;
    this.firebaseService = locateService('firebaseService');
    this.usersService = locateService('usersService');
    this.tourbookAnalyticsService = locateService('tourbookAnalyticsService');
  }

  handleSharing() {
    return Promise.resolve();
  }

  subscribeToTourbookSharedUsers(tourbookId, fn) {
    return this.firebaseService.subscribeToUpdates(
      `tourbooks/${tourbookId}/shared`,
      fn
    );
  }
}
SharingService.initClass();

module.exports = SharingService;
