/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require('lodash');
const { EventEmitter } = require('events');
const KeyboardShortcutService = require('./keyboard_shortcut_service');

const buildMockEvent = (data) =>
  _.assign({}, data, {
    preventDefault: sinon.stub(),
    stopPropagation: sinon.stub(),
  });

describe('KeyboardShortcutService', function () {
  beforeEach(function () {
    this.document = { some: 'document data' };
    this.$document = new EventEmitter();
    this.$ = sinon.stub().withArgs(this.document).returns(this.$document);
    return (this.service = new KeyboardShortcutService({
      $: this.$,
      document: this.document,
    }));
  });

  describe('register', function () {
    describe('pressing a single key', function () {
      beforeEach(function () {
        this.callback = sinon.stub();
        return this.service.register({ key1: this.callback });
      });

      describe('when an event is set to bypass KeyboardShortcutService', function () {
        beforeEach(function () {
          this.event = buildMockEvent({ bypassKeyboardShortcutService: true });
          return this.$document.emit('keydown', this.event);
        });

        return it('does not execute the callback', function () {
          return expect(this.callback).to.not.have.been.called;
        });
      });

      describe('when a key is pressed', function () {
        beforeEach(function () {
          this.event = buildMockEvent({ which: 'key1' });
          return this.$document.emit('keydown', this.event);
        });

        it('executes the callback', function () {
          expect(this.callback).to.have.been.calledOnce;
          return expect(this.callback).to.have.been.calledWith(this.event);
        });

        return it('prevents the event default action and stops propagation', function () {
          expect(this.event.preventDefault).to.have.been.calledOnce;
          return expect(this.event.stopPropagation).to.have.been.calledOnce;
        });
      });

      return describe('when a different key is pressed', function () {
        beforeEach(function () {
          this.event = buildMockEvent({ which: 'key2' });
          return this.$document.emit('keydown', this.event);
        });

        it('does not execute the callback', function () {
          return expect(this.callback).not.to.have.been.called;
        });

        return it('does not prevent the event default action or stop propagation', function () {
          expect(this.event.preventDefault).not.to.have.been.called;
          return expect(this.event.stopPropagation).not.to.have.been.called;
        });
      });
    });

    describe('pressing shift and another key', function () {
      beforeEach(function () {
        this.callback = sinon.stub();
        return this.service.register({ 'shift-key1': this.callback });
      });

      return describe('when the key are pressed', function () {
        beforeEach(function () {
          this.event = buildMockEvent({ shiftKey: true, which: 'key1' });
          return this.$document.emit('keydown', this.event);
        });

        it('executes the callback', function () {
          expect(this.callback).to.have.been.calledOnce;
          return expect(this.callback).to.have.been.calledWith(this.event);
        });

        return it('prevents the event default action and stops propagation', function () {
          expect(this.event.preventDefault).to.have.been.calledOnce;
          return expect(this.event.stopPropagation).to.have.been.calledOnce;
        });
      });
    });

    describe('pressing ctrl and another key', function () {
      beforeEach(function () {
        this.callback = sinon.stub();
        return this.service.register({ 'ctrl-key1': this.callback });
      });

      return describe('when the key are pressed', function () {
        beforeEach(function () {
          this.event = buildMockEvent({ ctrlKey: true, which: 'key1' });
          return this.$document.emit('keydown', this.event);
        });

        it('executes the callback', function () {
          expect(this.callback).to.have.been.calledOnce;
          return expect(this.callback).to.have.been.calledWith(this.event);
        });

        return it('prevents the event default action and stops propagation', function () {
          expect(this.event.preventDefault).to.have.been.calledOnce;
          return expect(this.event.stopPropagation).to.have.been.calledOnce;
        });
      });
    });

    return describe('multiple registered callbacks', function () {
      beforeEach(function () {
        this.callback1 = sinon.stub();
        this.callback2 = sinon.stub();
        this.service.register({ key1: this.callback1 });
        return (this.event = buildMockEvent({ which: 'key1' }));
      });

      describe('by default', function () {
        beforeEach(function () {
          this.service.register({ key1: this.callback2 });
          return this.$document.emit('keydown', this.event);
        });

        return it('executes callbacks in the order they registed', function () {
          expect(this.callback1).to.have.been.calledOnce;
          expect(this.callback2).to.have.been.calledOnce;
          return expect(this.callback1).to.have.been.calledBefore(
            this.callback2
          );
        });
      });

      return describe('with prepend option', function () {
        beforeEach(function () {
          this.service.register({ key1: this.callback2 }, { prepend: true });
          return this.$document.emit('keydown', this.event);
        });

        return it('puts the callback first in the list', function () {
          expect(this.callback1).to.have.been.calledOnce;
          expect(this.callback2).to.have.been.calledOnce;
          return expect(this.callback2).to.have.been.calledBefore(
            this.callback1
          );
        });
      });
    });
  });

  describe('unregister', function () {
    beforeEach(function () {
      this.callback1 = sinon.stub();
      this.callback2 = sinon.stub();
      this.unregister = this.service.register({
        key1: this.callback1,
        key2: this.callback2,
      });
      return this.unregister();
    });

    return describe('when the key is pressed', function () {
      beforeEach(function () {
        this.events = ['key1', 'key2'].map((key) =>
          buildMockEvent({ which: key })
        );
        return this.events.forEach((event) =>
          this.$document.emit('keydown', event)
        );
      });

      it('does not executes the callback', function () {
        expect(this.callback1).not.to.have.been.called;
        return expect(this.callback2).not.to.have.been.called;
      });

      return it('does not prevent the event default action or stop propagation', function () {
        return this.events.forEach(function (event) {
          expect(event.preventDefault).not.to.have.been.called;
          return expect(event.stopPropagation).not.to.have.been.called;
        });
      });
    });
  });

  describe('overwrite', function () {
    beforeEach(function () {
      this.callback1 = sinon.stub();
      this.callback2 = sinon.stub();
      this.unregister = this.service.register({ key1: this.callback1 });
      return (this.restore = this.service.overwrite({ key2: this.callback2 }));
    });

    describe('when key1 is pressed', function () {
      beforeEach(function () {
        return this.$document.emit(
          'keydown',
          buildMockEvent({ which: 'key1' })
        );
      });

      return it('does not executes the callback', function () {
        return expect(this.callback1).not.to.have.been.called;
      });
    });

    describe('when key2 is pressed', function () {
      beforeEach(function () {
        return this.$document.emit(
          'keydown',
          buildMockEvent({ which: 'key2' })
        );
      });

      return it('executes the callback', function () {
        return expect(this.callback2).to.have.been.called;
      });
    });

    describe('unregister key1 and then restore', function () {
      beforeEach(function () {
        this.unregister();
        return this.restore();
      });

      return describe('when key1 is pressed', function () {
        beforeEach(function () {
          return this.$document.emit(
            'keydown',
            buildMockEvent({ which: 'key1' })
          );
        });

        return it('does not executes the callback', function () {
          return expect(this.callback1).not.to.have.been.called;
        });
      });
    });

    return describe('restore', function () {
      beforeEach(function () {
        return this.restore();
      });

      return describe('when key1 is pressed', function () {
        beforeEach(function () {
          return this.$document.emit(
            'keydown',
            buildMockEvent({ which: 'key1' })
          );
        });

        return it('executes the callback', function () {
          return expect(this.callback1).to.have.been.calledOnce;
        });
      });
    });
  });

  return describe('reassign', function () {
    beforeEach(function () {
      this.callback = sinon.stub();
      this.unregister = this.service.register({ key1: this.callback });
      return (this.restore = this.service.reassign('key1', 'key2'));
    });

    describe('when key1 is pressed', function () {
      beforeEach(function () {
        return this.$document.emit(
          'keydown',
          buildMockEvent({ which: 'key1' })
        );
      });

      return it('does not execute the callback', function () {
        return expect(this.callback).not.to.have.been.called;
      });
    });

    describe('when key2 is pressed', function () {
      beforeEach(function () {
        return this.$document.emit(
          'keydown',
          buildMockEvent({ which: 'key2' })
        );
      });

      return it('executes the callback', function () {
        return expect(this.callback).to.have.been.called;
      });
    });

    describe('unregister and then restore', function () {
      beforeEach(function () {
        this.unregister();
        return this.restore();
      });

      return describe('when key1 is pressed', function () {
        beforeEach(function () {
          return this.$document.emit(
            'keydown',
            buildMockEvent({ which: 'key1' })
          );
        });

        return it('does not execute the callback', function () {
          return expect(this.callback).not.to.have.been.called;
        });
      });
    });

    return describe('restore', function () {
      beforeEach(function () {
        return this.restore();
      });

      return describe('when key1 is pressed', function () {
        beforeEach(function () {
          return this.$document.emit(
            'keydown',
            buildMockEvent({ which: 'key1' })
          );
        });

        return it('executes the callback', function () {
          return expect(this.callback).to.have.been.calledOnce;
        });
      });
    });
  });
});
