const _ = require('lodash');
const madison = require('madison');
const moment = require('moment');
const numeral = require('numeral');

const abbrState = function (str) {
  let abbr;
  if (str?.length > 2) {
    abbr = madison.getStateAbbrev(str);
  }
  return abbr || str;
};

const abbrToUppercase = function (str) {
  if (!str?.replace) {
    return str;
  }
  str = str.trim().replace(/^(ne|nw|se|sw)\s+/i, (txt) => txt.toUpperCase());
  str = str.replace(/\s+(ne|nw|se|sw)$/i, (txt) => txt.toUpperCase());
  str = str.replace(/\s+(ne|nw|se|sw)\s+/gi, (txt) => txt.toUpperCase());
  return str.replace(/\s+([xivl]+)$/i, (txt) => txt.toUpperCase());
};

const boolToString = function (bool) {
  if (bool == null) {
    return null;
  }
  if (bool) {
    return 'Yes';
  } else {
    return 'No';
  }
};

const formatPercentage = function (str) {
  if (!str) {
    return;
  }
  return numeral(str).format('0.00%');
};

const formatThousands = function (str) {
  if (!str) {
    return;
  }
  return numeral(str).format('0,0');
};

const formatThousandsDecimal = function (str) {
  if (!str) {
    return;
  }
  return numeral(str).format('0,0.000');
};

const handleDate = function (date) {
  if (!date) {
    return;
  }
  if (_.isString(date)) {
    return date;
  }
  return moment.unix(date).format('MMM dd, yyyy');
};

const titleCase = function (str) {
  if (!str?.replace) {
    return str;
  }
  str = str
    .trim()
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  return abbrToUppercase(str);
};

const toRange = function (min, field, data) {
  const max = data[field.context]?.[field.toRangeMaxKey] || null;
  min = min === max ? null : min || null;
  return { max, min };
};

const sectionFieldConfigMapping = {
  header: [
    { context: 'property', key: 'propertyName', transform: titleCase },
    { context: 'property', key: 'CorrectedAddress1', transform: titleCase },
    { context: 'property', key: 'city', transform: titleCase },
    { context: 'property', key: 'state', transform: abbrState },
    { context: 'property', key: 'zip' },
    { context: 'property', key: 'crossStreets' },
  ],
  property: [
    { context: 'property', key: 'buildingArea', transform: formatThousands },
    // {backupContext: 'property', context: 'space', key: 'floors'}
    { backupContext: 'space', context: 'property', key: 'floors' },
    { context: 'property', key: 'yearBuilt' },
    { context: 'property', key: 'yearRenovated' },
    { context: 'property', key: 'officeClass' },
    { context: 'property', key: 'parkingRatio' },
  ],
  space: [
    { context: 'space', key: 'smallestAvailableSpace', transform: toRange },
    {
      context: 'space',
      key: 'leaseRateLow',
      newKey: 'leaseHighPrice',
      toRangeMaxKey: 'leaseRateHigh',
      transform: toRange,
    },
    { context: 'space', key: 'totalRent' },
    { context: 'space', key: 'leaseRateType' },
    { context: 'space', key: 'spaceType' },
    { context: 'space', key: 'floor' },
    { context: 'space', key: 'suite' },
    { context: 'space', key: 'term' },
    { context: 'space', key: 'availableDate', transform: handleDate },
    { context: 'space', key: 'condition' },
    { context: 'space', key: 'description' },
    { context: 'space', key: 'url' },
  ],
  subLease: [
    { context: 'space', key: 'smallestAvailableSpace', transform: toRange },
    {
      context: 'space',
      key: 'leaseRateLow',
      newKey: 'leaseHighPrice',
      toRangeMaxKey: 'leaseRateHigh',
      transform: toRange,
    },
    { context: 'space', key: 'totalRent' },
    { context: 'space', key: 'leaseRateType' },
    { context: 'space', key: 'spaceType' },
    { context: 'space', key: 'floor' },
    { context: 'space', key: 'suite' },
    { context: 'space', key: 'term' },
    { context: 'space', key: 'availableDate', transform: handleDate },
    { context: 'space', key: 'condition' },
    { context: 'space', key: 'description' },
    { context: 'space', key: 'url' },
  ],
  terms: [
    { context: 'space', key: 'availableSize', transform: toRange },
    {
      context: 'space',
      key: 'salePriceLow',
      newKey: 'salePrice',
      toRangeMaxKey: 'salePriceHigh',
      transform: toRange,
    },
    { context: 'space', key: 'salePricePerSquareFoot', transform: toRange },
    { context: 'space', key: 'capRate', transform: formatPercentage },
  ],
};

const n360DataFieldConfigs = [
  { context: 'property', key: 'generalUse', newKey: 'propertyType' },
  //  {context: 'property', key: 'lossFactor', newKey: }
  { context: 'property', key: 'owner', transform: titleCase },
  { context: 'property', key: 'parkingSpaces' },
  { context: 'property', key: 'parkingRatio' },
  { context: 'property', key: 'lotArea', transform: formatThousandsDecimal },
  { context: 'property', key: 'zoning' },
  { context: 'property', key: 'blockLot' },
  { context: 'space', key: 'listingType' },
  { context: 'space', key: 'sublease', transform: boolToString },
  { context: 'space', key: 'market' },
  { context: 'space', key: 'submarket' },
  { context: 'space', key: 'company' },
  { context: 'space', key: 'leaseOrSale' },
  { context: 'space', key: 'isLease' },
  { context: 'space', key: 'tenantSiteType' },
  { context: 'space', key: 'relationshipType' },
  { context: 'space', key: 'propertyId' },
  { context: 'space', key: 'listingId' },
  { context: 'space', key: 'isSaleSpace', newKey: 'isSale' },
  { context: 'space', key: 'divisiblity' },
  { context: 'space', key: 'nnnExpenses' },
  { context: 'space', key: 'vacant', transform: boolToString },
  { context: 'space', key: 'maxContiguous', transform: formatThousands },
  { context: 'space', key: 'minDivisible', transform: formatThousands },
  { context: 'space', key: 'estMonthlyRent', transform: formatThousands },
  { context: 'space', key: 'offices' },
  { context: 'space', key: 'restrooms' },
  { context: 'space', key: 'tiAllowance' },
  { context: 'space', key: 'driveIns' },
  { context: 'space', key: 'fencedYard', transform: boolToString },
  { context: 'space', key: 'pavedYard', transform: boolToString },
  { context: 'space', key: 'sprinkler', transform: boolToString },
  { context: 'space', key: 'officeAir' },
  { context: 'space', key: 'officeHeat' },
  { context: 'space', key: 'warehouseHVAC' },
  { context: 'space', key: 'saleType' },
  { context: 'space', key: 'leaseRatePerType' },
  { context: 'space', key: 'dockDoors' },
  {
    context: 'space',
    key: 'clearHeightMin',
    newKey: 'clearHeight',
    toRangeMaxKey: 'clearHeightMax',
    transform: toRange,
  },
  { context: 'space', key: 'operatingExpensesPerArea' },
];

const extractData = function (config, data) {
  let value =
    data[config.context]?.[config.key] ||
    data[config.backupContext]?.[config.key];
  if (config.transform) {
    value = config.transform(value, config, data);
  }
  return {
    key: config.newKey || config.key,
    value: value || null,
  };
};

const buildEntryFields = (data) =>
  _.mapValues(sectionFieldConfigMapping, (sectionFieldConfigs, sectionKey) =>
    _.chain(sectionFieldConfigs)
      .map(function (config, index) {
        const { key, value } = extractData(config, data);
        if (sectionKey === 'header') {
          return [key, value];
        } else {
          return [key, { order: index, value }];
        }
      })
      .fromPairs()
      .value()
  );

const buildEntryN360Data = (data) =>
  _.chain(n360DataFieldConfigs)
    .map(function (config) {
      const { key, value } = extractData(config, data);
      return [key, value];
    })
    .fromPairs()
    .value();

module.exports = { buildEntryFields, buildEntryN360Data };
