const _ = require('lodash');
const importedStyles = require('../../index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const ImageContainer = require('@components/shared/image/container');
const PropTypes = require('prop-types');
const React = require('react');
const ReadyRegistry = require('@utils/ready_registry');
const { findDOMNode } = require('react-dom');
const { LOGO_DEFAULT_WIDTH } = require("@const");

const ContactInfo = (props) => {
  const _buildOnClick = (fieldKey) => {
    return () => {
      props.setCurrentTab({
        key: `contact_info_${props.contactInfoId}`,
      });
      return props.setCurrentField({ key: _getFieldId(fieldKey) });
    };
  };

  const _getFieldId = (fieldKey) => {
    return `content-cover-contactInfos-${props.contactInfoId}-${fieldKey}`;
  };

  const _getFieldKeysToRender = () => {
    return _.chain(props.tourbookFields.cover.roleFields)
      .map('key')
      .filter(
        (fieldKey) => _isFieldActive(fieldKey) || _getFieldValue(fieldKey)
      )
      .value();
  };

  const _getFieldProps = (fieldKey) => {
    const fieldProps = {
      className: cx([
        fieldKey,
        `field`,
        `${_isFieldActive(fieldKey) ? 'active' : ''}`,
      ]),
    };
    if (props.isEditing) {
      fieldProps.onClick = _buildOnClick(fieldKey);
    }
    return fieldProps;
  };

  const _getFieldValue = (fieldKey) => {
    return props.contactInfo[fieldKey];
  };

  const _convertLogoVwToWidthPx = (vw) => {
    return props.imageService.convertVwToPx(vw || LOGO_DEFAULT_WIDTH);
  }
  const _convertLogoVwToHeightPx = (vw, logoRatio) => {
    return props.imageService.convertVhToPx((vw || LOGO_DEFAULT_WIDTH) / logoRatio);
  }

  const coverLogoCss = (logoOptions) => {
    const { widthInVw } = logoOptions;

    const additionalStyles = {};
    if (widthInVw) {
      additionalStyles['width'] = `${_convertLogoVwToWidthPx(widthInVw)}px`; // Docraptor converts better when logos are in px
      if (logoRatio) {
        additionalStyles['height'] = `${_convertLogoVwToHeightPx(widthInVw, logoRatio)}px`; // Docraptor converts better when logos are in px
      }
    }
    return additionalStyles;
  };

  const _getLogoProps = () => {
    const clickable = props.shouldRenderLogo && props.isEditing;
    const fieldProps = {
      className: cx([
        `logo-container`,
        `${props.shouldRenderLogo && props.isEditing ? 'clickable' : ''}`,
        `${_isFieldActive('logo') ? 'active' : ''}`,
      ]),
      id: `logo-wrapper-${props.contactInfo.logo?.cloudinaryPublicId}`,
      style: coverLogoCss(props.contactInfo),
    };
    if (clickable) {
      fieldProps.onClick = _buildOnClick('logo');
    }
    return fieldProps;
  };

  const _isFieldActive = (fieldKey) => {
    return props.isEditing && props.currentField.key === _getFieldId(fieldKey);
  };

  const [logoRatio, setLogoRatio] = React.useState();
  React.useEffect(() => {
    // Get logo width vs height ratio
    if (props.shouldRenderLogo) {
      props.imageService
        .getImageRatio(props.contactInfo.logo.cloudinaryPublicId)
        .then((r) => setLogoRatio(r));
    }
  }, [
    props.shouldRenderLogo,
    setLogoRatio,
    props.contactInfo.logo?.cloudinaryPublicId,
  ]);

  React.useEffect(() => {
    // Apply logo resizing
    if (props.shouldRenderLogo && logoRatio) {
      const { widthInVw } = props.contactInfo;
      const elemId = `logo-wrapper-${props.contactInfo.logo.cloudinaryPublicId}`;
      const elem = findDOMNode(document.getElementById(elemId));

      const width = `${_convertLogoVwToWidthPx(widthInVw)}px`;
      const height = `${_convertLogoVwToHeightPx(widthInVw, logoRatio)}px`;
      if (elem.style) {
        elem.style.width = width;
        elem.style.height = height;
      } else {
        elem.style = { width, height };
      }
    }
  }, [
    props.shouldRenderLogo,
    logoRatio,
    props.contactInfo.widthInVw,
    props.contactInfo.logo,
  ]);

  return (
    <div>
      <div {..._getLogoProps()}>
        {props.shouldRenderLogo ? (
          <ImageContainer
            backgroundSize="contain"
            croppable={false}
            image={props.contactInfo.logo}
            imagePath={[
              'content',
              'cover',
              'contactInfos',
              props.contactInfoId,
              'logo',
            ]}
            logo={true}
            readyRegistry={props.readyRegistry}
          />
        ) : undefined}
      </div>
      {_getFieldKeysToRender().map((fieldKey) => {
        return (
          <div key={fieldKey} className={cx(['field-container'])}>
            <div {..._getFieldProps(fieldKey)}>{_getFieldValue(fieldKey)}</div>
          </div>
        );
      })}
    </div>
  );
};

ContactInfo.propTypes = {
  contactInfo: PropTypes.object.isRequired,
  contactInfoId: PropTypes.string.isRequired,
  currentField: PropTypes.object,
  isEditing: PropTypes.bool,
  readyRegistry: PropTypes.instanceOf(ReadyRegistry).isRequired,
  setCurrentField: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  shouldRenderLogo: PropTypes.bool,
  tourbookFields: PropTypes.object,
  imageService: PropTypes.object.isRequired,
};

module.exports = ContactInfo;
