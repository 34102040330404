const _ = require('lodash');
const PropTypes = require('prop-types');
const React = require('react');

const importedStyles = require('./index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);

const ModalContainer = require('@components/shared/modal/container');
const { build } = require('@components/container_helpers');
const { locateService } = require('@services/service_locator');
const FontAwesomeIcon = require('@components/shared/font_awesome_icon');

const mapStateToProps = (state) => ({
  currentUser: state.userSession.id,
  currentUserEmail: state.userSession.firebaseProfile.email,
  sharedUsers: state.collaborateModal.sharedUsers || {},
  tourbook: state.collaborateModal.tourbook,
  tourbookId: state.collaborateModal.tourbookId,
  trackingCategory: state.collaborateModal.trackingCategory,
});

class CollaborateModalInner extends React.Component {
  static initClass() {
    this.propTypes = {
      onClose: PropTypes.func.isRequired,
      currentUser: PropTypes.string.isRequired,
      currentUserEmail: PropTypes.string.isRequired,
      sharedUsers: PropTypes.object,
      tourbook: PropTypes.object.isRequired,
      tourbookId: PropTypes.string.isRequired,
      trackingCategory: PropTypes.string.isRequired,
    };

    this.prototype.componentWillMount = async function () {
      return (this.teardown = await this.props.dispatch(
        this.collaborateModalActions.initializeCollaborateModal(
          this.props.tourbookId
        )
      ));
    };

    this.prototype._addCollaborator = async function (emailToAdd) {
      let needle;
      if (emailToAdd == null) {
        emailToAdd = this.state.emailInput;
      }
      if (!this.usersService.validateEmail(emailToAdd)) {
        return this._setErrorMessage(
          'Oops. This email does not appear to be valid.'
        );
      } else if (
        emailToAdd.toLowerCase() === this.props.currentUserEmail.toLowerCase()
      ) {
        return this._setErrorMessage(
          'Oops. You cannot share a tour book with yourself.'
        );
      } else if (
        ((needle = emailToAdd),
        Array.from(
          _.map(this.props.sharedUsers, ({ email }) => email)
        ).includes(needle)) ||
        emailToAdd === this.props.tourbook.ownerEmail
      ) {
        return this._setErrorMessage('Oops. This user already has access.');
      } else {
        try {
          await this.sharingService.shareTourbook({
            currentUser: this.props.currentUser,
            currentUserEmail: this.props.currentUserEmail,
            emailInput: emailToAdd,
            tourbookMeta: this.props.tourbook,
            tourbookId: this.props.tourbookId,
            trackingCategory: this.props.trackingCategory,
          });
          this.setState({
            emailInput: '',
            errorMessage: null,
            notification: null,
          });
          return this._setNotification({
            type: 'notification',
            text: `${emailToAdd} can now edit this tour book.`,
            title: 'Tour Book Shared!',
            isError: false,
          });
        } catch (error) {
          if (error.message === 'User Not Found') {
            const uids =
              await this.sharingService.usersService.getUserIdsByEmailPascalCase(
                emailToAdd
              );
            if (uids && uids.length > 0) {
              this._setNotification({
                type: 'notification',
                text: `${emailToAdd} doesn't match with a user exactly. Ask them to log out and log in again to fix this`,
                title: 'User Not Found',
                isError: true,
              });
            } else {
              this._setNotification({
                type: 'notification',
                text: `${emailToAdd} has not yet signed up for Spaceful`,
                title: 'User Not Found',
                isError: true,
              });
            }
            return this.setState({
              emailInput: '',
            });
          } else {
            throw error;
          }
        }
      }
    };

    this.prototype._removeCollaborator = async function ({ email, userId }) {
      this._setNotification({
        type: 'removed',
        lastRemoved: email,
      });
      return await this.props.dispatch(
        this.collaborateModalActions.removeCollaborator(this.props.tourbookId, {
          email,
          userId,
        })
      );
    };
  }
  constructor(props) {
    super(props);
    this.sharingService = locateService('sharingService');
    this.usersService = locateService('usersService');
    this.collaborateModalActions = locateService('collaborateModalActions');
    this.state = {
      errorMessage: null,
      emailInput: '',
      notification: null,
    };
  }

  componentWillUnmount() {
    return typeof this.teardown === 'function' ? this.teardown() : undefined;
  }

  render() {
    return (
      <ModalContainer onClose={this.props.onClose}>
        <div className={cx(['helper'])}>
          <div className={cx(['root'])}>
            <div className={cx(['header'])}>Collaborate on a tour book</div>
            <div className={cx(['sub-header'])}>
              {'Allow team members to collaborate on '}
              <span className={cx(['tourbook-title'])}>
                {this.props.tourbook.name}
              </span>
              {' with you.'}
            </div>
            <div
              className={cx([
                'add-email-container',
                this.state.errorMessage != null ? 'with-error' : undefined,
              ])}
            >
              <div className={cx(['text'])}>
                Enter email address of team member
              </div>
              <div className={cx(['input-container'])}>
                <input
                  className={cx(['email'])}
                  placeholder="email@domain.com"
                  spellCheck={false}
                  onChange={(event) =>
                    this.setState({ emailInput: event.target.value })
                  }
                  value={this.state.emailInput}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      return this._addCollaborator();
                    }
                  }}
                />
                <div className={cx(['add-svg-container'])} onClick={() => this._addCollaborator()}>
                  <FontAwesomeIcon name="arrow-right" />
                </div>
              </div>
              {this.state.errorMessage != null
                ? this._renderErrorMessage()
                : undefined}
            </div>
            {this.state.notification != null
              ? this._renderNotification()
              : undefined}
            <div className={cx(['shared-with-container'])}>
              <div className={cx(['shared-with-header'])}>
                Tour book shared with:
              </div>
              <div className={cx(['owner-container'])}>
                <div className={cx(['email'])}>
                  {this.props.tourbook.ownerEmail}
                </div>
                <span className={cx(['table-right'])}>owner</span>
              </div>
              <div className={cx(['shared-container'])}>
                {_.map(this.props.sharedUsers, ({ email }, userId) => {
                  return (
                    <div className={cx(['shared-with'])} key={email}>
                      <div className={cx(['email'])}>{email}</div>
                      {userId !== this.props.currentUser ? (
                        <div
                          className={cx(['remove'])}
                          onClick={() =>
                            this._removeCollaborator({ email, userId })
                          }
                        >
                          <FontAwesomeIcon name="xmark" />
                          <span className={cx(['table-right'])}>remove</span>
                        </div>
                      ) : undefined}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }

  _renderErrorMessage() {
    return (
      <div className={cx(['error-message'])}>{this.state.errorMessage}</div>
    );
  }

  _renderNotification() {
    return (
      <div className={cx(['notification-container'])}>
        {(() => {
          switch (this.state.notification.type) {
            case 'removed':
              return (
                <div className={cx(['notification'])}>
                  <div className={cx(['notification-header'])}>
                    <div className={cx(['notification-title'])}>
                      User has been removed!
                    </div>
                    <div
                      className={cx(['undo-text'])}
                      onClick={() => {
                        this._addCollaborator(
                          this.state.notification.lastRemoved
                        );
                        return this.props.dispatch(
                          this.collaborateModalActions._track(
                            'Hits Undo to Re-add Removed User',
                            {
                              tourbookId: this.props.tourbookId,
                              additionalProperties: {
                                collab_email:
                                  this.state.notification.lastRemoved,
                              },
                            }
                          )
                        );
                      }}
                    >
                      (undo?)
                    </div>
                  </div>
                  <div className={cx(['notification-text'])}>
                    {`${this.state.notification.lastRemoved} \
    can no longer see or edit this \
    tour book.`}
                  </div>
                </div>
              );
            case 'notification':
              return (
                <div className={cx(['notification'])}>
                  <div className={cx(['notification-header'])}>
                    <div
                      className={cx([
                        `notification-title`,
                        `${this.state.notification.isError ? 'error' : ''}`,
                      ])}
                    >
                      {this.state.notification.title}
                    </div>
                  </div>
                  <div className={cx(['notification-text'])}>
                    {this.state.notification.text}
                  </div>
                </div>
              );
          }
        })()}
      </div>
    );
  }

  _setNotification(notification) {
    return this.setState({
      notification,
      errorMessage: null,
    });
  }

  _setErrorMessage(errorMessage) {
    return this.setState({
      notification: null,
      errorMessage,
    });
  }
}
CollaborateModalInner.initClass();

const CollaborateModal = build({
  component: CollaborateModalInner,
  displayName: 'CollaborateModal',
  mapStateToProps,
});

module.exports = { CollaborateModal, CollaborateModalInner };
