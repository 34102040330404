const _ = require('lodash');
const importedStyles = require('../../index.styl');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const PropTypes = require('prop-types');
const React = require('react');

class HeaderSection extends React.Component {
  constructor(props) {
    super(props);
    this._hasValue = this._hasValue.bind(this);
    this._isFieldActive = this._isFieldActive.bind(this);
  }

  static initClass() {
    this.propTypes = {
      currentField: PropTypes.object,
      entryId: PropTypes.string.isRequired,
      entryAccentColor: PropTypes.string.isRequired,
      headerFields: PropTypes.object.isRequired,
      isEditing: PropTypes.bool,
      navigateToCurrentEntryField: PropTypes.func.isRequired,
    };
  }

  render() {
    return (
      <div className={cx(['header-section'])}>
        {this._shouldRenderField('notesHeader') ? (
          <div
            {...this._getFieldProps(['header', 'notesHeader'], 'notesHeader')}
          >
            {this.props.headerFields.notesHeader}
          </div>
        ) : undefined}
      </div>
    );
  }

  _buildOnClick(fieldKey) {
    if (!this.props.isEditing) {
      return;
    }
    return () => {
      return this.props.navigateToCurrentEntryField({
        fieldKey: this._getFieldId(fieldKey),
        tabKey: 'notes',
        key: 'entry-tourbook-notes',
      });
    };
  }

  _getFieldId(fieldKey) {
    return `content-entries-${this.props.entryId}-fields-header-${fieldKey}`;
  }

  _getFieldProps(classNameArray, ...fieldKeys) {
    const fieldKeyToFocus =
      fieldKeys.length === 1 ? fieldKeys[0] : _.find(fieldKeys, this._hasValue);
    return {
      className: cx([
        ...classNameArray,
        _.some(fieldKeys, this._isFieldActive) ? 'active' : '',
      ]),
      onClick: this._buildOnClick(fieldKeyToFocus),
    };
  }

  _hasValue(fieldKey) {
    return this.props.headerFields[fieldKey];
  }

  _isFieldActive(fieldKey) {
    return (
      this.props.isEditing &&
      this.props.currentField.key === this._getFieldId(fieldKey)
    );
  }

  _shouldRenderField(fieldKey) {
    return this._hasValue(fieldKey) || this._isFieldActive(fieldKey);
  }
}
HeaderSection.initClass();

module.exports = HeaderSection;
