const _ = require('lodash');
const Promise = require('bluebird');
const ChangeImageModalContainer = require('./components/change_image_modal/container');
const importedStyles = require('./index.styl');
const CoverViewContainer = require('./components/cover_view/container');
const CreatingEntryOverlayContainer = require('./components/creating_entry_overlay/container');
const CropImageModalContainer = require('./components/crop_image_modal/container');
const DeleteEntryImageModalContainer = require('./components/delete_entry_image_modal/container');
const DeletePageModalContainer = require('./components/delete_page_modal/container');
const DownloadPdfModalContainer = require('./components/download_pdf_modal/container');
var classNames = require('classnames/bind');
const cx = classNames.bind(importedStyles);
const EntryAmenitiesViewContainer = require('./components/entry_amenities_view/container');
const EntryImagesViewContainer = require('./components/entry_images_view/container');
const EntryViewContainer = require('./components/entry_view/container');
const ImportFromXproModalContainer = require('./components/import_from_xpro_modal/container');
const LoadingToggle = require('@components/shared/loading/toggle');
const MapViewContainer = require('./components/map_view/container');
const NewEntryModalContainer = require('./components/new_entry_modal/container');
const PdfPreviewModalContainer = require('./components/pdf_preview_modal/container');
const PropTypes = require('prop-types');
const querystring = require('querystring');
const React = require('react');
const RecommendedFieldsModalContainer = require('./components/recommended_fields_modal/container');
const SendToClientModalContainer = require('./components/send_to_client_modal/container');
const SettingsViewContainer = require('./components/settings_view/container');
const SummaryViewContainer = require('./components/summary_view/container');
const UpdateEntryAddressModalContainer = require('./components/update_entry_address_modal/container');
const ViewNavigationContainer = require('./components/view_navigation/container');
const { CollaborateModal } = require('@components/shared/collaborate_modal');
const AdditionalInputSelectionContainer = require('./components/input_set/additional_input_selection/container');
const PrivateNotesViewContainer = require('./components/private_notes_view/container');
const TourbookNotesContainer = require('./components/tourbook_notes/container');
const BrokerViewContainer = require('./components/broker-view/container');
const { useParams, useNavigate } = require('react-router-dom');

class TourbookEditorPage extends React.Component {
  static initClass() {
    this.propTypes = {
      currentModal: PropTypes.object.isRequired,
      currentView: PropTypes.object.isRequired,
      getFieldDefinitions: PropTypes.func.isRequired,
      initializeStatuses: PropTypes.object.isRequired,
      params: PropTypes.shape({
        tourbookId: PropTypes.string.isRequired,
      }),
      navigate: PropTypes.func.isRequired,
      setCurrentModal: PropTypes.func.isRequired,
      setCurrentView: PropTypes.func.isRequired,
      setTourbookId: PropTypes.func.isRequired,
      subscribeToData: PropTypes.func.isRequired,
      subscribeToPreviousFields: PropTypes.func.isRequired,
      subscribeToUserImages: PropTypes.func.isRequired,
      track: PropTypes.func.isRequired,
    };

    this.prototype._initialize = async function (props) {
      let results;
      props.setTourbookId(props.params.tourbookId);
      props.setCurrentView({ key: 'cover' });
      try {
        results = await Promise.all([
          props.getFieldDefinitions(),
          props.subscribeToData(),
          props.subscribeToPreviousFields(),
          props.subscribeToUserImages(),
        ]);
      } catch (err) {
        if (!this._isKnownError(err)) {
          throw err;
        }
        return;
      }
      props.track('Views tour book');
      this.unsubscribeFns = _.tail(results);
      return this._handleQueryParams();
    };
  }

  componentWillMount() {
    return this._initialize(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { subscribeToData } = nextProps.initializeStatuses;
    if (subscribeToData?.rejected) {
      if (this.redirectTimeout == null) {
        this.props.navigate('/library');
      }
    }

    if (nextProps.params.tourbookId !== this.props.params.tourbookId) {
      this._teardown();
      return this._initialize(nextProps);
    }
  }

  componentWillUnmount() {
    return this._teardown();
  }

  render() {
    return LoadingToggle(
      { isLoading: this._isLoading() },
      <div className={cx(['root'])}>
        <div className={cx(['view-navigation'])}>
          <ViewNavigationContainer />
        </div>
        <div className={cx(['view'])}>{this._renderCurrentView()}</div>
        {this._renderCurrentModal()}
      </div>
    );
  }

  _handleQueryParams() {
    const queryObj = querystring.parse(this.props.location?.search.replace(/^\?/, ''));
    if (queryObj.sendToClient) {
      // e.g. from Analytics page '?sendToClient=true'
      this.props.setCurrentModal({ key: 'send-to-client' });
    }
  }

  _isKnownError(error) {
    return _.startsWith(error.message, 'tourbook not found: ') || error.message === 'unauthorized';
  }

  _isLoading() {
    return _.some(
      this.props.initializeStatuses,
      (status) => !status?.fulfilled
    );
  }

  _renderCurrentModal() {
    const modalProps = {
      onClose: () => this.props.setCurrentModal({}),
      options: this.props.currentModal,
    };
    switch (this.props.currentModal?.key) {
      case 'creating-entry':
        return <CreatingEntryOverlayContainer {...modalProps} />;
      case 'crop-image':
        return <CropImageModalContainer {...modalProps} />;
      case 'change-image':
        return <ChangeImageModalContainer {...modalProps} />;
      case 'delete-entry-image':
        return <DeleteEntryImageModalContainer {...modalProps} />;
      case 'delete-page':
        return <DeletePageModalContainer entryType="page" {...modalProps} />;
      case 'delete-field':
        return <DeletePageModalContainer entryType="field" {...modalProps} />;
      case 'delete-broker':
        return <DeletePageModalContainer entryType="broker" {...modalProps} />;
      case 'download-pdf':
        return <DownloadPdfModalContainer {...modalProps} />;
      case 'import-from-xpro':
        return <ImportFromXproModalContainer {...modalProps} />;
      case 'new-entry':
        return <NewEntryModalContainer {...modalProps} />;
      case 'pdf-preview':
        return <PdfPreviewModalContainer {...modalProps} />;
      case 'recommended-fields':
        return <RecommendedFieldsModalContainer {...modalProps} />;
      case 'send-to-client':
        return <SendToClientModalContainer {...modalProps} />;
      case 'update-entry-address':
        return <UpdateEntryAddressModalContainer {...modalProps} />;
      case 'collaborate':
        return <CollaborateModal {...modalProps} />;
      case 'additional-input-selection':
        return <AdditionalInputSelectionContainer {...modalProps} />;
    }
  }

  _renderCurrentView() {
    const key = this.props.currentView?.key;
    switch (key) {
      case 'cover':
        return <CoverViewContainer />;
      case 'broker':
        return <BrokerViewContainer />;
      case 'entry-amenities':
        return <EntryAmenitiesViewContainer entryId={this.props.currentView.entryId} />;
      case 'entry-images':
        return <EntryImagesViewContainer entryId={this.props.currentView.entryId} />;
      case 'entry':
        return <EntryViewContainer entryId={this.props.currentView.entryId} />;
      case 'map':
        // change in key remounts and re-renders the component
        return <MapViewContainer key={this.props.currentView.mapOptionsKey} mapOptionsKey={this.props.currentView.mapOptionsKey} />;
      case 'summary':
        return <SummaryViewContainer />;
      case 'settings':
        return <SettingsViewContainer />;
      case 'private-notes':
        return <PrivateNotesViewContainer />;
      case 'entry-tourbook-notes':
        return <TourbookNotesContainer entryId={this.props.currentView.entryId} />;
      case 'entry-tourbook-images':
        return <TourbookNotesContainer entryId={this.props.currentView.entryId} />;
      case 'entry-tourbook-floorplan':
        return <TourbookNotesContainer entryId={this.props.currentView.entryId} />;
      default:
        return key;
    }
  }

  _teardown() {
    if (this.unsubscribeFns != null) {
      this.unsubscribeFns.forEach((fn) => fn());
    }
    return this.props.resetNavigation();
  }
}
TourbookEditorPage.initClass();

module.exports = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  return <TourbookEditorPage {...props} navigate={navigate} params={params} />;
};
