exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n/* line 11 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/text_input/index.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 297 : /opt/build/repo/react-app/editor_frontend/node_modules/stylus/lib/functions/index.styl */\n\n/* line 103 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/helpers.styl */\n\n/* line 103 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/helpers.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 22 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/colors.styl */\n\n/* line 1 : /opt/build/repo/react-app/editor_frontend/src/global_styles/mixins/durations.styl */\n\n/* line 3 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/text_input/index.styl */\n.text_input---active---cF8TpgbIyfrVk9fI9WOqK {\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n}\n\n/* line 7 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/text_input/index.styl */\n.text_input---unit---1Y-qMT23SnHjYpzC-WY_rD {\n  margin-left: 5px;\n}\n\n/* line 10 : /opt/build/repo/react-app/editor_frontend/src/components/shared/input/components/text_input/index.styl */\n.text_input---inactive---3RGyexNeb-9pqIt28_ftjU {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"active": "text_input---active---cF8TpgbIyfrVk9fI9WOqK",
	"unit": "text_input---unit---1Y-qMT23SnHjYpzC-WY_rD",
	"inactive": "text_input---inactive---3RGyexNeb-9pqIt28_ftjU"
};