const _ = require('lodash');
const { locateService, locateServices } = require('./service_locator');

class ImageService {
  constructor() {
    ({ cloudinaryService: this.cloudinaryService } = locateServices([
      'cloudinaryService',
    ]));
  }

  getImageFromUrl(imageUrl) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = (e) => {
        resolve(img);
      };
      img.onerror = reject;
      img.src = imageUrl;
    });
  }

  async getImageSize(cloudinaryPublicId) {
    const imageUrl = this.cloudinaryService.getImageUrl({
      cloudinaryPublicId,
    });
    const image = await this.getImageFromUrl(imageUrl);
    return { width: image.width, height: image.height };
  }

  async getImageRatio(cloudinaryPublicId) {
    const { width, height } = await this.getImageSize(cloudinaryPublicId);
    return width / height;
  }

  convertVwToPx(vw) {
    return (window.innerWidth * vw) / 100;
  }

  convertVhToPx(vh) {
    return (window.innerHeight * vh) / 100;
  }
}

module.exports = ImageService;
