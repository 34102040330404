const _ = require('lodash');
const { locateServices } = require('./service_locator');

class AddressSearchService {
  static initClass() {
    // ***!!! - Space images are retrieved via the address search service directly
    // ***!!! - and not delegated to n360_service
    this.prototype._searchByN360Address = async function(n360Address) {
      const result = { hasN360Error: false };
      const addressFields = {
        CorrectedAddress1: n360Address._source.streetAddress,
        CorrectedCity: n360Address._source.city,
        CorrectedState: n360Address._source.state,
        CorrectedZip: n360Address._source.zip,
        address: n360Address._source.streetAddress,
      };
      _.assign(result, addressFields);
      try {
        result.property = await this.n360Service.propertyDetailFetch(
          n360Address._id
        );
      } catch (error) {
        result.hasN360Error = true;
      }
      if (result.property != null) {
        const ordered = {};
        ordered.propertyId = result.property.propertyId;
        _.each(
          Object.keys(result.property).sort(),
          (key) => (ordered[key] = result.property[key])
        );
        _.assign(result.property, ordered);
        _.assign(result.property, addressFields);
      }
      try {
        result.N360Spaces = await this.n360Service.spaceSearch({
          propertyId: result.property.propertyId,
        });
      } catch (error1) {
        result.hasN360Error = true;
      }
      if (result.N360Spaces != null) {
        _.assign(result, {
          isN360Property: true,
          propertySource: 'N360API',
        });
      }
      if (result.N360Spaces.noResponse != null) {
        if (result.N360Spaces.noResponse && result.hasN360Error !== true) {
          result.N360Spaces = [];
        }
      }
      return result;
    };

    // this was changed!!!!!
    this.prototype._searchByGoogleAddress = async function(googleAddress) {
      const result = { hasGoogleError: false };
      const addressFields =
        this.googleAddressService.getAddressParts(googleAddress);
      _.assign(result, this._getGoogleAddressData(googleAddress));
      return result;
    };
  }

  constructor() {
    ({
      googleAddressService: this.googleAddressService,
      n360FieldsService: this.n360FieldsService,
      n360Service: this.n360Service,
    } = locateServices([
      'googleAddressService',
      'n360FieldsService',
      'n360Service',
    ]));
  }

  search({
    n360Address,
    googleAddress,
  }) {
    if (n360Address) {
      return this._searchByN360Address(n360Address);
    } else {
      return this._searchByGoogleAddress(googleAddress);
    }
  }

  _getGoogleAddressData(googleAddress) {
    const result = {
      isXceligentProperty: false,
      isN360Property: false,
      property: this.googleAddressService.getProperty(googleAddress),
      propertySource: 'google',
      N360Spaces: [],
    };
    result.property.googlePlace = googleAddress;
    return result;
  }
}
AddressSearchService.initClass();

module.exports = AddressSearchService;
